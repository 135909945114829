/* eslint-disable radix */
/* eslint-disable no-bitwise */
import React, { createContext, useState, useMemo, useContext, useEffect, useCallback } from 'react';
import type {
  IAssetPropCreate,
  IChangeColorTag,
  IChildrenNodes,
  ISelectedVulns,
} from '../utils/helpers/types';
import type localeDashboardForm from '../utils/i18n/dashboardLocale/addDashboardForm.json';
import type {
  AssetFirewallDashboardResponse,
  AssetHypervisorDashboardResponse,
  AssetRouterDashboardResponse,
  AssetSwitchDashboardResponse,
} from '../types/__generated/on-premise-solution/api/assetsDevicesDashboardResponse.v1';
import type { AssetHostDashboardResponse } from '../types/__generated/on-premise-solution/api/assetHostDashboardResponse.v1';
import type { AssetImageDashboardResponse } from '../types/__generated/on-premise-solution/api/assetImageDashboardResponse.v1';
import { useHostModalContext } from './useHostModalContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeDashboardForm;

const AssetCreatePropContext = createContext<IAssetPropCreate | null>(null);

export function AssetCreatePropProvider({ children }: IChildrenNodes): React.ReactElement {
  const hostSubType = useMemo(
    () => [
      { text: currentLocale === 'ru-RU' ? 'Сервер' : 'Server', val: 'server' },
      {
        text: currentLocale === 'ru-RU' ? 'Рабочая станция (АРМ)' : 'Workstation',
        val: 'workstation',
      },
      { text: currentLocale === 'ru-RU' ? 'Другой' : 'Other', val: 'other' },
    ],
    [],
  );

  // const hostImportance = AssetHost.properties.importance.enum;
  const hostImportance = useMemo(
    () => [
      { text: currentLocale === 'ru-RU' ? 'Критическая' : 'Critical', val: 'critical' },
      { text: currentLocale === 'ru-RU' ? 'Высокая' : 'High', val: 'high' },
      { text: currentLocale === 'ru-RU' ? 'Средняя' : 'Medium', val: 'medium' },
      { text: currentLocale === 'ru-RU' ? 'Низкая' : 'Low', val: 'low' },
    ],
    [],
  );

  const perimeterImpactText = useMemo(
    () => [
      { text: currentLocale === 'ru-RU' ? 'Да' : 'Yes', val: 1 },
      { text: currentLocale === 'ru-RU' ? 'Нет' : ' No', val: 0.5 },
    ],
    [],
  );

  const [selectedHostType, setSelectedHostType] = useState<
    | {
        text: string;
        val: string;
      }
    | undefined
  >(hostSubType[0]);

  const [selectedPerimeterImpactText, setSelectedPerimeterImpactText] = useState<
    | {
        text: string;
        val: number;
      }
    | undefined
  >(perimeterImpactText[0]);

  const [selectedImportance, setSelectedImportance] = useState<
    | {
        text: string;
        val: string;
      }
    | undefined
  >(hostImportance[1]);

  const [perimeterImpact, setPerimeterImpact] = useState(true);

  const [errCreateHostMutation, setErrCreateHostMutation] = useState('');

  const [isShowImportance, setShowImportance] = useState(false);

  const [isShowHostType, setShowHostType] = useState(false);

  const [isShowPerimeterImpact, setShowPerimeterImpact] = useState(false);

  const [mutableAssetProps, setMutableAssetProps] = useState<{
    assetId: string;
    type: string;
    accountId: string | undefined;
  } | null>(null);

  const [isSavedImportance, setSavedImportance] = useState(false);

  const [isSavedHostType, setSavedHostType] = useState(false);

  const [isSavedPerimeterImpact, setSavedPerimeterImpact] = useState(false);

  // tags down
  const [tagName, setTagName] = useState<string>('');

  const [tagDataForDel, setTagDataForDel] = useState<null | { tagId: string; tagName: string }>(
    null,
  );

  const [isShowAddTag, setShowAddTag] = useState(false);

  const [isTagMenu, setTagMenu] = useState(false);

  const [editTagData, setEditTagData] = useState<{ tagId: string; indexEl: number } | null>(null);

  const [editTagColor, setEditTagColor] = useState<IChangeColorTag | null>(null);

  const [hovered, setHovered] = useState<string>('');

  const [keyValue, setKeyValue] = useState<number | null>(null);

  const { setTagListInHostList, setMissedKbs } = useHostModalContext();

  // tags up

  const [selectedVulns, setSelectedVulns] = useState<ISelectedVulns[] | null>(null);

  const [isAddVulnToWLForm, setAddVulnToWLForm] = useState(false);

  const clearAddHost = useCallback((): void => {
    setSelectedHostType(hostSubType[0]);
    setSelectedImportance(hostImportance[1]);
    setSelectedPerimeterImpactText(perimeterImpactText[0]);
    setErrCreateHostMutation('');
  }, [hostImportance, hostSubType, perimeterImpactText]);

  const prepareAssetData = useCallback(
    (
      setShowValue: (v: boolean) => void,
      booleanVal: boolean,
      data:
        | AssetHostDashboardResponse
        | AssetRouterDashboardResponse
        | AssetFirewallDashboardResponse
        | AssetSwitchDashboardResponse
        | AssetImageDashboardResponse
        | AssetHypervisorDashboardResponse
        | undefined,
    ): void => {
      setShowValue(!booleanVal);
      const assetData = {
        assetId: data!.assetId,
        type: data!.type === 'image' ? 'host' : data!.type,
        accountId: data?.accountId,
      };
      setMutableAssetProps(assetData);
    },
    [],
  );

  const closeTagWindow = useCallback(
    (e: any): void => {
      if (
        e.target &&
        e.target.nodeName !== 'IMG' &&
        e.target.nodeName !== 'SPAN' &&
        e.target.nodeName !== 'BUTTON' &&
        !isShowAddTag &&
        e.target.id !== 'tag'
      ) {
        setShowAddTag(false);
        setTagMenu(false);
        // setEditTagData(null);
        setTagListInHostList('');
      }
    },
    [isShowAddTag],
  );

  const handleBgColor = (
    event: any,
    setBgColor: (value: React.SetStateAction<string>) => void,
  ): void => {
    const rgba = event.value.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
    const hex = `${(
      (1 << 24) +
      (parseInt(rgba[0]) << 16) +
      (parseInt(rgba[1]) << 8) +
      parseInt(rgba[2])
    )
      .toString(16)
      .slice(1)}`;
    setBgColor(`#${hex}`);
    // chooseColor(hex);
  };

  const chooseInColorPicker = (
    e: React.MouseEvent,
    chooseColor: (color: string) => void,
    bgColor: string,
  ): void => {
    e.stopPropagation();
    const { target } = e;

    if (
      target instanceof HTMLElement &&
      !target.className.includes('editTagColorElement') &&
      target.className.includes('editTagColor')
    ) {
      chooseColor(bgColor.slice(1));
    }
  };

  const delFromTheSelectedVuln = useCallback(
    (vulnId: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      event.stopPropagation();
      if (selectedVulns && selectedVulns.length > 0) {
        setSelectedVulns(selectedVulns?.filter((v) => v.vulnId !== vulnId));
      }
      if (selectedVulns && selectedVulns.length > 0) {
        setSelectedVulns(selectedVulns?.filter((v) => v.vulnId !== vulnId));
      }
    },
    [selectedVulns],
  );

  const closeWhenClickButtonX = useCallback((): void => {
    setAddVulnToWLForm(false);
    setSelectedVulns(null);
    setMissedKbs(null);
  }, [setMissedKbs]);

  const addThisVulnToWl = useCallback((id: string): void => {
    setSelectedVulns([{ vulnId: id || '' }]);
    setAddVulnToWLForm(true);
  }, []);

  const addThisKbToWl = useCallback(
    (id: string): void => {
      setMissedKbs([{ id, title: '' }]);
      setAddVulnToWLForm(true);
    },
    [setMissedKbs],
  );

  const value = useMemo(
    () => ({
      hostSubType,
      selectedHostType,
      selectedImportance,
      hostImportance,
      perimeterImpact,
      setPerimeterImpact,
      errCreateHostMutation,
      setErrCreateHostMutation,
      setSelectedHostType,
      setSelectedImportance,
      isShowImportance,
      setShowImportance,
      isShowHostType,
      setShowHostType,
      mutableAssetProps,
      setMutableAssetProps,
      isSavedImportance,
      setSavedImportance,
      setSavedHostType,
      isSavedHostType,
      isSavedPerimeterImpact,
      setSavedPerimeterImpact,
      perimeterImpactText,
      isShowPerimeterImpact,
      setShowPerimeterImpact,
      selectedPerimeterImpactText,
      setSelectedPerimeterImpactText,
      clearAddHost,
      prepareAssetData,
      tagDataForDel,
      setTagDataForDel,
      isShowAddTag,
      setShowAddTag,
      isTagMenu,
      setTagMenu,
      editTagData,
      setEditTagData,
      tagName,
      setTagName,
      editTagColor,
      setEditTagColor,
      closeTagWindow,
      handleBgColor,
      chooseInColorPicker,
      hovered,
      setHovered,
      keyValue,
      setKeyValue,
      selectedVulns,
      setSelectedVulns,
      isAddVulnToWLForm,
      setAddVulnToWLForm,
      delFromTheSelectedVuln,
      closeWhenClickButtonX,
      addThisVulnToWl,
      addThisKbToWl,
    }),
    [
      hostSubType,
      selectedHostType,
      selectedImportance,
      hostImportance,
      perimeterImpact,
      errCreateHostMutation,
      isShowImportance,
      isShowHostType,
      mutableAssetProps,
      isSavedImportance,
      isSavedHostType,
      isSavedPerimeterImpact,
      perimeterImpactText,
      isShowPerimeterImpact,
      selectedPerimeterImpactText,
      clearAddHost,
      prepareAssetData,
      tagDataForDel,
      isShowAddTag,
      isTagMenu,
      editTagData,
      tagName,
      editTagColor,
      closeTagWindow,
      hovered,
      keyValue,
      selectedVulns,
      isAddVulnToWLForm,
      delFromTheSelectedVuln,
      closeWhenClickButtonX,
      addThisVulnToWl,
      addThisKbToWl,
    ],
  );

  return (
    <AssetCreatePropContext.Provider value={value}>{children}</AssetCreatePropContext.Provider>
  );
}
export const useAssetCreatePropContext = (): IAssetPropCreate => {
  const context = useContext(AssetCreatePropContext);
  if (context === null) {
    throw new Error('AssetCreatePropContext must be used inside the ModalContext.Provider.');
  }

  return context;
};
