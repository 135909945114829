import { TextFilter } from '@progress/kendo-react-data-tools';
import {
  FILTER_OPERATORS,
  FILTER_TASK_OPERATORS,
  FILTER_TEXT_CONTAINS,
} from './constant-filter-operators';
import { FilterInputCvss } from '../../components/common/filterComponents/FilterInputCvss';
import { FilterInputInteger } from '../../components/common/filterComponents/FilterInputInteger';

export const FILTER_ID_OPERATORS = {
  text: [
    { text: 'grid.filterContainsOperator', operator: 'contains' },
    { text: 'grid.filterNotContainsOperator', operator: 'doesnotcontain' },
  ],
  numeric: [
    { text: 'filter.eqOperator', operator: 'eq' },
    { text: 'filter.notEqOperator', operator: 'neq' },
  ],
};
export const FILTER_REPOSITORY_LIST = {
  'ru-RU': [
    {
      name: 'repositoryName',
      label: 'Имя репозитория',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'scannedAssetImages.imageNames',
      label: 'Имена просканированного образа',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'scannedAssetImages.imageId',
      label: 'ID',
      filter: TextFilter,
      operators: FILTER_ID_OPERATORS.text,
    },
  ],
  'en-EN': [
    {
      name: 'repositoryName',
      label: 'Repository name',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'scannedAssetImages.imageNames',
      label: 'Names of the scanned image',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'scannedAssetImages.imageId',
      label: 'ID',
      filter: TextFilter,
      operators: FILTER_ID_OPERATORS.text,
    },
  ],
};
export const FILTER_IMAGE_REPOSITORY = {
  'ru-RU': [
    {
      name: 'latestAudit.maxCvssScore',
      label: 'CVSS Score',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numericLocal,
    },
    {
      name: 'imageNames[0]',
      label: 'Имена просканированного образа',
      filter: TextFilter,
      operators: FILTER_TEXT_CONTAINS.text,
    },
    {
      name: 'latestAudit.vulnerabilities.count',
      label: 'Уязвимостей',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'imageId',
      label: 'ID образа',
      filter: TextFilter,
      operators: FILTER_TEXT_CONTAINS.text,
    },
  ],
  'en-EN': [
    {
      name: 'latestAudit.maxCvssScore',
      label: 'CVSS Score',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numericLocal,
    },
    {
      name: 'imageNames[0]',
      label: 'Image names',
      filter: TextFilter,
      operators: FILTER_TEXT_CONTAINS.text,
    },
    {
      name: 'latestAudit.vulnerabilities.count',
      label: 'Vulnerabilities',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'imageId',
      label: 'ID',
      filter: TextFilter,
      operators: FILTER_TEXT_CONTAINS.text,
    },
  ],
};
