/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-nested-ternary */
import type { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useCallback, useEffect } from 'react';
import { useAccountModalContext } from '../../../../hooks/useAccountModalContext';

export function CellSelectAccount(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { accountForm, setAccountForm } = useAccountModalContext();

  useEffect(() => {
    if (!accountForm) {
      setAccountForm(accountForm);
    }
  }, []);

  const addAccount = useCallback(
    ({ name, accountId }: { name: string; accountId: string }): void => {
      if (accountForm && accountForm.length > 0) {
        const index = accountForm?.findIndex((a) => a.accountId === accountId);
        if (index !== -1) {
          const cutAccountForm = accountForm.filter((a) => a.accountId !== accountId);
          setAccountForm([...cutAccountForm]);
        } else {
          setAccountForm([
            ...accountForm,
            {
              accountId,
              name,
            },
          ]);
        }
      } else {
        setAccountForm([
          {
            accountId: dataItem.accountId,
            name: dataItem.name,
          },
        ]);
      }
    },
    [accountForm, setAccountForm, dataItem.accountId, dataItem.name],
  );

  return (
    <td>
      <Checkbox
        checked={!!accountForm?.find((a) => a.accountId === dataItem.accountId)}
        onClick={(): void =>
          addAccount({
            name: dataItem.name,
            accountId: dataItem.accountId,
          })
        }
      />
    </td>
  );
}
