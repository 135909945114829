import { Dialog } from '@progress/kendo-react-dialogs';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { useCallback, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Button } from '../../../common/baseElements/Button';
import { TextField } from '../../../common/form/TextField';
import localeAccount from '../../../../utils/i18n/accountLocales/accountList.json';
import localeErrorMessage from '../../../../utils/i18n/commonLocales/errorMessage.json';
import { handleError } from '../../../../utils/errors';
import { textValidator } from '../../../../utils/helpers/validation';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { useNotificationContext } from '../../../../hooks/useNotificationContext';
import type { FormDataValue } from '../../../../utils/helpers/types';
import { createAccount, editAccount } from '../../../../services/account-service';
import type { AccountCreationResponse } from '../../../../types/__generated/on-premise-solution/api/accountCreationResponse.v1';
import type { AccountCreationRequest } from '../../../../types/__generated/on-premise-solution/api/accountCreationRequest.v1';
import { useAccountModalContext } from '../../../../hooks/useAccountModalContext';
import type { AccountUpdateResponse } from '../../../../types/__generated/on-premise-solution/api/accountUpdateResponse.v1';
import { FormInput } from '../../../common/form/FormInput';

const currentLocale =
  ((window.navigator.language === 'ru-RU' || window.navigator.language === 'ru'
    ? 'ru-RU'
    : 'en-EN') as keyof typeof localeAccount) ||
  ((window.navigator.language === 'ru-RU' || window.navigator.language === 'ru'
    ? 'ru-RU'
    : 'en-EN') as keyof typeof localeErrorMessage);
export function AccountAddEdit(): React.ReactElement {
  const { setOpen, isEdit, setEdit, accountData, setAccountData, name, setName } =
    useAccountModalContext();

  const { createNotification } = useNotificationContext();

  const queryClient = useQueryClient();

  const mutationNewAccount = useMutation<AccountCreationResponse, ApiError, AccountCreationRequest>(
    (payload) => createAccount(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('accountList');
        setOpen(false);
      },
    },
  );

  const mutationEditAccount = useMutation<AccountUpdateResponse, ApiError, AccountUpdateResponse>(
    (payload) => editAccount(payload),
  );

  useEffect(() => {
    if (mutationEditAccount.error) {
      handleError(mutationEditAccount.error, createNotification);
    }
  }, [mutationEditAccount.isError, mutationEditAccount.error, createNotification]);

  useEffect(() => {
    if (mutationNewAccount.error) {
      handleError(mutationNewAccount.error, createNotification);
    }
  }, [mutationNewAccount.isError, mutationNewAccount.error, createNotification]);

  const handleClose = (): void => {
    setOpen(false);
    setEdit(false);
    setAccountData(null);
  };

  const initialFields = {
    name: accountData?.name || '',
  };

  const handleSubmit = (dataItem: FormDataValue): void => {
    if (isEdit && accountData)
      mutationEditAccount
        .mutateAsync({
          accountId: accountData.accountId,
          name,
        } as unknown as AccountUpdateResponse)
        .then(() => {
          queryClient.invalidateQueries('accountList');
        });
    else
      mutationNewAccount
        .mutateAsync({
          accountId: accountData?.accountId,
          name,
        } as unknown as AccountCreationRequest)
        .then(() => {
          queryClient.invalidateQueries('accountList');
        });

    handleClose();
  };

  const checkLength = useCallback(
    (value: string): string => {
      const result = textValidator(
        value,
        localeErrorMessage[currentLocale].form.fillOut,
        localeErrorMessage[currentLocale].form.minLength,
        localeErrorMessage[currentLocale].form.maxLength,
      );

      return result;
    },
    [currentLocale],
  );

  return (
    <Dialog
      title={
        isEdit
          ? `${localeAccount[currentLocale].modal.editTitle} "${accountData?.name}"`
          : localeAccount[currentLocale].modal.addTitle
      }
      onClose={handleClose}
      className="small"
    >
      <Form
        onSubmit={handleSubmit}
        initialValues={initialFields}
        render={(formRenderProps: FormRenderProps): JSX.Element => (
          <FormElement>
            <div className="form-content">
              {/* <Field
                name="name"
                component={TextField}
                label={localeAccount[currentLocale].modal.name}
                validator={checkLength}
              /> */}
              <Field
                name="name"
                component={FormInput}
                id="name"
                label={localeAccount[currentLocale].modal.name}
                onChange={(e): void => {
                  setName(e.target.value);
                }}
                inputValue={name}
                className="form-field-wrapper"
                classForLabel="required-label"
                customValidator={checkLength}
                type="text"
                required
              />
            </div>
            <div className="k-form-buttons">
              <Button
                disabled={!formRenderProps.allowSubmit || !formRenderProps.valid}
                type="submit"
              >
                {isEdit
                  ? localeAccount[currentLocale].modal.btnEdit
                  : localeAccount[currentLocale].modal.btnAdd}
              </Button>
              <Button onClick={handleClose}>{localeAccount[currentLocale].modal.btnCancel}</Button>
            </div>
          </FormElement>
        )}
      />
    </Dialog>
  );
}
