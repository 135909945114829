import type { GridCellProps } from '@progress/kendo-react-grid';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import localeTaskDescription from '../../../../utils/i18n/taskLocale/taskDescription.json';
import type { TaskDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/tasksDashboardResponse.v1';
import { setAssetGroupData } from '../../../../utils/helpers/task-helpers';
import { ScopeElement } from './ScopeElement';
import { AssetScopeNotification } from './AssetScopeNotification';
import styles from '../TaskList.module.scss';
import { ScopeNotification } from './ScopeNotification';

const currentLocale =
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru'
    ? 'ru-RU'
    : ('en-EN' as keyof typeof localeTaskDescription);

export function AssetScope(props: GridCellProps): React.ReactElement {
  const [isShow, setShow] = useState(false);

  const { dataItem } = props;

  const { scope, type }: TaskDashboardResponse = dataItem;

  const scoreData = setAssetGroupData(scope);

  const data = scoreData.assets
    ?.map((asset) => ({
      id: asset.assetId,
      name:
        // eslint-disable-next-line no-nested-ternary
        'imageNames' in asset && asset.imageNames?.length
          ? asset.imageNames[0]
          : 'hostname' in asset
          ? asset.hostname
          : null,
      taskType: type as string,
      deviceType: asset.type,
    }))
    .concat(
      scoreData.assetGroups?.map((assetGroup) => ({
        id: assetGroup.assetGroupId,
        name: assetGroup.name,
        taskType: 'assetgroup',
        deviceType: undefined,
      })) || [],
    )
    .concat(
      scoreData.registries?.map((asset) => ({
        id: asset.registryId,
        name: asset.name,
        taskType: 'registry',
        deviceType: undefined,
      })) || [],
    );

  return (
    <td>
      <div className={styles.assetscope}>
        {/* <HistoryScopeNotification scoreData={scoreData} taskType={taskType} isShow={isShow} /> */}
        {data && data.length > 3 ? (
          <>
            <div className={styles.assets}>
              {data?.slice(0, 3).map((se) => (
                <ScopeElement element={se} />
              ))}
            </div>
            <span
              onMouseEnter={(): void => setShow(true)}
              onMouseLeave={(): void => setShow(false)}
            >
              <ScopeNotification
                className="scope-notification"
                data={data}
                isShow={isShow}
                sliceValue={3}
              />
              {localeTaskDescription[currentLocale].summary.andMore}
              {data.length - 3}
            </span>
          </>
        ) : (
          data?.map((se) => <ScopeElement element={se} />)
        )}
      </div>
    </td>
  );

  // return (
  //   <td onMouseEnter={(): void => setShow(true)} onMouseLeave={(): void => setShow(false)}>
  //     <div className={styles.assetscope}>
  //       <AssetScopeNotification scoreData={scoreData} type={type} isShow={isShow} />
  //       {scoreData && scoreData.assetGroups && scoreData.assetGroups.length > 2 ? (
  //         <>
  //           <div className={styles.groupname}>
  //             <NavLink to={`/lk/assets/groups/${scoreData.assetGroups[0].assetGroupId}`}>
  //               <span>{scoreData.assetGroups[0].name}</span>
  //             </NavLink>
  //           </div>
  //           <div className={styles.groupname}>
  //             <NavLink to={`/lk/assets/groups/${scoreData.assetGroups[1].assetGroupId}`}>
  //               <span>{scoreData.assetGroups[1].name}</span>
  //             </NavLink>
  //           </div>
  //           <span>
  //             {localeTaskDescription[currentLocale].summary.andMore}{' '}
  //             {scoreData.assetGroups.length - 2}
  //           </span>
  //         </>
  //       ) : (
  //         scoreData &&
  //         scoreData.assetGroups &&
  //         scoreData.assetGroups.length > 0 &&
  //         scoreData.assetGroups.map((a) => (
  //           <div className={styles.groupname} key={a.assetGroupId}>
  //             <NavLink to={`/lk/assets/groups/${a.assetGroupId}`}>
  //               <span>{a.name}</span>
  //             </NavLink>
  //           </div>
  //         ))
  //       )}
  //       {scoreData && type !== 'device' && scoreData.assets && scoreData.assets.length > 2 ? (
  //         <>
  //           <div className={styles.hostname}>
  //             <NavLink to={`/lk/assets/hosts/${scoreData.assets[0].assetId}`}>
  //               <span>{scoreData.assets[0].hostname}</span>
  //             </NavLink>
  //           </div>
  //           <div className={styles.hostname}>
  //             <NavLink to={`/lk/assets/hosts/${scoreData.assets[1].assetId}`}>
  //               <span>{scoreData.assets[1].hostname}</span>
  //             </NavLink>
  //           </div>
  //           <span>
  //             {localeTaskDescription[currentLocale].summary.andMore} {scoreData.assets.length - 2}
  //           </span>
  //         </>
  //       ) : (
  //         scoreData &&
  //         scoreData.assets &&
  //         type !== 'device' &&
  //         scoreData.assets.length > 0 &&
  //         scoreData.assets.map((a) => (
  //           <div className={styles.hostname} key={a.assetId}>
  //             <NavLink to={`/lk/assets/hosts/${a.assetId}`}>
  //               <span>{a.hostname ? a.hostname : a.assetId}</span>
  //             </NavLink>
  //           </div>
  //         ))
  //       )}
  //       {scoreData && type === 'device' && scoreData.assets && scoreData.assets.length > 2 ? (
  //         <>
  //           <div className={styles.hostname}>
  //             <NavLink
  //               to={`/lk/assets/devices/${scoreData.assets[0].type}/${scoreData.assets[0].assetId}`}
  //             >
  //               <span>{scoreData.assets[0].hostname}</span>
  //             </NavLink>
  //           </div>
  //           <div className={styles.hostname}>
  //             <NavLink
  //               to={`/lk/assets/devices/${scoreData.assets[1].type}/${scoreData.assets[1].assetId}`}
  //             >
  //               <span>{scoreData.assets[1].hostname}</span>
  //             </NavLink>
  //           </div>
  //           <span>
  //             {localeTaskDescription[currentLocale].summary.andMore} {scoreData.assets.length - 2}
  //           </span>
  //         </>
  //       ) : (
  //         scoreData &&
  //         scoreData.assets &&
  //         type === 'device' &&
  //         scoreData.assets.length > 0 &&
  //         scoreData.assets.map((a) => (
  //           <div className={styles.hostname} key={a.assetId}>
  //             <NavLink to={`/lk/assets/devices/${a.type}/${a.assetId}`}>
  //               <span>{a.hostname ? a.hostname : a.assetId}</span>
  //             </NavLink>
  //           </div>
  //         ))
  //       )}
  //       {scoreData && scoreData.registries && scoreData.registries.length > 2 ? (
  //         <>
  //           <div className={styles.hostname}>
  //             <NavLink to={`/lk/assets/registries/${scoreData.registries[0].registryId}`}>
  //               <span>{scoreData.registries[0].name}</span>
  //             </NavLink>
  //           </div>
  //           <div className={styles.hostname}>
  //             <NavLink to={`/lk/assets/registries/${scoreData.registries[1].registryId}`}>
  //               <span>{scoreData.registries[1].name}</span>
  //             </NavLink>
  //           </div>
  //           <span>
  //             {localeTaskDescription[currentLocale].summary.andMore}{' '}
  //             {scoreData.registries.length - 2}
  //           </span>
  //         </>
  //       ) : (
  //         scoreData &&
  //         scoreData.registries &&
  //         scoreData.registries.length > 0 &&
  //         scoreData.registries.map((r) => (
  //           <div className={styles.hostname} key={r.registryId}>
  //             <NavLink to={`/lk/assets/registries/${r.registryId}`}>
  //               <span>{r.name ? r.name : r.registryId}</span>
  //             </NavLink>
  //           </div>
  //         ))
  //       )}
  //     </div>
  //   </td>
  // );
}
