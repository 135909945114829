import type { GridCellProps } from '@progress/kendo-react-grid';
import type { AssetRouter } from '../../../../types/__generated/on-premise-solution/api/asset.v1';
import type { AssetRouterDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/assetRouterDashboardResponse.v1';

export function CellDeviceAssetId({
  dataItem: { latestInventorization },
  dataItem: { assetId },
  dataItem: { type },
}: Omit<GridCellProps, 'latestInventorization' | 'assetId' | 'type'> & {
  dataItem: AssetRouter | AssetRouterDashboardResponse;
}): React.ReactElement {
  return (
    <td title={latestInventorization.hostname ? latestInventorization.hostname : assetId}>
      {latestInventorization.hostname || assetId}
    </td>
  );
}
