import type { GridCellProps } from '@progress/kendo-react-grid';
import type { LogResponse } from '../../../../../types/__generated/on-premise-solution/api/logsResponse.v1';

export function CellUserLogCreated(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { createdAt }: LogResponse = dataItem;

  return <td>{createdAt && <span>{new Date(createdAt).toLocaleString()}</span>}</td>;
}
