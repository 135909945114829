import type { GridCellProps } from '@progress/kendo-react-grid';
import type React from 'react';
// import localeUser from '../../../../utils/i18n/userLocales/userList.json';
import locale from '../../../utils/i18n/commonLocales/table.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function StatusCell({ dataItem }: GridCellProps): React.ReactElement {
  return (
    <td>
      {dataItem.isDisabled === false
        ? locale[currentLocale].values.activeStatus
        : locale[currentLocale].values.disabledStatus}
    </td>
  );
}
