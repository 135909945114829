/* eslint-disable @typescript-eslint/naming-convention */
import { Grid, GridColumn as Column, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { orderBy, type SortDescriptor } from '@progress/kendo-data-query';
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import type { AuditWindowsDifference } from '../../../../../types/__generated/on-premise-solution/api/auditDifferenceResponse.v1';
import { PAGEABLE_DATA } from '../../../../../utils/helpers/constants';
import { DiffTypeCell } from '../cells/DiffTypeCell';
import localeAuditTask from '../../../../../utils/i18n/taskLocale/auditTask.json';
import { DiffStandartCell } from '../cells/DiffStandartCell';
import { Input } from '../../../../common/baseElements/Input';
import { Button } from '../../../../common/baseElements/Button';
import localeVmTask from '../../../../../utils/i18n/taskLocale/vmTask.json';
import invLocale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import hostDescriptionLocale from '../../../../../utils/i18n/assetLocales/hostDescription.json';
import { DiffFilters } from '../DiffFilters';
import {
  DiffFilterTypes,
  createFromListInvData,
} from '../../../../../utils/helpers/difference-modal-helper';
import type {
  InventorizationDifferenceLinux,
  InventorizationDifferenceWindows,
} from '../../../../../types/__generated/on-premise-solution/api/inventorizationDifference.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

function createFullData(data: AuditWindowsDifference['software']): any[] {
  const softData: any[] = [];

  Object.entries(data || 0).forEach(([key, value]) => {
    softData.push(
      ...(value?.map((p) => ({
        ...p,
        diffType: key,
      })) || []),
    );
  });

  return softData.sort((a, b) => a.name.localeCompare(b.name));
}

export function DiffSoftwareList({
  data,
}: {
  data:
    | InventorizationDifferenceLinux['software']
    | InventorizationDifferenceWindows['software']
    | undefined;
}): ReactElement {
  const _export = useRef<ExcelExport | null>(null);

  const [page, setPage] = useState({ take: 10, skip: 0 });

  const sort: SortDescriptor[] = [{ field: 'name', dir: 'asc' }];

  const [searchValue, setSearchValue] = useState('');

  const [filter, setFilter] = useState<DiffFilterTypes>('all');

  const softData = useMemo(
    () => createFromListInvData(data as unknown as { new: any[]; noChanges: []; old: any[] }[]),
    [data],
  );
  const [softList, setSoftList] = useState(softData[filter]);

  const filterList = {
    all: `${hostDescriptionLocale[currentLocale].changes.shortFilters.soft.all} (${softData.all.length})`,
    noChanges: `${hostDescriptionLocale[currentLocale].changes.shortFilters.soft.noChanges} (${softData.noChanges.length})`,
    // updated: `${hostDescriptionLocale[currentLocale].changes.shortFilters.soft.updated} (${
    //   softData.updated.length / 2
    // })`,
    installed: `${hostDescriptionLocale[currentLocale].changes.shortFilters.soft.installed} (${softData.installed.length})`,
    removed: `${hostDescriptionLocale[currentLocale].changes.shortFilters.soft.removed} (${softData.removed.length})`,
    changed: `${hostDescriptionLocale[currentLocale].changes.shortFilters.soft.updated} (${
      softData.changed.length / 2
    })`,
  };

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  useEffect(() => {
    setSoftList(
      softData[filter].filter((soft) => {
        if (!soft.name) return false;

        return soft.name.toLowerCase().includes(searchValue.toLowerCase());
      }),
    );
    setPage((prev) => ({ ...prev, skip: 0 }));
  }, [filter, searchValue, data]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.target.value);
  };

  const excelExport = (): void => {
    if (_export.current !== null) {
      _export.current.save(softData.all);
    }
  };

  return (
    <>
      <div className="filter-line">
        <Input
          name="customSearch"
          placeholder={localeAuditTask[currentLocale].customFilterName}
          onChange={handleSearch}
          value={searchValue}
        />
        <Button excel fill="flat" onClick={excelExport}>
          {localeAuditTask[currentLocale].vulnsSoft.table.exportToExcel}
        </Button>
      </div>
      <DiffFilters currentFilter={filter} setFilter={setFilter} filterList={filterList} />
      <ExcelExport ref={_export} fileName={localeVmTask[currentLocale].tabs.softwareList}>
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <Grid
              pageable={PAGEABLE_DATA}
              sortable={false}
              // sort={sort}
              total={softList ? softList.length : 0}
              skip={page.skip}
              take={page.take}
              onPageChange={pageChange}
              data={orderBy(softList || [], sort)?.slice(page.skip, page.take + page.skip)}
              className="no-margin-top diffinventorization__softwaregrid"
              scrollable="none"
            >
              <Column
                field="diffType"
                cell={DiffTypeCell}
                title=" "
                width={20}
                sortable={false}
                headerClassName="no-cursor"
              />
              <Column
                field="name"
                title={invLocale[currentLocale].softwareLin.name}
                cell={DiffStandartCell}
                sortable={false}
                headerClassName="no-cursor"
              />
              <Column
                field="version"
                title={invLocale[currentLocale].softwareWin.version}
                width="160"
                cell={DiffStandartCell}
                headerClassName="no-cursor"
              />
              <Column
                field="publisher"
                title={invLocale[currentLocale].softwareWin.publisher}
                //   cell={CellPublisher}
                cell={DiffStandartCell}
                headerClassName="no-cursor"
              />
              <Column
                field="installedAt"
                title={invLocale[currentLocale].softwareWin.installedAt}
                //   cell={CellInstalledAtSoftware}
                width="208px"
                cell={DiffStandartCell}
                headerClassName="no-cursor"
              />
              <Column
                field="installLocation"
                title={invLocale[currentLocale].softwareWin.installLocation}
                //   cell={CellWinInstallLocation}
                cell={DiffStandartCell}
                headerClassName="no-cursor"
              />
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      </ExcelExport>
    </>
  );
}
