import { Grid, GridColumn, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useMemo, useState } from 'react';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { LAZY_PAGEABLE_DATA } from '../../../../../utils/helpers/constants';
import { WrapperMenu } from '../../../Task/taskIDetails/iDetailsComponents/WrapperMenu';
import type {
  InventorizationDifferenceFirewall,
  InventorizationDifferenceRouter,
  InventorizationDifferenceSwitch,
} from '../../../../../types/__generated/on-premise-solution/api/inventorizationDifference.v1';
import {
  createFromListInvData,
  IDiffInvElementInfo,
  type DiffFilterTypes,
  defineWrapperStatuses,
} from '../../../../../utils/helpers/difference-modal-helper';
import { DiffTypeCell } from '../cells/DiffTypeCell';
import { DiffStandartCell } from '../cells/DiffStandartCell';
import { DiffFullDateCell } from '../cells/DiffFullDateCell';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function DiffUsersNetDevices({
  data,
  filter,
}: {
  data:
    | InventorizationDifferenceFirewall
    | InventorizationDifferenceRouter
    | InventorizationDifferenceSwitch;
  filter: DiffFilterTypes;
}): React.ReactElement {
  const userData = useMemo(
    () => createFromListInvData(data.users as unknown as IDiffInvElementInfo[], filter),
    [data.users, filter],
  );
  const [page, setPage] = useState({ take: 5, skip: 0 });

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  return userData && userData.length ? (
    <WrapperMenu
      title={locale[currentLocale].users.title}
      diffStatuses={filter === 'all' ? defineWrapperStatuses(data.users) : undefined}
    >
      <Grid
        data={userData?.slice(page.skip, page.take + page.skip)}
        pageable={LAZY_PAGEABLE_DATA}
        scrollable="none"
        total={userData?.length}
        pageSize={page.take}
        skip={page.skip}
        onPageChange={pageChange}
        className="no-margin-top"
      >
        <GridColumn
          field="diffType"
          cell={DiffTypeCell}
          title=" "
          width={20}
          sortable={false}
          headerClassName="no-cursor"
        />
        <GridColumn
          field="name"
          title={locale[currentLocale].usersNetDevices.name}
          cell={DiffStandartCell}
          sortable={false}
          headerClassName="no-cursor"
        />
        <GridColumn
          field="group"
          title={locale[currentLocale].usersNetDevices.group}
          cell={DiffStandartCell}
          sortable={false}
          headerClassName="no-cursor"
        />
        <GridColumn
          field="lastLoggedIn"
          title={locale[currentLocale].usersNetDevices.lastLoggedIn}
          cell={DiffFullDateCell}
          sortable={false}
          headerClassName="no-cursor"
        />
      </Grid>
    </WrapperMenu>
  ) : (
    <></>
  );
}
