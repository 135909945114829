import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { WrapperMenu } from '../../../Task/taskIDetails/iDetailsComponents/WrapperMenu';
import type {
  InventorizationDifferenceRouter,
  InventorizationDifferenceSwitch,
} from '../../../../../types/__generated/on-premise-solution/api/inventorizationDifference.v1';
import {
  prepareDiffObjectData,
  type DiffFilterTypes,
} from '../../../../../utils/helpers/difference-modal-helper';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function DiffCiscoHardwareInfo({
  data,
  filter,
}: {
  data: InventorizationDifferenceSwitch | InventorizationDifferenceRouter;
  filter: DiffFilterTypes;
}): React.ReactElement {
  const hardwareData = prepareDiffObjectData(
    {
      closed: data.hardware_info?.old,
      noChanges: data.hardware_info?.noChanges,
      updated: data.hardware_info?.new,
    },
    filter,
  );

  const isHardwareWrapperShow =
    Object.keys(hardwareData).length &&
    ((data && data.type === 'router' && data.class === 'cisco') ||
      (data && data.type === 'switch' && data.class === 'aruba'));

  const wrapperStatuses = [];
  if (filter === 'all' && Object.keys(data.hardware_info?.old || {}).length)
    wrapperStatuses.push('closed');
  if (filter === 'all' && Object.keys(data.hardware_info?.new || {}).length)
    wrapperStatuses.push('new');
  if (filter === 'all' && Object.keys(data.hardware_info?.noChanges || {}).length)
    wrapperStatuses.push('noChanges');

  return isHardwareWrapperShow ? (
    <WrapperMenu
      title={locale[currentLocale].ciscoHardwareInfo.hardware}
      diffStatuses={filter === 'all' ? wrapperStatuses : undefined}
    >
      {data &&
        data.type === 'router' &&
        data.class === 'cisco' &&
        ('model' in hardwareData ||
          'motherboardRevision' in hardwareData ||
          'motherboardAssembly' in hardwareData ||
          'softwareImage' in hardwareData ||
          'daugheboardAssembly' in hardwareData ||
          'daugheboardSerial' in hardwareData ||
          'topAssemblyPart' in hardwareData ||
          'versionId' in hardwareData) && (
          <table className="column">
            {'model' in hardwareData && (
              <>
                {hardwareData.model.map((hardwareModel) => (
                  <tr>
                    <td className={hardwareModel.className}>
                      {locale[currentLocale].ciscoHardwareInfo.model}
                    </td>
                    <td className={hardwareModel.className}>{hardwareModel.value || '-'}</td>
                  </tr>
                ))}
              </>
            )}
            {'motherboardRevision' in hardwareData && (
              <>
                {hardwareData.motherboardRevision.map((hardwareMotherboardRevision) => (
                  <tr>
                    <td className={hardwareMotherboardRevision.className}>
                      {locale[currentLocale].ciscoHardwareInfo.motherboardRevision}
                    </td>
                    <td className={hardwareMotherboardRevision.className}>
                      {hardwareMotherboardRevision.value || '-'}
                    </td>
                  </tr>
                ))}
              </>
            )}
            {'motherboardAssembly' in hardwareData && (
              <>
                {hardwareData.motherboardAssembly.map((hardwareMotherboardAssembly) => (
                  <tr>
                    <td className={hardwareMotherboardAssembly.className}>
                      {locale[currentLocale].ciscoHardwareInfo.motherboardAssembly}
                    </td>
                    <td className={hardwareMotherboardAssembly.className}>
                      {hardwareMotherboardAssembly.value || '-'}
                    </td>
                  </tr>
                ))}
              </>
            )}
            {'softwareImage' in hardwareData && (
              <>
                {hardwareData.softwareImage.map((hardwareSoftImage) => (
                  <tr>
                    <td className={hardwareSoftImage.className}>
                      {locale[currentLocale].ciscoHardwareInfo.softwareImage}
                    </td>
                    <td className={hardwareSoftImage.className}>
                      {hardwareSoftImage.value || '-'}
                    </td>
                  </tr>
                ))}
              </>
            )}
            {'daugheboardAssembly' in hardwareData && (
              <>
                {hardwareData.daugheboardAssembly.map((hardwareDaugheboardAssembly) => (
                  <tr>
                    <td className={hardwareDaugheboardAssembly.className}>
                      {locale[currentLocale].ciscoHardwareInfo.daugheboardAssembly}
                    </td>
                    <td className={hardwareDaugheboardAssembly.className}>
                      {hardwareDaugheboardAssembly.value || '-'}
                    </td>
                  </tr>
                ))}
              </>
            )}
            {'daugheboardSerial' in hardwareData && (
              <>
                {hardwareData.daugheboardSerial.map((hardwareDaugheboardSerial) => (
                  <tr>
                    <td className={hardwareDaugheboardSerial.className}>
                      {locale[currentLocale].ciscoHardwareInfo.daugheboardSerial}
                    </td>
                    <td className={hardwareDaugheboardSerial.className}>
                      {hardwareDaugheboardSerial.value || '-'}
                    </td>
                  </tr>
                ))}
              </>
            )}
            {'topAssemblyPart' in hardwareData && (
              <>
                {hardwareData.topAssemblyPart.map((hardwareTopAssemblyPart) => (
                  <tr>
                    <td className={hardwareTopAssemblyPart.className}>
                      {locale[currentLocale].ciscoHardwareInfo.topAssemblyPart}
                    </td>
                    <td className={hardwareTopAssemblyPart.className}>
                      {hardwareTopAssemblyPart.value || '-'}
                    </td>
                  </tr>
                ))}
              </>
            )}
            {'versionId' in hardwareData && (
              <>
                {hardwareData.versionId.map((hardwareVersionId) => (
                  <tr>
                    <td className={hardwareVersionId.className}>
                      {locale[currentLocale].ciscoHardwareInfo.versionId}
                    </td>
                    <td className={hardwareVersionId.className}>
                      {hardwareVersionId.value || '-'}
                    </td>
                  </tr>
                ))}
              </>
            )}
          </table>
        )}
      {data &&
        data.type === 'router' &&
        data.class === 'cisco' &&
        ('mac' in hardwareData ||
          'ports' in hardwareData ||
          'motherboardSerial' in hardwareData ||
          'modelRevision' in hardwareData ||
          'hardwareBoardRevision' in hardwareData ||
          'systemSerial' in hardwareData ||
          'topAssemblyRevision' in hardwareData ||
          'cleiCode' in hardwareData) && (
          <table className="column">
            {'mac' in hardwareData && (
              <>
                {hardwareData.mac.map((hardwareMac) => (
                  <tr>
                    <td className={hardwareMac.className}>
                      {locale[currentLocale].ciscoHardwareInfo.mac}
                    </td>
                    <td className={hardwareMac.className}>{hardwareMac.value || '-'}</td>
                  </tr>
                ))}
              </>
            )}
            {'ports' in hardwareData && (
              <>
                {hardwareData.ports.map((hardwarePorts) => (
                  <tr>
                    <td className={hardwarePorts.className}>
                      {locale[currentLocale].ciscoHardwareInfo.ports}
                    </td>
                    <td className={hardwarePorts.className}>{hardwarePorts.value || '-'}</td>
                  </tr>
                ))}
              </>
            )}
            {'motherboardSerial' in hardwareData && (
              <>
                {hardwareData.motherboardSerial.map((hardwareMotherboardSerial) => (
                  <tr>
                    <td className={hardwareMotherboardSerial.className}>
                      {locale[currentLocale].ciscoHardwareInfo.motherboardSerial}
                    </td>
                    <td className={hardwareMotherboardSerial.className}>
                      {hardwareMotherboardSerial.value || '-'}
                    </td>
                  </tr>
                ))}
              </>
            )}
            {'modelRevision' in hardwareData && (
              <>
                {hardwareData.modelRevision.map((hardwareModelRevision) => (
                  <tr>
                    <td className={hardwareModelRevision.className}>
                      {locale[currentLocale].ciscoHardwareInfo.modelRevision}
                    </td>
                    <td className={hardwareModelRevision.className}>
                      {hardwareModelRevision.value || '-'}
                    </td>
                  </tr>
                ))}
              </>
            )}
            {'hardwareBoardRevision' in hardwareData && (
              <>
                {hardwareData.hardwareBoardRevision.map((hardwareBoardRevision) => (
                  <tr>
                    <td className={hardwareBoardRevision.className}>
                      {locale[currentLocale].ciscoHardwareInfo.hardwareBoardRevision}
                    </td>
                    <td className={hardwareBoardRevision.className}>
                      {hardwareBoardRevision.value || '-'}
                    </td>
                  </tr>
                ))}
              </>
            )}
            {'systemSerial' in hardwareData && (
              <>
                {hardwareData.systemSerial.map((hardwareSystemSerial) => (
                  <tr>
                    <td className={hardwareSystemSerial.className}>
                      {locale[currentLocale].ciscoHardwareInfo.systemSerial}
                    </td>
                    <td className={hardwareSystemSerial.className}>
                      {hardwareSystemSerial.value || '-'}
                    </td>
                  </tr>
                ))}
              </>
            )}
            {'topAssemblyRevision' in hardwareData && (
              <>
                {hardwareData.topAssemblyRevision.map((hardwareTopAssemblyRevision) => (
                  <tr>
                    <td className={hardwareTopAssemblyRevision.className}>
                      {locale[currentLocale].ciscoHardwareInfo.topAssemblyRevision}
                    </td>
                    <td className={hardwareTopAssemblyRevision.className}>
                      {hardwareTopAssemblyRevision.value || '-'}
                    </td>
                  </tr>
                ))}
              </>
            )}
            {'cleiCode' in hardwareData && (
              <>
                {hardwareData.cleiCode.map((hardwareCleiCode) => (
                  <tr>
                    <td className={hardwareCleiCode.className}>
                      {locale[currentLocale].ciscoHardwareInfo.cleiCode}
                    </td>
                    <td className={hardwareCleiCode.className}>{hardwareCleiCode.value || '-'}</td>
                  </tr>
                ))}
              </>
            )}
          </table>
        )}
      {data &&
        data.type === 'switch' &&
        data.class === 'aruba' &&
        ('serialNumber' in hardwareData || 'mac' in hardwareData) && (
          <table className="column">
            {'serialNumber' in hardwareData && (
              <>
                {hardwareData.serialNumber.map((hardwareSerialNumber) => (
                  <tr>
                    <td className={hardwareSerialNumber.className}>
                      {locale[currentLocale].ciscoHardwareInfo.systemSerial}
                    </td>
                    <td className={hardwareSerialNumber.className}>
                      {hardwareSerialNumber.value || '-'}
                    </td>
                  </tr>
                ))}
              </>
            )}
            {'mac' in hardwareData && (
              <>
                {hardwareData.mac.map((hardwareMac) => (
                  <tr>
                    <td className={hardwareMac.className}>
                      {locale[currentLocale].ciscoHardwareInfo.mac}
                    </td>
                    <td className={hardwareMac.className}>{hardwareMac.value || '-'}</td>
                  </tr>
                ))}
              </>
            )}
          </table>
        )}
    </WrapperMenu>
  ) : (
    <></>
  );
}
