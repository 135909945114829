import { ChangeEvent, useState } from 'react';
import type { TextFilterProps } from '@progress/kendo-react-data-tools/dist/npm/filter/filters/TextFilter';
import locale from '../../../utils/i18n/filterComponent/inputFilter.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;
export function FilterInputCvssLocal(props: TextFilterProps): React.ReactElement {
  const { filter, onFilterChange } = props;

  const [value, setValue] = useState<number | string>('');

  const changeDate = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value && Number(event.target.value) >= 0 && Number(event.target.value) <= 10) {
      onFilterChange({
        nextFilter: { ...filter, ...{ value: Number(event.target.value), text: '' } },
      });
      setValue(Number(event.target.value));
    } else if (event.target.value && Number(event.target.value) > 10) {
      onFilterChange({
        nextFilter: { ...filter, ...{ value: 10, text: '' } },
      });
      setValue(10);
    } else if (event.target.value && Number(event.target.value) < 0) {
      onFilterChange({
        nextFilter: { ...filter, ...{ value: 0, text: '' } },
      });
      setValue(0);
    } else {
      setValue('');
      onFilterChange({
        nextFilter: { ...filter, ...{ value: '', text: '' } },
      });
    }
  };

  return (
    <input
      className="k-input"
      type="number"
      placeholder={locale[currentLocale].inputNumber}
      value={value}
      onChange={(e): void => changeDate(e)}
      step="0.1"
      min="0"
      max="10"
    />
  );
}
