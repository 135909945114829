import { Dialog } from '@progress/kendo-react-dialogs';
import localeHost from '../../../../../utils/i18n/assetLocales/hostList.json';
import '../AssetList.module.scss';
import { useHostModalContext } from '../../../../../hooks/useHostModalContext';
import { useCreateTask } from '../../../../../hooks/components/useCreateTask';
import { useAuditModalContext } from '../../../../../hooks/useAuditModalContext';
import { Button } from '../../../../common/baseElements/Button';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHost;
export function ModalHostDetailById(): React.ReactElement {
  const { setHostDetailById, hostDetailById } = useHostModalContext();

  const { createTaskForAsset } = useCreateTask();

  const { setCveName } = useAuditModalContext();

  const createTaskByAssetId = (): void => {
    createTaskForAsset({
      val: 'agent_update',
      assetId: hostDetailById || undefined,
    });
    setHostDetailById(null);
    setCveName(null);
  };

  return (
    <Dialog
      className="small"
      title={localeHost[currentLocale].waitingDetailsTitle}
      onClose={(): void => setHostDetailById(null)}
    >
      <div className="k-form">
        <div className="form-content host-inv-confirm">
          <p>{localeHost[currentLocale].waitingDetailsInfo0}</p>
          <p>{localeHost[currentLocale].waitingDetailsInfo1}</p>
          <p>{localeHost[currentLocale].waitingDetailsInfo2}</p>
          <p>{localeHost[currentLocale].waitingDetailsInfo3}</p>
          <p>{localeHost[currentLocale].waitingDetailsInfo5}</p>
          <p>{localeHost[currentLocale].waitingDetailsInfo6}</p>
        </div>

        <div className="k-form-buttons">
          <Button type="submit" onClick={createTaskByAssetId}>
            {localeHost[currentLocale].create}
          </Button>
          <Button type="button" onClick={(): void => setHostDetailById(null)}>
            {localeHost[currentLocale].canсel}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
