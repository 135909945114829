import type { ReactElement } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import type { InventorizationResponse } from '../../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import { WrapperMenu } from '../../../Task/taskIDetails/iDetailsComponents/WrapperMenu';
import {
  DiffFilterTypes,
  prepareDiffObjectData,
} from '../../../../../utils/helpers/difference-modal-helper';
import type {
  InventorizationDifferenceFirewall,
  InventorizationDifferenceRouter,
  InventorizationDifferenceSwitch,
} from '../../../../../types/__generated/on-premise-solution/api/inventorizationDifference.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function DiffDiskNetDevices({
  data,
  filter,
}: {
  data:
    | InventorizationDifferenceFirewall
    | InventorizationDifferenceSwitch
    | InventorizationDifferenceRouter;
  filter: DiffFilterTypes;
}): ReactElement {
  const diskData = prepareDiffObjectData(
    { closed: data.disk?.old, noChanges: data.disk?.noChanges, updated: data.disk?.new },
    filter,
  );

  const wrapperStatuses = [];
  if (filter === 'all' && Object.keys(data.disk?.old || {}).length) wrapperStatuses.push('closed');
  if (filter === 'all' && Object.keys(data.disk?.new || {}).length) wrapperStatuses.push('new');
  if (filter === 'all' && Object.keys(data.disk?.noChanges || {}).length)
    wrapperStatuses.push('noChanges');

  return Object.keys(diskData).length ? (
    <WrapperMenu
      title={locale[currentLocale].disks.title}
      diffStatuses={filter === 'all' ? wrapperStatuses : undefined}
    >
      {('total' in diskData || 'free' in diskData || 'badBlocks' in diskData) && (
        <table className="column">
          {'total' in diskData && (
            <>
              {diskData.total.map((diskTotal) => (
                <tr>
                  <td className={diskTotal.className}>
                    {locale[currentLocale].memoryNetDevices.total}
                  </td>
                  <td className={diskTotal.className}>
                    {diskTotal.value || diskTotal.value === 0
                      ? `${Math.round(Number(diskTotal.value) / 1024 / 1024)} ${
                          locale[currentLocale].memoryNetDevices.sizeMb
                        }`
                      : '-'}
                  </td>
                </tr>
              ))}
            </>
          )}
          {'free' in diskData && (
            <>
              {diskData.free.map((diskFree) => (
                <tr>
                  <td className={diskFree.className}>
                    {locale[currentLocale].memoryNetDevices.free}
                  </td>
                  <td className={diskFree.className}>
                    {diskFree.value || diskFree.value === 0
                      ? `${Math.round(Number(diskFree.value) / 1024 / 1024)} ${
                          locale[currentLocale].memoryNetDevices.sizeMb
                        }`
                      : '-'}
                  </td>
                </tr>
              ))}
            </>
          )}
          {'badBlocks' in diskData && (
            <>
              {diskData.free.map((diskBadBlocks) => (
                <tr>
                  <td className={diskBadBlocks.className}>Bad blocks</td>
                  <td className={diskBadBlocks.className}>
                    {diskBadBlocks.value ? diskBadBlocks.value : '-'}
                  </td>
                </tr>
              ))}
            </>
          )}
        </table>
      )}
    </WrapperMenu>
  ) : (
    <></>
  );
}
