/* eslint-disable no-useless-escape */
import localeLogin from '../../i18n/loginLocale/loginForm.json';
import localeErrorMessage from '../../i18n/commonLocales/errorMessage.json';
import UserLoginRequestJson from '../../../types/__generated/on-premise-solution/api/userLoginRequest.v1.schema.json';
import { regExpSpecSymbols } from '../constants';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeLogin;

// const emailRegex = /\S+@\S+\.\S{2,}/;
// /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^*<>()[\].,;:\s@"]{2,})$/iu;
// const emailRegex =
//   /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+)+\.[^*<>()[\].,;:\s@"]{2,3}?)$/iu;

const emailRegex = /^([\w\-\.])+@([\w\-\.])+\.([A-Za-z]{2,24}?)$/iu;
export const emailValidator = (value: string): string =>
  emailRegex.test(value) ? '' : localeLogin[currentLocale].emailError;
export const passwordValidator = (value: string): string => {
  // if (!value || value.length < UserLoginRequestJson.properties.password.minLength)
  const { minLength } = UserLoginRequestJson.oneOf[0].properties.password;
  if (!value || value.length < minLength)
    return `${localeLogin[currentLocale].minimumPassLength} ${minLength}`;
  // if (value.length > 12) return 'Maximum length is 12 symbols';

  return '';
};
export const textValidator = (
  value: string,
  errorMessage: string,
  errorMinLegth: string,
  errorMaxLegth: string,
): string => {
  if (!value) return errorMessage;
  if (value.length < 1) return errorMinLegth;
  if (value.length > 255) return errorMaxLegth;

  return '';
};

// export function checkTaskName(value: string): string {
//   if (!value) return localeErrorMessage[currentLocale].form.fillOut;
//   if (value.length < 1) return localeErrorMessage[currentLocale].form.minLength;
//   if (value.length > 255) return localeErrorMessage[currentLocale].form.maxLength;
//   if (!value.search(regExpSpecSymbols)) return localeErrorMessage[currentLocale].form.invalidSymbol;

//   return '';
// }
export function checkTaskName(
  e: React.FormEvent<HTMLInputElement>,
  setError: (value: React.SetStateAction<string>) => void,
): void {
  if (!e.currentTarget.value) setError(localeErrorMessage[currentLocale].form.fillOut);
  if (e.currentTarget.value.length < 1) setError(localeErrorMessage[currentLocale].form.minLength);
  if (e.currentTarget.value.length > 255)
    setError(localeErrorMessage[currentLocale].form.maxLength);
  if (!e.currentTarget.value.search(regExpSpecSymbols))
    setError(localeErrorMessage[currentLocale].form.invalidSymbol);
}

export const checkLength = (
  e: React.FormEvent<HTMLInputElement>,
  setError: (value: React.SetStateAction<string>) => void,
): void => {
  const result = textValidator(
    e.currentTarget.value,
    localeErrorMessage[currentLocale].form.fillOut,
    localeErrorMessage[currentLocale].form.minLength,
    localeErrorMessage[currentLocale].form.maxLength,
  );

  setError(result);
};

export const checkCaptchaField = (
  e: React.FormEvent<HTMLInputElement>,
  setError: (value: React.SetStateAction<string>) => void,
): void => {
  if (e.currentTarget.value.length < 6)
    setError(localeErrorMessage[currentLocale].form.invalidCaptchaLenght);
};

export const checkEmail = (
  e: React.FormEvent<HTMLInputElement>,
  setError: (value: React.SetStateAction<string>) => void,
): void => {
  const result = emailRegex.test(e.currentTarget.value)
    ? ''
    : localeLogin[currentLocale].emailError;

  setError(result);
};

export const checkPassword = (
  e: React.FormEvent<HTMLInputElement>,
  setError: (value: React.SetStateAction<string>) => void,
): void => {
  const { minLength } = UserLoginRequestJson.oneOf[0].properties.password;
  if (!e.currentTarget.value || e.currentTarget.value.length < minLength) {
    setError(`${localeLogin[currentLocale].minimumPassLength} ${minLength}`);
  }
};

// export const inputValidator = (value: string): string => {
//   if (!value) return 'Please enter a text.';
//   if (value.length < 7) return 'Minimum length is 7 symbols';
//   if (value.length > 255) return 'Maximum length is 255';

//   return '';
// };
