import type { GridCellProps } from '@progress/kendo-react-grid';
import { Button } from '../baseElements/Button';
import localeAssetGroup from '../../../utils/i18n/assetLocales/assetGroups.json';
import locale from '../../../utils/i18n/assetLocales/assetDescriptionGroup.json';
import { useGroupHook } from '../../../hooks/components/useGroupHook';
import { useTaskForGroup } from '../../../hooks/components/useTaskForGroup';
import { getUserData } from '../../../services/local-storage-service';
import type { EnrichedAssetGroupResponse } from '../../../types/__generated/on-premise-solution/api/enrichedAssetGroupResponse.v1';
import { DeleteConfirmModal } from '../modal/DeleteConfirmModal';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAssetGroup;

export function CellMenuGroup(props: GridCellProps): React.ReactElement {
  const currentUser = getUserData();

  const { dataItem } = props;

  const { name, description, assetGroupId, assetCount }: EnrichedAssetGroupResponse = dataItem;

  const { isConfirm, setConfirm, editGroup, handleConfirm, handleDelete, isError, errorMessage } =
    useGroupHook(name, description, assetGroupId);

  const createTaskForGroup = useTaskForGroup(name, assetGroupId);

  return (
    <>
      {isConfirm && (
        <DeleteConfirmModal
          name={name}
          type="group"
          onClose={(): void => setConfirm(false)}
          handleDelete={(): void => handleDelete(assetGroupId)}
          errorMessage={isError ? errorMessage : undefined}
          countInside={assetCount}
        />
      )}
      <td className="cell_menu">
        <div className="groups__menu">
          <div className="dropdown__container">
            <span className="k-icon k-i-more-vertical" />
            <div className="dropdown__content">
              <Button
                fill="menu"
                onClick={(): void => {
                  createTaskForGroup('default');
                }}
              >
                {locale[currentLocale].btns.btnCreateTask}
              </Button>
              {currentUser?.role !== 'reader_user' && (
                <Button
                  fill="menu"
                  onClick={editGroup}
                  disabled={
                    currentUser?.role === 'user' && currentUser.id !== dataItem.createdBy.id
                  }
                >
                  {localeAssetGroup[currentLocale].menu.btnEdit}
                </Button>
              )}
              {currentUser?.role !== 'reader_user' && (
                <Button
                  fill="menu"
                  onClick={handleConfirm}
                  disabled={
                    currentUser?.role === 'user' && currentUser.id !== dataItem.createdBy.id
                  }
                >
                  {localeAssetGroup[currentLocale].menu.btnDelete}
                </Button>
              )}
            </div>
          </div>
        </div>
      </td>
    </>
  );
}
