import { Grid, GridColumn, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useMemo, useState } from 'react';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
// import { DeviceTagInfoCell } from './сells/DeviceTagInfoCell';
import { LAZY_PAGEABLE_DATA } from '../../../../../utils/helpers/constants';
import { WrapperMenu } from '../../../Task/taskIDetails/iDetailsComponents/WrapperMenu';
import type {
  InventorizationDifferenceRouter,
  InventorizationDifferenceSwitch,
} from '../../../../../types/__generated/on-premise-solution/api/inventorizationDifference.v1';
import {
  createFromListInvData,
  defineWrapperStatuses,
  type DiffFilterTypes,
  type IDiffInvElementInfo,
} from '../../../../../utils/helpers/difference-modal-helper';
import { DiffTypeCell } from '../cells/DiffTypeCell';
import { DiffStandartCell } from '../cells/DiffStandartCell';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function DiffVlanCommonNetDevice({
  data,
  filter,
}: {
  data: InventorizationDifferenceRouter | InventorizationDifferenceSwitch;
  filter: DiffFilterTypes;
}): React.ReactElement {
  const vlanData = useMemo(
    () => createFromListInvData(data.vlans as unknown as IDiffInvElementInfo[], filter),
    [data.vlans, filter],
  );

  const [page, setPage] = useState({ take: 5, skip: 0 });

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  return vlanData && vlanData.length ? (
    <WrapperMenu
      title={locale[currentLocale].vlan.title}
      diffStatuses={filter === 'all' ? defineWrapperStatuses(data.vlans) : undefined}
    >
      <Grid
        data={vlanData.slice(page.skip, page.take + page.skip)}
        // style={{ maxHeight: '304px' }}
        pageable={LAZY_PAGEABLE_DATA}
        total={vlanData.length}
        pageSize={page.take}
        skip={page.skip}
        onPageChange={pageChange}
        scrollable="none"
        className="no-margin-top"
      >
        <GridColumn
          field="diffType"
          cell={DiffTypeCell}
          title=" "
          width={20}
          sortable={false}
          headerClassName="no-cursor"
        />
        <GridColumn
          field="vlanId"
          title={locale[currentLocale].vlan.vlanId}
          cell={DiffStandartCell}
          sortable={false}
          headerClassName="no-cursor"
        />
        <GridColumn
          field="name"
          title={locale[currentLocale].vlan.name}
          cell={DiffStandartCell}
          sortable={false}
          headerClassName="no-cursor"
        />
        {data.class !== 'eltex' && (
          <GridColumn
            field="ports"
            title={locale[currentLocale].vlan.ports}
            cell={DiffStandartCell}
            className="title"
            sortable={false}
            headerClassName="no-cursor"
          />
        )}
        {data.class !== 'eltex' && (
          <GridColumn
            field="status"
            title={locale[currentLocale].vlan.status}
            cell={DiffStandartCell}
            sortable={false}
            headerClassName="no-cursor"
          />
        )}
        {data.class === 'eltex' && (
          <GridColumn
            field="tagged"
            title={locale[currentLocale].vlan.tagged}
            cell={DiffStandartCell}
            className="title"
            sortable={false}
            headerClassName="no-cursor"
          />
        )}
        {data.class === 'eltex' && (
          <GridColumn
            field="untagged"
            title={locale[currentLocale].vlan.untagged}
            cell={DiffStandartCell}
            sortable={false}
            headerClassName="no-cursor"
          />
        )}
      </Grid>
    </WrapperMenu>
  ) : (
    <></>
  );
}
