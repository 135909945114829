import type { GridCellProps } from '@progress/kendo-react-grid';
import type { AssetImageDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/assetsImagesDashboardResponse.v1';

export function ImageIdInfo(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;
  const { imageId }: AssetImageDashboardResponse = dataItem;
  // const { latestAudit }: AssetImageDashboardResponse = dataItem;

  // return <td>{latestAudit?.layerId.split(':')[1].slice(0, 6) || '-'}</td>;
  return <td title={imageId}>{imageId.split(':')[1].slice(0, 12) || '-'}</td>;
}
