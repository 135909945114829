import type { GridCellProps } from '@progress/kendo-react-grid';
import { Button } from '../../../../../common/baseElements/Button';
import { useAuditModalContext } from '../../../../../../hooks/useAuditModalContext';
import { handleVectorModal } from '../../../../../../utils/helpers/audit-helpers';

export function CellCvssVector(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;
  const { vector } = dataItem;

  const { setCvssVector, setOpen } = useAuditModalContext();

  // decodeVector(dataItem.vector);

  return (
    <td title={vector}>
      {vector && vector !== '-' ? (
        <Button
          fill="modal"
          className="cvss-button"
          onClick={(): void => handleVectorModal(vector, setOpen, setCvssVector)}
        >
          {vector}
        </Button>
      ) : (
        '-'
      )}
    </td>
  );
}
