import type { GridCellProps } from '@progress/kendo-react-grid';

export function CellCwe(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  return (
    <td title={dataItem.cweName ? dataItem.cweName : ''}>
      <span>
        {dataItem.cwe === '-' ? (
          <span>-</span>
        ) : (
          <span>
            {dataItem.cwe}
            {/* : {dataItem.cweName} */}
          </span>
        )}
      </span>
    </td>
  );
}
