import type { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import type { IUrlParamsWls } from './wl-list-helper';

export function fillFiltersWlList(
  urlParams: IUrlParamsWls,
  filterValue: React.MutableRefObject<CompositeFilterDescriptor | null>,
  setFilter: (value: React.SetStateAction<CompositeFilterDescriptor>) => void,
  setFilterStatus: React.Dispatch<React.SetStateAction<boolean>>,
): void {
  console.log('fillFiltersWlList');
  if (urlParams.filterUrlData.name) {
    filterValue.current = {
      filters: [
        {
          field: 'name',
          operator: 'eq',
          value: urlParams.filterUrlData.name,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  } else if (urlParams.filterUrlData.createdByName) {
    setFilterStatus(true);
    filterValue.current = {
      filters: [
        {
          field: 'createdBy.name',
          operator: 'contains',
          value: urlParams.filterUrlData.createdByName,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  } else if (urlParams.filterUrlData.assetIds) {
    setFilterStatus(true);
    filterValue.current = {
      filters: [
        {
          field: 'assetIds',
          operator: 'eq',
          value: urlParams.filterUrlData.assetIds,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  }
}
