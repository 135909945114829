import { useEffect, useState } from 'react';
import { Input } from '../baseElements/Input';
import formStyles from './Form.module.scss';
import { InputTooltipForm } from './InputTooltipForm';
import { PasswordToolTip } from '../../features/User/modals/PasswordToolTip';
import localeUser from '../../../utils/i18n/userLocales/addUserForm.json';
import UserLocalCreationRequest from '../../../types/__generated/on-premise-solution/api/userLocalCreationRequest.v1.schema.json';

interface IInputRenderProps {
  name: string;
  label?: string;
  inputValue: string;
  onChange: ((event: any) => void) | undefined;
  type: string;
  customValidator?: (
    e: React.FormEvent<HTMLInputElement>,
    setError: (value: React.SetStateAction<string>) => void,
  ) => void;
  className?: string;
  placeholder?: string;
  id: string;
  tooltipText?: string;
  min?: number;
  max?: number;
  required?: boolean;
  autoFocus?: boolean;
  classForLabel?: string;
  disabled?: boolean;
}

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeUser;

export function FormNewPassword(inputRenderProps: IInputRenderProps): React.ReactElement {
  const {
    name,
    label,
    inputValue,
    type,
    className,
    placeholder,
    id,
    tooltipText,
    min,
    max,
    required,
    autoFocus,
    classForLabel,
    disabled,
    onChange,
  } = inputRenderProps;

  const [passwordProperties, setPasswordProperties] = useState<{
    isLength: boolean;
    isNumber: boolean;
    isLetter: boolean;
    isCapital: boolean;
    isSpec: boolean;
  }>({
    isLength: false,
    isNumber: false,
    isLetter: false,
    isCapital: false,
    isSpec: false,
  });

  const [error, setError] = useState<string>('');
  const [isFocused, setFocused] = useState(false);

  useEffect(() => {
    setError('');
  }, [inputValue]);

  const checkNewPassword = (
    password: string,
  ): {
    isLength: boolean;
    isNumber: boolean;
    isLetter: boolean;
    isCapital: boolean;
    isSpec: boolean;
  } => {
    return {
      isLength: password.length >= UserLocalCreationRequest.properties.password.minLength,
      // isNumber: password.search(/[0-9]/) !== -1,
      isNumber: /\d/.test(password),
      isLetter: /\p{Ll}/u.test(password),
      isCapital: /\p{Lu}/u.test(password),
      isSpec: /[!@"'№#$%^&*()_+`~[\]|{}:;?><.,/\\=-]/.test(password),
    };
  };

  const onChangeThis = (e: any) => {
    if (onChange) onChange(e);
    setPasswordProperties(checkNewPassword(e.target.value));
  };

  const onFocus = (): void => {
    setFocused(true);
  };

  const onBlur = (e: any): void => {
    const result = Object.values(checkNewPassword(e.target.value)).filter((v) => !v).length;
    setFocused(false);

    if (result) setError(localeUser[currentLocale].errors.passNotSecurityRequirements);
    // if (customValidator) {
    //   customValidator(e, setError);
    // }
  };

  return (
    <div className={className || formStyles.field_wrapper}>
      {isFocused && (
        <PasswordToolTip
          isLength={passwordProperties.isLength}
          isNumber={passwordProperties.isNumber}
          isLetter={passwordProperties.isLetter}
          isCapital={passwordProperties.isCapital}
          isSpec={passwordProperties.isSpec}
        />
      )}
      {tooltipText ? (
        <InputTooltipForm
          tooltipText={tooltipText}
          classForLabel={classForLabel}
          label={label || ''}
          id={id}
        />
      ) : (
        <label className={classForLabel ? `${classForLabel} k-label` : 'k-label'} htmlFor={id}>
          {label}
        </label>
      )}
      <Input
        name={name}
        type={type}
        value={inputValue}
        id={id}
        onChange={onChangeThis}
        onFocus={onFocus}
        onBlur={onBlur}
        maxLength={100}
        className="k-input"
        placeholder={placeholder}
        min={min}
        max={max}
        required={required}
        autoFocus={autoFocus}
        disabled={disabled}
      />
      {error && (
        <div className="error-common-field">
          <span>{error}</span>
        </div>
      )}
    </div>
  );
}
