import type { GridCellProps } from '@progress/kendo-react-grid';
import localeAuditTask from '../../../../../utils/i18n/taskLocale/auditTask.json';
import type { IRebootBehavior } from '../../../../../utils/helpers/types';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;
export function RebootBehaviorCell(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const rebootBehaviorVal: 'never' | 'always' | 'canRequest' = dataItem.rebootBehavior;

  return (
    <td>
      {dataItem.rebootBehavior
        ? (localeAuditTask[currentLocale].rebootBehaviorVal[
            rebootBehaviorVal
          ] as keyof IRebootBehavior)
        : '-'}
    </td>
  );
}
