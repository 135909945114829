/* eslint-disable react/jsx-pascal-case */
import { useMemo } from "react";
import styles from './VDB.module.scss';

export function StandartDashboard({
    count,
    label,
}: {
    count: string | number | (number | undefined | string)[] | undefined | null;
    label: string | string[];
}): React.ReactElement {
    return useMemo(
        () => (
            <div className={styles.dashboard}>
                <h2>{Array.isArray(count) ? count.join(' / ') : count || '-'}</h2>
                <div>{Array.isArray(label) ? label.join(' / ') : label}</div>
            </div>
        ),
        [count],
    );
}