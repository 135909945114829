import type { GridCellProps } from '@progress/kendo-react-grid';
import { NavLink } from 'react-router-dom';

export function CellConsole(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  return (
    <td>
      {dataItem.partialResults && dataItem.partialResults.hasPartialResults && (
        <NavLink to={`/lk/task/actions/${dataItem.taskActionId}/package_update/console`}>
          <span className="k-icon k-i-exe" />
        </NavLink>
      )}
      {dataItem.status === 'success' &&
        dataItem.partialResults &&
        !dataItem.partialResults.hasPartialResults && (
          <NavLink to={`/lk/task/actions/${dataItem.taskActionId}/package_update/console`}>
            <span className="k-icon k-i-exe" />
          </NavLink>
        )}
      {dataItem.status === 'processing' &&
        dataItem.partialResults &&
        !dataItem.partialResults.hasPartialResults && <span className="k-i-loader" />}
      {(dataItem.status === 'fail' || dataItem.status === 'timeout') &&
        dataItem.partialResults &&
        !dataItem.partialResults.hasPartialResults &&
        (!dataItem.result ||
          (dataItem.result && dataItem.result.data && !dataItem.result.data[0].output)) && (
          <span className="k-icon k-i-cancel-outline" />
        )}
      {(dataItem.status === 'fail' || dataItem.status === 'timeout') &&
        dataItem.result &&
        dataItem.result.data &&
        dataItem.result.data[0].output && (
          <NavLink to={`/lk/task/actions/${dataItem.taskActionId}/package_update/console`}>
            <span className="k-icon k-i-exe" />
          </NavLink>
        )}
    </td>
  );
}
