import type { GridCellProps } from '@progress/kendo-react-grid';
import type { ArtifactResponse } from '../../../../../types/__generated/on-premise-solution/api/artifactsResponse.v1';
import type { EnrichedLogResponse } from '../../../../../types/__generated/on-premise-solution/api/enrichedLogResponse.v1';

export function CellCreatedAt(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { createdAt }: ArtifactResponse | EnrichedLogResponse = dataItem;

  return (
    <td>
      <span>{new Date(String(createdAt)).toLocaleString()}</span>
    </td>
  );
}
