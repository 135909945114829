/* eslint-disable no-nested-ternary */
import { useQuery } from 'react-query';
import { getStatus } from '../../../services/license-service';
import localeSettings from '../../../utils/i18n/settingsLocales/settings.json';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import type { StatusResponse } from '../../../types/__generated/on-premise-solution/api/statusResponse.v1';
import { getPastTime } from '../../../utils/helpers/settings-helpers';
import styles from './Settings.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

export function Summary(): React.ReactElement {
  const query = useQuery<StatusResponse, ApiError>(['settingsStatus'], () => getStatus(), {
    // staleTime: DATA_CACHE_STALETIME,
    // cacheTime: DATA_CACHE_CACHETIME,
    // keepPreviousData: true,
  });

  const { data } = query;

  return (
    <div className="common-summary-page">
      <table className="column column-first">
        <tbody>
          <tr className="app-version">
            <td>{localeSettings[currentLocale].summary.appVersion}</td>
            <td>{data?.version ? data.version : '-'}</td>
          </tr>
          <tr className={styles.agent_version}>
            <td>{localeSettings[currentLocale].summary.agentsVersion}</td>
            <td>
              {data?.agentLatestVersions?.linux && (
                <>
                  <img src={`${process.env.PUBLIC_URL}/images/linux.svg`} alt="linux" />
                  {data?.agentLatestVersions?.linux}{' '}
                </>
              )}
              {data?.agentLatestVersions?.windows && (
                <>
                  <img src={`${process.env.PUBLIC_URL}/images/windows.svg`} alt="windows" />
                  {data?.agentLatestVersions?.windows}
                </>
              )}
            </td>
          </tr>
          <tr className="host-count">
            <td>{localeSettings[currentLocale].summary.hosts}</td>
            <td>{data?.assetsCount ? data.assetsCount : '-'}</td>
          </tr>
        </tbody>
      </table>
      <table className="column column-second">
        <tbody>
          <tr className="last-update">
            <td>{localeSettings[currentLocale].summary.lastDbUpdate}</td>
            <td>
              {data?.channelsLastUpdatedAt
                ? `${getPastTime(
                    data?.channelsLastUpdatedAt
                      ? Date.now() - new Date(data.channelsLastUpdatedAt).getTime()
                      : undefined,
                    currentLocale,
                    1,
                    localeSettings[currentLocale].summary.agoPostfix,
                  )}`
                : '-'}
            </td>
          </tr>
          <tr className="uptime">
            <td>{localeSettings[currentLocale].summary.uptime}</td>
            <td>{getPastTime(data?.uptime?.uptime, currentLocale, 1000)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
