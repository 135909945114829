import type { GridCellProps } from '@progress/kendo-react-grid';
import type { ICollectVulnSoftData } from '../../../../../../utils/helpers/audit-helpers';

export function CellUpdateVersion(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { updateVersion }: ICollectVulnSoftData = dataItem;

  return (
    <td title={updateVersion || ''}>
      {updateVersion ? <span>{updateVersion}</span> : <span>-</span>}
    </td>
  );
}
