/* eslint-disable jsx-a11y/aria-role */
import type { GridCellProps } from '@progress/kendo-react-grid';
import { UserRole } from '../../Task/common/UserRole';

export function CellUserRole(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  return (
    <td>
      <div className="task__actions">
        {dataItem.role === 'super_admin' && <UserRole title role="super_admin" />}
        {dataItem.role === 'account_admin' && <UserRole title role="account_admin" />}
        {dataItem.role === 'user' && <UserRole title role="user" />}
        {dataItem.role === 'reader_user' && <UserRole title role="reader_user" />}
      </div>
    </td>
  );
}
