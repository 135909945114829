/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-pascal-case */
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Filter } from '@progress/kendo-react-data-tools';
import localeCommonTable from '../../../../../utils/i18n/commonLocales/table.json';
import type localeImageDescription from '../../../../../utils/i18n/assetLocales/imageDescription.json';
import styles from '../../imageRegister.module.scss';
import { FILTER_REPOSITORY_LIST } from '../../../../../utils/helpers/constant-filter-image-registry';
import { RepositoryRow } from './RepositoryRow';
import { PAGEABLE_DATA } from '../../../../../utils/helpers/constants';
import { useRegDescriptionRepList } from '../../../../../hooks/components/useRegDescriptionRepList';
import { Input } from '../../../../common/baseElements/Input';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeImageDescription;

export function RepositoryList(): React.ReactElement {
  const {
    filter,
    onFilterChange,
    dataRepo,
    handleCustomSearch,
    customSearch,
    page,
    total,
    pageChange,
  } = useRegDescriptionRepList();

  return (
    <div className="repository-list">
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <div className="filter-line">
            <Filter
              value={filter}
              onChange={onFilterChange}
              fields={FILTER_REPOSITORY_LIST[currentLocale]}
            />
            {filter.filters.length === 0 && (
              <Input
                name="customSearch"
                placeholder={localeCommonTable[currentLocale].searchRepositoryName}
                value={customSearch}
                onChange={(e): void => handleCustomSearch(e)}
              />
            )}
          </div>
          <Grid
            pageable={PAGEABLE_DATA}
            data={dataRepo}
            skip={page.skip}
            take={page.take}
            total={total}
            onPageChange={pageChange}
            scrollable="none"
            className={`${styles.repositories_table} no-margin-top`}
          >
            <Column cell={RepositoryRow} />
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
      {/* {dataRepo ? dataRepo.data.map((rep) => <RepositoryRow rep={rep} />) : 'No data'} */}
    </div>
  );
}
