import { useEffect } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import localeHostDescription from '../../../../../utils/i18n/assetLocales/hostDescription.json';
import { useHostActions } from '../../../../../hooks/components/useHostActions';
import { setColorValue } from '../../../../../utils/helpers/host-vulns-metaBlock-helpers';
import { useHostModalContext } from '../../../../../hooks/useHostModalContext';
import type { AssetHostDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/assetHostDashboardResponse.v1';
import { AssetDescriptionContextMenu } from '../../../../common/contextMenu/AssetDescriptionContextMenu';
import { getStatusAgentName } from '../../../../../utils/helpers/host-list-cell-helper';
import styles from '../HostDescription.module.scss';
import formStyles from '../../../Containers/form/Form.module.scss';
import { getUserData } from '../../../../../services/local-storage-service';

export function TableSHostMetaBlock({
  data,
  currentLocale,
}: {
  data: AssetHostDashboardResponse | undefined;
  currentLocale: 'ru-RU' | 'en-EN';
}): React.ReactElement {
  const currentUser = getUserData();

  const colorValue = data?.type === 'host' && setColorValue(data?.agent?.status);

  const { setSshPort } = useHostModalContext();

  useEffect(() => {
    if (data && data.sshPort) {
      setSshPort(String(data.sshPort));
    }
  }, []);

  const userRoleCondition =
    data?.createdBy.source === 'user' &&
    currentUser?.role === 'user' &&
    data.createdBy.id === currentUser.id;

  const { selectCredential } = useHostActions();

  return (
    <table className="column column-second">
      <tbody>
        <tr>
          <td>
            <span>{localeHostDescription[currentLocale].summary.scanType}</span>
          </td>
          <td>
            {data?.type === 'host' && data?.credential ? (
              <span>
                {' '}
                {localeHostDescription[currentLocale].agent} /{' '}
                {localeHostDescription[currentLocale].summary.credential}
              </span>
            ) : (
              <span> {localeHostDescription[currentLocale].agent}</span>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeHostDescription[currentLocale].summary.agentStatus}</span>
          </td>
          <td>
            <div className="agent-status">
              {data && data.type === 'host' && data.agent ? (
                <>
                  <span style={{ backgroundColor: colorValue ? colorValue[0] : '#b5b0b0' }} />
                  {getStatusAgentName(data.agent.status, currentLocale)}
                </>
              ) : (
                <span>-</span>
              )}
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeHostDescription[currentLocale].summary.agentVersion}</span>
          </td>
          <td>
            <span>
              {data?.type === 'host' && data?.agent && data?.agent?.softwareVersion
                ? data?.agent?.softwareVersion
                : '-'}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeHostDescription[currentLocale].summary.credential}</span>
          </td>
          <td>
            {data &&
              data.type === 'host' &&
              data.credential &&
              (currentUser?.role === 'reader_user' ||
                (!userRoleCondition && currentUser?.role === 'user')) && (
                <div className="credential-block-content">
                  <div className="credential-block">
                    <div className="credential-name" title={data.credential.name}>
                      {data.credential.name}
                    </div>
                    {data.credential.type === 'winrm' ? (
                      <span className="credential-icon">winrm</span>
                    ) : (
                      <span className="credential-icon">ssh</span>
                    )}
                  </div>
                </div>
              )}
            {data &&
              data.type === 'host' &&
              data.credential &&
              data.credentialId &&
              (currentUser?.role === 'super_admin' ||
                currentUser?.role === 'account_admin' ||
                userRoleCondition) && (
                <div className="credential-block-content">
                  <div className="credential-block">
                    <div className="credential-name" title={data.credential.name}>
                      {data.credential.name}
                    </div>
                    {data.credential.type === 'winrm' ? (
                      <span className="credential-icon">winrm</span>
                    ) : (
                      <span className="credential-icon">ssh</span>
                    )}
                  </div>
                  <AssetDescriptionContextMenu data={data} currentLocale={currentLocale} />
                </div>
              )}
            {(!data?.credential || !data?.credentialId) &&
              (currentUser?.role === 'super_admin' ||
                currentUser?.role === 'account_admin' ||
                userRoleCondition) && (
                <Button
                  icon={
                    data?.latestInventorization?.os &&
                    data?.latestInventorization?.os.type &&
                    'plus'
                  }
                  fillMode="flat"
                  className={
                    data?.latestInventorization?.os && data?.latestInventorization?.os.type
                      ? `${formStyles.add_button} ${styles.add_credential}`
                      : styles.add_credential
                  }
                  disabled={
                    !data?.latestInventorization?.os || !data?.latestInventorization?.os.type
                  }
                  onClick={(): void =>
                    selectCredential({
                      hostname: data?.latestInventorization?.hostname,
                      assetId: data?.assetId,
                      accountId: data?.accountId,
                      assetGroupIds: data?.assetGroupIds,
                      credentialId:
                        data?.type === 'host' ? data?.credential?.credentialId : undefined,
                      osType:
                        data?.type === 'host' ? data?.latestInventorization?.os.type : undefined,
                      assetType: 'host',
                    })
                  }
                >
                  {localeHostDescription[currentLocale].summary.addCredential}
                </Button>
              )}
            {(!data?.credential || !data?.credentialId) &&
              (currentUser?.role === 'reader_user' ||
                (!userRoleCondition && currentUser?.role === 'user')) && (
                <span>{localeHostDescription[currentLocale].isNotUsed}</span>
              )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
