import React, { useCallback, useState } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import localeVmTask from '../../../../../utils/i18n/taskLocale/vmTask.json';
import localeHost from '../../../../../utils/i18n/assetLocales/hostList.json';
import { HostVulnsMetaBlock } from '../../../Host/hostComponents/HostVulnsMetaBlock';
import { TabsHostBottom } from '../../../Host/hostComponents/TabsHostBottom';
import type { AssetImageDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/assetImageDashboardResponse.v1';
import { CommonHostList } from '../../../../common/CommonHostList';
import { useHostList } from '../../../../../hooks/components/useHostList';
import type { IDataHooks } from '../../../../../utils/helpers/types';
import { getUrlParamsHosts } from '../../../../../utils/helpers/host-list-helper';
import { LayerIdCell } from '../cellComponents/LayerIdCell';
import { CreatedCell } from '../cellComponents/CreatedCell';
import { CommandCell } from '../cellComponents/CommandCell';
import { SizeCell } from '../cellComponents/SizeCell';
import { PackageList } from '../../../Task/taskVmDetails/vmDetailsComponents/PackageList';
import { SoftwareListWin } from '../../../Task/taskVmDetails/vmDetailsComponents/SoftwareListWin';
import { useRegistriesList } from '../../../../../hooks/components/useRegistryList';
import { CommonRegistryList } from '../../../../common/CommonRegistryList';
import type { AuditResponse } from '../../../../../types/__generated/on-premise-solution/api/auditsResponse.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeVmTask;

export function TabsImageTop({
  data,
  auditData,
  maxVFstec,
}: {
  data: AssetImageDashboardResponse | undefined;
  auditData: AuditResponse | undefined;
  maxVFstec: number | null | undefined;
}): React.ReactElement {
  const [selected, setSelected] = useState<number>(0);

  const urlParams = getUrlParamsHosts('HostList');

  const dataForHooks: IDataHooks = {
    name: '',
    componentName: 'ImageIngo',
  };

  const { sort, dataDashboard, page, total, pageChange, setSort } = useHostList(
    urlParams,
    dataForHooks,
    data?.assetHostIds,
  );

  const {
    sort: imageSort,
    page: imagePage,
    total: imageTotal,
    pageChange: imagePageChange,
    setSort: imageSetSort,
    dataRegistry,
  } = useRegistriesList(urlParams, dataForHooks, data?.registryIds);

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      setSelected(e.selected);
    },
    [setSelected],
  );

  const countPackageList =
    auditData?.payload && auditData.type === 'linux' && 'packages' in auditData.payload
      ? auditData.payload.packages.length
      : 0;

  const countSoftwareList =
    auditData?.payload && auditData.type === 'windows' && 'software' in auditData.payload
      ? auditData.payload.software?.length
      : 0;

  const countVulns = auditData?.result?.cumulativeData.vulns
    ? (auditData?.result?.cumulativeData.vulns as object)
    : 0;

  return (
    <div className="top-tabstrip">
      <TabStrip selected={selected} onSelect={handleSelect}>
        <TabStripTab
          title={`${localeHost[currentLocale].form.vulnerabilities} (${
            Object.values(countVulns).length || 0
          })`}
        >
          <HostVulnsMetaBlock
            queryAuditInfo={auditData}
            hostName={data?.imageNames[0]}
            isImage
            maxVFstec={maxVFstec}
          />
          <TabsHostBottom data={auditData} hostName={data?.imageNames[0]} isImage />
        </TabStripTab>

        {auditData?.type === 'linux' && (
          <TabStripTab
            title={`${localeVmTask[currentLocale].tabs.packageList} (${countPackageList})`}
          >
            <PackageList dataPayload={auditData?.payload} hostName={data?.imageNames[0]} />
          </TabStripTab>
        )}
        {auditData?.type === 'windows' && (
          <TabStripTab
            title={`${localeVmTask[currentLocale].tabs.softwareList} (${countSoftwareList})`}
          >
            <SoftwareListWin data={auditData} hostName={data?.imageNames[0]} />
          </TabStripTab>
        )}

        <TabStripTab
          title={`${localeHost[currentLocale].layer} (${
            data?.latestInventorization.layers ? data.latestInventorization.layers.length : '0'
          })`}
        >
          {data && (
            <Grid data={data.latestInventorization.layers} scrollable="none">
              <Column
                field="created"
                title={localeHost[currentLocale].form.created}
                cell={CreatedCell}
                width="220px"
              />
              <Column
                field="command"
                title={localeHost[currentLocale].form.command}
                cell={CommandCell}
              />
              <Column
                field="size"
                title={localeHost[currentLocale].form.size}
                cell={SizeCell}
                width="64px"
              />
              <Column
                field="layer_id"
                title={localeHost[currentLocale].form.layerId}
                cell={LayerIdCell}
                width="108px"
              />
            </Grid>
          )}
        </TabStripTab>
        <TabStripTab title={`${localeHost[currentLocale].hosts} (${total})`}>
          {data && (
            <CommonHostList
              sort={sort}
              dataDashboard={dataDashboard}
              page={page}
              total={total}
              pageChange={pageChange}
              setSort={setSort}
              dataForHooks={dataForHooks}
              conditionSort={15}
            />
          )}
        </TabStripTab>
        <TabStripTab
          title={`${localeHost[currentLocale].registries} (${
            data?.registryIds === null || data?.registryIds === undefined ? 0 : imageTotal
          })`}
        >
          {data && (
            <CommonRegistryList
              sort={imageSort}
              dataRegistry={data.registryIds === null ? undefined : dataRegistry}
              page={imagePage}
              total={imageTotal}
              pageChange={imagePageChange}
              setSort={imageSetSort}
              dataForHooks={dataForHooks}
            />
          )}
        </TabStripTab>
      </TabStrip>
    </div>
  );
}
