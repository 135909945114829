import type { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useCallback, useEffect } from 'react';
import { useRegisterContext } from '../../../../../hooks/useRegisterContext';

export function CellSelectImage(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { imageForm, setImageForm } = useRegisterContext();

  useEffect(() => {
    if (!imageForm) {
      setImageForm(imageForm);
    }
  }, [imageForm, setImageForm]);

  const addImage = useCallback(
    ({
      imageNames,
      imageId,
      assetId,
    }: {
      imageNames: string;
      imageId: string;
      assetId: string;
    }): void => {
      if (imageForm && imageForm.length > 0) {
        const index = imageForm?.findIndex((image) => image.imageId === imageId);
        if (index !== -1) {
          const cutImageForm = imageForm.filter((image) => image.imageId !== imageId);
          setImageForm([...cutImageForm]);
        } else {
          setImageForm([...imageForm, { imageNames, imageId, assetId }]);
        }
      } else {
        setImageForm([
          {
            imageNames: dataItem.imageNames[0],
            imageId: dataItem.imageId,
            assetId: dataItem.assetId,
          },
        ]);
      }
    },
    [imageForm, setImageForm, dataItem.imageNames, dataItem.imageId, dataItem.assetId],
  );

  return (
    <td>
      <Checkbox
        checked={
          !!imageForm?.find(
            (image) => image.imageId === dataItem.imageId || image.assetId === dataItem.assetId,
          )
        }
        onClick={(): void =>
          addImage({
            imageNames: dataItem.imageNames[0],
            imageId: dataItem.imageId,
            assetId: dataItem.assetId,
          })
        }
      />
    </td>
  );
}
