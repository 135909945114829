/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import type { GridCellProps } from '@progress/kendo-react-grid';
import styles from '../UserList.module.scss';

export function StringWithTfaCell(props: GridCellProps): React.ReactElement {
  const { dataItem, field, className } = props;

  const fieldArray = field ? field.split('.') : [];
  let value = dataItem;

  if (!fieldArray) value = null;
  else if (fieldArray.length === 1) value = dataItem[fieldArray[0]];
  else if (fieldArray.length > 1) {
    fieldArray.forEach((f) => {
      value = value ? value[f] : value;
    });
  }

  return value && value !== '-' ? (
    <td
      title={className && className.includes('title') ? value : ''}
      className={[styles.tfa_cell, className].join(' ')}
    >
      <span className={styles.value_span}>{value}</span>
      {dataItem && dataItem['2fa'] && dataItem['2fa'].isEnabled && (
        <span className={styles.tfa_span}>2fa</span>
      )}
    </td>
  ) : (
    <td>-</td>
  );
}
