/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-pascal-case */
import { useParams } from 'react-router-dom';
import { Typography } from '@progress/kendo-react-common';
import { useQuery } from 'react-query';
import { ErrorBoundary } from 'react-error-boundary';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { BoundaryErrorComponent } from '../../../common/BoundaryErrorComponent';
import localeSettings from '../../../../utils/i18n/settingsLocales/settings.json';
import { getApiKeyId } from '../../../../services/settings-service';
import { ApiKeyDescriptionMetaBlock } from './description/ApiKeyDescriptionMetaBlock';
import { ApiKeyDescriptionContent } from './description/ApiKeyDescriptionContent';
import type { ApiKeyDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/apiKeyDashboardResponse.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

export function ApiKeyDescription(): React.ReactElement {
  const { apiKeyId } = useParams<{ apiKeyId: string }>();

  const query = useQuery<ApiKeyDashboardResponse, ApiError>(['tokenId', apiKeyId], () =>
    getApiKeyId(apiKeyId),
  );

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      <div className="apikey-description">
        <div className="common-header-page">
          <Typography.h3 className="title">
            {localeSettings[currentLocale].apiKey.description.title}
            {query.data?.name}
          </Typography.h3>
        </div>
        <ApiKeyDescriptionMetaBlock data={query.data} />
        <ApiKeyDescriptionContent scope={query.data?.scope} />
      </div>
    </ErrorBoundary>
  );
}
