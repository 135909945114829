import localeSettings from '../../../../../utils/i18n/settingsLocales/settings.json';
import type { IApiMethod } from '../../../../../utils/helpers/types';
import { CommonMethodList } from '../../../../common/CommonMethodList';
import styles from '../ApiKeyList.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

export function MethodDescriptionList({
  apiMethods,
}: {
  apiMethods: IApiMethod[];
}): React.ReactElement {
  const componentName = 'MethodDescriptionList';

  return (
    <div className="methodRestriction">
      {apiMethods ? (
        <>
          {/* <div className={styles.titleDescriptionList}>
            <span>{localeSettings[currentLocale].apiKey.restrictions.titleMethod}</span>{' '}
          </div> */}
          <CommonMethodList apiMethods={apiMethods} componentName={componentName} />
        </>
      ) : (
        <div className={styles.titleDescriptionList}>
          <span>{localeSettings[currentLocale].apiKey.form.tabs.titleNoRestriction}</span>
        </div>
      )}
    </div>
  );
}
