import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import type {
  InventorizationDifferenceFirewall,
  InventorizationDifferenceRouter,
  InventorizationDifferenceSwitch,
} from '../../../../../types/__generated/on-premise-solution/api/inventorizationDifference.v1';
import { WrapperMenu } from '../../../Task/taskIDetails/iDetailsComponents/WrapperMenu';
import {
  DiffFilterTypes,
  prepareDiffObjectData,
} from '../../../../../utils/helpers/difference-modal-helper';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function DiffMemoryNetDevices({
  data,
  filter,
}: {
  data:
    | InventorizationDifferenceFirewall
    | InventorizationDifferenceSwitch
    | InventorizationDifferenceRouter;
  filter: DiffFilterTypes;
}): React.ReactElement {
  const memoryData = prepareDiffObjectData(
    { closed: data.memory?.old, noChanges: data.memory?.noChanges, updated: data.memory?.new },
    filter,
  );

  const wrapperStatuses = [];
  if (filter === 'all' && Object.keys(data.memory?.old || {}).length)
    wrapperStatuses.push('closed');
  if (filter === 'all' && Object.keys(data.memory?.new || {}).length) wrapperStatuses.push('new');
  if (filter === 'all' && Object.keys(data.memory?.noChanges || {}).length)
    wrapperStatuses.push('noChanges');

  return Object.keys(memoryData).length ? (
    <WrapperMenu
      title={locale[currentLocale].memory.title}
      diffStatuses={filter === 'all' ? wrapperStatuses : undefined}
    >
      {('total' in memoryData || 'free' in memoryData) && (
        <table className="column">
          {'total' in memoryData && (
            <>
              {memoryData.total.map((memoryTotal) => (
                <tr>
                  <td className={memoryTotal.className}>
                    {locale[currentLocale].memoryNetDevices.total}
                  </td>
                  <td className={memoryTotal.className}>
                    {memoryTotal.value || memoryTotal.value === 0
                      ? `${Math.round(Number(memoryTotal.value) / 1024 / 1024)} ${
                          locale[currentLocale].memoryNetDevices.sizeMb
                        }`
                      : '-'}
                  </td>
                </tr>
              ))}
            </>
          )}
          {'free' in memoryData && (
            <>
              {memoryData.free.map((memoryFree) => (
                <tr>
                  <td className={memoryFree.className}>
                    {locale[currentLocale].memoryNetDevices.free}
                  </td>
                  <td className={memoryFree.className}>
                    {memoryFree.value || memoryFree.value === 0
                      ? `${Math.round(Number(memoryFree.value) / 1024 / 1024)} ${
                          locale[currentLocale].memoryNetDevices.sizeMb
                        }`
                      : '-'}
                  </td>
                </tr>
              ))}
            </>
          )}
        </table>
      )}
    </WrapperMenu>
  ) : (
    <></>
  );
}
