/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field } from '@progress/kendo-react-form';
import { DropDownList, AutoComplete } from '@progress/kendo-react-dropdowns';
import { useEffect } from 'react';
import localeNewTask from '../../../../utils/i18n/taskLocale/newTask.json';
import { CheckBox } from '../../../common/form/CheckBox';
import { reportTypeValue } from '../../../../utils/helpers/constants';
import { useTaskAddContext } from '../../../../hooks/useTaskAddContext';
import { useTaskContext } from '../../../../hooks/useTaskContext';
import { BulletinMutableBlockTask } from '../../../common/form/BulletinMutableBlockTask';
import { СommonTableLoader } from '../../../common/tableLoader/CommonTableLoader';
import { FormInput } from '../../../common/form/FormInput';
import { checkLength } from '../../../../utils/helpers/validation';
import styles from './NewTask.module.scss';
import { TaskDatePicker } from './TaskDatePicker';

export function TaskReport({
  currentLocale,
}: {
  currentLocale: 'ru-RU' | 'en-EN';
}): React.ReactElement {
  const {
    reportType,
    setReportType,
    taskGroupReport,
    setTaskGroupReport,
    taskSingleReport,
    setTaskSingleReport,
    taskType,
    topN,
    setTopN,
    isPdfFormat,
    isHtmlFormat,
    setPdfFormat,
    setHtmlFormat,
    taskSource,
  } = useTaskAddContext();

  const {
    bulletinId,
    selectBulletinData,
    changeBulletinIdsData,
    isLoadingBulletinData,
    changeBulletinData,
  } = useTaskContext();

  useEffect(() => {
    if (selectBulletinData?.length === 1) {
      changeBulletinIdsData(selectBulletinData[0].id);
    }
    if (taskSource === 'image') setReportType({ value: reportTypeValue[currentLocale][1] });
  }, [changeBulletinIdsData, currentLocale, selectBulletinData, setReportType, taskSource]);

  useEffect(() => {
    if (reportType.value.id === 'top_bulletins' || reportType.value.id === 'top_vulnerable_assets')
      setTaskSingleReport(false);
  }, [reportType]);

  return (
    <div>
      {taskType.value.id === 'report' && (
        <>
          <Field
            name="group_report"
            component={CheckBox}
            checked={taskGroupReport}
            value={taskGroupReport}
            label={localeNewTask[currentLocale].form.todoActions.reportG}
            onChange={(e): void => {
              setTaskGroupReport(e.target.value);
            }}
          />
          <Field
            name="single_report"
            component={CheckBox}
            checked={taskSingleReport}
            value={taskSingleReport}
            label={localeNewTask[currentLocale].form.todoActions.reportS}
            onChange={(e): void => {
              setTaskSingleReport(e.target.value);
            }}
            disabled={
              reportType.value.id === 'top_bulletins' ||
              reportType.value.id === 'top_vulnerable_assets'
            }
          />
          <div>
            <p className="reporttype-title required-label">
              {localeNewTask[currentLocale].form.reports.reportType}
            </p>
            <DropDownList
              data={reportTypeValue[currentLocale]
                .filter((r) =>
                  taskGroupReport
                    ? r.id !== 'differential_audit'
                    : taskSingleReport
                    ? r.id !== 'top_bulletins' && r.id !== 'top_vulnerable_assets'
                    : r.id,
                )
                .filter((r) => {
                  if (taskSource === 'image') return r.id === 'asset_vulnerability_audit';
                  if (taskSource === 'device')
                    return r.id === 'asset_vulnerability_audit' || r.id === 'asset_inventorization';

                  return true;
                })}
              textField="text"
              dataItemKey="id"
              value={reportType.value}
              onChange={(e): void => setReportType(e)}
              style={{
                marginBottom:
                  reportType.value.id === 'top_bulletins' ||
                  reportType.value.id === 'top_vulnerable_assets'
                    ? '12px'
                    : '0',
              }}
              disabled={!taskGroupReport && !taskSingleReport}
            />
            {(reportType.value.id === 'top_bulletins' ||
              reportType.value.id === 'top_vulnerable_assets') && (
              <Field
                name="topN"
                component={FormInput}
                id="topN"
                label={localeNewTask[currentLocale].form.topN}
                onChange={(e): void => {
                  setTopN(e.target.value);
                }}
                inputValue={topN}
                className="form-field-wrapper"
                customValidator={checkLength}
                type="number"
                min="1"
              />
            )}
            {reportType.value.id === 'bulletin_overview' && (
              <>
                <p className="reporttype-title">
                  {localeNewTask[currentLocale].form.applyForBulletin.bulletinId}
                </p>
                <AutoComplete
                  data={selectBulletinData || []}
                  textField="id"
                  onChange={changeBulletinData}
                  style={{
                    marginBottom: reportType.value.id === 'bulletin_overview' ? '16px' : '0',
                  }}
                  value={bulletinId}
                  placeholder={
                    localeNewTask[currentLocale].form.applyForBulletin.inputPlaceholderId
                  }
                  clearButton={false}
                />
              </>
            )}
            {isLoadingBulletinData && (
              <СommonTableLoader queryData={isLoadingBulletinData} filteringData={false} />
            )}
            {reportType.value.id === 'differential_audit' && !taskGroupReport && (
              <TaskDatePicker currentLocale={currentLocale} />
            )}
          </div>
          {taskType.value.id === 'report' && reportType.value.id === 'bulletin_overview' && (
            <BulletinMutableBlockTask />
          )}
          <label className={`${styles.fileFormatTitle} k-label file-format-title required-label`}>
            {localeNewTask[currentLocale].form.reports.fileFormat}
          </label>
          <div className={styles.fileFormatCheckboxWrap}>
            <Field
              name="pdf"
              component={CheckBox}
              checked={isPdfFormat}
              value={isPdfFormat}
              label="PDF"
              onChange={(e): void => {
                setPdfFormat(e.target.value);
              }}
            />
            <Field
              name="html"
              component={CheckBox}
              checked={isHtmlFormat}
              value={isHtmlFormat}
              label="HTML"
              onChange={(e): void => {
                setHtmlFormat(e.target.value);
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}
