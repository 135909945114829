import type { GridCellProps } from '@progress/kendo-react-grid';
import type { IWinSoftList } from '../../../../../utils/helpers/types';

export function CellName(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { name }: IWinSoftList = dataItem;

  return <td title={name}>{name}</td>;
}
