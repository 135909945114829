/* eslint-disable react/jsx-props-no-spreading */
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { State, process } from '@progress/kendo-data-query';
import { useEffect, useState } from 'react';
import { FILTER_INITIAL } from '../../../../utils/helpers/constants';
import localeSettings from '../../../../utils/i18n/settingsLocales/settings.json';
import styles from '../WhiteListTabEl.module.scss';
import type { BulletinWhitelistDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/bulletinWhitelistDashboardResponse.v1';
import { prepareAssetTypeGrid } from '../../../../utils/helpers/settings-helpers';
import { CellCommonDate } from '../../../common/baseElements/CellCommonDate';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

export function AssetTypeDescriptionList({
  scope,
}: {
  scope: BulletinWhitelistDashboardResponse['scope'] | undefined;
}): React.ReactElement {
  const pageLimitGrid: { [key: string]: string } =
    localStorage.getItem('pageLimitGrid') &&
    JSON.parse(localStorage.getItem('pageLimitGrid') || '');

  const initialDataState: State = {
    sort: [{ field: 'name', dir: 'desc' }],
    take: pageLimitGrid && pageLimitGrid.ApiMessods ? Number(pageLimitGrid.ApiMessods) : 15,
    skip: 0,
    filter: FILTER_INITIAL,
  };

  const [dataState, setDataState] = useState<State>(initialDataState);

  const assetTypeValues: BulletinWhitelistDashboardResponse['scope']['assetTypes'] =
    scope!.assetTypes;

  const assetTypeGrid = prepareAssetTypeGrid(assetTypeValues, currentLocale);

  // useEffect(() => {
  //   const table = document.getElementsByClassName('whitelist-assettype')[0].querySelector('table');

  //   if (table) {
  //     table.style.marginBottom = '18px';
  //   }
  // }, []);

  return (
    <div className="whitelist-assettype">
      {assetTypeGrid ? (
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <Grid
              pageable={false}
              sortable
              data={process(assetTypeGrid || [], dataState)}
              {...dataState}
              onDataStateChange={(e: GridDataStateChangeEvent): void => {
                setDataState(e.dataState);
                if (pageLimitGrid) {
                  const pageLimit = {
                    ...pageLimitGrid,
                    componentName: String(e.dataState.take),
                  };
                  localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
                } else {
                  const pageLimit = {
                    componentName: String(e.dataState.take),
                  };
                  localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
                }
              }}
              scrollable="none"
            >
              <Column
                field="name"
                title={localeSettings[currentLocale].whiteList.description.type}
                filter="text"
                width="300px"
              />
              <Column
                field="addedBy.name"
                title={localeSettings[currentLocale].whiteList.grid.addedBy}
                filter="text"
                width="200px"
              />
              <Column
                cell={CellCommonDate}
                title={localeSettings[currentLocale].whiteList.grid.addedAt}
                filter="text"
                field="addedAt"
                width="200px"
              />
              <Column
                field="description"
                title={localeSettings[currentLocale].whiteList.grid.description}
                filter="text"
              />
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      ) : (
        <div className={styles.titleDescriptionList}>
          <span>{localeSettings[currentLocale].whiteList.grid.noData}</span>
        </div>
      )}
    </div>
  );
}
