import React, {
  createContext,
  useState,
  useMemo,
  useContext,
  ReactElement,
  JSXElementConstructor,
  useEffect,
} from 'react';
import { useCommonContext } from './useCommonContext';

interface IGroupData {
  name: string;
  description: string | undefined | null;
}

interface IInitialStateGroup {
  name: string;
  description?: string | undefined | null;
}

export interface IGroupContext {
  isEdit: boolean | null;
  setIsEdit: (v: boolean | null) => void;
  hostId: string | null;
  setHostId: (v: string | null) => void;
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
  groupData: IGroupData | null;
  setGroupData: (v: IGroupData | null) => void;
  groupName: string;
  setGroupName: (v: string) => void;
  groupDescription: string | undefined | null;
  setGroupDescription: (v: string | undefined | null) => void;
  initialStateGr: IInitialStateGroup | undefined | null;
  setInitialStateGr: (v: IInitialStateGroup | undefined | null) => void;
  errorMutation: string;
  setErrorMutation: (v: string) => void;
}

const GroupContext = createContext<IGroupContext | null>(null);

export function GroupProvider({
  children,
}: {
  children: ReactElement<string | JSXElementConstructor<string>>;
}): React.ReactElement {
  const { setOpenForm } = useCommonContext();

  const [groupData, setGroupData] = useState<IGroupData | null>(null);

  const [hostId, setHostId] = useState<string | null>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [isEdit, setIsEdit] = useState<boolean | null>(null);

  const [groupName, setGroupName] = useState('');

  const [groupDescription, setGroupDescription] = useState<string | undefined | null>('');

  const [initialStateGr, setInitialStateGr] = useState<IInitialStateGroup | undefined | null>(null);

  const [errorMutation, setErrorMutation] = useState('');

  useEffect(() => {
    if (groupData) {
      setGroupName(groupData.name);
      setGroupDescription(groupData.description);
    }
    setInitialStateGr({ name: groupData?.name || '', description: groupData?.description });
  }, [groupData]);

  const value = useMemo(
    () => ({
      isEdit,
      setIsEdit,
      hostId,
      setHostId,
      isOpen,
      setIsOpen,
      groupData,
      setGroupData,
      groupName,
      setGroupName,
      groupDescription,
      setGroupDescription,
      initialStateGr,
      setInitialStateGr,
      errorMutation,
      setErrorMutation,
    }),
    [isEdit, hostId, isOpen, groupData, groupName, groupDescription, initialStateGr, errorMutation],
  );

  useEffect((): void => {
    setOpenForm(isOpen);
  }, [isOpen, setOpenForm]);

  return <GroupContext.Provider value={value}>{children}</GroupContext.Provider>;
}

export const useGroupContext = (): IGroupContext => {
  const context = useContext(GroupContext);
  if (context === null) {
    throw new Error('useGroupEditContext must be used inside the GroupContext.Provider.');
  }

  return context;
};
