/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@progress/kendo-react-common';
import { useQuery } from 'react-query';
import { Loader } from '@progress/kendo-react-indicators';
import { ErrorBoundary } from 'react-error-boundary';
import { Menu, MenuItem } from '@progress/kendo-react-layout';
import { Button } from '../../../common/baseElements/Button';
import localeVmTask from '../../../../utils/i18n/taskLocale/vmTask.json';
import '../TaskDescription.module.scss';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { handleError } from '../../../../utils/errors';
import { useNotificationContext } from '../../../../hooks/useNotificationContext';
import { useTaskContext } from '../../../../hooks/useTaskContext';
import { MetaBlock } from './vmDetailsComponents/MetaBlock';
import { getAuditInfo } from '../../../../services/audit-service';
import { TabsVmDetails } from './vmDetailsComponents/TabsVmDetails';
import { useAuditModalContext } from '../../../../hooks/useAuditModalContext';
import { BoundaryErrorComponent } from '../../../common/BoundaryErrorComponent';
import { TaskAddEdit } from '../form/AddTask';
import { useConvertToJson } from '../../../../hooks/components/useConvertToJson';
import type { AuditDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/auditDashboardResponse.v1';
import { useRepeatTask } from '../../../../hooks/components/useRepeatTask';
import { useCreateTask } from '../../../../hooks/components/useCreateTask';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeVmTask;

export function TaskVmDetails(): React.ReactElement {
  const { setAuditData } = useAuditModalContext();

  const { isOpenDialog } = useTaskContext();

  const { createNotification } = useNotificationContext();

  const { auditId } = useParams<{ auditId: string }>();

  const taskActionId = useRef<string | undefined>();

  const hostName = useRef<string | false | undefined>();

  const imageName = useRef<string | false | undefined>();

  const querygetAuditInfo = useQuery<AuditDashboardResponse, ApiError>(
    ['auditInfo', auditId],
    () => getAuditInfo(auditId),
    {
      onSuccess: (data) => {
        taskActionId.current = data.taskActionId;
        setAuditData(data);
        hostName.current =
          ('latestInventorization' in data.asset &&
            data.asset.type === 'host' &&
            data.asset.latestInventorization?.hostname) ||
          '';
        imageName.current =
          data.asset.type === 'image' && 'imageNames' in data.asset && data.asset.imageNames[0];
      },
    },
  );

  const usedWhiteList = querygetAuditInfo.data?.result.whitelisted
    ? 'audit.useBulletinWhitelists'
    : false;

  useEffect(() => {
    if (querygetAuditInfo.error) {
      handleError(querygetAuditInfo.error, createNotification);
    }
  }, [createNotification, querygetAuditInfo.error]);

  const { createReportTaskVmDetails } = useCreateTask();

  const taskName =
    querygetAuditInfo.data?.asset.type === 'image'
      ? `${localeVmTask[currentLocale].titleVmImage} ${imageName.current}`
      : `${localeVmTask[currentLocale].titleVmHost} ${hostName.current}`;

  const handle2Json = useConvertToJson(
    querygetAuditInfo.data,
    querygetAuditInfo.data?.asset.type === 'image' ? imageName.current : hostName.current,
  );

  const { repeatAuditTask } = useRepeatTask();

  return (
    <div>
      {isOpenDialog && <TaskAddEdit />}
      {querygetAuditInfo.status === 'success' ? (
        <div className="taskdescription">
          <div className="common-header-page">
            <Typography.h3 className="title">
              <span className="audit-taskname">{taskName}</span>
              {querygetAuditInfo.data?.type !== 'hypervisor' && querygetAuditInfo.data?.layerId && (
                <span className="audit-layer">
                  {localeVmTask[currentLocale].layerId}
                  {querygetAuditInfo.data.layerId}
                </span>
              )}
            </Typography.h3>
            <div className="topactions">
              <Button
                onClick={(): void =>
                  repeatAuditTask(querygetAuditInfo.data, 'audit', usedWhiteList, taskName)
                }
                // disabled={
                //   taskActionId.current === undefined ||
                //   querygetAuditInfo.data.asset.type === 'image'
                // }
                disabled={taskActionId.current === undefined}
              >
                {localeVmTask[currentLocale].btnRepeat}
              </Button>
              {querygetAuditInfo.data.asset.type === 'host' && (
                <Button onClick={(): void => createReportTaskVmDetails(querygetAuditInfo.data)}>
                  {localeVmTask[currentLocale].btnCreateReport}
                </Button>
              )}
              <div className="json-actions">
                <Menu hoverOpenDelay={0} onSelect={handle2Json}>
                  <MenuItem text={localeVmTask[currentLocale].techDetails}>
                    <MenuItem
                      text={
                        querygetAuditInfo.data?.asset.type === 'image'
                          ? localeVmTask[currentLocale].imagePayload
                          : localeVmTask[currentLocale].assetPayload
                      }
                    />
                    <MenuItem text={localeVmTask[currentLocale].auditResult} />
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
          <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
            <MetaBlock auditInfo={querygetAuditInfo.data} />
            <TabsVmDetails data={querygetAuditInfo.data} hostName={hostName.current} />
          </ErrorBoundary>
        </div>
      ) : (
        <Loader
          style={{ color: 'black', marginLeft: '50%' }}
          size="large"
          type="converging-spinner"
        />
      )}
    </div>
  );
}
