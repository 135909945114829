import type { GridCellProps } from '@progress/kendo-react-grid';
import type { AssetRouter } from '../../../../types/__generated/on-premise-solution/api/asset.v1';
import type { AssetRouterDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/assetRouterDashboardResponse.v1';

export function CellDeviceModel({
  dataItem: { latestInventorization },
}: Omit<GridCellProps, 'latestInventorization' | 'assetId' | 'type'> & {
  dataItem: AssetRouter | AssetRouterDashboardResponse;
}): React.ReactElement {
  return (
    <td
      title={
        latestInventorization.model && latestInventorization.model.name
          ? latestInventorization.model.name
          : ''
      }
    >
      {latestInventorization.model && latestInventorization.model.name
        ? latestInventorization.model.name
        : '-'}
    </td>
  );
}
