/* eslint-disable react/jsx-no-useless-fragment */
import { Grid, GridColumn as Column, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useMemo, useState } from 'react';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { LAZY_PAGEABLE_DATA } from '../../../../../utils/helpers/constants';
import type {
  InventorizationDifferenceLinux,
  InventorizationDifferenceWindows,
} from '../../../../../types/__generated/on-premise-solution/api/inventorizationDifference.v1';
import { WrapperMenu } from '../../../Task/taskIDetails/iDetailsComponents/WrapperMenu';
import { DiffBoolCell } from '../cells/DiffBoolCell';
import { DiffStandartCell } from '../cells/DiffStandartCell';
import {
  createFromListInvData,
  defineWrapperStatuses,
  type DiffFilterTypes,
  type IDiffInvElementInfo,
} from '../../../../../utils/helpers/difference-modal-helper';
import { DiffTypeCell } from '../cells/DiffTypeCell';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function DiffUsers({
  data,
  filter,
}: {
  data: InventorizationDifferenceLinux | InventorizationDifferenceWindows;
  filter: DiffFilterTypes;
}): React.ReactElement {
  const [page, setPage] = useState({ take: 5, skip: 0 });

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  const usersData = useMemo(
    () => createFromListInvData(data.users as unknown as IDiffInvElementInfo[], filter),
    [data.users, filter],
  );

  if (!usersData || !usersData.length) return <></>;

  return (
    <WrapperMenu
      title={locale[currentLocale].users.title}
      diffStatuses={filter === 'all' ? defineWrapperStatuses(data.users) : undefined}
    >
      <Grid
        data={usersData.slice(page.skip, page.take + page.skip)}
        pageable={LAZY_PAGEABLE_DATA}
        scrollable="none"
        total={usersData.length}
        pageSize={page.take}
        skip={page.skip}
        onPageChange={pageChange}
        className="no-margin-top"
      >
        <Column
          field="diffType"
          cell={DiffTypeCell}
          title=" "
          width={20}
          sortable={false}
          headerClassName="no-cursor"
        />
        <Column
          field="username"
          title={locale[currentLocale].users.username}
          cell={DiffStandartCell}
          sortable={false}
          headerClassName="no-cursor"
        />
        <Column field="uid" title={locale[currentLocale].users.uid} cell={DiffStandartCell} />
        <Column field="gid" title={locale[currentLocale].users.gid} cell={DiffStandartCell} />
        <Column
          field="description"
          title={locale[currentLocale].users.description}
          cell={DiffStandartCell}
        />
        {data.type === 'linux' && (
          <Column
            field="directory"
            title={locale[currentLocale].users.directory}
            cell={DiffStandartCell}
          />
        )}
        {data.type === 'linux' && (
          <Column field="shell" title={locale[currentLocale].users.shell} cell={DiffStandartCell} />
        )}
        {data.type === 'windows' && (
          <Column
            field="domain"
            title={locale[currentLocale].users.domain}
            cell={DiffStandartCell}
          />
        )}
        {data.type === 'windows' && (
          <Column
            field="disabled"
            title={locale[currentLocale].users.disabled}
            cell={DiffBoolCell}
          />
        )}
        {data.type === 'windows' && (
          <Column
            field="lockout"
            title={locale[currentLocale].users.lockout}
            cell={DiffBoolCell}
            sortable={false}
            headerClassName="no-cursor"
          />
        )}
      </Grid>
    </WrapperMenu>
  );
}
