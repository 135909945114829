import type { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { useRef } from 'react';
import { handleFilteringTest, IFilterVal } from '../../utils/filtering-in-table-test';
import { regExpSpecSymbols } from '../../utils/helpers/constants';
import type { IUrlParamsHosts } from '../../utils/helpers/host-list-helper';
import type { IPageState } from '../../utils/helpers/types';
import { history } from '../../utils/history';

export function useCustomSearchHost(
  setFilterStatus: (value: React.SetStateAction<boolean>) => void,
  setCustomSearch: (value: React.SetStateAction<string>) => void,
  filter: CompositeFilterDescriptor,
  setFilterVal: React.Dispatch<React.SetStateAction<IFilterVal[]>>,
  setPage: (value: React.SetStateAction<IPageState>) => void,
  page: IPageState,
  urlParams: IUrlParamsHosts,
): (e: React.ChangeEvent<HTMLInputElement>) => void {
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const debouncedCallback = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFilterStatus(true);
    if (timer.current) {
      clearTimeout(timer.current);
    }
    if (!e.target.value) setCustomSearch('');
    setCustomSearch((prevState) =>
      !e.target.value.search(regExpSpecSymbols) ? prevState : e.target.value.trimStart(),
    );

    timer.current = setTimeout(() => {
      if (e.target.value === '') {
        // setPage({ skip: 0, take: page.take });
        setPage({ skip: 0, take: urlParams.page.take ? urlParams.page.take : 15 });
        handleFilteringTest(filter, setFilterVal);
        setFilterStatus(false);
        history.replace({
          pathname: `/lk/assets/hosts?skip=${page.skip}&limit=${
            urlParams.pageLimitGrid ? urlParams.pageLimitGrid.HostList : page.take
          }&sortField=latestAudit.maxCvssScore&sortOrder=desc`,
        });
      } else if (e.target.value !== ' ' && /[a-za-яй_\\-]+/gi.test(e.target.value)) {
        setFilterVal([
          {
            'latestInventorization.hostname': { $regex: e.target.value, $options: 'i' },
          },
        ]);
        setPage({ skip: 0, take: urlParams.page.take ? urlParams.page.take : 15 });
        setFilterStatus(false);
      } else if (
        e.target.value !== ' ' &&
        /^[0-9\\.]{1,3}\.*[0-9\\.]{0,3}\.*[0-9\\.]{0,3}\.*[0-9\\.]{0,3}$/gi.test(e.target.value)
      ) {
        setFilterVal([
          {
            'latestInventorization.ip': { $regex: e.target.value },
          },
        ]);
        setPage({ skip: 0, take: urlParams.page.take ? urlParams.page.take : 15 });
        setFilterStatus(false);
      }
      // else {
      //   const { value } = e.target;
      //   setFilterVal([
      //     {
      //       '$or[0][latestInventorization.ip]': { $regex: value },
      //     },
      //     {
      //       '$or[1][latestInventorization.hostname]': { $regex: value, $options: 'i' },
      //     },
      //   ]);
      //   // setPage({ skip: 0, take: 15 });
      //   setPage({ skip: 0, take: urlParams.page.take ? urlParams.page.take : 15 });
      //   setFilterStatus(false);
      // }
    }, 800);
  };

  return debouncedCallback;
}
