// import type { InventorizationResponse } from '../../../../types/__generated/on-premise-solution/api/inventorizationResponse.v1';
// import { Cpu } from './iDetailsComponents/Cpu';
// import { Disk } from './iDetailsComponents/Disk';
// import { Memory } from './iDetailsComponents/Memory';
// import locale from '../../../../utils/i18n/taskLocale/inventorizationTask.json';
// import { MemoryNetDevices } from './iDetailsComponents/MemoryNetDevices';
// import { DiskNetDevices } from './iDetailsComponents/DiskNetDevices';
// import { CpuNetDevices } from './iDetailsComponents/CpuNetDevices';
// import { CiscoHardwareInfo } from './iDetailsComponents/CiscoHardwareInfo';

import { useState } from 'react';
import hostDescriptionLocale from '../../../../../utils/i18n/assetLocales/hostDescription.json';
import type { InventorizationDifference } from '../../../../../types/__generated/on-premise-solution/api/inventorizationDifference.v1';
import { DiffFilters } from '../DiffFilters';
import { DiffMemory } from './DiffMemory';
import type { DiffFilterTypes } from '../../../../../utils/helpers/difference-modal-helper';
import { DiffCpu } from './DiffCpu';
import { DiffDisk } from './DiffDisk';
import { DiffMemoryNetDevices } from './DiffMemoryNetDevices';
import { DiffDiskNetDevices } from './DiffDiskNetDevices';
import { DiffCpuNetDevices } from './DiffCpuNetDevices';
import { DiffCiscoHardwareInfo } from './DiffCiscoHardwareInfo';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof hostDescriptionLocale;

export function DiffHardwareIDetails({
  data,
}: {
  data: InventorizationDifference | undefined;
}): React.ReactElement {
  const [filter, setFilter] = useState<DiffFilterTypes>('all');

  const filterList = {
    all: `${hostDescriptionLocale[currentLocale].changes.shortFilters.soft.all}`,
    noChanges: `${hostDescriptionLocale[currentLocale].changes.shortFilters.soft.noChanges}`,
    // updated: `${hostDescriptionLocale[currentLocale].changes.shortFilters.soft.updated}`,
    installed: `${hostDescriptionLocale[currentLocale].changes.shortFilters.soft.added}`,
    removed: `${hostDescriptionLocale[currentLocale].changes.shortFilters.soft.removed}`,
    changed: `${hostDescriptionLocale[currentLocale].changes.shortFilters.soft.changed}`,
  };

  return (
    <div className="inventorization-hardware">
      {data && (
        <>
          <DiffFilters currentFilter={filter} setFilter={setFilter} filterList={filterList} />
          {data.type !== 'firewall' &&
            data.type !== 'router' &&
            data.type !== 'switch' &&
            data.memory &&
            (data.type === 'linux' || data.type === 'windows') && (
              <DiffMemory data={data} filter={filter} />
            )}
          {(data.type === 'firewall' || data.type === 'router' || data.type === 'switch') &&
            data.memory && <DiffMemoryNetDevices data={data} filter={filter} />}

          {data.type !== 'firewall' &&
            data.type !== 'router' &&
            data.type !== 'switch' &&
            data.memory &&
            (data.type === 'linux' || data.type === 'windows') && (
              <DiffDisk data={data} filter={filter} />
            )}
          {(data.type === 'firewall' || data.type === 'router' || data.type === 'switch') && (
            <DiffDiskNetDevices data={data} filter={filter} />
          )}

          {data.type !== 'firewall' &&
            data.type !== 'router' &&
            data.type !== 'switch' &&
            data.memory &&
            (data.type === 'linux' || data.type === 'windows') && (
              <DiffCpu data={data} filter={filter} />
            )}
          {(data.type === 'firewall' || data.type === 'router' || data.type === 'switch') &&
            data.cpu_info && <DiffCpuNetDevices data={data} filter={filter} />}
          {((data.type === 'router' && data.class === 'cisco') ||
            (data.type === 'switch' && data.class === 'aruba')) &&
            data.hardware_info && <DiffCiscoHardwareInfo data={data} filter={filter} />}
        </>
      )}
    </div>
  );
}
