/* eslint-disable react/jsx-no-useless-fragment */
import { Grid, GridColumn, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useState } from 'react';
import locale from '../../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { DiffStandartCell } from '../../cells/DiffStandartCell';
import { DiffSizeCell } from '../../cells/DiffSizeCell';
import { DiffTypeCell } from '../../cells/DiffTypeCell';
import { LAZY_PAGEABLE_DATA } from '../../../../../../utils/helpers/constants';
import styles from '../../../forms/AddAssetForm.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function DiffDiskLogical({ disks }: { disks: any }): React.ReactElement {
  const [page, setPage] = useState({ take: 5, skip: 0 });

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  return (
    <Grid
      data={disks.slice(page.skip, page.take + page.skip)}
      pageable={LAZY_PAGEABLE_DATA}
      scrollable="none"
      total={disks.length}
      pageSize={page.take}
      skip={page.skip}
      onPageChange={pageChange}
      className="no-margin-top"
    >
      <GridColumn
        field="diffType"
        cell={DiffTypeCell}
        title=" "
        width={20}
        sortable={false}
        headerClassName="no-cursor"
      />
      <GridColumn
        field="device_id"
        title={locale[currentLocale].disks.logicalDiskTable.deviceId}
        cell={DiffStandartCell}
        sortable={false}
        headerClassName="no-cursor"
      />
      <GridColumn
        field="description"
        title={locale[currentLocale].disks.logicalDiskTable.description}
        cell={DiffStandartCell}
        sortable={false}
        headerClassName="no-cursor"
      />
      <GridColumn
        field="file_system"
        title={locale[currentLocale].disks.logicalDiskTable.fs}
        cell={DiffStandartCell}
        sortable={false}
        headerClassName="no-cursor"
      />
      <GridColumn
        field="size"
        title={locale[currentLocale].disks.logicalDiskTable.size}
        cell={DiffSizeCell}
        sortable={false}
        headerClassName="no-cursor"
      />
      <GridColumn
        field="free_space"
        title={locale[currentLocale].disks.logicalDiskTable.freeSpace}
        cell={DiffSizeCell}
        sortable={false}
        headerClassName="no-cursor"
      />
    </Grid>
  );
}
