/* eslint-disable no-nested-ternary */
import { Grid, GridColumn, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useState } from 'react';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import type { InventorizationResponse } from '../../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { StandartCell } from '../../../../common/cells/StandartCell';
import { BoolCell } from '../../../../common/cells/BoolCell';
import { WrapperMenu } from './WrapperMenu';
import { LAZY_PAGEABLE_DATA } from '../../../../../utils/helpers/constants';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function Interface({ data }: { data: InventorizationResponse }): React.ReactElement {
  const [page, setPage] = useState({ take: 5, skip: 0 });

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  return (
    <WrapperMenu title={locale[currentLocale].interfaces.title}>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <Grid
            data={
              data.type === 'linux' ||
              data.type === 'windows' ||
              data.type === 'router' ||
              data.type === 'firewall' ||
              data.type === 'switch' ||
              data.type === 'hypervisor'
                ? data.result.interface_details?.slice(page.skip, page.take + page.skip)
                : null
            }
            pageable={LAZY_PAGEABLE_DATA}
            scrollable="none"
            total={
              data.type === 'linux' ||
              data.type === 'windows' ||
              data.type === 'router' ||
              data.type === 'firewall' ||
              data.type === 'switch'
                ? data.result.interface_details?.length
                : 0
            }
            pageSize={page.take}
            skip={page.skip}
            onPageChange={pageChange}
            className="no-margin-top"
          >
            <GridColumn
              field="interface"
              title={locale[currentLocale].interfaces.interface}
              cell={StandartCell}
            />
            <GridColumn
              field="ip.v4.address"
              title={locale[currentLocale].interfaces.addressIpV4}
              cell={StandartCell}
            />
            <GridColumn
              field="ip.v4.netmask"
              title={locale[currentLocale].interfaces.netmaskIpV4}
              cell={StandartCell}
            />
            <GridColumn
              field="ip.v6.address"
              title={locale[currentLocale].interfaces.addressIpV6}
              cell={StandartCell}
            />
            <GridColumn
              field="ip.v6.netmask"
              title={locale[currentLocale].interfaces.netmaskIpV6}
              cell={StandartCell}
            />
            <GridColumn
              field="is_default"
              title={locale[currentLocale].interfaces.is_default}
              cell={BoolCell}
            />
            <GridColumn
              field="mac"
              title={locale[currentLocale].interfaces.mac}
              cell={StandartCell}
            />
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </WrapperMenu>
  );
}
