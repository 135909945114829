import type { GridCellProps } from '@progress/kendo-react-grid';
import type { ICollectVulnSoftData } from '../../../../../../utils/helpers/audit-helpers';

export function CellTotalVulns(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { totalVulns }: ICollectVulnSoftData = dataItem;

  return (
    <td className="number">
      <span>{totalVulns}</span>
    </td>
  );
}
