import { Grid, GridColumn, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useState } from 'react';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import type { InventorizationResponse } from '../../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import type { InventorizationLinux } from '../../../../../types/__generated/on-premise-solution/api/inventorizationResponse.v1';
import type { InventorizationWindows } from '../../../../../types/__generated/on-premise-solution/api/inventorizationDeletionResponse.v1';
import { StandartCell } from '../../../../common/cells/StandartCell';
import { WrapperMenu } from './WrapperMenu';
import { LAZY_PAGEABLE_DATA } from '../../../../../utils/helpers/constants';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function Groups({ data }: { data: InventorizationResponse }): React.ReactElement {
  const [page, setPage] = useState({ take: 5, skip: 0 });

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  return (
    <WrapperMenu title={locale[currentLocale].groups.title}>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <Grid
            data={(
              data.result.groups as
                | InventorizationLinux['result']['groups']
                | InventorizationWindows['result']['groups']
            )?.slice(page.skip, page.take + page.skip)}
            pageable={LAZY_PAGEABLE_DATA}
            scrollable="none"
            total={
              (
                data.result.groups as
                  | InventorizationLinux['result']['groups']
                  | InventorizationWindows['result']['groups']
              )?.length
            }
            pageSize={page.take}
            skip={page.skip}
            onPageChange={pageChange}
            className="no-margin-top"
          >
            <GridColumn
              field="groupname"
              title={locale[currentLocale].groups.groupname}
              cell={StandartCell}
            />
            <GridColumn field="gid" title={locale[currentLocale].groups.gid} cell={StandartCell} />
            {data.type === 'windows' && (
              <GridColumn
                field="description"
                title={locale[currentLocale].groups.description}
                cell={StandartCell}
              />
            )}
            {data.type === 'windows' && (
              <GridColumn
                field="domain"
                title={locale[currentLocale].groups.domain}
                cell={StandartCell}
              />
            )}
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </WrapperMenu>
  );
}
