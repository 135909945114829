import type { DecodedCVSSVector } from '../../../../types/__generated/cloud/common/decodedCVSSVector.v1';
import { scoreMetricsData } from '../../../../utils/helpers/constants';
import styles from './vectorModal.module.scss';
import localeAuditTask from '../../../../utils/i18n/taskLocale/auditTask.json';

interface ITest {
  name: string;
  value: string;
  description: string;
  abbreviatedForm: { name: string; value: string };
}

export function ScoresInfoBlock({
  data,
}: {
  data: Exclude<DecodedCVSSVector['cvss'], undefined>;
}): React.ReactElement {
  const cvssVersion = parseInt(data.version, 10);
  function metricsToComponent(submetrics: ITest[]): React.ReactElement {
    return (
      <table className={styles.metric_info_table}>
        {Object.values(submetrics).map((metric: ITest) => (
          <tr>
            <td className={styles.metric_name}>
              <li>
                {metric.name} ({metric.abbreviatedForm.name}):
              </li>
            </td>
            <td>
              <span className={styles.score_values}>
                {scoreMetricsData[cvssVersion][metric.abbreviatedForm.name]
                  // .filter((scoreName) => scoreName !== metric.value)
                  .map((scoreName) =>
                    scoreName === metric.value ? (
                      <span className={styles.metric_score_value}>
                        {scoreName} ({metric.abbreviatedForm.name}:{metric.abbreviatedForm.value})
                      </span>
                    ) : (
                      <span className="alternative-score-name-info">{scoreName}</span>
                    ),
                  )}
              </span>
              {/* <span className="metric-score-value">
                  {metric.value} ({metric.abbreviatedForm.name}:{metric.abbreviatedForm.value})
                </span>{' '} */}
              {/* {scoreMetricsData[cvssVersion][metric.abbreviatedForm.name] && (
                  <span className="score-values">
                    {scoreMetricsData[cvssVersion][metric.abbreviatedForm.name]
                      // .filter((scoreName) => scoreName !== metric.value)
                      .map((scoreName) => (
                        <>
                          {scoreName === metric.name ? (
                            <span className="alternative-score-name-info">{scoreName}</span>
                          ) : (
                            <span className="alternative-score-name-info">{scoreName}</span>
                          )}
                        </>
                      ))}
                  </span>
                )} */}
              <span className={styles.metric_description}>{metric.description}</span>
            </td>
          </tr>
        ))}
      </table>
    );
  }

  const {
    subscore: exSubscore,
    substring: exSubstring,
    ...exploitabilities
  } = data.base.exploitability;
  const { subscore: imSubscore, substring: imSubstring, ...impact } = data.base.impact;
  const {
    score: tScore,
    rating: tRating,
    substring: tSubstring,
    ...temporal
  } = data.temporal || {};
  const {
    subscore: mImSubscore,
    substring: mImSubstring,
    rating: mImRating,
    ...mImpact
  } = data.environmental?.securityRequirements || {};

  const { substring: mSubstring, ...mMetrics } =
    (data.version === '2.0'
      ? data.environmental?.generalModifiers
      : data.environmental?.modifiedBaseMetrics) || {};

  return (
    <div className="base">
      <p className={styles.metric_title}>Base Score Metric</p>
      <p className="submetric-title">Exploitability Metrics:</p>
      {metricsToComponent(Object.values(exploitabilities))}
      <p className="submetric-title">Impact Metrics:</p>
      {metricsToComponent(Object.values(impact))}
      {(data.version === '3.0' || data.version === '3.1') && data.base.scope && (
        <>
          <p className="submetric-title">Scope:</p>
          {metricsToComponent(Object.values({ scope: data.base.scope }))}
        </>
      )}
      {data.temporal && (
        <>
          <p className={styles.metric_title}>Temporal Score Metric</p>
          {metricsToComponent(Object.values(temporal))}
        </>
      )}
      {data.environmental && (
        <>
          <p className={styles.metric_title}>Environmental Score Metric</p>
          <p className="submetric-title">Impact Metrics</p>
          {metricsToComponent(Object.values(mImpact))}
          <p>{data.version === '2.0' && 'General Modifiers'}</p>
          <p>
            {(data.version === '3.0' || data.version === '3.1') &&
              'Exploitability and Impact Metrics'}
          </p>
          {metricsToComponent(Object.values(mMetrics))}
        </>
      )}
    </div>
  );
}
