import { nanoid } from 'nanoid';
import type { VulnerableSoftware } from '../../../../types/__generated/on-premise-solution/api/vulnerableSoftware.v1';
import locale from '../../../../utils/i18n/dashboardLocale/dashboardLayout.json';
import styles from '../Dashboard.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function TopVulnsSoftware({
  data,
}: {
  data: VulnerableSoftware | undefined;
}): React.ReactElement {
  return (
    <table className="horizontal">
      <thead>
        <tr>
          <th>{locale[currentLocale].softName}</th>
          <th>{locale[currentLocale].version}</th>
          <th className={styles.vulns_count}>{locale[currentLocale].totalBulletins}</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((d) => (
          <tr key={nanoid(4)}>
            <td>{d.name}</td>
            <td>{d.version}</td>
            <td className="number">{d.totalBulletins}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
