/* eslint-disable react/jsx-pascal-case */
import { Grid, GridColumn as Column, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { PAGEABLE_DATA } from '../../utils/helpers/constants';
import type { ICommonRegistryList } from '../../utils/helpers/types';
import { handleSort } from '../../utils/sorting-in-table';
import localeHost from '../../utils/i18n/assetLocales/hostList.json';
import { CellSelectRegistry } from '../features/Containers/Registers/cellComponents/CellSelectRegistry';
import { RegistryMenu } from '../features/Containers/Registers/cellComponents/RegistryMenu';
import { StandartCell } from './baseElements/StandartCell';
import { CellCommonDate } from './baseElements/CellCommonDate';
import { RegistryName } from '../features/Containers/Registers/cellComponents/RegistryName';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHost;

export function CommonRegistryList(props: ICommonRegistryList): React.ReactElement {
  const { sort, dataRegistry, page, total, pageChange, setSort, dataForHooks } = props;

  return (
    <LocalizationProvider language={currentLocale}>
      <IntlProvider locale={currentLocale.substring(0, 2)}>
        <Grid
          pageable={total && total > 15 ? PAGEABLE_DATA : false}
          sortable
          sort={sort}
          data={dataRegistry}
          skip={page.skip}
          take={page.take}
          total={total || 0}
          onPageChange={pageChange}
          onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
          scrollable="none"
          className={total && total < 15 ? 'no-pageable-k-grid' : ''}
        >
          {dataForHooks.componentName === 'AddRegistryList' && (
            <Column
              field="addAsset"
              // title={localeHost[currentLocale].form.selectAsset}
              title=" "
              cell={CellSelectRegistry}
              width="40px"
            />
          )}
          <Column
            field="type"
            title={localeHost[currentLocale].form.imageType}
            cell={StandartCell}
            width="72px"
          />
          {dataForHooks.componentName !== 'RegistryList' ? (
            <Column
              field="name"
              title={localeHost[currentLocale].form.registryName}
              width="320px"
            />
          ) : (
            <Column
              field="name"
              title={localeHost[currentLocale].form.registryName}
              cell={RegistryName}
              width="320px"
            />
          )}
          <Column field="address" title={localeHost[currentLocale].form.url} width="320px" />
          <Column
            field="repositoriesCount"
            title={localeHost[currentLocale].form.repoCount}
            width="144px"
            className="number"
          />
          <Column
            field="imagesCount"
            title={localeHost[currentLocale].form.imageCount}
            width="104px"
            className="number"
          />
          <Column
            field="latestAuditTime"
            title={localeHost[currentLocale].form.latestAudit}
            cell={CellCommonDate}
            width="180px"
          />
          <Column
            field="description"
            title={localeHost[currentLocale].form.description}
            cell={StandartCell}
            className="title"
          />
          {dataForHooks.componentName === 'RegistryList' && (
            <Column cell={RegistryMenu} width="40px" />
          )}
        </Grid>
      </IntlProvider>
    </LocalizationProvider>
  );
}
