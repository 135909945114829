import React, { createContext, useState, useMemo, useContext, useEffect, useCallback } from 'react';
import type { Asset } from '../types/__generated/on-premise-solution/api/asset.v1';
import type { IChildrenNodes } from '../utils/helpers/types';
import { useCommonContext } from './useCommonContext';
import { useCredentialContext } from './useCredentialContext';

type THostData = Pick<Asset, 'accountId' | 'assetId' | 'createdBy'>;
export interface IHostForm {
  assetId?: string | undefined;
  hostname?: string | null | undefined;
  assetGroupId?: string | undefined;
  name?: string | undefined;
  addedBy?: {
    source: 'user';
    id: string;
  };
  addedAt?: string;
}

export type THostModalContent = {
  isOpen: boolean;
  setOpen: (v: boolean) => void;
  isOpenGroupList: boolean;
  setOpenGroupList: (v: boolean) => void;
  isOpenHostGroups: boolean;
  setOpenHostGroups: (v: boolean) => void;
  hostData: THostData | null;
  setHostData: (v: THostData | null) => void;
  hostForm: IHostForm[] | null;
  setHostForm: (v: IHostForm[] | null) => void;
  hostFormTemp: IHostForm[] | null;
  setHostFormTemp: (v: IHostForm[] | null) => void;
  groupsIds: string[];
  setGroupsIds: (v: string[]) => void;
  ipHost: string;
  setIpHost: (V: string) => void;
  hostName: string;
  setHostName: (V: string) => void;
  // editIpHost(val: string): void;
  editHostName: (val: string) => void;
  hostDetailById: string | null;
  setHostDetailById: (v: string | null) => void;
  sshPort: string;
  setSshPort: (v: string) => void;
  smbPort: string;
  setSmbPort: (v: string) => void;
  winrmPort: string;
  setWinrmPort: (v: string) => void;
  isEditPort: boolean;
  setEditPort: (v: boolean) => void;
  selectedPkgs: { name: string; packetVersion?: string }[] | null;
  setSelectedPkgs: (v: { name: string; packetVersion?: string }[] | null) => void;
  selectedPkgsWl: { name: string; packetVersion?: string }[] | null;
  setSelectedPkgsWl: (v: { name: string; packetVersion?: string }[] | null) => void;
  missedKbs: { id: string; title: string }[] | null;
  setMissedKbs: (v: { id: string; title: string }[] | null) => void;
  kbsInWl: { id: string; title: string }[] | null;
  setKbsInWl: (v: { id: string; title: string }[] | null) => void;
  deletePkgFromSelectedPkgs: (
    name: string,
    packetVersion: string | undefined,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  deleteKbFromSelectedKbs: (
    id: string,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    kbInWl?: boolean | undefined,
  ) => void;
  tagListInHostList: string;
  setTagListInHostList: React.Dispatch<React.SetStateAction<string>>;
  isKbWlTask: boolean;
  setKbWlTask: React.Dispatch<React.SetStateAction<boolean>>;
  isPkgWlTask: boolean;
  setPkgWlTask: React.Dispatch<React.SetStateAction<boolean>>;
  delKbFromMutableBlock: (
    kbId: string | undefined,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  isAssetName: boolean;
  setAssetName: React.Dispatch<React.SetStateAction<boolean>>;
};

const HostModalContext = createContext<THostModalContent | null>(null);

export function HostModalProvider({ children }: IChildrenNodes): React.ReactElement {
  const { setResultCheckIp } = useCommonContext();

  const { setErrorMutation, setCheckErrorMutation } = useCredentialContext();

  const [isOpen, setOpen] = useState(false);

  const [hostDetailById, setHostDetailById] = useState<string | null>(null);

  const [isOpenGroupList, setOpenGroupList] = useState(false);

  const [isOpenHostGroups, setOpenHostGroups] = useState(false);

  const [hostData, setHostData] = useState<THostData | null>(null);

  const [hostForm, setHostForm] = useState<IHostForm[] | null>(null);

  const [hostFormTemp, setHostFormTemp] = useState<IHostForm[] | null>(null);

  const [groupsIds, setGroupsIds] = useState<string[]>([]);

  const [ipHost, setIpHost] = useState('');

  const [hostName, setHostName] = useState('');

  const [isAssetName, setAssetName] = useState(false);

  const [sshPort, setSshPort] = useState<string>('22');

  const [smbPort, setSmbPort] = useState<string>('445');

  const [winrmPort, setWinrmPort] = useState<string>('5985');

  const [isEditPort, setEditPort] = useState(false);

  const [selectedPkgs, setSelectedPkgs] = useState<
    { name: string; packetVersion?: string }[] | null
  >(null);

  const [selectedPkgsWl, setSelectedPkgsWl] = useState<
    { name: string; packetVersion?: string }[] | null
  >(null);

  const [missedKbs, setMissedKbs] = useState<{ id: string; title: string }[] | null>(null);

  const [kbsInWl, setKbsInWl] = useState<{ id: string; title: string }[] | null>(null);

  const [isKbWlTask, setKbWlTask] = useState(false);

  const [tagListInHostList, setTagListInHostList] = useState('');

  const [isPkgWlTask, setPkgWlTask] = useState(false);

  const deletePkgFromSelectedPkgs = useCallback(
    (
      name: string,
      packetVersion: string | undefined,
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ): void => {
      event.stopPropagation();
      if (selectedPkgs && selectedPkgs.length > 0) {
        setSelectedPkgs(
          selectedPkgs?.filter((pkg) => pkg.name !== name || pkg.packetVersion !== packetVersion),
        );
      }
    },
    [selectedPkgs],
  );

  // const editIpHost = useCallback(
  //   (val: string): void => {
  //     setResultCheckIp(null);
  //     setErrorMutation('');
  //     setCheckErrorMutation('');
  //     setIpHost(val);
  //   },
  //   [setCheckErrorMutation, setErrorMutation, setResultCheckIp],
  // );

  const editHostName = useCallback(
    (val: string): void => {
      setCheckErrorMutation('');
      setHostName(val);
    },
    [setCheckErrorMutation],
  );

  const deleteKbFromSelectedKbs = useCallback(
    (
      id: string,
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      kbInWl?: boolean | undefined,
    ): void => {
      event.stopPropagation();
      if (!kbInWl && missedKbs && missedKbs.length > 0) {
        setMissedKbs(missedKbs.filter((kbs) => kbs.id !== id));
      }
      if (kbInWl && kbsInWl && kbsInWl.length > 0) {
        setMissedKbs(kbsInWl?.filter((kbs) => kbs.id !== id));
      }
    },
    [kbsInWl, missedKbs],
  );

  const delKbFromMutableBlock = useCallback(
    (kbId: string | undefined, event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      event.stopPropagation();
      if (missedKbs && missedKbs.length > 0) {
        setMissedKbs(missedKbs?.filter((kb) => kb.id !== kbId));
      }
    },
    [missedKbs],
  );

  const value = useMemo(
    () => ({
      isOpen,
      setOpen,
      isOpenGroupList,
      setOpenGroupList,
      isOpenHostGroups,
      setOpenHostGroups,
      hostData,
      setHostData,
      hostForm,
      setHostForm,
      groupsIds,
      setGroupsIds,
      ipHost,
      setIpHost,
      hostName,
      setHostName,
      hostDetailById,
      setHostDetailById,
      hostFormTemp,
      setHostFormTemp,
      sshPort,
      setSshPort,
      smbPort,
      setSmbPort,
      winrmPort,
      setWinrmPort,
      isEditPort,
      setEditPort,
      selectedPkgs,
      setSelectedPkgs,
      selectedPkgsWl,
      setSelectedPkgsWl,
      missedKbs,
      setMissedKbs,
      deletePkgFromSelectedPkgs,
      deleteKbFromSelectedKbs,
      editHostName,
      tagListInHostList,
      setTagListInHostList,
      kbsInWl,
      setKbsInWl,
      isKbWlTask,
      setKbWlTask,
      isPkgWlTask,
      setPkgWlTask,
      delKbFromMutableBlock,
      isAssetName,
      setAssetName,
    }),
    [
      isOpen,
      isOpenGroupList,
      isOpenHostGroups,
      hostData,
      hostForm,
      groupsIds,
      ipHost,
      hostName,
      hostDetailById,
      hostFormTemp,
      sshPort,
      smbPort,
      winrmPort,
      isEditPort,
      selectedPkgs,
      selectedPkgsWl,
      missedKbs,
      deletePkgFromSelectedPkgs,
      deleteKbFromSelectedKbs,
      editHostName,
      tagListInHostList,
      kbsInWl,
      isKbWlTask,
      isPkgWlTask,
      delKbFromMutableBlock,
      isAssetName,
    ],
  );

  return <HostModalContext.Provider value={value}>{children}</HostModalContext.Provider>;
}
export const useHostModalContext = (): THostModalContent => {
  const context = useContext(HostModalContext);
  if (context === null) {
    throw new Error('useHostModalContext must be used inside the ModalContext.Provider.');
  }

  return context;
};
