import React, { useState, useEffect, useRef } from 'react';
import { Filter, FilterChangeEvent } from '@progress/kendo-react-data-tools';
import type { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { IntlProvider, loadMessages, LocalizationProvider } from '@progress/kendo-react-intl';
import { useLocation } from 'react-router-dom';
import {
  Grid,
  GridColumn as Column,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { useQuery, useQueryClient } from 'react-query';
import {
  cvssScoreWidth,
  FILTER_INITIAL,
  PAGEABLE_DATA,
  publishedWidth,
} from '../../../utils/helpers/constants';
import type { IDataHooks, IPageState } from '../../../utils/helpers/types';
import './securityVulnsList.scss';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { СommonTableLoader } from '../../common/tableLoader/CommonTableLoader';
import { handleError } from '../../../utils/errors';
import { useNotificationContext } from '../../../hooks/useNotificationContext';
import { handleSort } from '../../../utils/sorting-in-table';
import locale from '../../../utils/i18n/security/vulnerabilities.json';
import localeAuditTask from '../../../utils/i18n/taskLocale/auditTask.json';
import { handleFilteringTest, IFilterVal } from '../../../utils/filtering-in-table-test';
import { useCustomSearch } from '../../../hooks/components/useCustomSearch';
import { usePageChange } from '../../../hooks/components/usePageChange';
import { useFilter } from '../../../hooks/components/useFilter';
import { FILTER_OPERATORS } from '../../../utils/helpers/constant-filter-operators';
import { FILTER_SECURITY_VULNS } from '../../../utils/helpers/constant-serv-filter-task';
import { getBulletinsWl } from '../../../services/bulletins-service';
import type { DashboardBulletinsResponse } from '../../../types/__generated/on-premise-solution/api/dashboardBulletinsResponse.v1';
import { CellSecurityCwe } from './cells/CellSecurityCwe';
import { CellPublished } from '../Task/taskVmDetails/vmDetailsComponents/cells/CellVulnerabilitiesListPublished';
import { useAuditModalContext } from '../../../hooks/useAuditModalContext';
import { CellSecurityVulnsId } from './cells/CellSecurityVulnsId';
import { CellSecurityTitle } from './cells/CellSecurityTitle';
import { CellSecurityAsset } from './cells/CellSecurityAsset';
import {
  collectSecurityVulns,
  getUrlSecVulnsList,
} from '../../../utils/helpers/security-vulns-helper';
import { VulnModal } from '../../common/modal/vulnModal/VulnModal';
import { CellSecurityNetDev } from './cells/CellSecurityNetDev';
import { CellVulnListAlias } from '../Task/taskVmDetails/vmDetailsComponents/cells/CellVulnListAlias';
import { Input } from '../../common/baseElements/Input';
import { CellSecurityImage } from './cells/CellSecurityImage';
import { ScoreCvssCell } from '../../common/cells/ScoreCvssCell';
import styles from '../Task/taskVmDetails/vmDetailsComponents/packageNameModal.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

loadMessages(locale[currentLocale], currentLocale);

export function SecurityVulnsListWL(): React.ReactElement {
  const urlParams = getUrlSecVulnsList('SecurityVulnsListWL');

  const location = useLocation();

  const queryClient = useQueryClient();

  const [customSearch, setCustomSearch] = useState('');

  const [sort, setSort] = useState<SortDescriptor[]>(urlParams.sort);

  const [page, setPage] = useState<IPageState>(urlParams.page);

  const [filter, setFilter] = useState<CompositeFilterDescriptor>(FILTER_INITIAL);

  const [filterStatus, setFilterStatus] = useState(false);

  const [filterVal, setFilterVal] = useState<IFilterVal[]>([]);

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  const filterRef = useRef<CompositeFilterDescriptor>(filter);

  const dataForHooks: IDataHooks = {
    name: 'bulletinId',
    componentName: 'SecurityVulnsListWL',
  };

  const { createNotification } = useNotificationContext();

  const { cveName } = useAuditModalContext();

  const debouncedCustomSearch = useCustomSearch(
    setFilterStatus,
    setCustomSearch,
    filter,
    setFilterVal,
    setPage,
    page,
    dataForHooks,
  );

  const filterSearch = useFilter(
    filterValue,
    setFilter,
    customSearch,
    setCustomSearch,
    setFilterVal,
    filterRef,
    filter,
    setPage,
    page,
    dataForHooks,
  );

  const getPage = usePageChange(setPage, urlParams, dataForHooks);

  const query = useQuery<DashboardBulletinsResponse, ApiError>(
    ['SecurityVulnsListWL', page, filterVal, sort],
    () => getBulletinsWl(page, filterVal, sort),
    {
      keepPreviousData: true,
    },
  );

  useEffect(() => {
    queryClient.invalidateQueries(['SecurityVulnsListWL']);
  }, [queryClient]);

  useEffect(() => {
    let delaySearch: NodeJS.Timeout;

    if (filter?.filters?.length > 0) {
      filterRef.current = filter;
      delaySearch = setTimeout(() => {
        handleFilteringTest(filterRef.current, setFilterVal);
        setFilterStatus(false);
      }, 300);
    }

    return () => clearTimeout(delaySearch);
  }, [filter.filters, page.skip, location]);

  useEffect(() => {
    if (query.error) {
      handleError(query.error, createNotification);
    }
  }, [query.isError, query.error, createNotification]);

  const pageChange = (event: GridPageChangeEvent): void => {
    getPage(event);
  };

  const onFilterChange = (event: FilterChangeEvent): void => {
    filterSearch(event);
  };

  const handleCustomSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    debouncedCustomSearch(e);
  };

  const { data } = query;

  const securityVulns = collectSecurityVulns(data);

  return (
    <div className={`security-vulns-wl ${styles.securityVulns}`} id="idSecurityVulns">
      {/* <div className="security-vulns-wl__title">
        <span>{locale[currentLocale].vulnsTable.gridTitleWl}</span>
        <Button
          fill="dotted"
          type="button"
          onClick={(): void => setWhiteListTable(!isWhiteListTable)}
        >
          {isWhiteListTable
            ? locale[currentLocale].vulnsTable.titleWlHide
            : locale[currentLocale].vulnsTable.titleWlShow}
        </Button>
      </div> */}
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <div className="filter-line">
            <Filter
              value={filter}
              onChange={onFilterChange}
              fields={FILTER_SECURITY_VULNS[currentLocale]}
            />
            {filter.filters.length === 0 && (
              <Input
                name="customSearch"
                placeholder={locale[currentLocale].customSearchName}
                value={customSearch}
                onChange={(e): void => handleCustomSearch(e)}
              />
            )}
          </div>
        </IntlProvider>
      </LocalizationProvider>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <Grid
            pageable={PAGEABLE_DATA}
            sortable
            sort={sort}
            className="table task-table"
            data={securityVulns}
            skip={page.skip}
            take={page.take}
            total={Number(query.data?.total)}
            filterOperators={FILTER_OPERATORS}
            onPageChange={pageChange}
            onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
            scrollable="none"
          >
            <Column
              title={locale[currentLocale].vulnsTable.cvssScore}
              filter="text"
              field="maxScore"
              width={cvssScoreWidth.noSort}
              cell={ScoreCvssCell}
              sortable={false}
              headerClassName="no-cursor"
            />
            <Column
              title={locale[currentLocale].vulnsTable.id}
              filter="text"
              field="bulletinId"
              width="140px"
              cell={CellSecurityVulnsId}
            />
            <Column
              field="aliases"
              title={localeAuditTask[currentLocale].vulnsList.table.aliases}
              cell={CellVulnListAlias}
              // width="164px"
              width={query.data?.data.find((a) => a.aliases) ? '180px' : '160px'}
              sortable={false}
              headerClassName="no-cursor"
            />
            <Column
              title={locale[currentLocale].vulnsTable.title}
              filter="text"
              field="title"
              width="140px"
              cell={CellSecurityTitle}
              sortable={false}
              headerClassName="no-cursor"
            />
            <Column
              field="cwe"
              title={locale[currentLocale].vulnsTable.cwe}
              // width="260px"
              cell={CellSecurityCwe}
              sortable={false}
            />
            <Column
              title={locale[currentLocale].vulnsTable.published}
              width={publishedWidth.sort}
              filter="text"
              field="date.published"
              cell={CellPublished}
              sortable={false}
              headerClassName="no-cursor"
            />
            <Column
              title={locale[currentLocale].vulnsTable.hosts}
              width="88px"
              filter="text"
              field="totalHostAssets"
              cell={CellSecurityAsset}
              className="whitelist"
            />
            <Column
              title={locale[currentLocale].vulnsTable.totalImageAssets}
              width="96px"
              filter="text"
              field="totalImageAssets"
              cell={CellSecurityImage}
              className="whitelist"
            />
            <Column
              title={locale[currentLocale].vulnsTable.netDevices}
              filter="text"
              field="totalNetworkDeviceAssets"
              cell={CellSecurityNetDev}
              width="128px"
              className="whitelist"
            />
          </Grid>
          <СommonTableLoader queryData={query.isLoading} filteringData={filterStatus} />
          {query.isError && (
            <div className="info alert alert-danger">Error: {query.error.message}</div>
          )}
        </IntlProvider>
      </LocalizationProvider>
      {cveName && <VulnModal />}
    </div>
  );
}
