/* eslint-disable no-nested-ternary */
import {
  createContext,
  JSXElementConstructor,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHostModalContext } from './useHostModalContext';
import { useNetDeviceContext } from './useNetDeviceContext';
import { useRegisterContext } from './useRegisterContext';
import { useAccountModalContext } from './useAccountModalContext';
import { getAccountId } from '../services/local-storage-service';
import type { ApiKeyCreationResponse } from '../types/__generated/on-premise-solution/api/apiKeyCreationResponse.v1';
import type { ApiError } from '../types/__generated/on-premise-solution/api/apiError.v1';
import type { ApiKeyCreationRequest } from '../types/__generated/on-premise-solution/api/apiKeyCreationRequest.v1';
import { createApiKey, deleteToken } from '../services/settings-service';
import type { ApiKeyDeletionResponse } from '../types/__generated/on-premise-solution/api/apiKeyDeletionResponse.v1';
import ApiKey from '../types/__generated/on-premise-solution/api/apiKey.v1.schema.json';
import type { IApiKeyContext, IApiMethod, ITokenDel } from '../utils/helpers/types';
import { prepareAssetIds, prepareScopeForApiToken } from '../utils/helpers/apikey-helpers';
import { useCommonContext } from './useCommonContext';

const ApiKeyContext = createContext<IApiKeyContext | null>(null);

export function ApiKeyProvider({
  children,
}: {
  children: ReactElement<string | JSXElementConstructor<string>>;
}): React.ReactElement {
  const queryClient = useQueryClient();

  const [name, setName] = useState<string>('');

  const [description, setDescription] = useState<string>('');

  const [isApiKeyForm, setApiKeyForm] = useState<boolean>(false);

  const [apiMethodsForm, setApiMethodsForm] = useState<IApiMethod[] | null>(null);

  const [isApiMethods, setApiMethods] = useState<boolean>(false);

  const [ipRanges, setIpRanges] = useState<string>('');

  const [ipRangesForm, setIpRangesForm] = useState<string[] | null>(null);

  const [dataForDelete, setDataForDelete] = useState<ITokenDel | null>(null);

  const [apiKeyValue, setApiKeyValue] = useState<string | null>(null);

  const [errorMutation, setErrorMutation] = useState('');

  const { accountForm, setAccountForm } = useAccountModalContext();

  const { hostForm, setHostForm, setHostFormTemp } = useHostModalContext();

  const { deviceForm, setDeviceForm } = useNetDeviceContext();

  const { setRegistryForm, imageForm, setImageForm } = useRegisterContext();

  const { setResultCheckIp } = useCommonContext();

  const cancelApiKeyForm = useCallback((): void => {
    setAccountForm(null);
    setApiMethodsForm(null);
    setHostForm(null);
    setHostFormTemp(null);
    setDeviceForm(null);
    setRegistryForm(null);
    setImageForm(null);
    setIpRangesForm(null);
    setName('');
    setDescription('');
    setApiKeyForm(false);
  }, [setAccountForm, setDeviceForm, setHostForm, setHostFormTemp, setImageForm, setRegistryForm]);

  const mutationNewToken = useMutation<ApiKeyCreationResponse, ApiError, ApiKeyCreationRequest>(
    (payload) => createApiKey(payload),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('TabApiKeyList');
        cancelApiKeyForm();
        setApiKeyValue(response.apiKeyValue);
      },
      onError: (resp) => {
        setErrorMutation(resp.message);
      },
    },
  );

  const mutationDelToken = useMutation<ApiKeyDeletionResponse | null, ApiError, string>(
    (payload) => deleteToken(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('TabApiKeyList');
        setDataForDelete(null);
      },
      onError: (resp) => {
        setErrorMutation(resp.message);
      },
    },
  );

  const exitMethodsFrom = useCallback(() => {
    setApiMethodsForm(null);
    setApiMethods(false);
  }, []);

  const addSelectedMethods = useCallback((): void => {
    setApiMethodsForm(apiMethodsForm);
    setApiMethods(false);
  }, [apiMethodsForm]);

  const deleteMethodFromTask = useCallback(
    (Methodname: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      event.stopPropagation();
      if (apiMethodsForm && apiMethodsForm.length > 0) {
        setApiMethodsForm(apiMethodsForm?.filter((m) => m.path !== Methodname));
      }
    },
    [apiMethodsForm],
  );

  const deleteIpRangesFromTask = useCallback(
    (ipVal: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      event.stopPropagation();
      if (ipRangesForm && ipRangesForm.length > 0) {
        setIpRangesForm(ipRangesForm?.filter((ip) => ip !== ipVal));
      }
    },
    [ipRangesForm],
  );

  const addIpRanges = useCallback(
    (ipVal: string): void => {
      if (ipRangesForm && ipRangesForm.length > 0) {
        setIpRangesForm([...ipRangesForm, ipVal]);
      } else {
        setIpRangesForm([ipVal]);
      }
      setIpRanges('');
    },
    [ipRangesForm],
  );

  const delToken = useCallback(
    (id): void => {
      mutationDelToken.mutate(id);
    },
    [mutationDelToken],
  );

  const prepareAllowedApiMethodsData = useCallback((): IApiMethod[] => {
    const allowedApiMethodsData = [] as { path: string; httpMethods: string[] }[];
    Object.keys(
      ApiKey.properties.scope.properties.allowedApiMethods.items.properties.path.enum,
    ).forEach((key, i) => {
      allowedApiMethodsData.push({
        path: ApiKey.properties.scope.properties.allowedApiMethods.items.properties.path.enum[i],
        httpMethods: ['GET', 'POST', 'PATCH', 'DELETE'],
      });
    });

    return allowedApiMethodsData;
  }, []);

  const changeIpValue = useCallback(
    (value: string) => {
      setIpRanges(value);
      setResultCheckIp(null);
    },
    [setIpRanges, setResultCheckIp],
  );

  const onSubmit = useCallback((): void => {
    const assetIds = prepareAssetIds({ hostForm, deviceForm, imageForm });
    const scope = prepareScopeForApiToken({
      hostForm,
      accountForm,
      assetIds,
      apiMethodsForm,
      ipRangesForm,
    });
    const accountId = getAccountId();

    const onSubmitData = {
      scope,
      accountId,
      name,
      description: description || null,
    } as ApiKeyCreationRequest;

    mutationNewToken.mutate({ ...onSubmitData });
  }, [
    accountForm,
    apiMethodsForm,
    ipRangesForm,
    description,
    deviceForm,
    hostForm,
    imageForm,
    mutationNewToken,
    name,
  ]);

  const value = useMemo(
    () => ({
      apiMethodsForm,
      setApiMethodsForm,
      isApiMethods,
      setApiMethods,
      exitMethodsFrom,
      addSelectedMethods,
      deleteMethodFromTask,
      isApiKeyForm,
      setApiKeyForm,
      cancelApiKeyForm,
      imageForm,
      setImageForm,
      ipRanges,
      setIpRanges,
      ipRangesForm,
      setIpRangesForm,
      deleteIpRangesFromTask,
      addIpRanges,
      onSubmit,
      name,
      setName,
      description,
      setDescription,
      dataForDelete,
      setDataForDelete,
      delToken,
      prepareAllowedApiMethodsData,
      apiKeyValue,
      setApiKeyValue,
      changeIpValue,
      errorMutation,
      setErrorMutation,
    }),
    [
      apiMethodsForm,
      isApiMethods,
      exitMethodsFrom,
      addSelectedMethods,
      deleteMethodFromTask,
      isApiKeyForm,
      cancelApiKeyForm,
      imageForm,
      setImageForm,
      ipRanges,
      ipRangesForm,
      deleteIpRangesFromTask,
      addIpRanges,
      onSubmit,
      name,
      description,
      dataForDelete,
      delToken,
      prepareAllowedApiMethodsData,
      apiKeyValue,
      changeIpValue,
      errorMutation,
    ],
  );

  return <ApiKeyContext.Provider value={value}>{children}</ApiKeyContext.Provider>;
}

export const useApiKeyContext = (): IApiKeyContext => {
  const context = useContext(ApiKeyContext);
  if (context === null) {
    throw new Error('useApiContextContext must be used inside the WebSocketContext.Provider.');
  }

  return context;
};
