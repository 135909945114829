import type { GridCellProps } from '@progress/kendo-react-grid';
import type { ReactElement } from 'react';
import styles from '../../forms/AddAssetForm.module.scss';

export function DiffTypeCell(props: GridCellProps): ReactElement {
  const { dataItem } = props;

  return (
    <td
      className={`${dataItem.diffType === 'updated' ? styles.updated : ''} ${
        dataItem.diffType === 'closed' ? styles.closed : ''
      } ${dataItem.isInGroup ? styles.groupping : ''}`}
      style={{ padding: '0 0 0 12px', textAlign: 'center' }}
    >
      {dataItem.diffType === 'updated' && '+'}
      {dataItem.diffType === 'closed' && '-'}
    </td>
  );
}
