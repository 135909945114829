import { useState, type ReactElement } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Button } from '../../baseElements/Button';
import styles from './TfaConnectModal.module.scss';
import localeUser from '../../../../utils/i18n/userLocales/addUserForm.json';
import { useUserModalContext } from '../../../../hooks/useUserModalContext';
import { HostNotification } from '../../../features/Host/hostComponents/HostNotification';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeUser;

export function BackupCodesView({
  backupCodes,
  onClose,
}: {
  backupCodes: string[];
  onClose: () => void;
}): ReactElement {
  const { email } = useUserModalContext();
  const [copied, setCopied] = useState(false);

  const handleCopied = (): void => {
    setCopied(true);
    const timeout = setTimeout(() => {
      setCopied(false);
      clearTimeout(timeout);
    }, 900);
  };

  return (
    <div>
      <h3>
        {localeUser[currentLocale].backupCodesForm.tfaEnabledTextFirst}{' '}
        {email || localeUser[currentLocale].backupCodesForm.user}{' '}
        {localeUser[currentLocale].backupCodesForm.tfaEnabledTextLast}
      </h3>
      <p>{localeUser[currentLocale].backupCodesForm.tfaDisableInfo}.</p>
      <h3>{localeUser[currentLocale].backupCodesForm.saveCodesText}</h3>
      <p>{localeUser[currentLocale].backupCodesForm.forWhatCodesInfo}:</p>
      <CopyToClipboard text={backupCodes.join(' ')}>
        <div className="code-container">
          <div className="text-container">
            <div className={styles.tfa_backup_codes}>
              <ul>
                {backupCodes.map((code) => (
                  <li>{code}</li>
                ))}
              </ul>
            </div>
            {/* <span>sudo chmod u+x {hostCreateData?.url.split('/')[6]}</span> */}
          </div>
          <div className={styles.btn_block}>
            <Button fill="copy" onClick={handleCopied} />
            <HostNotification copied={copied} />
            {/* {step === 2 && <HostNotification copied={copied} />}{' '} */}
          </div>
        </div>
      </CopyToClipboard>
      {/* <div className={styles.tfa_backup_codes}>
        <ul> */}
      {/* {[
            '12345',
            'asdaqwdd',
            'qewffgjf',
            'yryurytrt',
            'ghmpczxc',
            'azxcvncvnc',
            'ogdksjq',
            'bvcfkgoh',
            'zxcfsadw',
            'ppgdfggd',
          ].map((code) => (
            <li>{code}</li>
          ))} */}
      {/* {backupCodes.map((code) => (
            <li>{code}</li>
          ))}
        </ul>
      </div> */}
      <p>{localeUser[currentLocale].backupCodesForm.safeCodesText}.</p>
      <Button fill="action" onClick={onClose}>
        {localeUser[currentLocale].backupCodesForm.enterBtn}
      </Button>
    </div>
  );
}
