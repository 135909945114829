import { Grid, GridColumn } from '@progress/kendo-react-grid';
import type locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { WrapperMenu } from '../../../Task/taskIDetails/iDetailsComponents/WrapperMenu';
import type {
  createFromListInvData,
  DiffFilterTypes,
} from '../../../../../utils/helpers/difference-modal-helper';
import type {
  InventorizationDifferenceFirewall,
  InventorizationDifferenceRouter,
} from '../../../../../types/__generated/on-premise-solution/api/inventorizationDifference.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function DiffInventoryNetDevices({
  data,
  filter,
}: {
  data: InventorizationDifferenceRouter | InventorizationDifferenceFirewall;
  filter: DiffFilterTypes;
}): React.ReactElement {
  // const d = createFromListInvData(data.)

  // return (
  //   <WrapperMenu title={locale[currentLocale].inventory.title}>
  //     <Grid data={inventory} scrollable="none">
  //       <GridColumn field="name" title={locale[currentLocale].inventory.name} />
  //       <GridColumn field="pid" title={locale[currentLocale].inventory.pid} />
  //       <GridColumn field="vid" title={locale[currentLocale].inventory.vid} />
  //       <GridColumn field="sn" title={locale[currentLocale].inventory.sn} />
  //       <GridColumn field="descr" title={locale[currentLocale].inventory.descr} />
  //     </Grid>
  //   </WrapperMenu>
  // );
  return <div />;
}
