import type { GridCellProps } from '@progress/kendo-react-grid';
import type { ReactElement } from 'react';
import styles from '../../forms/AddAssetForm.module.scss';
import { CellVulns } from '../../../Task/taskVmDetails/vmDetailsComponents/cells/CellVulnsSoftVulns';

export function DiffCellVulnsSoft(props: GridCellProps): ReactElement {
  const { className, dataItem, ...other } = props;

  return (
    <CellVulns
      className={`${dataItem.diffType === 'updated' && styles.updated} ${
        dataItem.diffType === 'closed' && styles.closed
      }`}
      dataItem={dataItem}
      {...other}
    />
  );
}
