import { Grid, GridColumn, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useState } from 'react';
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import type { InventorizationResponse } from '../../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import { WrapperMenu } from './WrapperMenu';
import type { InventorizationHypervisorEsxiResult } from '../../../../../types/__generated/on-premise-solution/api/inventorizationResponse.v1';
import { SizeCell } from '../../../../common/cells/SizeCell';
import { StandartCell } from '../../../../common/baseElements/StandartCell';
import { LAZY_PAGEABLE_DATA } from '../../../../../utils/helpers/constants';
import localeCommonTable from '../../../../../utils/i18n/commonLocales/table.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function DiskHypervisor({ data }: { data: InventorizationResponse }): React.ReactElement {
  const { disk } = data.result as InventorizationHypervisorEsxiResult;

  const [page, setPage] = useState({ take: 5, skip: 0 });

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  if (disk?.length)
    return (
      <WrapperMenu title={locale[currentLocale].disks.title}>
        {disk?.map((d) => {
          return (
            <WrapperMenu
              title={`${d.info.name} ${
                d.info.total_size
                  ? `(${d.info.total_size / 1024 / 1024} ${locale[currentLocale].sizeMb})`
                  : ''
              }`}
            >
              <LocalizationProvider language={currentLocale}>
                <IntlProvider locale={currentLocale.substring(0, 2)}>
                  <Grid
                    data={d.partitions?.slice(page.skip, page.take + page.skip)}
                    pageable={LAZY_PAGEABLE_DATA}
                    scrollable="none"
                    pageSize={page.take}
                    skip={page.skip}
                    total={d.partitions?.length}
                    className="no-margin-top"
                    onPageChange={pageChange}
                  >
                    <GridColumn title="Name" field="name" cell={StandartCell} />
                    <GridColumn title="Size" field="size" cell={SizeCell} />
                  </Grid>
                </IntlProvider>
              </LocalizationProvider>
            </WrapperMenu>
          );
        })}
      </WrapperMenu>
    );

  return <div />;
}
