import type { GridCellProps } from '@progress/kendo-react-grid';
import type { IServicesWin } from '../../../../../../utils/helpers/types';

export function CellWinPath(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { path }: IServicesWin = dataItem;

  return <td title={path}>{path}</td>;
}
