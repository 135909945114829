import type { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useHostModalContext } from '../../../hooks/useHostModalContext';

export function CellToCheckGr({ dataItem }: GridCellProps): React.ReactElement {
  const { hostFormTemp, setHostFormTemp } = useHostModalContext();

  const groupId = dataItem.assetGroupId;

  const change = (): void => {
    if (!hostFormTemp) {
      setHostFormTemp([{ assetGroupId: groupId }]);
    } else if (hostFormTemp && !hostFormTemp?.find((v) => v.assetGroupId === groupId)) {
      setHostFormTemp([...hostFormTemp, { assetGroupId: groupId }]);
    } else if (hostFormTemp && hostFormTemp?.find((v) => v.assetGroupId === groupId)) {
      setHostFormTemp([...hostFormTemp.filter((v) => v.assetGroupId !== groupId)]);
    }
  };

  return (
    <td>
      <Checkbox
        checked={!!hostFormTemp?.find((a) => a.assetGroupId === dataItem.assetGroupId)}
        onChange={change}
      />
    </td>
  );
}
