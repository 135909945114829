import type {
  AuditDifferenceResponse,
  AuditLinuxDifference,
  AuditWindowsDifference,
} from '../../types/__generated/on-premise-solution/api/auditDifferenceResponse.v1';
import { ICollectVulnSoftData, collectVulnSoft, collectVulnsData } from './audit-helpers';
import styles from '../../components/features/Host/forms/AddAssetForm.module.scss';
import type { IPkgVulns } from './types';

export type DiffFilterTypes = 'all' | 'noChanges' | 'updated' | 'changed' | 'installed' | 'removed';

export interface IDiffShortFilters {
  [k: string]: {
    title?: string | undefined;
    isActive: boolean;
  };
}

interface ILinuxPackage {
  name: string;
  version: string;
  arch: string;
  diffType: 'closed' | 'noChanges' | 'updated';
}

interface IMissedKb {
  diffType: string;
  id: string;
  title: string;
  description: string;
  date: {
    published: string;
    modified?: string | undefined;
  };
}

export interface IDiffInvElementInfo {
  new: any[];
  noChanges: any[];
  old: any[];
}

export interface IDiffInvObjectElementInfo {
  closed: any;
  noChanges: any;
  updated: any;
}

type WinSoft = {
  [k: string]: unknown;
  name: string;
  version: string;
  publisher?: string | undefined;
  version_major?: string | undefined;
  version_minor?: string | undefined;
  guid?: string | undefined;
} | null;

function groupData(data: any[], keyNaming: string) {
  const d = data;
  if (data.length > 1)
    for (let i = 1; i < data.length; i++) {
      if (
        !data[i - 1].isInGroup &&
        data[i - 1][keyNaming] === data[i][keyNaming] &&
        ((data[i - 1].diffType === 'closed' && data[i].diffType === 'updated') ||
          (data[i - 1].diffType === 'updated' && data[i].diffType === 'closed'))
      ) {
        d[i - 1].isInGroup = true;
        d[i].isInGroup = true;
      }
    }

  return d;
}

export function createDiffVulnSoftData(data: AuditDifferenceResponse) {
  const vulnsSoft: {
    all: ICollectVulnSoftData[];
    noChanges: ICollectVulnSoftData[];
    updated: ICollectVulnSoftData[];
    changed: ICollectVulnSoftData[];
    installed: ICollectVulnSoftData[];
    removed: ICollectVulnSoftData[];
  } = {
    all: [],
    noChanges: [],
    updated: [],
    changed: [],
    installed: [],
    removed: [],
  };

  const closedData =
    collectVulnSoft(data?.vulnerableObjects.closed, data?.vulns.closed, 'closed') || [];
  const noChangesData =
    collectVulnSoft(data?.vulnerableObjects.noChanges, data?.vulns.noChanges, 'noChanges') || [];
  const updatedData =
    collectVulnSoft(data?.vulnerableObjects.updated, data?.vulns.updated, 'updated') || [];

  const closedNames = closedData.map((cl) => cl.name);
  const updatedNames = updatedData.map((up) => up.name);

  // all
  vulnsSoft.all.push(...closedData, ...noChangesData, ...updatedData);
  // noChanges
  vulnsSoft.noChanges.push(...noChangesData);
  // installed
  vulnsSoft.installed.push(...updatedData.filter((up) => closedNames.indexOf(up.name) === -1));
  // removed
  vulnsSoft.removed.push(...closedData.filter((cl) => updatedNames.indexOf(cl.name) === -1));

  // changed
  // const sameVersions: ICollectVulnSoftData[] = []

  // closedData.forEach((cl) => {
  //     if (updatedData.filter(up => up.name === cl.name && up.packetVersion === cl.packetVersion).length) sameVersions.push(cl);
  // })

  // updatedData.forEach((up) => {
  //     if (closedData.filter(cl => up.name === cl.name && up.packetVersion === cl.packetVersion).length) sameVersions.push(up);
  // })

  // vulnsSoft.changed = sameVersions;

  /// // ///////////////////////////

  const pairedPackages = closedData
    .filter((cl) => updatedNames.indexOf(cl.name) !== -1)
    .concat(updatedData.filter((up) => closedNames.indexOf(up.name) !== -1));
  const notSameVersions: { [key: string]: ICollectVulnSoftData[] } = {};

  while (pairedPackages.length) {
    let isUpdated = true;
    for (let i = 1; i < pairedPackages.length; i++) {
      if (
        pairedPackages[i].name === pairedPackages[0].name &&
        pairedPackages[i].packetVersion === pairedPackages[0].packetVersion
      ) {
        vulnsSoft.changed.push(pairedPackages[0], pairedPackages[i]);
        pairedPackages.splice(i, 1);
        pairedPackages.splice(0, 1);
        isUpdated = false;
        break;
      }
    }
    if (isUpdated) {
      if (pairedPackages[0].name in notSameVersions)
        notSameVersions[pairedPackages[0].name].push(pairedPackages[0]);
      else notSameVersions[pairedPackages[0].name] = [pairedPackages[0]];
      pairedPackages.shift();
    }
  }

  Object.values(notSameVersions).forEach((soft) => {
    soft.sort((a, b) => {
      if (a.packetVersion < b.packetVersion) return -1;
      if (a.packetVersion > b.packetVersion) return 1;

      return 0;
    });
  });

  Object.values(notSameVersions).forEach((softList) => {
    for (let i = 0; i < softList.length; i += 2) {
      if (i === softList.length - 1) {
        if (softList[i].diffType === 'closed') vulnsSoft.removed.push(softList[i]);
        if (softList[i].diffType === 'updated') vulnsSoft.installed.push(softList[i]);
      } else if (softList[i].diffType !== softList[i + 1].diffType)
        vulnsSoft.updated.push(softList[i], softList[i + 1]);
      else {
        if (softList[i].diffType === 'closed') vulnsSoft.removed.push(softList[i]);
        if (softList[i].diffType === 'updated') vulnsSoft.installed.push(softList[i]);
        i -= 1;
      }
    }
  });

  vulnsSoft.all = groupData(vulnsSoft.all, 'name');
  vulnsSoft.installed = groupData(vulnsSoft.installed, 'name');

  return vulnsSoft;
}

export function createDiffVulnData(data: AuditDifferenceResponse) {
  const vulns: {
    all: IPkgVulns[];
    noChanges: IPkgVulns[];
    updated: IPkgVulns[];
    changed: IPkgVulns[];
    installed: IPkgVulns[];
    removed: IPkgVulns[];
  } = {
    all: [],
    noChanges: [],
    updated: [],
    changed: [],
    installed: [],
    removed: [],
  };

  const fMetrics = data?.sourceAudits.firstAudit?.metrics;
  const sMetrics = data?.sourceAudits.secondAudit?.metrics;

  const closedData = collectVulnsData(data?.vulns.closed, fMetrics, 'closed') || [];
  const noChangesData = collectVulnsData(data?.vulns.noChanges, sMetrics, 'noChanges') || [];
  const updatedData = collectVulnsData(data?.vulns.updated, sMetrics, 'updated') || [];

  const closedNames = closedData.map((cl) => cl.id);
  const updatedNames = updatedData.map((up) => up.id);

  // all
  vulns.all.push(...closedData, ...noChangesData, ...updatedData);
  // noChanges
  vulns.noChanges.push(...noChangesData);
  // installed
  vulns.installed.push(...updatedData.filter((up) => closedNames.indexOf(up.id) === -1));
  // removed
  vulns.removed.push(...closedData.filter((cl) => updatedNames.indexOf(cl.id) === -1));

  vulns.all = groupData(vulns.all, 'id');
  vulns.installed = groupData(vulns.installed, 'id');

  return vulns;
}

// export function createDiffVulnData(data: AuditDifferenceResponse, currentFilter: string, searchValue?: string) {
//     let list: any[] = [];

//     const fMetrics = data?.sourceAudits.firstAudit?.metrics;
//     const sMetrics = data?.sourceAudits.secondAudit?.metrics;

//     if (currentFilter === 'all')
//         list = collectVulnsData(data?.vulns.closed, fMetrics, 'closed')
//             .concat(collectVulnsData(data?.vulns.noChanges, sMetrics, 'noChanges'))
//             .concat(collectVulnsData(data?.vulns.updated, sMetrics, 'updated'))

//     else if (currentFilter === 'noChanges')
//         list = collectVulnsData(data?.vulns.noChanges, sMetrics, 'noChanges')

//     else if (currentFilter === 'updated') {
//         const updatedList = Object.keys(data?.vulns.closed || 0).filter(cVuln => data?.vulns.updated && cVuln in data?.vulns.updated)
//         const closed: AuditDifferenceResponse['vulns']['closed'] = {}
//         const updated: AuditDifferenceResponse['vulns']['updated'] = {}

//         updatedList.forEach(vId => {
//             if (data.vulns.closed && data.vulns.updated) {
//                 closed[vId] = data.vulns.closed[vId];
//                 updated[vId] = data.vulns.updated[vId];
//             }
//         })
//         list = collectVulnsData(closed, fMetrics, 'closed').concat(collectVulnsData(updated, sMetrics, 'updated'))
//     }

//     else if (currentFilter === 'installed') {
//         const installed: AuditDifferenceResponse['vulns']['updated'] = {}
//         Object.entries(data?.vulns.updated || 0).forEach(([key, val]) => {
//             if (!data?.vulns.closed || !(key in data.vulns.closed))
//                 installed[key] = val;
//         })
//         list = collectVulnsData(installed, sMetrics, 'updated')
//     }

//     else if (currentFilter === 'removed') {
//         const removed: AuditDifferenceResponse['vulns']['closed'] = {}
//         Object.entries(data?.vulns.closed || 0).forEach(([key, val]) => {
//             if (!data?.vulns.updated || !(key in data.vulns.updated))
//                 removed[key] = val;
//         })
//         list = collectVulnsData(removed, sMetrics, 'closed')
//     }

//     if (searchValue)
//         list = list.filter(vuln => {
//             if (!vuln.id) return false;
//             return vuln.id.toLowerCase().includes(searchValue.toLowerCase())
//         })

//     return groupData(list.sort((a, b) => a.id.localeCompare(b.id)), 'id');
//         // .map((d, i, arr) => {
//         //     if (arr[i - 1] && d.id === arr[i - 1].id || arr[i + 1] && d.id === arr[i + 1].id)
//         //         return { ...d, isInGroup: true }
//         //     return { ...d }
//         // });

// }

export function createMissedKbsData(data: AuditWindowsDifference['missedKb']) {
  const missedKb: {
    all: IMissedKb[];
    noChanges: IMissedKb[];
    updated: IMissedKb[];
    changed: IMissedKb[];
    installed: IMissedKb[];
    removed: IMissedKb[];
  } = {
    all: [],
    noChanges: [],
    updated: [],
    changed: [],
    installed: [],
    removed: [],
  };

  const closedData = Object.values(data?.closed || {}).map((v) => ({ ...v, diffType: 'closed' }));
  const noChangesData = Object.values(data?.noChanges || {}).map((v) => ({
    ...v,
    diffType: 'noChanges',
  }));
  const updatedData = Object.values(data?.updated || {}).map((v) => ({
    ...v,
    diffType: 'updated',
  }));

  const closedNames = closedData.map((cl) => cl.id);
  const updatedNames = updatedData.map((up) => up.id);

  // all
  missedKb.all.push(...closedData, ...noChangesData, ...updatedData);
  // noChanges
  missedKb.noChanges.push(...noChangesData);
  // installed
  missedKb.installed.push(...updatedData.filter((up) => closedNames.indexOf(up.id) === -1));
  // removed
  missedKb.removed.push(...closedData.filter((cl) => updatedNames.indexOf(cl.id) === -1));

  missedKb.all = groupData(missedKb.all, 'id');
  missedKb.installed = groupData(missedKb.installed, 'id');

  return missedKb;
}

// export function createMissedKbsData(data: AuditWindowsDifference['missedKb'], currentFilter: string, searchValue?: string) {
//     let list: any[] = [];

//     if (currentFilter === 'all')
//         list = Object.values(data?.closed || {}).map(v => ({ ...v, diffType: 'closed' }))
//             .concat(Object.values(data?.noChanges || {}).map(v => ({ ...v, diffType: 'noChanges' })))
//             .concat(Object.values(data?.updated || {}).map(v => ({ ...v, diffType: 'updated' })))

//     else if (currentFilter === 'noChanges')
//         list = Object.values(data?.noChanges || {}).map(v => ({ ...v, diffType: 'noChanges' }))

//     else if (currentFilter === 'updated') {
//         const updatedList = Object.keys(data?.closed || 0).filter(cMkb => data?.updated && cMkb in data?.updated)
//         const closed: AuditWindowsDifference['missedKb']['closed'] = {}
//         const updated: AuditWindowsDifference['missedKb']['updated'] = {}

//         updatedList.forEach(mId => {
//             if (data.closed && data.updated) {
//                 closed[mId] = data.closed[mId];
//                 updated[mId] = data.updated[mId];
//             }
//         })
//         list = Object.values(closed || {}).map(v => ({ ...v, diffType: 'closed' }))
//             .concat(Object.values(updated || {}).map(v => ({ ...v, diffType: 'updated' })))
//     }

//     else if (currentFilter === 'installed') {
//         const installed: AuditWindowsDifference['missedKb']['updated'] = {}
//         Object.entries(data?.updated || 0).forEach(([key, val]) => {
//             if (!data?.closed || !(key in data.closed))
//                 installed[key] = val;
//         })
//         list = Object.values(installed || {}).map(v => ({ ...v, diffType: 'updated' }))
//     }

//     else if (currentFilter === 'removed') {
//         const removed: AuditWindowsDifference['missedKb']['closed'] = {}
//         Object.entries(data?.closed || 0).forEach(([key, val]) => {
//             if (!data?.updated || !(key in data.updated))
//                 removed[key] = val;
//         })
//         list = Object.values(removed || {}).map(v => ({ ...v, diffType: 'closed' }))
//     }

//     if (searchValue)
//         list = list.filter(mkb => mkb.id.toLowerCase().includes(searchValue.toLowerCase()))

//     return groupData(list.sort((a, b) => a.id.localeCompare(b.id)), 'id')
//         // .map((d, i, arr) => {
//         //     if (arr[i - 1] && d.id === arr[i - 1].id || arr[i + 1] && d.id === arr[i + 1].id)
//         //         return { ...d, isInGroup: true }
//         //     return { ...d }
//         // });
// }

export function createSoftData(data: AuditWindowsDifference['software']) {
  const winSoft: {
    all: ILinuxPackage[];
    noChanges: ILinuxPackage[];
    updated: ILinuxPackage[];
    changed: ILinuxPackage[];
    installed: ILinuxPackage[];
    removed: ILinuxPackage[];
  } = {
    all: [],
    noChanges: [],
    updated: [],
    changed: [],
    installed: [],
    removed: [],
  };

  const closedData: ILinuxPackage[] =
    data?.closed?.map((p: any) => ({
      ...p,
      diffType: 'closed',
    })) || [];
  const noChangesData: ILinuxPackage[] =
    data?.noChanges?.map((p: any) => ({
      ...p,
      diffType: 'noChanges',
    })) || [];
  const updatedData: ILinuxPackage[] =
    data?.updated?.map((p: any) => ({
      ...p,
      diffType: 'updated',
    })) || [];

  const closedNames = closedData.map((cl) => cl.name);
  const updatedNames = updatedData.map((up) => up.name);

  // all
  winSoft.all.push(...closedData, ...noChangesData, ...updatedData);
  // noChanges
  winSoft.noChanges.push(...noChangesData);
  // installed
  winSoft.installed.push(...updatedData.filter((up) => closedNames.indexOf(up.name) === -1));
  // removed
  winSoft.removed.push(...closedData.filter((cl) => updatedNames.indexOf(cl.name) === -1));

  const sameNamesData = closedData
    .filter((cl) => updatedNames.indexOf(cl.name) !== -1)
    .concat(updatedData.filter((up) => closedNames.indexOf(up.name)));

  // edited
  const sameVersions: ILinuxPackage[] = [];

  closedData.forEach((cl) => {
    if (updatedData.filter((up) => up.name === cl.name && up.version === cl.version).length)
      sameVersions.push(cl);
  });

  updatedData.forEach((up) => {
    if (closedData.filter((cl) => up.name === cl.name && up.version === cl.version).length)
      sameVersions.push(up);
  });

  winSoft.changed = sameVersions;

  // updated
  const notSameVersions: { [key: string]: ILinuxPackage[] } = {};
  closedData.forEach((cl) => {
    if (updatedData.filter((up) => up.name === cl.name && up.version !== cl.version).length) {
      if (cl.name in notSameVersions) notSameVersions[cl.name].push(cl);
      else notSameVersions[cl.name] = [cl];
    }
  });

  updatedData.forEach((up) => {
    if (closedData.filter((cl) => up.name === cl.name && up.version !== cl.version).length)
      notSameVersions[up.name].push(up);
  });

  // Sorting
  Object.values(notSameVersions).forEach((soft) => {
    soft.sort((a, b) => {
      if (a.version < b.version) return -1;
      if (a.version > b.version) return 1;

      return 0;
    });
  });

  Object.values(notSameVersions).forEach((softList) => {
    for (let i = 0; i < softList.length; i += 2) {
      if (i === softList.length - 1) {
        if (softList[i].diffType === 'closed') winSoft.removed.push(softList[i]);
        if (softList[i].diffType === 'updated') winSoft.installed.push(softList[i]);
      } else if (softList[i].diffType !== softList[i + 1].diffType)
        winSoft.updated.push(softList[i], softList[i + 1]);
      else {
        if (softList[i].diffType === 'closed') winSoft.removed.push(softList[i]);
        if (softList[i].diffType === 'updated') winSoft.installed.push(softList[i]);
        i -= 1;
      }
    }
  });

  winSoft.all = groupData(winSoft.all, 'name');
  winSoft.installed = groupData(winSoft.installed, 'name');
  // vulnsSoft.all = groupData(vulnsSoft.all, 'name');

  return winSoft;
}

// export function createSoftData(data: AuditWindowsDifference['software'] | undefined, currentFilter: string, searchValue?: string) {
//     let list: any[] = [];

//     if (currentFilter === 'all')
//         list = (data?.closed?.map((p: any) => ({
//             ...p,
//             diffType: 'closed'
//         })) || [])
//             .concat(
//                 (data?.noChanges?.map((p: any) => ({
//                     ...p,
//                     diffType: 'noChanges'
//                 })) || [])
//             )
//             .concat(
//                 (data?.updated?.map((p: any) => ({
//                     ...p,
//                     diffType: 'updated'
//                 })) || [])
//             )

//     else if (currentFilter === 'noChanges')
//         list = (data?.noChanges?.map((p: any) => ({
//             ...p,
//             diffType: 'noChanges'
//         })) || [])

//     else if (currentFilter === 'updated') {
//         const closed: any[] = [];
//         const updated: any[] = []

//         data?.closed?.forEach(cPack => {
//             const mPack = data?.updated?.filter(uPack => uPack.name === cPack.name);
//             if (mPack?.length) {
//                 closed.push(cPack);
//                 updated.push(mPack[0])
//             }
//         })

//         list = closed.map((p: any) => ({
//             ...p,
//             diffType: 'closed'
//         }))
//             .concat(updated.map((p: any) => ({
//                 ...p,
//                 diffType: 'updated'
//             })))
//     }

//     else if (currentFilter === 'installed') {
//         const closedList = data?.closed?.map(cPack => cPack.name) || [];

//         list = (data?.updated
//             ?.filter(uPack => closedList?.indexOf(uPack.name) === -1)
//             .map((p: any) => ({
//                 ...p,
//                 diffType: 'updated'
//             })) || [])
//     }

//     else if (currentFilter === 'removed') {
//         const updatedList = data?.updated?.map(uPack => uPack.name) || [];

//         list = (data?.closed
//             ?.filter(cPack => updatedList.indexOf(cPack.name) === -1)
//             .map((p: any) => ({
//                 ...p,
//                 diffType: 'closed'
//             })) || [])
//     }

//     if (searchValue)
//         list = list.filter(soft => soft.name.toLowerCase().includes(searchValue.toLowerCase()))

//     return groupData(list.sort((a, b) => a.name.localeCompare(b.name)), 'name');
//         // .map((d, i, arr) => {
//         //     if (arr[i - 1] && d.name === arr[i - 1].name || arr[i + 1] && d.name === arr[i + 1].name)
//         //         return { ...d, isInGroup: d.diffType === 'closed' && d.diffType === 'updated' ? true : false  }
//         //     return { ...d }
//         // });

// }

export function createPackagesData(data: AuditLinuxDifference['packages']) {
  const packageSoft: {
    all: ILinuxPackage[];
    noChanges: ILinuxPackage[];
    updated: ILinuxPackage[];
    changed: ILinuxPackage[];
    installed: ILinuxPackage[];
    removed: ILinuxPackage[];
  } = {
    all: [],
    noChanges: [],
    updated: [],
    changed: [],
    installed: [],
    removed: [],
  };

  const closedData: ILinuxPackage[] =
    data?.closed?.map((p: any) => ({
      name: p[0],
      version: p[1],
      arch: p[2],
      diffType: 'closed',
    })) || [];
  const noChangesData: ILinuxPackage[] =
    data?.noChanges?.map((p: any) => ({
      name: p[0],
      version: p[1],
      arch: p[2],
      diffType: 'noChanges',
    })) || [];
  const updatedData: ILinuxPackage[] =
    data?.updated?.map((p: any) => ({
      name: p[0],
      version: p[1],
      arch: p[2],
      diffType: 'updated',
    })) || [];

  const closedNames = closedData.map((cl) => cl.name);
  const updatedNames = updatedData.map((up) => up.name);

  // all
  packageSoft.all.push(...closedData, ...noChangesData, ...updatedData);
  // noChanges
  packageSoft.noChanges.push(...noChangesData);
  // installed
  packageSoft.installed.push(...updatedData.filter((up) => closedNames.indexOf(up.name) === -1));
  // removed
  packageSoft.removed.push(...closedData.filter((cl) => updatedNames.indexOf(cl.name) === -1));

  const sameNamesData = closedData
    .filter((cl) => updatedNames.indexOf(cl.name) !== -1)
    .concat(updatedData.filter((up) => closedNames.indexOf(up.name)));

  // edited
  const sameVersions: ILinuxPackage[] = [];

  closedData.forEach((cl) => {
    if (updatedData.filter((up) => up.name === cl.name && up.version === cl.version).length)
      sameVersions.push(cl);
  });

  updatedData.forEach((up) => {
    if (closedData.filter((cl) => up.name === cl.name && up.version === cl.version).length)
      sameVersions.push(up);
  });

  packageSoft.changed = sameVersions;

  // updated
  const notSameVersions: { [key: string]: ILinuxPackage[] } = {};
  closedData.forEach((cl) => {
    if (updatedData.filter((up) => up.name === cl.name && up.version !== cl.version).length) {
      if (cl.name in notSameVersions) notSameVersions[cl.name].push(cl);
      else notSameVersions[cl.name] = [cl];
    }
  });

  updatedData.forEach((up) => {
    if (closedData.filter((cl) => up.name === cl.name && up.version !== cl.version).length)
      notSameVersions[up.name].push(up);
  });

  // Sorting
  Object.values(notSameVersions).forEach((soft) => {
    soft.sort((a, b) => {
      if (a.version < b.version) return -1;
      if (a.version > b.version) return 1;

      return 0;
    });
  });

  Object.values(notSameVersions).forEach((softList) => {
    for (let i = 0; i < softList.length; i += 2) {
      if (i === softList.length - 1) {
        if (softList[i].diffType === 'closed') packageSoft.removed.push(softList[i]);
        if (softList[i].diffType === 'updated') packageSoft.installed.push(softList[i]);
      } else if (softList[i].diffType !== softList[i + 1].diffType)
        packageSoft.updated.push(softList[i], softList[i + 1]);
      else {
        if (softList[i].diffType === 'closed') packageSoft.removed.push(softList[i]);
        if (softList[i].diffType === 'updated') packageSoft.installed.push(softList[i]);
        i -= 1;
      }
    }
  });

  packageSoft.all = groupData(packageSoft.all, 'name');
  packageSoft.installed = groupData(packageSoft.installed, 'name');
  // vulnsSoft.all = groupData(vulnsSoft.all, 'name');

  return packageSoft;
}

export function createFromListInvData(data: IDiffInvElementInfo[] | null): {
  all: any[];
  noChanges: any[];
  installed: any[];
  removed: any[];
  changed: any[];
  updated: any[];
};
export function createFromListInvData(
  data: IDiffInvElementInfo[] | null,
  filter: DiffFilterTypes,
): any;

export function createFromListInvData(
  data: IDiffInvElementInfo[] | null,
  filter?: DiffFilterTypes,
) {
  const noChangesData: any[] = [];
  const changedData: any[] = [];
  const installedData: any[] = [];
  const closedData: any[] = [];
  const updatedData: any[] = [];

  data?.forEach((obj) => {
    if (obj.old && obj.new)
      changedData.push({ ...obj.old, diffType: 'closed' }, { ...obj.new, diffType: 'updated' });
    else if (obj.noChanges) noChangesData.push({ ...obj.noChanges, diffType: 'noChanges' });
    else if (obj.new) installedData.push({ ...obj.new, diffType: 'updated' });
    else if (obj.old) closedData.push({ ...obj.old, diffType: 'closed' });
  });

  if (!filter) {
    return {
      all: closedData
        .concat(noChangesData)
        .concat(installedData)
        .concat(changedData)
        .concat(updatedData),
      noChanges: noChangesData,
      installed: installedData,
      removed: closedData,
      changed: changedData,
      updated: updatedData,
    };
  }

  switch (filter) {
    case 'all':
      return closedData.concat(noChangesData).concat(installedData).concat(changedData);

    case 'noChanges':
      return noChangesData;

    case 'installed':
      return installedData;

    case 'removed':
      return closedData;

    case 'changed':
      return changedData;

    default:
      return [];
  }
}

// export function createPackagesData(data: AuditLinuxDifference['packages'], currentFilter: string, searchValue?: string) {
//     let list: any[] = [];

//     if (currentFilter === 'all')
//         list = (data?.closed?.map((p: any) => ({
//             name: p[0],
//             version: p[1],
//             arch: p[2],
//             diffType: 'closed'
//         })) || [])
//             .concat(
//                 (data?.noChanges?.map((p: any) => ({
//                     name: p[0],
//                     version: p[1],
//                     arch: p[2],
//                     diffType: 'noChanges'
//                 })) || [])
//             )
//             .concat(
//                 (data?.updated?.map((p: any) => ({
//                     name: p[0],
//                     version: p[1],
//                     arch: p[2],
//                     diffType: 'updated'
//                 })) || [])
//             )

//     else if (currentFilter === 'noChanges')
//         list = (data?.noChanges?.map((p: any) => ({
//             name: p[0],
//             version: p[1],
//             arch: p[2],
//             diffType: 'noChanges'
//         })) || [])

//     else if (currentFilter === 'updated') {
//         const closed: any[] = [];
//         const updated: any[] = []

//         data?.closed?.forEach(cPack => {
//             const mPack = data?.updated?.filter(uPack => uPack[0] === cPack[0]);
//             if (mPack?.length) {
//                 closed.push(cPack);
//                 updated.push(mPack[0])
//             }
//         })

//         list = closed.map((p: any) => ({
//             name: p[0],
//             version: p[1],
//             arch: p[2],
//             diffType: 'closed'
//         }))
//             .concat(updated.map((p: any) => ({
//                 name: p[0],
//                 version: p[1],
//                 arch: p[2],
//                 diffType: 'updated'
//             })))
//     }

//     else if (currentFilter === 'installed') {
//         const closedList = data?.closed?.map(cPack => cPack[0]) || [];

//         list = (data?.updated
//             ?.filter(uPack => closedList.indexOf(uPack[0]) === -1)
//             .map((p: any) => ({
//                 name: p[0],
//                 version: p[1],
//                 arch: p[2],
//                 diffType: 'updated'
//             })) || [])
//     }

//     else if (currentFilter === 'removed') {
//         const updatedList = data?.updated?.map(uPack => uPack[0]) || [];

//         list = (data?.closed
//             ?.filter(cPack => updatedList.indexOf(cPack[0]) === -1)
//             .map((p: any) => ({
//                 name: p[0],
//                 version: p[1],
//                 arch: p[2],
//                 diffType: 'closed'
//             })) || [])
//     }

//     if (searchValue)
//         list = list.filter(pack => {
//             if (!pack.name) return false;
//             return pack.name.toLowerCase().includes(searchValue.toLowerCase())
//         })

//     return groupData(list.sort((a, b) => a.name.localeCompare(b.name)), 'name')
//         // .map((d, i, arr) => {
//         //     if (arr[i - 1] && d.name === arr[i - 1].name || arr[i + 1] && d.name === arr[i + 1].name){
//         //         return { ...d, isInGroup: d.diffType === 'closed' && d.diffType === 'updated' ? true : false }
//         //     }
//         //     return { ...d }
//         // });

// }

// export function createInvSoftData(
//     data:
//         | InventorizationDifferenceLinux['software']
//         | InventorizationDifferenceWindows['software']
//         | undefined
//     ){
//     const soft: {
//         all: any[],
//         noChanges: any[],
//         updated: any[],
//         changed: any[],
//         installed: any[],
//         removed: any[]
//     } = {
//         all: [],
//         noChanges: data?.noChanges?.map(s => ({...s, diffType: 'noChanges'})) || [],
//         updated:[],
//         changed: [],
//         installed: data?.new?.map(s => ({...s, diffType: 'updated'})) || [],
//         removed: [],
//     }

//     soft.all.push(
//         ...(data?.closed?.map(s => ({...s, diffType: 'closed'})) || []),
//         ...(data?.noChanges?.map(s => ({...s, diffType: 'noChanges'})) || []),
//         ...(data?.updated?.map(s => ({...s, diffType: 'updated'})) || []),
//         ...(data?.new?.map(s => ({...s, diffType: 'updated'})) || []),
//     );

//     const closedData = data?.closed?.map(s => ({...s, diffType: 'closed'})) || [];
//     const updatedData = [];

//     const notSameVersions = [];

//     data?.updated?.forEach(uSoft => {
//         const updatedSoftIndex = closedData.findIndex(cSoft => cSoft.name === uSoft.name && cSoft.version !== uSoft.version) || -1;
//         if (updatedSoftIndex > -1 && closedData.length > updatedSoftIndex){
//             notSameVersions.push(uSoft, closedData[updatedSoftIndex]);
//             closedData.splice(updatedSoftIndex, 1);
//         }
//     })

//     soft.removed.push(...closedData);

//     // soft.removed.push(...closedData.filter(cl => updatedNames.indexOf(cl.name) === -1));

//     return soft;
// }

// export function createInvLinServiceData(data: InventorizationDifferenceLinux['systemd_units']){
//     const services: {
//         all: any[],
//         noChanges: any[],
//         updated: any[],
//         changed: any[],
//         installed: any[],
//         removed: any[]
//     } = {
//         all: [],
//         noChanges: data?.noChanges?.map(s => ({...s, diffType: 'noChanges'})) || [],
//         updated:[],
//         changed: [],
//         installed: data?.new?.map(s => ({...s, diffType: 'updated'})) || [],
//         removed: [],
//     };

//     services.all.push(
//         ...(data?.closed?.map(s => ({...s, diffType: 'closed'})) || []),
//         ...(data?.noChanges?.map(s => ({...s, diffType: 'noChanges'})) || []),
//         ...(data?.changed?.map(s => ({...s, diffType: 'updated'})) || []),
//         ...(data?.new?.map(s => ({...s, diffType: 'updated'})) || []),
//     );

//     const closedData = data?.closed?.map(s => ({...s, diffType: 'closed'})) || [];

//     const notSameVersions = [];

//     data?.changed?.forEach(uSoft => {
//         const changedSoftIndex = closedData.findIndex(cSoft => cSoft.id === uSoft.id) || -1;
//         if (changedSoftIndex > -1 && closedData.length > changedSoftIndex){
//             notSameVersions.push(uSoft, closedData[changedSoftIndex]);
//             closedData.splice(changedSoftIndex, 1);
//         }
//     })

//     services.removed.push(...closedData);

//     return services;
// }

// export function createInvWinServiceData(data: InventorizationDifferenceWindows['services']){
//     const services: {
//         all: any[],
//         noChanges: any[],
//         updated: any[],
//         changed: any[],
//         installed: any[],
//         removed: any[]
//     } = {
//         all: [],
//         noChanges: data?.noChanges?.map(s => ({...s, diffType: 'noChanges'})) || [],
//         updated:[],
//         changed: [],
//         installed: data?.new?.map(s => ({...s, diffType: 'updated'})) || [],
//         removed: [],
//     };

//     services.all.push(
//         ...(data?.closed?.map(s => ({...s, diffType: 'closed'})) || []),
//         ...(data?.noChanges?.map(s => ({...s, diffType: 'noChanges'})) || []),
//         ...(data?.changed?.map(s => ({...s, diffType: 'updated'})) || []),
//         ...(data?.new?.map(s => ({...s, diffType: 'updated'})) || []),
//     );

//     const closedData = data?.closed?.map(s => ({...s, diffType: 'closed'})) || [];

//     const notSameVersions = [];

//     data?.changed?.forEach(uSoft => {
//         const changedSoftIndex = closedData.findIndex(cSoft => cSoft.name === uSoft.name) || -1;
//         if (changedSoftIndex > -1 && closedData.length > changedSoftIndex){
//             notSameVersions.push(uSoft, closedData[changedSoftIndex]);
//             closedData.splice(changedSoftIndex, 1);
//         }
//     })

//     services.removed.push(...closedData);

//     return services;
// }

// export function createInvSystemData(data: InventorizationDifference){
//     const systemData: {
//         all: {},
//         noChanges: {},
//         changed: {},
//         new: {},
//         removed: {}
//     } = {
//         all: {},
//         noChanges: {},
//         changed: {},
//         new: {},
//         removed: {},
//     };
// }

export function defineObjectWrapperStatus(data: IDiffInvObjectElementInfo | null) {
  const statuses: string[] = [];
  const hasClosed = !!Object.keys(data?.closed || {}).length;
  const hasNoChanges = !!Object.keys(data?.noChanges || {}).length;
  const hasUpdated = !!Object.keys(data?.updated || {}).length;

  if (hasClosed && !hasUpdated && !hasNoChanges) statuses.push('allClosed');
  if (!hasClosed && hasUpdated && !hasNoChanges) statuses.push('allNew');

  return statuses;
}

export function defineWrapperStatus(data: IDiffInvElementInfo[] | null) {
  let hasOnlyClosed = true;
  let hasOnlyNew = true;
  const statuses: string[] = [];
  data?.forEach((element) => {
    if (!element.old || element.noChanges || element.new) hasOnlyClosed = false;
    if (!element.new || element.noChanges || element.old) hasOnlyNew = false;
  });

  if (hasOnlyClosed) statuses.push('allClosed');
  if (hasOnlyNew) statuses.push('allNew');

  return statuses;
}

export function prepareDiffObjectData(
  data: {
    closed: any;
    noChanges: any;
    updated: any;
  } | null,
  filter: DiffFilterTypes,
): {
  [k: string]: {
    className?: string;
    value: any;
  }[];
} {
  const result: {
    [k: string]: {
      className?: string;
      value: any;
    }[];
  } = {};

  switch (filter) {
    case 'all':
      Object.entries(data?.closed || {}).forEach(([k, v]) => {
        if (k in result) result[k].push({ className: styles.closed, value: v });
        else result[k] = [{ className: styles.closed, value: v }];
      });
      Object.entries(data?.noChanges || {}).forEach(([k, v]) => {
        if (k in result) result[k].push({ value: v });
        else result[k] = [{ value: v }];
      });
      Object.entries(data?.updated || {}).forEach(([k, v]) => {
        if (k in result) result[k].push({ className: styles.updated, value: v });
        else result[k] = [{ className: styles.updated, value: v }];
      });
      break;

    case 'noChanges':
      Object.entries(data?.noChanges || {}).forEach(([k, v]) => {
        result[k] = [{ value: v }];
      });
      break;

    case 'removed':
      Object.entries(data?.closed || {}).forEach(([k, v]) => {
        if (!data?.updated || !data.updated[k])
          result[k] = [{ className: styles.closed, value: v }];
      });
      break;

    case 'installed':
      Object.entries(data?.updated || {}).forEach(([k, v]) => {
        if (!data?.closed || !data.closed[k]) result[k] = [{ className: styles.updated, value: v }];
      });
      break;

    case 'changed':
      Object.entries(data?.closed || {}).forEach(([k, v]) => {
        if (data?.updated && k in data.updated) {
          result[k] = [
            { value: v, className: styles.closed },
            { value: data.updated[k], className: styles.updated },
          ];
        }
      });
      break;

    default:
      break;
  }

  return result;
}

export function defineWrapperStatuses(
  data:
    | {
        old: any;
        noChanges: any;
        new: any;
      }
    | {
        closed: any;
        noChanges: any;
        updated: any;
      }
    | any[]
    | null,
): string[] {
  const statuses: string[] = [];

  if (data && Array.isArray(data)) {
    data.forEach((o) => {
      if ((('closed' in o && o.closed) || ('old' in o && o.old)) && !statuses.includes('closed'))
        statuses.push('closed');
      if ('noChanges' in o && o.noChanges && !statuses.includes('noChanges'))
        statuses.push('noChanges');
      if ((('updated' in o && o.updated) || ('new' in o && o.new)) && !statuses.includes('new'))
        statuses.push('new');
    });
  } else if (data) {
    if (('closed' in data && data.closed) || ('old' in data && data.old)) statuses.push('closed');
    if ('noChanges' in data && data.noChanges) statuses.push('noChanges');
    if (('updated' in data && data.updated) || ('new' in data && data.new)) statuses.push('new');
  }

  return statuses;
}
