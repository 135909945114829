import { Grid, GridColumn, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useState } from 'react';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import type { InventorizationResponse } from '../../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import { SizeCell } from '../../../../common/cells/SizeCell';
import { StandartCell } from '../../../../common/cells/StandartCell';
import { NormalDateCell } from '../../../../common/cells/NormalDateCell';
import { WrapperMenu } from './WrapperMenu';
import { LAZY_PAGEABLE_DATA } from '../../../../../utils/helpers/constants';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function ContainerImages({ data }: { data: InventorizationResponse }): React.ReactElement {
  const [page, setPage] = useState({ take: 5, skip: 0 });

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  return (
    <WrapperMenu title={locale[currentLocale].images.title}>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <Grid
            data={
              data.type === 'linux'
                ? data.result.container_images?.slice(page.skip, page.take + page.skip)
                : null
            }
            pageable={LAZY_PAGEABLE_DATA}
            scrollable="none"
            total={data.type === 'linux' ? data.result.container_images?.length : 0}
            pageSize={page.take}
            skip={page.skip}
            onPageChange={pageChange}
            className="no-margin-top"
          >
            <GridColumn
              field="data.repo"
              title={locale[currentLocale].images.repository}
              cell={StandartCell}
              className="title"
            />
            <GridColumn
              field="data.tag"
              title={locale[currentLocale].images.tag}
              cell={StandartCell}
            />
            <GridColumn
              field="data.id"
              title={locale[currentLocale].images.id}
              cell={StandartCell}
            />
            <GridColumn
              field="data.created"
              title={locale[currentLocale].images.createdAt}
              cell={NormalDateCell}
            />
            <GridColumn
              field="data.size"
              title={locale[currentLocale].images.size}
              cell={SizeCell}
            />
            <GridColumn
              field="type"
              title={locale[currentLocale].images.type}
              cell={StandartCell}
            />
            <GridColumn
              field="data.arch"
              title={locale[currentLocale].images.arch}
              cell={StandartCell}
            />
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </WrapperMenu>
  );
}
