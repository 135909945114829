import { useEffect, useState } from 'react';
import { Stepper, StepperChangeEvent } from '@progress/kendo-react-layout';
import { Button } from '../../../../common/baseElements/Button';
import styles from '../AddAssetForm.module.scss';
import formStyles from '../../../Containers/form/Form.module.scss';
import localeDashboardForm from '../../../../../utils/i18n/dashboardLocale/addDashboardForm.json';
import { useHostActions } from '../../../../../hooks/components/useHostActions';
import { useCommonContext } from '../../../../../hooks/useCommonContext';
import { useHostCreateContext } from '../../../../../hooks/useHostCreateContext';
import { CheckCredentialInfo } from '../../../../common/CheckCredentialInfo';
import { useHostModalContext } from '../../../../../hooks/useHostModalContext';
import { useCheck } from '../../../../../hooks/components/useCheck';
import { Input } from '../../../../common/baseElements/Input';
import { useCredentialContext } from '../../../../../hooks/useCredentialContext';
import { SelectedCredForAsset } from '../../../../common/form/SelectedCredForAsset';
import { CommonTooltip } from '../../../../common/CommonTooltip';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeDashboardForm;

export function SetCredentialForHost({ type }: { type?: 'windows' }): React.ReactElement {
  const { selectCredential, editIpHost } = useHostActions();

  const { ipHost, hostName } = useHostModalContext();

  const { resultCheckIp } = useCommonContext();

  const { selectedCredential, handleEdit, credentialData, credentialHost } = useCredentialContext();

  const { hostCreateData } = useHostCreateContext();

  const { checkNameInIp } = useCheck();

  const [step, setStep] = useState(0);

  useEffect(() => {
    if (selectedCredential?.credentialId) {
      handleEdit(selectedCredential?.credentialId);
    }
  }, [handleEdit, selectedCredential?.credentialId]);

  const handleCopied = (e: StepperChangeEvent): void => {
    setStep(e.value);
  };

  const items = [{ text: '1' }, { text: '2' }];

  return (
    <div className="create-linux-asset__content">
      <div className={styles.asset_form}>
        <Stepper
          className={type && type === 'windows' ? 'credential-stepper-win' : 'credential-stepper'}
          value={step}
          onChange={handleCopied}
          items={items}
          orientation="vertical"
        />
        <div style={{ width: '100%' }}>
          <div className={styles.inputWrap}>
            <div className={styles.ipHostLabelWrap}>
              <label htmlFor="ip" className="k-label required-label">
                {localeDashboardForm[currentLocale].ipOrNamePlaceholder}
              </label>
              <CommonTooltip>{localeDashboardForm[currentLocale].fqdnLabel}</CommonTooltip>
            </div>
            <Input
              className="k-input"
              name="ip"
              id="ip"
              placeholder={localeDashboardForm[currentLocale].ipOrNamePlaceholder}
              value={ipHost}
              onChange={(e): void => editIpHost(e.target.value)}
              onBlur={(e): void => checkNameInIp(e.target.value)}
            />
            {resultCheckIp && (
              <div className={styles.resultCheckIpWrap}>
                <span className={styles.ip_error}>{resultCheckIp}</span>
              </div>
            )}
          </div>
          <div>
            <label htmlFor="addCredential" className="k-label required-label">
              {localeDashboardForm[currentLocale].form.credStep2}
            </label>
            <Button
              // fill="flat"
              icon="plus"
              id="addCredential"
              className={formStyles.add_button}
              onClick={(): void =>
                selectCredential({
                  assetId: hostCreateData?.assetId,
                  assetGroupIds: null,
                  osType: hostCreateData?.assetType,
                  ip: ipHost,
                  assetType: 'host',
                })
              }
            >
              {credentialHost
                ? localeDashboardForm[currentLocale].changeCredential
                : localeDashboardForm[currentLocale].addCredential}
            </Button>
            <SelectedCredForAsset dataCred={credentialHost} type="host" />
          </div>
          <CheckCredentialInfo
            ip={ipHost}
            port={type && type === 'windows' ? 5985 : credentialData?.sshPort}
            сredentialId={credentialHost?.credentialId}
            hostname={hostName}
            typeOfPort={type && type === 'windows' ? 'winrm' : 'ssh'}
          />
        </div>
      </div>
    </div>
  );
}
