import type { SortDescriptor } from '@progress/kendo-data-query';
import { INITIAL_PAGING, INITIAL_SORT_TASK_HISTORY } from './constants';
import type { IPageState } from './types';

export interface IFilterUrlDataTaskHistory {
  taskName24eq?: string;
  taskName24ne?: string;
  taskName24regex?: string;
  taskName24not24regex?: string;
  status24eq?: string;
  startedAt24gt?: string;
}
export interface IUrlParamsTaskHistory {
  sort: SortDescriptor[];
  page: IPageState;
  pageLimitGrid: { [key: string]: string } | null;
  filterUrlData: IFilterUrlDataTaskHistory;
}

export function getUrlTaskHistory(pageLimitGridKey: string): IUrlParamsTaskHistory {
  const pageLimitGrid: IUrlParamsTaskHistory['pageLimitGrid'] =
    localStorage.getItem('pageLimitGrid') &&
    JSON.parse(localStorage.getItem('pageLimitGrid') || '');

  const locationUrlData: { [name: string]: string } | null = {};
  if (document.location.search) {
    const locationSearch = document.location.search.slice(1).split('&');
    locationSearch.forEach((val) => {
      const value = val.split('=');
      if (value[0] === 'taskName[%24eq]') {
        locationUrlData.taskName24eq = decodeURI(value[1]) ? decodeURI(value[1]) : '';
      } else if (value[0] === 'taskName[%24ne]') {
        locationUrlData.taskName24ne = decodeURI(value[1]) || '';
      } else if (value[0] === 'taskName[%24regex]') {
        locationUrlData.taskName24regex = decodeURI(value[1]) || '';
      } else if (value[0] === 'taskName[%24not][%24regex]') {
        locationUrlData.taskName24not24regex = decodeURI(value[1]) || '';
      } else if (value[0] === 'status[%24eq]') {
        locationUrlData.status24eq = decodeURI(value[1]) || '';
      } else if (value[0] === 'startedAt[%24gt]') {
        locationUrlData.startedAt24gt = decodeURI(value[1]) || '';
      } else {
        locationUrlData[value[0]] = value[1] || '';
      }
    });
  }

  const { sortField, sortOrder, skip } = locationUrlData;
  const sort: SortDescriptor[] =
    sortField && sortOrder && (sortOrder === 'asc' || sortOrder === 'desc')
      ? [{ field: sortField, dir: sortOrder }]
      : INITIAL_SORT_TASK_HISTORY;

  const page: IPageState = {
    skip: Number(skip) || INITIAL_PAGING.skip,
    take:
      pageLimitGrid && Number(pageLimitGrid[pageLimitGridKey])
        ? Number(pageLimitGrid[pageLimitGridKey])
        : INITIAL_PAGING.take,
  };

  const filterUrlData = {} as IFilterUrlDataTaskHistory;

  if (locationUrlData.taskName24ne) {
    filterUrlData.taskName24ne = locationUrlData.taskName24ne;
  }
  if (locationUrlData.taskName24eq) {
    filterUrlData.taskName24eq = locationUrlData.taskName24eq;
  }
  if (locationUrlData.taskName24regex) {
    filterUrlData.taskName24regex = locationUrlData.taskName24regex;
  }
  if (locationUrlData.taskName24not24regex) {
    filterUrlData.taskName24not24regex = locationUrlData.taskName24not24regex;
  }
  if (locationUrlData.startedAt24gt) {
    filterUrlData.startedAt24gt = locationUrlData.startedAt24gt;
  }

  return {
    sort,
    page,
    pageLimitGrid,
    filterUrlData,
  };
}
