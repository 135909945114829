import qs from 'qs';
import type { SortDescriptor } from '@progress/kendo-data-query';
import { history } from '../utils/history';
import { vmInstance } from '../lib/axios/hooks/useVmAxios';
import type { TaskCreationRequest } from '../types/__generated/on-premise-solution/api/taskCreationRequest.v1';
import type { TaskCreationResponse } from '../types/__generated/on-premise-solution/api/taskCreationResponse.v1';
import type { IPageState } from '../utils/helpers/types';
import type { TasksResponse } from '../types/__generated/on-premise-solution/api/tasksResponse.v1';
import type { AssetsResponse } from '../types/__generated/on-premise-solution/api/assetsResponse.v1';
import type { TaskDeletionResponse } from '../types/__generated/on-premise-solution/api/taskDeletionResponse.v1';
import type { TaskUpdateResponse } from '../types/__generated/on-premise-solution/api/taskUpdateResponse.v1';
import type { TaskResponse } from '../types/__generated/on-premise-solution/api/taskResponse.v1';
import type { InventorizationResponse } from '../types/__generated/on-premise-solution/api/inventorizationResponse.v1';
import type { ArtifactsResponse } from '../types/__generated/on-premise-solution/api/artifactsResponse.v1';
import { dashboardInstance } from '../lib/axios/hooks/useDashboardAxios';
import type { EnrichedTaskActionResponse } from '../types/__generated/on-premise-solution/api/enrichedTaskActionResponse.v1';
import type { EnrichedTaskActionsResponse } from '../types/__generated/on-premise-solution/api/enrichedTaskActionsResponse.v1';
import type { IFilterVal } from '../utils/filtering-in-table-test';
import { prepareFilterVal } from './filterVal-for-service';
import type { LogsResponse } from '../types/__generated/on-premise-solution/api/logsResponse.v1';
import type {
  TaskExecutionDashboardResponse,
  TaskExecutionsDashboardResponse,
} from '../types/__generated/on-premise-solution/api/taskExecutionsDashboardResponse.v1';
import type { TasksDashboardResponse } from '../types/__generated/on-premise-solution/api/tasksDashboardResponse.v1';
import { urlFilterString } from '../utils/helpers/url-filter-string-helper';

export async function getTasks(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
): Promise<TasksDashboardResponse> {
  // const queryString = urlFilterString(filterVal);

  // const paramsData = {
  //   skip: page.skip,
  //   limit: page.take,
  //   sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
  //   sortOrder: sort[0].dir,
  //   '$or[0][status]': 'processing&$or[1][status]=waiting',
  //   str: queryString,
  // };

  const result = await dashboardInstance.get<TasksDashboardResponse>('/tasks', {
    params: {
      skip: page.skip,
      limit: page.take,
      sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
      sortOrder: sort[0].dir,
      '$or[0][status]': 'processing&$or[1][status]=waiting',
      ...prepareFilterVal(filterVal),
    },

    // params: paramsData,

    paramsSerializer: (params) => {
      const stringifiedParams = qs.stringify(params, { encode: false });
      history.replace({
        pathname: `/lk/audit/tasks?${stringifiedParams}`,
      });

      return stringifiedParams;
    },
  });

  return result.data;
}

export async function createTask(taskData: TaskCreationRequest): Promise<TaskCreationResponse> {
  const result = await vmInstance.post<TaskCreationResponse>('/tasks', taskData);

  return result.data;
}

export async function deleteTask(taskId: number): Promise<TaskDeletionResponse | null> {
  const result = await vmInstance.delete<TaskDeletionResponse>(`/tasks/${taskId}`);

  return result.data;
}

// export async function getAssetsList(): Promise<AssetsResponse> {
//   const result = await vmInstance.get<AssetsResponse>('/assets');

//   return result.data;
// }

// export async function getAssetsByAccountId(accountId: string): Promise<AssetsResponse> {
//   const result = await vmInstance.get<AssetsResponse>('/assets', {
//     params: {
//       accountId,
//     },
//   });

//   return result.data;
// }

export async function getTaskInfo(taskId: number): Promise<TaskResponse> {
  const result = await vmInstance.get<TaskResponse>(`/tasks/${taskId}`);

  return result.data;
}

// export async function editTask(taskData: TaskUpdateResponse): Promise<TaskUpdateResponse> {
//   const result = await vmInstance.patch<TaskUpdateResponse>(`/tasks/${taskData.taskId}`, taskData);

//   return result.data;
// }

// executions:

export async function getTaskExecutionInfo(
  taskExecutionId: string | undefined,
): Promise<TaskExecutionDashboardResponse> {
  const result = await dashboardInstance.get<TaskExecutionDashboardResponse>(
    `/task/executions/${taskExecutionId}`,
  );

  return result.data;
}
export async function getTaskHistoryList(
  page: IPageState,
  sort: Array<SortDescriptor>,
  filterVal: IFilterVal[],
): Promise<TaskExecutionsDashboardResponse> {
  // let queryString = '';

  // const fieldName: string[] = [];

  // filterVal.forEach((v) => {
  //   if (Object.keys(v)[0]) fieldName.push(Object.keys(v)[0]);
  // });

  // filterVal.forEach((v, i) => {
  //   if (fieldName && fieldName.length > i) {
  //     queryString += `${i > 0 ? '&' : ''}${fieldName[i]}[${Object.keys(Object.values(v)[0])[0]}]=${
  //       fieldName[i].includes('startedAt')
  //         ? new Date(Object.values(Object.values(v)[0])[0]).toISOString()
  //         : Object.values(Object.values(v)[0])[0]
  //     }${
  //       Object.keys(Object.values(v)[0]).includes('$regex') ? `&${fieldName[i]}[$options]=i` : ''
  //     }`;
  //   }
  // });

  // const queryString = urlFilterString(filterVal);

  // const paramsData = {
  //   skip: page.skip,
  //   limit: page.take,
  //   sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
  //   sortOrder: sort[0].dir,
  //   str: queryString,
  // };
  const result = await dashboardInstance.get<TaskExecutionsDashboardResponse>('/task/executions', {
    params: {
      skip: page.skip,
      limit: page.take,
      sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
      sortOrder: sort[0].dir,
      ...prepareFilterVal(filterVal),
    },
    // params: paramsData,

    paramsSerializer: (params) => {
      const stringifiedParams = qs.stringify(params, { encode: false });
      history.replace({
        pathname: `/lk/audit/history?${stringifiedParams}`,
      });

      return stringifiedParams;
    },
  });

  return result.data;
}

export async function getHistoryByAsset(
  page: IPageState,
  sort: Array<SortDescriptor>,
  filterVal: IFilterVal[],
  asset: string,
): Promise<TaskExecutionsDashboardResponse> {
  const result = await dashboardInstance.get<TaskExecutionsDashboardResponse>(
    `/task/executions?assetIds=${asset}`,
    {
      params: {
        skip: page.skip,
        limit: page.take,
        sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
        sortOrder: sort[0].dir,
        ...prepareFilterVal(filterVal),
      },

      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });
        history.replace({
          pathname: `/lk/assets/hosts/${asset}?${stringifiedParams}`,
        });

        return stringifiedParams;
      },
    },
  );

  return result.data;
}

export async function getHistoryByRegistry(
  page: IPageState,
  sort: Array<SortDescriptor>,
  filterVal: IFilterVal[],
  registry: string,
): Promise<TaskExecutionsDashboardResponse> {
  const result = await dashboardInstance.get<TaskExecutionsDashboardResponse>(
    `/task/executions?scope.registries=${registry}`,
    {
      params: {
        skip: page.skip,
        limit: page.take,
        sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
        sortOrder: sort[0].dir,
        ...prepareFilterVal(filterVal),
      },

      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });
        history.replace({
          pathname: `/lk/assets/registries/${registry}/historytask?${stringifiedParams}`,
        });

        return stringifiedParams;
      },
    },
  );

  return result.data;
}

export async function getHistoryByDevice(
  assetType: 'router' | 'firewall',
  page: IPageState,
  sort: Array<SortDescriptor>,
  filterVal: IFilterVal[],
  assetId: string,
): Promise<TaskExecutionsDashboardResponse> {
  const result = await dashboardInstance.get<TaskExecutionsDashboardResponse>(
    `/task/executions?assetIds=${assetId}`,
    {
      params: {
        skip: page.skip,
        limit: page.take,
        sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
        sortOrder: sort[0].dir,
        ...prepareFilterVal(filterVal),
      },

      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });
        history.replace({
          pathname: `/lk/assets/devices/${assetType}/${assetId}`,
        });

        return stringifiedParams;
      },
    },
  );

  return result.data;
}

export async function getTaskInventorizationId(
  inventorizationId: string | null | undefined,
): Promise<InventorizationResponse> {
  const result = await vmInstance.get<InventorizationResponse>(
    `/inventorizations/${inventorizationId}`,
  );

  return result.data;
}

export async function getResultTaskActions(
  taskExecutionId: string | undefined,
  page: IPageState,
  sort: Array<SortDescriptor>,
  filterVal: IFilterVal[],
): Promise<EnrichedTaskActionsResponse> {
  const result = await dashboardInstance.get<EnrichedTaskActionsResponse>(
    `/task/actions?taskExecutionId=${taskExecutionId}`,
    {
      params: {
        skip: page.skip,
        limit: page.take,
        sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
        sortOrder: sort[0].dir,
        ...prepareFilterVal(filterVal),
      },

      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });
        history.replace({
          pathname: `/lk/task/executions/${taskExecutionId}?${stringifiedParams}`,
        });

        return stringifiedParams;
      },
    },
  );

  return result.data;
}

// export async function getTaskActions(
//   taskActionId: string | undefined,
// ): Promise<EnrichedTaskActionsResponse> {
//   const result = await dashboardInstance.get<EnrichedTaskActionsResponse>(
//     `/task/actions?taskActionId=${taskActionId}`,
//   );

//   return result.data;
// }
export async function getTaskActionById(
  taskActionId: string | undefined,
): Promise<EnrichedTaskActionResponse> {
  const result = await dashboardInstance.get<EnrichedTaskActionResponse>(
    `/task/actions/${taskActionId}`,
  );

  return result.data;
}

export async function getTaskActionsId(
  taskExecutionId: string | undefined,
): Promise<EnrichedTaskActionResponse> {
  const result = await dashboardInstance.get<EnrichedTaskActionResponse>(
    `/task/actions/${taskExecutionId}`,
  );

  return result.data;
}

export async function getArtifactsId(
  taskExecutionId: string | undefined,
  page: IPageState,
  sort: Array<SortDescriptor>,
  filterVal: IFilterVal[],
): Promise<ArtifactsResponse> {
  const result = await vmInstance.get<ArtifactsResponse>(
    `artifacts?taskExecutionId=${taskExecutionId}`,
    {
      params: {
        skip: page.skip,
        limit: page.take,
        sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
        sortOrder: sort[0].dir,
        ...prepareFilterVal(filterVal),
      },

      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });
        history.replace({
          pathname: `/lk/task/executions/${taskExecutionId}?${stringifiedParams}`,
        });

        return stringifiedParams;
      },

      // paramsSerializer: (params) => {
      //   history.replace({
      //     pathname: `/lk/task/executions/${taskExecutionId}?${qs.stringify({
      //       skip: page.skip,
      //       limit: page.take,
      //       sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
      //       sortOrder: sort[0].dir,
      //       ...filterVal,
      //     })}`,
      //   });

      //   return qs.stringify(params, { encode: false });
      // },
    },
  );

  return result.data;
}

export async function getTaskLogs(
  taskExecutionId: string | undefined,
  page: IPageState,
  sort: Array<SortDescriptor>,
  filterVal: IFilterVal[],
): Promise<LogsResponse> {
  const result = await dashboardInstance.get<LogsResponse>(
    `/logs?taskExecutionId=${taskExecutionId}`,
    {
      params: {
        skip: page.skip,
        limit: page.take,
        sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
        sortOrder: sort[0].dir,
        ...prepareFilterVal(filterVal),
      },

      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });
        history.replace({
          pathname: `/lk/task/executions/${taskExecutionId}?${stringifiedParams}`,
        });

        return stringifiedParams;
      },
    },
  );

  return result.data;
}

// export async function getAllTaskLogs(
//   page: IPageState,
//   sort: Array<SortDescriptor>,
//   filterVal: IFilterVal[],
// ): Promise<LogsResponse> {
//   const result = await dashboardInstance.get<LogsResponse>('/logs?type=task', {
//     params: {
//       skip: page.skip,
//       limit: page.take,
//       sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
//       sortOrder: sort[0].dir,
//       ...prepareFilterVal(filterVal),
//     },

//     paramsSerializer: (params) => {
//       const stringifiedParams = qs.stringify(params, { encode: false });
//       history.replace({
//         pathname: `/lk/systemlog?${stringifiedParams}`,
//       });

//       return stringifiedParams;
//     },
//   });

//   return result.data;
// }
