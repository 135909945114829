import { useState, type ReactElement, useCallback } from 'react';
import {
  TabStrip,
  TabStripTab,
  type TabStripSelectEventArguments,
} from '@progress/kendo-react-layout';
import type { AuditDifferenceResponse } from '../../../../../types/__generated/on-premise-solution/api/auditDifferenceResponse.v1';
import type { ApiError } from '../../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { LoadingPanel } from '../../../../common/tableLoader/LoadingPanel';
import localeVmTask from '../../../../../utils/i18n/taskLocale/vmTask.json';
import { DiffVulnerabilitiesList } from './DiffVulnerabilitiesList';
import { DiffPackageList } from './DiffPackageList';
import { DiffVulnSoft } from './DiffVulnSoft';
import { DiffSoftwareListWin } from './DiffSoftwareListWin';
import { DiffMissingKbs } from './DiffMissingKbs';
import styles from '../../forms/AddAssetForm.module.scss';
import hostDescriptionLocale from '../../../../../utils/i18n/assetLocales/hostDescription.json';
import { CommonTooltip } from '../../../../common/CommonTooltip';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeVmTask;

export function DifferenceAuditResult({
  data,
  isLoading,
  error,
  localError,
}: {
  isLoading: boolean;
  data: AuditDifferenceResponse | undefined;
  error: ApiError | null;
  localError?: string | null;
}): ReactElement {
  const [selected, setSelected] = useState(0);

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      setSelected(e.selected);
    },
    [setSelected],
  );

  return (
    <div style={{ position: 'relative', minHeight: '60px', marginTop: '12px' }}>
      {isLoading && <LoadingPanel />}
      {data && !localError && !error && (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <h4
              style={{
                fontSize: '20px',
                fontWeight: '400',
                lineHeight: '125%',
                marginBottom: '32px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {hostDescriptionLocale[currentLocale].changes.afterAuditsInfo}:
            </h4>
            <div className={styles.vulnsMetricItem}>
              <CommonTooltip>
                <div style={{ padding: '12px' }}>
                  {hostDescriptionLocale[currentLocale].changes.auditTooltip.title}.<br />
                  <br />
                  {hostDescriptionLocale[currentLocale].changes.auditTooltip.changesInfo}:<br />
                  <div className={styles.diff_legend}>
                    <div>
                      <span
                        style={{ backgroundColor: 'rgba(255, 0, 0, 0.15)' }}
                        className={styles.legend}
                      >
                        -
                      </span>
                      {hostDescriptionLocale[currentLocale].changes.auditTooltip.updated}
                    </div>
                    <div>
                      <span
                        style={{ backgroundColor: 'rgba(0, 180, 0, 0.1)' }}
                        className={styles.legend}
                      >
                        +
                      </span>
                      <span>
                        {hostDescriptionLocale[currentLocale].changes.auditTooltip.closed}
                      </span>
                    </div>
                    <div>
                      <span className={styles.legend} />
                      {hostDescriptionLocale[currentLocale].changes.auditTooltip.noChanges}
                    </div>
                  </div>
                </div>
              </CommonTooltip>
            </div>
          </div>
          {data?.type === 'linux' && (
            <TabStrip selected={selected} onSelect={handleSelect}>
              <TabStripTab
                title={`${localeVmTask[currentLocale].tabs.vulnSoftLin} ( +${
                  data.vulnerableObjects.updated?.length || 0
                } / -${data.vulnerableObjects.closed?.length || 0} / ${
                  data.vulnerableObjects.noChanges?.length || 0
                })`}
              >
                <DiffVulnSoft data={data} />
              </TabStripTab>
              <TabStripTab
                title={`${localeVmTask[currentLocale].tabs.vulnerabilities} ( +${
                  Object.keys(data.vulns.updated || {}).length || 0
                } / -${Object.keys(data.vulns.closed || {}).length || 0} / ${
                  Object.keys(data.vulns.noChanges || {}).length || 0
                })`}
              >
                <DiffVulnerabilitiesList data={data} />
              </TabStripTab>
              <TabStripTab
                title={`${localeVmTask[currentLocale].tabs.packageList} ( +${
                  data.packages.updated?.length || 0
                } / -${data.packages.closed?.length || 0} / ${
                  data.packages.noChanges?.length || 0
                })`}
              >
                <DiffPackageList packages={data.packages} />
              </TabStripTab>
            </TabStrip>
          )}
          {(data?.type === 'windows' ||
            data?.type === 'network_device' ||
            data?.type === 'hypervisor') && (
            <TabStrip selected={selected} onSelect={handleSelect}>
              <TabStripTab
                title={`${localeVmTask[currentLocale].tabs.vulnSoftWin} ( +${
                  data.vulnerableObjects.updated?.length || 0
                } / -${data.vulnerableObjects.closed?.length || 0} / ${
                  data.vulnerableObjects.noChanges?.length || 0
                } )`}
              >
                <DiffVulnSoft data={data} />
              </TabStripTab>
              <TabStripTab
                title={`${localeVmTask[currentLocale].tabs.vulnerabilities} ( +${
                  Object.keys(data.vulns.updated || {}).length || 0
                } / -${Object.keys(data.vulns.closed || {}).length || 0} / ${
                  Object.keys(data.vulns.noChanges || {}).length || 0
                } )`}
              >
                <DiffVulnerabilitiesList data={data} />
              </TabStripTab>
              {data.type === 'windows' && (
                <TabStripTab
                  title={`${localeVmTask[currentLocale].tabs.missingKBs} ( +${
                    Object.keys(data.missedKb.updated || {}).length || 0
                  } / -${Object.keys(data.missedKb.closed || {}).length || 0} / ${
                    Object.keys(data.missedKb.noChanges || {}).length || 0
                  })`}
                >
                  <DiffMissingKbs data={data.missedKb} />
                </TabStripTab>
              )}
              {data.type === 'windows' && (
                <TabStripTab
                  title={`${localeVmTask[currentLocale].tabs.softwareList} ( +${
                    data.software.updated?.length || 0
                  } / -${data.software.closed?.length || 0} / ${
                    data.software.noChanges?.length || 0
                  })`}
                >
                  <DiffSoftwareListWin data={data.software} />
                </TabStripTab>
              )}
            </TabStrip>
          )}
        </>
      )}
    </div>
  );
}
