/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-nested-ternary */
import type { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useCallback, useEffect } from 'react';
import { useUserModalContext } from '../../hooks/useUserModalContext';
import type { User } from '../../types/__generated/on-premise-solution/api/user.v1';

export function CellSelectUser(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { userTaskEmail, userTaskEmailTemp, setUserTaskEmailTemp } = useUserModalContext();

  useEffect(() => {
    if (!userTaskEmailTemp) {
      setUserTaskEmailTemp(userTaskEmail);
    }
  }, []);

  const addEmail = useCallback(
    ({ email, userId }: { email: string; userId: string }): void => {
      if (userTaskEmailTemp && userTaskEmailTemp.length > 0) {
        if (userTaskEmailTemp?.find((u) => u.userId === userId)) {
          const cutUserEmailForm = userTaskEmailTemp.filter((user) => user.userId !== userId);
          setUserTaskEmailTemp([...cutUserEmailForm]);
        } else {
          setUserTaskEmailTemp([
            ...userTaskEmailTemp,
            {
              email,
              userId,
            },
          ]);
        }
      } else {
        setUserTaskEmailTemp([
          {
            email: dataItem.email,
            userId: dataItem.userId,
          },
        ]);
      }
    },
    [userTaskEmailTemp, setUserTaskEmailTemp, dataItem.email, dataItem.userId],
  );

  return (
    <td className="checkbox_cell">
      <Checkbox
        checked={
          !!userTaskEmailTemp?.find(
            (u) => u.email === dataItem.email && u.userId === dataItem.userId,
          )
        }
        onClick={(): void =>
          addEmail({
            email: dataItem.email,
            userId: dataItem.userId,
          })
        }
      />
    </td>
  );
}
