/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-pascal-case */
import { Typography } from '@progress/kendo-react-common';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useMutation, useQueryClient } from 'react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { Button } from '../../../../common/baseElements/Button';
import localeImageDescription from '../../../../../utils/i18n/assetLocales/imageDescription.json';
import localeImReg from '../../../../../utils/i18n/imageRegister/ImageRegister.json';
import '../../../Host/hostComponents/HostDescription.module.scss';
import localeCommon from '../../../../../utils/i18n/common/common.json';
import { СommonTableLoader } from '../../../../common/tableLoader/CommonTableLoader';
import { RegistryMetaBlock } from './RegistryMetaBlock';
import { RepositoryList } from './RepositoryList';
import { useRegDescriptionRepList } from '../../../../../hooks/components/useRegDescriptionRepList';
import { RegistryTaskHistory } from './RegistryTaskHistory';
import { useCreateTask } from '../../../../../hooks/components/useCreateTask';
import { useRegisterContext } from '../../../../../hooks/useRegisterContext';
import type { IRegisterData } from '../../../../../utils/helpers/types';
import { AddEditRegisterForm } from '../../form/AddEditRegisterForm';
import type { ContainerRegistryDeletionResponse } from '../../../../../types/__generated/on-premise-solution/api/containerRegistryDeletionResponse.v1';
import type { ApiError } from '../../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { deleteRegistry } from '../../../../../services/asset-service';
import { BoundaryErrorComponent } from '../../../../common/BoundaryErrorComponent';
import { CommonWebhook } from '../../../../common/CommonWebhook';
import { getUserData } from '../../../../../services/local-storage-service';
import { DeleteConfirmModal } from '../../../../common/modal/DeleteConfirmModal';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeImageDescription;

export function RegistryDescription(): React.ReactElement {
  const currentUser = getUserData();

  const history = useHistory();

  const queryClient = useQueryClient();

  const [isConfirm, setConfirm] = useState(false);

  const { registryId } = useParams<{ registryId: string }>();

  const { queryDashboardRegistryId } = useRegDescriptionRepList();

  const createdById =
    queryDashboardRegistryId.data?.createdBy.source === 'user' &&
    queryDashboardRegistryId.data?.createdBy.id;

  const { createTaskForRegistry } = useCreateTask();

  const { isAddRegister, registerData, setRegisterData, showRegWebHooks, setShowRegWebHooks } =
    useRegisterContext();

  const [selected, setSelected] = useState<number>(
    document.location.pathname.includes('repositories') ? 0 : 1,
  );

  const handleConfirm = (): void => {
    setConfirm(true);
  };

  function addDataForEdit(): void {
    const payloadRegisterData: IRegisterData = {
      registryId,
      name: queryDashboardRegistryId.data?.name || '',
      address: queryDashboardRegistryId.data?.address || '',
      credential: queryDashboardRegistryId.data?.credential?.credentialId
        ? {
            credentialId: queryDashboardRegistryId.data?.credential?.credentialId,
            name: queryDashboardRegistryId.data?.credential?.name,
            type: queryDashboardRegistryId.data?.credential?.type,
          }
        : undefined,
      repositories: queryDashboardRegistryId.data?.repositories || [],
      description: queryDashboardRegistryId.data?.description || '',
    };

    setRegisterData(payloadRegisterData);
  }

  const mutation = useMutation<ContainerRegistryDeletionResponse | null, ApiError, string>(
    (payload) => deleteRegistry(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('registryList');
      },
    },
  );

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      if (e.selected === 0) {
        history.replace(`/lk/assets/registries/${registryId}/repositories`);
        setSelected(e.selected);
      }
      if (e.selected === 1) {
        history.replace(`/lk/assets/registries/${registryId}/historytask`);
        setSelected(e.selected);
      }
    },
    [history, registryId],
  );

  const handleDelete = (): void => {
    mutation.mutate(registryId);
    setConfirm(false);
    history.push('/lk/assets/registries');
  };

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      <div className="regisrtydescription">
        {(isAddRegister || registerData) && <AddEditRegisterForm />}
        {showRegWebHooks && (
          <CommonWebhook
            title={localeCommon[currentLocale].webHook.titleRegistry}
            id={registryId}
            name={queryDashboardRegistryId.data?.name || ''}
          />
        )}
        {isConfirm && (
          <DeleteConfirmModal
            name={queryDashboardRegistryId.data?.name || '-'}
            type="registry"
            onClose={(): void => setConfirm(false)}
            handleDelete={handleDelete}
            errorMessage={mutation.error?.message}
          />
        )}
        <div className="common-header-page">
          <Typography.h3 className="title">
            {localeImageDescription[currentLocale].registryTitle}
            {queryDashboardRegistryId.data?.name || ''}
          </Typography.h3>
          <div className="topactions">
            <Button
              className="action"
              onClick={(): void => {
                createTaskForRegistry('registry', registryId, queryDashboardRegistryId.data?.name);
              }}
            >
              {localeImageDescription[currentLocale].btnCreateTask}
            </Button>
            {currentUser?.role !== 'reader_user' && (
              <div className="button-group edit-group">
                <Button
                  onClick={(): void => addDataForEdit()}
                  disabled={
                    currentUser?.role !== 'account_admin' &&
                    currentUser?.role !== 'super_admin' &&
                    currentUser?.id !== createdById
                  }
                >
                  {localeImReg[currentLocale].btnEdit}
                </Button>
                <Button
                  onClick={handleConfirm}
                  disabled={
                    currentUser?.role !== 'account_admin' &&
                    currentUser?.role !== 'super_admin' &&
                    currentUser?.id !== createdById
                  }
                >
                  {localeImReg[currentLocale].btnDelete}
                </Button>
              </div>
            )}
            <Button
              // fillMode="flat"
              className="buttons-container-button"
              onClick={(): void => setShowRegWebHooks(true)}
              imageUrl={`${process.env.PUBLIC_URL}/images/webhook.svg`}
            >
              {localeImReg[currentLocale].webhook}
            </Button>
          </div>
          <СommonTableLoader queryData={queryDashboardRegistryId.isLoading} filteringData={false} />
        </div>
        {queryDashboardRegistryId.data && <RegistryMetaBlock />}
        <TabStrip selected={selected} onSelect={handleSelect}>
          <TabStripTab title={localeImageDescription[currentLocale].registries.tabs.repositories}>
            <RepositoryList />
          </TabStripTab>
          <TabStripTab title={localeImageDescription[currentLocale].registries.tabs.taskHistory}>
            <RegistryTaskHistory />
          </TabStripTab>
        </TabStrip>
      </div>
    </ErrorBoundary>
  );
}
