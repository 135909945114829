import type { SortDescriptor } from '@progress/kendo-data-query';
import type { GridSortChangeEvent } from '@progress/kendo-react-grid';

export function handleSort(
  e: GridSortChangeEvent,
  setSort: (value: React.SetStateAction<SortDescriptor[]>) => void,
): void {
  if (e.sort.length > 0) {
    setSort([...e.sort]);
  } else {
    setSort([{ field: '', dir: 'desc' }]);
  }
}
