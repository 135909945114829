import { Filter } from '@progress/kendo-react-data-tools';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Button } from '../baseElements/Button';
import { СommonTableLoader } from '../tableLoader/CommonTableLoader';
import type localeHost from '../../../utils/i18n/assetLocales/hostList.json';
import { useHostModalContext } from '../../../hooks/useHostModalContext';
import localeNewTask from '../../../utils/i18n/taskLocale/newTask.json';
import { getUrlParamsAddHosts } from '../../../utils/helpers/add-host-list-helper';
import { CommonHostList } from '../CommonHostList';
import { FILTER_HOSTLIST } from '../../../utils/helpers/constant-serv-filter-host';
import { useHostList } from '../../../hooks/components/useHostList';
import { Input } from '../baseElements/Input';
import { INITIAL_SORT_HOST } from '../../../utils/helpers/constants';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHost;

export function HostListFormTab(): React.ReactElement {
  const dataForHooks = {
    name: 'latestInventorization.ip',
    componentName: 'AddHostList',
  };

  const urlParams = getUrlParamsAddHosts(dataForHooks.componentName, INITIAL_SORT_HOST);

  const { hostFormTemp } = useHostModalContext();

  const {
    filter,
    onFilterChange,
    customSearch,
    sort,
    dataDashboard,
    page,
    handleCustomSearch,
    total,
    pageChange,
    setSort,
    isLoading,
    filterStatus,
    isError,
    errorMessage,
    addAllAsset,
    cancelAllAsset,
    cancelAssetTypeHost,
  } = useHostList(urlParams, dataForHooks);

  return (
    <div className="k-form">
      <div className="form-content select-asset">
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <div className="filter-line">
              <Filter
                value={filter}
                onChange={onFilterChange}
                fields={FILTER_HOSTLIST[currentLocale]}
              />
              {filter.filters.length === 0 && (
                <Input
                  name="customSearch"
                  placeholder={localeNewTask[currentLocale].customSearchIp}
                  value={customSearch}
                  onChange={(e): void => handleCustomSearch(e)}
                />
              )}
            </div>
          </IntlProvider>
        </LocalizationProvider>
        <div className="k-form-select-buttons">
          <Button onClick={addAllAsset}>{localeNewTask[currentLocale].form.btnAddAllAssets}</Button>
          {hostFormTemp &&
          hostFormTemp.length > 0 &&
          hostFormTemp.filter((h) => h.assetId)?.length ? (
            <Button onClick={cancelAssetTypeHost}>
              {localeNewTask[currentLocale].form.btnDelAllAssets} (
              {hostFormTemp.filter((h) => h.assetId)?.length})
            </Button>
          ) : null}
        </div>
        <CommonHostList
          sort={sort}
          dataDashboard={dataDashboard}
          page={page}
          total={total}
          pageChange={pageChange}
          setSort={setSort}
          dataForHooks={dataForHooks}
          conditionSort={15}
        />
        <СommonTableLoader queryData={isLoading} filteringData={filterStatus} />
        {isError && <div className="info alert alert-danger">Error: {errorMessage}</div>}
      </div>
    </div>
  );
}
