/* eslint-disable react/jsx-pascal-case */
import { Typography } from '@progress/kendo-react-common';
import { useQueryClient } from 'react-query';
import localeHostGroups from '../../../utils/i18n/assetLocales/assetGroups.json';
import { EditGroupForm } from './form/EditGroup';
import { useGroupContext } from '../../../hooks/useGroupContext';
import { getUrlHostGr } from '../../../utils/helpers/host-group-list-helper';
import type { IDataHooks } from '../../../utils/helpers/types';
import { CommonGroupList } from '../../common/CommonGroupList';
import { Button } from '../../common/baseElements/Button';
import { getUserData } from '../../../services/local-storage-service';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHostGroups;

export function GroupList(): React.ReactElement {
  const currentUser = getUserData();

  const urlParams = getUrlHostGr('HostGroupList');

  const queryClient = useQueryClient();

  const { isOpen, setIsOpen } = useGroupContext();

  const dataForHooks: IDataHooks = {
    name: 'name',
    componentName: 'HostGroupList',
  };

  const editThisGroup = (): void => {
    setIsOpen(true);
  };

  const handleRefresh = (): void => {
    queryClient.invalidateQueries('HostGroupList');
  };

  return (
    <div className="groups">
      <div className="common-header-page">
        <Typography.h3>{localeHostGroups[currentLocale].dialogTitle}</Typography.h3>
        <div className="topactions">
          {currentUser?.role !== 'reader_user' && (
            <Button fill="action" onClick={editThisGroup}>
              {localeHostGroups[currentLocale].btnAddGroup}
            </Button>
          )}
          <Button onClick={handleRefresh}>{localeHostGroups[currentLocale].btnRefresh}</Button>
        </div>
      </div>
      <CommonGroupList dataForHooks={dataForHooks} urlParams={urlParams} />
      {isOpen && <EditGroupForm />}
    </div>
  );
}
