/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-nested-ternary */
import { Grid, GridColumn, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useState } from 'react';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import type {
  InventorizationFirewallCiscoResult,
  InventorizationResponse,
  InventorizationRouterCiscoResult,
  InventorizationFirewallCheckpointResult,
} from '../../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import type { InventorizationFirewallPaloAltoNetworksResult } from '../../../../../types/__generated/on-premise-solution/api/inventorizationDeletionResponse.v1';
import { SizeCell } from '../../../../common/cells/SizeCell';
import { StandartCell } from '../../../../common/cells/StandartCell';
import { BoolCell } from '../../../../common/cells/BoolCell';
import { WrapperMenu } from './WrapperMenu';
import { LAZY_PAGEABLE_DATA } from '../../../../../utils/helpers/constants';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function FileSystemNetDevices({
  data,
}: {
  data: InventorizationResponse;
}): React.ReactElement {
  const { result } = data;

  const { file_system } = result as unknown as
    | InventorizationFirewallCiscoResult
    | InventorizationRouterCiscoResult
    | InventorizationFirewallPaloAltoNetworksResult
    | InventorizationFirewallCheckpointResult;

  const [page, setPage] = useState({ take: 5, skip: 0 });

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  return (
    <WrapperMenu title={locale[currentLocale].fileSystem.title}>
      {data.class === 'cisco' && (
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <Grid
              data={
                (data.type === 'router' || data.type === 'firewall') && data.class === 'cisco'
                  ? file_system?.slice(page.skip, page.take + page.skip)
                  : null
              }
              pageable={LAZY_PAGEABLE_DATA}
              scrollable="none"
              total={
                (data.type === 'router' || data.type === 'firewall') && data.class === 'cisco'
                  ? file_system?.length
                  : 0
              }
              pageSize={page.take}
              skip={page.skip}
              onPageChange={pageChange}
              className="no-margin-top"
            >
              <GridColumn
                field="flags"
                title={locale[currentLocale].fileSystem.flags}
                cell={StandartCell}
              />
              <GridColumn
                field="free"
                title={locale[currentLocale].fileSystem.free}
                cell={SizeCell}
              />
              <GridColumn
                field="isDefault"
                title={locale[currentLocale].fileSystem.isDefault}
                cell={BoolCell}
              />
              <GridColumn
                field="prefixes"
                title={locale[currentLocale].fileSystem.prefixes}
                cell={StandartCell}
              />
              <GridColumn
                field="size"
                title={locale[currentLocale].fileSystem.size}
                cell={SizeCell}
              />
              <GridColumn
                field="type"
                title={locale[currentLocale].fileSystem.type}
                cell={StandartCell}
              />
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      )}
      {data.class === 'juniper' && (
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <Grid
              data={
                Array.isArray(file_system)
                  ? file_system.slice(page.skip, page.take + page.skip)
                  : null
              }
              pageable={LAZY_PAGEABLE_DATA}
              scrollable="none"
              total={Array.isArray(file_system) ? file_system.length : 0}
              pageSize={page.take}
              skip={page.skip}
              onPageChange={pageChange}
              className="no-margin-top"
            >
              <GridColumn
                field="device"
                title={locale[currentLocale].fileSystem.device}
                cell={StandartCell}
              />
              <GridColumn
                field="free"
                title={locale[currentLocale].fileSystem.free}
                cell={SizeCell}
              />
              <GridColumn
                field="size"
                title={locale[currentLocale].fileSystem.size}
                cell={SizeCell}
              />
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      )}
      {(data.class === 'paloaltonetworks' || data.class === 'checkpoint') && (
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <Grid
              data={
                Array.isArray(file_system)
                  ? file_system.slice(page.skip, page.take + page.skip)
                  : null
              }
              pageable={LAZY_PAGEABLE_DATA}
              scrollable="none"
              total={Array.isArray(file_system) ? file_system.length : 0}
              pageSize={page.take}
              skip={page.skip}
              onPageChange={pageChange}
              className="no-margin-top"
            >
              <GridColumn
                field="device"
                title={locale[currentLocale].fileSystem.device}
                cell={StandartCell}
                className="title"
              />
              <GridColumn
                field="size"
                title={locale[currentLocale].fileSystem.size}
                cell={SizeCell}
              />
              {data.class === 'checkpoint' && (
                <GridColumn
                  field="used"
                  title={locale[currentLocale].fileSystem.used}
                  cell={SizeCell}
                />
              )}
              <GridColumn
                field="available"
                title={locale[currentLocale].fileSystem.available}
                cell={SizeCell}
              />
              <GridColumn
                field="path"
                title={locale[currentLocale].fileSystem.path}
                cell={StandartCell}
              />
              {data.class === 'checkpoint' && (
                <GridColumn
                  field="type"
                  title={locale[currentLocale].fileSystem.type}
                  cell={StandartCell}
                />
              )}
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      )}
    </WrapperMenu>
  );
}
