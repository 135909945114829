import type { ReactElement, ReactNode } from 'react';
import styles from './Button.module.scss';

interface IButtonProps {
  id?: string;
  name?: string;
  fill?:
    | 'action'
    | 'flat'
    | 'menu'
    | 'copy'
    | 'task_asset'
    | 'modal'
    | 'link'
    | 'dotted'
    | 'delete';
  textColor?: 'green';
  imageUrl?: string;
  excel?: boolean;
  style?: React.CSSProperties;
  className?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  children?: ReactNode;
  icon?: string;
  disabled?: boolean;
  tabIndex?: number;
  title?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export function Button(props: IButtonProps): ReactElement {
  const {
    id,
    name,
    fill,
    textColor,
    imageUrl,
    excel,
    style,
    className,
    type,
    children,
    icon,
    disabled,
    tabIndex,
    title,
    onClick,
  } = props;

  return (
    <button
      id={id}
      style={style}
      name={name}
      // eslint-disable-next-line react/button-has-type
      type={type}
      tabIndex={tabIndex}
      className={`${styles.btn} ${className || ''} ${fill === 'action' ? styles.action : ''} ${
        fill === 'menu' ? styles.menu : ''
      } ${fill === 'flat' ? styles.flat : ''} ${fill === 'link' ? styles.link : ''} ${
        fill === 'dotted' ? styles.dotted : ''
      } ${fill === 'copy' ? styles.copy : ''} ${fill === 'task_asset' ? styles.task_asset : ''} ${
        fill === 'modal' ? styles.modal : ''
      } ${fill === 'delete' ? styles.delete : ''}
      ${textColor === 'green' ? styles.text_green : ''} ${excel ? styles.excel : ''}`}
      // className={className}
      disabled={disabled}
      title={title}
      onClick={onClick}
    >
      {icon === 'hide' && <span className="v-icon hide-passwd-icon" />}
      {icon === 'show' && <span className="v-icon show-passwd-icon" />}
      {imageUrl && <img className="v-icon" src={imageUrl} alt="" />}
      {(fill === 'copy' || icon === 'copy') && (
        <span role="presentation" className={`${styles.copy_icon} k-icon k-i-copy`} />
      )}
      {icon === 'refresh' && <span className="v-icon refresh-icon" />}
      {icon === 'plus' && <span className="k-icon k-i-plus" />}
      {icon === 'credentials' && <span className={`${styles.icon} k-icon credentials-menu-icon`} />}
      {icon === 'delete' && <span className={`${styles.icon} v-icon delete-icon`} />}
      {icon === 'edit' && <span className={`${styles.icon} v-icon edit-icon`} />}
      {icon === 'repeat' && <span className={`${styles.icon} v-icon repeat-icon`} />}
      {icon === 'drop' && <span className={`${styles.icon} v-icon drop-icon`} />}
      {icon === 'filterTag' && <span className={`${styles.icon} v-icon filterTag-icon`} />}
      {icon === 'graphic' && <span className="v-icon graphic-icon" />}

      {children}
    </button>
  );
}
