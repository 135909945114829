import type { SortDescriptor } from '@progress/kendo-data-query';
import { INITIAL_SORT, INITIAL_PAGING } from './constants';
import type { IPageState } from './types';

export interface IFilterUrlList {
  type?: string;
  bulletinId?: string;
  whitelistedBulletinId?: string;
}
export interface IUrlParamsList {
  sort: SortDescriptor[];
  page: IPageState;
  pageLimitGrid: { [key: string]: string } | null;
  filterUrlData: IFilterUrlList;
}

export function getUrlList(
  pageLimitGridKey: string,
  individualSort?: SortDescriptor[] | undefined,
): IUrlParamsList {
  const pageLimitGrid: IUrlParamsList['pageLimitGrid'] =
    localStorage.getItem('pageLimitGrid') &&
    JSON.parse(localStorage.getItem('pageLimitGrid') || '');

  const locationUrlData: { [name: string]: string } | null = {};
  if (document.location.search) {
    const locationSearch = document.location.search.slice(1).split('&');
    locationSearch.forEach((val) => {
      const value = val.split('=');
      if (value[0] === 'type[$eq]') {
        locationUrlData.type = decodeURI(value[1]) || '';
      }
      if (value[0] === 'bulletinId[$eq]') {
        locationUrlData.bulletinId = decodeURI(value[1]) || '';
      }
      if (value[0] === 'whitelistedBulletinId[$eq]') {
        locationUrlData.whitelistedBulletinId = decodeURI(value[1]) || '';
      }
      locationUrlData[value[0]] = value[1] || '';
    });
  }

  const { sortField, sortOrder, skip } = locationUrlData;
  const sort: SortDescriptor[] =
    sortField && sortOrder && (sortOrder === 'asc' || sortOrder === 'desc')
      ? [{ field: sortField, dir: sortOrder }]
      : individualSort || INITIAL_SORT;

  const page: IPageState = {
    skip: pageLimitGridKey.includes('add')
      ? INITIAL_PAGING.skip
      : Number(skip) || INITIAL_PAGING.skip,
    take:
      pageLimitGrid && Number(pageLimitGrid[pageLimitGridKey])
        ? Number(pageLimitGrid[pageLimitGridKey])
        : INITIAL_PAGING.take,
  };

  const filterUrlData = {} as IFilterUrlList;

  if (locationUrlData.osNameRegex) {
    filterUrlData.type = locationUrlData.type;
  }

  if (locationUrlData.bulletinId) {
    filterUrlData.bulletinId = locationUrlData.bulletinId;
  }
  if (locationUrlData.whitelistedBulletinId) {
    filterUrlData.whitelistedBulletinId = locationUrlData.whitelistedBulletinId;
  }

  return {
    sort,
    page,
    pageLimitGrid,
    filterUrlData,
  };
}
