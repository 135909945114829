import { vmInstance } from '../lib/axios/hooks/useVmAxios';
import type { License } from '../types/__generated/on-premise-solution/api/license.v1';
import type { ConfigResponse } from '../types/__generated/on-premise-solution/api/configResponse.v1';
import type { ChannelUpdatesResponse } from '../types/__generated/on-premise-solution/api/channelUpdatesResponse.v1';
import type { SystemStatusResponse } from '../types/__generated/on-premise-solution/api/systemStatusResponse.v1';

export async function getLicense(): Promise<License> {
  const result = await vmInstance.get<License>('/system/license');

  return result.data;
}

export async function getStatus(): Promise<SystemStatusResponse> {
  const result = await vmInstance.get<SystemStatusResponse>('/system/status');

  return result.data;
}

export async function getConfig(): Promise<ConfigResponse> {
  const result = await vmInstance.get<ConfigResponse>('/system/config');

  return result.data;
}

export async function getChannels(): Promise<ChannelUpdatesResponse> {
  const result = await vmInstance.get<ChannelUpdatesResponse>('/system/update-channels');

  return result.data;
}

// export async function checkLicense(): Promise<SystemStatusResponse> {
//   const result = await vmInstance.get<SystemStatusResponse>('/system/status');

//   return result.data;
// }
