import type { GridCellProps } from '@progress/kendo-react-grid';
import { IMG_SRC_VALUES } from '../../../../../utils/helpers/constants';
import localeHost from '../../../../../utils/i18n/assetLocales/hostList.json';
import type { AssetImageDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/assetsImagesDashboardResponse.v1';
import { getMainPartOfOsName } from '../../../../../utils/helpers/inventorization-helper';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHost;
export function ImageSystem(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { latestInventorization }: AssetImageDashboardResponse = dataItem;

  let osName = '';
  const osVersion = latestInventorization.os?.version;
  if (latestInventorization?.os?.type === 'linux') {
    osName = getMainPartOfOsName(latestInventorization.os.name);
  }

  return (
    <td style={{ position: 'relative' }}>
      {latestInventorization ? (
        <div className="cell_os">
          <img
            src={`${process.env.PUBLIC_URL}/images/os_logo/${IMG_SRC_VALUES[osName.toLowerCase()]}`}
            alt={osName}
          />
          <span style={{ textTransform: 'capitalize' }}>{osName}</span>
          <span>{osVersion}</span>
        </div>
      ) : (
        <span>{localeHost[currentLocale].form.waitingForData}</span>
      )}
    </td>
  );
}
