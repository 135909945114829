import '../../TaskDescription.module.scss';
import { useParams } from 'react-router-dom';
import localeAuditTask from '../../../../../utils/i18n/taskLocale/auditTask.json';
import { Button } from '../../../../common/baseElements/Button';
import styles from './packageNameModal.module.scss';
import { useHostModalContext } from '../../../../../hooks/useHostModalContext';
import { useCreateTask } from '../../../../../hooks/components/useCreateTask';
import { useAssetCreatePropContext } from '../../../../../hooks/useAssetCreatePropContext';
import type { TKbs, TMissingKBsActionsProp } from '../../../../../utils/helpers/types';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

export function MissingKBsActions(props: TMissingKBsActionsProp): React.ReactElement {
  const { isEnabledWl, hostName, id, kbsDataForSelect, kbsDataForSelectWl } = props;

  const { assetId } = useParams<{ assetId: string }>();

  const { missedKbs, kbsInWl, setMissedKbs, setKbsInWl, setKbWlTask } = useHostModalContext();

  const { createTaskForAsset } = useCreateTask();

  const { setAddVulnToWLForm } = useAssetCreatePropContext();

  const handleTaskForAsset = (type: string): void => {
    createTaskForAsset({
      val: 'kb_install',
      data: undefined,
      assetId,
      hostname: hostName || undefined,
    });
    setKbWlTask(type === 'wl');
  };

  const selectAllKbsOnPage = (kbsOnPage: TKbs[] | undefined, type?: string): void => {
    if (type) {
      if (missedKbs && kbsOnPage) {
        const selectedAndOnPage = [...missedKbs, ...kbsOnPage];

        const duplicatValChecked = selectedAndOnPage.filter(
          (kbs, i, arr) => arr.findIndex((k) => k.id === kbs.id) === i,
        );

        setMissedKbs(duplicatValChecked);
      }
      if (!missedKbs && kbsOnPage) setMissedKbs(kbsOnPage);
    }
    if (!type) {
      if (kbsInWl && kbsOnPage) {
        const selectedAndOnPage = [...kbsInWl, ...kbsOnPage];

        const duplicatValChecked = selectedAndOnPage.filter(
          (kbs, i, arr) => arr.findIndex((k) => k.id === kbs.id) === i,
        );

        setKbsInWl(duplicatValChecked);
      }
      if (!kbsInWl && kbsOnPage) setKbsInWl(kbsOnPage);
    }
  };

  return (
    <div className={styles.kbUpdate}>
      {!id && (
        <Button
          onClick={(): void => selectAllKbsOnPage(kbsDataForSelect, 'notWl')}
          className="selected-pkgs button-mr20"
        >
          {localeAuditTask[currentLocale].vulnsSoft.selectAllVulnerabilities}
        </Button>
      )}
      {!id && missedKbs && missedKbs.length > 0 && (
        <Button
          onClick={(): void => handleTaskForAsset('notWl')}
          className="selected-pkgs action button-mr20"
        >
          {localeAuditTask[currentLocale].updateSelectedKb} ({missedKbs.length})
        </Button>
      )}
      {id && (
        <Button
          onClick={(): void => selectAllKbsOnPage(kbsDataForSelectWl)}
          className="selected-pkgs button-mr20"
        >
          {localeAuditTask[currentLocale].vulnsSoft.selectAllVulnerabilities}
        </Button>
      )}
      {id && kbsInWl && kbsInWl.length > 0 && (
        <Button
          onClick={(): void => handleTaskForAsset('wl')}
          className="selected-pkgs action button-mr20"
        >
          {localeAuditTask[currentLocale].updateSelectedKb} ({kbsInWl.length})
        </Button>
      )}
      {!id && missedKbs && missedKbs.length > 0 && !isEnabledWl && (
        <Button onClick={(): void => setAddVulnToWLForm(true)} className={styles.kbToInclude}>
          {localeAuditTask[currentLocale].vulnsSoft.excludeSelectedKbs} ({missedKbs.length})
        </Button>
      )}
      {!id && missedKbs && missedKbs.length > 0 && (
        <Button onClick={(): void => setMissedKbs(null)} className="cancel-selected-pkgs">
          {localeAuditTask[currentLocale].cancelSelectedPackages}
        </Button>
      )}
      {id && kbsInWl && kbsInWl.length > 0 && (
        <Button onClick={(): void => setKbsInWl(null)} className="cancel-selected-pkgs">
          {localeAuditTask[currentLocale].cancelSelectedPackages}
        </Button>
      )}
    </div>
  );
}
