import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { nanoid } from 'nanoid';
import styles from './HostDescription.module.scss';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import type { Tag } from '../../../../types/__generated/on-premise-solution/api/assetHostDashboardResponse.v1';
import type { AssetUpdateRequest } from '../../../../types/__generated/on-premise-solution/api/assetUpdateRequest.v1';
import { useHostActions } from '../../../../hooks/components/useHostActions';
import { Input } from '../../../common/baseElements/Input';
import type { TagUpdateResponse } from '../../../../types/__generated/on-premise-solution/api/tagUpdateResponse.v1';
import type { TagUpdateRequest } from '../../../../types/__generated/on-premise-solution/api/tagUpdateRequest.v1';
import { editTag, getAllTags } from '../../../../services/asset-service';
import localeHostDescription from '../../../../utils/i18n/assetLocales/hostDescription.json';
import type { TagsResponse } from '../../../../types/__generated/on-premise-solution/api/tagsResponse.v1';
import { Button } from '../../../common/baseElements/Button';
import { useAssetActions } from '../../../../hooks/components/useAssetActions';
import { useAssetCreatePropContext } from '../../../../hooks/useAssetCreatePropContext';

interface IEditTagBlock {
  assetId: string;
  tags: Tag[] | null | undefined;
  setHovered: React.Dispatch<React.SetStateAction<string>>;
  keyValue: number | null;
  currentTagId: string;
  beforeEditVal: string;
}

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHostDescription;

export function EditTagBlock(propEditTagBlock: IEditTagBlock): React.ReactElement {
  const queryClient = useQueryClient();

  const { assetId, tags, setHovered, keyValue, currentTagId, beforeEditVal } = propEditTagBlock;

  const [editTagError, setEditTagError] = useState<string | null>(null);

  const isExistingTag = useRef(true);

  const currentTagName = useRef<string | null>(null);

  const { handleEditHost } = useHostActions();

  const { setTagMenu, editTagData, setEditTagData, tagName, setTagName } =
    useAssetCreatePropContext();

  const { handleEditTag, addExistingTag, getTextColor } = useAssetActions();

  const query = useQuery<TagsResponse, ApiError>(['tagsList', tagName], () => getAllTags(tagName), {
    keepPreviousData: true,
  });

  const mutation = useMutation<TagUpdateResponse, ApiError, TagUpdateRequest>(
    (payload) => editTag(editTagData?.tagId || '', payload),
    {
      onSuccess: (resp) => {
        const val = {
          tagIdsToAdd: [resp.tagId],
          type: 'host',
        } as AssetUpdateRequest;

        queryClient.invalidateQueries('tagsList').then(() => {
          setEditTagData(null);
          handleEditHost(assetId, val);
          isExistingTag.current = true;
        });
      },
      onError: (resp) => {
        if (resp.code && resp.code === '409') {
          setEditTagError(`${localeHostDescription[currentLocale].summary.confirmDelTag}`);
        } else {
          setEditTagError(`${resp.code} ${resp.type}`);
        }
      },
    },
  );

  const dataEdit = useMemo(
    () => ({
      editTagError,
      tags,
      isExistingTag,
      setEditTagError,
      mutation,
      setHovered,
      keyValue,
    }),
    [editTagError, keyValue, mutation, setHovered, tags],
  );

  const dataAddExistTag = {
    query,
    currentTagName,
    currentTagId,
    tags,
    assetId,
    setHovered,
    setEditTagError,
    beforeEditVal,
  };

  const changeTagName = useCallback(
    (value: string): void => {
      if (editTagError) {
        setEditTagError(null);
      }
      isExistingTag.current = false;
      setTagName(() => value);
    },
    [editTagError, setTagName],
  );

  const handleKeydown = useCallback(
    (e: KeyboardEvent): void => {
      if (e.key === 'Escape') {
        setEditTagData(null);
        setHovered('');
        setTagMenu(false);
      }
      if (e.key === 'Enter') {
        handleEditTag({ ...dataEdit, name: tagName });
        setEditTagData(null);
        setHovered('');
        setTagMenu(false);
      }
    },
    [dataEdit, handleEditTag, setEditTagData, setHovered, setTagMenu, tagName],
  );

  const closeEditing = useCallback((e: any): void => {
    isExistingTag.current = true;
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);
    // document.addEventListener('click', closeEditing);

    // return () => {
    //   document.removeEventListener('keydown', handleKeydown);
    //   // document.addEventListener('click', closeEditing);
    // };
  }, [handleKeydown]);

  useEffect(() => {
    return () => {
      setEditTagData(null);
      document.removeEventListener('keydown', handleKeydown);
    };
  }, []);

  return (
    <div className={styles.editTagInputWrap}>
      <Input
        className="edit-tag-input"
        name="edit"
        value={tagName}
        onChange={(e): void => changeTagName(e.target.value)}
        onBlur={(e): void => handleEditTag({ ...dataEdit, name: e.target.value.trim() })}
        autoFocus
      />
      <div
        className={
          editTagError ? `error-common-field ${styles.errorFieldUnderTag}` : 'error-common-field'
        }
      >
        <span>{editTagError}</span>
      </div>
      {editTagError === null && (
        <div className={styles.editAllTags} onMouseEnter={closeEditing}>
          {query.data?.data.map((t) => (
            <Button
              key={nanoid()}
              onClick={(): void =>
                addExistingTag({ ...dataAddExistTag, existedTagName: t.name, tagId: t.tagId })
              }
              className={styles.editTagBtn}
            >
              {/* <span>{t.name}</span> */}
              <div
                key={nanoid()}
                className={styles.tagContentWrap}
                // style={{ backgroundColor: t.color }}
              >
                {/* <span className="tagTitle" style={{ color: getTextColor(t.color) }}>
                  {t.name}
                </span> */}
                <span className="tagTitle" style={{ color: t.color }}>
                  {t.name}
                </span>
              </div>
            </Button>
          ))}
        </div>
      )}
    </div>
  );
}
