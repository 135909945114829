import type { IFilterVal } from '../utils/filtering-in-table-test';

export function prepareFilterVal(filterVal: IFilterVal[], logicType?: 'and' | 'or'): any {
  const mutableFilterVal = filterVal.map((f) => {
    if (Object.values(f).find((fl) => Object.keys(fl).includes('$regex'))) {
      const keyValue = Object.keys(f)[0];

      const filterContent = { [keyValue]: { ...Object.values(f)[0], $options: 'i' } };

      return filterContent;
    }

    return { ...f };
  });

  const resultFilterData =
    mutableFilterVal && mutableFilterVal.length > 1
      ? { [logicType ? `$${logicType}` : '$and']: mutableFilterVal }
      : mutableFilterVal[0];

  return resultFilterData;
}
