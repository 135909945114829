/* eslint-disable react/jsx-no-useless-fragment */
import { nanoid } from 'nanoid';
import type { IMultilineTooltipProp } from '../../utils/helpers/types';

export function CommonTooltipMultiline({
  isTooltip,
  tooltipProp,
}: {
  isTooltip: boolean;
  tooltipProp: IMultilineTooltipProp;
}): React.ReactElement {
  return (
    <>
      {isTooltip && (
        <div
          className="multiline-tooltip-wrap"
          style={{
            marginTop: tooltipProp.marginTop,
            marginLeft: tooltipProp.marginLeft,
            padding: tooltipProp.padding,
            fontSize: tooltipProp.fontSize,
            width: tooltipProp.width,
          }}
        >
          {tooltipProp.text.map((t, i) => (
            <div
              key={nanoid()}
              className="content-wrap"
              style={{ marginBottom: i + 1 !== tooltipProp.text.length ? '4px' : '' }}
            >
              <span className="value-text">{t.val}</span>
              <span>{t.des}</span>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
