/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useState } from 'react';
import { loadMessages } from '@progress/kendo-react-intl';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import locale from '../../../utils/i18n/security/vulnerabilities.json';
import { SecurityVulnsList } from './SecurityVulnsList';
// import '../../TaskDescription.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

loadMessages(locale[currentLocale], currentLocale);

export function TabsVulnsDetails(): React.ReactElement {
  const [selected, setSelected] = useState<number>(0);

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      setSelected(e.selected);
    },
    [setSelected],
  );

  return (
    <TabStrip selected={selected} onSelect={handleSelect}>
      <TabStripTab title={locale[currentLocale].tabs.vulnerabilities}>
        <SecurityVulnsList />
      </TabStripTab>
      {/* <TabStripTab title={locale[currentLocale].tabs.vulnerableLinuxSoftware} disabled />
      <TabStripTab title={locale[currentLocale].tabs.vulnerableLinuxSoftware} disabled /> */}
    </TabStrip>
  );
}
