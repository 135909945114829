import type { GridCellProps } from '@progress/kendo-react-grid';
import type {
  TaskAgentUpdateAction,
  TaskAuditAction,
  TaskGroupReportAction,
  TaskInventorizationAction,
  TaskSingleReportAction,
} from '../../../../../types/__generated/on-premise-solution/api/taskActionsResponse.v1';

export function CellLastActivity(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const {
    lastActivity,
  }:
    | TaskAuditAction
    | TaskInventorizationAction
    | TaskAgentUpdateAction
    | TaskGroupReportAction
    | TaskSingleReportAction = dataItem;

  return <td>{new Date(lastActivity).toLocaleString()}</td>;
}
