import type { GridCellProps } from '@progress/kendo-react-grid';
import locale from '../../../../../../utils/i18n/taskLocale/inventorizationTask.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;
export function CellWinService(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  interface IStartType {
    DEMAND_START: string;
    DISABLED: string;
    AUTO_START: string;
  }

  return (
    <td>
      {locale[currentLocale].servicesWin.startTypeValues[dataItem.start_type as keyof IStartType]}
    </td>
  );
}
