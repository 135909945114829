/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import type { GridCellProps } from '@progress/kendo-react-grid';
import { Button } from '../baseElements/Button';
import { useAuditModalContext } from '../../../hooks/useAuditModalContext';
import { handleVectorModal } from '../../../utils/helpers/audit-helpers';

export function CvssVectorCell(props: GridCellProps): React.ReactElement {
  const { dataItem, field, className } = props;

  const { setCvssVector, setOpen } = useAuditModalContext();

  const fieldArray = field ? field.split('.') : [];
  let value = dataItem;

  if (!fieldArray) value = null;
  else if (fieldArray.length === 1) value = dataItem[fieldArray[0]];
  else if (fieldArray.length > 1) {
    fieldArray.forEach((f) => {
      value = value ? value[f] : value;
    });
  }
  if (typeof value === 'number') value = Number(value) >= 0 ? Number(value) : null;

  return (
    <td title={value} className={className ? className : ''}>
      {value ? (
        <Button
          fill="modal"
          className="cvss-button"
          onClick={(): void => handleVectorModal(value, setOpen, setCvssVector)}
        >
          {value}
        </Button>
      ) : (
        '-'
      )}
    </td>
  )
}
