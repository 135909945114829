/* eslint-disable react/jsx-pascal-case */
import { useCallback } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '../../../common/baseElements/Button';
import localeUser from '../../../../utils/i18n/userLocales/userList.json';
import localeNewTask from '../../../../utils/i18n/taskLocale/newTask.json';
import type { IDataHooks } from '../../../../utils/helpers/types';
import { getUrlUser } from '../../../../utils/helpers/user-list-helper';
import { CommonUserList } from '../../../common/CommonUserList';
import { useUserList } from '../../../../hooks/components/useUserList';
import { useUserModalContext } from '../../../../hooks/useUserModalContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeUser;

export function UserListForm(): React.ReactElement {
  const urlParams = getUrlUser('addUserList');

  const dataForHooks: IDataHooks = {
    name: '',
    componentName: 'AddUserList',
  };

  const {
    setAddUserEmail,
    userTaskEmail,
    userTaskEmailTemp,
    setUserTaskEmailTemp,
    addAllUserEmail,
    delAllUserEmail,
    addSelectedUsers,
  } = useUserModalContext();

  const { sort, page, total, pageChange, setSort, data } = useUserList(urlParams, dataForHooks);

  const handleExit = useCallback(() => {
    setUserTaskEmailTemp(null);
    setAddUserEmail(false);
  }, [setAddUserEmail, setUserTaskEmailTemp]);

  return (
    <Dialog
      title={localeUser[currentLocale].userList}
      onClose={(): void => setAddUserEmail(false)}
      className="user-list modal"
    >
      <div className="k-form">
        <div className="form-content groups">
          <div className="k-form-select-buttons">
            <Button onClick={(): void => addAllUserEmail(data)}>
              {localeNewTask[currentLocale].form.btnAddAllAssets}
            </Button>
            {userTaskEmailTemp && userTaskEmailTemp.length > 0 && (
              <Button onClick={delAllUserEmail}>
                {localeNewTask[currentLocale].form.btnDelAllUsers} ({userTaskEmailTemp?.length})
              </Button>
            )}
          </div>
          <CommonUserList
            sort={sort}
            data={data}
            page={page}
            total={total}
            pageChange={pageChange}
            setSort={setSort}
            dataForHooks={dataForHooks}
          />
        </div>
        <div className="k-form-buttons">
          <Button
            type="submit"
            disabled={
              (!userTaskEmailTemp && userTaskEmail?.length === 0) ||
              (!userTaskEmailTemp && userTaskEmail === null) ||
              (!userTaskEmail && userTaskEmailTemp?.length === 0) ||
              (!userTaskEmail && userTaskEmailTemp === null)
            }
            onClick={addSelectedUsers}
          >
            {localeNewTask[currentLocale].form.btnAddAsset}{' '}
          </Button>
          <Button type="button" onClick={handleExit}>
            {localeNewTask[currentLocale].form.btnCancel}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
