// import type { InventorizationResponse } from '../../../../types/__generated/on-premise-solution/api/inventorizationResponse.v1';
// import { Os } from './iDetailsComponents/Os';
// import { Route } from './iDetailsComponents/Route';
// import { Interface } from './iDetailsComponents/Interface';
// import { Users } from './iDetailsComponents/Users';
// import { Groups } from './iDetailsComponents/Groups';
// import { Mount } from './iDetailsComponents/Mount';
// import { SystemInfo } from './iDetailsComponents/SystemInfo';
// import { Containers } from './iDetailsComponents/Containers';
// import type locale from '../../../../utils/i18n/taskLocale/inventorizationTask.json';
// import { ContainerImages } from './iDetailsComponents/ContainerImages';
// import { OpenPorts } from './iDetailsComponents/OpenPorts';
// import { UsersNetDevices } from './iDetailsComponents/UsersNetDevices';
// import { GroupNetDevices } from './iDetailsComponents/GroupNetDevices';
// import { VlanCommonNetDevice } from './iDetailsComponents/VlanCommonNetDevice';
// import { FileSystemNetDevices } from './iDetailsComponents/FileSystemNetDevices';
// import { CdpNetDevice } from './iDetailsComponents/CdpNetDevice';
// import { VlanMikrotikNetDevice } from './iDetailsComponents/VlanMikrotikNetDevice';
// import { SoftPackageNetDevices } from './iDetailsComponents/SoftPackageNetDevices';
// import { VlanJuniperNetDevice } from './iDetailsComponents/VlanJuniperNetDevice';
// import { FirewallRules } from './iDetailsComponents/FirewallRules';
import { useState } from 'react';
import type { InventorizationDifference } from '../../../../../types/__generated/on-premise-solution/api/inventorizationDifference.v1';
import { DiffFilters } from '../DiffFilters';
import { DiffOs } from './DiffOs';
import { DiffSystemInfo } from './DiffSystemInfo';
import { DiffMount } from './DiffMount';
import { DiffRoute } from './DiffRoute';
import { DiffInterface } from './DiffInterface';
import { DiffUsers } from './DiffUsers';
import { DiffGroups } from './DiffGroups';
import type { DiffFilterTypes } from '../../../../../utils/helpers/difference-modal-helper';
import { ContainerImages } from './DiffContainerImages';
import { DiffContainers } from './DiffContainers';
import { DiffOpenPorts } from './DiffOpenPorts';
import hostDescriptionLocale from '../../../../../utils/i18n/assetLocales/hostDescription.json';
import { DiffFileSystemNetDevices } from './DiffFileSystemNetDevices';
import { DiffUsersNetDevices } from './DiffUsersNetDevices';
import { DiffGroupNetDevices } from './DiffGroupNetDevices';
import { DiffVlanCommonNetDevice } from './DiffVlanCommonNetDevice';
import { DiffVlanJuniperNetDevice } from './DiffVlanJuniperNetDevice';
import { DiffVlanMikrotikNetDevice } from './DiffVlanMikrotikNetDevice';
import { DiffSoftPackageNetDevices } from './DiffSoftPackageNetDevices';
import { DiffCdpNetDevice } from './DiffCdpNetDevice';
import { DiffFirewallRules } from './DiffFirewallRules';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof hostDescriptionLocale;

export function DiffSystemIDetails({
  data,
}: {
  data: InventorizationDifference | undefined;
}): React.ReactElement {
  const [filter, setFilter] = useState<DiffFilterTypes>('all');

  const filterList = {
    all: `${hostDescriptionLocale[currentLocale].changes.shortFilters.soft.all}`,
    noChanges: `${hostDescriptionLocale[currentLocale].changes.shortFilters.soft.noChanges}`,
    // updated: `${hostDescriptionLocale[currentLocale].changes.shortFilters.soft.updated}`,
    installed: `${hostDescriptionLocale[currentLocale].changes.shortFilters.soft.added}`,
    removed: `${hostDescriptionLocale[currentLocale].changes.shortFilters.soft.removed}`,
    changed: `${hostDescriptionLocale[currentLocale].changes.shortFilters.soft.changed}`,
  };

  return (
    <div className="inventorization-system">
      {data && (
        <>
          <DiffFilters currentFilter={filter} setFilter={setFilter} filterList={filterList} />
          {/* {(data.result.system_info ||
            data.result.os ||
            (data.type === 'linux' && data.result?.mounts) ||
            data.result.routes ||
            data.result.interface_details ||
            data.result.users ||
            data.result.groups ||
            data.result.container_images ||
            data.result.containers) && <span>{locale[currentLocale].titleGroupPropos}</span>} */}
          {data.system_info &&
            (data.system_info.closed || data.system_info.noChanges || data.system_info.updated) && (
              <DiffSystemInfo data={data} filter={filter} />
            )}
          {data.os && (data.os.closed || data.os.noChanges || data.os.updated) && (
            <DiffOs data={data} filter={filter} />
          )}
          {data.type === 'linux' && data.mounts && <DiffMount data={data} filter={filter} />}
          {(data.type === 'router' || data.type === 'firewall' || data.type === 'switch') &&
            data.file_system && <DiffFileSystemNetDevices data={data} filter={filter} />}
          {data.routes && <DiffRoute data={data} filter={filter} />}
          {data.interface_details && <DiffInterface data={data} filter={filter} />}
          {data.type !== 'router' &&
            data.type !== 'firewall' &&
            data.type !== 'switch' &&
            (data.type === 'linux' || data.type === 'windows') && (
              <DiffUsers data={data} filter={filter} />
            )}
          {(data.type === 'router' || data.type === 'firewall' || data.type === 'switch') && (
            <DiffUsersNetDevices data={data} filter={filter} />
          )}
          {data.type !== 'router' &&
            data.type !== 'firewall' &&
            data.type !== 'switch' &&
            (data.type === 'linux' || data.type === 'windows') && (
              <DiffGroups data={data} filter={filter} />
            )}
          {(data.type === 'router' || data.type === 'firewall' || data.type === 'switch') && (
            <DiffGroupNetDevices data={data} filter={filter} />
          )}
          {data.type !== 'router' &&
            data.type !== 'firewall' &&
            data.type !== 'switch' &&
            (data.type === 'linux' || data.type === 'windows') &&
            data.container_images && <ContainerImages data={data} filter={filter} />}
          {data.type === 'linux' && data.containers && (
            <DiffContainers data={data} filter={filter} />
          )}
          {data.type !== 'router' &&
            data.type !== 'firewall' &&
            data.type !== 'switch' &&
            (data.type === 'linux' || data.type === 'windows') &&
            data.containers && <DiffOpenPorts data={data} filter={filter} />}
          {((data.type === 'router' &&
            (data.class === 'cisco' || data.class === 'huawei' || data.class === 'eltex')) ||
            (data.type === 'switch' && data.class === 'eltex') ||
            (data.type === 'switch' && data.class === 'aruba')) &&
            data.vlans && <DiffVlanCommonNetDevice data={data} filter={filter} />}
          {data.type === 'router' && data.class === 'juniper' && data.vlans && (
            <DiffVlanJuniperNetDevice data={data} filter={filter} />
          )}
          {data.type === 'router' && data.class === 'mikrotik' && data.vlans && (
            <DiffVlanMikrotikNetDevice data={data} filter={filter} />
          )}
          {(data.type === 'router' || data.type === 'firewall') && data.packages && (
            <DiffSoftPackageNetDevices data={data} filter={filter} />
          )}
          {((data.type === 'router' && data.class === 'cisco') ||
            (data.type === 'switch' && data.class === 'aruba')) &&
            data.cdp && <DiffCdpNetDevice data={data} filter={filter} />}
          {(data.type === 'router' || data.type === 'firewall' || data.type === 'switch') &&
            data.firewall_rules && <DiffFirewallRules data={data} filter={filter} />}
        </>
      )}
    </div>
  );
}
