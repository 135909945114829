/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable no-nested-ternary */
// import './HostDescription.module.scss';
import { NavLink, useHistory } from 'react-router-dom';
import { Menu, MenuItem } from '@progress/kendo-react-layout';
import { Button } from '../../../common/baseElements/Button';
import localeHostDescription from '../../../../utils/i18n/assetLocales/hostDescription.json';
import localeVmTask from '../../../../utils/i18n/taskLocale/vmTask.json';
import { calcDataSummaryAudit } from '../../../../utils/helpers/host-vulns-metaBlock-helpers';
import { useConvertToJson } from '../../../../hooks/components/useConvertToJson';
import { handleVectorModal } from '../../../../utils/helpers/audit-helpers';
import { useAuditModalContext } from '../../../../hooks/useAuditModalContext';
import { CvssBlock } from '../../../common/smallBlocks/CvssBlock';
import type { AuditResponse } from '../../../../types/__generated/on-premise-solution/api/auditsResponse.v1';
import styles from './HostDescription.module.scss';
import { CommonTooltip } from '../../../common/CommonTooltip';
import { VBlock } from '../../../common/smallBlocks/VBlock';
import { useDifferenceModalContext } from '../../../../hooks/useDifferenceModalContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHostDescription;

export function HostVulnsMetaBlock({
  queryAuditInfo,
  hostName,
  isImage,
  maxVFstec,
}: {
  queryAuditInfo: AuditResponse | undefined;
  hostName: string | undefined | null;
  isImage?: boolean;
  maxVFstec?: number | null | undefined;
}): React.ReactElement {
  const data = calcDataSummaryAudit(queryAuditInfo);

  const handle2Json = useConvertToJson(queryAuditInfo, hostName);

  const { setCvssVector, setOpen } = useAuditModalContext();

  const {
    setAuditOpen: setDifferenceModalOpen,
    assetType,
    setAssetType,
  } = useDifferenceModalContext();

  const history = useHistory();
  if (history.location.pathname.includes('assets/hosts')) setAssetType('host');
  else if (history.location.pathname.includes('assets/images')) setAssetType('image');
  else if (history.location.pathname.includes('assets/devices')) setAssetType('device');

  return (
    <div className="tab-meta-block">
      <div>
        <table className="column">
          <tbody>
            <tr>
              <td>
                <span>{localeHostDescription[currentLocale].titleCvssMetric}</span>
              </td>
              <td>
                <div className={styles.vulnsMetricHeader}>
                  <div className={styles.vulnsMetricItem}>
                    <CvssBlock score={data.maxScore} />
                  </div>
                  <div className={styles.vulnsMetricItem}>
                    <span>{data.severity ? data.severity : '-'}</span>
                  </div>
                  {data.cvssVector ? (
                    <div className={styles.vulnsMetricItem}>
                      <Button
                        fill="modal"
                        onClick={(): void =>
                          handleVectorModal(data.cvssVector, setOpen, setCvssVector)
                        }
                      >
                        <span className="underlined">{data.cvssVector}</span>
                      </Button>
                    </div>
                  ) : (
                    <div className={styles.vulnsMetricItem}>
                      <span>-</span>
                    </div>
                  )}
                  <div className={styles.vulnsMetricItem}>
                    <CommonTooltip>
                      {localeHostDescription[currentLocale].cvssMetricInfo}
                    </CommonTooltip>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <span>{localeHostDescription[currentLocale].titleFstec}</span>
              </td>
              <td>
                <div className={styles.vulnsMetricFstecHeader}>
                  <div className={styles.fstecItem}>
                    {maxVFstec ? <VBlock score={maxVFstec} /> : '-'}
                  </div>
                  <CommonTooltip>{localeHostDescription[currentLocale].fstecInfo}</CommonTooltip>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <span>{localeHostDescription[currentLocale].auditDate}</span>
              </td>
              <td style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                {queryAuditInfo?.taskExecutionId ? (
                  <NavLink
                    className="link-underline"
                    to={`/lk/task/actions/${queryAuditInfo?.auditId}/audit`}
                  >
                    <span>
                      {new Date(String(queryAuditInfo?.createdAt))
                        .toLocaleString()
                        .replace(',', '')}
                    </span>
                  </NavLink>
                ) : queryAuditInfo?.createdAt ? (
                  <span>{new Date(String(queryAuditInfo?.createdAt)).toLocaleString()}</span>
                ) : (
                  <span>-</span>
                )}
                {queryAuditInfo &&
                  queryAuditInfo.type !== 'hypervisor' &&
                  queryAuditInfo.payload.meta?.createdAt &&
                  queryAuditInfo.payload.meta?.taskAuditId &&
                  queryAuditInfo?.auditId !== queryAuditInfo.payload.meta?.taskAuditId && (
                    <>
                      <span className={styles.auditDateSource}>
                        {localeHostDescription[currentLocale].auditDateSource}
                      </span>
                      <NavLink
                        className="link-underline"
                        to={`/lk/task/actions/${queryAuditInfo.payload.meta?.taskAuditId}/audit`}
                      >
                        {new Date(queryAuditInfo.payload.meta?.createdAt).toLocaleString()}
                      </NavLink>
                    </>
                  )}
                <Button
                  onClick={(): void => setDifferenceModalOpen(true)}
                  style={{ display: 'flex', padding: '4px 8px', marginLeft: '8px' }}
                >
                  <span
                    style={{ background: '#424242', maskRepeat: 'no-repeat' }}
                    className="k-icon scales-icon"
                  />
                </Button>
                <CommonTooltip>{`${
                  localeHostDescription[currentLocale].changes.show
                } ${localeHostDescription[
                  currentLocale
                ].changes.auditModalTitle.toLocaleLowerCase()} ${
                  assetType ? localeHostDescription[currentLocale].changes[assetType] : ''
                }`}</CommonTooltip>
              </td>
            </tr>
            {isImage && (
              <tr>
                <td>
                  <span>{localeHostDescription[currentLocale].layerId}</span>
                </td>
                <td>
                  {queryAuditInfo?.type !== 'hypervisor' && queryAuditInfo?.layerId
                    ? queryAuditInfo.layerId.split(':')[1].slice(0, 12)
                    : '-'}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="json-actions">
        <Menu hoverOpenDelay={0} onSelect={handle2Json}>
          <MenuItem text={localeVmTask[currentLocale].techDetails}>
            <MenuItem
              text={
                document.location.pathname.includes('devices')
                  ? localeVmTask[currentLocale].devicePayload
                  : localeVmTask[currentLocale].assetPayload
              }
            />
            <MenuItem text={localeVmTask[currentLocale].auditResult} />
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
}
