/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-nested-ternary */
import type { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useCallback, useEffect } from 'react';
import { useNetDeviceContext } from '../../../../hooks/useNetDeviceContext';

export function CellSelectDevice(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { deviceForm, deviceFormTemp, setDeviceFormTemp } = useNetDeviceContext();

  useEffect(() => {
    if (!deviceFormTemp) {
      setDeviceFormTemp(deviceForm);
    }
  }, [deviceForm, deviceFormTemp, setDeviceFormTemp]);

  const addDevice = useCallback(
    ({ hostname, assetId, ip }: { hostname: string | null; assetId: string; ip: string }): void => {
      if (deviceFormTemp && deviceFormTemp.length > 0) {
        const index = deviceFormTemp?.findIndex((d) => d.assetId === assetId);
        if (index !== -1) {
          const cutDeviceForm = deviceFormTemp.filter((device) => device.assetId !== assetId);
          setDeviceFormTemp([...cutDeviceForm]);
        } else {
          setDeviceFormTemp([
            ...deviceFormTemp,
            {
              assetId,
              ip,
              hostname: hostname || null,
            },
          ]);
        }
      } else {
        setDeviceFormTemp([
          {
            assetId: dataItem.assetId,
            hostname: dataItem.latestInventorization.hostname
              ? dataItem.latestInventorization.hostname
              : null,
            ip: dataItem.latestInventorization.ip,
          },
        ]);
      }
    },
    [
      deviceFormTemp,
      setDeviceFormTemp,
      dataItem.assetId,
      dataItem.latestInventorization.hostname,
      dataItem.latestInventorization.ip,
    ],
  );

  return (
    <td className="checkbox_cell">
      <Checkbox
        checked={!!deviceFormTemp?.find((d) => d.assetId === dataItem.assetId)}
        onClick={(): void =>
          addDevice({
            hostname: dataItem.latestInventorization.hostname
              ? dataItem.latestInventorization.hostname
              : null,
            assetId: dataItem.assetId,
            ip: dataItem.latestInventorization.ip,
          })
        }
      />
    </td>
  );
}
