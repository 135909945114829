/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-pascal-case */
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Grid, GridColumn as Column, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useState } from 'react';
import type { SortDescriptor } from '@progress/kendo-data-query';
import localeImageDescription from '../../../../../utils/i18n/assetLocales/imageDescription.json';
import styles from '../../imageRegister.module.scss';
import type { ContainerRegistryRepositoriesDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/containerRegistryRepositoriesDashboardResponse.v1';
import { ImageIdInfo } from '../../Image/cellComponents/ImageIdInfo';
import { ImageSize } from '../../Image/cellComponents/ImageSize';
import { HostsCount } from '../../Image/cellComponents/HostCount';
import { VulnsCount } from '../../Image/cellComponents/VulnsCount';
import { ImageName } from '../cellComponents/ImageName';
import { FILTER_INITIAL } from '../../../../../utils/helpers/constants';
import { ScoreCvssCell } from '../../../../common/cells/ScoreCvssCell';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeImageDescription;

export function ImageRepList({
  repoValues,
}: {
  repoValues: ContainerRegistryRepositoriesDashboardResponse['data'][0]['scannedAssetImages'];
}): React.ReactElement {
  const pageLimitGrid: { [key: string]: string } =
    localStorage.getItem('pageLimitGrid') &&
    JSON.parse(localStorage.getItem('pageLimitGrid') || '');

  // const initialDataState: State = {
  //   sort: [{ field: '', dir: 'asc' }],
  //   take: pageLimitGrid && pageLimitGrid.ServicesLin ? Number(pageLimitGrid.ServicesLin) : 15,
  //   skip: 0,
  //   filter: FILTER_INITIAL,
  // };
  const [dataState, setDataState] = useState<{
    sort: SortDescriptor[];
    take: number;
    skip: number;
    filter: any;
  }>({
    sort: [{ field: '', dir: 'asc' }],
    take: pageLimitGrid && pageLimitGrid.ServicesLin ? Number(pageLimitGrid.ServicesLin) : 15,
    skip: 0,
    filter: FILTER_INITIAL,
  });

  // const { sort, setSort } = useRegDescriptionRepList();
  // const [sort, setSort] = useState();

  const [skip, setSkip] = useState<number>(0);
  const pageChange = (event: GridPageChangeEvent): void => {
    setSkip(event.page.skip);
  };

  const data = repoValues;

  return (
    <div className={styles.imageRepList}>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <Grid
            className="rep-images no-margin-top"
            total={data?.length}
            // pageable={PAGEABLE_DATA}
            // sortable
            // sort={sort}
            // onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
            sortable
            style={{
              height: `${Math.min((data?.length || 1) * 56 + 56, 280)}px`,
              // height: '200px',
            }}
            // rowHeight={56}
            pageSize={10}
            // scrollable={(data?.length || 0) > 5 ? 'virtual' : 'none'}
            onPageChange={pageChange}
            data={data}
            {...dataState}
          >
            <Column
              field="latestAudit.maxCvssScore"
              title={localeImageDescription[currentLocale].summary.cvss}
              filter="text"
              cell={ScoreCvssCell}
              width="100px"
            />
            <Column
              field="latestAudit.vulnerabilities.count"
              title={localeImageDescription[currentLocale].summary.vulnerabilities}
              cell={VulnsCount}
              width="132px"
            />
            <Column
              field="imageNames"
              title={localeImageDescription[currentLocale].summary.scannedImageNames}
              filter="text"
              cell={ImageName}
            />
            <Column
              field="latestInventorization.os.size"
              title={localeImageDescription[currentLocale].summary.size}
              filter="text"
              cell={ImageSize}
              width="100px"
              // sortable={false}
            />
            <Column
              field="imageId"
              title={localeImageDescription[currentLocale].summary.imageId}
              cell={ImageIdInfo}
              width="120px"
              // sortable={false}
            />
            <Column
              field="assetHostsCount"
              title={localeImageDescription[currentLocale].summary.hostsCount}
              cell={HostsCount}
              width="80px"
            />
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
      <div className={styles.totalItemsBlock}>
        <span>
          {localeImageDescription[currentLocale].totalItems}: {data?.length}
        </span>
      </div>
    </div>
  );
}
