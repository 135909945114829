import localeSettings from '../../../../utils/i18n/settingsLocales/settings.json';
import styles from '../WhiteListTabEl.module.scss';
import type { BulletinWhitelistDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/bulletinWhitelistDashboardResponse.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

export function WhiteListDescriptionMetaBlock({
  data,
}: {
  data: BulletinWhitelistDashboardResponse | undefined;
}): React.ReactElement {
  return (
    <div className={styles.whiteListMetablockWrapper}>
      <div className={styles.whiteListMetablock}>
        <table className="column">
          <tbody>
            <tr>
              <td>
                <span>{localeSettings[currentLocale].whiteList.description.name}</span>
              </td>
              <td>
                {data?.name ? (
                  <span style={{ fontWeight: '700' }}>{data?.name}</span>
                ) : (
                  <span>-</span>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <span>{localeSettings[currentLocale].whiteList.description.id}</span>
              </td>
              <td className="pd-0">
                {data?.bulletinWhitelistId ? (
                  <span>{data?.bulletinWhitelistId}</span>
                ) : (
                  <span>-</span>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <table className="column">
          <tbody>
            <tr>
              <td>
                <span>{localeSettings[currentLocale].whiteList.description.accountName}</span>
              </td>
              <td>{data?.account.name ? <span>{data?.account.name}</span> : <span>-</span>}</td>
            </tr>
            <tr>
              <td>
                <span>{localeSettings[currentLocale].whiteList.description.status}</span>
              </td>
              <td className="pd-0">
                {data?.isDisabled ? (
                  <span>{localeSettings[currentLocale].whiteList.description.statusDisable}</span>
                ) : (
                  <span>{localeSettings[currentLocale].whiteList.description.statusEnable}</span>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <table className="column">
          <tbody>
            <tr>
              <td>
                <span>{localeSettings[currentLocale].whiteList.description.createdAt}</span>
              </td>
              <td>
                {data?.createdAt ? (
                  <span>{new Date(data?.createdAt).toLocaleString()}</span>
                ) : (
                  <span>-</span>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <span>{localeSettings[currentLocale].whiteList.description.createdBy}</span>
              </td>
              <td className="pd-0">
                {data?.createdBy.source === 'user' ? (
                  <span>{data?.createdBy.name}</span>
                ) : (
                  <span>-</span>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={styles.whiteListDescription}>
        <span>{localeSettings[currentLocale].whiteList.grid.description}:</span>
        {data?.description ? (
          <span
            style={{
              paddingLeft: currentLocale === 'ru-RU' ? '8px' : '',
            }}
          >
            {data?.description}
          </span>
        ) : (
          <span>-</span>
        )}
      </div>
    </div>
  );
}
