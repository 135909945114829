import type { GridCellProps } from '@progress/kendo-react-grid';
import type { EnrichedLogResponse } from '../../../../../types/__generated/on-premise-solution/api/enrichedLogResponse.v1';
import { TaskType } from '../../common/TaskType';

export function CellActionLogs(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { taskAction }: EnrichedLogResponse = dataItem;

  return (
    <td>
      <div className="task__actions">
        {taskAction && taskAction.type === 'audit' && <TaskType title type="audit" />}
        {taskAction && taskAction.type === 'audit_image' && <TaskType title type="audit_image" />}
        {taskAction && taskAction.type === 'package_update' && (
          <TaskType title type="package_update" />
        )}
        {taskAction && taskAction.type === 'kb_install' && <TaskType title type="kb_install" />}
        {taskAction && taskAction.type === 'inventorization' && (
          <TaskType title type="inventorization" />
        )}
        {taskAction && taskAction.type === 'agent_update' && <TaskType title type="agent_update" />}
        {taskAction && taskAction.type === 'group_report' && <TaskType title type="group_report" />}
        {taskAction && taskAction.type === 'single_report' && (
          <TaskType title type="single_report" />
        )}
        {taskAction && taskAction.type === 'notification' && <TaskType title type="notification" />}
        {!taskAction && <span>-</span>}
      </div>
    </td>
  );
}
