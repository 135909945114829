/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useState } from 'react';
import { loadMessages } from '@progress/kendo-react-intl';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { Typography } from '@progress/kendo-react-common';
import { ErrorBoundary } from 'react-error-boundary';
import { useHistory } from 'react-router';
import localeCommonTable from '../../../utils/i18n/commonLocales/table.json';
import localeImageRegister from '../../../utils/i18n/imageRegister/ImageRegister.json';
import { ImageList } from './Image/ImageList';
import './imageRegister.module.scss';
import { RegistryList } from './Registers/RegistryList';
import { useRegisterContext } from '../../../hooks/useRegisterContext';
import { AddEditRegisterForm } from './form/AddEditRegisterForm';
import { BoundaryErrorComponent } from '../../common/BoundaryErrorComponent';
import { Button } from '../../common/baseElements/Button';
import { getUserData } from '../../../services/local-storage-service';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeImageRegister;

loadMessages(localeCommonTable[currentLocale], currentLocale);

export function ImageRegisterTabs(): React.ReactElement {
  const currentUser = getUserData();

  const history = useHistory();

  const [selected, setSelected] = useState<number>(
    history.location.pathname.includes('registries') ? 1 : 0,
  );

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      if (e.selected === 0 && !document.location.pathname.includes('/images')) {
        history.replace('/lk/assets/images');
        setSelected(e.selected);
      }
      if (e.selected === 1 && !document.location.pathname.includes('/registries')) {
        history.replace('/lk/assets/registries');
        setSelected(e.selected);
      }
      // setSelected(0);
    },
    [history],
  );

  const { isAddRegister, setAddRegister, registerData } = useRegisterContext();

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      <div className="image-register-tabs">
        <div className="common-header-page">
          <Typography.h3>{localeImageRegister[currentLocale].imageTitle}</Typography.h3>
          <div className="topactions">
            {currentUser?.role !== 'reader_user' && (
              <Button
                fill="action"
                onClick={(): void => {
                  setAddRegister(true);
                }}
              >
                {localeImageRegister[currentLocale].createRegister}
              </Button>
            )}
          </div>
        </div>
        <TabStrip className="image registry" selected={selected} onSelect={handleSelect}>
          <TabStripTab title={localeImageRegister[currentLocale].image}>
            <ImageList />
          </TabStripTab>
          <TabStripTab title={localeImageRegister[currentLocale].register}>
            <RegistryList />
          </TabStripTab>
        </TabStrip>
        {(isAddRegister || registerData) && <AddEditRegisterForm />}
      </div>
    </ErrorBoundary>
  );
}
