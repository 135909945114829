import type { GridCellProps } from '@progress/kendo-react-grid';

export function CellDatetime({ dataItem }: GridCellProps): React.ReactElement {
  const { datetime } = dataItem;

  return (
    <td className="channel-date-row">
      {datetime && datetime !== '0' ? new Date(datetime).toLocaleString() : '-'}
    </td>
  );
}
