import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import type { FieldRenderProps } from '@progress/kendo-react-form';
import styles from './Form.module.scss';

interface IDropwDownListRenderProps {
  id?: string;
  label?: string;
  data?: any[];
  name?: string;
  style?: React.CSSProperties;
  textField?: string;
  dataItemKey?: string;
  defaultValue?: any;
  className?: string;
  onChange?: (event: DropDownListChangeEvent) => void;
  disabled?: boolean;
}

export function FormDropDownList(
  inputRenderProps: IDropwDownListRenderProps | FieldRenderProps,
): React.ReactElement {
  const {
    id,
    label,
    data,
    name,
    style,
    textField,
    dataItemKey,
    defaultValue,
    className,
    onChange,
    disabled,
  } = inputRenderProps;

  return (
    <div className={`${styles.field_wrapper} ${className || ''}`}>
      <label htmlFor={id}>{label}</label>
      <DropDownList
        id={id}
        data={data}
        name={name}
        style={style}
        textField={textField}
        dataItemKey={dataItemKey}
        onChange={onChange}
        defaultValue={defaultValue}
        className={`${styles.dropdown} ${className || ''}`}
        disabled={disabled}
      />
    </div>
  );
}
