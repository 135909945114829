import { NotificationGroup, Notification } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import styles from '../TaskList.module.scss';
import { ScopeElement } from './ScopeElement';

export function ScopeNotification({
  data,
  // scoreData,
  // taskType,
  isShow,
  className,
  sliceValue,
}: {
  data:
    | {
        id: string;
        name: string | null;
        taskType: string;
        deviceType: string | undefined;
      }[]
    | undefined;
  // scoreData: IAssetGroupData;
  // taskType: 'image' | 'device' | 'host' | 'report';
  isShow: boolean;
  className?: string;
  sliceValue: number;
}): React.ReactElement {
  return (
    <NotificationGroup className={`${styles.notification} ${className || ''}`}>
      <Fade>
        {isShow && data && data.length > 3 && (
          <Notification>
            {data.slice(sliceValue).map((se) => (
              <ScopeElement element={se} />
            ))}
          </Notification>
        )}
      </Fade>
    </NotificationGroup>
  );
}
