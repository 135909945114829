import type { GridCellProps } from '@progress/kendo-react-grid';
import { NavLink } from 'react-router-dom';
import type { TaskHostDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/tasksDashboardResponse.v1';

export function LastStart(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { startedAt, latestTaskExecutionId }: TaskHostDashboardResponse = dataItem;

  return (
    <td>
      <div className="task__timestart">
        {startedAt ? (
          <NavLink className="link-underline" to={`/lk/task/executions/${latestTaskExecutionId}`}>
            {new Date(startedAt).toLocaleString()}
          </NavLink>
        ) : (
          <span className="task__timestart">-</span>
        )}
      </div>
    </td>
  );
}
