import type { GridCellProps } from '@progress/kendo-react-grid';
import type { ISecurityVulns } from '../../../../utils/helpers/types';

export function CellDescription(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { description }: ISecurityVulns = dataItem;

  return (
    <td title={description}>
      <span>{description ? <span>{description}</span> : <span>-</span>}</span>
    </td>
  );
}
