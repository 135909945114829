import type { GridCellProps } from '@progress/kendo-react-grid';

export function CellChannelName({ dataItem }: GridCellProps): React.ReactElement {
  const { name } = dataItem;

  const cName = name.split('@')[1] || name;

  return (
    <td className="channel-name-row" title={cName}>
      {cName}
    </td>
  );
}
