import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels,
  ChartTooltip,
} from '@progress/kendo-react-charts';
import locale from '../../../../utils/i18n/dashboardLocale/dashboardLayout.json';
import type { AuditAssetsAge } from '../../../../types/__generated/on-premise-solution/api/auditAssetsAge.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

function getColorAndCategory(info: AuditAssetsAge[0]): { category: string; color: string } {
  if (info.label === '0-3')
    return { category: `${locale[currentLocale].auditAge0_3} (${info.value})`, color: '#4caf50' };
  if (info.label === '3-7')
    return { category: `${locale[currentLocale].auditAge3_7} (${info.value})`, color: '#ffeb3b' };
  if (info.label === '7-10')
    return { category: `${locale[currentLocale].auditAge7_10} (${info.value})`, color: '#ff9800' };
  if (info.label === '10+')
    return {
      category: `${locale[currentLocale].auditAge10more} (${info.value})`,
      color: '#ff5722',
    };

  return { category: info.label, color: 'rgba(135,136,141,1)' };
}

const labelContent = (e: any) => `${e.category}`;

export function AudAssetsAge({ data }: { data: AuditAssetsAge | undefined }): React.ReactElement {
  return (
    <Chart>
      {/* <ChartTitle text="World Population by Broad Age Groups" /> */}
      <ChartLegend position="bottom" />
      <ChartTooltip
        render={(e: any) => {
          if (e.point?.category) return <span>{e.point.category}</span>;

          return '';
        }}
      />
      <ChartSeries>
        <ChartSeriesItem
          type="donut"
          data={
            data && Array.isArray(data)
              ? data.map((d) => ({
                  value: d.value,
                  ...getColorAndCategory(d),
                }))
              : []
          }
          field="value"
          categoryField="category"
          tooltip={{ visible: true }}
          // labels={{ visible: true, content: labelContent }}
        >
          {/* <ChartSeriesLabels position="outsideEnd" background="none" content={labelContent} /> */}
        </ChartSeriesItem>
      </ChartSeries>
    </Chart>
  );
}
