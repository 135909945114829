import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ReactElement, useEffect, useRef, useState } from 'react';
import QRCode from 'qrcode';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Skeleton } from '@progress/kendo-react-indicators';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { confirmTfaQr, getTfaQr } from '../../../../services/user-service';
import styles from './TfaConnectModal.module.scss';
import { FormInput } from '../../form/FormInput';
import { Button } from '../../baseElements/Button';
// import type { TwoFactorAuthConfirmResponse } from '../../../../types/__generated/on-premise-solution/api/twoFactorAuthConfirmResponse.v1';
// import type { TwoFactorAuthConfirmRequest } from '../../../../types/__generated/on-premise-solution/api/twoFactorAuthConfirmRequest.v1';
import { useUserModalContext } from '../../../../hooks/useUserModalContext';
import type { FormDataValue } from '../../../../utils/helpers/types';
import localeUser from '../../../../utils/i18n/userLocales/addUserForm.json';
import type { TwoFactorAuthCreateRequest } from '../../../../types/__generated/on-premise-solution/api/twoFactorAuthCreateRequest.v1';
import type { TwoFactorAuthCreateResponse } from '../../../../types/__generated/on-premise-solution/api/twoFactorAuthCreateResponse.v1';
import type { TwoFactorAuthQRCodeResponse } from '../../../../types/__generated/on-premise-solution/api/twoFactorAuthQRCodeResponse.v1';
import { handleError } from '../../../../utils/errors';
import { useNotificationContext } from '../../../../hooks/useNotificationContext';

function transformSecret(secret: string): string {
  let newSecret = '';
  for (let i = 0; i < secret.length; i++) {
    if (i && i % 4 === 0) newSecret += ` ${secret[i]}`;
    else newSecret += secret[i];
  }

  return newSecret;
}

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeUser;

export function TfaConnectionForm({
  uid,
  userId,
  onClose,
  setBackupCodes,
  setModifiedTfa,
}: {
  uid: string;
  userId: string;
  onClose: () => void;
  setBackupCodes: React.Dispatch<React.SetStateAction<string[] | null>>;
  setModifiedTfa: React.Dispatch<React.SetStateAction<boolean>>;
}): ReactElement {
  const query = useQuery<TwoFactorAuthQRCodeResponse, ApiError>('tfaQr', () => getTfaQr(uid), {
    // cacheTime: DATA_CACHE_CACHETIME,
    // staleTime: DATA_CACHE_STALETIME,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: false,
  });

  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [tfaCode, setTfaCode] = useState('');
  const [isDisabledConfirm, setDisabledConfirm] = useState(false);
  const attemptCount = useRef(0);
  const formRef: React.LegacyRef<Form> | null = useRef(null);

  const { createNotification } = useNotificationContext();

  let timer: NodeJS.Timeout | null = null;

  const queryClient = useQueryClient();

  const [errorMessage, setErrorMessage] = useState('');

  const { setTfa } = useUserModalContext();

  const mutationConfirmTfaQr = useMutation<
    TwoFactorAuthCreateResponse,
    ApiError,
    TwoFactorAuthCreateRequest
  >((payload) => confirmTfaQr(payload), {
    onSuccess: (resp) => {
      queryClient.invalidateQueries('userList');
      setBackupCodes(resp.backupCodes);
      setTfa(resp.isValid);
    },
    onError: (resp) => {
      if (resp.code === '404') setErrorMessage(localeUser[currentLocale].errors.undefinedUser);
      else if (resp.code === '409')
        setErrorMessage(localeUser[currentLocale].errors.tfaAlreadyConnected);
      else if (resp.code === '422' && attemptCount.current >= 5) {
        attemptCount.current = 0;
        setDisabledConfirm(true);
        setErrorMessage(localeUser[currentLocale].errors.tooManyTries);
        timer = setTimeout(() => {
          attemptCount.current = 0;
          setDisabledConfirm(false);
          setErrorMessage('');
        }, 30000);
      } else if (resp.code === '422') {
        attemptCount.current += 1;
        setErrorMessage(localeUser[currentLocale].errors.invalidCode);
      }
    },
  });

  useEffect(() => {
    if (query.data)
      QRCode.toDataURL(query.data.url)
        .then((url) => setImageUrl(url))
        .catch((err) => {
          console.error(err);
        });
  }, [query.data?.url]);

  useEffect(() => {
    setErrorMessage('');

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);

  const onInputChange = (e: any): void => {
    setTfaCode(e.target.value.trim());
  };

  useEffect(() => {
    if (mutationConfirmTfaQr.error) {
      handleError(mutationConfirmTfaQr.error, createNotification);
    }
  }, [mutationConfirmTfaQr.isError, mutationConfirmTfaQr.error, createNotification]);

  return (
    <Form
      ref={formRef}
      onSubmit={(dataItem: FormDataValue): void => {
        setErrorMessage('');
        mutationConfirmTfaQr.mutateAsync({
          code: dataItem.code || '',
          userId,
          secret: query.data?.secret || '',
        });
        setTfaCode('');
        setModifiedTfa(true);
      }}
      render={(): JSX.Element => (
        <FormElement>
          <div>
            <h3>{localeUser[currentLocale].tfaForm.perpareDeviceTitle}</h3>
            <p>{localeUser[currentLocale].tfaForm.perpareDeviceAppInfo}</p>
            {/* <p>{localeUser[currentLocale].tfaForm.perpareDeviceSyncInfo}</p> */}
          </div>
          <div className={styles.tfa_representation}>
            <div className={styles.tfa_block}>
              <div className={styles.text_block}>
                <h3>{localeUser[currentLocale].tfaForm.scanQrLabel}</h3>
                <p>{localeUser[currentLocale].tfaForm.scanQrInfo}</p>
              </div>
              <div className={styles.tfa_add_data}>
                {!imageUrl && (
                  <Skeleton shape="rectangle" style={{ width: '200px', height: '200px' }} />
                )}
                {imageUrl && <img height={228} width={228} src={imageUrl} alt="" />}
              </div>
            </div>
            <div className={styles.tfa_block}>
              <div className={styles.text_block}>
                <h3>{localeUser[currentLocale].tfaForm.manualLabel}</h3>
                <p>
                  {localeUser[currentLocale].tfaForm.manualInfo}{' '}
                  <strong>{localeUser[currentLocale].tfaForm.manualInfoStrong}</strong>.
                </p>
              </div>
              <div className={`${styles.tfa_add_data} ${styles.tfa_secret_code}`}>
                <span style={{ padding: '12px' }}>
                  {!query.data?.secret && (
                    <>
                      <div style={{ display: 'flex', gap: '0 8px' }}>
                        <Skeleton shape="text" style={{ width: '64px', height: '44px' }} />
                        <Skeleton shape="text" style={{ width: '64px', height: '44px' }} />
                        <Skeleton shape="text" style={{ width: '64px', height: '44px' }} />
                      </div>
                      <div style={{ display: 'flex', gap: '0 8px' }}>
                        <Skeleton shape="text" style={{ width: '64px', height: '44px' }} />
                        <Skeleton shape="text" style={{ width: '64px', height: '44px' }} />
                        <Skeleton shape="text" style={{ width: '64px', height: '44px' }} />
                      </div>
                      <div style={{ display: 'flex', gap: '0 8px' }}>
                        <Skeleton shape="text" style={{ width: '64px', height: '44px' }} />
                        <Skeleton shape="text" style={{ width: '64px', height: '44px' }} />
                      </div>
                    </>
                  )}
                  {query.data?.secret && transformSecret(query.data?.secret)}
                </span>
              </div>
            </div>
          </div>
          <div className="form-content">
            <Field
              name="code"
              component={FormInput}
              label={`${localeUser[currentLocale].tfaForm.enterCodeLabel}:`}
              onChange={onInputChange}
              inputValue={tfaCode}
              type="text"
              disabled={isDisabledConfirm}
            />
          </div>
          <div className="error-common-field">
            <span>{errorMessage}</span>
          </div>
          <div className="k-form-buttons">
            <Button
              type="submit"
              disabled={isDisabledConfirm}
              className="k-button k-button-md k-button-rectangle k-button-solid k-button-solid-base"
            >
              {/* {editUserData
                  ? localeUser[currentLocale].modal.btnEdit
                  : localeUser[currentLocale].modal.btnCreate} */}
              {localeUser[currentLocale].tfaForm.btnConfirm}
            </Button>
            <Button
              onClick={onClose}
              className="k-button k-button-md k-button-rectangle k-button-solid k-button-solid-base"
            >
              {/* {localeUser[currentLocale].modal.btnCancel} */}
              {localeUser[currentLocale].tfaForm.btnClose}
            </Button>
          </div>
        </FormElement>
      )}
    />
  );
}
