import { Dialog } from '@progress/kendo-react-dialogs';
import { Label } from '@progress/kendo-react-labels';
import localeCredential from '../../../utils/i18n/credentials/credentials.json';
import { useHostModalContext } from '../../../hooks/useHostModalContext';
import type { AssetHostUpdateRequest } from '../../../types/__generated/on-premise-solution/api/assetUpdateRequest.v1';
import { Button } from '../baseElements/Button';
import { Input } from '../baseElements/Input';
import { useCredentialContext } from '../../../hooks/useCredentialContext';
import { useHostActions } from '../../../hooks/components/useHostActions';
import { useHostDescription } from '../../../hooks/components/useHostDescription';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeCredential;
export function EditPortModal({
  assetId,
  assetType,
  sshPortCurrent,
}: {
  assetId: string;
  assetType: string;
  sshPortCurrent: number | undefined;
}): React.ReactElement {
  const { assetIdData, hostname, auditIdData, inventorizationIdData } = useHostDescription();

  const { sshPort, setSshPort, setEditPort, smbPort, setSmbPort, winrmPort, setWinrmPort } =
    useHostModalContext();

  const { selectedCredential, setSelectedCredential } = useCredentialContext();

  const { handleEditHost } = useHostActions();

  const handleEdit = (): void => {
    // const port = Number(sshPort);
    const payload = {
      sshPort: selectedCredential?.isSsh ? Number(sshPort) : undefined,
      smbPort: selectedCredential?.isSmbPort ? Number(smbPort) : undefined,
      winrmPort: selectedCredential?.isWinrmPort ? Number(winrmPort) : undefined,
      type: assetType,
    } as AssetHostUpdateRequest;
    setSelectedCredential(null);
    setEditPort(false);
    // mutation.mutateAsync({ payloadAssetId: assetId, payload });
    handleEditHost(assetId, payload);
    // editAsset(assetId, payload);
  };

  const handleExit = (): void => {
    setSelectedCredential(null);
    setEditPort(false);
    if (sshPortCurrent) {
      setSshPort(String(sshPortCurrent));
    }
    if (assetIdData?.smbPort) {
      setSmbPort(String(assetIdData?.smbPort));
    }
    if (assetIdData?.winrmPort) {
      setWinrmPort(String(assetIdData?.winrmPort));
    }
  };

  const returnTitles = (): string => {
    if (selectedCredential?.isSmbPort) return 'smb';
    if (selectedCredential?.isWinrmPort) return 'winrm';

    return 'ssh';
  };

  const title = returnTitles();

  return (
    <Dialog
      className="sshport-modal modal small"
      title={`${localeCredential[currentLocale].table.changePortTitle} ${title}`}
      onClose={handleExit}
    >
      <div className="k-form">
        {selectedCredential?.isSsh && (
          <div className="form-content sshport-modal__content">
            <Label>{localeCredential[currentLocale].table.sshPort}</Label>
            <Input
              name="sshPort"
              value={sshPort}
              onChange={(e): void => setSshPort(e.target.value)}
              type="number"
              className="k-input"
            />
          </div>
        )}
        {selectedCredential?.isSmbPort && (
          <div className="form-content sshport-modal__content">
            <Label>{localeCredential[currentLocale].table.smbPort}</Label>
            <Input
              name="smbPort"
              value={smbPort}
              onChange={(e): void => setSmbPort(e.target.value)}
              type="number"
              className="k-input"
            />
          </div>
        )}
        {selectedCredential?.isWinrmPort && (
          <div className="form-content sshport-modal__content">
            <Label>{localeCredential[currentLocale].table.winrmPort}</Label>
            <Input
              name="winrmPort"
              value={winrmPort}
              onChange={(e): void => setWinrmPort(e.target.value)}
              type="number"
              className="k-input"
            />
          </div>
        )}
        <div className="k-form-buttons">
          <Button type="button" fill="action" onClick={handleEdit}>
            {localeCredential[currentLocale].btnSshPortOk}
          </Button>
          <Button type="button" onClick={handleExit}>
            {localeCredential[currentLocale].btnSshPortCancel}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
