/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import type { GridCellProps } from '@progress/kendo-react-grid';
import type { IServicesLin } from '../../../../../../utils/helpers/types';

export function CellLinPid(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { pid }: IServicesLin = dataItem;

  return <td>{pid || pid === 0 ? <span>{pid}</span> : <span>-</span>}</td>;
}
