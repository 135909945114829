import type { SortDescriptor } from '@progress/kendo-data-query';
import { INITIAL_SORT_ASSET_GROUP_LIST, INITIAL_PAGING } from './constants';
import type { IPageState } from './types';

export interface IFilterUrlDataVulnRelated {
  SecurityVulnsList?: string;
}
export interface IUrlParamsVulnRelated {
  sort: SortDescriptor[];
  page: IPageState;
  pageLimitGrid: { [key: string]: string } | null;
  filterUrlData: IFilterUrlDataVulnRelated;
}

export function getUrlVulnRelated(pageLimitGridKey: string): IUrlParamsVulnRelated {
  const pageLimitGrid: IUrlParamsVulnRelated['pageLimitGrid'] =
    localStorage.getItem('pageLimitGrid') &&
    JSON.parse(localStorage.getItem('pageLimitGrid') || '');

  const locationUrlData: { [name: string]: string } | null = {};
  if (document.location.search) {
    const locationSearch = document.location.search.slice(1).split('&');
    locationSearch.forEach((val) => {
      const value = val.split('=');
      locationUrlData[value[0]] = value[1] || '';
    });
  }

  const { sortField, sortOrder, skip } = locationUrlData;
  const sort: SortDescriptor[] =
    sortField && sortOrder && (sortOrder === 'asc' || sortOrder === 'desc')
      ? [{ field: sortField, dir: sortOrder }]
      : INITIAL_SORT_ASSET_GROUP_LIST;

  const page: IPageState = {
    skip: Number(skip) || INITIAL_PAGING.skip,
    take:
      pageLimitGrid && Number(pageLimitGrid[pageLimitGridKey])
        ? Number(pageLimitGrid[pageLimitGridKey])
        : INITIAL_PAGING.take,
  };

  const filterUrlData = {} as IFilterUrlDataVulnRelated;

  return {
    sort,
    page,
    pageLimitGrid,
    filterUrlData,
  };
}
