import { Dialog } from '@progress/kendo-react-dialogs';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useCallback, useState } from 'react';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Button } from '../../../../common/baseElements/Button';
import localeSettings from '../../../../../utils/i18n/settingsLocales/settings.json';
import { useApiKeyContext } from '../../../../../hooks/useApiKeyContext';
import style from '../ApiKeyList.module.scss';
import { useCommonContext } from '../../../../../hooks/useCommonContext';
import { SecureInput } from '../../../../common/baseElements/SecureInput';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

export function ApiKeyValue(): React.ReactElement {
  const { apiKeyValue, setApiKeyValue } = useApiKeyContext();

  const { handleCopied, copied, setCopied } = useCommonContext();

  return (
    <div className="apikey-value">
      {apiKeyValue && (
        <Dialog
          title={localeSettings[currentLocale].apiKey.form.apiKeyValueTitle}
          onClose={(): void => setApiKeyValue(null)}
          className="modal small"
        >
          <div className="k-form">
            <div className="form-content">
              <div className={style.apikeyValueContent}>
                <div className={style.apiKeyValueLockBlock}>
                  <SecureInput
                    className={`k-input ${style.apiKeyValueLockField}`}
                    value={apiKeyValue}
                    type="password"
                  />
                </div>
                <CopyToClipboard text={apiKeyValue} onCopy={(): void => setCopied(true)}>
                  <Button onClick={handleCopied} icon="copy">
                    {localeSettings[currentLocale].apiKey.copyToClipboard}
                  </Button>
                </CopyToClipboard>
              </div>
              {copied && (
                <NotificationGroup
                  className="copied-container"
                  style={{
                    right: '160px',
                    top: '88px',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap-reverse',
                    zIndex: '100',
                  }}
                >
                  <Notification
                    closable
                    type={{ icon: false }}
                    onClose={(): void => setCopied(false)}
                  >
                    <span>{localeSettings[currentLocale].apiKey.copyToClipboardSaved}</span>
                  </Notification>
                </NotificationGroup>
              )}
            </div>
            <div className="k-form-buttons">
              <Button type="submit" onClick={(): void => setApiKeyValue(null)}>
                Ok
              </Button>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
}
