/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useState } from 'react';
import { loadMessages } from '@progress/kendo-react-intl';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { useHistory } from 'react-router';
import localePkgUpdate from '../../../../utils/i18n/taskLocale/auditTask.json';
import type { IPackagesUpdate } from '../../../../utils/helpers/task-helpers';
import { TaskPResult } from './TaskPResult';
import { TaskPConsole } from './TaskPConsole';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localePkgUpdate;

loadMessages(localePkgUpdate[currentLocale], currentLocale);

export function TabsPDetails({
  packagesUpdate,
  hostName,
  taskActionId,
  status,
  agentError,
  checkConsoleData,
}: {
  packagesUpdate: IPackagesUpdate;
  hostName: string | false | undefined;
  taskActionId: string;
  status: 'processing' | 'success' | 'fail' | 'timeout';
  agentError: string | false | undefined;
  checkConsoleData: boolean;
}): React.ReactElement {
  const history = useHistory();

  const [selected, setSelected] = useState<number>(0);

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      if (e.selected === 0) {
        history.replace(`/lk/task/actions/${taskActionId}/package_update/result`);
        setSelected(e.selected);
      }
      if (e.selected === 1 && !document.location.pathname.includes('/console')) {
        history.replace(`/lk/task/actions/${taskActionId}/package_update/console`);
        setSelected(e.selected);
      }
    },
    [history, taskActionId],
  );

  return (
    <TabStrip
      selected={document.location.pathname.includes('/result') ? 0 : 1}
      onSelect={handleSelect}
    >
      <TabStripTab title={localePkgUpdate[currentLocale].pkgResult}>
        <TaskPResult
          packagesUpdate={packagesUpdate}
          hostName={hostName}
          status={status}
          agentError={agentError}
          checkConsoleData={checkConsoleData}
          setSelected={setSelected}
        />
      </TabStripTab>
      {checkConsoleData && (
        <TabStripTab title={localePkgUpdate[currentLocale].pkgConsole}>
          <TaskPConsole taskActionId={taskActionId} />
        </TabStripTab>
      )}
    </TabStrip>
  );
}
