/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import type { GridCellProps } from '@progress/kendo-react-grid';
import { IMG_SRC_VALUES } from '../../../../../utils/helpers/constants';
import localeHost from '../../../../../utils/i18n/assetLocales/hostList.json';
import { getMainPartOfOsName } from '../../../../../utils/helpers/inventorization-helper';
import { useHostModalContext } from '../../../../../hooks/useHostModalContext';
import type { AssetsHostItemDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/assetsHostsDashboardResponse.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHost;
export function System(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { latestInventorization, assetId }: AssetsHostItemDashboardResponse = dataItem;

  const { setHostDetailById } = useHostModalContext();

  let osName = '';
  if (latestInventorization?.os?.name) {
    osName = getMainPartOfOsName(latestInventorization.os.name);
  }

  return (
    <td
      style={{ position: 'relative' }}
      title={`${
        latestInventorization && latestInventorization?.os && 'name' in latestInventorization.os
          ? latestInventorization.os.name
          : ''
      } ${
        latestInventorization && latestInventorization.os && 'version' in latestInventorization.os
          ? latestInventorization.os.version
          : ''
      }`}
    >
      {latestInventorization && latestInventorization?.os?.name ? (
        <div className="cell_os">
          <img
            src={`${process.env.PUBLIC_URL}/images/os_logo/${IMG_SRC_VALUES[osName.toLowerCase()]}`}
            alt={latestInventorization.os.name}
          />
          <span>{latestInventorization.os.name}&nbsp;</span>
          <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {latestInventorization.os.version}
          </span>
        </div>
      ) : dataItem?.agentValue?.status === 'online' && latestInventorization === null ? (
        <span
          onClick={(): void => setHostDetailById(assetId)}
          className="k-button"
          style={{ borderBottom: '1px black dashed' }}
        >
          <span className="k-icon k-i-warning" style={{ color: 'red' }} />
          {localeHost[currentLocale].waitingDetails}
        </span>
      ) : (
        <span>{localeHost[currentLocale].form.waitingForData}</span>
      )}
    </td>
  );
}
