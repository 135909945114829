/* eslint-disable no-nested-ternary */
import qs from 'qs';
import type { SortDescriptor } from '@progress/kendo-data-query';
import type { AxiosResponse } from 'axios';
import type { IEditRegister, IPageState } from '../utils/helpers/types';
import { history } from '../utils/history';
import type { AssetsImagesDashboardResponse } from '../types/__generated/on-premise-solution/api/assetsImagesDashboardResponse.v1';
import type { AgentsStatusRequest } from '../types/__generated/on-premise-solution/api/agentsStatusRequest.v1';
import type { AgentsStatusResponse } from '../types/__generated/on-premise-solution/api/agentsStatusResponse.v1';
import { dashboardInstance } from '../lib/axios/hooks/useDashboardAxios';
import type { AssetOs } from '../types/__generated/on-premise-solution/api/assetOs.v1';
import type { AgentStatuses } from '../types/__generated/on-premise-solution/api/agentStatuses.v1';
import type {
  OSLinuxPlatforms,
  OSWindowsPlatforms,
} from '../types/__generated/on-premise-solution/api/oSPlatformsResponse.v1';
import type { InventorizationAssetsAge } from '../types/__generated/on-premise-solution/api/inventorizationAssetsAge.v1';
import type { AssetsResponse } from '../types/__generated/on-premise-solution/api/assetsResponse.v1';
import type { VulnerableAssetsResponse } from '../types/__generated/on-premise-solution/api/vulnerableAssetsResponse.v1';
import type { CvssScores } from '../types/__generated/on-premise-solution/api/cvssScores.v1';
import type { VulnerableSoftware } from '../types/__generated/on-premise-solution/api/vulnerableSoftware.v1';
import type { BulletinsWidgetResponse } from '../types/__generated/on-premise-solution/api/bulletinsWidgetResponse.v1';
import { vmInstance } from '../lib/axios/hooks/useVmAxios';
import type { IFilterVal } from '../utils/filtering-in-table-test';
import { prepareFilterVal } from './filterVal-for-service';
import type { AssetsHostsDashboardResponse } from '../types/__generated/on-premise-solution/api/assetsHostsDashboardResponse.v1';
import type { AssetImageDashboardResponse } from '../types/__generated/on-premise-solution/api/assetImageDashboardResponse.v1';
import type { AssetHostsAuditType } from '../types/__generated/on-premise-solution/api/assetHostsAuditType.v1';
import type { ContainerRegistriesDashboardResponse } from '../types/__generated/on-premise-solution/api/containerRegistriesDashboardResponse.v1';
import type { ContainerRegistryCreationRequest } from '../types/__generated/on-premise-solution/api/containerRegistryCreationRequest.v1';
import type { ContainerRegistryCreationResponse } from '../types/__generated/on-premise-solution/api/containerRegistryCreationResponse.v1';
import type { ContainerRegistryUpdateResponse } from '../types/__generated/on-premise-solution/api/containerRegistryUpdateResponse.v1';
import type { ContainerRegistryRepositoriesDashboardResponse } from '../types/__generated/on-premise-solution/api/containerRegistryRepositoriesDashboardResponse.v1';
import type { AssetDeletionResponse } from '../types/__generated/on-premise-solution/api/assetDeletionResponse.v1';
import type { ContainerRegistryDashboardResponse } from '../types/__generated/on-premise-solution/api/containerRegistryDashboardResponse.v1';
import type { AssetRouterDashboardResponse } from '../types/__generated/on-premise-solution/api/assetRouterDashboardResponse.v1';
import type {
  AssetFirewallDashboardResponse,
  AssetHypervisorDashboardResponse,
  AssetsDevicesDashboardResponse,
} from '../types/__generated/on-premise-solution/api/assetsDevicesDashboardResponse.v1';
import type { AssetHostDashboardResponse } from '../types/__generated/on-premise-solution/api/assetHostDashboardResponse.v1';
import type { AssetSwitchDashboardResponse } from '../types/__generated/on-premise-solution/api/assetDeviceDashboardResponse.v1';
import type { TotalAssetVulnerabillitiesResponse } from '../types/__generated/on-premise-solution/api/totalAssetVulnerabillitiesResponse.v1';
import type { AuditAssetsAgeResponse } from '../types/__generated/on-premise-solution/api/auditAssetsAgeResponse.v1';
import type { TotalAssetCount } from '../types/__generated/on-premise-solution/api/totalAssetCount.v1';

export async function getAssetList(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
  assetHostIds: string[] | undefined | null,
): Promise<AssetsHostsDashboardResponse> {
  const paramsData = {
    skip: page.skip,
    limit: page.take,
    sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
    sortOrder: sort[0].dir,
    ...prepareFilterVal(filterVal),
  };

  let result: AxiosResponse<AssetsHostsDashboardResponse, any>;

  if (document.location.pathname.includes('assets/images') && assetHostIds) {
    result = await dashboardInstance.post<AssetsHostsDashboardResponse>(
      '/assets/hosts',
      {
        assetId:
          assetHostIds && assetHostIds.length > 0
            ? {
                $in: assetHostIds && assetHostIds.length > 0 ? [...assetHostIds] : null,
              }
            : ' ',
      },
      {
        params: paramsData,

        paramsSerializer: (params) => {
          const stringifiedParams = qs.stringify(params, { encode: false });

          return stringifiedParams;
        },
      },
    );
  } else if (
    (document.location.pathname.includes('assets/groups') && assetHostIds) ||
    assetHostIds
  ) {
    result = await dashboardInstance.post<AssetsHostsDashboardResponse>(
      '/assets/hosts',
      {
        assetId:
          assetHostIds.length > 0
            ? {
                $in: assetHostIds && assetHostIds.length > 0 ? [...assetHostIds] : null,
              }
            : ' ',
      },
      {
        params: paramsData,
        paramsSerializer: (params) => {
          const stringifiedParams = qs.stringify(params, { encode: false });

          return stringifiedParams;
        },
      },
    );
  } else {
    result = await dashboardInstance.get<AssetsHostsDashboardResponse>('/assets/hosts', {
      params: paramsData,

      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });
        if (document.location.pathname.includes('assets/hosts')) {
          history.replace({
            pathname: `/lk/assets/hosts?${stringifiedParams}`,
          });
        }

        return stringifiedParams;
      },
    });
  }

  return result.data;
}

export async function getNetworkDevices(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
): Promise<AssetsDevicesDashboardResponse> {
  let result: AxiosResponse<AssetsDevicesDashboardResponse, any>;

  if (document.location.pathname.includes('assets/devices')) {
    result = await dashboardInstance.get<AssetsDevicesDashboardResponse>('/assets/devices', {
      params: {
        skip: page.skip,
        limit: page.take,
        sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
        sortOrder: sort[0].dir,
        ...prepareFilterVal(filterVal),
      },

      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });
        history.replace({
          pathname: `/lk/assets/devices?${stringifiedParams}`,
        });

        return stringifiedParams;
      },
    });
  } else {
    result = await dashboardInstance.get<AssetsDevicesDashboardResponse>('/assets/devices', {
      params: {
        skip: page.skip,
        limit: page.take,
        sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
        sortOrder: sort[0].dir,
        ...prepareFilterVal(filterVal),
      },

      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });

        return stringifiedParams;
      },
    });
  }

  return result.data;
}

export async function getImageList(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
): Promise<AssetsImagesDashboardResponse> {
  let result: AxiosResponse<AssetsImagesDashboardResponse, any>;

  if (document.location.pathname.includes('assets/images')) {
    result = await dashboardInstance.get<AssetsImagesDashboardResponse>('/assets/images', {
      params: {
        skip: page.skip,
        limit: page.take,
        sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
        sortOrder: sort[0].dir,
        ...prepareFilterVal(filterVal),
      },

      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });
        history.replace({
          pathname: `/lk/assets/images?${stringifiedParams}`,
        });

        return stringifiedParams;
      },
    });
  } else {
    result = await dashboardInstance.get<AssetsImagesDashboardResponse>('/assets/images', {
      params: {
        skip: page.skip,
        limit: page.take,
        sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
        sortOrder: sort[0].dir,
        ...prepareFilterVal(filterVal),
      },

      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });

        return stringifiedParams;
      },
    });
  }

  return result.data;
}

export async function deleteImage(assetId: string): Promise<AssetDeletionResponse | null> {
  const result = await vmInstance.delete<AssetDeletionResponse>(`/assets/${assetId}`);

  return result.data;
}
export async function getBulletinImages(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
  bulletinId: string | null,
): Promise<AssetsImagesDashboardResponse> {
  const result = await dashboardInstance.get<AssetsImagesDashboardResponse>('/assets/images', {
    params: {
      skip: page.skip,
      limit: page.take,
      sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
      sortOrder: sort[0].dir,
      '&bulletinId[$eq]': `${bulletinId}`,
      ...prepareFilterVal(filterVal),
    },

    paramsSerializer: (params) => {
      const stringifiedParams = qs.stringify(params, { encode: false });

      return stringifiedParams;
    },
  });

  return result.data;
}
export async function getBulletinImagesWl(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
  bulletinId: string | null,
): Promise<AssetsImagesDashboardResponse> {
  const result = await dashboardInstance.get<AssetsImagesDashboardResponse>('/assets/images', {
    params: {
      skip: page.skip,
      limit: page.take,
      sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
      sortOrder: sort[0].dir,
      '&whitelistedBulletinId[$eq]': `${bulletinId}`,
      ...prepareFilterVal(filterVal),
    },

    paramsSerializer: (params) => {
      const stringifiedParams = qs.stringify(params, { encode: false });

      return stringifiedParams;
    },
  });

  return result.data;
}
export async function getRegistryList(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
  registryIds: string[] | null | undefined,
): Promise<ContainerRegistriesDashboardResponse> {
  let result: AxiosResponse<ContainerRegistriesDashboardResponse, any>;

  if (document.location.pathname.includes('assets/images/')) {
    result = await dashboardInstance.get<ContainerRegistriesDashboardResponse>(
      'container-registries',
      {
        params: {
          skip: page.skip,
          limit: page.take,
          sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
          sortOrder: sort[0].dir,
          registryId: { $in: registryIds },
          ...prepareFilterVal(filterVal),
        },

        paramsSerializer: (params) => {
          const stringifiedParams = qs.stringify(params, { encode: false });

          return stringifiedParams;
        },
      },
    );
  } else if (document.location.pathname.includes('assets/registries/')) {
    result = await dashboardInstance.get<ContainerRegistriesDashboardResponse>(
      'container-registries',
      {
        params: {
          skip: page.skip,
          limit: page.take,
          sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
          sortOrder: sort[0].dir,
          ...prepareFilterVal(filterVal),
        },

        paramsSerializer: (params) => {
          const stringifiedParams = qs.stringify(params, { encode: false });
          history.replace({
            pathname: `/lk/assets/registries?${stringifiedParams}`,
          });

          return stringifiedParams;
        },
      },
    );
  } else {
    result = await dashboardInstance.get<ContainerRegistriesDashboardResponse>(
      'container-registries',
      {
        params: {
          skip: page.skip,
          limit: page.take,
          sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
          sortOrder: sort[0].dir,
          ...prepareFilterVal(filterVal),
        },

        paramsSerializer: (params) => {
          const stringifiedParams = qs.stringify(params, { encode: false });

          return stringifiedParams;
        },
      },
    );
  }

  return result.data;
}

export async function createRegistry(
  registerData: ContainerRegistryCreationRequest,
): Promise<ContainerRegistryCreationResponse> {
  const result = await vmInstance.post<ContainerRegistryCreationResponse>(
    'container-registries',
    registerData,
  );

  return result.data;
}

export async function editRegistry(
  registerData: IEditRegister,
): Promise<ContainerRegistryCreationResponse> {
  const result = await vmInstance.patch<ContainerRegistryUpdateResponse>(
    `container-registries/${registerData.registryId}`,
    registerData.payload,
  );

  return result.data;
}

export async function getTotalAssets(): Promise<AssetsResponse> {
  const result = await vmInstance.get<AssetsResponse>('/assets?type=host', {
    params: {
      limit: 0,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { encode: false });
    },
  });

  return result.data;
}

export async function getAllTotalInfo(): Promise<TotalAssetCount> {
  const result = await dashboardInstance.get<TotalAssetCount>('/widgets/assets/count');

  return result.data;
}

export async function getAgentsStatus(
  agentsIdArr: AgentsStatusRequest,
): Promise<AgentsStatusResponse> {
  if (agentsIdArr.length < 1) return [];
  const result = await dashboardInstance.post<AgentsStatusResponse>('/agents/status', agentsIdArr);

  return result.data;
}

export async function getAssetDashboard(assetId: string): Promise<AssetHostDashboardResponse> {
  const result = await dashboardInstance.get<AssetHostDashboardResponse>(
    `/assets/hosts/${assetId}`,
  );

  return result.data;
}

export async function getImage(assetId: string): Promise<AssetImageDashboardResponse> {
  const result = await dashboardInstance.get<AssetImageDashboardResponse>(
    `/assets/images/${assetId}`,
  );

  return result.data;
}

export async function getRegistry(
  registryId: string | undefined,
): Promise<ContainerRegistryDashboardResponse> {
  const result = await dashboardInstance.get<ContainerRegistryDashboardResponse>(
    `/container-registries/${registryId}`,
  );

  return result.data;
}

export async function getDeviceRouter(assetId: string): Promise<AssetRouterDashboardResponse> {
  const result = await dashboardInstance.get<AssetRouterDashboardResponse>(
    `/assets/routers/${assetId}`,
  );

  return result.data;
}

export async function getDeviceFirewall(assetId: string): Promise<AssetFirewallDashboardResponse> {
  const result = await dashboardInstance.get<AssetFirewallDashboardResponse>(
    `/assets/firewalls/${assetId}`,
  );

  return result.data;
}

export async function getDeviceSwitch(assetId: string): Promise<AssetSwitchDashboardResponse> {
  const result = await dashboardInstance.get<AssetSwitchDashboardResponse>(
    `/assets/switches/${assetId}`,
  );

  return result.data;
}

export async function getDeviceHypervisor(
  assetId: string,
): Promise<AssetHypervisorDashboardResponse> {
  const result = await dashboardInstance.get<AssetHypervisorDashboardResponse>(
    `/assets/hypervisors/${assetId}`,
  );

  return result.data;
}

export async function getRepositories(
  registryId: string | undefined,
  page: IPageState,
  filterVal: IFilterVal[],
  sort: SortDescriptor[],
): Promise<ContainerRegistryRepositoriesDashboardResponse> {
  const result = await dashboardInstance.get<ContainerRegistryRepositoriesDashboardResponse>(
    `/container-registries/${registryId}/repositories`,
    {
      params: {
        skip: page.skip,
        limit: page.take,
        sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
        sortOrder: sort[0].dir,
        ...prepareFilterVal(filterVal),
      },

      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });
        if (document.location.pathname.includes('repositories')) {
          history.replace({
            pathname: `/lk/assets/registries/${registryId}/repositories?${stringifiedParams}`,
          });
        }

        return stringifiedParams;
      },
    },
  );

  return result.data;
}

export async function getAssetDashboardOs(): Promise<AssetOs> {
  const result = await dashboardInstance.get<AssetOs>('/widgets/assets/hosts/asset-os');

  return result.data;
}

export async function getAgentStatusesDashboard(): Promise<AgentStatuses> {
  const result = await dashboardInstance.get<AgentStatuses>('/widgets/agent-statuses');

  return result.data;
}

export async function getOsPlatformsLinux(): Promise<OSLinuxPlatforms> {
  const result = await dashboardInstance.get<OSLinuxPlatforms>(
    '/widgets/assets/hosts/os-platforms/linux',
    {
      params: {
        limit: 10,
        sortOrder: 'desc',
      },
    },
  );

  return result.data;
}

export async function getOsPlatformsWindows(): Promise<OSWindowsPlatforms> {
  const result = await dashboardInstance.get<OSWindowsPlatforms>(
    '/widgets/assets/hosts/os-platforms/windows',
    {
      params: {
        limit: 10,
        sortOrder: 'desc',
      },
    },
  );

  return result.data;
}

export async function getInventarizationAssetsAge(): Promise<InventorizationAssetsAge> {
  const result = await dashboardInstance.get<InventorizationAssetsAge>(
    '/widgets/assets/hosts/inventorization-assets-age',
  );

  return result.data;
}

// export async function getAssetsTypes(): Promise<AssetTypes> {
//   const result = await dashboardInstance.get<AssetTypes>('/widgets/asset-types');

//   return result.data;
// }

export async function getAssetHostsAuditType(): Promise<AssetHostsAuditType> {
  const result = await dashboardInstance.get<AssetHostsAuditType>(
    '/widgets/assets/hosts/audit-types',
  );

  return result.data;
}

export async function getVulnerableAssets(sortField?: string): Promise<VulnerableAssetsResponse> {
  const params: {
    limit: number;
    sortOrder: string;
    sortField?: string;
  } = {
    limit: 10,
    sortOrder: 'desc',
  };
  if (sortField) params.sortField = sortField;
  const result = await dashboardInstance.get<VulnerableAssetsResponse>(
    '/widgets/assets/hosts/vulnerable-assets',
    {
      params,
    },
  );

  return result.data;
}

export async function getCvssScore(): Promise<CvssScores> {
  const result = await dashboardInstance.get<CvssScores>('/widgets/assets/cvss-scores');

  return result.data;
}

export async function getVulnerableSoftware(): Promise<VulnerableSoftware> {
  const result = await dashboardInstance.get<VulnerableSoftware>(
    '/widgets/assets/hosts/vulnerable-software',
    {
      params: {
        limit: 10,
        sortOrder: 'desc',
      },
    },
  );

  return result.data;
}

export async function getBulletins(): Promise<BulletinsWidgetResponse> {
  const result = await dashboardInstance.get<BulletinsWidgetResponse>(
    '/widgets/assets/hosts/bulletins',
    {
      params: {
        limit: 10,
        sortOrder: 'desc',
      },
    },
  );

  return result.data;
}

export async function getTotalVulnerabilities(): Promise<TotalAssetVulnerabillitiesResponse> {
  const result = await dashboardInstance.get<TotalAssetVulnerabillitiesResponse>(
    '/widgets/assets/total-vulnerabillities',
  );

  return result.data;
}

export async function getAuditAssetAge(): Promise<AuditAssetsAgeResponse> {
  const result = await dashboardInstance.get<AuditAssetsAgeResponse>('/widgets/assets/audit-age');

  return result.data;
}
