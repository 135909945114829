import {
  DropDownList,
  ListItemProps,
  DropDownListChangeEvent,
} from '@progress/kendo-react-dropdowns';
import type { ReactElement } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '../../../../common/baseElements/Button';
import styles from '../../forms/AddAssetForm.module.scss';
import hostDescriptionLocale from '../../../../../utils/i18n/assetLocales/hostDescription.json';
import type { InventorizationHistory } from '../../../../../types/__generated/on-premise-solution/api/inventorizationHistory.v1';
import type { InventorizationDifference } from '../../../../../types/__generated/on-premise-solution/api/inventorizationDifference.v1';
import { NearestInfo } from '../NearestInfo';
import type { ApiError } from '../../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { LoadingPanel } from '../../../../common/tableLoader/LoadingPanel';

const itemRender = (
  li: React.ReactElement<HTMLLIElement>,
  itemProps: ListItemProps,
): ReactElement<HTMLLIElement, string | React.JSXElementConstructor<any>> => {
  const { index, dataItem } = itemProps;
  const itemChildren = (
    <span key={index}>
      <div>{dataItem.text}</div>
      <div style={{ fontSize: '11px' }}>
        {/* {dataItem.affectedSoftwareCount} уязв. ПО / {dataItem.vulnsCount} уязв. /{' '} */}
        {dataItem.counts} ПО
        {dataItem.os ? ` / ${dataItem.os}` : ''}
      </div>
    </span>
  );

  return React.cloneElement(li, li.props, itemChildren);
};

const listNoDataRender = (element: React.ReactElement<HTMLDivElement>): ReactElement => {
  const noData = <LoadingPanel />;

  return React.cloneElement(element, { ...element.props }, noData);
};

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof hostDescriptionLocale;

export function DiffByInvTab({
  data,
  isLoading,
  onClick,
  checkpoints,
  error,
  localError,
}: {
  data: InventorizationDifference | undefined;
  isLoading: boolean;
  onClick: (fd: string | undefined, sd: string | undefined) => void;
  checkpoints: InventorizationHistory | undefined;
  error: ApiError | null;
  localError?: string | null;
}): ReactElement {
  const dropdownData = useMemo(
    () =>
      checkpoints?.map((c, ind) => ({
        text: new Date(c.createdAt).toLocaleString(),
        id: ind,
        date: c.createdAt,
        counts: 'softwareCount' in c ? c.softwareCount : c.routeCount,
        os: c.os?.name || c.os?.version ? `${c.os?.name || ''} ${c.os?.version || ''}` : undefined,
      })),
    [checkpoints],
  );

  function getDatePoint(
    date:
      | Exclude<InventorizationDifference['sourceInventorizations'], null>['firstInventorization']
      | Exclude<InventorizationDifference['sourceInventorizations'], null>['secondInventorization']
      | undefined,
  ):
    | { text: string; id: number; date: string; counts: number; os: string | undefined }
    | undefined {
    if (!date || !(dropdownData && dropdownData.length)) return undefined;
    const index = dropdownData.findIndex((p) => p.date === date.createdAt);

    return index >= 0 ? dropdownData[index] : undefined;
  }
  const [firstPoint, setFirstPoint] = useState<
    { text: string; id: number; date: string; counts: number; os: string | undefined } | undefined
  >(getDatePoint(data?.sourceInventorizations?.firstInventorization));
  const [secondPoint, setSecondPoint] = useState<
    { text: string; id: number; date: string; counts: number; os: string | undefined } | undefined
  >(getDatePoint(data?.sourceInventorizations?.secondInventorization));

  useEffect(() => {
    setFirstPoint(getDatePoint(data?.sourceInventorizations?.firstInventorization));
    setSecondPoint(getDatePoint(data?.sourceInventorizations?.secondInventorization));
  }, [data?.sourceInventorizations, dropdownData]);

  const handleChange = (e: DropDownListChangeEvent, str: 'first' | 'second'): void => {
    const date = new Date(e.target.value.date);

    if (str === 'first') {
      if (secondPoint && date > new Date(secondPoint.date)) {
        setFirstPoint(secondPoint);
        setSecondPoint(e.target.value);
        // setFinishDate(new Date(e.target.value.date));
      } else {
        setFirstPoint(e.target.value);
        // setStartDate(new Date(e.target.value.date));
      }
    }
    if (str === 'second') {
      // console.log(date, firstPoint);
      if (firstPoint && date < new Date(firstPoint.date)) {
        setSecondPoint(firstPoint);
        setFirstPoint(e.target.value);
        // setStartDate(new Date(e.target.value.date));
      } else {
        setSecondPoint(e.target.value);
        // setFinishDate(new Date(e.target.value.date));
      }
    }

    // if (str === 'first') {
    //   setStartDate(new Date(e.target.value.date));
    // }
    // if (str === 'second') {
    //   setFinishDate(new Date(e.target.value.date));
    // }
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div>
          <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-end' }}>
            <div className={styles.rangeReg}>
              <div>{hostDescriptionLocale[currentLocale].changes.startInvPoint}</div>
              <DropDownList
                data={dropdownData}
                textField="text"
                dataItemKey="id"
                itemRender={itemRender}
                onChange={(e: DropDownListChangeEvent): void => handleChange(e, 'first')}
                defaultValue={getDatePoint(data?.sourceInventorizations?.firstInventorization)}
                value={firstPoint}
                listNoDataRender={listNoDataRender}
                required
              />
            </div>
            <div className={styles.rangeReg}>
              <div>{hostDescriptionLocale[currentLocale].changes.finishInvPoint}</div>
              <DropDownList
                data={dropdownData}
                textField="text"
                dataItemKey="id"
                onChange={(e: DropDownListChangeEvent): void => handleChange(e, 'second')}
                itemRender={itemRender}
                defaultValue={getDatePoint(data?.sourceInventorizations?.secondInventorization)}
                value={secondPoint}
                listNoDataRender={listNoDataRender}
                required
              />
            </div>
            <div className="k-form-buttons">
              <Button
                className="action"
                onClick={(): void => onClick(firstPoint?.date, secondPoint?.date)}
              >
                {hostDescriptionLocale[currentLocale].changes.btnShowChanges}
              </Button>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              gap: '20px',
              marginTop: '16px',
              fontSize: '13px',
            }}
          >
            <NearestInfo
              type="inventorization"
              className={`${styles.rangeReg} ${styles.audit_info}`}
              isLoading={isLoading}
              data={data?.sourceInventorizations?.firstInventorization}
            />
            <NearestInfo
              type="inventorization"
              className={`${styles.rangeReg} ${styles.audit_info}`}
              isLoading={isLoading}
              data={data?.sourceInventorizations?.secondInventorization}
            />
          </div>
        </div>
      </div>
      {localError && (
        <div style={{ color: 'rgb(243, 23, 0)', marginTop: '12px' }}>{localError}</div>
      )}
      {error && error.code === '404' && (
        <div style={{ color: 'rgb(243, 23, 0)', marginTop: '12px' }}>
          {hostDescriptionLocale[currentLocale].changes.errors.noDataTime}
        </div>
      )}
    </>
  );
}
