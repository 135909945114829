/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-pascal-case */
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { State, process, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { useRef, useState } from 'react';
import { Button } from './baseElements/Button';
import { FILTER_INITIAL, PAGEABLE_DATA } from '../../utils/helpers/constants';
import localeAccount from '../../utils/i18n/accountLocales/accountList.json';
import localeSettings from '../../utils/i18n/settingsLocales/settings.json';
import styles from '../features/Settings/ApiKeys/ApiKeyList.module.scss';
import { CellSelectMethodApi } from '../features/Settings/ApiKeys/cells/CellSelectMethodApi';
import { useApiKeyContext } from '../../hooks/useApiKeyContext';
import { addAllMethods } from '../../utils/helpers/apikey-helpers';
import type { IApiMethod } from '../../utils/helpers/types';
import { Input } from './baseElements/Input';
import { handlerCustomSearch } from '../../utils/helpers/local-filter-helpers';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAccount;

export function CommonMethodList({
  apiMethods,
  componentName,
}: {
  apiMethods: IApiMethod[];
  componentName: string;
}): React.ReactElement {
  const pageLimitGrid: { [key: string]: string } =
    localStorage.getItem('pageLimitGrid') &&
    JSON.parse(localStorage.getItem('pageLimitGrid') || '');

  const initialDataState: State = {
    sort: [{ field: 'name', dir: 'desc' }],
    take: pageLimitGrid && pageLimitGrid.ApiMessods ? Number(pageLimitGrid.ApiMessods) : 15,
    skip: 0,
    filter: FILTER_INITIAL,
  };

  const fieldFindInTable = 'path';

  const [dataState, setDataState] = useState<State>(initialDataState);

  const [customSearch, setCustomSearch] = useState('');

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  const { apiMethodsForm, setApiMethodsForm } = useApiKeyContext();

  return (
    <div className="method-list">
      <Input
        className={`k-input k-input-md ${styles.apiKeyCustomSearch}`}
        name="customSearch"
        placeholder={localeSettings[currentLocale].apiKey.method.methodNamePlaceholder}
        value={customSearch}
        onChange={(e): void =>
          handlerCustomSearch(
            e,
            setCustomSearch,
            setDataState,
            filterValue,
            dataState,
            fieldFindInTable,
          )
        }
      />
      {componentName === 'MethodListForForm' && (
        <div className={`${styles.methodListActions} k-form-select-buttons`}>
          <Button
            onClick={(): void => addAllMethods(apiMethods, apiMethodsForm, setApiMethodsForm)}
          >
            {localeSettings[currentLocale].apiKey.method.addAllMethods}
          </Button>
          {apiMethodsForm && apiMethodsForm.length > 0 && (
            <Button onClick={(): void => setApiMethodsForm(null)}>
              {localeSettings[currentLocale].apiKey.method.btnDelAllMethods} (
              {apiMethodsForm?.length})
            </Button>
          )}
        </div>
      )}
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <Grid
            pageable={PAGEABLE_DATA}
            sortable
            data={process(apiMethods || [], dataState)}
            {...dataState}
            onDataStateChange={(e: GridDataStateChangeEvent): void => {
              setDataState(e.dataState);
              if (pageLimitGrid) {
                const pageLimit = {
                  ...pageLimitGrid,
                  componentName: String(e.dataState.take),
                };
                localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
              } else {
                const pageLimit = {
                  componentName: String(e.dataState.take),
                };
                localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
              }
            }}
            scrollable="none"
          >
            {componentName === 'MethodListForForm' && (
              <Column
                field="addAccount"
                title={localeAccount[currentLocale].titleForColumnGrid.selectAccount}
                cell={CellSelectMethodApi}
                width="80px"
              />
            )}
            <Column
              field="path"
              title={localeSettings[currentLocale].apiKey.method.methodName}
              filter="text"
            />
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </div>
  );
}
