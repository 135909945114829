import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import type { InventorizationResponse } from '../../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import { WrapperMenu } from './WrapperMenu';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function MemoryNetDevices({ data }: { data: InventorizationResponse }): React.ReactElement {
  if (
    (data.type === 'router' || data.type === 'firewall') &&
    (data.class === 'mikrotik' ||
      data.class === 'fortinet' ||
      data.class === 'huawei' ||
      data.class === 'juniper' ||
      data.class === 'eltex' ||
      data.class === 'paloaltonetworks' ||
      data.class === 'checkpoint')
  )
    return (
      <WrapperMenu title={locale[currentLocale].memory.title}>
        <table className="column">
          <tr>
            <td>{locale[currentLocale].memoryNetDevices.total}</td>
            {data.result.memory?.total ? (
              <td>
                {Math.round(Number(data.result.memory?.total) / 1024 / 1024)}{' '}
                {locale[currentLocale].memoryNetDevices.sizeMb}
              </td>
            ) : (
              <td>-</td>
            )}
          </tr>
          <tr>
            <td>{locale[currentLocale].memoryNetDevices.free}</td>
            {data.result.memory?.free ? (
              <td>
                {Math.round(Number(data.result.memory?.free) / 1024 / 1024)}{' '}
                {locale[currentLocale].memoryNetDevices.sizeMb}
              </td>
            ) : (
              <td>-</td>
            )}
          </tr>
        </table>
      </WrapperMenu>
    );

  if (data.type === 'hypervisor')
    return (
      <WrapperMenu title={locale[currentLocale].memory.title}>
        <table className="column">
          <tr>
            <td>{locale[currentLocale].memoryNetDevices.total}</td>
            {data.result.memory?.total_size ? (
              <td>
                {Math.round(Number(data.result.memory?.total_size) / 1024 / 1024)}{' '}
                {locale[currentLocale].memoryNetDevices.sizeMb}
              </td>
            ) : (
              <td>-</td>
            )}
          </tr>
        </table>
      </WrapperMenu>
    );

  return <div />;
}
