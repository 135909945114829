/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import type { GridCellProps } from '@progress/kendo-react-grid';
import type { IServicesLin } from '../../../../../../utils/helpers/types';

export function CellLinPath(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { fragment_path }: IServicesLin = dataItem;

  return <td title={fragment_path || ''}>{fragment_path}</td>;
}
