/* eslint-disable no-nested-ternary */
import { useMemo } from 'react';
import type {
  InventorizationDifferenceLinux,
  InventorizationDifferenceWindows,
} from '../../../../../types/__generated/on-premise-solution/api/inventorizationDifference.v1';
import {
  prepareDiffObjectData,
  type DiffFilterTypes,
  createFromListInvData,
  IDiffInvElementInfo,
  defineWrapperStatuses,
} from '../../../../../utils/helpers/difference-modal-helper';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { WrapperMenu } from '../../../Task/taskIDetails/iDetailsComponents/WrapperMenu';
import { DiffDiskPhysicalWrapper } from './diskContent/DiffDiskPhysicalWrapper';
import { DiffDiskLogical } from './diskContent/DiffDiskLogical';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function DiffDisk({
  data,
  filter,
}: {
  data: InventorizationDifferenceLinux | InventorizationDifferenceWindows;
  filter: DiffFilterTypes;
}): React.ReactElement {
  const pDisksData = useMemo(() => {
    const result: {
      infoData: any;
      partitionsData: any[];
      name: string | null;
      newName: string | null;
    }[] = [];

    data.disk?.forEach((pDisk) => {
      let diskName = null;
      let newDiskName = null;
      const infoData = prepareDiffObjectData(
        {
          closed: pDisk.info?.old,
          noChanges: pDisk.info?.noChanges,
          updated: pDisk.info?.new,
        },
        filter,
      );
      const partitionsData = createFromListInvData(
        pDisk.partitions as unknown as IDiffInvElementInfo[],
        filter,
      );

      if (pDisk.info?.noChanges?.name) diskName = pDisk.info?.noChanges?.name as string;
      else if (pDisk.info?.old?.name) diskName = pDisk.info?.old?.name as string;
      else if (pDisk.info?.new?.name) diskName = pDisk.info?.new?.name as string;

      if (pDisk.info?.new?.name && pDisk.info?.new?.name !== diskName)
        newDiskName = pDisk.info?.new?.name as string;

      if (Object.keys(infoData).length || (partitionsData && partitionsData.length))
        result.push({
          infoData: prepareDiffObjectData(
            {
              closed: pDisk.info?.old,
              noChanges: pDisk.info?.noChanges,
              updated: pDisk.info?.new,
            },
            filter,
          ),
          partitionsData: createFromListInvData(
            pDisk.partitions as unknown as IDiffInvElementInfo[],
            filter,
          ),
          name: diskName,
          newName: newDiskName,
        });
    });

    return result;
  }, [data.disk, filter]);

  const lDisksData = useMemo(
    () =>
      data.type === 'windows'
        ? createFromListInvData(data.logical_drives as unknown as IDiffInvElementInfo[], filter)
        : [],
    [data.type === 'windows' ? data.logical_drives : null, filter],
  );

  const infoStatuses: string[] = [];
  const physicalStatuses: string[] = [];
  const logicalStatuses: string[] = [];
  data.disk?.forEach((pd) => {
    if (Object.keys(pd.info.old || {}).length) infoStatuses.push('closed');
    if (Object.keys(pd.info.noChanges || {}).length) infoStatuses.push('noChanges');
    if (Object.keys(pd.info.new || {}).length) infoStatuses.push('new');
    pd.partitions?.forEach((part) => {
      if (Object.keys(part.old || {}).length) physicalStatuses.push('closed');
      if (Object.keys(part.noChanges || {}).length) physicalStatuses.push('noChanges');
      if (Object.keys(part.new || {}).length) physicalStatuses.push('new');
    });
  });

  if (data.type === 'windows')
    data.logical_drives?.forEach((ld) => {
      if (Object.keys(ld.old || {}).length) logicalStatuses.push('closed');
      if (Object.keys(ld.noChanges || {}).length) logicalStatuses.push('noChanges');
      if (Object.keys(ld.new || {}).length) logicalStatuses.push('new');
    });

  const isDisks =
    !!pDisksData.filter(
      (pDisk) =>
        (pDisk.partitionsData && pDisk.partitionsData.length) ||
        (pDisk.infoData && Object.keys(pDisk.infoData || {}).length),
    ).length ||
    (lDisksData && lDisksData.length);

  return isDisks ? (
    <WrapperMenu
      title={locale[currentLocale].disks.title}
      diffStatuses={
        filter === 'all' ? infoStatuses.concat(...physicalStatuses, ...logicalStatuses) : undefined
      }
    >
      {pDisksData && pDisksData.length ? (
        <WrapperMenu
          title={locale[currentLocale].disks.titlePhysicalDrives}
          diffStatuses={filter === 'all' ? infoStatuses.concat(...physicalStatuses) : undefined}
        >
          {pDisksData.map((pDisk) => (
            <DiffDiskPhysicalWrapper disk={pDisk} needShowStatus={filter === 'all'} />
          ))}
        </WrapperMenu>
      ) : (
        <></>
      )}
      {lDisksData && lDisksData.length ? (
        <WrapperMenu
          title={locale[currentLocale].disks.titleLogicalDisk}
          diffStatuses={filter === 'all' && logicalStatuses.length ? logicalStatuses : undefined}
        >
          <DiffDiskLogical disks={lDisksData} />
        </WrapperMenu>
      ) : (
        <></>
      )}

      {/* {data.disk && data.disk.length ? (
        data.disk.map((disk) => <DiffDiskPhysicalWrapper disk={disk} filter={filter} />)
      ) : (
        <></>
      )} */}
    </WrapperMenu>
  ) : (
    <></>
  );

  // return (
  //   <WrapperMenu title={locale[currentLocale].disks.title}>
  //     {/* <WrapperMenu title={locale[currentLocale].disks.titlePhysicalDrives}>
  //       <DiskPhysical data={data} />
  //     </WrapperMenu> */}
  //     {/* {data.type === 'linux' && (
  //       <WrapperMenu title={locale[currentLocale].disks.titleVirtualDisk}>
  //         <DiskVirtual data={data} />
  //       </WrapperMenu>
  //     )} */}
  //     {/* <DiskLogical data={data} /> */}
  //   </WrapperMenu>
  // );
}
