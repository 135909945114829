import { Dialog } from '@progress/kendo-react-dialogs';
import { useEffect, useState } from 'react';
import {
  TabStrip,
  TabStripTab,
  type TabStripSelectEventArguments,
} from '@progress/kendo-react-layout';
import localeHostGroups from '../../../../utils/i18n/assetLocales/assetGroups.json';
import locale from '../../../../utils/i18n/credentials/credentials.json';
import { getUrlCredential } from '../../../../utils/helpers/credential-helper';
import type { IDataHooks } from '../../../../utils/helpers/types';
import { useCredentialList } from '../../../../hooks/components/useCredentialList';
import { Button } from '../../../common/baseElements/Button';
import { CommonCredentialList } from '../../../common/CommonCredentialList';
import { СommonTableLoader } from '../../../common/tableLoader/CommonTableLoader';
import { useCredential } from '../../../../hooks/components/useCredential';
import { AddCredentialForm } from '../form/AddCredentialForm';
import '../../../common/modal/BaseModal.scss';
import { useCredentialContext } from '../../../../hooks/useCredentialContext';
import { useNetDeviceContext } from '../../../../hooks/useNetDeviceContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHostGroups;

export function CredentialListModal(): React.ReactElement {
  const urlParams = getUrlCredential('CredentialList');

  const checkPath =
    document.location.pathname.includes('/lk/assets/images') ||
    document.location.pathname.includes('/lk/assets/registries');

  const dataForHooks: IDataHooks = {
    name: 'name',
    componentName: 'CredentialListModal',
  };

  const {
    data,
    isLoading,
    isFetching,
    filterStatus,
    isError,
    errorMessage,
    filterValue,
    setFilter,
  } = useCredentialList(urlParams, dataForHooks);

  const { setSelectedCredential, selectedCredential, setAddCredential, isAddCredential } =
    useCredentialContext();

  const { setCredForAsset } = useCredential();

  const { typeOfDevice } = useNetDeviceContext();

  const [creditType, setCreditType] = useState('');

  useEffect(() => {
    if (
      selectedCredential?.osType === 'linux' ||
      selectedCredential?.assetType === 'router' ||
      selectedCredential?.assetType === 'firewall' ||
      selectedCredential?.assetType === 'switch'
    ) {
      setCreditType('ssh');
    } else if (selectedCredential?.osType === 'windows') {
      setCreditType('winrm');
    } else if (checkPath) {
      setCreditType('container_registry');
    } else if (
      !checkPath &&
      !selectedCredential?.assetType &&
      selectedCredential?.osType === undefined
    ) {
      setCreditType('hashicorp_vault_userpass');
    }

    if (!checkPath && !selectedCredential?.assetType && selectedCredential?.osType === undefined) {
      setCreditType('hashicorp_vault_token');
    }

    if (
      (!selectedCredential?.osType && selectedCredential?.typeOfDevice === 'hypervisor') ||
      selectedCredential?.assetType === 'hypervisor'
    ) {
      setCreditType('snmp');
    }
  }, [
    checkPath,
    data,
    filterValue,
    selectedCredential,
    selectedCredential?.assetType,
    selectedCredential?.osType,
    selectedCredential?.type,
    setFilter,
  ]);

  const [selected, setSelected] = useState<number>(0);

  const handleSelect = (e: TabStripSelectEventArguments): void => {
    setSelected(e.selected);
  };

  return (
    <div>
      <Dialog
        title={locale[currentLocale].table.titleModalList}
        onClose={(): void => setSelectedCredential(null)}
      >
        <div className="k-form">
          <div className="form-content credential-list">
            <div className="modal-credential-actions">
              <div className="modal-topactions">
                <Button
                  fill="action"
                  onClick={(): void => {
                    setAddCredential(true);
                  }}
                >
                  {locale[currentLocale].table.createCredential}
                </Button>
              </div>
            </div>
            {(typeOfDevice === 'hypervisor' || selectedCredential?.assetType === 'hypervisor') && (
              <TabStrip selected={selected} onSelect={handleSelect}>
                {(typeOfDevice === 'hypervisor' ||
                  selectedCredential?.assetType === 'hypervisor') && (
                  <TabStripTab title="ssh">
                    <CommonCredentialList dataForHooks={dataForHooks} creditType="ssh" />
                  </TabStripTab>
                )}
                {creditType === 'snmp' && (
                  <TabStripTab title="snmp">
                    <CommonCredentialList dataForHooks={dataForHooks} creditType="snmp" />
                  </TabStripTab>
                )}
              </TabStrip>
            )}
            {(creditType === 'hashicorp_vault_userpass' ||
              creditType === 'hashicorp_vault_token') && (
              <TabStrip selected={selected} onSelect={handleSelect}>
                <TabStripTab title="hashicorp_vault_userpass">
                  <CommonCredentialList
                    dataForHooks={dataForHooks}
                    creditType="hashicorp_vault_userpass"
                  />
                </TabStripTab>
                <TabStripTab title="hashicorp_vault_token">
                  <CommonCredentialList
                    dataForHooks={dataForHooks}
                    creditType="hashicorp_vault_token"
                  />
                </TabStripTab>
              </TabStrip>
            )}
            {creditType !== 'snmp' &&
              creditType !== 'hashicorp_vault_userpass' &&
              creditType !== 'hashicorp_vault_token' && (
                <CommonCredentialList dataForHooks={dataForHooks} creditType={creditType} />
              )}
            <СommonTableLoader queryData={filterStatus} filteringData={filterStatus} />{' '}
            {isError && <div className="info alert alert-danger">Error: {errorMessage}</div>}
          </div>
          <div className="k-form-buttons">
            <Button
              type="submit"
              disabled={!selectedCredential?.credentialName}
              onClick={setCredForAsset}
            >
              {localeHostGroups[currentLocale].modal.btnSave}
            </Button>
            <Button type="button" onClick={(): void => setSelectedCredential(null)}>
              {localeHostGroups[currentLocale].modal.btnCancel}
            </Button>
          </div>
        </div>
      </Dialog>
      {isAddCredential && <AddCredentialForm />}
    </div>
  );
}
