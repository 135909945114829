/* eslint-disable react/jsx-no-useless-fragment */
import { useSystemInfoContext } from '../../hooks/useSystemInfoContext';
import localeDbFirstUpdated from '../../utils/i18n/settingsLocales/settings.json';
import styles from '../features/Settings/Settings.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeDbFirstUpdated;
export function CommonDBFirstUpdatedInfo(): React.ReactElement {
  const { dbFirstUpdated } = useSystemInfoContext();

  return (
    <>
      {dbFirstUpdated && (
        <div className={styles.dbFirstUpdatedInfoWrap}>
          <div className={styles.dbFirstUpdatedInfoContent}>
            <span className="k-i-loader" />{' '}
            <span>{localeDbFirstUpdated[currentLocale].dbFirstUpdated.info}</span>
          </div>
        </div>
      )}
    </>
  );
}
