/* eslint-disable react/jsx-pascal-case */
// import styles from './VDB.module.scss';
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
} from '@progress/kendo-react-charts';
import localeVulnDatabase from '../../../utils/i18n/taskLocale/vulnDatabase.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeVulnDatabase;

export function CountsByTypeDashboard({
  data,
}: {
  data:
  | {
    name: string;
    count: number;
    explode?: boolean;
  }[]
  | undefined;
}): React.ReactElement {
  return (
    <Chart
      style={{
        width: '300px',
        height: '240px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        marginTop: '12px',
        padding: '0 12px 12px',
      }}
      seriesColors={['#fecc09', '#f49c0f', '#44c74f', '#333']}
    >
      <ChartTitle text={localeVulnDatabase[currentLocale].charts.byType.title} />
      <ChartLegend position="bottom" />
      <ChartSeries>
        <ChartSeriesItem
          type="pie"
          data={data}
          tooltip={{ visible: true }}
          categoryField="name"
          field="count"
        />
      </ChartSeries>
    </Chart>
  );
}
