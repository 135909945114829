/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useRef, useState } from 'react';
import { loadMessages } from '@progress/kendo-react-intl';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import localeAuditTask from '../../../../utils/i18n/taskLocale/auditTask.json';
import { VulnDescriptionModal } from './VulnDescriptionModal';
import { VulnModalHosts } from './VulnModalHosts';
import { VulnModalRelated } from './VulnModalRelated';
import { VulnReferenceList } from './VulnReferenceList';
import { VulnModalNetDev } from './VulnModalNetDev';
import { VulnModalImage } from './VulnModalImage';
import type { BulletinDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/bulletinDashboardResponse.v1';
import { VulnExploitsList } from './VulnExploitsList';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

loadMessages(localeAuditTask[currentLocale], currentLocale);

export function TabsVulnModal({
  cveName,
  data,
  handleSelect,
  selected,
}: {
  cveName: string | null;
  data: BulletinDashboardResponse | undefined;
  handleSelect: (e: TabStripSelectEventArguments) => void;
  selected: number;
}): React.ReactElement {
  const [references, setReferences] = useState<string[] | null | undefined>(null);

  const cisaKev = useRef<string | undefined>(undefined);

  useEffect(() => {
    if (data && 'cisa_kev' in data && data.cisa_kev?.url) cisaKev.current = data.cisa_kev?.url;
  }, [data]);

  useEffect(() => {
    if (data?.references && !cisaKev.current) {
      setReferences(data.references);
    } else if (data?.references && cisaKev.current) {
      setReferences([...data.references, cisaKev.current]);
    }
  }, [data?.references, cisaKev]);

  const assetTitle = `${localeAuditTask[currentLocale].vulnModal.assets} (${
    Number(data?.totalHostAssets) + Number(data?.totalHostAssetsWhitelisted)
  })`;

  const netDevTitle = `${localeAuditTask[currentLocale].vulnModal.netDev} (${
    Number(data?.totalNetworkDeviceAssets) + Number(data?.totalNetworkDeviceAssetsWhitelisted)
  })`;

  const netImageTitle = `${localeAuditTask[currentLocale].vulnModal.images} (${
    Number(data?.totalImageAssets) + Number(data?.totalImageAssetsWhitelisted)
  })`;

  const exploits = (data && 'exploits' in data && data.exploits) || null;

  return (
    <TabStrip selected={selected} onSelect={handleSelect}>
      <TabStripTab title={localeAuditTask[currentLocale].vulnModal.tabsDesTitle}>
        <VulnDescriptionModal data={data} />
      </TabStripTab>

      <TabStripTab
        title={`${localeAuditTask[currentLocale].vulnModal.exploits} (${
          exploits ? exploits.length : 0
        })`}
      >
        <VulnExploitsList exploits={exploits} />
      </TabStripTab>
      <TabStripTab
        title={`${localeAuditTask[currentLocale].vulnModal.links} (${
          references ? references.length : 0
        })`}
      >
        <VulnReferenceList references={references} />
      </TabStripTab>
      <TabStripTab
        title={`${localeAuditTask[currentLocale].vulnModal.related} ${
          data?.related ? `(${data?.related?.length})` : ''
        }`}
      >
        <VulnModalRelated data={data} />
      </TabStripTab>
      {(data?.totalHostAssets || data?.totalHostAssetsWhitelisted) && (
        <TabStripTab title={assetTitle}>
          <VulnModalHosts
            bulletinId={cveName}
            totalHostAssetsWhitelisted={data?.totalHostAssetsWhitelisted}
          />
        </TabStripTab>
      )}
      {(data?.totalNetworkDeviceAssets || data?.totalNetworkDeviceAssetsWhitelisted) && (
        <TabStripTab title={netDevTitle}>
          <VulnModalNetDev
            bulletinId={cveName}
            totalNetworkDeviceAssetsWhitelisted={data?.totalNetworkDeviceAssetsWhitelisted}
          />
        </TabStripTab>
      )}
      {data?.totalImageAssets && (
        <TabStripTab title={netImageTitle}>
          <VulnModalImage
            bulletinId={cveName}
            totalImageAssetsWhitelisted={data.totalImageAssetsWhitelisted}
          />
        </TabStripTab>
      )}
    </TabStrip>
  );
}
