import { Grid, GridColumn, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useState } from 'react';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import type { InventorizationResponse } from '../../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import { StandartCell } from '../../../../common/cells/StandartCell';
import { NormalDateCell } from '../../../../common/cells/NormalDateCell';
import { WrapperMenu } from './WrapperMenu';
import { LAZY_PAGEABLE_DATA } from '../../../../../utils/helpers/constants';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function UsersNetDevices({ data }: { data: InventorizationResponse }): React.ReactElement {
  const [page, setPage] = useState({ take: 5, skip: 0 });

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  return (
    <WrapperMenu title={locale[currentLocale].users.title}>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <Grid
            data={
              Array.isArray(data.result.users)
                ? data.result.users.slice(page.skip, page.take + page.skip)
                : null
            }
            pageable={LAZY_PAGEABLE_DATA}
            scrollable="none"
            total={Array.isArray(data.result.users) ? data.result.users?.length : 0}
            pageSize={page.take}
            skip={page.skip}
            onPageChange={pageChange}
            className="no-margin-top"
          >
            <GridColumn
              field="name"
              title={locale[currentLocale].usersNetDevices.name}
              cell={StandartCell}
            />
            <GridColumn
              field="group"
              title={locale[currentLocale].usersNetDevices.group}
              cell={StandartCell}
            />
            <GridColumn
              field="lastLoggedIn"
              title={locale[currentLocale].usersNetDevices.lastLoggedIn}
              cell={NormalDateCell}
            />
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </WrapperMenu>
  );
}
