/* eslint-disable react/no-array-index-key */
import { NavLink } from 'react-router-dom';
import type { OSLinuxPlatforms } from '../../../../types/__generated/on-premise-solution/api/oSPlatformsResponse.v1';
import { IMG_SRC_VALUES } from '../../../../utils/helpers/constants';
import { getMainPartOfOsName } from '../../../../utils/helpers/inventorization-helper';
import locale from '../../../../utils/i18n/dashboardLocale/dashboardLayout.json';
import styles from '../Dashboard.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function OsLinux({ data }: { data: OSLinuxPlatforms | undefined }): React.ReactElement {
  return (
    <table className="horizontal">
      <thead>
        <tr>
          <th>{locale[currentLocale].linuxVersion}</th>
          <th className={styles.total_assets}>{locale[currentLocale].numberAssetsTable}</th>
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map((osLinux, i) => {
            return (
              <tr key={i}>
                <td>
                  <div className="cell_os">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/os_logo/${
                        IMG_SRC_VALUES[getMainPartOfOsName(osLinux.data.osPlatform)]
                      }`}
                      alt={osLinux.label}
                    />
                    <NavLink
                      className="dashboard__link"
                      to={`/lk/assets/hosts?skip=0&limit=15&sortOrder=desc&latestInventorization.os.name[%24regex]=${osLinux.data.osName}&latestInventorization.os.version=${osLinux.data.osVersion}`}
                    >
                      {osLinux.label}
                    </NavLink>
                  </div>
                </td>
                <td className="number">
                  <NavLink
                    className="dashboard__link"
                    key={i}
                    to={`/lk/assets/hosts?skip=0&limit=15&sortOrder=desc&latestInventorization.os.name[%24regex]=${osLinux.data.osName}&latestInventorization.os.version=${osLinux.data.osVersion}`}
                  >
                    {osLinux.value}
                  </NavLink>
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
}
