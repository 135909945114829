/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-nested-ternary */
import type { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { memo, useCallback } from 'react';
import { useHostModalContext } from '../../../../../../hooks/useHostModalContext';

function CellSelectKbsWl(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { kbsInWl, setKbsInWl } = useHostModalContext();

  const addKb = useCallback(
    (id: string, title: string): void => {
      if (kbsInWl && kbsInWl.length > 0) {
        const index = kbsInWl?.findIndex((k) => k.id === id && k.title === title);
        if (index !== -1) {
          const cutSelectedKbs = kbsInWl.filter((kb) => kb.id !== id || kb.title !== title);
          setKbsInWl([...cutSelectedKbs]);
        } else {
          setKbsInWl([...kbsInWl, { id, title }]);
        }
      } else {
        setKbsInWl([{ id, title }]);
      }
    },
    [kbsInWl, setKbsInWl],
  );

  return (
    <td>
      {kbsInWl?.find((k) => k === dataItem.id) ? (
        <Checkbox
          checked={!!kbsInWl?.find((k) => k.id === dataItem.id && k.title === dataItem.title)}
          onClick={(): void => addKb(dataItem.id, dataItem.title)}
        />
      ) : (
        <Checkbox
          checked={!!kbsInWl?.find((k) => k.id === dataItem.id && k.title === dataItem.title)}
          onClick={(): void => addKb(dataItem.id, dataItem.title)}
        />
      )}
    </td>
  );
}

export default memo(CellSelectKbsWl);
