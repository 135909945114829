/* eslint-disable no-nested-ternary */
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import type { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { useCredentialContext } from '../useCredentialContext';
import { useAccountModalContext } from '../useAccountModalContext';
import locale from '../../utils/i18n/credentials/credentials.json';
import type { VaultCreationRequest } from '../../types/__generated/on-premise-solution/api/vaultCreationRequest.v1';
import type { VaultCreationResponse } from '../../types/__generated/on-premise-solution/api/vaultCreationResponse.v1';
import type { ApiError } from '../../types/__generated/on-premise-solution/api/apiError.v1';
import { createVault } from '../../services/credential-service';
import { getAccountId, getUserData } from '../../services/local-storage-service';
import { useAccounts } from './useAccounts';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export interface IUseVault {
  exitStorageForm: () => void;
  onSubmitAddStorage: () => void;
  changeDropDownType: (event: DropDownListChangeEvent) => void;
  changeStorageName: (event: DropDownListChangeEvent) => void;
  changeCredType: (event: DropDownListChangeEvent) => void;
  changeSnmpVersion: (event: DropDownListChangeEvent) => void;
}
export function useVault(): IUseVault {
  const currentUser = getUserData();

  const currentAccountId = getAccountId();

  const {
    setAddCredential,
    setCredentialVault,
    setUsername,
    setPassword,
    setPassphrase,
    setPasswordConfirm,
    setAddCredentialStorage,
    setNameVault,
    setTypeVault,
    setEndpoint,
    setEnginePath,
    nameVault,
    typeValuesForSelect,
    endpoint,
    enginePath,
    credentialVault,
    descriptionVault,
    setNameCredStorage,
    setTypeCred,
    setErrorMutation,
    setVersion,
  } = useCredentialContext();

  const { accountId, setAccountId } = useAccountModalContext();

  const { destructuringAccounts } = useAccounts();

  const destructuredAccounts = destructuringAccounts();

  const queryClient = useQueryClient();

  const changeDropDownType = useCallback(
    (event: DropDownListChangeEvent): void => {
      setTypeVault(event.target.value);
    },
    [setTypeVault],
  );

  const changeStorageName = useCallback(
    (event: DropDownListChangeEvent): void => {
      setNameCredStorage({ id: event.target.value.id, name: event.target.value.name });
    },
    [setNameCredStorage],
  );

  const changeCredType = useCallback(
    (event: DropDownListChangeEvent): void => {
      setTypeCred(event.target.value);
      if (event.target.value === 'hashicorp_vault_token') {
        setUsername('');
        setPassword('');
        setPasswordConfirm('');
        setPassphrase('');
      }
    },
    [setPassphrase, setPassword, setPasswordConfirm, setTypeCred, setUsername],
  );

  const changeSnmpVersion = useCallback(
    (event: DropDownListChangeEvent): void => {
      setVersion(event.target.value);
    },
    [setVersion],
  );

  const exitStorageForm = useCallback((): void => {
    setAddCredentialStorage(false);
    setCredentialVault(undefined);
    setNameVault('');
    setEndpoint('');
    setEnginePath('');
    setAccountId({
      id: '000',
      name: locale[currentLocale].form.placeholderAccountId,
    });
  }, [
    setAccountId,
    setAddCredentialStorage,
    setCredentialVault,
    setEndpoint,
    setEnginePath,
    setNameVault,
  ]);

  const mutationNewVault = useMutation<VaultCreationResponse, ApiError, VaultCreationRequest>(
    (payload) => createVault(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('vaultslList');
        setAddCredential(false);
        exitStorageForm();
      },
      onError: (resp) => {
        setErrorMutation(resp.message);
      },
    },
  );

  const onSubmitAddStorage = useCallback((): void => {
    const onSubmitData = {
      accountId:
        currentUser?.role === 'account_admin'
          ? currentAccountId
          : destructuredAccounts &&
            Array.isArray(destructuredAccounts) &&
            destructuredAccounts.length === 1
          ? destructuredAccounts[0].id
          : accountId.id,
      name: nameVault,
      type: typeValuesForSelect[0],
      endpoint,
      engine: { type: 'kv-v1', path: enginePath || 'vio_onprem_credentials_storage' },
      auth: { type: 'credential', credentialId: credentialVault?.credentialId },
      description: descriptionVault || undefined,
    } as VaultCreationRequest;

    mutationNewVault.mutateAsync(onSubmitData);
  }, [
    accountId.id,
    credentialVault?.credentialId,
    currentAccountId,
    currentUser?.role,
    descriptionVault,
    destructuredAccounts,
    endpoint,
    enginePath,
    mutationNewVault,
    nameVault,
    typeValuesForSelect,
  ]);

  return {
    onSubmitAddStorage,
    exitStorageForm,
    changeDropDownType,
    changeStorageName,
    changeCredType,
    changeSnmpVersion,
  };
}
