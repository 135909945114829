/* eslint-disable react/jsx-no-useless-fragment */
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import type { InventorizationResponse } from '../../../../../../types/__generated/on-premise-solution/api/inventorizationResponse.v1';
import locale from '../../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { WrapperMenu } from '../WrapperMenu';
import { SizeCell } from '../../../../../common/cells/SizeCell';
import { StandartCell } from '../../../../../common/cells/StandartCell';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function DiskLogical({ data }: { data: InventorizationResponse }): React.ReactElement {
  return (
    <>
      {data.type === 'windows' && (
        <WrapperMenu title={locale[currentLocale].disks.titleLogicalDisk}>
          <Grid data={data.result.logical_drives} scrollable="none" className="no-margin-top">
            <GridColumn
              field="device_id"
              title={locale[currentLocale].disks.logicalDiskTable.deviceId}
              cell={StandartCell}
            />
            <GridColumn
              field="description"
              title={locale[currentLocale].disks.logicalDiskTable.description}
              cell={StandartCell}
            />
            <GridColumn
              field="file_system"
              title={locale[currentLocale].disks.logicalDiskTable.fs}
              cell={StandartCell}
            />
            <GridColumn
              field="size"
              title={locale[currentLocale].disks.logicalDiskTable.size}
              cell={SizeCell}
            />
            <GridColumn
              field="free_space"
              title={locale[currentLocale].disks.logicalDiskTable.freeSpace}
              cell={SizeCell}
            />
          </Grid>
        </WrapperMenu>
      )}
    </>
  );
}
