import { useMemo } from 'react';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { WrapperMenu } from '../../../Task/taskIDetails/iDetailsComponents/WrapperMenu';
import {
  createFromListInvData,
  IDiffInvElementInfo,
  type DiffFilterTypes,
  prepareDiffObjectData,
  defineWrapperStatuses,
} from '../../../../../utils/helpers/difference-modal-helper';
import type { InventorizationDifferenceLinux } from '../../../../../types/__generated/on-premise-solution/api/inventorizationDifference.v1';
import { DiffContainerWrapper } from './DiffContainerWrapper';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function DiffContainers({
  data,
  filter,
}: {
  data: InventorizationDifferenceLinux;
  filter: DiffFilterTypes;
}): React.ReactElement {
  const containerWrapperStatuses: string[] = [];
  const containersData = useMemo(() => {
    const result: {
      infoData: any;
      fileMountsData: any[];
      portBindingsData: any[];
      name: string | null;
      newName: string | null;
    }[] = [];

    data.containers?.forEach((container) => {
      let containerName = null;
      let newContainerName = null;
      const infoData = prepareDiffObjectData(
        {
          closed: container.containerInfo?.old,
          noChanges: container.containerInfo?.noChanges,
          updated: container.containerInfo?.new,
        },
        filter,
      );
      const fileMountsData = createFromListInvData(
        container.fileMounts as unknown as IDiffInvElementInfo[],
        filter,
      );
      const portBindingsData = createFromListInvData(
        container.portBindings as unknown as IDiffInvElementInfo[],
        filter,
      );

      if (container.containerInfo?.noChanges?.name)
        containerName = container.containerInfo?.noChanges?.name as string | null;
      else if (container.containerInfo?.old?.name)
        containerName = container.containerInfo?.old?.name as string | null;
      else if (container.containerInfo?.new?.name)
        containerName = container.containerInfo?.new?.name as string | null;

      if (
        container.containerInfo?.new?.name &&
        container.containerInfo?.new?.name !== containerName
      )
        newContainerName = container.containerInfo?.new?.name as string | null;

      if (
        Object.keys(infoData).length ||
        (fileMountsData && fileMountsData.length) ||
        (portBindingsData && portBindingsData.length)
      )
        result.push({
          infoData,
          fileMountsData,
          portBindingsData,
          name: containerName,
          newName: newContainerName,
        });

      containerWrapperStatuses.push(
        ...defineWrapperStatuses(container.containerInfo),
        ...defineWrapperStatuses(container.fileMounts),
        ...defineWrapperStatuses(container.portBindings),
      );
    });

    return result;
  }, [containerWrapperStatuses, data.containers, filter]);

  const isContainersData =
    containersData &&
    !!containersData?.filter(
      (container) =>
        (container.fileMountsData && container.fileMountsData.length) ||
        (container.infoData && Object.keys(container.infoData || {}).length) ||
        (container.portBindingsData && container.portBindingsData.length),
    ).length;

  return isContainersData ? (
    <WrapperMenu
      title={locale[currentLocale].containers.title}
      diffStatuses={filter === 'all' ? Array.from(new Set(containerWrapperStatuses)) : undefined}
    >
      {containersData.map((container) => (
        <DiffContainerWrapper
          container={container}
          assetType={data.type}
          needShowStatus={filter === 'all'}
        />
      ))}
    </WrapperMenu>
  ) : (
    <></>
  );
}
