/* eslint-disable no-nested-ternary */
import { Button } from '@progress/kendo-react-buttons';
import type { ReactNode } from 'react';
import { useState } from 'react';
import styles from './WrapperMenu.module.scss';

export function WrapperMenu({
  title,
  children,
  diffStatuses,
  className,
  goTo,
  onClick,
}: {
  title: string | null | undefined;
  children?: ReactNode;
  diffStatuses?: string[] | null;
  className?: string;
  goTo?: () => void;
  onClick?: () => void;
}): React.ReactElement {
  const [isOpened, setOpened] = useState(false);

  const changeOpened = (): void => {
    setOpened((prev) => !prev);
    if (onClick) onClick();
  };

  const statusInfo = {
    left: null,
    rigth: null,
  };

  return (
    <>
      <div className="wrapper-menu">
        <div className="row">
          <Button
            className={isOpened ? 'active' : ''}
            icon={isOpened ? 'minus' : 'plus'}
            onClick={changeOpened}
          />
          <span
            onClick={changeOpened}
            className="k-button k-button-flat row-title"
            role="button"
            tabIndex={0}
            onKeyPress={changeOpened}
          >
            {diffStatuses && (
              <span className={styles.diffStatus}>
                {diffStatuses?.includes('closed') && <span className={styles.closed} />}
                {diffStatuses?.includes('noChanges') &&
                  (!diffStatuses?.includes('closed') || !diffStatuses?.includes('new')) && <span />}
                {diffStatuses?.includes('new') && <span className={styles.new} />}
                {/* <span className={diffStatuses?.includes('closed') ? styles.closed : undefined} />
                <span className={diffStatuses?.includes('new') ? styles.new : undefined} /> */}
              </span>
            )}
            {title}
          </span>
          {goTo && (
            <Button fillMode="flat" onClick={goTo}>
              <span className="k-icon k-i-redo" />
            </Button>
          )}
        </div>
        {isOpened && <div className="content visible">{children}</div>}
      </div>
      <div className="break" />
    </>
  );
}
