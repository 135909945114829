/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import type { GridHeaderCellProps } from '@progress/kendo-react-grid';
import styles from './VHeaderCell.module.scss';
import localeHostList from '../../utils/i18n/assetLocales/hostList.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHostList;

export function AgentStatusHeaderCell(props: GridHeaderCellProps): React.ReactElement {
  const { title, onClick, children } = props;

  return (
    <div onClick={onClick} className={`${styles.action_header_cell} vhead-cell k-cell-inner`}>
      {title}
      <span className={`${styles.types_info}`}>
        <div style={{ width: '400px' }} className={`${styles.types_info_content} k-link`}>
          <div className={styles.type_info}>
            {localeHostList[currentLocale].form.agentStatusHeaderTitle}
          </div>
          <table className={styles.info_table_agent}>
            <tr>
              <td className={styles.score_cell_agent}>
                <span style={{ backgroundColor: '#44c74f' }} />
              </td>
              <td>{localeHostList[currentLocale].form.agentStatusHeader.online}</td>
            </tr>
            <tr>
              <td className={styles.score_cell_agent}>
                <span style={{ backgroundColor: '#e97d7d' }} />
              </td>
              <td>{localeHostList[currentLocale].form.agentStatusHeader.offline}</td>
            </tr>
            <tr>
              <td className={styles.score_cell_agent}>
                <span style={{ backgroundColor: '#b5b0b0' }} />
              </td>
              <td>{localeHostList[currentLocale].form.agentStatusHeader.notActivated}</td>
            </tr>
            <tr>
              <td className={styles.score_cell_cred}>
                <span>ssh / winrm</span>
              </td>
              <td>{localeHostList[currentLocale].form.agentStatusHeader.agentStatusCred}</td>
            </tr>
          </table>
        </div>
        {children}
        <img
          src={`${process.env.PUBLIC_URL}/images/question.svg`}
          alt=""
          className="tooltip-question-images"
        />
      </span>
    </div>
  );
}
