/* eslint-disable @typescript-eslint/ban-types */
import type { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import type { SetStateAction } from 'react';
import type { FilterChangeEvent } from '@progress/kendo-react-data-tools';
import { FILTER_OPERATOR_LIST } from './helpers/constant-filter-operators';
import { regExpSpecSymbols } from './helpers/constants';
import type { IPageState } from './helpers/types';

export interface IFilterVal {
  [field: string]: {
    [operator: string]: string;
  };
}

export function handleFilteringTest(
  filterValue: CompositeFilterDescriptor | null,
  setFilterVal: (value: SetStateAction<IFilterVal[]>) => void,
): void {
  if (
    filterValue &&
    filterValue.filters[0] &&
    'value' in filterValue.filters[0] &&
    filterValue.filters[0].value
  ) {
    const filters = filterValue.filters as FilterDescriptor[];
    const value = filters.map((val) => {
      const keyVal = val.field as string;
      const operator = val.operator as string;
      // Исключаются  поля, содержащие дату, при условии, что в имени всегда есть At
      if (operator === 'doesnotcontain' && val.value && val.value.trimStart()) {
        return {
          [keyVal]: { $not: { $regex: val.value } },
        };
      }

      if (
        val.value &&
        typeof val.value !== 'object' &&
        keyVal.indexOf('At') === -1 &&
        val.value.trimStart()
      ) {
        // const checkedRegExpValue = val.value.replace(regExpSpecSymbols, 'invalidСharacter');
        const checkedRegExpValue = !val.value.search(regExpSpecSymbols)
          ? 'invalidСharacter'
          : val.value;

        return {
          [keyVal]: {
            [FILTER_OPERATOR_LIST[operator]]: checkedRegExpValue,
          },
        };
      }

      if (val.value && typeof val.value === 'object') {
        return {
          [keyVal]: {
            [FILTER_OPERATOR_LIST[operator]]: val.value,
          },
        };
      }

      return {};
    }) as IFilterVal[];
    console.log('VALUE: ', value);
    if (value) setFilterVal(value);
  } else {
    setFilterVal([]);
  }
}

export const setDataInFilter = (
  event: FilterChangeEvent,
  setFilter: (value: React.SetStateAction<CompositeFilterDescriptor>) => void,
  timer: React.MutableRefObject<NodeJS.Timeout | null>,
  setPage: (value: React.SetStateAction<IPageState>) => void,
  setFilterVal: React.Dispatch<React.SetStateAction<IFilterVal[]>>,
  page?: IPageState,
): void => {
  const filters = event.filter.filters.map((v) => ({
    ...v,
    value: 'value' in v && v.value && v.value,
  }));

  const eventValueRes = { ...event.filter, filters };

  setFilter(eventValueRes);

  if (timer.current) clearTimeout(timer.current);

  timer.current = setTimeout(() => {
    handleFilteringTest(event.filter, setFilterVal);
  }, 900);

  if (page) {
    setPage({ skip: 0, take: page.take });
  }
};
