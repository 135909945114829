/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import type { GridCellProps } from '@progress/kendo-react-grid';

export function DeviceCapabilityCell(props: GridCellProps): React.ReactElement {
  const { dataItem, field, className } = props;

  return field !== undefined && Array.isArray(dataItem[field]) && dataItem[field][0] ? (
    <td className={className || ''}>{dataItem[field][0]}</td>
  ) : (
    <td>-</td>
  );
}
