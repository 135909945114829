import type { ChangeEvent } from 'react';
import { useHostModalContext } from '../useHostModalContext';
import { textValidator } from '../../utils/helpers/validation';
import localeErrorMessage from '../../utils/i18n/commonLocales/errorMessage.json';
import locale from '../../utils/i18n/credentials/credentials.json';
import { useCommonContext } from '../useCommonContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeErrorMessage;

interface IUseCheck {
  checkLength: (value: string) => string;
  validateDropdownlist: (value: any) => string;
  ipValidator: (value: string) => void;
  ipRangesValidator: (value: string) => void;
  checkGreaterThanZero: (e: ChangeEvent<HTMLInputElement>, setFn: (v: number) => void) => void;
  checkNameInIp: (value: string) => void;
}
export function useCheck(): IUseCheck {
  const { setResultCheckIp } = useCommonContext();

  const { setAssetName } = useHostModalContext();

  const checkLength = (value: string): string => {
    const result = textValidator(
      value,
      localeErrorMessage[currentLocale].form.fillOut,
      localeErrorMessage[currentLocale].form.minLength,
      localeErrorMessage[currentLocale].form.maxLength,
    );

    return result;
  };

  const validateDropdownlist = (value: any): string => {
    if (value && value !== 'value') return '';

    return locale[currentLocale].form.invalidData;
  };

  const ipValidator = (value: string): void => {
    if (value.trim().length === 0) {
      setResultCheckIp(localeErrorMessage[currentLocale].form.fillOut);
    } else {
      const ipRegex = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/;
      if (ipRegex.test(value)) {
        setResultCheckIp(null);
      } else {
        setResultCheckIp(locale[currentLocale].ipError);
      }
    }
  };

  const checkNameInIp = (value: string): void => {
    if (value.trim().length === 0) {
      setResultCheckIp(localeErrorMessage[currentLocale].form.fillOut);
    } else {
      const ipRegex = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/;

      // const domainRegex = /^(?!:\/\/)([a-zA-Z0-9]+\.)?[a-zA-Z0-9][a-zA-Z0-9-]+\.[a-zA-Z]{2,6}?$/i;
      const domainRegex = /^(?!:\/\/)(?=.{1,255}$)((.{1,63}\.){1,127}(?![0-9]*$)[a-z0-9-]+\.?)$/;

      if (ipRegex.test(value.trimEnd())) {
        setResultCheckIp(null);
        setAssetName(false);
      } else {
        setAssetName(true);
        if (!domainRegex.test(value))
          setResultCheckIp(localeErrorMessage[currentLocale].form.useName);
      }
    }
  };

  const ipRangesValidator = (value: string): void => {
    const ipRegex =
      /^(([1-9]{0,1}[0-9]{0,2}|2[0-4][0-9]|25[0-5])\.){3}([1-9]{0,1}[0-9]{0,2}|2[0-4][0-9]|25[0-5])\/([1-2][0-9]|3[0-2])$/;
    if (ipRegex.test(value)) {
      setResultCheckIp(null);
    } else {
      setResultCheckIp(locale[currentLocale].ipRangesError);
    }
  };

  const checkGreaterThanZero = (
    e: ChangeEvent<HTMLInputElement>,
    setFn: (v: number) => void,
  ): void => {
    const val = Number(e.target.value);
    if (val > 0) {
      setFn(val);
    }
  };

  return {
    checkLength,
    validateDropdownlist,
    ipValidator,
    ipRangesValidator,
    checkGreaterThanZero,
    checkNameInIp,
  };
}
