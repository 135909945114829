import { memo } from 'react';
import cronstrue from 'cronstrue/i18n';
import { ErrorBoundary } from 'react-error-boundary';
import { useTaskAddContext } from '../../../../hooks/useTaskAddContext';
import { BoundaryErrorComponent } from '../../../common/BoundaryErrorComponent';
import { Input } from '../../../common/baseElements/Input';
import { CronCalculator } from '../../../common/cronCalculator/CronCalculator';

function CronTask({ name }: { name: string }): React.ReactElement {
  const { cronInputValue, setCronInputValue, changeCronValue, errorCron, correctCron } =
    useTaskAddContext();

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      {/* <Input
        name={name}
        id="name"
        onChange={(e): void => {
          setCronInputValue(e.target.value);
        }}
        onBlur={changeCronValue}
        value={cronInputValue}
        className="k-input k-input-md k-rounded-md k-input-solid k-state-invalid"
        type="text"
      /> */}
      {/* <div
        style={{
          position: 'absolute',
          maxWidth: '99%',
          maxHeight: '99%',
          width: 'fit-content',
          height: 'fit-content',
          background: 'white',
          border: '1px solid rgba(0, 0, 0, 0.08)',
          zIndex: '1',
          boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.03), 0 4px 5px 0 rgba(0 , 0, 0, 0.04)',
          borderRadius: '5px',
          padding: '8px',
        }}
      > */}
      <CronCalculator />
      {/* </div> */}
      {/* {errorCron.length < 1 && (
        <p className="newtask__description">{cronstrue.toString(correctCron, { locale: 'ru' })}</p>
      )}
      {errorCron.length > 0 && <div className="newtask__description">{errorCron}</div>} */}
    </ErrorBoundary>
  );
}

export default memo(CronTask);
