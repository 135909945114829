import type { GridCellProps } from '@progress/kendo-react-grid';
import { NavLink } from 'react-router-dom';
import type { ContainerRegistryDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/containerRegistryDashboardResponse.v1';

export function RegistryName(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;
  const { name, registryId }: ContainerRegistryDashboardResponse = dataItem;

  return (
    <td>
      {name ? (
        <NavLink to={`/lk/assets/registries/${registryId}/repositories`}>{name}</NavLink>
      ) : (
        '-'
      )}
    </td>
  );
}
