import type { GridCellProps } from '@progress/kendo-react-grid';
import { useEffect } from 'react';
import { Button } from '../../../common/baseElements/Button';
import type { IPkgVulns } from '../../../../utils/helpers/types';
import { useAuditModalContext } from '../../../../hooks/useAuditModalContext';
import { handleVulnModal } from '../../../../utils/helpers/audit-helpers';

export function CellBulletinId(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  // const { id }: IPkgVulns = dataItem;

  const { cveName, setCveName, setOpen } = useAuditModalContext();

  let delayOpenModal: NodeJS.Timeout;

  const openModal = (): void => {
    // handleVulnModal(id, setOpen, setCveName);
    if (cveName) {
      // setCveName(null);
      delayOpenModal = setTimeout(() => {
        setCveName(null);
        handleVulnModal(dataItem.bulletinId, setOpen, setCveName);
      }, 100);
    } else {
      handleVulnModal(dataItem.bulletinId, setOpen, setCveName);
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(delayOpenModal);
    };
  }, []);

  return (
    <td className="audit__vulns">
      <Button fill="modal" onClick={openModal}>
        {dataItem.bulletinId}
      </Button>
    </td>
  );
}
