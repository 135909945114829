import qs from 'qs';
import type { SortDescriptor } from '@progress/kendo-data-query';
import { dashboardInstance } from '../lib/axios/hooks/useDashboardAxios';
import type { BulletinsMetaWidgetResponse } from '../types/__generated/on-premise-solution/api/bulletinsMetaWidgetResponse.v1';
import type { BulletinCountGroupedByTypeWidget } from '../types/__generated/on-premise-solution/api/bulletinCountGroupedByTypeWidget.v1';
import type { BulletinCountPerMonthWidget } from '../types/__generated/on-premise-solution/api/bulletinCountPerMonthWidget.v1';
import type { IPageState } from '../utils/helpers/types';
import type { IFilterVal } from '../utils/filtering-in-table-test';
import type { DashboardBulletinsResponse } from '../types/__generated/on-premise-solution/api/dashboardBulletinsResponse.v1';
import { prepareFilterVal } from './filterVal-for-service';
import { commonInstance } from '../lib/axios/hooks/useCommonAxios';
import { history } from '../utils/history';
import { urlFilterString } from '../utils/helpers/url-filter-string-helper';

export async function getMetaWidgets(): Promise<BulletinsMetaWidgetResponse> {
  const result = await dashboardInstance.get<BulletinsMetaWidgetResponse>(
    '/widgets/bulletins/meta',
  );

  return result.data;
}

export async function getCountByTypeWidget(): Promise<BulletinCountGroupedByTypeWidget> {
  const result = await dashboardInstance.get<BulletinCountGroupedByTypeWidget>(
    '/widgets/bulletins/bulletin-count',
  );

  return result.data;
}

export async function getCountPerMonthWidget(): Promise<BulletinCountPerMonthWidget> {
  const result = await dashboardInstance.get<BulletinCountPerMonthWidget>(
    '/widgets/bulletins/bulletin-month',
  );

  return result.data;
}

export async function getBulletinsPost(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
): Promise<DashboardBulletinsResponse> {
  // const queryString = urlFilterString(filterVal);

  // const paramsData = {
  //   skip: page.skip,
  //   limit: page.take,
  //   sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
  //   sortOrder: sort[0].dir,
  //   str: queryString,
  // };

  const result = await commonInstance.get<DashboardBulletinsResponse>('/v1/info/bulletins', {
    params: {
      skip: page.skip,
      limit: page.take,
      sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
      sortOrder: sort[0].dir,
      ...prepareFilterVal(filterVal),
    },

    // params: paramsData,

    paramsSerializer: (params) => {
      const stringifiedParams = qs.stringify(params, { encode: false });
      history.replace({
        pathname: `/lk/security/vdb?${stringifiedParams}`,
      });

      return stringifiedParams;
    },
  });

  //
  // const params = {
  //   skip: page.skip,
  //   limit: page.take,
  //   sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
  //   sortOrder: sort[0].dir,
  //   ...prepareFilterVal(filterVal),
  // };

  // const stringifiedParams = qs.stringify(params, { encode: false });

  // const result = await commonInstance.post<DashboardBulletinsResponse>(
  //   `/v1/info/bulletins?${stringifiedParams}`,
  //   {
  //     // params,
  //     paramsSerializer: (p: any) => {
  //       const sp = qs.stringify(p, { encode: false });
  //       history.replace({
  //         pathname: `/lk/security/vdb?${stringifiedParams}`,
  //       });

  //       return sp;
  //     },
  //   },
  // );
  //

  return result.data;
}
