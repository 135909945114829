/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import type { GridCellProps } from '@progress/kendo-react-grid';
import { NavLink } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import { useAuditModalContext } from '../../../../../hooks/useAuditModalContext';
import localeHost from '../../../../../utils/i18n/assetLocales/hostList.json';
import localeHostDescription from '../../../../../utils/i18n/assetLocales/hostDescription.json';
import { useHostModalContext } from '../../../../../hooks/useHostModalContext';
import { CommonTooltip } from '../../../../common/CommonTooltip';
import styles from '../../hostComponents/HostDescription.module.scss';
import { TagList } from '../../hostComponents/TagList';
import { useAssetCreatePropContext } from '../../../../../hooks/useAssetCreatePropContext';
import type { AssetsHostItemDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/assetsHostsDashboardResponse.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHost;

export function DomainName(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { latestInventorization, assetId }: AssetsHostItemDashboardResponse = dataItem;

  const { setCveName, setPkgName } = useAuditModalContext();

  const closeVulnModal = (): void => {
    setCveName(null);
    setPkgName(null);
  };

  const { setHostDetailById, tagListInHostList, setTagListInHostList } = useHostModalContext();

  const { closeTagWindow } = useAssetCreatePropContext();

  const handleKeydown = useCallback((e: KeyboardEvent): void => {
    if (e.key === 'Escape') {
      setTagListInHostList('');
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);
  }, [handleKeydown]);

  useEffect(() => {
    document.addEventListener('click', closeTagWindow);

    return () => {
      document.removeEventListener('click', closeTagWindow);
      document.removeEventListener('keydown', handleKeydown);
      setTagListInHostList('');
    };
  }, []);

  // const handleTagListInHostList = (): void => {
  //   if (tagListInHostList && tagListInHostList !== '') {
  //     setTagListInHostList('');
  //   } else {
  //     setTagListInHostList(assetId);
  //   }
  // };

  return (
    <td onMouseLeave={(): void => setTagListInHostList('')}>
      {latestInventorization && latestInventorization.hostname ? (
        <div className="domainname-wrap">
          <NavLink to={`/lk/assets/hosts/${assetId}`} onClick={closeVulnModal}>
            {latestInventorization.hostname}
          </NavLink>
          {dataItem.latestAudit && dataItem.latestAudit.isRebootPending && (
            <CommonTooltip time={100} icon="reboot">
              {localeHostDescription[currentLocale].rebootTooltip}
            </CommonTooltip>
          )}
          <div className={styles.tagBlockHostList}>
            <TagList
              assetId={assetId}
              tags={dataItem.tags}
              tagIds={dataItem.tagIds}
              componentName="HostList"
              domainNameLength={latestInventorization.hostname.length}
            />
            {dataItem.tags && dataItem.tags.length >= 2 && (
              <div
                className={styles.tagsCount}
                onMouseOver={(): void => setTagListInHostList(assetId)}
              >
                <span>+ {dataItem.tags.length - 1}</span>
              </div>
              // <div
              //   onMouseOver={(): void => setTagListInHostList(assetId)}
              //   className="tag-image-wrap"
              // >
              //   <img src={`${process.env.PUBLIC_URL}/images/tagList.svg`} alt="tagList" />
              // </div>
            )}
          </div>
          {tagListInHostList && tagListInHostList === assetId && (
            <div
              className={styles.allTagBlockHostListWrap}
              onMouseLeave={(): void => setTagListInHostList('')}
            >
              <div className={styles.allTagBlockHostList}>
                <TagList
                  assetId={assetId}
                  tags={dataItem.tags}
                  tagIds={dataItem.tagIds}
                  componentName="HostListAllTag"
                />
              </div>
            </div>
          )}
        </div>
      ) : dataItem?.agentValue?.status === 'online' && latestInventorization === null ? (
        <div className="domainname-wrap">
          <NavLink to={`/lk/assets/hosts/${assetId}`} onClick={closeVulnModal}>
            <span
              onClick={(): void => setHostDetailById(assetId)}
              className="k-button"
              style={{ borderBottom: '1px black dashed' }}
            >
              <span className="k-icon k-i-warning" style={{ color: 'red' }} />
              {localeHost[currentLocale].waitingDetails}
            </span>
          </NavLink>
          <CommonTooltip time={100} icon="reboot">
            {localeHostDescription[currentLocale].rebootTooltip}
          </CommonTooltip>
        </div>
      ) : (
        <NavLink to={`/lk/assets/hosts/${assetId}`} onClick={closeVulnModal}>
          <span>{localeHost[currentLocale].form.waitingForData}</span>
        </NavLink>
      )}
    </td>
  );
}
