import type { GridCellProps } from '@progress/kendo-react-grid';
import localeAuditTask from '../../../../../utils/i18n/taskLocale/auditTask.json';
import type { IStatusValues } from '../../../../../utils/helpers/types';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;
export function StatusCell(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const statusVal:
    | 'alreadyExistKb'
    | 'deletedKb'
    | 'installedKb'
    | 'notFoundKb'
    | 'notInstalledKb' = dataItem.status;

  return (
    <td>
      {statusVal
        ? (localeAuditTask[currentLocale].statusValues[statusVal] as keyof IStatusValues)
        : '-'}
    </td>
  );
}
