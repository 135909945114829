/* eslint-disable no-nested-ternary */
import { ReactElement, useMemo } from 'react';
import type {
  InventorizationDifferenceLinux,
  InventorizationDifferenceWindows,
} from '../../../../../types/__generated/on-premise-solution/api/inventorizationDifference.v1';
import {
  prepareDiffObjectData,
  type DiffFilterTypes,
  defineWrapperStatuses,
} from '../../../../../utils/helpers/difference-modal-helper';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { WrapperMenu } from '../../../Task/taskIDetails/iDetailsComponents/WrapperMenu';
import styles from '../../forms/AddAssetForm.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

function DiffMemoryDevice({
  device,
  deviceType,
  tdClassName,
  needShowStatus,
}: {
  device:
    | Exclude<
        Exclude<InventorizationDifferenceLinux['memory']['devices'], null | undefined>[number],
        null
      >['new']
    | Exclude<
        Exclude<InventorizationDifferenceWindows['memory']['devices'], null | undefined>[number],
        null
      >['new']
    | undefined;
  deviceType: 'linux' | 'windows';
  tdClassName?: string;
  needShowStatus: boolean;
}): ReactElement {
  const wrapperStatuses = [];
  if (needShowStatus && tdClassName === styles.closed) wrapperStatuses.push('closed');
  else if (needShowStatus && tdClassName === styles.updated) wrapperStatuses.push('new');
  if (!tdClassName || (tdClassName !== styles.closed && tdClassName !== styles.updated))
    wrapperStatuses.push('noChanges');

  return (
    <WrapperMenu
      title={device?.device_locator}
      diffStatuses={needShowStatus ? wrapperStatuses : undefined}
    >
      <table className="column">
        <tr>
          <td className={tdClassName}>
            <span>{locale[currentLocale].devices.size}</span>
          </td>
          <td className={tdClassName}>
            {device && 'size' in device && device.size ? (
              <span>
                {device.size} {locale[currentLocale].sizeMb}
              </span>
            ) : (
              <span>-</span>
            )}
          </td>
        </tr>
        <tr>
          <td className={tdClassName}>
            <span>{locale[currentLocale].devices.formFactor}</span>
          </td>
          <td className={tdClassName}>
            {device && 'form_factor' in device && <span>{device.form_factor || '-'}</span>}
          </td>
        </tr>
        <tr>
          <td className={tdClassName}>
            <span>{locale[currentLocale].devices.memoryType}</span>
          </td>
          <td className={tdClassName}>
            {device && 'memory_type' in device && <span>{device.memory_type || '-'}</span>}
          </td>
        </tr>
        <tr>
          <td className={tdClassName}>
            <span>{locale[currentLocale].devices.bankLocator}</span>
          </td>
          <td className={tdClassName}>
            {device && 'bank_locator' in device && <span>{device.bank_locator || '-'}</span>}
          </td>
        </tr>
      </table>
      <table className="column">
        <tr>
          <td className={tdClassName}>
            <span>{locale[currentLocale].devices.manufacturer}</span>
          </td>
          <td className={tdClassName}>
            {device && 'manufacturer' in device && <span>{device.manufacturer || '-'}</span>}
          </td>
        </tr>
        <tr>
          <td className={tdClassName}>
            <span>{locale[currentLocale].devices.part_number}</span>
          </td>
          <td className={tdClassName}>
            {device && 'part_number' in device && <span>{device.part_number || '-'}</span>}
          </td>
        </tr>
        <tr>
          <td className={tdClassName}>
            <span>{locale[currentLocale].devices.serialNumber}</span>
          </td>
          <td className={tdClassName}>
            {device && 'serial_number' in device && <span>{device.serial_number || '-'}</span>}
          </td>
        </tr>
        <tr>
          <td className={tdClassName}>
            <span>{locale[currentLocale].devices.assetTag}</span>
          </td>
          <td className={tdClassName}>
            {device && 'asset_tag' in device && <span>{device.asset_tag || '-'}</span>}
          </td>
        </tr>
      </table>
      <table className="column">
        <tr>
          <td className={tdClassName}>
            <span>{locale[currentLocale].devices.dataWidth}</span>
          </td>
          <td className={tdClassName}>
            {device && 'data_width' in device && device.data_width ? (
              <span>{device.data_width}</span>
            ) : (
              <span>-</span>
            )}
          </td>
        </tr>
        <tr>
          <td className={tdClassName}>
            <span>{locale[currentLocale].devices.totalWidth}</span>
          </td>
          <td className={tdClassName}>
            {device && 'total_width' in device && device.total_width ? (
              <span>{device.total_width}</span>
            ) : (
              <span>-</span>
            )}
          </td>
        </tr>
        <tr>
          <td className={tdClassName}>
            <span>{locale[currentLocale].devices.maxSpeed}</span>
          </td>
          <td className={tdClassName}>
            {device && 'max_speed' in device && device.max_speed ? (
              <span>{device.max_speed}</span>
            ) : (
              <span>-</span>
            )}
          </td>
        </tr>
        <tr>
          <td className={tdClassName}>
            <span>{locale[currentLocale].devices.configuredClockSpeed}</span>
          </td>
          <td className={tdClassName}>
            {device && 'configured_clock_speed' in device && device.configured_clock_speed ? (
              <span>{device.configured_clock_speed}</span>
            ) : (
              <span>-</span>
            )}
          </td>
        </tr>
      </table>
      <table className="column">
        <tr>
          {deviceType === 'linux' && (
            <>
              <td className={tdClassName}>
                <span>{locale[currentLocale].devices.handle}</span>
              </td>
              <td className={tdClassName}>
                {device && 'handle' in device && typeof device.handle === 'string' && (
                  <span>{device.handle}</span>
                )}
              </td>
            </>
          )}
        </tr>
        <tr>
          {deviceType === 'linux' && (
            <>
              <td className={tdClassName}>
                <span>{locale[currentLocale].devices.handleArray}</span>
              </td>
              <td className={tdClassName}>
                {device && 'array_handle' in device && typeof device.array_handle === 'string' && (
                  <span>{device.array_handle}</span>
                )}
              </td>
            </>
          )}
        </tr>
        <tr>
          <td className={tdClassName}>
            <span>{locale[currentLocale].devices.memoryTypeDetails}</span>
          </td>
          <td className={tdClassName}>
            {device && 'memory_type_details' in device && <span>{device.memory_type_details}</span>}
          </td>
        </tr>
      </table>
    </WrapperMenu>
  );
}

export function DiffMemory({
  data,
  filter,
}: {
  data: InventorizationDifferenceLinux | InventorizationDifferenceWindows;
  filter: DiffFilterTypes;
}): React.ReactElement {
  const memoryInfoData = useMemo(
    () => prepareDiffObjectData(data.memory.info, filter),
    [data.memory.info, filter],
  );

  const wrapperStatuses = defineWrapperStatuses(data.memory.info);
  if (data.memory.devices) wrapperStatuses.push(...defineWrapperStatuses(data.memory.devices));

  const memoryDevicesInfoData = useMemo(() => {
    const result: {
      all: any[];
      noChanges: any[];
      installed: any[];
      removed: any[];
      changed: any[];
      updated: null;
    } = {
      all: [],
      noChanges: [],
      installed: [],
      removed: [],
      changed: [],
      updated: null,
    };

    data.memory.devices?.forEach((mDevice) => {
      if (mDevice?.noChanges) {
        result.all.push(mDevice.noChanges);
        result.noChanges.push(mDevice.noChanges);
      } else if (mDevice?.old) {
        result.all.push({ ...mDevice.old, className: styles.closed });
        if (mDevice?.new)
          result.changed.push(
            { ...mDevice.old, className: styles.closed },
            { ...mDevice.new, className: styles.updated },
          );
        else result.removed.push({ ...mDevice.old, className: styles.closed });
      }
      if (mDevice?.new) {
        result.all.push({ ...mDevice.new, className: styles.updated });
        if (!mDevice.old) result.installed.push({ ...mDevice.new, className: styles.updated });
      }
    });

    return result;
  }, [data.memory.devices, filter]);

  const isShow =
    Object.keys(memoryInfoData || {}).length ||
    (memoryDevicesInfoData[filter] && memoryDevicesInfoData[filter]?.length);

  return isShow ? (
    <WrapperMenu
      title={locale[currentLocale].memory.title}
      diffStatuses={filter === 'all' ? wrapperStatuses : undefined}
    >
      {Object.keys(memoryInfoData || {}).length ? (
        <WrapperMenu
          title={locale[currentLocale].memory.titleInfo}
          diffStatuses={filter === 'all' ? defineWrapperStatuses(data.memory.info) : undefined}
        >
          {('total' in memoryInfoData ||
            'free' in memoryInfoData ||
            'buffers' in memoryInfoData) && (
            <table className="column">
              {'total' in memoryInfoData &&
                memoryInfoData.total.map((memoryTotal) => (
                  <tr>
                    <td className={memoryTotal.className}>{locale[currentLocale].memory.total}</td>
                    <td className={memoryTotal.className}>
                      {Math.round(Number(memoryTotal.value || 0) / 1024 / 1024)}{' '}
                      {locale[currentLocale].sizeMb}
                    </td>
                  </tr>
                ))}
              {'free' in memoryInfoData &&
                memoryInfoData.free.map((memoryFree) => (
                  <tr>
                    <td className={memoryFree.className}>{locale[currentLocale].memory.free}</td>
                    <td className={memoryFree.className}>
                      {Math.round(Number(memoryFree.value || 0) / 1024 / 1024)}{' '}
                      {locale[currentLocale].sizeMb}
                    </td>
                  </tr>
                ))}
              {'buffers' in memoryInfoData &&
                memoryInfoData.buffers.map((memoryBuffers) => (
                  <tr>
                    <td className={memoryBuffers.className}>
                      {locale[currentLocale].memory.buffers}
                    </td>
                    <td className={memoryBuffers.className}>
                      {Math.round(Number(memoryBuffers.value || 0) / 1024 / 1024)}{' '}
                      {locale[currentLocale].sizeMb}
                    </td>
                  </tr>
                ))}
            </table>
          )}
          {('cached' in memoryInfoData ||
            'active' in memoryInfoData ||
            'inactive' in memoryInfoData) && (
            <table className="column">
              {'cached' in memoryInfoData &&
                memoryInfoData.cached.map((memoryCached) => (
                  <tr>
                    <td className={memoryCached.className}>
                      {locale[currentLocale].memory.cached}
                    </td>
                    <td className={memoryCached.className}>
                      {Math.round(Number(memoryCached.value || 0) / 1024 / 1024)}{' '}
                      {locale[currentLocale].sizeMb}
                    </td>
                  </tr>
                ))}
              {'active' in memoryInfoData &&
                memoryInfoData.active.map((memoryActive) => (
                  <tr>
                    <td className={memoryActive.className}>
                      {locale[currentLocale].memory.active}
                    </td>
                    <td className={memoryActive.className}>
                      {Math.round(Number(memoryActive.value || 0) / 1024 / 1024)}{' '}
                      {locale[currentLocale].sizeMb}
                    </td>
                  </tr>
                ))}
              {'inactive' in memoryInfoData &&
                memoryInfoData.inactive.map((memoryInactive) => (
                  <tr>
                    <td className={memoryInactive.className}>
                      {locale[currentLocale].memory.inactive}
                    </td>
                    <td className={memoryInactive.className}>
                      {Math.round(Number(memoryInactive.value || 0) / 1024 / 1024)}{' '}
                      {locale[currentLocale].sizeMb}
                    </td>
                  </tr>
                ))}
            </table>
          )}
          {'swap' in memoryInfoData && (
            <table className="column">
              {memoryInfoData.swap.map((memorySwap) =>
                memorySwap.value &&
                'total' in memorySwap.value &&
                memorySwap.value.total != null ? (
                  <tr>
                    <td className={memorySwap.className}>
                      {locale[currentLocale].memory.swapTotal}
                    </td>
                    <td className={memorySwap.className}>
                      {Math.round(Number(memorySwap.value.total || 0) / 1024 / 1024)}{' '}
                      {locale[currentLocale].sizeMb}
                    </td>
                  </tr>
                ) : (
                  <></>
                ),
              )}
              {memoryInfoData.swap.map((memorySwap) =>
                memorySwap.value &&
                'cached' in memorySwap.value &&
                memorySwap.value.cached != null ? (
                  <tr>
                    <td className={memorySwap.className}>
                      {locale[currentLocale].memory.swapCached}
                    </td>
                    <td className={memorySwap.className}>
                      {Math.round(Number(memorySwap.value.cached || 0) / 1024 / 1024)}{' '}
                      {locale[currentLocale].sizeMb}
                    </td>
                  </tr>
                ) : (
                  <></>
                ),
              )}
              {memoryInfoData.swap.map((memorySwap) =>
                memorySwap.value && 'free' in memorySwap.value && memorySwap.value.free != null ? (
                  <tr>
                    <td className={memorySwap.className}>
                      {locale[currentLocale].memory.swapFree}
                    </td>
                    <td className={memorySwap.className}>
                      {Math.round(Number(memorySwap.value.free || 0) / 1024 / 1024)}{' '}
                      {locale[currentLocale].sizeMb}
                    </td>
                  </tr>
                ) : (
                  <></>
                ),
              )}
            </table>
          )}
        </WrapperMenu>
      ) : (
        <></>
      )}

      {memoryDevicesInfoData[filter] && memoryDevicesInfoData[filter]?.length ? (
        <WrapperMenu
          title={locale[currentLocale].devices.title}
          diffStatuses={
            data.memory.devices && filter === 'all'
              ? defineWrapperStatuses(data.memory.devices)
              : undefined
          }
        >
          {memoryDevicesInfoData[filter]?.map((mDevice) => (
            <DiffMemoryDevice
              device={mDevice}
              deviceType={data.type}
              tdClassName={mDevice.className}
              needShowStatus={filter === 'all'}
            />
          ))}
        </WrapperMenu>
      ) : (
        <></>
      )}
    </WrapperMenu>
  ) : (
    <></>
  );
}
