import { nanoid } from 'nanoid';
import { useEffect, useMemo } from 'react';
import { Button } from '../baseElements/Button';
import locale from '../../../utils/i18n/security/vulnerabilities.json';
import { useWhiteListContext } from '../../../hooks/useWhiteListContext';
import { useHostModalContext } from '../../../hooks/useHostModalContext';
import { getUserData } from '../../../services/local-storage-service';
import type { WhitelistedBulletin } from '../../../types/__generated/on-premise-solution/api/bulletinWhitelistDashboardResponse.v1';
import { useHostDescription } from '../../../hooks/components/useHostDescription';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function BulletinMutableBlockWhiteList(): React.ReactElement {
  const currentUser = getUserData();

  const { bulletinForm, setBulletinForm, setAddBulletin, delBulletinFromMutableBlock } =
    useWhiteListContext();

  const { missedKbs, setHostForm } = useHostModalContext();

  const { assetId, hostname } = useHostDescription();

  const bulletins = useMemo(() => [], []) as WhitelistedBulletin[];

  useEffect(() => {
    if (missedKbs && missedKbs.length > 0) {
      missedKbs.forEach((kb) => {
        const t = {
          bulletinId: kb.id,
          addedBy: {
            source: 'user' as const,
            id: currentUser?.id || '',
          },
          addedAt: new Date().toISOString(),
        };
        bulletins.push(t);
      });
    }
  }, [bulletins, currentUser?.id, missedKbs]);

  useEffect(() => {
    if (bulletins && bulletins.length > 0 && hostname) {
      setBulletinForm(bulletins);
      setHostForm([{ assetId, hostname }]);
    }
  }, [assetId, bulletins, hostname, setBulletinForm, setHostForm]);

  return (
    <div className="apply-assets">
      <div className="title-apply-assets">
        <span className="title-mutable-block">{locale[currentLocale].applyForVulns.title}</span>
        <Button onClick={(): void => setAddBulletin(true)} type="button">
          {locale[currentLocale].vulnsTable.AddVulns}
        </Button>
      </div>
      <div className="list-assets">
        {bulletinForm?.map((b) => {
          return (
            <Button
              type="button"
              fill="task_asset"
              onClick={(e): void => delBulletinFromMutableBlock(b.bulletinId, e)}
              key={nanoid()}
            >
              {b.bulletinId}
            </Button>
          );
        })}
        {(bulletinForm?.length === 0 || bulletinForm === null) && (
          <span className="placeholder">
            {locale[currentLocale].applyForVulns.selectVulnsPlaceholder}
          </span>
        )}
      </div>
    </div>
  );
}
