import type { GridCellProps } from '@progress/kendo-react-grid';
import type { IMissingKBs } from '../../../../../../utils/helpers/types';

export function cellModified(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { date }: IMissingKBs = dataItem;

  return (
    <td className='number'>
      {date.modified ? <span>{new Date(date.modified).toLocaleDateString()}</span> : <span>-</span>}
    </td>
  );
}
