/* eslint-disable react/jsx-props-no-spreading */
import '../../TaskDescription.module.scss';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { State, process, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { useRef, useState } from 'react';
import { Filter } from '@progress/kendo-react-data-tools';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { FILTER_INITIAL, PAGEABLE_DATA_LOCAL } from '../../../../../utils/helpers/constants';
import localeAuditTask from '../../../../../utils/i18n/taskLocale/auditTask.json';
import localeVmTask from '../../../../../utils/i18n/taskLocale/vmTask.json';
import type { AuditRequestPayload } from '../../../../../types/__generated/on-premise-solution/api/audit.v1';
import {
  handlerCustomSearch,
  onFilterChange,
} from '../../../../../utils/helpers/local-filter-helpers';
import { useLocalExcelExport } from '../../../../../hooks/components/useLocalExcelExport';
import { LOCAL_FILTER_PACKAGE_LIST } from '../../../../../utils/helpers/constant-local-filter-task';
import { Input } from '../../../../common/baseElements/Input';
import { Button } from '../../../../common/baseElements/Button';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

export function PackageList({
  dataPayload,
  hostName,
}: {
  dataPayload: AuditRequestPayload | undefined;
  hostName: string | null | undefined | false;
}): React.ReactElement {
  const pageLimitGrid: { [key: string]: string } =
    localStorage.getItem('pageLimitGrid') &&
    JSON.parse(localStorage.getItem('pageLimitGrid') || '');

  const initialDataState: State = {
    sort: [{ field: 'maxScore', dir: 'desc' }],
    take: pageLimitGrid && pageLimitGrid.PackageList ? Number(pageLimitGrid.PackageList) : 10,
    skip: 0,
    filter: FILTER_INITIAL,
  };

  const fieldFindInTable = 'name';

  const [dataState, setDataState] = useState<State>(initialDataState);

  const [filter, setFilter] = useState(FILTER_INITIAL);

  const [customSearch, setCustomSearch] = useState('');

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _grid = useRef<React.LegacyRef<Grid> | undefined>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _export = useRef<ExcelExport | null>(null);

  const packageList =
    dataPayload && 'packages' in dataPayload
      ? dataPayload.packages.map((p) => ({
          name: p[0],
          version: p[1],
          arch: p[2],
        }))
      : null;

  const excelExport = useLocalExcelExport(dataState, _export, packageList, setDataState);

  const titleVulnsSoft = document.location.pathname.includes('assets/images')
    ? `${localeVmTask[currentLocale].tabs.packageList}`
    : `${hostName && hostName.replaceAll('.', '-')}_${
        localeVmTask[currentLocale].tabs.packageList
      }`;

  return (
    <div className="vulns-package-list">
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <div className="filter-line">
            <Filter
              value={filter}
              onChange={(event): void =>
                onFilterChange(
                  event,
                  setCustomSearch,
                  setDataState,
                  dataState,
                  setFilter,
                  filterValue,
                  customSearch,
                  fieldFindInTable,
                )
              }
              fields={LOCAL_FILTER_PACKAGE_LIST[currentLocale]}
            />
            {filter.filters.length === 0 && (
              <Input
                name="customSearch"
                placeholder={localeAuditTask[currentLocale].customFilterName}
                value={customSearch}
                onChange={(e): void =>
                  handlerCustomSearch(
                    e,
                    setCustomSearch,
                    setDataState,
                    filterValue,
                    dataState,
                    fieldFindInTable,
                  )
                }
              />
            )}
            <Button excel fill="flat" onClick={excelExport}>
              {localeAuditTask[currentLocale].vulnsSoft.table.exportToExcel}
            </Button>
          </div>
        </IntlProvider>
      </LocalizationProvider>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          {packageList && (
            <ExcelExport data={packageList} ref={_export} fileName={titleVulnsSoft}>
              <Grid
                pageable={packageList && packageList.length > 10 ? PAGEABLE_DATA_LOCAL : false}
                sortable
                data={process(packageList, dataState)}
                {...dataState}
                onDataStateChange={(e: GridDataStateChangeEvent): void => {
                  setDataState(e.dataState);
                  if (pageLimitGrid) {
                    const pageLimit = {
                      ...pageLimitGrid,
                      PackageList: String(e.dataState.take),
                    };
                    localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
                  } else {
                    const pageLimit = {
                      PackageList: String(e.dataState.take),
                    };
                    localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
                  }
                }}
                scrollable="none"
                ref={_grid.current}
              >
                <Column field="name" title={localeAuditTask[currentLocale].packageList.name} />
                <Column
                  field="version"
                  title={localeAuditTask[currentLocale].packageList.version}
                />
                <Column field="arch" title={localeAuditTask[currentLocale].packageList.arch} />
              </Grid>
            </ExcelExport>
          )}
        </IntlProvider>
      </LocalizationProvider>
    </div>
  );
}
