import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '../../../../common/baseElements/Button';
import localeSettings from '../../../../../utils/i18n/settingsLocales/settings.json';
import { useApiKeyContext } from '../../../../../hooks/useApiKeyContext';
import { CommonMethodList } from '../../../../common/CommonMethodList';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

export function MethodListForForm(): React.ReactElement {
  const componentName = 'MethodListForForm';

  const {
    exitMethodsFrom,
    setApiMethods,
    apiMethodsForm,
    addSelectedMethods,
    prepareAllowedApiMethodsData,
  } = useApiKeyContext();

  const allowedApiMethods = prepareAllowedApiMethodsData();

  return (
    <div className="add-asset">
      <Dialog
        title={localeSettings[currentLocale].apiKey.method.titleCheckboxMethos}
        onClose={(): void => setApiMethods(false)}
      >
        <div className="k-form">
          <div className="form-content select-asset">
            <CommonMethodList apiMethods={allowedApiMethods} componentName={componentName} />
          </div>
          <div className="k-form-buttons">
            <Button type="submit" onClick={addSelectedMethods}>
              {localeSettings[currentLocale].apiKey.method.addMethod}{' '}
              {apiMethodsForm &&
                apiMethodsForm?.length > 0 &&
                `(${apiMethodsForm.filter((m) => m)?.length})`}
            </Button>
            <Button type="button" onClick={exitMethodsFrom}>
              {localeSettings[currentLocale].apiKey.btnCnl}
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
