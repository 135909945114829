import { nanoid } from 'nanoid';
import { Button } from '../../../../common/baseElements/Button';
import localeSettings from '../../../../../utils/i18n/settingsLocales/settings.json';
import { useApiKeyContext } from '../../../../../hooks/useApiKeyContext';
import { useCheck } from '../../../../../hooks/components/useCheck';
import { useCommonContext } from '../../../../../hooks/useCommonContext';
import styles from '../ApiKeyList.module.scss';
import { Input } from '../../../../common/baseElements/Input';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

export function IpRangesMutableBlock(): React.ReactElement {
  const {
    ipRanges,
    addIpRanges,
    ipRangesForm,
    setIpRangesForm,
    deleteIpRangesFromTask,
    changeIpValue,
  } = useApiKeyContext();

  const { ipRangesValidator } = useCheck();

  const { resultCheckIp } = useCommonContext();

  return (
    <div className="apply-assets">
      <div className="form-block">
        <label htmlFor="ipRanges">{localeSettings[currentLocale].apiKey.ipRanges.label}</label>
        {/* <input
          className="k-input"
          name="ipRanges"
          placeholder={localeSettings[currentLocale].apiKey.ipRanges.cidrPlaceHolder}
          value={ipRanges}
          onChange={(e): void => changeIpValue(e.target.value)}
          onBlur={(e): void => ipRangesValidator(e.target.value)}
        /> */}
        <Input
          className="k-input"
          name="ipRanges"
          placeholder={localeSettings[currentLocale].apiKey.ipRanges.cidrPlaceHolder}
          value={ipRanges}
          onChange={(e): void => changeIpValue(String(e.target.value))}
          onBlur={(e): void => ipRangesValidator(e.target.value)}
        />
        {resultCheckIp && (
          <div className={styles.resultCheckIp}>
            <span>{resultCheckIp}</span>
          </div>
        )}
      </div>
      <div className={styles.apiAddKeyCidrActions}>
        <Button
          icon="check"
          disabled={!!resultCheckIp || !ipRanges}
          onClick={(): void => addIpRanges(ipRanges)}
        >
          {localeSettings[currentLocale].apiKey.ipRanges.btnAddIp}
        </Button>
        {ipRangesForm && ipRangesForm.length > 0 && (
          <Button icon="close" onClick={(): void => setIpRangesForm(null)}>
            {localeSettings[currentLocale].apiKey.ipRanges.btnCancelAllIp} ({ipRangesForm?.length})
          </Button>
        )}
      </div>
      <div className="list-assets">
        {ipRangesForm?.map((ip) => {
          return (
            <div className="item-asset" key={nanoid()}>
              <Button
                type="button"
                fill="task_asset"
                onClick={(e): void => deleteIpRangesFromTask(ip, e)}
              >
                {ip}
              </Button>
            </div>
          );
        })}
        {(ipRangesForm?.length === 0 || ipRangesForm === null) && (
          <span className="placeholder">
            {localeSettings[currentLocale].apiKey.ipRanges.titleSelectIp}
          </span>
        )}
      </div>
    </div>
  );
}
