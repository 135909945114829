import '../../features/Host/hostComponents/HostDescription.module.scss';
import { useHistory } from 'react-router';
import { useEffect } from 'react';
import { Button } from '../baseElements/Button';
import localeHostDescription from '../../../utils/i18n/assetLocales/hostDescription.json';
import { useHostActions } from '../../../hooks/components/useHostActions';
import { useHostModalContext } from '../../../hooks/useHostModalContext';
import type { AssetHostDashboardResponse } from '../../../types/__generated/on-premise-solution/api/assetHostDashboardResponse.v1';
import type { AssetRouterDashboardResponse } from '../../../types/__generated/on-premise-solution/api/assetRouterDashboardResponse.v1';
import type {
  AssetFirewallDashboardResponse,
  AssetHypervisorDashboardResponse,
} from '../../../types/__generated/on-premise-solution/api/assetsDevicesDashboardResponse.v1';
import type { AssetSwitchDashboardResponse } from '../../../types/__generated/on-premise-solution/api/assetDeviceDashboardResponse.v1';
import styles from './styles.module.scss';
import { useCredentialContext } from '../../../hooks/useCredentialContext';

export function AssetDescriptionContextMenu({
  data,
  currentLocale,
}: {
  data:
    | AssetHostDashboardResponse
    | AssetRouterDashboardResponse
    | AssetFirewallDashboardResponse
    | AssetSwitchDashboardResponse
    | AssetHypervisorDashboardResponse
    | undefined;
  currentLocale: 'ru-RU' | 'en-EN';
}): React.ReactElement {
  const history = useHistory();

  const { handleEdit, setEdit } = useCredentialContext();

  const { sshPort, setSshPort, smbPort, setSmbPort, winrmPort, setWinrmPort } =
    useHostModalContext();

  const onEdit = (id: string | undefined): void => {
    handleEdit(id);
    setEdit(true);
  };

  useEffect(() => {
    if (data && data.sshPort) {
      setSshPort(String(data.sshPort));
    }
    if (data?.type === 'host' && data.smbPort) {
      setSmbPort(String(data.smbPort));
    }
    if (data?.type === 'host' && data.winrmPort) {
      setWinrmPort(String(data.winrmPort));
    }
  }, []);

  const { handleDelCredential, selectCredential, handlePort } = useHostActions();

  return (
    <div className="groups__menu">
      {data && data.credential && (
        <div className="dropdown__container">
          <span className="k-icon k-i-more-vertical" />
          <div className={`dropdown__content ${styles.cred_menu}`}>
            <Button
              fill="menu"
              icon="edit"
              className={styles.credentials_button}
              style={{ marginTop: '8px' }}
              onClick={(): void => onEdit(data.credential?.credentialId)}
            >
              {localeHostDescription[currentLocale].summary.editCredetial}
            </Button>
            <Button
              fill="menu"
              icon="repeat"
              className={styles.credentials_button}
              onClick={(): void =>
                selectCredential({
                  assetId: data.assetId,
                  hostname: data?.latestInventorization?.hostname,
                  accountId: data.accountId,
                  assetGroupIds: data.assetGroupIds,
                  credentialId: data.credential?.credentialId,
                  osType: data.type === 'host' ? data.latestInventorization?.os.type : undefined,
                  assetType: data.type,
                })
              }
            >
              {localeHostDescription[currentLocale].summary.changeCredential}
            </Button>
            {data.credential.type === 'ssh' ? (
              <Button
                fill="menu"
                className={styles.credentials_button}
                onClick={(): void =>
                  handlePort({
                    assetId: data.assetId,
                    accountId: data.accountId,
                    assetGroupIds: data.assetGroupIds,
                    credentialId: data.credential?.credentialId,
                    osType: data.type === 'host' ? data.latestInventorization?.os.type : undefined,
                    assetType: data.type,
                    sshPort: Number(sshPort),
                    isSsh: true,
                  })
                }
              >
                <span className="k-button-icon k-icon k-i-button" />
                {localeHostDescription[currentLocale].summary.changeSsh}
              </Button>
            ) : null}
            {data.credential.type === 'winrm' ? (
              <Button
                fill="menu"
                className={styles.credentials_button}
                onClick={(): void =>
                  handlePort({
                    assetId: data.assetId,
                    accountId: data.accountId,
                    assetGroupIds: data.assetGroupIds,
                    credentialId: data.credential?.credentialId,
                    osType: data.type === 'host' ? data.latestInventorization?.os.type : undefined,
                    assetType: data.type,
                    smbPort: Number(smbPort),
                    isSmbPort: true,
                  })
                }
              >
                <span className="k-button-icon k-icon k-i-button" />
                {localeHostDescription[currentLocale].summary.changesmbPort}
              </Button>
            ) : null}
            {data.credential.type === 'winrm' ? (
              <Button
                fill="menu"
                className={styles.credentials_button}
                onClick={(): void =>
                  handlePort({
                    assetId: data.assetId,
                    accountId: data.accountId,
                    assetGroupIds: data.assetGroupIds,
                    credentialId: data.credential?.credentialId,
                    osType: data.type === 'host' ? data.latestInventorization?.os.type : undefined,
                    assetType: data.type,
                    winrmPort: Number(winrmPort),
                    isWinrmPort: true,
                  })
                }
              >
                <span className="k-button-icon k-icon k-i-button" />
                {localeHostDescription[currentLocale].summary.changewinrmPort}
              </Button>
            ) : null}
            <Button
              fill="menu"
              onClick={(): void => handleDelCredential(data.assetId, data)}
              className={styles.credentials_button}
              icon="delete"
            >
              {localeHostDescription[currentLocale].summary.deleteCredential}
            </Button>
            <div className={styles.break} />
            <Button
              fill="menu"
              icon="credentials"
              style={{ marginBottom: '8px' }}
              onClick={(): void => {
                history.push('/lk/credentials/list');
              }}
              className={styles.credentials_button}
            >
              {localeHostDescription[currentLocale].summary.CredentialList}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
