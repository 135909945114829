import './TaskIDetails.module.scss';
import type { InventorizationResponse } from '../../../../types/__generated/on-premise-solution/api/inventorizationResponse.v1';
import { Cpu } from './iDetailsComponents/Cpu';
import { Disk } from './iDetailsComponents/Disk';
import { Memory } from './iDetailsComponents/Memory';
import { MemoryNetDevices } from './iDetailsComponents/MemoryNetDevices';
import { DiskNetDevices } from './iDetailsComponents/DiskNetDevices';
import { CpuNetDevices } from './iDetailsComponents/CpuNetDevices';
import { CiscoHardwareInfo } from './iDetailsComponents/CiscoHardwareInfo';
import { DiskHypervisor } from './iDetailsComponents/DiskHypervisor';

export function HardwareIDetails({
  data,
}: {
  data: InventorizationResponse | undefined;
}): React.ReactElement {
  return (
    <div className="inventorization-hardware">
      {data && (
        <>
          {data.result.memory &&
            data.type !== 'firewall' &&
            data.type !== 'router' &&
            data.type !== 'switch' &&
            data.type !== 'hypervisor' && <Memory data={data} />}
          {data.result.memory &&
            (data.type === 'firewall' ||
              data.type === 'router' ||
              data.type === 'switch' ||
              data.type === 'hypervisor') && <MemoryNetDevices data={data} />}
          {data.result.disk &&
            data.type !== 'firewall' &&
            data.type !== 'router' &&
            data.type !== 'switch' &&
            data.type !== 'hypervisor' && <Disk data={data} />}
          {data.result.disk &&
            (data.type === 'firewall' || data.type === 'router' || data.type === 'switch') && (
              <DiskNetDevices data={data} />
            )}
          {data.result.disk &&
            (data.type === 'hypervisor') && (
              <DiskHypervisor data={data} />
            )}
          {data.result.cpu_info &&
            data.type !== 'firewall' &&
            data.type !== 'router' &&
            data.type !== 'switch' &&
            data.type !== 'hypervisor' && <Cpu data={data} />}
          {data.result.cpu_info &&
            (data.type === 'firewall' ||
              data.type === 'router' ||
              data.type === 'switch' ||
              data.type === 'hypervisor') && <CpuNetDevices data={data} />}
          {((data.type === 'router' && data.class === 'cisco') ||
            (data.type === 'switch' && data.class === 'aruba')) &&
            data.result.hardware_info && <CiscoHardwareInfo data={data} />}
        </>
      )}
    </div>
  );
}
