import { Grid, GridColumn as Column, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { orderBy, type SortDescriptor } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { PAGEABLE_DATA } from '../../../../../utils/helpers/constants';
import type { AuditLinuxDifference } from '../../../../../types/__generated/on-premise-solution/api/auditDifferenceResponse.v1';
import { DiffStandartCell } from '../cells/DiffStandartCell';
import { DiffTypeCell } from '../cells/DiffTypeCell';
import localeAuditTask from '../../../../../utils/i18n/taskLocale/auditTask.json';
import { createPackagesData } from '../../../../../utils/helpers/difference-modal-helper';
import { Input } from '../../../../common/baseElements/Input';
import localeVmTask from '../../../../../utils/i18n/taskLocale/vmTask.json';
import { Button } from '../../../../common/baseElements/Button';
import hostDescriptionLocale from '../../../../../utils/i18n/assetLocales/hostDescription.json';
import { DiffFilters } from '../DiffFilters';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

export function DiffPackageList({
  packages,
}: {
  packages: AuditLinuxDifference['packages'];
}): ReactElement {
  const _export = useRef<ExcelExport | null>(null);
  const [page, setPage] = useState({ take: 10, skip: 0 });
  const sort: SortDescriptor[] = [{ field: 'name', dir: 'asc' }];
  const [searchValue, setSearchValue] = useState('');
  const [filter, setFilter] = useState<
    'all' | 'noChanges' | 'updated' | 'changed' | 'installed' | 'removed'
  >('all');
  const packageData = useMemo(() => createPackagesData(packages), [packages]);
  const [packageList, setPackageList] = useState(packageData[filter]);

  const filterList = {
    all: `${hostDescriptionLocale[currentLocale].changes.shortFilters.vulns.all} (${packageData.all.length})`,
    noChanges: `${hostDescriptionLocale[currentLocale].changes.shortFilters.vulns.noChanges} (${packageData.noChanges.length})`,
    updated: `${hostDescriptionLocale[currentLocale].changes.shortFilters.vulns.updated} (${
      packageData.updated.length / 2
    })`,
    installed: `${hostDescriptionLocale[currentLocale].changes.shortFilters.vulns.installed} (${packageData.installed.length})`,
    removed: `${hostDescriptionLocale[currentLocale].changes.shortFilters.vulns.removed} (${packageData.removed.length})`,
    // changed: `${hostDescriptionLocale[currentLocale].changes.shortFilters.vulns.changed} (${packageData.changed.length})`,
  };

  useEffect(() => {
    setPackageList(
      packageData[filter].filter((soft) => {
        if (!soft.name) return false;

        return soft.name.toLowerCase().includes(searchValue.toLowerCase());
      }),
    );
    setPage((prev) => ({ ...prev, skip: 0 }));
  }, [filter, searchValue, packages]);

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save(packageData.all);
    }
  };

  return (
    <>
      <div className="filter-line">
        <Input
          name="customSearch"
          placeholder={localeAuditTask[currentLocale].customFilterName}
          onChange={handleSearch}
        />
        <Button excel fill="flat" onClick={excelExport}>
          {localeAuditTask[currentLocale].vulnsSoft.table.exportToExcel}
        </Button>
      </div>
      <DiffFilters currentFilter={filter} setFilter={setFilter} filterList={filterList} />
      <ExcelExport ref={_export} fileName={localeVmTask[currentLocale].tabs.packageList}>
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <Grid
              pageable={PAGEABLE_DATA}
              sortable={false}
              // sort={sort}
              data={orderBy(packageList || [], sort)?.slice(page.skip, page.take + page.skip)}
              total={packageList?.length}
              skip={page.skip}
              take={page.take}
              onPageChange={pageChange}
              className="no-margin-top diffaudit__packagesgrid"
              scrollable="none"
            >
              <Column
                field="diffType"
                cell={DiffTypeCell}
                title=" "
                width={20}
                sortable={false}
                headerClassName="no-cursor"
              />
              <Column
                field="name"
                title={localeAuditTask[currentLocale].packageList.name}
                cell={DiffStandartCell}
                sortable={false}
                headerClassName="no-cursor"
              />
              <Column
                field="version"
                title={localeAuditTask[currentLocale].packageList.version}
                cell={DiffStandartCell}
                sortable={false}
                headerClassName="no-cursor"
              />
              <Column
                field="arch"
                title={localeAuditTask[currentLocale].packageList.arch}
                cell={DiffStandartCell}
                sortable={false}
                headerClassName="no-cursor"
              />
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      </ExcelExport>
    </>
  );
}
