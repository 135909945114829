/* eslint-disable react/jsx-no-useless-fragment */
import type { GridCellProps } from '@progress/kendo-react-grid';
import { NavLink } from 'react-router-dom';
import type { EnrichedLogResponse } from '../../../../../types/__generated/on-premise-solution/api/enrichedLogResponse.v1';

export function CellTaskname(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { taskExecution }: EnrichedLogResponse = dataItem;

  return (
    <>
      {taskExecution && taskExecution.taskName ? (
        <td title={taskExecution.taskName}>
          <NavLink to={`/lk/task/executions/${taskExecution.taskExecutionId}`}>
            {taskExecution && taskExecution.taskName}
          </NavLink>
        </td>
      ) : (
        <td>-</td>
      )}
    </>
  );
}
