import type { GridCellProps } from '@progress/kendo-react-grid';
import { useCallback, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Button } from '../../../common/baseElements/Button';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import localeUser from '../../../../utils/i18n/userLocales/user.json';
import { useServerContext } from '../../../../hooks/useServerContext';
import type { Ldap } from '../../../../types/__generated/on-premise-solution/api/ldapsDashboardResponse.v1';
import type { LdapDeletionResponse } from '../../../../types/__generated/on-premise-solution/api/ldapDeletionResponse.v1';
import { deleteServer } from '../../../../services/user-service';
import { getUserData } from '../../../../services/local-storage-service';
import { DeleteConfirmModal } from '../../../common/modal/DeleteConfirmModal';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeUser;

export function CellSrvListMenu({ dataItem }: GridCellProps): React.ReactElement {
  const queryClient = useQueryClient();

  const currentUser = getUserData();

  const [isConfirm, setConfirm] = useState(false);

  const { setServer, setEditServer } = useServerContext();

  const editServer = useCallback(() => {
    setEditServer({
      accountId: dataItem.accountId,
      baseDN: dataItem.baseDN,
      url: dataItem.url,
      ldapId: dataItem.ldapId,
      name: dataItem.name,
      description: dataItem.description,
      type: dataItem.type,
      account: { name: dataItem.account.name, accountId: dataItem.account.accountId },
    });
    // setServer(true);
  }, [
    setEditServer,
    dataItem.accountId,
    dataItem.baseDN,
    dataItem.url,
    dataItem.ldapId,
    dataItem.name,
    dataItem.description,
    dataItem.type,
    dataItem.account.name,
    dataItem.account.accountId,
  ]);

  const mutation = useMutation<LdapDeletionResponse | null, ApiError, Ldap['ldapId']>(
    (payload) => deleteServer(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('ServerList');
      },
    },
  );

  const handleConfirm = (): void => {
    setConfirm(true);
  };

  const handleDelete = (): void => {
    const { ldapId } = dataItem as Ldap;
    mutation.mutate(ldapId);
    setConfirm(false);
  };

  return (
    <>
      {isConfirm && (
        <DeleteConfirmModal
          type="auth-server"
          name={dataItem?.name}
          onClose={(): void => setConfirm(false)}
          handleDelete={handleDelete}
          errorMessage={mutation.error?.message}
        />
      )}
      <td className="cell_menu">
        <div className="groups__menu">
          <div className="dropdown__container">
            <span className="k-icon k-i-more-vertical" />
            <div className="dropdown__content">
              <Button
                fill="menu"
                onClick={editServer}
                disabled={currentUser?.role === 'user' || currentUser?.role === 'reader_user'}
              >
                {localeUser[currentLocale].connectServers.editmenu}
              </Button>
              <Button
                fill="menu"
                onClick={handleConfirm}
                disabled={currentUser?.role === 'user' || currentUser?.role === 'reader_user'}
              >
                {localeUser[currentLocale].connectServers.deleteMenu}
              </Button>
            </div>
          </div>
        </div>
      </td>
    </>
  );
}
