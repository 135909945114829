import { Loader } from '@progress/kendo-react-indicators';
import styles from '../features/Host/forms/AddAssetForm.module.scss';
import localeDashboardForm from '../../utils/i18n/dashboardLocale/addDashboardForm.json';
import localeErrorMessage from '../../utils/i18n/commonLocales/errorMessage.json';
import { useCommonContext } from '../../hooks/useCommonContext';
import { Button } from './baseElements/Button';
import { useCredentialContext } from '../../hooks/useCredentialContext';
import { useCredential } from '../../hooks/components/useCredential';

export function CheckCredentialInfo({
  ip,
  port,
  сredentialId,
  hostname,
  typeOfPort,
}: {
  ip: string;
  port: number | undefined;
  сredentialId: string | null | undefined;
  hostname?: string | undefined;
  typeOfPort?: string | undefined;
}): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeDashboardForm;

  const { resultCheckIp } = useCommonContext();

  const { checkCredentilsResp, checkingCredential, checkErrorMutation, authType } =
    useCredentialContext();

  const { checkCredentialAndIp } = useCredential();

  return (
    <div className={styles.checkCredentialInfo}>
      <div className="check-credential-content">
        <div className="check-credential-success">
          {checkCredentilsResp &&
            checkCredentilsResp.isAssetReachable.value &&
            checkCredentilsResp.isAuthenticatable.value &&
            checkCredentilsResp.isEnoughRights?.value &&
            checkCredentilsResp.isEnoughRights?.reason === 'OK' && (
              <div className="check-credential-success__content">
                <span
                  className="k-button-icon k-icon k-i-check"
                  style={{ color: 'green', fontWeight: 'bold' }}
                />
                <span>
                  {currentLocale === 'ru-RU'
                    ? checkCredentilsResp.isAssetReachable.reason_RU
                    : checkCredentilsResp.isAssetReachable.reason}
                </span>
              </div>
            )}
          {checkCredentilsResp &&
            checkCredentilsResp.isAssetReachable.value &&
            checkCredentilsResp.isAuthenticatable.value &&
            !checkCredentilsResp.isEnoughRights && (
              <div className="check-credential-success__content">
                <span
                  className="k-button-icon k-icon k-i-check"
                  style={{ color: 'green', fontWeight: 'bold' }}
                />
                <span>
                  {currentLocale === 'ru-RU'
                    ? checkCredentilsResp.isAssetReachable.reason_RU
                    : checkCredentilsResp.isAssetReachable.reason}
                </span>
              </div>
            )}
        </div>
        <div className="check-credential-false">
          {checkCredentilsResp &&
            !checkCredentilsResp.isAssetReachable.value &&
            checkCredentilsResp.isAuthenticatable.value &&
            checkCredentilsResp.isEnoughRights?.value && (
              <div className="check-credential-success__content">
                <span
                  className="k-button-icon k-icon k-i-error"
                  style={{ color: '#e92e2e', fontWeight: 'bold' }}
                />
                <span>
                  {currentLocale === 'ru-RU'
                    ? checkCredentilsResp.isAssetReachable.reason_RU
                    : checkCredentilsResp.isAssetReachable.reason}
                </span>
              </div>
            )}
        </div>
        <div className="check-credential-false">
          {checkCredentilsResp &&
            checkCredentilsResp.isAssetReachable.value &&
            !checkCredentilsResp.isAuthenticatable.value &&
            checkCredentilsResp.isEnoughRights?.value && (
              <div className="check-credential-success__content">
                <span
                  className="k-button-icon k-icon k-i-error"
                  style={{ color: '#e92e2e', fontWeight: 'bold' }}
                />
                <span>
                  {currentLocale === 'ru-RU'
                    ? checkCredentilsResp.isAuthenticatable.reason_RU
                    : checkCredentilsResp.isAuthenticatable.reason}
                </span>
              </div>
            )}
        </div>
        <div className="check-credential-false">
          {checkCredentilsResp &&
            checkCredentilsResp.isAssetReachable.value &&
            checkCredentilsResp.isAuthenticatable.value &&
            checkCredentilsResp.isEnoughRights?.value === false && (
              <div className="check-credential-success__content">
                <span
                  className="k-button-icon k-icon k-i-error"
                  style={{ color: '#e92e2e', fontWeight: 'bold' }}
                />
                <span>
                  {currentLocale === 'ru-RU'
                    ? checkCredentilsResp.isEnoughRights?.reason_RU
                    : checkCredentilsResp.isEnoughRights?.reason}
                </span>
              </div>
            )}
        </div>
        <div className="check-credential-false">
          {checkCredentilsResp &&
            !checkCredentilsResp.isAssetReachable.value &&
            !checkCredentilsResp.isAuthenticatable.value &&
            checkCredentilsResp.isEnoughRights?.value && (
              <div className="check-credential-success__content">
                <span
                  className="k-button-icon k-icon k-i-error"
                  style={{ color: '#e92e2e', fontWeight: 'bold' }}
                />
                <span>
                  {currentLocale === 'ru-RU'
                    ? checkCredentilsResp.isAssetReachable?.reason_RU
                    : checkCredentilsResp.isAssetReachable?.reason}
                </span>
              </div>
            )}
        </div>
        <div className="check-credential-false">
          {checkCredentilsResp &&
            checkCredentilsResp.isAssetReachable.value &&
            !checkCredentilsResp.isAuthenticatable.value &&
            !checkCredentilsResp.isEnoughRights?.value && (
              <div className="check-credential-success__content">
                <span
                  className="k-button-icon k-icon k-i-error"
                  style={{ color: '#e92e2e', fontWeight: 'bold' }}
                />
                <span>
                  {currentLocale === 'ru-RU'
                    ? checkCredentilsResp.isAuthenticatable?.reason_RU
                    : checkCredentilsResp.isAuthenticatable?.reason}
                </span>
              </div>
            )}
        </div>
        <div className="check-credential-false">
          {checkCredentilsResp &&
            !checkCredentilsResp.isAssetReachable.value &&
            !checkCredentilsResp.isAuthenticatable.value &&
            !checkCredentilsResp.isEnoughRights?.value && (
              <div className="check-credential-success__content">
                <span
                  className="k-button-icon k-icon k-i-error"
                  style={{ color: '#e92e2e', fontWeight: 'bold' }}
                />
                <span>
                  {currentLocale === 'ru-RU'
                    ? checkCredentilsResp.isAssetReachable?.reason_RU
                    : checkCredentilsResp.isAssetReachable?.reason}
                </span>
              </div>
            )}
        </div>
      </div>
      {/* <div className="error-common-field">{checkErrorMutation}</div> */}
      <Button
        type="button"
        onClick={(): void =>
          checkCredentialAndIp({
            ip,
            port,
            сredentialId,
            hostname,
            typeOfPort,
            authType,
          })
        }
        disabled={
          сredentialId === '' ||
          сredentialId === undefined ||
          ip === '' ||
          resultCheckIp === localeErrorMessage[currentLocale].form.useName
        }
      >
        <div className="check-credential-ip__wrapper">
          <span>
            {document.location.pathname.includes('credentials')
              ? localeDashboardForm[currentLocale].checkCredentialShort
              : localeDashboardForm[currentLocale].checkCredential}
          </span>
        </div>
        {checkingCredential && (
          <div className="col-4">
            <Loader type="pulsing" style={{ color: 'black' }} />
          </div>
        )}
      </Button>
    </div>
  );
}
