import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Dialog } from '@progress/kendo-react-dialogs';
import { useEffect } from 'react';
import { checkLength } from '../../../../utils/helpers/validation';
import localeUser from '../../../../utils/i18n/userLocales/user.json';
import localeUserList from '../../../../utils/i18n/userLocales/userList.json';
import type localeErrorMessage from '../../../../utils/i18n/commonLocales/errorMessage.json';
import { FormInput } from '../../../common/form/FormInput';
import { useServerContext } from '../../../../hooks/useServerContext';
import { DropDownSelect } from '../../../common/form/DropDownSelect';
import { useAccounts } from '../../../../hooks/components/useAccounts';
import { Button } from '../../../common/baseElements/Button';
import { FormTextArea } from '../../../common/form/FormTextArea';
import { СommonTableLoader } from '../../../common/tableLoader/CommonTableLoader';
import { useAccountModalContext } from '../../../../hooks/useAccountModalContext';
import { getUserData } from '../../../../services/local-storage-service';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeErrorMessage;

export function AddServerForm(): React.ReactElement {
  const currentUser = getUserData();

  const {
    url,
    setUrl,
    baseDN,
    setBaseDN,
    editServer,
    name,
    setName,
    description,
    setDescription,
    handleClose,
    handleSubmit,
    type,
    changeDropDownType,
    setErrorMutation,
    errorMutation,
  } = useServerContext();

  useEffect(() => {
    return () => setErrorMutation('');
  }, []);

  const { accountId, changeDropDownAccount } = useAccountModalContext();

  const { destructuringAccounts, isLoading, isFetching } = useAccounts();

  const destructuredAccounts = destructuringAccounts();

  return (
    <Dialog
      title={
        !editServer
          ? localeUser[currentLocale].connectServers.form.title
          : `${localeUser[currentLocale].connectServers.form.titleEdit} ${name}`
      }
      onClose={handleClose}
      className="modal small"
    >
      <Form
        onSubmit={handleSubmit}
        render={(): JSX.Element => (
          <FormElement>
            <div className="form-content">
              {currentUser?.role === 'super_admin' && (
                <Field
                  name="accountId"
                  component={DropDownSelect}
                  label={localeUserList[currentLocale].modal.accountName}
                  onChange={changeDropDownAccount}
                  data={destructuredAccounts?.filter((a) => a.id !== accountId.id)}
                  textField="name"
                  dataItemKey="id"
                  defaultValue={{
                    id: '000',
                    name: localeUserList[currentLocale].modal.placeholderAccountName,
                  }}
                  cls="form-field-wrapper"
                  currentValue={accountId}
                  disabled={!destructuredAccounts}
                  classForLabel="required-label"
                />
              )}
              <Field
                name="type"
                component={DropDownSelect}
                label={localeUserList[currentLocale].modal.type}
                onChange={changeDropDownType}
                data={['ldap', 'activedirectory'].filter((t) => t !== type)}
                defaultValue={
                  !type ? localeUser[currentLocale].connectServers.grid.selectType : type
                }
                cls="form-field-wrapper"
                currentValue={type}
                classForLabel="required-label"
              />
              <Field
                name="name"
                component={FormInput}
                id="name"
                label={localeUser[currentLocale].connectServers.form.name}
                onChange={(e): void => {
                  setName(e.target.value);
                }}
                placeholder={localeUser[currentLocale].connectServers.form.placeholderSrvName}
                inputValue={name}
                className="form-field-wrapper"
                customValidator={checkLength}
                type="text"
                classForLabel="required-label"
              />
              <Field
                name="url"
                component={FormInput}
                id="url"
                label={localeUser[currentLocale].connectServers.form.url}
                onChange={(e): void => {
                  setUrl(e.target.value);
                }}
                placeholder="ldap://192.168.0.1"
                inputValue={url}
                className="form-field-wrapper"
                customValidator={checkLength}
                type="text"
                classForLabel="required-label"
              />
              <Field
                name="baseDN"
                component={FormInput}
                id="baseDn"
                label={localeUser[currentLocale].connectServers.form.baseDN}
                onChange={(e): void => {
                  setBaseDN(e.target.value);
                }}
                placeholder="dc=test,dc=example,dc=com'"
                inputValue={baseDN}
                className="form-field-wrapper"
                customValidator={checkLength}
                type="text"
                classForLabel="required-label"
              />
              <Field
                name="description"
                component={FormTextArea}
                id="description"
                label={localeUser[currentLocale].connectServers.form.description}
                onChange={(e): void => {
                  setDescription(e.value);
                }}
                placeholder={localeUser[currentLocale].connectServers.form.desPlaceholder}
                inputValue={description}
              />
            </div>
            <div className="error-common-field">
              <span>{errorMutation}</span>
            </div>
            <div className="k-form-buttons">
              <Button
                type="submit"
                disabled={
                  !name ||
                  !url ||
                  !baseDN ||
                  (currentUser?.role === 'super_admin' && accountId.id === '000') ||
                  !type
                }
              >
                {editServer
                  ? localeUserList[currentLocale].modal.btnSave
                  : localeUserList[currentLocale].modal.btnAdd}
              </Button>
              <Button onClick={handleClose}>{localeUserList[currentLocale].modal.btnCancel}</Button>
            </div>
          </FormElement>
        )}
      />
      <СommonTableLoader queryData={isLoading || isFetching} filteringData={false} />
    </Dialog>
  );
}
