import { Dialog } from '@progress/kendo-react-dialogs';
import { Form, FormElement } from '@progress/kendo-react-form';
import type { DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { Button } from '@progress/kendo-react-buttons';
import { useQuery } from 'react-query';
import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ErrorBoundary } from 'react-error-boundary';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
// import localeNewTask from '../../../../../utils/i18n/taskLocale/newTask.json';
import hostDescriptionLocale from '../../../../../utils/i18n/assetLocales/hostDescription.json';
import styles from '../../forms/AddAssetForm.module.scss';
import type { ApiError } from '../../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getDiffInvCheckpoints, getDifferenceInv } from '../../../../../services/asset-service';
import { useDifferenceModalContext } from '../../../../../hooks/useDifferenceModalContext';
import { DifferenceInvResult } from './DifferenceInvResult';
import { BoundaryErrorComponent } from '../../../../common/BoundaryErrorComponent';
import type { InventorizationDifference } from '../../../../../types/__generated/on-premise-solution/api/inventorizationDifference.v1';
import { handleError } from '../../../../../utils/errors';
import { useNotificationContext } from '../../../../../hooks/useNotificationContext';
import { DiffByDateTab } from '../DiffByDateTab';
import type { InventorizationHistory } from '../../../../../types/__generated/on-premise-solution/api/inventorizationHistory.v1';
import { DiffByInvTab } from './DiffByInvTab';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof hostDescriptionLocale;

export function DifferenceInvModal({
  hostName,
  ip,
  system,
  deviceType,
}: {
  hostName?: string;
  ip?: string | null;
  system?: string;
  deviceType?: string;
}): ReactElement {
  const { setInvOpen, assetType } = useDifferenceModalContext();
  const { createNotification } = useNotificationContext();

  const [localError, setLocalError] = useState<string | null>(null);
  // const [startDate, setStartDate] = useState<Date | null>(null);
  // const [finishDate, setFinishDate] = useState<Date | null>(new Date());
  let startDate: Date | null = null;
  let finishDate: Date | null = null;
  const { assetId } = useParams<{ assetId: string }>();
  const [selected, setSelected] = useState<number>(0);

  const checkpointsQuery = useQuery<InventorizationHistory, ApiError>(
    ['diffInvCheckpoints'],
    () => getDiffInvCheckpoints(assetId),
    { refetchOnWindowFocus: false },
  );

  const { isLoading, error, data, isFetching, refetch, remove } = useQuery<
    InventorizationDifference,
    ApiError
  >(
    ['diffInv'],
    () =>
      getDifferenceInv(assetId, startDate?.toISOString() || '', finishDate?.toISOString() || ''),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (d) => {
        if (!startDate)
          // setStartDate(
          //   d?.sourceInventorizations?.firstInventorization?.createdAt
          //     ? new Date(d.sourceInventorizations.firstInventorization.createdAt)
          //     : null,
          // );
          startDate = d?.sourceInventorizations?.firstInventorization?.createdAt
            ? new Date(d.sourceInventorizations.firstInventorization.createdAt)
            : null;
      },
      // keepPreviousData: true,
      // cacheTime: DATA_CACHE_CACHETIME,
      // staleTime: DATA_CACHE_STALETIME,
    },
  );

  const onClick = (firstDate: string | undefined, secondDate: string | undefined): void => {
    if (!firstDate || !secondDate)
      setLocalError(hostDescriptionLocale[currentLocale].changes.errors.invalidDates);
    else if (firstDate === secondDate)
      setLocalError(hostDescriptionLocale[currentLocale].changes.errors.sameDates);
    else {
      startDate = new Date(firstDate);
      finishDate = new Date(secondDate);
      // setStartDate(new Date(firstDate));
      // setFinishDate(new Date(secondDate));
      setLocalError(null);
      refetch();
      // console.log(firstDate, secondDate);
    }
  };

  useEffect(() => {
    return () => {
      remove();
      checkpointsQuery.remove();
    };
  }, []);

  const handleSelect = (e: TabStripSelectEventArguments): void => {
    setSelected(e.selected);
  };

  useEffect(() => {
    if (error) handleError(error, createNotification);
  }, [error, createNotification]);

  return (
    <Dialog
      autoFocus
      className="biggest difference-modal"
      title={`${hostDescriptionLocale[currentLocale].changes.invModalTitle} ${
        assetType ? hostDescriptionLocale[currentLocale].changes[assetType] : ''
      }: ${hostName || assetId}`}
      onClose={(): void => setInvOpen(false)}
    >
      <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
        <Form
          render={(): React.ReactElement => (
            <FormElement className={styles.diff_form}>
              <div className="common-summary-page" style={{ marginBottom: '4px' }}>
                {(hostName || ip || deviceType || system) && (
                  <table className="column">
                    <tbody>
                      {assetType === 'host' && (
                        <>
                          <tr>
                            <td>{hostDescriptionLocale[currentLocale].summary.hostname}</td>
                            <td>{hostName || '-'}</td>
                          </tr>
                          <tr>
                            <td>{hostDescriptionLocale[currentLocale].summary.ip}</td>
                            <td>{ip || '-'}</td>
                          </tr>
                        </>
                      )}
                      {assetType === 'device' && (
                        <>
                          <tr>
                            <td>
                              <span>{hostDescriptionLocale[currentLocale].summary.type}</span>
                            </td>
                            <td>{deviceType || '-'}</td>
                          </tr>
                          <tr>
                            <td>
                              <span>OS</span>
                            </td>
                            <td className="device-os" title={system}>
                              {system || '-'}
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                )}
                <table className="column">
                  <tbody>
                    {assetType === 'host' && (
                      <tr style={{ textTransform: 'capitalize' }}>
                        <td>{hostDescriptionLocale[currentLocale].summary.system}</td>
                        <td>{system || '-'}</td>
                      </tr>
                    )}
                    {assetType === 'device' && (
                      <tr>
                        <td>{hostDescriptionLocale[currentLocale].summary.ip}</td>
                        <td>{ip || '-'}</td>
                      </tr>
                    )}
                    <tr>
                      <td>{hostDescriptionLocale[currentLocale].summary.idAsset}</td>
                      <td>{assetId || '-'}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* <h4
                style={{
                  fontSize: '20px',
                  fontWeight: '400',
                  lineHeight: '125%',
                  marginTop: '24px',
                }}
              >
                {hostDescriptionLocale[currentLocale].changes.description}
              </h4> */}
              <TabStrip
                selected={selected}
                onSelect={handleSelect}
                style={{ marginTop: '32px' }}
                // className="diff-tabstrip"
              >
                <TabStripTab title={hostDescriptionLocale[currentLocale].changes.byInventorization}>
                  <DiffByInvTab
                    // setStartDate={setStartDate}
                    // setFinishDate={setFinishDate}
                    onClick={onClick}
                    data={data}
                    isLoading={isLoading || isFetching}
                    checkpoints={checkpointsQuery.data}
                    error={error}
                    localError={localError}
                  />
                </TabStripTab>
                <TabStripTab title={hostDescriptionLocale[currentLocale].changes.byDate}>
                  <DiffByDateTab
                    type="inventorization"
                    startDate={startDate}
                    // setStartDate={setStartDate}
                    finishDate={finishDate}
                    // setFinishDate={setFinishDate}
                    onClick={onClick}
                    data={data}
                    isLoading={isLoading || isFetching}
                    error={error}
                    localError={localError}
                  />
                </TabStripTab>
              </TabStrip>
              {/* <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-end' }}>
                <div className={styles.rangeReg}>
                  <DatePicker
                    value={startDate}
                    format="dd.MM.yyyy HH:mm:ss"
                    focusedDate={startDate || new Date()}
                    name="startAt"
                    onChange={changeStartDate}
                    placeholder={localeNewTask[currentLocale].form.todoActions.specifyTheDate}
                    max={finishDate || new Date()}
                    // max={new Date()}
                    label={hostDescriptionLocale[currentLocale].changes.startDate}
                    required
                    validationMessage={
                      startDate && finishDate && startDate >= finishDate
                        ? hostDescriptionLocale[currentLocale].changes.startDateHigherError
                        : hostDescriptionLocale[currentLocale].changes.invalidDateError
                    }
                  />
                </div>
                <div className={styles.rangeReg}>
                  <DatePicker
                    value={finishDate}
                    format="dd.MM.yyyy HH:mm:ss"
                    focusedDate={finishDate || new Date()}
                    name="finishAt"
                    onChange={changeFinishDate}
                    placeholder={localeNewTask[currentLocale].form.todoActions.specifyTheDate}
                    max={new Date()}
                    label={hostDescriptionLocale[currentLocale].changes.finishDate}
                    validationMessage={
                      hostDescriptionLocale[currentLocale].changes.invalidDateError
                    }
                    required
                  />
                </div>
                <div className="k-form-buttons">
                  <Button className="action" onClick={onClick}>
                    {hostDescriptionLocale[currentLocale].changes.btnShowChanges}
                  </Button>
                </div>
              </div> */}
              {/* {!error && ( */}
              <DifferenceInvResult
                data={data}
                isLoading={isLoading || isFetching}
                error={error}
                localError={localError}
              />
              {/* )} */}
              <div className="k-form-buttons">
                <Button onClick={(): void => setInvOpen(false)}>
                  {hostDescriptionLocale[currentLocale].changes.btnClose}
                </Button>
              </div>
            </FormElement>
          )}
        />
      </ErrorBoundary>
    </Dialog>
  );
}
