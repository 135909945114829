import '../../../../Host/hostComponents/HostDescription.module.scss';
import localeHostDescription from '../../../../../../utils/i18n/assetLocales/hostDescription.json';
import localeImageDescription from '../../../../../../utils/i18n/assetLocales/imageDescription.json';
import type { ContainerRegistryResponse } from '../../../../../../types/__generated/on-premise-solution/api/containerRegistriesResponse.v1';
import { useRegDescriptionRepList } from '../../../../../../hooks/components/useRegDescriptionRepList';

export function TableSRegistryMetaBlock({
  currentLocale,
}: {
  currentLocale: 'ru-RU' | 'en-EN';
}): React.ReactElement {
  const { queryDashboardRegistryId } = useRegDescriptionRepList();

  const { data } = queryDashboardRegistryId;

  return (
    <table className="column">
      <tbody>
        <tr>
          <td>
            <span>{localeImageDescription[currentLocale].summary.createdAt}</span>
          </td>
          <td>{data?.createdAt ? new Date(data?.createdAt).toLocaleString() : '-'}</td>
        </tr>
        <tr>
          <td>
            <span>{localeImageDescription[currentLocale].summary.modifiedAt}</span>
          </td>
          <td>{data?.modifiedAt ? new Date(data?.modifiedAt).toLocaleString() : '-'}</td>
        </tr>
        <tr>
          <td>
            <span>{localeImageDescription[currentLocale].summary.modifiedBy}</span>
          </td>
          <td>{data?.modifiedBy?.source || '-'}</td>
        </tr>
        {/* <tr>
          <td>
            <span>{localeHostDescription[currentLocale].summary.idAsset}</span>
          </td>
          <td>:</td>
          <td>
            <span> {data?.type === 'image' && data?.assetId}</span>
          </td>
        </tr> */}
      </tbody>
    </table>
  );
}
