import { useRef } from 'react';
import type { FilterChangeEvent } from '@progress/kendo-react-data-tools';
import type { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { FILTER_INITIAL } from '../../utils/helpers/constants';
import {
  handleFilteringTest,
  IFilterVal,
  setDataInFilter,
} from '../../utils/filtering-in-table-test';
import type { IPageState } from '../../utils/helpers/types';
import type { IUrlParamsHosts } from '../../utils/helpers/host-list-helper';

export function useFilterHostChange(
  filterValue: React.MutableRefObject<CompositeFilterDescriptor | null>,
  setFilter: (value: React.SetStateAction<CompositeFilterDescriptor>) => void,
  customSearch: string,
  setCustomSearch: (value: React.SetStateAction<string>) => void,
  setFilterVal: React.Dispatch<React.SetStateAction<IFilterVal[]>>,
  filterRef: React.MutableRefObject<CompositeFilterDescriptor>,
  filter: CompositeFilterDescriptor,
  setPage: (value: React.SetStateAction<IPageState>) => void,
  urlParams: IUrlParamsHosts,
): (event: FilterChangeEvent) => void {
  const timer = useRef<NodeJS.Timeout | null>(null);

  const filterCallback = (event: FilterChangeEvent): void => {
    if (
      event.filter.filters.length === 0 ||
      event.filter.filters[0] === undefined ||
      (event.filter.filters.length === 0 && customSearch !== '')
    ) {
      filterValue.current = null;
      setFilter(FILTER_INITIAL);
      setCustomSearch('');
      handleFilteringTest(FILTER_INITIAL, setFilterVal);
      filterRef.current = filter;
    } else if (event.filter.filters.length > 0 && customSearch !== '') {
      if (customSearch !== ' ' && /[a-za-яй_\\-]+/gi.test(customSearch)) {
        filterValue.current = {
          filters: [
            {
              field: 'latestInventorization.hostname',
              operator: 'contains',
              value: customSearch,
            },
          ],
          logic: 'and',
        } as CompositeFilterDescriptor;
        setFilter(filterValue.current);
        setPage({ skip: 0, take: urlParams.page.take ? urlParams.page.take : 15 });
        setCustomSearch('');
      }
      if (
        customSearch !== ' ' &&
        /^[0-9]{1,3}\.*[0-9]{0,3}\.*[0-9]{0,3}\.*[0-9]{0,3}$/gi.test(customSearch)
      ) {
        filterValue.current = {
          filters: [
            {
              field: 'latestInventorization.ip',
              operator: 'contains',
              value: customSearch,
            },
          ],
          logic: 'and',
        } as CompositeFilterDescriptor;
        setFilter(filterValue.current);
        setPage({ skip: 0, take: urlParams.page.take ? urlParams.page.take : 15 });
        setCustomSearch('');
      }
    } else {
      setPage({ skip: 0, take: urlParams.page.take ? urlParams.page.take : 15 });
      // setFilter(event.filter);
      setDataInFilter(event, setFilter, timer, setPage, setFilterVal);
    }
  };

  return filterCallback;
}
