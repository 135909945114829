/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import type { GridCellProps } from '@progress/kendo-react-grid';
import { Button } from '../../../baseElements/Button';
import { handleVectorModal } from '../../../../../utils/helpers/audit-helpers';
import { useAuditModalContext } from '../../../../../hooks/useAuditModalContext';

export function VectorCell(props: GridCellProps): React.ReactElement {
  const { dataItem, field } = props;

  const { setCvssVector, setOpen } = useAuditModalContext();

  const fieldArray = field ? field.split('.') : [];
  let value = dataItem;

  if (!fieldArray) value = null;
  else if (fieldArray.length === 1) value = dataItem[fieldArray[0]];
  else if (fieldArray.length > 1) {
    fieldArray.forEach((f) => {
      value = value ? value[f] : value;
    });
  }

  if (value)
    return (
      <td>
        <Button
          fill="modal"
          onClick={(): void => {
            handleVectorModal(
              value,
              setOpen,
              setCvssVector,
              // setCveName,
            );
          }}
        >
          <span className="underlined">{value}</span>
        </Button>
      </td>
    );

  return <td>-</td>;
}
