/* eslint-disable react/jsx-pascal-case */
// import './vulnModal.scss';
import { useQuery, useQueryClient } from 'react-query';
import { useEffect, useRef, useState } from 'react';
import type { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Filter, FilterChangeEvent } from '@progress/kendo-react-data-tools';
import {
  Grid,
  GridColumn as Column,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import type localeAuditTask from '../../../../utils/i18n/taskLocale/auditTask.json';
import type { EnrichedBulletinResponse } from '../../../../types/__generated/on-premise-solution/api/enrichedBulletinResponse.v1';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getRelatedBulletinsPost } from '../../../../services/bulletins-service';
import { useNotificationContext } from '../../../../hooks/useNotificationContext';
import type { IPageState } from '../../../../utils/helpers/types';
import {
  cvssScoreWidth,
  DATA_CACHE_STALETIME,
  FILTER_INITIAL,
  INITIAL_PAGING_10,
  INITIAL_SORT_HOST,
  PAGEABLE_DATA,
  publishedWidth,
} from '../../../../utils/helpers/constants';
import { handleFilteringTest, IFilterVal } from '../../../../utils/filtering-in-table-test';
import { usePageChange } from '../../../../hooks/components/usePageChange';
import { getUrlVulnRelated } from '../../../../utils/helpers/vuln-related-list-helper';
import { handleError } from '../../../../utils/errors';
import { СommonTableLoader } from '../../tableLoader/CommonTableLoader';
import { handleSort } from '../../../../utils/sorting-in-table';
import type { DashboardBulletinsResponse } from '../../../../types/__generated/on-premise-solution/api/dashboardBulletinsResponse.v1';
import locale from '../../../../utils/i18n/security/vulnerabilities.json';
import { CellSecurityVulnsId } from '../../../features/Security/cells/CellSecurityVulnsId';
import { CellPublished } from '../../../features/Task/taskVmDetails/vmDetailsComponents/cells/CellVulnerabilitiesListPublished';
import { useCustomSearch } from '../../../../hooks/components/useCustomSearch';
import { FILTER_VULNS_RELATED } from '../../../../utils/helpers/constant-serv-filter-task';
import { useFilter } from '../../../../hooks/components/useFilter';
import { CellDescription } from '../../../features/Security/cells/CellDescription';
import { collectSecurityVulns } from '../../../../utils/helpers/security-vulns-helper';
import { ScoreCvssCell } from '../../cells/ScoreCvssCell';
import { Input } from '../../baseElements/Input';
import type { BulletinDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/bulletinDashboardResponse.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

const dataForHooks = {
  name: 'bulletinId',
  componentName: 'VulnRelated',
};

export function VulnModalRelated({
  data,
}: {
  data: BulletinDashboardResponse | undefined;
}): React.ReactElement {
  const urlParams = getUrlVulnRelated(dataForHooks.componentName);

  const { createNotification } = useNotificationContext();

  const queryClient = useQueryClient();

  const [page, setPage] = useState<IPageState>(INITIAL_PAGING_10);

  const [filter, setFilter] = useState<CompositeFilterDescriptor>(FILTER_INITIAL);

  const [filterStatus, setFilterStatus] = useState(false);

  const [sort, setSort] = useState(INITIAL_SORT_HOST);

  const filterRef = useRef<CompositeFilterDescriptor>(filter);

  const [staleTimeVal, setStaleTimeVal] = useState(DATA_CACHE_STALETIME);

  const [filterVal, setFilterVal] = useState<IFilterVal[]>([]);

  const [customSearch, setCustomSearch] = useState('');

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  const getPage = usePageChange(setPage, urlParams, dataForHooks);

  const query = useQuery<DashboardBulletinsResponse, ApiError>(
    ['relatedBulletins', page, filterVal, sort],
    () => getRelatedBulletinsPost(data?.related, page, filterVal, sort),
    {
      enabled: data?.related !== null,
    },
  );

  const debouncedCustomSearch = useCustomSearch(
    setFilterStatus,
    setCustomSearch,
    filter,
    setFilterVal,
    setPage,
    page,
    dataForHooks,
  );

  const filterSearch = useFilter(
    filterValue,
    setFilter,
    customSearch,
    setCustomSearch,
    setFilterVal,
    filterRef,
    filter,
    setPage,
    page,
    dataForHooks,
  );

  useEffect(() => {
    setStaleTimeVal(0);
  }, [sort]);

  useEffect(() => {
    setStaleTimeVal(DATA_CACHE_STALETIME);
  }, [page]);

  useEffect(() => {
    let delaySearch: NodeJS.Timeout;

    if (filter?.filters?.length > 0) {
      filterRef.current = filter;
      delaySearch = setTimeout(() => {
        handleFilteringTest(filterRef.current, setFilterVal);
        setFilterStatus(false);
      }, 300);
    }

    return () => clearTimeout(delaySearch);
  }, [filter]);

  const pageChange = (event: GridPageChangeEvent): void => {
    getPage(event);
  };

  useEffect(() => {
    if (query.error) {
      handleError(query.error, createNotification);
    }
  }, [query.isError, createNotification, query.error]);

  useEffect(() => {
    queryClient.invalidateQueries('relatedBulletins');
  }, [queryClient]);

  const onFilterChange = (event: FilterChangeEvent): void => {
    filterSearch(event);
  };

  const handleCustomSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    debouncedCustomSearch(e);
  };

  const securityVulns = data?.related ? collectSecurityVulns(query.data) : [];

  return (
    <div className="vulns-related">
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <div className="filter-line">
            <Filter
              value={filter}
              onChange={onFilterChange}
              fields={FILTER_VULNS_RELATED[currentLocale]}
            />
            {filter.filters.length === 0 && (
              <Input
                name="customSearch"
                placeholder={locale[currentLocale].customSearchName}
                value={customSearch}
                onChange={(e): void => handleCustomSearch(e)}
              />
            )}
          </div>
        </IntlProvider>
      </LocalizationProvider>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <Grid
            pageable={query?.data?.total && query?.data?.total > 10 ? PAGEABLE_DATA : false}
            sortable
            sort={sort}
            data={securityVulns}
            skip={page.skip}
            take={page.take}
            total={query?.data?.total}
            onPageChange={pageChange}
            onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
            scrollable="none"
          >
            <Column
              field="maxScore"
              filter="text"
              cell={ScoreCvssCell}
              title={locale[currentLocale].vulnsTable.cvssScore}
              width={cvssScoreWidth.sort}
            />
            <Column
              title={locale[currentLocale].vulnsTable.id}
              filter="text"
              field="bulletinId"
              width="180px"
              cell={CellSecurityVulnsId}
            />
            {/* <Column
              // field="actions"
              title={locale[currentLocale].vulnsTable.cwe}
              width="200px"
              cell={CellSecurityCwe}
              sortable={false}
            />
            <Column
              field="vector"
              title={locale[currentLocale].vulnsTable.cvssVector}
              width="200px"
              filter="text"
              cell={CellSecurityVector}
              sortable={false}
            /> */}
            <Column
              title={locale[currentLocale].vulnsTable.description}
              // width="200px"
              cell={CellDescription}
              sortable={false}
            />
            <Column
              title={locale[currentLocale].vulnsTable.published}
              width={publishedWidth.sort}
              filter="text"
              field="date.published"
              cell={CellPublished}
              sortable={false}
            />
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
      <СommonTableLoader queryData={query.isLoading} filteringData={filterStatus} />
      {query.isError && <div className="info alert alert-danger">Error: {query.error.message}</div>}
    </div>
  );
}
