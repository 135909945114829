import type { CompositeFilterDescriptor, State } from '@progress/kendo-data-query';
import type { FilterChangeEvent } from '@progress/kendo-react-data-tools';

export function onFilterChange(
  event: FilterChangeEvent,
  setCustomSearch: (value: React.SetStateAction<string>) => void,
  setDataState: (value: React.SetStateAction<State>) => void,
  dataState: State,
  setFilter: (value: React.SetStateAction<CompositeFilterDescriptor>) => void,
  filterValue: React.MutableRefObject<CompositeFilterDescriptor | null>,
  customSearch: string,
  fieldFindInTable: string,
): void {
  if (event.filter.filters.length === 0) {
    setCustomSearch('');
    setDataState({
      ...dataState,
      filter: event.filter,
      skip: 0,
      take: dataState.take,
    });
    setFilter(event.filter);
  } else if (event.filter.filters.length > 0 && customSearch !== '') {
    filterValue.current = {
      filters: [
        {
          field:
            dataState.filter && 'field' in dataState.filter.filters[0]
              ? dataState.filter?.filters[0].field
              : '',
          operator: 'contains',
          value: customSearch,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setDataState({
      ...dataState,
    });
    setFilter(filterValue.current);
    setCustomSearch('');
  } else if (
    event.filter.filters.length > 0 &&
    'value' in event.filter.filters[0] &&
    !event.filter.filters[0].value
  ) {
    // setDataState({
    //   ...dataState,
    //   filter: event.filter,
    //   skip: 0,
    //   take: dataState.take,
    // });
    setFilter(event.filter);
  } else {
    setDataState({
      ...dataState,
      filter: event.filter,
      skip: 0,
      take: dataState.take,
    });
    setFilter(event.filter);
  }
}

export function handlerCustomSearch(
  e: React.ChangeEvent<HTMLInputElement>,
  setCustomSearch: (value: React.SetStateAction<string>) => void,
  setDataState: (value: React.SetStateAction<State>) => void,
  filterValue: React.MutableRefObject<CompositeFilterDescriptor | null>,
  dataState: State,
  fieldFindInTable: string,
): void {
  setCustomSearch(e.target.value);

  filterValue.current = {
    logic: 'or',
    filters: [
      { field: fieldFindInTable, operator: 'contains', value: e.target.value },
      { field: 'imageNames[0]', operator: 'contains', value: e.target.value },
      { field: 'id', operator: 'contains', value: e.target.value },
      // { field: 'aliases[0]', operator: 'contains', value: e.target.value },
      // { field: 'aliases[1]', operator: 'contains', value: e.target.value },
      // { field: 'aliases[2]', operator: 'contains', value: e.target.value },
      { field: 'aliases', operator: 'contains', value: e.target.value },
    ],
  } as CompositeFilterDescriptor;

  // if (fieldFindInTable === 'latestInventorization') {
  //   filterValue.current = {
  //     logic: 'or',
  //     filters: [
  //       { field: 'latestInventorization.ip', operator: 'contains', value: e.target.value },
  //       { field: 'latestInventorization.hostname', operator: 'contains', value: e.target.value },
  //     ],
  //   } as CompositeFilterDescriptor;
  // }

  // if (fieldFindInTable === 'id') {
  //   filterValue.current = {
  //     logic: 'or',
  //     filters: [
  //       { field: 'id', operator: 'contains', value: e.target.value },
  //       { field: 'aliases[0]', operator: 'contains', value: e.target.value },
  //       { field: 'aliases[1]', operator: 'contains', value: e.target.value },
  //       { field: 'aliases[2]', operator: 'contains', value: e.target.value },
  //     ],
  //   } as CompositeFilterDescriptor;
  // } else {
  //   filterValue.current = {
  //     logic: 'and',
  //     filters: [{ field: fieldFindInTable, operator: 'contains', value: e.target.value }],
  //   } as CompositeFilterDescriptor;
  // }

  setDataState({
    ...dataState,
    filter: filterValue.current,
    skip: 0,
    take: dataState.take,
  });
}

export function handlerInstalledSearch(
  setDataState: (value: React.SetStateAction<State>) => void,
  filterValue: React.MutableRefObject<CompositeFilterDescriptor | null>,
  dataState: State,
  value: string,
  setActiveBtn: React.Dispatch<React.SetStateAction<string>>,
  fieldName: string,
): void {
  if (value === 'all') {
    filterValue.current = null;
  } else {
    filterValue.current = {
      logic: 'or',
      filters: [{ field: fieldName, operator: 'eq', value }],
    } as CompositeFilterDescriptor;
  }

  setActiveBtn(value);

  setDataState({
    ...dataState,
    filter: filterValue.current || undefined,
    skip: 0,
    take: dataState.take,
  });
}
