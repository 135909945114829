import styles from '../HostDescription.module.scss';
import localeHostDescription from '../../../../../utils/i18n/assetLocales/hostDescription.json';
import type { AssetHostDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/assetHostDashboardResponse.v1';
import { CommonTooltip } from '../../../../common/CommonTooltip';

export function TableFHostMetaBlock({
  data,
  currentLocale,
}: {
  data: AssetHostDashboardResponse | undefined;
  currentLocale: 'ru-RU' | 'en-EN';
}): React.ReactElement {
  return (
    <table className="column column-first">
      <tbody>
        <tr>
          <td>
            <span>{localeHostDescription[currentLocale].summary.hostname}</span>
          </td>
          <td>
            {data?.type === 'host' &&
            data.latestInventorization &&
            data?.latestInventorization?.hostname ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span className={styles.hostname_bold} style={{ marginRight: '8px' }}>
                  {data?.latestInventorization?.hostname}
                </span>
                <div>
                  {data.latestAudit?.isRebootPending && (
                    <CommonTooltip icon="reboot">
                      {localeHostDescription[currentLocale].rebootTooltip}
                    </CommonTooltip>
                  )}
                </div>
              </div>
            ) : (
              <span>-</span>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeHostDescription[currentLocale].summary.ip}</span>
          </td>
          <td>
            {data?.type === 'host' &&
            data?.latestInventorization &&
            data?.latestInventorization?.ip ? (
              <span> {data?.latestInventorization?.ip}</span>
            ) : (
              <span>-</span>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeHostDescription[currentLocale].summary.system}</span>
          </td>
          <td>
            {data?.type === 'host' &&
            data?.latestInventorization &&
            data?.latestInventorization?.os ? (
              <span>
                {data?.latestInventorization?.os.name} {data?.latestInventorization?.os.version}
              </span>
            ) : (
              <span>-</span>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeHostDescription[currentLocale].summary.idAsset}</span>
          </td>
          <td>
            <span> {data?.type === 'host' ? data?.assetId : '-'}</span>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
