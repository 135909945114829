import '../../TaskDescription.module.scss';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import type { State, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { useEffect, useRef, useState } from 'react';
import { Filter } from '@progress/kendo-react-data-tools';
import type { ExcelExport } from '@progress/kendo-react-excel-export';
import { NavLink, useParams } from 'react-router-dom';
import { FILTER_INITIAL } from '../../../../../utils/helpers/constants';
import localeAuditTask from '../../../../../utils/i18n/taskLocale/auditTask.json';
import locale from '../../../../../utils/i18n/security/vulnerabilities.json';
import {
  handlerCustomSearch,
  onFilterChange,
} from '../../../../../utils/helpers/local-filter-helpers';
import { useLocalExcelExport } from '../../../../../hooks/components/useLocalExcelExport';
import { FILTER_MISSING_KB } from '../../../../../utils/helpers/constant-local-filter-task';
import { Input } from '../../../../common/baseElements/Input';
import { Button } from '../../../../common/baseElements/Button';
import type { TKbs, TMissingKBsProp } from '../../../../../utils/helpers/types';
import styles from './packageNameModal.module.scss';
import { AddVulnToWLForm } from '../../../WhiteList/form/AddVulnToWLForm';
import { useAssetCreatePropContext } from '../../../../../hooks/useAssetCreatePropContext';
import { MissingKBsTable } from './MissingKBsTable';
import { MissingKBsActions } from './MissingKBsActions';
import { useWhiteListContext } from '../../../../../hooks/useWhiteListContext';
import { AddWhiteListForm } from '../../../WhiteList/form/AddWhiteListForm';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;
export function MissingKBs(props: TMissingKBsProp): React.ReactElement {
  const { data, hostName, missedKbWlData, id } = props;

  const pageLimitGrid: { [key: string]: string } =
    localStorage.getItem('pageLimitGrid') &&
    JSON.parse(localStorage.getItem('pageLimitGrid') || '');

  const initialDataState: State = {
    sort: [{ field: 'maxScore', dir: 'desc' }],
    take: pageLimitGrid && pageLimitGrid.MissingKBs ? Number(pageLimitGrid.MissingKBs) : 10,
    skip: 0,
    filter: FILTER_INITIAL,
  };

  const fieldFindInTable = 'id';

  const [dataState, setDataState] = useState<State>(initialDataState);

  const { assetId } = useParams<{ assetId: string }>();

  const [filter, setFilter] = useState(FILTER_INITIAL);

  const [customSearch, setCustomSearch] = useState('');

  const [isWhiteListTable, setWhiteListTable] = useState(false);

  const [isEnabledWl, setEnabledWl] = useState(false);

  const [kbsDataForSelect, setKbsDataForSelect] = useState<TKbs[] | undefined>(undefined);

  const [kbsDataForSelectWl, setKbsDataForSelectWl] = useState<TKbs[] | undefined>(undefined);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _export = useRef<ExcelExport | null>(null);

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  const refWrapper = useRef<HTMLDivElement>(null);

  const { isAddVulnToWLForm } = useAssetCreatePropContext();

  const { isWhiteListForm } = useWhiteListContext();

  const missedKb =
    data &&
    data.type === 'windows' &&
    data.result.cumulativeData.missedKb &&
    Object.values(data.result.cumulativeData.missedKb);

  const missedKbWl =
    data &&
    data.type === 'windows' &&
    data.result.whitelisted?.cumulativeData.missedKb &&
    Object.values(data.result.whitelisted?.cumulativeData.missedKb);

  const excelExport = useLocalExcelExport(
    dataState,
    _export,
    missedKbWlData || missedKb,
    setDataState,
  );

  // useEffect(() => {
  //   return () => {
  //     setKbsInWl(null);
  //     setMissedKbs(null);
  //   };
  // }, [setKbsInWl, setMissedKbs]);

  useEffect(() => {
    if (!refWrapper.current?.className.includes('kb')) {
      setEnabledWl(true);
    }

    const wlBlock = document.getElementById('idKbs');

    if (wlBlock) {
      wlBlock.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [isWhiteListTable]);

  useEffect(() => {
    if (dataState && missedKb) {
      const kbsDataSliced = missedKb
        .sort((a, b) => Number(b.id) - Number(a.id))
        .slice(dataState.skip, (dataState.skip || 0) + (dataState.take || 0))
        .map((value) => ({ id: value.id, title: value.title }));

      setKbsDataForSelect(kbsDataSliced);
    }
    if (dataState && missedKbWl) {
      const vulnSoftDataSliced = missedKbWl
        .sort((a, b) => Number(b.id) - Number(a.id))
        .slice(dataState.skip, (dataState.skip || 0) + (dataState.take || 0))
        .map((value) => ({ id: value.id, title: value.title }));

      setKbsDataForSelectWl(vulnSoftDataSliced);
    }
  }, [dataState.skip, dataState.take]);

  return (
    <>
      <div
        className={missedKbWlData ? styles.vulnsSoftWhiteList : `kb ${styles.vulnsKb}`}
        ref={refWrapper}
        id={id}
      >
        {data?.type === 'windows' && (missedKbWlData || missedKb) ? (
          <>
            <LocalizationProvider language={currentLocale}>
              <IntlProvider locale={currentLocale.substring(0, 2)}>
                <div className="filter-line">
                  <Filter
                    value={filter}
                    onChange={(event): void =>
                      onFilterChange(
                        event,
                        setCustomSearch,
                        setDataState,
                        dataState,
                        setFilter,
                        filterValue,
                        customSearch,
                        fieldFindInTable,
                      )
                    }
                    fields={FILTER_MISSING_KB[currentLocale]}
                  />
                  {filter.filters.length === 0 && (
                    <Input
                      name="customSearch"
                      placeholder={localeAuditTask[currentLocale].customFilterId}
                      value={customSearch}
                      onChange={(e): void =>
                        handlerCustomSearch(
                          e,
                          setCustomSearch,
                          setDataState,
                          filterValue,
                          dataState,
                          fieldFindInTable,
                        )
                      }
                    />
                  )}
                  {missedKbWl && missedKbWl.length > 0 && !isEnabledWl && (
                    <div className={styles.whiteListGridTitle}>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/attention_yellow.svg`}
                        alt="attention"
                      />
                      <Button
                        fill="dotted"
                        type="button"
                        onClick={(): void => setWhiteListTable(!isWhiteListTable)}
                      >
                        {!isWhiteListTable
                          ? localeAuditTask[currentLocale].vulnsSoft.table.commontitleWl
                          : localeAuditTask[currentLocale].vulnsSoft.table.commontitleWlHide}
                        ({missedKbWl?.length})
                      </Button>
                    </div>
                  )}
                  <Button excel fill="flat" onClick={excelExport}>
                    {localeAuditTask[currentLocale].vulnsSoft.table.exportToExcel}
                  </Button>
                </div>
              </IntlProvider>
            </LocalizationProvider>
            <MissingKBsActions
              isEnabledWl={isEnabledWl}
              hostName={hostName}
              id={id}
              kbsDataForSelect={kbsDataForSelect}
              kbsDataForSelectWl={kbsDataForSelectWl}
            />
            <MissingKBsTable
              hostName={hostName}
              missedKbWlData={missedKbWlData}
              id={id}
              missedKb={missedKb}
              exportRef={_export}
              dataState={dataState}
              setDataState={setDataState}
              pageLimitGrid={pageLimitGrid}
            />
          </>
        ) : (
          <p>{localeAuditTask[currentLocale].isMissedKb.missingKbNotFound}</p>
        )}
      </div>
      {data &&
        data.type === 'windows' &&
        data.result.cumulativeData.missedKb &&
        missedKbWl &&
        missedKbWl.length > 0 &&
        !isEnabledWl && (
          <div className={styles.actionsWLWrap}>
            <div className={styles.whiteListGridTitle}>
              <span className="whitelist-grid-title__text">
                {locale[currentLocale].vulnsTable.titleWl}
              </span>
              <NavLink
                className="dashboard__link"
                to={`/lk/audit/exceptions${
                  assetId
                    ? `?skip=0&limit=15&sortField=createdAt&sortOrder=desc&assetIds[$eq]=${assetId}`
                    : ''
                }`}
              >
                {locale[currentLocale].vulnsTable.titleWlLink}
              </NavLink>{' '}
              <Button
                fill="link"
                type="button"
                onClick={(): void => setWhiteListTable(!isWhiteListTable)}
              >
                {isWhiteListTable
                  ? localeAuditTask[currentLocale].vulnsSoft.table.linkWlHide
                  : localeAuditTask[currentLocale].vulnsSoft.table.linkWlShow}
                {localeAuditTask[currentLocale].vulnsSoft.table.linkWlKb}({missedKbWl?.length})
              </Button>
            </div>
          </div>
        )}
      {isWhiteListTable && (
        <MissingKBs data={data} hostName={hostName} missedKbWlData={missedKbWl} id="idKbs" />
      )}
      {isAddVulnToWLForm && <AddVulnToWLForm title={locale[currentLocale].selectedKbs} />}
      {isWhiteListForm && !isAddVulnToWLForm && <AddWhiteListForm />}
    </>
  );
}
