import type { GridCellProps } from '@progress/kendo-react-grid';
// import type { EncrichedAssetGroupDashboardItem } from '../../../types/__generated/on-premise-solution/api/assetGroupsDashboardResponse.v1';
import type { EnrichedAssetGroupResponse } from '../../../types/__generated/on-premise-solution/api/enrichedAssetGroupResponse.v1';

export function CellGroupDescription(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { description }: EnrichedAssetGroupResponse = dataItem;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{description ? <td title={description}>{description}</td> : <td>-</td>}</>;
}
