/* eslint-disable no-nested-ternary */
import '../../TaskDescription.module.scss';
import type { IStatusValues } from '../../../../../utils/helpers/types';
import localeTaskDescription from '../../../../../utils/i18n/taskLocale/taskDescription.json';
import type { TaskExecutionDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/taskExecutionsDashboardResponse.v1';

export function ColumnFirst({
  data,
  cssClasses,
  currentLocale,
}: {
  data: TaskExecutionDashboardResponse;
  cssClasses: string[];
  currentLocale: 'ru-RU' | 'en-EN';
}): React.ReactElement {
  return (
    <table className="column">
      <tbody>
        <tr className="status">
          <td>
            <span>{localeTaskDescription[currentLocale].summary.status}</span>
          </td>
          <td>
            <span className={cssClasses.join(' ')}>
              {
                localeTaskDescription[currentLocale].summary.statusValues[
                  data?.status as keyof IStatusValues
                ]
              }
            </span>
          </td>
        </tr>
        <tr className="start">
          <td>
            <span>{localeTaskDescription[currentLocale].summary.start}</span>
          </td>
          <td className="td-date">
            {data.startedAt ? (
              <span>{new Date(String(data?.startedAt)).toLocaleString()}</span>
            ) : (
              <span> - </span>
            )}
          </td>
        </tr>
        <tr className="finish">
          <td>
            <span>{localeTaskDescription[currentLocale].summary.finish}</span>
          </td>
          <td className="td-date">
            {data.finishedAt ? (
              <span> {new Date(String(data?.finishedAt)).toLocaleString()}</span>
            ) : (
              <span> - </span>
            )}
          </td>
        </tr>
        <tr className="activity">
          <td>
            <span>{localeTaskDescription[currentLocale].summary.activity}</span>
          </td>
          <td className="td-date">
            {data.lastActivity ? (
              <span> {new Date(String(data?.lastActivity)).toLocaleString()}</span>
            ) : (
              <span> - </span>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
