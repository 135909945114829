import { useHostModalContext } from '../useHostModalContext';
import { useTaskContext } from '../useTaskContext';
import localeHostDescription from '../../utils/i18n/assetLocales/hostDescription.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHostDescription;

export function useTaskForGroup(
  name: string | undefined,
  assetGroupId: string,
): (val: string) => void {
  const { setHostForm } = useHostModalContext();

  const { setOpenDialog, setTaskRepeatData } = useTaskContext();

  const createTaskForGroup = (val: string): void => {
    if (val === 'default' && name) {
      setHostForm([
        {
          assetGroupId,
          name,
        },
      ]);
      setTaskRepeatData({
        taskName: `${localeHostDescription[currentLocale].taskForHostGr} ${name}`,
        taskType: 'asset',
        actionI: '',
        actionA: '',
      });
    } else if (val === 'agent_update' && name) {
      setHostForm([
        {
          assetGroupId,
          name,
        },
      ]);
      setTaskRepeatData({
        taskName: `${localeHostDescription[currentLocale].taskForHostGr} ${name}`,
        taskType: 'asset',
        actionI: '',
        actionA: '',
        actionAgentU: val,
      });
    }
    setOpenDialog(true);
  };

  return createTaskForGroup;
}
