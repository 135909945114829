import type { GridCellProps } from '@progress/kendo-react-grid';
import type { IPkgVulns } from '../../../../../../utils/helpers/types';

export function CellDescription(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { description }: IPkgVulns = dataItem;

  return <td title={description}>{description}</td>;
}
