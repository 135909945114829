import { Button } from '@progress/kendo-react-buttons';
import type { GridCellProps } from '@progress/kendo-react-grid';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import type { AssetImageDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/assetsImagesDashboardResponse.v1';

export function Repotags(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;
  const { imageNames, assetId }: AssetImageDashboardResponse = dataItem;

  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <td title={imageNames ? imageNames[0] : ''}>
      {imageNames ? (
        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {imageNames.length > 1 && (
            <Button
              fillMode="flat"
              icon={!isOpen ? 'plus' : 'minus'}
              onClick={(): void => setOpen((prev) => !prev)}
              style={{ marginRight: '8px' }}
            />
          )}
          <NavLink
            // target="_blank"
            to={`/lk/assets/images/${assetId}`}
          >
            {imageNames[0]}
          </NavLink>
          {isOpen && (
            <div style={{ marginLeft: '36px', lineHeight: '2' }}>
              {imageNames.map((name) => <div>{name}</div>).slice(1) || '-'}
            </div>
          )}
        </div>
      ) : (
        '-'
      )}
    </td>
  );
}
