/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-nested-ternary */
import type { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { memo, useCallback, useEffect } from 'react';
import { useHostModalContext } from '../../../../../hooks/useHostModalContext';

function CellSelectHost(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { hostForm, hostFormTemp, setHostFormTemp } = useHostModalContext();

  useEffect(() => {
    if (!hostFormTemp) {
      setHostFormTemp(hostForm);
    }
  }, [hostForm, hostFormTemp, setHostFormTemp]);

  const addAsset = useCallback(
    ({ hostname, assetId }: { hostname: string; assetId: string }): void => {
      if (hostFormTemp && hostFormTemp.length > 0) {
        const index = hostFormTemp?.findIndex((h) => h.assetId === assetId);
        if (index !== -1) {
          const cutHostForm = hostFormTemp.filter((asset) => asset.assetId !== assetId);
          setHostFormTemp([...cutHostForm]);
        } else {
          setHostFormTemp([
            ...hostFormTemp,
            {
              assetId,
              hostname,
            },
          ]);
        }
      } else {
        setHostFormTemp([
          {
            assetId: dataItem.assetId,
            hostname: dataItem.latestInventorization ? dataItem.latestInventorization.hostname : '',
          },
        ]);
      }
    },
    [hostFormTemp, setHostFormTemp, dataItem.assetId, dataItem.latestInventorization],
  );

  return (
    <td className="checkbox_cell">
      {hostFormTemp?.find(
        (h) =>
          dataItem.latestInventorization &&
          dataItem.latestInventorization.hostname &&
          h.hostname === dataItem.latestInventorization.hostname,
      ) ? (
        <Checkbox
          checked={!!hostFormTemp?.find((h) => h.assetId === dataItem.assetId)}
          onClick={(): void =>
            addAsset({
              hostname: dataItem.latestInventorization.hostname,
              assetId: dataItem.assetId,
            })
          }
        />
      ) : dataItem.latestInventorization && dataItem.latestInventorization.hostname ? (
        <Checkbox
          checked={!!hostFormTemp?.find((h) => h.assetId === dataItem.assetId)}
          onClick={(): void =>
            addAsset({
              hostname: dataItem.latestInventorization.hostname,
              assetId: dataItem.assetId,
            })
          }
        />
      ) : dataItem.latestInventorization &&
        !dataItem.latestInventorization.hostname &&
        dataItem.latestInventorization.ip ? (
        <Checkbox
          checked={!!hostFormTemp?.find((h) => h.assetId === dataItem.assetId)}
          onClick={(): void =>
            addAsset({
              hostname: dataItem.latestInventorization.hostname
                ? dataItem.latestInventorization.hostname
                : '',
              assetId: dataItem.assetId,
            })
          }
        />
      ) : (
        dataItem.latestInventorization === null &&
        dataItem.assetId && (
          <Checkbox
            checked={!!hostFormTemp?.find((h) => h.assetId === dataItem.assetId)}
            onClick={(): void =>
              addAsset({
                hostname: '',
                assetId: dataItem.assetId,
              })
            }
          />
        )
      )}
    </td>
  );
}

export default memo(CellSelectHost);
