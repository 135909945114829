import type { IPkgDataModal } from '../../utils/helpers/types';
import { useAssetCreatePropContext } from '../useAssetCreatePropContext';
import { useAuditModalContext } from '../useAuditModalContext';

export function usePkgModal(
  data: IPkgDataModal,
  currentLength: React.MutableRefObject<number | null>,
  setCutValue: (value: React.SetStateAction<number>) => void,
  cutValue: number,
  referenceId: string[],
  setReferenceId: React.Dispatch<React.SetStateAction<string[]>>,
): { onScroll: () => void; closePkgModal: () => void; showReferenceList: (id: string) => void } {
  const { setPkgName } = useAuditModalContext();

  const { setSelectedVulns } = useAssetCreatePropContext();

  const onScroll = (): void => {
    if (data.pkgVulns && currentLength.current) {
      const kDialogContent = document.getElementsByClassName('k-dialog-content');
      const scrollTop = Number(kDialogContent[0].scrollTop);
      const scrollHeight = Number(kDialogContent[0].scrollHeight);
      const clientHeight = Number(kDialogContent[0].clientHeight);

      const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight - 28;

      if (currentLength.current < 10) {
        setCutValue(currentLength.current);
      } else if (scrolledToBottom && currentLength) {
        if (cutValue + 10 < currentLength.current) {
          setCutValue((prevState) => prevState + 10);
        } else {
          setCutValue(currentLength.current);
        }
      }
    }
  };

  const showReferenceList = (id: string): void => {
    const index = referenceId.indexOf(id);
    if (index === -1) {
      setReferenceId([...referenceId, id]);
    } else {
      setReferenceId([...referenceId.filter((r) => r !== id)]);
    }
  };

  const closePkgModal = (): void => {
    setPkgName(null);
    setSelectedVulns(null);
  };

  return { onScroll, closePkgModal, showReferenceList };
}
