/* eslint-disable no-nested-ternary */
import React, { createContext, useState, useMemo, useContext, useEffect, useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import type { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import type { User } from '../types/__generated/on-premise-solution/api/user.v1';
import type {
  IChildrenNodes,
  TEditUserData,
  TServerListData,
  UserModalContent,
} from '../utils/helpers/types';
import type { UsersDashboardResponse } from '../types/__generated/on-premise-solution/api/usersDashboardResponse.v1';
import type { ApiError } from '../types/__generated/on-premise-solution/api/apiError.v1';
import type { UserCreationResponse } from '../types/__generated/on-premise-solution/api/userCreationResponse.v1';
import type {
  UserActiveDirectoryCreationRequest,
  UserCreationRequest,
  UserLdapCreationRequest,
  UserLocalCreationRequest,
} from '../types/__generated/on-premise-solution/api/userCreationRequest.v1';
import { createUser, editUser } from '../services/user-service';
import type { UserUpdateResponse } from '../types/__generated/on-premise-solution/api/userUpdateResponse.v1';
import type {
  UserActiveDirectoryUpdateRequest,
  UserLdapUpdateRequest,
  UserLocalUpdateRequest,
  UserUpdateRequest,
} from '../types/__generated/on-premise-solution/api/userUpdateRequest.v1';
import { useNotificationContext } from './useNotificationContext';
import { handleError } from '../utils/errors';
import type localeErrorMessage from '../utils/i18n/commonLocales/errorMessage.json';
import { useAccountModalContext } from './useAccountModalContext';
import localeUserList from '../utils/i18n/userLocales/userList.json';
import localeLogin from '../utils/i18n/loginLocale/loginForm.json';
import { useAuthContext } from './useAuthContext';
import { getAccountId, getUserData } from '../services/local-storage-service';

export interface IInitialState {
  accountId: string;
  role: string;
  name: string;
  userStatusValues: boolean | undefined;
  login?: string | undefined;
  email?: string | null;
  password?: string | undefined;
  confirmPassword?: string | undefined;
  currentPassword?: string | undefined;
  ldapId?: string | undefined;
  dn?: string | undefined;
  uid?: string | undefined;
  username?: string | undefined;
}

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeErrorMessage;

const UserModalContext = createContext<UserModalContent | null>(null);

export function UserModalProvider({ children }: IChildrenNodes): React.ReactElement {
  const queryClient = useQueryClient();

  const currentUser = getUserData();

  const currentAccountId = getAccountId();

  const titleAccountValues = useMemo(
    () => [
      { name: localeLogin[currentLocale].localAccount, value: 'local', id: '0' },
      { name: localeLogin[currentLocale].ldapAccount, value: 'ldap', id: '1' },
      { name: localeLogin[currentLocale].adAccount, value: 'activedirectory', id: '2' },
    ],
    [],
  );

  const userStatusValues = useMemo(
    () => [
      { text: currentLocale === 'ru-RU' ? 'Активный' : 'Active', val: false },
      { text: currentLocale === 'ru-RU' ? 'Не активный' : 'Not active', val: true },
    ],
    [],
  );

  const [name, setName] = useState<string>('');

  const [login, setLogin] = useState<string | undefined>('');

  const [username, setUsername] = useState<string>('');

  const [dn, setDn] = useState<string>('');

  const [uid, setUid] = useState<string>('');

  const [role, setRole] = useState<string>('');

  const [email, setEmail] = useState<string | null>(null);

  const [password, setPassword] = useState<string | undefined>(undefined);

  const [confirmPassword, setConfirmPassword] = useState<string | undefined>(undefined);

  const [currentPassword, setCurrentPassword] = useState<string | undefined>(undefined);

  const [isTfa, setTfa] = useState<boolean | undefined>(undefined);

  // const [tfaData, setTfaData] = useState<{ isEnabled: boolean; isForced: boolean } | null>(null);

  const [isOpenDialog, setOpenDialog] = useState(false);

  const [editUserData, setEditUserData] = useState<TEditUserData | null>(null);

  const [userTaskEmail, setUserTaskEmail] = useState<Pick<User, 'email' | 'userId'>[] | null>(null);

  const [userTaskEmailTemp, setUserTaskEmailTemp] = useState<
    Pick<User, 'email' | 'userId'>[] | null
  >(null);

  const [errorMutation, setErrorMutation] = useState('');

  const [userTaskInputEmail, setUserTaskInputEmail] = useState<string[] | null>(null);

  const [isAddUserEmail, setAddUserEmail] = useState(false);

  const [userStatus, setUserStatus] = useState<{ text: string; val: boolean }>({
    text: localeUserList[currentLocale].active,
    val: false,
  });

  const [serverListData, setServerListData] = useState<TServerListData>({
    name: localeUserList[currentLocale].modal.placeHolderSrvList,
    ldapId: '000',
  });

  const [initialState, setInitialState] = useState<IInitialState | null>(null);

  const { type, setType } = useAuthContext();

  const { createNotification } = useNotificationContext();

  const { accountId, setAccountId } = useAccountModalContext();

  useEffect(() => {
    setName(editUserData ? editUserData.name : '');
    setLogin(editUserData ? editUserData.login : undefined);
    setRole(editUserData ? editUserData.role : localeUserList[currentLocale].modal.placeholderRole);
    setEmail(editUserData ? editUserData.email : null);
    setAccountId(
      editUserData
        ? { id: editUserData.account.accountId, name: editUserData.account.name }
        : {
            id: '000',
            name: localeUserList[currentLocale].modal.placeholderAccountName,
          },
    );
    setUserStatus(
      editUserData && editUserData.userStatus
        ? { text: editUserData.userStatus.text, val: editUserData.userStatus.val }
        : { text: localeUserList[currentLocale].active, val: false },
    );
    setServerListData(
      editUserData && editUserData.ldapId
        ? { name: '', ldapId: editUserData.ldapId }
        : {
            name: localeUserList[currentLocale].modal.placeHolderSrvList,
            ldapId: '000',
          },
    );
    setTfa(editUserData && editUserData['2fa'] ? editUserData['2fa'].isEnabled : undefined);
    setDn(editUserData && editUserData.dn ? editUserData.dn : '');
    setUid(editUserData && editUserData.uid ? editUserData.uid : '');
    setUsername(editUserData && editUserData.username ? editUserData.username : '');
    if (editUserData) {
      setInitialState({
        accountId: editUserData.account.accountId,
        role: editUserData.role,
        name: editUserData.name,
        userStatusValues: editUserData.userStatus?.val,
        login: editUserData.login,
        email: editUserData.email,
        password,
        confirmPassword,
        currentPassword,
        ldapId: editUserData.ldapId,
        dn: editUserData.dn,
        uid: editUserData.uid,
        username: editUserData.username,
      });
    }
  }, [editUserData]);

  const handleClose = useCallback((): void => {
    setEditUserData(null);
    setOpenDialog(false);
    setRole(localeUserList[currentLocale].modal.placeholderRole);
    setAccountId({
      id: '000',
      name: localeUserList[currentLocale].modal.placeholderAccountName,
    });
    setName('');
    setEmail('');
    setPassword(undefined);
    setConfirmPassword(undefined);
    setTfa(undefined);
    setDn('');
    setUid('');
    setServerListData({
      name: localeUserList[currentLocale].modal.placeHolderSrvList,
      ldapId: '000',
    });
    setType('local');
    setCurrentPassword('');
    setLogin('');
  }, [setAccountId, setType]);

  // const qrLinkQuery = useQuery<TwoFactorQRCodeResponse, ApiError>()

  const mutationNewUser = useMutation<UserCreationResponse, ApiError, UserCreationRequest>(
    (payload) => createUser(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('userList');
        handleClose();
      },
      onError: (resp) => {
        setErrorMutation(resp.message);
      },
    },
  );

  const mutationEditUser = useMutation<
    UserUpdateResponse,
    ApiError,
    { payloadUserId: string; payload: UserUpdateRequest }
  >(({ payloadUserId, payload }) => editUser(payloadUserId, payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('userList');
      handleClose();
    },
    onError: (resp) => {
      setErrorMutation(resp.message);
    },
  });

  useEffect(() => {
    if (mutationEditUser.error) {
      handleError(mutationEditUser.error, createNotification);
    }
  }, [mutationEditUser.isError, mutationEditUser.error, createNotification]);

  useEffect(() => {
    if (mutationNewUser.error) {
      handleError(mutationNewUser.error, createNotification);
    }
  }, [mutationNewUser.isError, mutationNewUser.error, createNotification]);

  const handleSubmit = useCallback((): void => {
    if (editUserData) {
      if (editUserData.type === 'local') {
        const dataEditSubmit = {
          accountId:
            currentUser?.role !== 'super_admin'
              ? currentAccountId
              : initialState?.accountId !== accountId.id
              ? accountId.id
              : undefined,
          name: initialState?.name !== name ? name : undefined,
          login: initialState?.login !== login ? login : undefined,
          email:
            initialState?.email !== email && email
              ? email
              : initialState?.email !== email && !email
              ? null
              : undefined,
          role: initialState?.role !== role ? role : undefined,
          isDisabled:
            currentUser?.role !== 'reader_user' &&
            userStatus.text !== localeUserList[currentLocale].chooseStatus &&
            initialState?.userStatusValues !== userStatus.val
              ? userStatus.val
              : undefined,
          password:
            password === 'tempForExample'
              ? undefined
              : {
                  new: password,
                  current:
                    currentUser?.role === 'user' || currentUser?.role === 'reader_user'
                      ? currentPassword
                      : undefined,
                },
        } as UserLocalUpdateRequest;
        mutationEditUser.mutateAsync({
          payloadUserId: editUserData.userId,
          payload: dataEditSubmit,
        });
      } else if (editUserData && editUserData.type === 'ldap') {
        const dataEditSubmit = {
          accountId:
            currentUser?.role !== 'super_admin'
              ? currentAccountId
              : initialState?.accountId !== accountId.id
              ? accountId.id
              : undefined,
          type: 'ldap',
          name: initialState?.name !== name ? name : undefined,
          ldapId: serverListData.ldapId,
          email:
            initialState?.email !== email && email
              ? email
              : initialState?.email !== email && !email
              ? null
              : undefined,
          role: initialState?.role !== role ? role : undefined,
          uid: initialState?.uid !== uid ? uid : undefined,
          dn: initialState?.dn !== dn ? dn : undefined,
          isDisabled:
            currentUser?.role !== 'reader_user' &&
            currentUser?.role !== 'user' &&
            userStatus.text !== localeUserList[currentLocale].chooseStatus &&
            initialState?.userStatusValues !== userStatus.val
              ? userStatus.val
              : undefined,
        } as UserLdapUpdateRequest;
        mutationEditUser.mutateAsync({
          payloadUserId: editUserData.userId,
          payload: dataEditSubmit,
        });
      } else if (editUserData && editUserData.type === 'activedirectory') {
        const dataEditSubmit = {
          accountId:
            currentUser?.role !== 'super_admin'
              ? currentAccountId
              : initialState?.accountId !== accountId.id
              ? accountId.id
              : undefined,
          type: 'activedirectory',
          name: initialState?.name !== name ? name : undefined,
          ldapId: serverListData.ldapId,
          email:
            initialState?.email !== email && email
              ? email
              : initialState?.email !== email && !email
              ? null
              : undefined,
          role: initialState?.role !== role ? role : undefined,
          username: initialState?.username !== username ? username : undefined,
          isDisabled:
            currentUser?.role !== 'reader_user' &&
            currentUser?.role !== 'user' &&
            userStatus.text !== localeUserList[currentLocale].chooseStatus &&
            initialState?.userStatusValues !== userStatus.val
              ? userStatus.val
              : undefined,
        } as UserActiveDirectoryUpdateRequest;
        mutationEditUser.mutateAsync({
          payloadUserId: editUserData.userId,
          payload: dataEditSubmit,
        });
      }
    } else if (!editUserData && type === 'local') {
      const dataSubmit = {
        accountId: currentUser?.role === 'account_admin' ? currentAccountId : accountId.id,
        role,
        name,
        ldapId: serverListData.ldapId,
        email: email && email !== '' ? email : null,
        login,
        password,
        isDisabled:
          userStatus.text !== localeUserList[currentLocale].chooseStatus
            ? userStatus.val
            : undefined,
      } as UserLocalCreationRequest;
      mutationNewUser.mutateAsync(dataSubmit);
    } else if (!editUserData && type === 'ldap') {
      const dataSubmit = {
        accountId: currentUser?.role === 'account_admin' ? currentAccountId : accountId.id,
        role,
        type,
        name,
        email: email && email !== '' ? email : null,
        ldapId: serverListData.ldapId,
        uid,
        dn,
        isDisabled:
          userStatus.text !== localeUserList[currentLocale].chooseStatus
            ? userStatus.val
            : undefined,
      } as UserLdapCreationRequest;
      mutationNewUser.mutateAsync(dataSubmit);
    } else if (!editUserData && type === 'activedirectory') {
      const dataSubmit = {
        accountId: currentUser?.role === 'account_admin' ? currentAccountId : accountId.id,
        role,
        type,
        name,
        email: email && email !== '' ? email : null,
        ldapId: serverListData.ldapId,
        username,
        isDisabled:
          userStatus.text !== localeUserList[currentLocale].chooseStatus
            ? userStatus.val
            : undefined,
      } as UserActiveDirectoryCreationRequest;
      mutationNewUser.mutateAsync(dataSubmit);
    }
  }, [
    editUserData,
    type,
    currentUser?.role,
    currentAccountId,
    initialState?.accountId,
    initialState?.name,
    initialState?.login,
    initialState?.email,
    initialState?.role,
    initialState?.userStatusValues,
    initialState?.uid,
    initialState?.dn,
    initialState?.username,
    accountId.id,
    name,
    login,
    email,
    role,
    userStatus.text,
    userStatus.val,
    password,
    currentPassword,
    mutationEditUser,
    uid,
    dn,
    username,
    mutationNewUser,
    serverListData.ldapId,
  ]);

  const addAllUserEmail = useCallback((data: UsersDashboardResponse | undefined): void => {
    const selectedAllUsers: Pick<User, 'email' | 'userId'>[] = [];
    data?.data.forEach((u) => {
      selectedAllUsers.push({ email: u.email, userId: u.userId });
    });
    setUserTaskEmailTemp(selectedAllUsers);
  }, []);

  const delAllUserEmail = useCallback((): void => {
    setUserTaskEmailTemp(null);
  }, []);

  const addSelectedUsers = useCallback((): void => {
    setUserTaskEmail(userTaskEmailTemp);
    setAddUserEmail(false);
  }, [userTaskEmailTemp]);

  const changeDropDownRole = useCallback(
    (event: DropDownListChangeEvent): void => {
      setRole(event.target.value);
    },
    [setRole],
  );

  const changeDropDownStatus = useCallback(
    (event: DropDownListChangeEvent): void => {
      if (event.target.value === localeUserList[currentLocale].active) {
        setUserStatus({ text: localeUserList[currentLocale].active, val: false });
      } else {
        setUserStatus({ text: localeUserList[currentLocale].notActive, val: true });
      }
    },
    [setUserStatus],
  );

  const changeDropDownSrvList = useCallback(
    (event: DropDownListChangeEvent): void => {
      setServerListData({ name: event.target.value.name, ldapId: event.target.value.ldapId });
    },
    [setServerListData],
  );

  const changeDropDownUserType = useCallback(
    (event: DropDownListChangeEvent): void => {
      const currentType = event.target.value.value;

      setType(currentType);
    },
    [setType],
  );

  const editUserProfile = useCallback(
    (dataItem: any) => {
      const {
        name: userName,
        email: userEmail,
        role: userRole,
        account,
        userId,
        isDisabled,
        type: userType,
        auth,
      } = dataItem;
      if (dataItem.type === 'local') {
        setPassword('tempForExample');
        setConfirmPassword('tempForExample');
        setEditUserData({
          name: userName,
          email: userEmail,
          login: auth.type === 'local' ? auth.login : undefined,
          role: userRole,
          userId,
          type: userType,
          userStatus: !isDisabled
            ? { text: localeUserList[currentLocale].active, val: false }
            : { text: localeUserList[currentLocale].notActive, val: true },
          account: { name: account.name, accountId: account.accountId },
          '2fa': dataItem['2fa'],
        });
      }
      if (dataItem.type === 'ldap') {
        setType('ldap');
        setEditUserData({
          name: userName,
          email: userEmail,
          role: userRole,
          userId,
          type: userType,
          userStatus: !isDisabled
            ? { text: localeUserList[currentLocale].active, val: false }
            : { text: localeUserList[currentLocale].notActive, val: true },
          account: { name: account.name, accountId: account.accountId },
          ldapId: auth.type === 'ldap' ? auth.ldapId : undefined,
          dn: auth.type === 'ldap' ? auth.dn : undefined,
          uid: auth.type === 'ldap' ? auth.uid : undefined,
          '2fa': dataItem['2fa'],
        });
      }
      if (dataItem.type === 'activedirectory') {
        setType('activedirectory');
        setEditUserData({
          name: userName,
          email: userEmail,
          role: userRole,
          userId,
          type: userType,
          userStatus: !isDisabled
            ? { text: localeUserList[currentLocale].active, val: false }
            : { text: localeUserList[currentLocale].notActive, val: true },
          account: { name: account.name, accountId: account.accountId },
          ldapId: auth.type === 'activedirectory' ? auth.ldapId : undefined,
          username: auth.type === 'activedirectory' ? auth.username : undefined,
          '2fa': dataItem['2fa'],
        });
      }
    },
    [setType],
  );

  const value = useMemo(
    () => ({
      isOpenDialog,
      setOpenDialog,
      userTaskEmail,
      setUserTaskEmail,
      userTaskInputEmail,
      setUserTaskInputEmail,
      isAddUserEmail,
      setAddUserEmail,
      addAllUserEmail,
      delAllUserEmail,
      userTaskEmailTemp,
      setUserTaskEmailTemp,
      addSelectedUsers,
      handleSubmit,
      handleClose,
      editUserData,
      setEditUserData,
      name,
      setName,
      role,
      setRole,
      email,
      setEmail,
      password,
      setPassword,
      isTfa,
      setTfa,
      confirmPassword,
      setConfirmPassword,
      currentPassword,
      setCurrentPassword,
      changeDropDownRole,
      userStatus,
      setUserStatus,
      changeDropDownStatus,
      userStatusValues,
      mutationEditUser,
      dn,
      setDn,
      changeDropDownSrvList,
      serverListData,
      uid,
      setUid,
      type,
      setType,
      username,
      setUsername,
      titleAccountValues,
      changeDropDownUserType,
      login,
      setLogin,
      editUserProfile,
      initialState,
      errorMutation,
      setErrorMutation,
    }),
    [
      isOpenDialog,
      userTaskEmail,
      userTaskInputEmail,
      isAddUserEmail,
      addAllUserEmail,
      delAllUserEmail,
      userTaskEmailTemp,
      addSelectedUsers,
      handleSubmit,
      handleClose,
      editUserData,
      name,
      role,
      email,
      password,
      confirmPassword,
      currentPassword,
      isTfa,
      changeDropDownRole,
      userStatus,
      changeDropDownStatus,
      userStatusValues,
      mutationEditUser,
      dn,
      changeDropDownSrvList,
      serverListData,
      uid,
      type,
      setType,
      username,
      titleAccountValues,
      changeDropDownUserType,
      login,
      editUserProfile,
      initialState,
      errorMutation,
    ],
  );

  return <UserModalContext.Provider value={value}>{children}</UserModalContext.Provider>;
}
export const useUserModalContext = (): UserModalContent => {
  const context = useContext(UserModalContext);
  if (context === null) {
    throw new Error('UserModalContext must be used inside the UserModalContext.Provider.');
  }

  return context;
};
