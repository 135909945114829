import localeUser from '../../../../utils/i18n/userLocales/userList.json';
import { useUserModalContext } from '../../../../hooks/useUserModalContext';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { FormDropDownList } from '../../../common/form/FormDropDownList';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeUser;

export function AccountTypeDropDown(): React.ReactElement {
  const { changeDropDownUserType, titleAccountValues, editUserData } = useUserModalContext();

  const { type } = useAuthContext();

  return (
    <FormDropDownList
      label={localeUser[currentLocale].accountType}
      name="typeOfAccount"
      onChange={changeDropDownUserType}
      data={titleAccountValues}
      textField="name"
      dataItemKey="id"
      defaultValue={titleAccountValues.find((t) => t.value === type)}
      id="titleAccount"
      disabled={!!editUserData}
    />
  );
}
