import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Grid, GridColumn as Column, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { ErrorBoundary } from 'react-error-boundary';
import { Filter } from '@progress/kendo-react-data-tools';
import type { IDataHooks } from '../../../utils/helpers/types';
import { getUrlList } from '../../../utils/helpers/getUrl-list-helper';
import { BoundaryErrorComponent } from '../../common/BoundaryErrorComponent';
import { СommonTableLoader } from '../../common/tableLoader/CommonTableLoader';
import { INITIAL_CREATED_AT_SORT, PAGEABLE_DATA } from '../../../utils/helpers/constants';
import { handleSort } from '../../../utils/sorting-in-table';
import localeUser from '../../../utils/i18n/userLocales/user.json';
import { CellSrvListMenu } from './Cells/CellSrvListMenu';
import { useServerList } from '../../../hooks/components/useServerList';
import { CellCommonDate } from '../../common/baseElements/CellCommonDate';
import { Input } from '../../common/baseElements/Input';
import { StandartCell } from '../../common/baseElements/StandartCell';
import { FILTER_SERVER_LIST } from '../../../utils/helpers/constant-serv-filter-host';
import { useServerContext } from '../../../hooks/useServerContext';
import { AddServerForm } from './modals/AddServerForm';
import { Button } from '../../common/baseElements/Button';
import { getUserData } from '../../../services/local-storage-service';
import styles from './AccountList.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeUser;

export function ServerList(): React.ReactElement {
  const currentUser = getUserData();

  const urlParams = getUrlList('ServerList', INITIAL_CREATED_AT_SORT);

  const { isServer, setServer, editServer } = useServerContext();

  const dataForHooks: IDataHooks = {
    name: 'url',
    componentName: 'ServerList',
  };

  const {
    filter,
    onFilterChange,
    sort,
    page,
    total,
    pageChange,
    setSort,
    isLoading,
    isFetching,
    filterStatus,
    isError,
    errorMessage,
    data,
    handleCustomSearch,
    customSearch,
  } = useServerList(urlParams, dataForHooks);

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      <div className="server-list">
        <div className={styles.accountActions}>
          {currentUser?.role !== 'user' && currentUser?.role !== 'reader_user' && (
            <Button
              fill="action"
              onClick={(): void => {
                setServer(true);
              }}
            >
              {localeUser[currentLocale].connectServers.addBtn}
            </Button>
          )}
        </div>
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <div className="filter-line">
              <Filter
                value={filter}
                onChange={onFilterChange}
                fields={FILTER_SERVER_LIST[currentLocale]}
              />
              {filter.filters.length === 0 && (
                <Input
                  className="k-input custom-search"
                  name="customSearch"
                  placeholder={localeUser[currentLocale].connectServers.customSearchSrv}
                  value={customSearch}
                  onChange={(e): void => handleCustomSearch(e)}
                />
              )}
            </div>
          </IntlProvider>
        </LocalizationProvider>
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <Grid
              pageable={total && total > 15 ? PAGEABLE_DATA : false}
              sortable
              sort={sort}
              data={data}
              skip={page.skip}
              take={page.take}
              total={total || 0}
              onPageChange={pageChange}
              onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
              scrollable="none"
              className={total && total < 15 ? 'no-pageable-k-grid' : ''}
            >
              <Column
                field="name"
                title={localeUser[currentLocale].connectServers.grid.name}
                filter="text"
                width="240px"
              />
              <Column
                field="url"
                title={localeUser[currentLocale].connectServers.grid.url}
                filter="text"
              />
              <Column
                field="type"
                title={localeUser[currentLocale].connectServers.grid.type}
                filter="text"
              />
              <Column
                field="baseDN"
                title={localeUser[currentLocale].connectServers.grid.baseDn}
                width="300px"
              />
              <Column
                field="totalUsers"
                title={localeUser[currentLocale].connectServers.grid.totalUsers}
                width="152x"
                className="number"
              />
              <Column
                field="account.name"
                title={localeUser[currentLocale].connectServers.grid.organization}
                width="136px"
              />
              <Column
                cell={CellCommonDate}
                title={localeUser[currentLocale].connectServers.grid.createdAt}
                filter="text"
                field="createdAt"
                width="180px"
              />
              <Column
                field="description"
                title={localeUser[currentLocale].connectServers.grid.description}
                cell={StandartCell}
                className="title"
              />
              <Column cell={CellSrvListMenu} width="40px" />
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
        <СommonTableLoader queryData={filterStatus} filteringData={filterStatus} />{' '}
        {isError && <div className="info alert alert-danger">Error: {errorMessage}</div>}
        {(isServer || editServer) && <AddServerForm />}
      </div>
    </ErrorBoundary>
  );
}
