import type { CvssScores } from '../../types/__generated/on-premise-solution/api/cvssScores.v1';
import locale from '../i18n/dashboardLocale/dashboardLayout.json';

interface ISeries {
  category: string;
  value: number;
  color: string;
}

const currentLocale = (
  window.navigator.language === 'ru-RU' ? window.navigator.language : 'en-EN'
) as keyof typeof locale;

function getColor(colors: string[], label: string | null): string {
  if (!label || label === 'N/a') return '#cccbcb';

  return colors.shift() || '';
}

export function checkDashboardCvss(data: CvssScores | undefined): ISeries[] {
  const colors = ['#12b76a', 'rgb(255, 193, 7)', '#ff6200', '#f20c0c'];

  return (
    data?.map((d) => ({
      category: `${!d.label || d.label === 'N/a' ? locale[currentLocale].nd : d.label} (${
        d.value
      })`,
      value: d.value,
      color: getColor(colors, d.label),
    })) || []
  );
}
