import { DatePickerChangeEvent, DatePicker } from '@progress/kendo-react-dateinputs';
import { useState } from 'react';
import type { DateFilterProps } from '@progress/kendo-react-data-tools/dist/npm/filter/filters/DateFilter';
import './filterCalendar.scss';

export function FilterCalendarLocal(props: DateFilterProps): React.ReactElement {
  const { filter, onFilterChange } = props;

  const [value, setValue] = useState<Date | null>(null);

  const handleChange = (event: DatePickerChangeEvent): void => {
    setValue(event.value);

    const dateString = event.value?.toISOString();

    onFilterChange({
      nextFilter: { ...filter, ...{ value: dateString, text: '' } },
    });
  };

  return (
    <div className="filter-calendar-wrapper">
      <DatePicker
        defaultValue={value}
        value={value}
        format="dd-MMM-yyyy"
        focusedDate={new Date()}
        name="startAt"
        onChange={handleChange}
      />
    </div>
  );
}
