import type { GridCellProps } from '@progress/kendo-react-grid';
import type { TaskExecutionDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/taskExecutionsDashboardResponse.v1';

export function HistoryTime(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { startedAt }: TaskExecutionDashboardResponse = dataItem;

  return (
    <td title={new Date(startedAt).toLocaleString()}>
      <div className="task__timestart">
        {startedAt && <span className="task__time">{new Date(startedAt).toLocaleString()}</span>}
      </div>
    </td>
  );
}
