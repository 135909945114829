import { ReactElement, useEffect, useState } from 'react';
import { Input } from '../baseElements/Input';
import formStyles from './Form.module.scss';
import { InputTooltipForm } from './InputTooltipForm';

interface IInputRenderProps {
  name: string;
  label?: string;
  inputValue: string;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  type: string;
  customValidator?: (
    e: React.FormEvent<HTMLInputElement>,
    setError: (value: React.SetStateAction<string>) => void,
  ) => void;
  className?: string;
  placeholder?: string;
  id: string;
  tooltipText?: string;
  min?: number;
  max?: number;
  required?: boolean;
  autoFocus?: boolean;
  classForLabel?: string;
  disabled?: boolean;
  toolTipActive?: ReactElement;
}
export function FormInput(inputRenderProps: IInputRenderProps): React.ReactElement {
  const {
    name,
    label,
    inputValue,
    type,
    customValidator,
    className,
    onChange,
    placeholder,
    id,
    tooltipText,
    min,
    max,
    required,
    autoFocus,
    classForLabel,
    disabled,
    toolTipActive,
  } = inputRenderProps;

  const [error, setError] = useState<string>('');

  useEffect(() => {
    setError('');
  }, [inputValue]);

  const onBlur = (e: React.FormEvent<HTMLInputElement>): void => {
    if (customValidator) {
      customValidator(e, setError);
    }
  };

  return (
    <div className={className || formStyles.field_wrapper}>
      {/* {isFocused && toolTipActive} */}
      {toolTipActive}
      {tooltipText ? (
        <InputTooltipForm
          tooltipText={tooltipText}
          classForLabel={classForLabel}
          label={label || ''}
          id={id}
        />
      ) : (
        <label className={classForLabel ? `${classForLabel} k-label` : 'k-label'} htmlFor={id}>
          {label}
        </label>
      )}
      <Input
        name={name}
        type={type}
        value={inputValue}
        id={id}
        onChange={onChange}
        onBlur={onBlur}
        maxLength={100}
        className="k-input"
        placeholder={placeholder}
        min={min}
        max={max}
        required={required}
        autoFocus={autoFocus}
        disabled={disabled}
      />
      {error && (
        <div className="error-common-field">
          <span>{error}</span>
        </div>
      )}
    </div>
  );
}
