import type { GridCellProps } from '@progress/kendo-react-grid';
import type localeSettings from '../../../../utils/i18n/settingsLocales/settings.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

export function CellDescription({ dataItem }: GridCellProps): React.ReactElement {
  const description =
    currentLocale === 'ru-RU' ? dataItem['x-ru-description'] : dataItem.description;

  return <td title={description || '-'}>{description || '-'}</td>;
}
