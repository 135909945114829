import type { GridCellProps } from '@progress/kendo-react-grid';
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Button } from '../../../common/baseElements/Button';
import { useUserModalContext } from '../../../../hooks/useUserModalContext';
import { getUserData } from '../../../../services/local-storage-service';
import { deleteUser } from '../../../../services/user-service';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import type { User } from '../../../../types/__generated/on-premise-solution/api/user.v1';
import type { UserDeletionResponse } from '../../../../types/__generated/on-premise-solution/api/userDeletionResponse.v1';
import localeUser from '../../../../utils/i18n/userLocales/userList.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeUser;

export function CellUserMenu({ dataItem }: GridCellProps): React.ReactElement {
  const currentUser = getUserData();

  const { mutationEditUser, editUserProfile } = useUserModalContext();

  const queryClient = useQueryClient();

  const mutation = useMutation<UserDeletionResponse | null, ApiError, User['userId']>(
    (payload) => deleteUser(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('userList');
      },
    },
  );

  const setInActiveUser = (): void => {
    const { userId } = dataItem as User;
    mutation.mutate(userId);
  };

  const setActiveUser = useCallback(() => {
    const { userId } = dataItem;
    const editUserData = {
      isDisabled: false,
    };
    mutationEditUser.mutateAsync({ payloadUserId: userId, payload: editUserData });
  }, [dataItem, mutationEditUser]);

  return (
    <td className="cell_menu">
      <div className="groups__menu">
        <div className="dropdown__container">
          <span className="k-icon k-i-more-vertical" />
          <div className="dropdown__content">
            {((currentUser?.role !== 'user' && currentUser?.role !== 'reader_user') ||
              (currentUser.id === dataItem.userId && dataItem.type === 'local')) && (
              <>
                <Button
                  fill="menu"
                  onClick={(): void => editUserProfile(dataItem)}
                  disabled={
                    currentUser?.role === 'account_admin' && dataItem.role === 'super_admin'
                  }
                >
                  {localeUser[currentLocale].btnEdit}
                </Button>
                {dataItem.isDisabled ? (
                  <Button
                    fill="menu"
                    onClick={setActiveUser}
                    disabled={dataItem.userId === currentUser?.id}
                  >
                    {localeUser[currentLocale].setActiveMenu}
                  </Button>
                ) : (
                  <Button
                    fill="menu"
                    onClick={setInActiveUser}
                    disabled={
                      dataItem.userId === currentUser?.id ||
                      (currentUser?.role === 'account_admin' && dataItem.role === 'super_admin')
                    }
                  >
                    {localeUser[currentLocale].setNotActive}
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </td>
  );
}
