import type { GridCellProps } from '@progress/kendo-react-grid';

export function LayerIdCell(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  return (
    <td title={dataItem.layer_id ? dataItem.layer_id : ''}>
      {dataItem.layer_id ? dataItem.layer_id.split(':')[1].slice(0, 12) : '-' || '-'}
    </td>
  );
}
