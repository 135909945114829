/* eslint-disable no-nested-ternary */
import React, { createContext, useState, useMemo, useContext, useEffect, useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import type { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import type { IChildrenNodes } from '../utils/helpers/types';
import localeUserList from '../utils/i18n/userLocales/userList.json';
import type { LdapCreationResponse } from '../types/__generated/on-premise-solution/api/ldapCreationResponse.v1';
import type { ApiError } from '../types/__generated/on-premise-solution/api/apiError.v1';
import type { LdapCreationRequest } from '../types/__generated/on-premise-solution/api/ldapCreationRequest.v1';
import { createServer, editSrv } from '../services/user-service';
import type {
  Account,
  Ldap,
} from '../types/__generated/on-premise-solution/api/ldapsDashboardResponse.v1';
import type { LdapUpdateRequest } from '../types/__generated/on-premise-solution/api/ldapUpdateRequest.v1';
import type { LdapUpdateResponse } from '../types/__generated/on-premise-solution/api/ldapUpdateResponse.v1';
import { useAccountModalContext } from './useAccountModalContext';
import { getAccountId, getUserData } from '../services/local-storage-service';

export interface IInitialStateSrv {
  accountId?: string;
  type: string;
  name: string;
  url: string;
  baseDN: string;
  description?: string | null;
}
type TEditServer = Pick<
  Ldap,
  'name' | 'description' | 'accountId' | 'baseDN' | 'url' | 'ldapId' | 'type'
> & {
  account: Pick<Account, 'name' | 'accountId'>;
};

export interface IServerContext {
  // accountId: { id: string; name: string };
  // setAccountId(accountId: { id: string; name: string }): void;
  url: string;
  setUrl(url: string): void;
  baseDN: string;
  setBaseDN(baseDN: string): void;
  name: string;
  setName(name: string): void;
  description: string | null;
  setDescription(description: string | null): void;
  isServer: boolean;
  setServer: React.Dispatch<React.SetStateAction<boolean>>;
  editServer: TEditServer | null;
  setEditServer: React.Dispatch<React.SetStateAction<TEditServer | null>>;
  handleClose: () => void;
  handleSubmit: () => void;
  type: 'ldap' | 'activedirectory' | undefined;
  setType(type: 'ldap' | 'activedirectory' | undefined): void;
  changeDropDownType: (event: DropDownListChangeEvent) => void;
  initialState: IInitialStateSrv | null;
  errorMutation: string;
  setErrorMutation: (v: string) => void;
}

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeUserList;

const ServerContext = createContext<IServerContext | null>(null);

export function ServerProvider({ children }: IChildrenNodes): React.ReactElement {
  const currentUser = getUserData();

  const currentAccountId = getAccountId();

  const queryClient = useQueryClient();

  const [url, setUrl] = useState<string>('');

  const [baseDN, setBaseDN] = useState<string>('');

  const [name, setName] = useState<string>('');

  const [type, setType] = useState<'ldap' | 'activedirectory' | undefined>(undefined);

  const [description, setDescription] = useState<string | null>('');

  const [isServer, setServer] = useState(false);

  const [editServer, setEditServer] = useState<TEditServer | null>(null);

  const [initialState, setInitialState] = useState<IInitialStateSrv | null>(null);

  const [errorMutation, setErrorMutation] = useState('');

  const { accountId, setAccountId } = useAccountModalContext();

  useEffect(() => {
    setName(editServer ? editServer.name : '');
    setUrl(editServer ? editServer.url : '');
    setBaseDN(editServer ? editServer.baseDN : '');
    setAccountId(
      editServer
        ? { id: editServer.account.accountId, name: editServer.account.name }
        : {
            id: '000',
            name: localeUserList[currentLocale].modal.placeholderAccountName,
          },
    );
    setDescription(editServer ? editServer.description : null);
    setType(editServer ? editServer.type : undefined);
    if (editServer) {
      setInitialState({
        accountId: editServer.accountId,
        type: editServer.type,
        name: editServer.name,
        url: editServer.url,
        baseDN: editServer.baseDN,
        description: editServer.description,
      });
    }
  }, [editServer, setAccountId]);

  const mutationNewServer = useMutation<LdapCreationResponse, ApiError, LdapCreationRequest>(
    (payload) => createServer(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('ServerList');
        setUrl('');
        setBaseDN('');
        setAccountId({
          id: '000',
          name: localeUserList[currentLocale].modal.placeholderAccountId,
        });
        setName('');
        setDescription(null);
        setServer(false);
        setType(undefined);
      },
      onError: (resp) => {
        setErrorMutation(resp.message);
      },
    },
  );

  const mutationEditServer = useMutation<
    LdapUpdateResponse,
    ApiError,
    { ldapId: string; payload: LdapUpdateRequest }
  >(({ ldapId, payload }) => editSrv(ldapId, payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('ServerList');
      setUrl('');
      setBaseDN('');
      setAccountId({
        id: '000',
        name: localeUserList[currentLocale].modal.placeholderAccountId,
      });
      setName('');
      setDescription(null);
      setEditServer(null);
      setServer(false);
    },
    onError: (resp) => {
      setErrorMutation(resp.message);
    },
  });

  const handleClose = (): void => {
    setServer(false);
    setEditServer(null);
    setName('');
    setDescription('');
  };

  const changeDropDownType = useCallback(
    (event: DropDownListChangeEvent): void => {
      setType(event.target.value);
    },
    [setType],
  );

  const handleSubmit = useCallback((): void => {
    const dataForm = {
      accountId: currentUser?.role === 'super_admin' ? accountId.id : currentAccountId,
      url,
      baseDN,
      name,
      type,
      description: description || null,
      tls: null,
    } as LdapCreationRequest;
    if (editServer) {
      mutationEditServer.mutate({
        ldapId: editServer.ldapId,
        payload: {
          url: initialState?.url !== url ? url : undefined,
          baseDN: editServer?.baseDN !== baseDN ? baseDN : undefined,
          name: initialState?.name !== name ? name : undefined,
          type: initialState?.type !== type ? type : undefined,
          description:
            description && description.length > 0 && initialState?.description !== description
              ? description
              : description?.length === 0
              ? null
              : undefined,
          tls: null,
        },
      });
    } else {
      mutationNewServer.mutate(dataForm);
    }
  }, [
    accountId.id,
    baseDN,
    currentAccountId,
    currentUser?.role,
    description,
    editServer,
    initialState?.description,
    initialState?.name,
    initialState?.type,
    initialState?.url,
    mutationEditServer,
    mutationNewServer,
    name,
    type,
    url,
  ]);

  const value = useMemo(
    () => ({
      url,
      setUrl,
      baseDN,
      setBaseDN,
      isServer,
      setServer,
      editServer,
      setEditServer,
      description,
      setDescription,
      name,
      setName,
      handleClose,
      handleSubmit,
      type,
      setType,
      changeDropDownType,
      initialState,
      errorMutation,
      setErrorMutation,
    }),
    [
      url,
      baseDN,
      isServer,
      editServer,
      description,
      name,
      handleSubmit,
      type,
      changeDropDownType,
      initialState,
      errorMutation,
    ],
  );

  return <ServerContext.Provider value={value}>{children}</ServerContext.Provider>;
}
export const useServerContext = (): IServerContext => {
  const context = useContext(ServerContext);
  if (context === null) {
    throw new Error('ServerContext must be used inside the ServerContext.Provider.');
  }

  return context;
};
