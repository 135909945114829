import { NavLink } from 'react-router-dom';
import { Menu, MenuItem } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import localeHostDescription from '../../../../utils/i18n/assetLocales/hostDescription.json';
import localeITask from '../../../../utils/i18n/taskLocale/inventorizationTask.json';
import type { InventorizationResponse } from '../../../../types/__generated/on-premise-solution/api/inventorizationResponse.v1';
import { useConvertToJson } from '../../../../hooks/components/useConvertToJson';
import { useCreateTask } from '../../../../hooks/components/useCreateTask';
import { CommonTooltip } from '../../../common/CommonTooltip';
import { useDifferenceModalContext } from '../../../../hooks/useDifferenceModalContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHostDescription;
export function DeviceInvMetaBlock({
  invData,
  inventorizationId,
}: {
  invData: InventorizationResponse | undefined;
  inventorizationId: string | null | undefined;
}): React.ReactElement {
  const handle2Json = useConvertToJson(
    invData,
    (invData?.type === 'linux' ||
      invData?.type === 'windows' ||
      invData?.type === 'firewall' ||
      invData?.type === 'router' ||
      invData?.type === 'switch' ||
      invData?.type === 'hypervisor') &&
      invData?.result.system_info?.hostname,
  );
  const { createTaskForAsset } = useCreateTask();
  const { setInvOpen: setDifferenceModalOpen, assetType } = useDifferenceModalContext();

  return (
    <>
      {invData?.class === 'minimal' && (
        <div className="partial-inv-info">
          <span>{localeHostDescription[currentLocale].partialInvInfo}</span>
          <Button
            // icon="plus"
            fillMode="flat"
            onClick={(): void => {
              createTaskForAsset({
                val: 'inventorization',
                data: invData,
                assetId: invData.assetId,
              });
            }}
          >
            {localeHostDescription[currentLocale].btnCreateTask}
          </Button>
        </div>
      )}
      <div className="tab-meta-block">
        <table className="column-first">
          <tbody>
            <tr>
              <td>
                <span>{localeHostDescription[currentLocale].inventorizationDate}</span>
              </td>
              <td>:</td>
              <td style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                {
                  // Check createdBy.source === 'user' for get correct inventorizationId
                  inventorizationId && invData?.createdBy.source === 'user' ? (
                    <NavLink to={`/lk/task/actions/${inventorizationId}/inventorization`}>
                      {new Date(String(invData?.createdAt)).toLocaleString()}
                    </NavLink>
                  ) : (
                    <span>{new Date(String(invData?.createdAt)).toLocaleString()}</span>
                  )
                }
                <Button
                  onClick={(): void => setDifferenceModalOpen(true)}
                  style={{ display: 'flex', padding: '4px 8px' }}
                >
                  <span
                    style={{ background: '#424242', maskRepeat: 'no-repeat' }}
                    className="k-icon scales-icon"
                  />
                </Button>
                <CommonTooltip>
                  {`${localeHostDescription[currentLocale].changes.show} ${localeHostDescription[
                    currentLocale
                  ].changes.invModalTitle.toLocaleLowerCase()} ${
                    assetType ? localeHostDescription[currentLocale].changes[assetType] : ''
                  }`}
                </CommonTooltip>
              </td>
            </tr>
            <tr>
              <td>
                <span>{localeHostDescription[currentLocale].nextInventorization}</span>
              </td>
              <td>:</td>
              <td className="pd-0">n/d</td>
            </tr>
          </tbody>
        </table>
        <div className="json-actions">
          <Menu hoverOpenDelay={0} onSelect={handle2Json}>
            <MenuItem text={localeITask[currentLocale].techDetails}>
              <MenuItem text={localeITask[currentLocale].auditResult} />
            </MenuItem>
          </Menu>
        </div>
      </div>
    </>
  );
}
