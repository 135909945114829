import type { TEditUserData } from '../../../../utils/helpers/types';
import localeUser from '../../../../utils/i18n/userLocales/userList.json';
import styles from './AddUserForm.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeUser;

export function IdInfo({
  editUserData,
}: {
  editUserData: TEditUserData | null;
}): React.ReactElement {
  return (
    <div className={styles.idInfoWrap}>
      <div className={styles.idInfoContent}>
        <span className={styles.idInfoTitle}>{localeUser[currentLocale].userId}</span>
        <span className={styles.idInfoValue}>{editUserData?.userId}</span>
      </div>
      <div className={styles.idInfoContent}>
        <span className={styles.idInfoTitle}>{localeUser[currentLocale].accountId}</span>
        <span className={styles.idInfoValue}>{editUserData?.account.accountId}</span>
      </div>
    </div>
  );
}
