import { nanoid } from 'nanoid';
import { Button } from '../baseElements/Button';
import localeNewTask from '../../../utils/i18n/taskLocale/newTask.json';
import { useAssetCreatePropContext } from '../../../hooks/useAssetCreatePropContext';
import styles from '../../features/Task/taskVmDetails/vmDetailsComponents/packageNameModal.module.scss';
import { useHostModalContext } from '../../../hooks/useHostModalContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;

export function BulletinMutableBlock({
  title,
}: {
  title?: string | undefined;
}): React.ReactElement {
  const { selectedVulns, delFromTheSelectedVuln } = useAssetCreatePropContext();

  const { missedKbs, deleteKbFromSelectedKbs } = useHostModalContext();

  return (
    <div className={styles.bulletinMutableBlockWrap}>
      {((missedKbs && missedKbs.length > 0) || (selectedVulns && selectedVulns.length > 0)) && (
        <div className="title-apply-assets">
          <span>{!title ? localeNewTask[currentLocale].selectedVulns : title}</span>
        </div>
      )}
      {!missedKbs && (
        <div className={styles.bulletinMutableBlockList}>
          {selectedVulns?.map((b) => {
            if (b.hostname || b.vulnId) {
              return (
                <Button
                  type="button"
                  fill="task_asset"
                  onClick={(e): void => delFromTheSelectedVuln(b.vulnId!, e)}
                  key={nanoid()}
                >
                  <span>{b.vulnId}</span>
                </Button>
              );
            }

            return null;
          })}
          {(selectedVulns?.length === 0 ||
            selectedVulns === null ||
            !selectedVulns.find((b) => b.vulnId)) && (
            <span className="placeholder">
              {!title
                ? localeNewTask[currentLocale].form.applyForAsset.bulletinPlaceholder
                : localeNewTask[currentLocale].form.applyForAsset.kbPlaceholder}
            </span>
          )}
        </div>
      )}
      {!selectedVulns && missedKbs && missedKbs.length > 0 && (
        <div className={styles.bulletinMutableBlockList}>
          {missedKbs.map((kb) => {
            if (kb.id) {
              return (
                <Button
                  type="button"
                  fill="task_asset"
                  onClick={(e): void => deleteKbFromSelectedKbs(kb.id, e)}
                  key={nanoid()}
                >
                  <span>{kb.id}</span>
                </Button>
              );
            }

            return null;
          })}
        </div>
      )}
      {!selectedVulns &&
        ((missedKbs && missedKbs.length === 0) ||
          !missedKbs ||
          missedKbs === null ||
          !missedKbs.find((kb) => kb.id)) && (
          <div className={styles.bulletinMutableBlockList}>
            <span className="placeholder">
              {!title
                ? localeNewTask[currentLocale].form.applyForAsset.bulletinPlaceholder
                : localeNewTask[currentLocale].form.applyForAsset.kbPlaceholder}
            </span>
          </div>
        )}
    </div>
  );
}
