/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-nested-ternary */
import type { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useWhiteListContext } from '../../../../hooks/useWhiteListContext';
import { getUserData } from '../../../../services/local-storage-service';

export function CellSelectBulletin(props: GridCellProps): React.ReactElement {
  const currentUser = getUserData();

  const { dataItem } = props;

  const { bulletinFormTemp, setBulletinFormTemp } = useWhiteListContext();

  const addBulletinId = (id: string): void => {
    const bulletin = {
      bulletinId: id,
      addedBy: {
        source: 'user' as const,
        id: currentUser?.id || '',
      },
      addedAt: new Date().toISOString(),
    };
    if (!bulletinFormTemp || bulletinFormTemp.length === 0) {
      setBulletinFormTemp([bulletin]);
    }
    if (bulletinFormTemp && bulletinFormTemp.length > 0) {
      const index = bulletinFormTemp.findIndex((b) => b.bulletinId === id);
      if (index !== -1) {
        const cutBulletinWhiteList = bulletinFormTemp.filter((b) => b.bulletinId !== id);
        setBulletinFormTemp(cutBulletinWhiteList);
      } else {
        setBulletinFormTemp([...bulletinFormTemp, bulletin]);
      }
    }
  };

  return (
    <td className="checkbox_cell">
      <Checkbox
        checked={!!bulletinFormTemp?.find((b) => b.bulletinId === dataItem.bulletinId)}
        onClick={(): void => addBulletinId(dataItem.bulletinId)}
      />
    </td>
  );
}
