import type { SortDescriptor } from '@progress/kendo-data-query';
import { INITIAL_SORT, INITIAL_PAGING } from './constants';
import type { IPageState } from './types';
import type {
  TaskHostResponse,
  TaskReportResponse,
} from '../../types/__generated/on-premise-solution/api/tasksResponse.v1';

export interface IFilterUrlDataTaskList {
  name24eq?: string;
  name24ne?: string;
  name24regex?: string;
  name24not24regex?: string;
  status24eq?: string;
  startedAt24gt?: string;
  startedAt24gte?: string;
  startedAt24ne?: string;
  startedAt24lt?: string;

  startedAt24lte?: string;
}
export interface IUrlParamsTaskList {
  sort: SortDescriptor[];
  page: IPageState;
  pageLimitGrid: { [key: string]: string } | null;
  filterUrlData: IFilterUrlDataTaskList;
}

export function getUrlTaskList(pageLimitGridKey: string): IUrlParamsTaskList {
  const pageLimitGrid: IUrlParamsTaskList['pageLimitGrid'] =
    localStorage.getItem('pageLimitGrid') &&
    JSON.parse(localStorage.getItem('pageLimitGrid') || '');

  const locationUrlData: { [name: string]: string } | null = {};
  if (document.location.search) {
    const locationSearch = document.location.search.slice(1).split('&');
    locationSearch.forEach((val) => {
      const value = val.split('=');
      if (value[0] === 'name[%24eq]' || value[0] === 'name[$eq]') {
        locationUrlData.name24eq = decodeURI(value[1]) || '';
      }
      if (value[0] === 'name[%24ne]' || value[0] === 'name[$ne]') {
        locationUrlData.name24ne = decodeURI(value[1]) || '';
      }
      if (value[0] === 'name[%24regex]' || value[0] === 'name[$regex]') {
        locationUrlData.name24regex = decodeURI(value[1]) || '';
      }
      if (value[0] === 'name[%24not][%24regex]' || value[0] === 'name[$not]') {
        locationUrlData.name24not24regex = decodeURI(value[1]) || '';
      }
      if (value[0] === 'startedAt[%24gte]' || value[0] === 'startedAt[$gte]') {
        locationUrlData.startedAt24gt = decodeURI(value[1]) || '';
      }
      if (value[0] === 'startedAt[%24gt]' || value[0] === 'startedAt[$gt]') {
        locationUrlData.startedAt24gt = decodeURI(value[1]) || '';
      }
      locationUrlData[value[0]] = value[1] || '';
    });
  }

  const { sortField, sortOrder, skip } = locationUrlData;
  const sort: SortDescriptor[] =
    sortField && sortOrder && (sortOrder === 'asc' || sortOrder === 'desc')
      ? [{ field: sortField, dir: sortOrder }]
      : INITIAL_SORT;

  const page: IPageState = {
    skip: Number(skip) || INITIAL_PAGING.skip,
    take:
      pageLimitGrid && Number(pageLimitGrid[pageLimitGridKey])
        ? Number(pageLimitGrid[pageLimitGridKey])
        : INITIAL_PAGING.take,
  };

  const filterUrlData = {} as IFilterUrlDataTaskList;

  if (locationUrlData.name24ne) {
    filterUrlData.name24ne = locationUrlData.name24ne;
  }
  if (locationUrlData.name24eq) {
    filterUrlData.name24eq = locationUrlData.name24eq;
  }
  if (locationUrlData.name24regex) {
    filterUrlData.name24regex = locationUrlData.name24regex;
  }
  if (locationUrlData.name24not24regex) {
    filterUrlData.name24not24regex = locationUrlData.name24not24regex;
  }
  if (locationUrlData.startedAt24gt) {
    filterUrlData.startedAt24gt = locationUrlData.startedAt24gt;
  }
  if (locationUrlData.startedAt24gte) {
    filterUrlData.startedAt24gte = locationUrlData.startedAt24gte;
  }

  return {
    sort,
    page,
    pageLimitGrid,
    filterUrlData,
  };
}
export function createStatusCls(
  status: TaskHostResponse['status'] | TaskReportResponse['status'],
): string[] {
  const cssClasses = [''] as string[];

  if (status === 'processing') {
    cssClasses.push('processing');
  } else if (status === 'waiting') {
    cssClasses.push('waiting');
  } else if (status === 'success') {
    cssClasses.push('success');
  } else if (status === 'fail' || status === 'timeout') {
    cssClasses.push('fail');
  }

  return cssClasses;
}
