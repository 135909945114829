import { useState, useEffect } from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Input } from '../../common/baseElements/Input';
import localeSettings from '../../../utils/i18n/settingsLocales/settings.json';
import type { ConfigResponse } from '../../../types/__generated/on-premise-solution/api/configResponse.v1';
import styles from './Settings.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

export function SystemTab({
  api,
  log,
}: {
  api: ConfigResponse['api'] | undefined;
  log: ConfigResponse['log'];
}): React.ReactElement {
  const [sysLog, setSysLog] = useState<'error' | 'warning' | 'info' | 'verbose' | 'debug'>(
    log?.level ? log.level : 'info',
  );

  const [cache, setCache] = useState<{ value: string; text: string }>(
    api?.use_cache
      ? { value: api.use_cache.toLocaleString(), text: 'Да' }
      : { value: 'false', text: 'Нет' },
  );

  const [debug, setDebug] = useState<{ value: string; text: string }>(
    api?.debug_mode
      ? { value: api.debug_mode.toLocaleString(), text: 'Да' }
      : { value: 'false', text: 'Нет' },
  );

  const [taskTimeout, setTaskTimeout] = useState<string>(
    api?.user_task_timeout ? api.user_task_timeout.toLocaleString() : '0',
  );
  const [tokenExpiration, setTokenExpiration] = useState<string>(
    api?.access_token_expiration ? api?.access_token_expiration : '0',
  );
  const [agentTokenExpiration, setAgentTokenExpiration] = useState<string>(
    api?.agent_access_token_expiration ? api.agent_access_token_expiration : '0',
  );

  const boolList = [
    { text: 'Да', value: 'true' },
    { text: 'Нет', value: 'false' },
  ];

  useEffect(() => {
    if (api?.use_cache) setCache({ value: api.use_cache.toLocaleString(), text: 'Да' });
  }, [api?.use_cache]);

  useEffect(() => {
    if (api?.debug_mode) setDebug({ value: api.debug_mode.toLocaleString(), text: 'Да' });
  }, [api?.debug_mode]);

  useEffect(() => {
    if (api?.user_task_timeout) setTaskTimeout(api.user_task_timeout.toString());
  }, [api?.user_task_timeout]);

  useEffect(() => {
    if (api?.access_token_expiration) setTokenExpiration(api.access_token_expiration);
  }, [api?.access_token_expiration]);

  useEffect(() => {
    if (api?.agent_access_token_expiration)
      setAgentTokenExpiration(api.agent_access_token_expiration);
  }, [api?.agent_access_token_expiration]);

  return (
    <>
      <div className="system">
        <div className={styles.settingInfo}>
          <span>
            {localeSettings[currentLocale].formSystem.settingInfoTitle}{' '}
            <a
              href="https://docs.vulns.io/onpremise/admin-guide.html#%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B3%D1%83%D1%80%D0%B0%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D1%8B%D0%B9-%D1%84%D0%B0%D0%B9%D0%BB-configini"
              target="_blank"
              rel="noreferrer"
            >
              {localeSettings[currentLocale].formSystem.settingInfoLink}
            </a>
          </span>
        </div>
        <div className={styles.setting}>
          <span className={styles.head}>{localeSettings[currentLocale].formSystem.logLevel}</span>
          <DropDownList
            data={['info', 'warning', 'error', 'debug', 'trace']}
            value={sysLog}
            onChange={(e: any): void => setSysLog(e.target.value)}
            disabled
          />
        </div>
        <div className={styles.setting}>
          <span className={styles.head}>{localeSettings[currentLocale].formSystem.useCache}</span>
          <DropDownList
            data={boolList}
            value={cache}
            textField="text"
            onChange={(e: any): void => setCache(e.target.value)}
            disabled
          />
        </div>
        <div className={styles.setting}>
          <span className={styles.head}>{localeSettings[currentLocale].formSystem.debugMode}</span>
          <DropDownList
            data={boolList}
            value={debug}
            textField="text"
            onChange={(e: any): void => setDebug(e.target.value)}
            disabled
          />
        </div>
        <div className={styles.setting}>
          <span className={styles.head}>
            {localeSettings[currentLocale].formSystem.taskTimeout}
          </span>
          <Input
            type="text"
            value={taskTimeout}
            onChange={(e: any): void => setTaskTimeout(e.target.value)}
            disabled
          />
        </div>
        <div className={styles.setting}>
          <span className={styles.head}>
            {localeSettings[currentLocale].formSystem.tokenExpiration}
          </span>
          <Input
            type="text"
            value={tokenExpiration}
            onChange={(e: any): void => setTokenExpiration(e.target.value)}
            disabled
          />
        </div>
        <div className={styles.setting}>
          <span className={styles.head}>
            {localeSettings[currentLocale].formSystem.agentTokenExpiration}
          </span>
          <Input
            type="text"
            value={agentTokenExpiration}
            onChange={(e: any): void => setAgentTokenExpiration(e.target.value)}
            disabled
          />
        </div>
      </div>
      {/* <Button
        onClick={(): void => {
          patchConfig({
            'log.db.system.level': sysLog || null,
            'api.use_cache': cache === 'true',
            'api.debug_mode': debug === 'true',
            'api.user_task_timeout': parseInt(taskTimeout, 10),
            'api.access_token_expiration': tokenExpiration || null,
            'api.agent_access_token_expiration': agentTokenExpiration || null,
          });
        }}
      >
        Save config
      </Button> */}
    </>
  );
}
