/* eslint-disable import/no-cycle */
import axios, { AxiosError, AxiosResponse } from 'axios';
import { JSXElementConstructor, ReactElement, useEffect } from 'react';
import { IAxiosResponse, processingResError } from '../helpers';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { BACKEND_AUTH_URL } from '../../../utils/helpers/constants';
import { useAuthContext } from '../../../hooks/useAuthContext';

export const authInstance = axios.create({
  baseURL: BACKEND_AUTH_URL,
  withCredentials: true,
});

export function AxiosAuthInterceptor({
  children,
}: {
  children: ReactElement<string | JSXElementConstructor<string>>;
}): React.ReactElement {
  const { setAccessToken } = useAuthContext();

  useEffect(() => {
    const resInterceptor = (
      response: AxiosResponse<IAxiosResponse, IAxiosResponse>,
    ): AxiosResponse<IAxiosResponse, IAxiosResponse> => {
      return response;
    };

    const errResInterceptor = async (error: AxiosError<ApiError>): Promise<AxiosError<ApiError>> =>
      processingResError(error, setAccessToken, authInstance);

    const responseInterceptor = authInstance.interceptors.response.use(
      resInterceptor,
      errResInterceptor,
    );

    return () => authInstance.interceptors.response.eject(responseInterceptor);
  }, [setAccessToken]);

  return children;
}
