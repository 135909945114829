import type { GridCellProps } from '@progress/kendo-react-grid';
import { NavLink } from 'react-router-dom';

export function CellLog(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  return (
    <td>
      {dataItem.partialResults && dataItem.partialResults.hasPartialResults && (
        <NavLink to={`/lk/task/actions/${dataItem.taskActionId}/kb_install/logs`}>
          <span className="k-icon k-font-icon k-i-file-bac" />
        </NavLink>
        // <span className="k-icon k-font-icon k-i-file-bac" />
      )}
      {dataItem.status === 'success' &&
        dataItem.partialResults &&
        !dataItem.partialResults.hasPartialResults && (
          <NavLink to={`/lk/task/actions/${dataItem.taskActionId}/kb_install/logs`}>
            <span className="k-icon k-font-icon k-i-file-bac" />
          </NavLink>
          // <span className="k-icon k-font-icon k-i-file-bac" />
        )}
      {dataItem.status === 'processing' &&
        dataItem.partialResults &&
        !dataItem.partialResults.hasPartialResults && <span className="k-i-loader" />}
      {(dataItem.status === 'fail' || dataItem.status === 'timeout') &&
        dataItem.partialResults &&
        !dataItem.partialResults.hasPartialResults && (
          <span className="k-icon k-i-cancel-outline" />
        )}
    </td>
  );
}
