/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-pascal-case */
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '../../../common/baseElements/Button';
import locale from '../../../../utils/i18n/assetLocales/assetDescriptionGroup.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function HostConfirmDelFromGr({
  setDelHostFromGr,
  handleDelAssets,
}: {
  setDelHostFromGr: React.Dispatch<React.SetStateAction<boolean>>;
  handleDelAssets: () => void;
}): React.ReactElement {
  return (
    <Dialog
      title={locale[currentLocale].confirmTitle}
      onClose={(): void => setDelHostFromGr(false)}
      className="modal small"
    >
      <div className="k-form">
        <div className="form-content">
          <p>{locale[currentLocale].confirmText}</p>
        </div>
        <div className="k-form-buttons">
          <Button type="submit" onClick={handleDelAssets}>
            {locale[currentLocale].yes}
          </Button>
          <Button type="button" onClick={(): void => setDelHostFromGr(false)}>
            {locale[currentLocale].no}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
