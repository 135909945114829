import type { SortDescriptor } from '@progress/kendo-data-query';
import { INITIAL_SORT_HOST, INITIAL_PAGING } from './constants';
import type { IPageState } from './types';
import localeHost from '../i18n/assetLocales/hostList.json';

interface IFilterUrlHosts {
  osNameRegex?: string;
  osVersionRegex?: string;
  hostNameEq?: string;
  hostNameNe?: string;
  hostNameRegex?: string;
  bulletinId?: string;
  whitelistedBulletinId?: string;
  tagsName?: string;
}
export interface IUrlParamsHosts {
  sort: SortDescriptor[];
  page: IPageState;
  pageLimitGrid: { [key: string]: string } | null;
  filterUrlData: IFilterUrlHosts;
}
export function getUrlParamsHosts(pageLimitGridKey: string): IUrlParamsHosts {
  const pageLimitGrid: IUrlParamsHosts['pageLimitGrid'] =
    localStorage.getItem('pageLimitGrid') &&
    JSON.parse(localStorage.getItem('pageLimitGrid') || '');

  const locationUrlData: { [name: string]: string } | null = {};
  if (document.location.search) {
    const locationSearch = document.location.search.slice(1).split('&');
    locationSearch.forEach((val) => {
      const value = val.split('=');
      if (
        value[0] === 'latestInventorization.os.name[%24regex]' ||
        value[0] === 'latestInventorization.os.name[$regex]'
      ) {
        locationUrlData.osNameRegex = decodeURI(value[1]) || '';
      }
      if (
        value[0] === 'latestInventorization.os.version' ||
        value[0] === 'latestInventorization.os.version[$regex]'
      ) {
        locationUrlData.osVersionRegex = decodeURI(value[1]) || '';
      }
      if (
        value[0] === 'latestInventorization.hostname' ||
        value[0] === 'latestInventorization.hostname[$eq]'
      ) {
        locationUrlData.hostNameEq = decodeURI(value[1]) || '';
      }
      if (
        value[0] === 'latestInventorization.hostname' ||
        value[0] === 'latestInventorization.hostname[$ne]'
      ) {
        locationUrlData.hostNameNe = decodeURI(value[1]) || '';
      }
      if (
        value[0] === 'latestInventorization.hostname' ||
        value[0] === 'latestInventorization.hostname[$regex]'
      ) {
        locationUrlData.hostNameRegex = decodeURI(value[1]) || '';
      }
      if (value[0] === 'bulletinId[$eq]') {
        locationUrlData.bulletinId = decodeURI(value[1]) || '';
      }
      if (value[0] === 'whitelistedBulletinId[$eq]') {
        locationUrlData.whitelistedBulletinId = decodeURI(value[1]) || '';
      }
      if (value[0] === 'tags.name' || value[0] === 'tags.name[$regex]') {
        locationUrlData.tagsName = decodeURI(value[1]) || '';
      }
      locationUrlData[value[0]] = value[1] || '';
    });
  }

  const { sortField, sortOrder, skip } = locationUrlData;
  const sort: SortDescriptor[] =
    sortField && sortOrder && (sortOrder === 'asc' || sortOrder === 'desc')
      ? [{ field: sortField, dir: sortOrder }]
      : INITIAL_SORT_HOST;

  const page: IPageState = {
    skip: Number(skip) || INITIAL_PAGING.skip,
    take:
      pageLimitGrid && Number(pageLimitGrid[pageLimitGridKey])
        ? Number(pageLimitGrid[pageLimitGridKey])
        : INITIAL_PAGING.take,
  };

  const filterUrlData = {} as IFilterUrlHosts;

  if (locationUrlData.osNameRegex) {
    filterUrlData.osNameRegex = locationUrlData.osNameRegex;
  }
  if (locationUrlData.osVersionRegex) {
    filterUrlData.osVersionRegex = locationUrlData.osVersionRegex;
  }
  if (locationUrlData.hostNameEq) {
    filterUrlData.hostNameEq = locationUrlData.hostNameEq;
  }
  if (locationUrlData.hostNameNe) {
    filterUrlData.hostNameNe = locationUrlData.hostNameNe;
  }
  if (locationUrlData.hostNameRegex) {
    filterUrlData.hostNameRegex = locationUrlData.hostNameRegex;
  }
  if (locationUrlData.bulletinId) {
    filterUrlData.bulletinId = locationUrlData.bulletinId;
  }
  if (locationUrlData.whitelistedBulletinId) {
    filterUrlData.whitelistedBulletinId = locationUrlData.whitelistedBulletinId;
  }
  if (locationUrlData.tagsName) {
    filterUrlData.tagsName = locationUrlData.tagsName;
  }

  return {
    sort,
    page,
    pageLimitGrid,
    filterUrlData,
  };
}

export function prepareStatus(val: string): string {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeHost;

  if (val === 'notActivated') {
    return localeHost[currentLocale].form.agentStatus.notActivated;
  }
  if (val === 'disabled') {
    return localeHost[currentLocale].form.agentStatus.disabled;
  }
  if (val === 'online') {
    return localeHost[currentLocale].form.agentStatus.online;
  }

  if (val === 'offline') {
    return localeHost[currentLocale].form.agentStatus.offline;
  }

  return '';
}
