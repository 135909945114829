import axios, { AxiosError, AxiosResponse } from 'axios';
import { JSXElementConstructor, ReactElement, useEffect } from 'react';
import { IAxiosResponse, processingResError } from '../helpers';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { BACKEND_COMMON } from '../../../utils/helpers/constants';

export const commonInstance = axios.create({
  baseURL: BACKEND_COMMON,
  // withCredentials: true,
});

export function AxiosDashboardInterceptor({
  children,
}: {
  children: ReactElement<string | JSXElementConstructor<string>>;
}): React.ReactElement {
  const { setAccessToken } = useAuthContext();

  useEffect(() => {
    const resInterceptor = (
      response: AxiosResponse<IAxiosResponse, IAxiosResponse>,
    ): AxiosResponse<IAxiosResponse, IAxiosResponse> => {
      return response;
    };

    const errInterceptor = async (error: AxiosError<ApiError>): Promise<AxiosError<ApiError>> =>
      processingResError(error, setAccessToken, commonInstance);

    const interceptor = commonInstance.interceptors.response.use(resInterceptor, errInterceptor);

    return () => commonInstance.interceptors.response.eject(interceptor);
  }, [setAccessToken]);

  return children;
}
