/* eslint-disable react/jsx-pascal-case */
import type { TabStripSelectEventArguments } from '@progress/kendo-react-layout';
import styles from './vulnModal.module.scss';
import localeAuditTask from '../../../../utils/i18n/taskLocale/auditTask.json';
import { handleVectorModal } from '../../../../utils/helpers/audit-helpers';
import { useAuditModalContext } from '../../../../hooks/useAuditModalContext';
import { CvssBlock } from '../../smallBlocks/CvssBlock';
import { CommonTooltip } from '../../CommonTooltip';
import type { BulletinDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/bulletinDashboardResponse.v1';
import { Button } from '../../baseElements/Button';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

export function VulnModalMetaBlock({
  data,
  handleSelect,
}: {
  data: BulletinDashboardResponse | undefined;
  handleSelect: (e: TabStripSelectEventArguments) => void;
}): React.ReactElement {
  const { setCvssVector, setOpen } = useAuditModalContext();

  const maxScore =
    data && 'maxMetric' in data && data.maxMetric && 'cvss' in data.maxMetric
      ? data.maxMetric.cvss.score
      : undefined;

  const maxSource =
    data && 'maxMetric' in data && data.maxMetric && 'source' in data.maxMetric
      ? data.maxMetric.source
      : undefined;

  const maxSeverity =
    data && 'maxMetric' in data && data.maxMetric && 'severity' in data.maxMetric
      ? data.maxMetric.severity
      : undefined;

  return (
    <div className="common-summary-page">
      <table className="column">
        <tr>
          <td>
            <span>CVSS Score</span>
          </td>
          {maxScore ? (
            <td>
              <div className={styles.score_block}>
                <CvssBlock score={maxScore} />{' '}
                {maxSource && <span>({maxSource[0].toUpperCase() + maxSource.slice(1)})</span>}
              </div>
            </td>
          ) : (
            <td>-</td>
          )}
        </tr>
        <tr>
          <td>
            <span>CVSS Severity</span>
          </td>
          {maxSeverity ? <td>{maxSeverity}</td> : <td>-</td>}
        </tr>
        <tr>
          <td>
            <span>Эксплойты</span>
          </td>
          {data && 'exploits' in data && data.exploits ? (
            <td>
              <Button
                onClick={(): void => handleSelect({ selected: 1 })}
                fill="modal"
                className={styles.btnDotted}
              >
                {data.exploits.length}
              </Button>
            </td>
          ) : (
            <td>-</td>
          )}
        </tr>
      </table>
      <table className="column">
        <tbody>
          <tr>
            <td>
              <span>CVSS Vector</span>
            </td>
            {data && 'maxMetric' in data && data.maxMetric && 'cvss' in data.maxMetric ? (
              <td>
                <Button
                  fill="modal"
                  className="cvss-button"
                  onClick={(): void => {
                    if (data && 'maxMetric' in data && data.maxMetric && 'cvss' in data.maxMetric)
                      handleVectorModal(data.maxMetric.cvss.vector, setOpen, setCvssVector);
                  }}
                >
                  <span className="underlined">{data.maxMetric.cvss.vector}</span>
                </Button>
              </td>
            ) : (
              <td>-</td>
            )}
          </tr>
          <tr>
            <td>
              {data &&
              'maxMetric' in data &&
              data.maxMetric &&
              'cwe' in data.maxMetric &&
              data.maxMetric.cwe ? (
                <span>{data.maxMetric.cwe[0].id}</span>
              ) : (
                <span>CWE</span>
              )}
            </td>
            {data &&
            'maxMetric' in data &&
            data.maxMetric &&
            'cwe' in data.maxMetric &&
            data.maxMetric.cwe ? (
              <td
                className={styles.cweName}
                title={data.maxMetric.cwe[0].name ? data.maxMetric.cwe[0].name : ''}
              >
                <span>{data.maxMetric.cwe[0].name}</span>
              </td>
            ) : (
              <td>-</td>
            )}
          </tr>
          <tr>
            <td>
              <span>CISA KEV</span>
            </td>
            {data && 'cisa_kev' in data && data.cisa_kev?.created_at ? (
              <td className={styles.cisaKev}>
                <a target="_blank" href={data.cisa_kev.url} rel="noreferrer">
                  {new Date(data.cisa_kev?.created_at).toLocaleDateString()}{' '}
                </a>
                <div className={styles.vulnsMetricItem}>
                  <CommonTooltip>
                    <div>
                      <span>{localeAuditTask[currentLocale].vulnModal.cisaKev}</span>
                    </div>
                    <div>
                      <span>{localeAuditTask[currentLocale].vulnModal.cisaKev2}</span>
                    </div>
                  </CommonTooltip>
                </div>
              </td>
            ) : (
              <td>-</td>
            )}
          </tr>
        </tbody>
      </table>
      <table className="column">
        <tbody>
          <tr>
            <td>
              <span>EPSS Score</span>
            </td>
            {data && 'epss' in data && data.epss?.score ? (
              <td className={styles.tooltipWrap}>
                <span>{data.epss.score}</span>
                <div className={styles.vulnsMetricItem}>
                  <CommonTooltip
                    hiddenStyle={{
                      fontSize: '10px',
                      marginTop: '-44px',
                      marginLeft: '24px',
                      padding: '8px',
                    }}
                    time={1800}
                  >
                    <div className={styles.vulnsMetricItemFBlocl}>
                      {localeAuditTask[currentLocale].vulnModal.epssScore}
                    </div>
                    {localeAuditTask[currentLocale].vulnModal.epssPercentile}
                  </CommonTooltip>
                </div>
              </td>
            ) : (
              <td className={styles.tooltipWrap}>
                <span>-</span>
                <div className={styles.vulnsMetricItem}>
                  <CommonTooltip
                    hiddenStyle={{
                      fontSize: '10px',
                      marginTop: '-44px',
                      marginLeft: '-28px',
                      padding: '8px',
                    }}
                    time={1800}
                  >
                    <div className={styles.vulnsMetricItemFBlocl}>
                      {localeAuditTask[currentLocale].vulnModal.epssScore}
                    </div>
                    {localeAuditTask[currentLocale].vulnModal.epssPercentile}
                  </CommonTooltip>
                </div>
              </td>
            )}
          </tr>
          <tr>
            <td>
              <span>EPSS Percentile</span>
            </td>
            {data && 'epss' in data && data.epss?.percentile ? (
              <td>
                <span>{data.epss?.percentile}</span>
              </td>
            ) : (
              <td>-</td>
            )}
          </tr>
        </tbody>
      </table>
      <table className="column">
        <tbody>
          <tr>
            <td>
              <span>{localeAuditTask[currentLocale].vulnModal.metaBlock.published}</span>
            </td>
            {data && data.date && 'published' in data.date ? (
              <td>{new Date(String(data?.date.published)).toLocaleString()}</td>
            ) : (
              <td>-</td>
            )}
          </tr>
          <tr>
            <td>
              <span>{localeAuditTask[currentLocale].vulnModal.metaBlock.modified}</span>
            </td>
            {data && data.date && 'modified' in data.date ? (
              <td>{new Date(String(data?.date.modified)).toLocaleString()}</td>
            ) : (
              <td>-</td>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
}
