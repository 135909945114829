import './TaskIDetails.module.scss';
import type { InventorizationResponse } from '../../../../types/__generated/on-premise-solution/api/inventorizationResponse.v1';
import { ServicesLin } from './iDetailsComponents/ServicesLin';
import { ServicesWin } from './iDetailsComponents/ServicesWin';
import locale from '../../../../utils/i18n/assetLocales/hostDescription.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function ServiceIDetails({
  data,
  hostName,
}: {
  data: InventorizationResponse | undefined;
  hostName: string | undefined | null;
}): React.ReactElement {
  return (
    <div className="inventorization-service">
      {data &&
        data.type === 'linux' &&
        data.result.systemd_units &&
        data.result.systemd_units.length > 0 && <ServicesLin data={data} hostName={hostName} />}
      {data &&
        data.type === 'windows' &&
        data.result.services &&
        data.result.services.length > 0 && <ServicesWin data={data} hostName={hostName} />}
      {!data && <p>{locale[currentLocale].noData}</p>}
    </div>
  );
}
