import type { GridCellProps } from '@progress/kendo-react-grid';

export function CellOldVersion(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  return (
    <td title={dataItem.oldVersion ? dataItem.oldVersion : ''}>
      {dataItem.oldVersion ? <span>{dataItem.oldVersion}</span> : <span>-</span>}
    </td>
  );
}
