/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import type { GridHeaderCellProps } from '@progress/kendo-react-grid';
import styles from '../../../TaskList.module.scss';
import kbStyles from '../packageNameModal.module.scss';
import localeTask from '../../../../../../utils/i18n/taskLocale/task.json';
// import { TaskType } from '../common/TaskType';
import globalLocale from '../../../../../../utils/i18n/templates/global.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTask;

export function FstecStatusHeaderCell(props: GridHeaderCellProps): React.ReactElement {
  const { title } = props;

  return (
    <div className={styles.action_header_cell}>
      {title}
      <span className={styles.types_info}>
        <div className={styles.types_info_content} style={{ maxWidth: '260px' }}>
          <span
            style={{
              whiteSpace: 'break-spaces',
              alignItems: 'center',
              textAlign: 'center',
              fontWeight: 'bold',
              marginBottom: '12px',
            }}
          >
            {localeTask[currentLocale].form.fstecStatus.title}
          </span>
          <div
            className={styles.type_info}
            style={{ whiteSpace: 'break-spaces', alignItems: 'center' }}
          >
            <span className="k-icon k-i-check success" style={{ marginRight: '3px' }} />
            {localeTask[currentLocale].form.fstecStatus.true}
          </div>
          <div
            className={styles.type_info}
            style={{ whiteSpace: 'break-spaces', alignItems: 'center' }}
          >
            <span className={`${kbStyles.fstec_warning} k-icon`} style={{ marginRight: '3px' }} />
            {localeTask[currentLocale].form.fstecStatus.warning}
          </div>
          <div
            className={styles.type_info}
            style={{ whiteSpace: 'break-spaces', alignItems: 'center' }}
          >
            <span className="k-icon k-i-close fail" style={{ marginRight: '3px' }} />
            {localeTask[currentLocale].form.fstecStatus.false}
          </div>
          <div
            className={styles.type_info}
            style={{ whiteSpace: 'break-spaces', alignItems: 'center' }}
          >
            <span className={kbStyles.fstec_no_info}>{globalLocale[currentLocale].cvssScore.nd}</span>
            {localeTask[currentLocale].form.fstecStatus.noInfo}
          </div>
        </div>
        <img src={`${process.env.PUBLIC_URL}/images/question.svg`} alt="" />
      </span>
    </div>
  );
}
