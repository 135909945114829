import type { GridCellProps } from '@progress/kendo-react-grid';
import type { AssetImageDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/assetsImagesDashboardResponse.v1';

export function HostsCount({
  dataItem: { assetHostsCount },
}: Omit<GridCellProps, 'dataItem'> & {
  dataItem: AssetImageDashboardResponse;
}): React.ReactElement {
  return <td className="number">{assetHostsCount || '-'}</td>;
}
