/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/jsx-pascal-case */
// import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useEffect, useRef, useState } from 'react';
import { LocalizationProvider, IntlProvider } from '@progress/kendo-react-intl';
import { Filter } from '@progress/kendo-react-data-tools';
import { Typography } from '@progress/kendo-react-common';
import { Button } from '../../common/baseElements/Button';
import localeSettings from '../../../utils/i18n/settingsLocales/settings.json';
import type { IDataHooks } from '../../../utils/helpers/types';
import { getUserData } from '../../../services/local-storage-service';
import { useWhiteListContext } from '../../../hooks/useWhiteListContext';
import { AddWhiteListForm } from './form/AddWhiteListForm';
import { getUrlList } from '../../../utils/helpers/getUrl-list-helper';
import { INITIAL_CREATED_AT_SORT } from '../../../utils/helpers/constants';
import styles from './WhiteListTabEl.module.scss';
import { FILTER_WHITE_LIST } from '../../../utils/helpers/constant-serv-filter-host';
import { Input } from '../../common/baseElements/Input';
import { CommonTooltip } from '../../common/CommonTooltip';
import { useWhiteList } from '../../../hooks/components/useWhiteList';
import { CommonWhiteList } from '../../common/CommonWhiteList';
import { СommonTableLoader } from '../../common/tableLoader/CommonTableLoader';
import { getUrlParamsWl } from '../../../utils/helpers/wl-list-helper';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

const dataForHooks: IDataHooks = {
  name: 'name',
  componentName: 'WhiteList',
  urlPath: '/lk/audit/exceptions',
};
export function WhiteList(): React.ReactElement {
  const currentUser = getUserData();

  const urlParams = getUrlParamsWl('WhiteList');

  const {
    handleRefresh,
    filter,
    onFilterChange,
    sort,
    page,
    total,
    pageChange,
    setSort,
    isLoading,
    filterStatus,
    isError,
    errorMessage,
    data,
    handleCustomSearch,
    customSearch,
  } = useWhiteList(urlParams, dataForHooks);

  const { isWhiteListForm, setWhiteListForm, whitelistUpdate } = useWhiteListContext();

  return (
    <div className="whitelist">
      <div className="common-header-page">
        <div className={styles.whiteListTitle}>
          <Typography.h3>{localeSettings[currentLocale].whiteList.title}</Typography.h3>
          <CommonTooltip
            visibleStyle={{ marginLeft: '12px' }}
            hiddenStyle={{ marginLeft: '48px' }}
            width="24px"
            height="24px"
          >
            {localeSettings[currentLocale].whiteList.titleDescription}
          </CommonTooltip>
        </div>
        <div className="whitelist__actions topactions">
          {currentUser?.role !== 'reader_user' && (
            <Button fill="action" onClick={(): void => setWhiteListForm(true)}>
              {localeSettings[currentLocale].whiteList.addWhiteListBtn}
            </Button>
          )}
          <Button onClick={handleRefresh}>{localeSettings[currentLocale].apiKey.refresh}</Button>
        </div>
      </div>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <div className={styles.whiteListFilterLine}>
            <Filter
              value={filter}
              onChange={onFilterChange}
              fields={FILTER_WHITE_LIST[currentLocale]}
            />
            {filter.filters.length === 0 && (
              <Input
                className="whitelist-filter"
                name="customSearch"
                placeholder={localeSettings[currentLocale].whiteList.grid.listPlaceHolder}
                value={customSearch}
                onChange={(e): void => handleCustomSearch(e)}
              />
            )}
          </div>
        </IntlProvider>
      </LocalizationProvider>
      <CommonWhiteList
        sort={sort}
        data={data}
        page={page}
        total={total}
        pageChange={pageChange}
        setSort={setSort}
        dataForHooks={dataForHooks}
      />
      <СommonTableLoader queryData={isLoading} filteringData={filterStatus} />
      {isError && <div className="info alert alert-danger">Error: {errorMessage}</div>}
      {(isWhiteListForm || whitelistUpdate) && <AddWhiteListForm />}
    </div>
  );
}
