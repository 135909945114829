/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, useState } from 'react';
import { Field } from '@progress/kendo-react-form';
import locale from '../../../../utils/i18n/credentials/credentials.json';
import localeDashboardForm from '../../../../utils/i18n/dashboardLocale/addDashboardForm.json';
import { useCheck } from '../../../../hooks/components/useCheck';
import { useHostModalContext } from '../../../../hooks/useHostModalContext';
import { CheckCredentialInfo } from '../../../common/CheckCredentialInfo';
import { useCommonContext } from '../../../../hooks/useCommonContext';
import styles from '../../Host/forms/AddAssetForm.module.scss';
import { useCredentialContext } from '../../../../hooks/useCredentialContext';
import { Input } from '../../../common/baseElements/Input';
import { FormInput } from '../../../common/form/FormInput';
import { useHostActions } from '../../../../hooks/components/useHostActions';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeDashboardForm;

export function CheckCredentialBlock(): React.ReactElement {
  const { ipValidator } = useCheck();

  const [port, setPort] = useState('');

  const [titlePort, setTitlePort] = useState('');

  const { ipHost, setIpHost, editHostName, hostName } = useHostModalContext();

  const { resultCheckIp, setResultCheckIp } = useCommonContext();

  const { credentialData, typeCred, authType, checkErrorMutation } = useCredentialContext();

  const { editIpHost } = useHostActions();

  useEffect(() => {
    switch (typeCred) {
      case 'winrm':
        setPort('5985');
        setTitlePort(localeDashboardForm[currentLocale].winrmPort);
        break;
      case 'ssh':
        setPort('22');
        setTitlePort(localeDashboardForm[currentLocale].sshPort);
        break;
      case 'snmp':
        setPort('161');
        setTitlePort(localeDashboardForm[currentLocale].snmpPort);
        break;
      default:
        setTitlePort(localeDashboardForm[currentLocale].defaultPort);
        setPort('');
    }
  }, [typeCred]);

  useEffect(() => {
    return () => {
      setResultCheckIp(null);
      // setIpHost('');
    };
  }, []);

  return (
    <>
      {typeCred !== 'container_registry' &&
        typeCred !== 'hashicorp_vault_userpass' &&
        typeCred !== 'hashicorp_vault_token' && (
          <div className={styles.check_credential}>
            <div className={styles.titleCheckCredential}>
              {credentialData ? (
                <span>{locale[currentLocale].form.checkCredential}</span>
              ) : (
                <span>{locale[currentLocale].form.beforeCheck}</span>
              )}
            </div>
            <div className="form-field-wrapper mb16">
              <label
                className={!credentialData ? 'k-label disabled-label' : 'k-label required-label'}
                htmlFor="ipHost"
              >
                {localeDashboardForm[currentLocale].ipTooltip}
              </label>
              <Input
                className="k-input"
                name="ip"
                id="ipHost"
                value={ipHost}
                onChange={(e): void => editIpHost(e.target.value)}
                onBlur={(e): void => ipValidator(e.target.value)}
                disabled={!credentialData}
              />
              {((ipHost && resultCheckIp) || resultCheckIp) && (
                <div className="error-common-field">
                  <span>{resultCheckIp}</span>
                </div>
              )}
            </div>
            <div className="form-field-wrapper">
              <label
                className={!credentialData ? 'k-label disabled-label' : 'k-label'}
                htmlFor="port"
              >
                {titlePort}
              </label>
              <Input
                className="k-input"
                name="port"
                id="port"
                value={port}
                onChange={(e): void => setPort(e.target.value)}
                disabled={!credentialData}
              />
            </div>
            {typeCred === 'winrm' && authType === 'kerberos' && (
              <Field
                name="hostName"
                component={FormInput}
                id="hostName"
                label={localeDashboardForm[currentLocale].hostNameLabel}
                onChange={(e): void => editHostName(e.target.value)}
                inputValue={hostName}
                className="form-field-wrapper"
                type="text"
                classForLabel={!credentialData ? 'disabled-label' : ''}
                tooltipText={localeDashboardForm[currentLocale].hostNameTooltip}
                disabled={!credentialData}
              />
            )}
            <div className="error-common-field">
              <span>{checkErrorMutation}</span>
            </div>
            <CheckCredentialInfo
              ip={ipHost}
              port={Number(port)}
              сredentialId={credentialData?.credentialId}
              hostname={hostName}
              typeOfPort={typeCred}
            />
          </div>
        )}
    </>
  );
}
