import type { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { useRef, useState } from 'react';
import type { IFilterVal } from '../../utils/filtering-in-table-test';
import { FILTER_INITIAL } from '../../utils/helpers/constants';
import type { IPageState } from '../../utils/helpers/types';
import type { IUrlParamsList } from '../../utils/helpers/getUrl-list-helper';

interface ICommonStates {
  customSearch: string;
  setCustomSearch: (value: React.SetStateAction<string>) => void;
  sort: SortDescriptor[];
  setSort: React.Dispatch<React.SetStateAction<SortDescriptor[]>>;
  filter: CompositeFilterDescriptor;
  setFilter: React.Dispatch<React.SetStateAction<CompositeFilterDescriptor>>;
  page: IPageState;
  setPage: React.Dispatch<React.SetStateAction<IPageState>>;
  filterStatus: boolean;
  setFilterStatus: React.Dispatch<React.SetStateAction<boolean>>;
  filterVal: IFilterVal[];
  setFilterVal: React.Dispatch<React.SetStateAction<IFilterVal[]>>;
  filterValue: React.MutableRefObject<CompositeFilterDescriptor | null>;
  filterRef: React.MutableRefObject<CompositeFilterDescriptor>;
}

export function useStatesForList(urlParams: IUrlParamsList): ICommonStates {
  const [customSearch, setCustomSearch] = useState('');

  const [sort, setSort] = useState<SortDescriptor[]>(urlParams.sort);

  const [page, setPage] = useState<IPageState>(urlParams.page);

  const [filter, setFilter] = useState<CompositeFilterDescriptor>(FILTER_INITIAL);

  const [filterStatus, setFilterStatus] = useState(false);

  const [filterVal, setFilterVal] = useState<IFilterVal[]>([]);

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  const filterRef = useRef<CompositeFilterDescriptor>(filter);

  return {
    customSearch,
    setCustomSearch,
    sort,
    setSort,
    page,
    setPage,
    filter,
    setFilter,
    filterStatus,
    setFilterStatus,
    filterVal,
    setFilterVal,
    filterValue,
    filterRef,
  };
}
