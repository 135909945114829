import jwtDecode from 'jwt-decode';
import type { User } from '../types/__generated/on-premise-solution/api/user.v1';
import type { UserLoginResponse } from '../types/__generated/on-premise-solution/api/userLoginResponse.v1';

const REFRESH_KEY = 'rt';
const EXPIRES_KEY = 'at-e-in';
const USER_DATA = 'user';
const ACCOUNT_ID = 'ai';
const ACCOUNT_NAME = 'an';
// const TOKEN_KEY = 'jt';
// const LANGUAGE = 'language';
export type CurrentUser = {
  email: User['email'];
  id: User['userId'];
  name: User['name'];
  role: User['role'];
  '2fa': {
    isEnabled: boolean;
    isForced: boolean;
  };
};
type AccessToken = {
  account: {
    id: string;
    name: string;
  };
  exp: number;
  iat: number;
  sub: string;
  user: CurrentUser;
};

export function setTokens({ refreshToken, accessToken, expiresIn }: UserLoginResponse): void {
  const token: AccessToken = jwtDecode(accessToken);
  const expiresDate = new Date().getTime() + Number(expiresIn) * 1000;
  const { account, user } = token;
  localStorage.setItem(ACCOUNT_ID, account.id);
  localStorage.setItem(ACCOUNT_NAME, account.name);
  if (refreshToken) localStorage.setItem(REFRESH_KEY, refreshToken);
  localStorage.setItem(EXPIRES_KEY, String(expiresDate));
  // localStorage.setItem(TOKEN_KEY, accessToken);
  localStorage.setItem(USER_DATA, JSON.stringify(user));
}
export function setRefreshToken(refreshToken: string): void {
  localStorage.setItem(REFRESH_KEY, refreshToken);
}

// export function setLanguage(language: string): void {
//   localStorage.setItem(LANGUAGE, language);
// }
export function getAccountId(): string | null {
  return localStorage.getItem(ACCOUNT_ID);
}

export function getAccountName(): string | null {
  return localStorage.getItem(ACCOUNT_NAME);
}
export function getRefreshToken(): string | null {
  return localStorage.getItem(REFRESH_KEY);
}
export function getExpiresToken(): string | null {
  return localStorage.getItem(EXPIRES_KEY);
}
// export function getAccessToken(): string | null {
//   return localStorage.getItem(TOKEN_KEY);
// }

export function getUserData(): CurrentUser | null {
  const userString = localStorage.getItem(USER_DATA);
  if (userString) {
    const userData: CurrentUser = JSON.parse(userString);

    return userData;
  }

  return null;
}
// export function getLanguage(): string | null {
//   if (LANGUAGE) {
//     return localStorage.getItem(LANGUAGE);
//   }

//   return 'en-EN';
// }
export function removeAuthData(): void {
  localStorage.removeItem(ACCOUNT_ID);
  localStorage.removeItem(REFRESH_KEY);
  localStorage.removeItem(EXPIRES_KEY);
  // localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USER_DATA);
}

export function clearDashboardSettings(name: string): void {
  if (localStorage.getItem(name)) {
    localStorage.removeItem(name);
    window.location.reload();
  }
}
