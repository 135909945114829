/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect, useRef } from 'react';
import type { GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useQuery, useQueryClient } from 'react-query';
import { Filter, FilterChangeEvent } from '@progress/kendo-react-data-tools';
import type { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import {
  AGENT_REFETCH_INTERVAL,
  DATA_CACHE_STALETIME,
  FILTER_INITIAL,
  INITIAL_PAGING,
  INITIAL_SORT_HOST,
} from '../../../../utils/helpers/constants';
import { useNotificationContext } from '../../../../hooks/useNotificationContext';
import { handleError } from '../../../../utils/errors';
import type { IDashboard, IPageState } from '../../../../utils/helpers/types';
import { getAgentsStatus } from '../../../../services/dashboard-service';
import type { AgentsStatusResponse } from '../../../../types/__generated/on-premise-solution/api/agentsStatusResponse.v1';
import { СommonTableLoader } from '../../tableLoader/CommonTableLoader';
import type localeHost from '../../../../utils/i18n/assetLocales/hostList.json';
import localeNewTask from '../../../../utils/i18n/taskLocale/newTask.json';
import { handleFilteringTest, IFilterVal } from '../../../../utils/filtering-in-table-test';
import { getUrlParamsAddHosts } from '../../../../utils/helpers/add-host-list-helper';
import { usePageChange } from '../../../../hooks/components/usePageChange';
import { useCustomSearchHost } from '../../../../hooks/components/useCustomSearchHost';
import { useFilterHostChange } from '../../../../hooks/components/useFilterHostChange';
import { CommonHostList } from '../../CommonHostList';
import { FILTER_ASSETLIST_WITHOUT_ID } from '../../../../utils/helpers/constant-serv-filter-host';
import { getBulletinAssetsWl } from '../../../../services/bulletins-service';
import { Input } from '../../baseElements/Input';
import styles from './vulnModal.module.scss';
import type {
  AssetsHostItemDashboardResponse,
  AssetsHostsDashboardResponse,
} from '../../../../types/__generated/on-premise-solution/api/assetsHostsDashboardResponse.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHost;

const dataForHooks = {
  name: '',
  componentName: 'VulnModalHostsWl',
};
export function VulnModalHostsWl({
  bulletinId,
}: {
  bulletinId: string | null;
}): React.ReactElement {
  const urlParams = getUrlParamsAddHosts(dataForHooks.componentName);

  const queryClient = useQueryClient();

  const { createNotification } = useNotificationContext();

  const [page, setPage] = useState<IPageState>(INITIAL_PAGING);

  const [filter, setFilter] = useState<CompositeFilterDescriptor>(FILTER_INITIAL);

  const [filterStatus, setFilterStatus] = useState(false);

  const [sort, setSort] = useState(INITIAL_SORT_HOST);

  const filterRef = useRef<CompositeFilterDescriptor>(filter);

  const [staleTimeVal, setStaleTimeVal] = useState(DATA_CACHE_STALETIME);

  const [filterVal, setFilterVal] = useState<IFilterVal[]>([]);

  const [customSearch, setCustomSearch] = useState('');

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  const agentsId = [] as Array<string>;

  const query = useQuery<AssetsHostsDashboardResponse, ApiError>(
    ['bulletinAssetsWl', page, filterVal, sort],
    () => getBulletinAssetsWl(page, filterVal, sort, bulletinId),
  );

  const [dataDashboard, setDataDashboard] = useState<
    IDashboard[] | AssetsHostItemDashboardResponse[] | undefined
  >([]);

  const getPage = usePageChange(setPage, urlParams, dataForHooks);

  const debouncedCustomSearch = useCustomSearchHost(
    setFilterStatus,
    setCustomSearch,
    filter,
    setFilterVal,
    setPage,
    page,
    urlParams,
  );

  const filterSearch = useFilterHostChange(
    filterValue,
    setFilter,
    customSearch,
    setCustomSearch,
    setFilterVal,
    filterRef,
    filter,
    setPage,
    urlParams,
  );

  if (query?.data?.data) {
    query.data.data.forEach((q: any) => {
      if (q.type === 'host' && q.agentId) agentsId.push(q.agentId);
    });
  }
  const agentsQuery = useQuery<AgentsStatusResponse, ApiError>(
    ['/v1/agent/status', agentsId],
    () => getAgentsStatus(agentsId),
    {
      refetchInterval: AGENT_REFETCH_INTERVAL,
    },
  );

  useEffect(() => {
    const dashboardData = query.data?.data.map((q: any) => {
      if (q.type === 'host' && q.agentId) {
        const agentStatus = agentsQuery.data?.find((f) => f.agentId === q.agentId);

        return {
          ...q,
          agentValue: agentStatus,
        };
      }

      return q;
    }) as IDashboard[] | AssetsHostItemDashboardResponse[] | undefined;
    setDataDashboard(dashboardData);
  }, [query.data?.data, agentsQuery.data]);

  useEffect(() => {
    setStaleTimeVal(0);
  }, [sort]);

  useEffect(() => {
    setStaleTimeVal(DATA_CACHE_STALETIME);
  }, [page]);

  useEffect(() => {
    let delaySearch: NodeJS.Timeout;

    if (filter?.filters?.length > 0) {
      filterRef.current = filter;
      delaySearch = setTimeout(() => {
        handleFilteringTest(filterRef.current, setFilterVal);
        setFilterStatus(false);
      }, 300);
    }

    return () => clearTimeout(delaySearch);
  }, [filter]);

  useEffect(() => {
    queryClient.invalidateQueries('bulletinAssetsWl');
  }, [queryClient]);

  const pageChange = (event: GridPageChangeEvent): void => {
    getPage(event);
  };

  useEffect(() => {
    if (query.error) {
      handleError(query.error, createNotification);
    }
  }, [query.isError, createNotification, query.error]);

  const onFilterChange = (event: FilterChangeEvent): void => {
    filterSearch(event);
  };

  const handleCustomSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    debouncedCustomSearch(e);
  };

  return (
    <>
      {query.data?.total && Number(query.data?.total) > 0 ? (
        <div className={styles.wlVulnModalAsset} id="idModalVulns">
          <LocalizationProvider language={currentLocale}>
            <IntlProvider locale={currentLocale.substring(0, 2)}>
              <div className="filter-line">
                <Filter
                  value={filter}
                  onChange={onFilterChange}
                  fields={FILTER_ASSETLIST_WITHOUT_ID[currentLocale]}
                />
                {filter.filters.length === 0 && (
                  <Input
                    name="customSearch"
                    placeholder={localeNewTask[currentLocale].customFilterPh}
                    value={customSearch}
                    onChange={(e): void => handleCustomSearch(e)}
                  />
                )}
              </div>
            </IntlProvider>
          </LocalizationProvider>
          <CommonHostList
            sort={sort}
            dataDashboard={dataDashboard}
            page={page}
            total={query.data?.total}
            pageChange={pageChange}
            setSort={setSort}
            dataForHooks={dataForHooks}
            conditionSort={10}
          />
          <СommonTableLoader queryData={query.isLoading} filteringData={filterStatus} />
          {query.isError && (
            <div className="info alert alert-danger">Error: {query.error.message}</div>
          )}
        </div>
      ) : null}
    </>
  );
}
