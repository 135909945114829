/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-nested-ternary */
import { Typography } from '@progress/kendo-react-common';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import type { TabStripSelectEventArguments } from '@progress/kendo-react-layout';
import { TabsUsers } from './TabsUsers';
import localeUser from '../../../utils/i18n/userLocales/user.json';
import { getUserData } from '../../../services/local-storage-service';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeUser;
export function User(): React.ReactElement {
  const currentUser = getUserData();

  const history = useHistory();

  const [selected, setSelected] = useState<number>(
    document.location.pathname.includes('/list')
      ? 0
      : document.location.pathname.includes('/organization')
      ? 1
      : document.location.pathname.includes('/role')
      ? 2
      : document.location.pathname.includes('/server') && currentUser?.role === 'account_admin'
      ? 2
      : document.location.pathname.includes('/server') && currentUser?.role !== 'account_admin'
      ? 3
      : 4,
  );

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      if (e.selected === 0 && !document.location.pathname.includes('/list')) {
        history.replace('/lk/users/list');
        setSelected(e.selected);
      }
      if (e.selected === 1 && !document.location.pathname.includes('/organization')) {
        history.replace('/lk/users/organization');
        setSelected(e.selected);
      }
      if (e.selected === 2 && !document.location.pathname.includes('/roles')) {
        history.replace('/lk/users/roles');
        setSelected(e.selected);
      }
      if (
        (e.selected === 3 && !document.location.pathname.includes('/servers')) ||
        (e.selected === 2 && document.location.pathname.includes('/servers'))
      ) {
        history.replace('/lk/users/servers');
        setSelected(e.selected);
      }

      setSelected(e.selected);
    },
    [history],
  );

  return (
    <div className="users">
      <div className="common-header-page">
        <Typography.h3>{localeUser[currentLocale].title}</Typography.h3>
      </div>
      <TabsUsers selected={selected} handleSelect={handleSelect} />
    </div>
  );
}
