/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import '../../TaskDescription.module.scss';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { State, process as gridProcess } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { useRef } from 'react';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { PAGEABLE_DATA_LOCAL } from '../../../../../utils/helpers/constants';
import localeAuditTask from '../../../../../utils/i18n/taskLocale/auditTask.json';
import localeVmTask from '../../../../../utils/i18n/taskLocale/vmTask.json';
import { CellVulns } from './cells/CellVulnsSoftVulns';
import { CellTotalVulns } from './cells/CellVulnsTotal';
import { CellPackageName } from './cells/CellVulnsPackageName';
import type {
  Audit,
  AuditNetworkDeviceResult,
  AuditResult,
  AuditWindowsResult,
} from '../../../../../types/__generated/on-premise-solution/api/audit.v1';
import { ICollectVulnSoftData, collectVulnSoft } from '../../../../../utils/helpers/audit-helpers';
import { CellUpdateVersion } from './cells/CellVulnsUpdate';
import CellSelectPackage from './cells/CellSelectPackage';
import { ScoreCvssCell } from '../../../../common/cells/ScoreCvssCell';
import CellSelectPackageWl from './cells/CellSelectPackageWl';
import { CellWlsInfo } from './cells/CellWlsInfo';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

interface IVulnsSoft {
  dataResult: AuditResult | AuditWindowsResult | AuditNetworkDeviceResult | undefined;
  type: Audit['type'] | undefined;
  hostName: string | undefined | null | false;
  vulnSoftWhiteList?: false | ICollectVulnSoftData[] | undefined;
  id?: string | undefined;
  dataState: State;
  setDataState: React.Dispatch<React.SetStateAction<State>>;
  pageLimitGrid: {
    [key: string]: string;
  };
  exportRef: React.MutableRefObject<ExcelExport | null>;
  classOfDvice?: string | undefined;
}

export function VulnsSoftTable({ ...props }: IVulnsSoft): React.ReactElement {
  const {
    hostName,
    dataResult,
    id,
    vulnSoftWhiteList,
    exportRef,
    dataState,
    setDataState,
    pageLimitGrid,
    type,
    classOfDvice,
  } = props;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _grid = useRef<React.LegacyRef<Grid> | undefined>();

  const vulnSoftData = collectVulnSoft(
    dataResult?.vulnerableObjects,
    dataResult?.cumulativeData.vulns,
    undefined,
    type,
    classOfDvice,
  );

  const titleVulnsSoft = document.location.pathname.includes('assets/images')
    ? `${
        vulnSoftWhiteList
          ? localeVmTask[currentLocale].tabs.vulnSoftWinWl
          : localeVmTask[currentLocale].tabs.vulnSoftWin
      }`
    : `${hostName && hostName.replaceAll('.', '-')}_${
        vulnSoftWhiteList
          ? localeVmTask[currentLocale].tabs.vulnSoftWinWl
          : localeVmTask[currentLocale].tabs.vulnSoftWin
      }`;

  return (
    <LocalizationProvider language={currentLocale}>
      <IntlProvider locale={currentLocale.substring(0, 2)}>
        {(vulnSoftWhiteList || vulnSoftData) && (
          <ExcelExport
            data={
              vulnSoftWhiteList && vulnSoftWhiteList.length > 0
                ? vulnSoftWhiteList
                : vulnSoftData && vulnSoftData.length > 0
                ? vulnSoftData
                : []
            }
            ref={exportRef}
            fileName={titleVulnsSoft}
          >
            <Grid
              pageable={
                id && vulnSoftWhiteList && vulnSoftWhiteList.length > 10
                  ? PAGEABLE_DATA_LOCAL
                  : !id && vulnSoftData && vulnSoftData.length > 10
                  ? PAGEABLE_DATA_LOCAL
                  : false
              }
              sortable
              data={gridProcess(
                vulnSoftWhiteList && vulnSoftWhiteList.length > 0
                  ? vulnSoftWhiteList
                  : vulnSoftData && vulnSoftData.length > 0
                  ? vulnSoftData
                  : [],
                dataState,
              )}
              {...dataState}
              onDataStateChange={(e: GridDataStateChangeEvent): void => {
                setDataState(e.dataState);
                if (pageLimitGrid) {
                  const pageLimit = {
                    ...pageLimitGrid,
                    VulnsSoft: String(e.dataState.take),
                  };
                  localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
                } else {
                  const pageLimit = {
                    VulnsSoft: String(e.dataState.take),
                  };
                  localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
                }
              }}
              className="taskaudit__vulnssoftgrid"
              scrollable="none"
              ref={_grid.current}
            >
              {(document.location.pathname.includes('/assets/hosts') ||
                document.location.pathname.includes('/task/actions')) &&
                type !== 'windows' && (
                  <Column
                    field="addAsset"
                    // title={localeAuditTask[currentLocale].vulnsSoft.table.selectPackage}
                    title=" "
                    cell={!id ? CellSelectPackage : CellSelectPackageWl}
                    width="40px"
                    sortable={false}
                  />
                )}
              <Column
                field="maxScore"
                title={localeAuditTask[currentLocale].vulnsSoft.table.cvssScore}
                cell={ScoreCvssCell}
                width="74px"
              />
              <Column
                field="name"
                title={
                  type && type === 'linux'
                    ? localeAuditTask[currentLocale].vulnsSoft.table.packageName
                    : localeAuditTask[currentLocale].vulnsSoft.table.softNameWin
                }
                cell={CellPackageName}
                width="220px"
                format={vulnSoftWhiteList && vulnSoftWhiteList.length > 0 ? 'wl' : ''}
              />
              <Column
                field="packetVersion"
                title={
                  type && type === 'linux'
                    ? localeAuditTask[currentLocale].vulnsSoft.table.packageVersion
                    : localeAuditTask[currentLocale].vulnsSoft.table.softVersionWin
                }
                width="220px"
              />
              <Column
                field="updateVersion"
                title={localeAuditTask[currentLocale].vulnsSoft.table.patchVersion}
                width="220px"
                cell={CellUpdateVersion}
              />
              <Column
                field="vulns"
                title={localeAuditTask[currentLocale].vulnsSoft.table.vulnerabilities}
                cell={CellVulns}
              />
              <Column
                field="totalVulns"
                title={localeAuditTask[currentLocale].vulnsSoft.table.totalVulnerabilities}
                cell={CellTotalVulns}
                width="140px"
              />
              {vulnSoftWhiteList && (
                <Column
                  title={localeAuditTask[currentLocale].vulnsSoft.table.wlCellTitle}
                  cell={CellWlsInfo}
                  width={60}
                />
              )}
            </Grid>
          </ExcelExport>
        )}
      </IntlProvider>
    </LocalizationProvider>
  );
}
