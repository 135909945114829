import { nanoid } from 'nanoid';
import { sortReferencesByDomain } from '../../../../utils/helpers/security-vulns-helper';
import { WrapperMenu } from '../../../features/Task/taskIDetails/iDetailsComponents/WrapperMenu';
import styles from './vulnModal.module.scss';

export interface IReferencesByDomain {
  article: string;
  arr: string[];
}

export function VulnReferenceList({
  references,
}: {
  references: string[] | null | undefined;
}): React.ReactElement {
  return (
    <>
      {sortReferencesByDomain(references).map((r) => {
        return (
          <WrapperMenu title={r.article} key={nanoid()}>
            <div className={styles.reference_list}>
              {r.arr.map((ref) => (
                <a href={ref} target="_blank" rel="noreferrer" key={nanoid()}>
                  {ref}
                </a>
              ))}
            </div>
          </WrapperMenu>
        );
      })}
    </>
  );
}
