import axios, { AxiosError, AxiosResponse } from 'axios';
import { JSXElementConstructor, ReactElement, useEffect } from 'react';
import { IAxiosResponse, processingResError } from '../helpers';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { BACKEND_DASHBOARD_URL } from '../../../utils/helpers/constants';
import { useAuthContext } from '../../../hooks/useAuthContext';

export const dashboardInstance = axios.create({
  baseURL: BACKEND_DASHBOARD_URL,
  withCredentials: true,
});

export function AxiosDashboardInterceptor({
  children,
}: {
  children: ReactElement<string | JSXElementConstructor<string>>;
}): React.ReactElement {
  const { setAccessToken } = useAuthContext();

  useEffect(() => {
    const resInterceptor = (
      response: AxiosResponse<IAxiosResponse, IAxiosResponse>,
    ): AxiosResponse<IAxiosResponse, IAxiosResponse> => {
      return response;
    };

    const errInterceptor = async (error: AxiosError<ApiError>): Promise<AxiosError<ApiError>> =>
      processingResError(error, setAccessToken, dashboardInstance);

    const interceptor = dashboardInstance.interceptors.response.use(resInterceptor, errInterceptor);

    return () => dashboardInstance.interceptors.response.eject(interceptor);
  }, [setAccessToken]);

  return children;
}
