/* eslint-disable no-nested-ternary */
import { Grid, GridColumn as Column, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useMemo, useState } from 'react';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { LAZY_PAGEABLE_DATA } from '../../../../../utils/helpers/constants';
import { WrapperMenu } from '../../../Task/taskIDetails/iDetailsComponents/WrapperMenu';
import type { InventorizationDifference } from '../../../../../types/__generated/on-premise-solution/api/inventorizationDifference.v1';
import { DiffTypeCell } from '../cells/DiffTypeCell';
import { DiffStandartCell } from '../cells/DiffStandartCell';
import {
  createFromListInvData,
  IDiffInvElementInfo,
  type DiffFilterTypes,
  defineWrapperStatuses,
} from '../../../../../utils/helpers/difference-modal-helper';
import { DiffBoolCell } from '../cells/DiffBoolCell';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function DiffRoute({
  data,
  filter,
}: {
  data: InventorizationDifference;
  filter: DiffFilterTypes;
}): React.ReactElement {
  const [page, setPage] = useState({ take: 5, skip: 0 });

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  const routesData = useMemo(
    () => createFromListInvData(data.routes as unknown as IDiffInvElementInfo[], filter),
    [data.routes, filter],
  );

  if (!routesData || !routesData.length) return <></>;

  return (
    <WrapperMenu
      title={locale[currentLocale].routes.title}
      diffStatuses={filter === 'all' ? defineWrapperStatuses(data.routes) : undefined}
    >
      <Grid
        data={routesData.slice(page.skip, page.take + page.skip)}
        pageable={LAZY_PAGEABLE_DATA}
        scrollable="none"
        total={routesData.length}
        pageSize={page.take}
        skip={page.skip}
        onPageChange={pageChange}
        className="no-margin-top"
      >
        <Column
          field="diffType"
          cell={DiffTypeCell}
          title=" "
          width={20}
          sortable={false}
          headerClassName="no-cursor"
        />
        <Column
          field="destination"
          title={locale[currentLocale].routes.destination}
          cell={DiffStandartCell}
          sortable={false}
          headerClassName="no-cursor"
        />
        <Column
          field="netmask"
          title={locale[currentLocale].routes.netmask}
          cell={DiffStandartCell}
          sortable={false}
          headerClassName="no-cursor"
        />
        <Column
          field="gateway"
          title={locale[currentLocale].routes.gateway}
          cell={DiffStandartCell}
          sortable={false}
          headerClassName="no-cursor"
        />
        <Column
          field="is_default"
          title={locale[currentLocale].routes.isDefault}
          cell={DiffBoolCell}
          sortable={false}
          headerClassName="no-cursor"
        />
        <Column
          field="metric"
          title={locale[currentLocale].routes.metric}
          cell={DiffStandartCell}
          sortable={false}
          headerClassName="no-cursor"
        />
        <Column
          field="interface"
          title={locale[currentLocale].routes.interface}
          cell={DiffStandartCell}
          sortable={false}
          headerClassName="no-cursor"
        />
        {data.type !== 'router' && data.type !== 'firewall' && data.type !== 'switch' && (
          <Column
            field="source"
            title={locale[currentLocale].routes.source}
            cell={DiffStandartCell}
            sortable={false}
            headerClassName="no-cursor"
          />
        )}
      </Grid>
    </WrapperMenu>
  );
}
