import { nanoid } from 'nanoid';
import { NavLink } from 'react-router-dom';
import type { VulnerableAssetsResponse } from '../../../../types/__generated/on-premise-solution/api/vulnerableAssetsResponse.v1';
import locale from '../../../../utils/i18n/dashboardLocale/dashboardLayout.json';
import { CvssBlock } from '../../../common/smallBlocks/CvssBlock';
import styles from '../Dashboard.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function TopVulnsAsset({
  data,
  isV,
}: {
  data: VulnerableAssetsResponse | undefined;
  isV?: boolean;
}): React.ReactElement {
  return (
    <table className="horizontal top-vulns-asset">
      <thead>
        <tr>
          <th className={styles.cvss_score}>{isV ? 'V' : 'CVSS'}</th>
          <th>{locale[currentLocale].hostName}</th>
          <th className={styles.vulns_count}>{locale[currentLocale].vulnerabilities}</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((d) => {
          if (d.type === 'host') {
            return (
              <tr key={nanoid(4)}>
                <td>
                  <CvssBlock score={isV ? d.latestAudit?.maxVFstec : d.latestAudit?.maxCvssScore} />
                </td>
                <td>
                  <NavLink className="dashboard__link" to={`/lk/assets/hosts/${d.assetId}`}>
                    {d.latestInventorization?.hostname}
                  </NavLink>
                </td>
                {/* <td>{d.latestInventorization?.ip}</td> */}
                <td className="number">{d.latestAudit?.vulnerabilities.count}</td>
              </tr>
            );
          }

          return null;
        })}
      </tbody>
    </table>
  );
}
