import { getUrlHostGr } from '../../../utils/helpers/host-group-list-helper';
import type { IDataHooks } from '../../../utils/helpers/types';
import { CommonGroupList } from '../CommonGroupList';

export function GroupListFormTab(): React.ReactElement {
  const urlParams = getUrlHostGr('AddHostGroups');

  const dataForHooks: IDataHooks = {
    name: 'name',
    componentName: 'AddHostGroups',
  };

  return (
    <div className="k-form">
      <div className="form-content groups">
        <CommonGroupList dataForHooks={dataForHooks} urlParams={urlParams} />
      </div>
    </div>
  );
}
