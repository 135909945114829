import type { GridCellProps } from '@progress/kendo-react-grid';
import localeAuditTask from '../../../../../../utils/i18n/taskLocale/auditTask.json';
import styles from '../packageNameModal.module.scss';
import { useWhiteListContext } from '../../../../../../hooks/useWhiteListContext';
import { CommonTooltip } from '../../../../../common/CommonTooltip';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

export function CellWlsInfo(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;
  const { bulletinWhitelistIds } = dataItem;

  const { setRelatedWhiteListModal, relatedWlIds } = useWhiteListContext();

  return (
    <td style={{ textAlign: 'right', position: 'relative', overflow: 'visible' }}>
      {bulletinWhitelistIds !== undefined && !bulletinWhitelistIds.length && (
        <span style={{ display: 'flex' }}>
          <CommonTooltip
            hiddenStyle={{
              left: '-460px',
              width: '440px',
              textAlign: 'left',
            }}
          >
            <div>{localeAuditTask[currentLocale].vulnsSoft.table.noWhiteListsTooltip}</div>
          </CommonTooltip>
          {bulletinWhitelistIds.length}
        </span>
      )}
      {bulletinWhitelistIds !== undefined && bulletinWhitelistIds.length !== 0 && (
        <button
          type="button"
          onClick={(): void => {
            relatedWlIds.current.push(...bulletinWhitelistIds);
            setRelatedWhiteListModal(true);
          }}
          style={{
            textDecoration: 'underline',
            border: 'none',
            padding: '0',
          }}
        >
          {bulletinWhitelistIds.length}
        </button>
      )}
    </td>
  );
}
