/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-pascal-case */
import { useLocation, useParams } from 'react-router-dom';
import { Typography } from '@progress/kendo-react-common';
import { ErrorBoundary } from 'react-error-boundary';
import { Menu, MenuItem, MenuSelectEvent } from '@progress/kendo-react-layout';
import { useEffect, useState } from 'react';
import { DeviceMetaBlock } from './DeviceMetaBlock';
import { TabsDeviceDescription } from './TabsDeviceDescription';
import { BoundaryErrorComponent } from '../../../common/BoundaryErrorComponent';
import styles from '../../Host/hostComponents/HostDescription.module.scss';
import { СommonTableLoader } from '../../../common/tableLoader/CommonTableLoader';
import { useCreateTask } from '../../../../hooks/components/useCreateTask';
import localeDevices from '../../../../utils/i18n/devices/devices.json';
import localeHostDescription from '../../../../utils/i18n/assetLocales/hostDescription.json';
import { useHostModalContext } from '../../../../hooks/useHostModalContext';
import { EditPortModal } from '../../../common/modal/EditPortModal';
import { useDifferenceModalContext } from '../../../../hooks/useDifferenceModalContext';
import { DifferenceAuditModal } from '../../Host/DifferenceModal/Audit/DifferenceAuditModal';
import { DifferenceInvModal } from '../../Host/DifferenceModal/Inventorization/DifferenceInvModal';
import { useDeviceDescription } from '../../../../hooks/components/useDeviceDescription';
import { getUserData } from '../../../../services/local-storage-service';
import { Button } from '../../../common/baseElements/Button';
import { HostConfirmDel } from '../../Host/hostComponents/HostConfirmDel';
import { useNetDeviceContext } from '../../../../hooks/useNetDeviceContext';
import { useAssetCreatePropContext } from '../../../../hooks/useAssetCreatePropContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeDevices;

export function DeviceDescription(): React.ReactElement {
  const { assetId } = useParams<{ assetId: string }>();

  const { devicename } = useDeviceDescription();

  const { createReportDevice } = useCreateTask();

  const { setDeviceForm, deviceForm } = useNetDeviceContext();

  const { pathname } = useLocation();

  const assetType: 'firewall' | 'router' | 'switch' | 'hypervisor' = pathname
    .replace('/lk/assets/devices/', '')
    .split('/')[0] as 'firewall' | 'router' | 'switch' | 'hypervisor';

  const {
    isAuditOpen: isDiffAuditOpen,
    setAuditOpen: setDiffAuditOpen,
    isInvOpen: isDiffInvOpen,
    setInvOpen: setDiffInvOpen,
  } = useDifferenceModalContext();

  const currentUser = getUserData();

  const [isConfirm, setConfirm] = useState(false);

  const { queryDashboardAssetId, queryInventorizationId } = useDeviceDescription();

  const { createTaskForAsset } = useCreateTask();

  const { isEditPort } = useHostModalContext();

  const { setSelectedVulns } = useAssetCreatePropContext();

  const onSelect = (e: MenuSelectEvent): void => {
    if (e.item.data === 'audit') setDiffAuditOpen(true);
    else if (e.item.data === 'inventorization') setDiffInvOpen(true);
  };

  useEffect(() => {
    if (devicename && !deviceForm) setDeviceForm([{ assetId, hostname: devicename }]);
  }, [assetId, devicename, setDeviceForm, deviceForm]);

  useEffect(() => {
    return () => {
      setDeviceForm(null);
      setSelectedVulns(null);
    };
  }, []);

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      <div className="devicedescription">
        {isConfirm && <HostConfirmDel setConfirm={setConfirm} assetName={devicename || assetId} />}
        {isDiffAuditOpen && (
          <DifferenceAuditModal
            deviceType={queryDashboardAssetId.data?.type}
            system={
              queryDashboardAssetId.data?.latestInventorization.os
                ? `${queryDashboardAssetId.data?.latestInventorization.os?.name} ${queryDashboardAssetId.data?.latestInventorization.os?.version}`
                : '-'
            }
            ip={queryDashboardAssetId.data?.latestInventorization.ip}
          />
        )}
        {isDiffInvOpen && (
          <DifferenceInvModal
            deviceType={queryDashboardAssetId.data?.type}
            system={
              queryDashboardAssetId.data?.latestInventorization.os
                ? `${queryDashboardAssetId.data?.latestInventorization.os?.name} ${queryDashboardAssetId.data?.latestInventorization.os?.version}`
                : '-'
            }
            ip={queryDashboardAssetId.data?.latestInventorization.ip}
          />
        )}
        <div className="common-header-page">
          <Typography.h3 className="title">
            <div className="device-title">
              {localeDevices[currentLocale].deviceDescriptionTitle}
              {queryDashboardAssetId?.data?.latestInventorization?.hostname || assetId}
            </div>
          </Typography.h3>
          <div className="topactions">
            <div className={`button-group ${styles.host_group}`}>
              <Button
                className="action"
                onClick={(): void => {
                  createTaskForAsset({
                    val: 'inventorization',
                    data: queryDashboardAssetId.data,
                    assetId: queryDashboardAssetId.data?.assetId,
                  });
                }}
              >
                {localeDevices[currentLocale].btnCreateTask}
              </Button>
              <Button onClick={(): void => createReportDevice(assetId, devicename || '-')}>
                {localeDevices[currentLocale].btnCreateReport}
              </Button>
            </div>
            <div>
              <Menu hoverOpenDelay={0} onSelect={onSelect} className="btn-menu">
                <MenuItem text={localeHostDescription[currentLocale].changes.differenceControl}>
                  <MenuItem
                    text={localeHostDescription[currentLocale].changes.auditTitle}
                    data="audit"
                  />
                  <MenuItem
                    text={localeHostDescription[currentLocale].changes.invTitle}
                    data="inventorization"
                  />
                </MenuItem>
              </Menu>
            </div>
            {currentUser?.role !== 'reader_user' && (
              <Button
                onClick={(): void => setConfirm(true)}
                fill="delete"
                // disabled={
                //   assetIdData?.createdBy.source === 'user' &&
                //   currentUser?.role === 'user' &&
                //   currentUser.id !== assetIdData.createdBy.id
                // }
              >
                {localeHostDescription[currentLocale].btnDelete}
              </Button>
            )}
          </div>
          <СommonTableLoader queryData={queryDashboardAssetId.isLoading} filteringData={false} />
        </div>
        {queryDashboardAssetId.data && <DeviceMetaBlock {...queryDashboardAssetId} />}
        <TabsDeviceDescription
          auditId={
            (queryDashboardAssetId.data?.latestAudit &&
              queryDashboardAssetId.data?.latestAudit.auditId &&
              queryDashboardAssetId.data?.latestAudit.auditId) ||
            ''
          }
          invData={queryInventorizationId.data}
          hostName={queryDashboardAssetId.data?.latestInventorization?.hostname}
          inventorizationId={queryDashboardAssetId.data?.latestInventorization?.inventorizationId}
          maxVFstec={queryDashboardAssetId.data?.latestAudit?.maxVFstec}
        />
        {isEditPort && (
          <EditPortModal
            assetId={assetId}
            assetType={assetType}
            sshPortCurrent={queryDashboardAssetId.data?.sshPort}
          />
        )}
      </div>
    </ErrorBoundary>
  );
}
