import React, { createContext, useState, useMemo, useContext } from 'react';
import type { ICalcVulns } from '../utils/audit-vulns-cumulative';
import type {
  IAuditModalContent,
  IChildrenNodes,
  IResultPackageMaxScore,
} from '../utils/helpers/types';
import type { AuditResponse } from '../types/__generated/on-premise-solution/api/auditsResponse.v1';

const AuditModalContext = createContext<IAuditModalContent | null>(null);

export function AuditModalProvider({ children }: IChildrenNodes): React.ReactElement {
  const [isOpen, setOpen] = useState(false);

  const [auditData, setAuditData] = useState<AuditResponse | null>(null);

  const [auditCurrentPkg, setAuditCurrentPkg] = useState<IResultPackageMaxScore | null>(null);

  const [auditCumulativePkg, setAuditVulnsPkg] = useState<ICalcVulns | null | undefined>(null);

  const [pkgName, setPkgName] = useState<{
    name: string;
    version: string;
    updateVersion: string | null;
  } | null>(null);

  const [cveName, setCveName] = useState<string | null>(null);

  const [cvssVector, setCvssVector] = useState<string | null>(null);

  const [idKb, setIdKb] = useState<string | null>(null);

  const value = useMemo(
    () => ({
      isOpen,
      setOpen,
      auditData,
      setAuditData,
      auditCurrentPkg,
      setAuditCurrentPkg,
      auditCumulativePkg,
      setAuditVulnsPkg,
      pkgName,
      setPkgName,
      cveName,
      setCveName,
      cvssVector,
      setCvssVector,
      idKb,
      setIdKb,
    }),
    [isOpen, auditData, auditCurrentPkg, auditCumulativePkg, pkgName, cveName, cvssVector, idKb],
  );

  return <AuditModalContext.Provider value={value}>{children}</AuditModalContext.Provider>;
}
export const useAuditModalContext = (): IAuditModalContent => {
  const context = useContext(AuditModalContext);
  if (context === null) {
    throw new Error('ModalContext must be used inside the ModalContext.Provider.');
  }

  return context;
};
