import '../../TaskDescription.module.scss';
import { useQuery } from 'react-query';
import { Dialog } from '@progress/kendo-react-dialogs';
import { useEffect, useState } from 'react';
import type { GridPageChangeEvent } from '@progress/kendo-react-grid';
import type { SortDescriptor } from '@progress/kendo-data-query';
import localeAuditTask from '../../../../../utils/i18n/taskLocale/auditTask.json';
import localeButtons from '../../../../../utils/i18n/commonLocales/buttons.json';
import type { BulletinWhitelistsResponse } from '../../../../../types/__generated/on-premise-solution/api/bulletinWhitelistsResponse.v1';
import type { ApiError } from '../../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getWhiteList } from '../../../../../services/settings-service';
import { CommonWhiteList } from '../../../../common/CommonWhiteList';
import { useWhiteListContext } from '../../../../../hooks/useWhiteListContext';
import { Button } from '../../../../common/baseElements/Button';
import type { IPageState } from '../../../../../utils/helpers/types';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

export function RelatedWhiteListModal(): React.ReactElement {
  const { setRelatedWhiteListModal, relatedWlIds } = useWhiteListContext();

  const [deletedWhiteLists, setDeletedWhiteLists] = useState<string[]>([]);

  const [page, setPage] = useState<IPageState>({ take: 15, skip: 0 });

  const [sort, setSort] = useState<SortDescriptor[]>([{ field: 'name', dir: 'asc' }]);

  const filterVal = relatedWlIds.current.map((wlid) => ({
    bulletinWhitelistId: {
      $eq: wlid,
    },
  }));

  const { data } = useQuery<BulletinWhitelistsResponse, ApiError>(
    ['RelatedWhiteList', page, sort],
    () => getWhiteList(page, sort, filterVal, 'or'),
  );

  useEffect(() => {
    return () => {
      relatedWlIds.current.splice(0);
    };
  }, []);

  useEffect(() => {
    setDeletedWhiteLists(
      relatedWlIds.current.filter(
        (rwlid) => data?.data.map((d) => d.bulletinWhitelistId).indexOf(rwlid) === -1,
      ),
    );
  }, [data]);

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  return (
    <Dialog
      title={localeAuditTask[currentLocale].vulnsSoft.table.relatedWlTitle}
      onClose={(): void => {
        setRelatedWhiteListModal(false);
      }}
    >
      <CommonWhiteList
        data={data}
        sort={sort}
        page={page}
        total={data?.total || 0}
        pageChange={pageChange}
        setSort={setSort}
        dataForHooks={{ name: 'related wl', componentName: 'RelatedWhiteListModal' }}
      />
      {deletedWhiteLists.length && (
        <div style={{ margin: '20px 0' }}>
          <span style={{ marginBottom: '8px' }}>
            {localeAuditTask[currentLocale].vulnsSoft.table.deletedWlIds}:
          </span>
          <ul
            style={{
              paddingLeft: '12px',
            }}
          >
            {deletedWhiteLists.map((dwlid) => (
              <li>{dwlid}</li>
            ))}
          </ul>
        </div>
      )}
      <div className="k-form-buttons">
        <Button type="button" onClick={(): void => setRelatedWhiteListModal(false)}>
          {localeButtons[currentLocale].cancel}
        </Button>
      </div>
    </Dialog>
  );
}
