import { Grid, GridColumn, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useState } from 'react';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import type { InventorizationResponse } from '../../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import { SizeCell } from '../../../../common/cells/SizeCell';
import { StandartCell } from '../../../../common/cells/StandartCell';
import { WrapperMenu } from './WrapperMenu';
import { LAZY_PAGEABLE_DATA } from '../../../../../utils/helpers/constants';
import { TypeCellYesOrNo } from '../../../../common/cells/TypeCellYesOrNo';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function HypervisorVirtImage({
  data,
}: {
  data: InventorizationResponse;
}): React.ReactElement {
  const [page, setPage] = useState({ take: 5, skip: 0 });

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  return (
    <WrapperMenu title={locale[currentLocale].hypervisorVirtImage.title}>
      <Grid
        data={
          data.type === 'hypervisor'
            ? data.result.virtual_images?.slice(page.skip, page.take + page.skip)
            : []
        }
        pageable={LAZY_PAGEABLE_DATA}
        scrollable="none"
        total={data.type === 'hypervisor' ? data.result.virtual_images?.length : 0}
        pageSize={page.take}
        skip={page.skip}
        onPageChange={pageChange}
        className="no-margin-top"
      >
        <GridColumn
          field="name"
          title={locale[currentLocale].hypervisorVirtImage.name}
          cell={StandartCell}
          width="260px"
        />
        <GridColumn
          field="cpuCoreCount"
          title={locale[currentLocale].hypervisorVirtImage.cpuCoreCount}
          cell={StandartCell}
          width="146px"
        />
        <GridColumn
          field="memorySize"
          title={locale[currentLocale].hypervisorVirtImage.memorySize}
          cell={SizeCell}
          width="78px"
        />
        <GridColumn
          field="isDisabled"
          title={locale[currentLocale].hypervisorVirtImage.isDisabled}
          cell={TypeCellYesOrNo}
          width="100px"
        />
        <GridColumn
          field="isRunning"
          title={locale[currentLocale].hypervisorVirtImage.isRunning}
          cell={TypeCellYesOrNo}
          width="120px"
        />
        <GridColumn
          field="osName"
          title={locale[currentLocale].hypervisorVirtImage.osName}
          cell={StandartCell}
          width="280px"
          className="title"
        />
        <GridColumn
          field="configPath"
          title={locale[currentLocale].hypervisorVirtImage.configPath}
          cell={StandartCell}
          className="title"
        />
      </Grid>
    </WrapperMenu>
  );
}
