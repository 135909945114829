import '../../../Host/hostComponents/HostDescription.module.scss';
import type localeHostDescription from '../../../../../utils/i18n/assetLocales/hostDescription.json';
import { AddCredentialForm } from '../../../Credentials/form/AddCredentialForm';
import { TableFRegistryMetaBlock } from './tables/TableFRegistryMetaBlock';
import { TableSRegistryMetaBlock } from './tables/TableSRegistryMetaBlock';
import { useCredentialContext } from '../../../../../hooks/useCredentialContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHostDescription;
export function RegistryMetaBlock(): React.ReactElement {
  const { isAddCredential, credentialData, isEdit } = useCredentialContext();

  return (
    <div className="common-summary-page">
      <TableFRegistryMetaBlock currentLocale={currentLocale} />
      <TableSRegistryMetaBlock currentLocale={currentLocale} />
      {/* <TableTHostMetaBlock data={data} currentLocale={currentLocale} /> */}
      {(isAddCredential || (credentialData && isEdit)) && <AddCredentialForm />}{' '}
    </div>
  );
}
