import { Label } from '@progress/kendo-react-labels';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useEffect } from 'react';
import localeDashboardForm from '../../../../utils/i18n/dashboardLocale/addDashboardForm.json';
import styles from './AddAssetForm.module.scss';
import { useHostActions } from '../../../../hooks/components/useHostActions';
import { useAssetCreatePropContext } from '../../../../hooks/useAssetCreatePropContext';
import { CommonTooltip } from '../../../common/CommonTooltip';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeDashboardForm;

export function AssetParamForm({ type }: { type?: string }): React.ReactElement {
  const {
    hostSubType,
    hostImportance,
    selectedHostType,
    selectedImportance,
    perimeterImpact,
    setPerimeterImpact,
    setSelectedHostType,
    setSelectedImportance,
    setMutableAssetProps,
  } = useAssetCreatePropContext();

  useEffect(() => {
    setSelectedHostType(hostSubType[0]);
    setSelectedImportance(hostImportance[1]);
    setPerimeterImpact(true);
    setMutableAssetProps(null);
  }, []);

  const { changeImportance, changeHostType } = useHostActions();

  return (
    <div className={type ? styles.assetParamForm : styles.hostParamForm}>
      {!type && (
        <div className="textarea-field">
          <Label>{localeDashboardForm[currentLocale].form.hostType}</Label>
          <DropDownList
            data={hostSubType}
            name="hostSubType"
            id="val"
            textField="text"
            onChange={changeHostType}
            defaultValue={selectedHostType}
          />
        </div>
      )}
      <div
        className={`${
          !type ? styles.hostImportanceWrapper : styles.assetImportanceWrapper
        } textarea-field`}
      >
        <Label>{localeDashboardForm[currentLocale].form.hostImportance}</Label>
        <DropDownList
          data={hostImportance}
          name="hostImportance"
          id="val"
          textField="text"
          onChange={changeImportance}
          defaultValue={selectedImportance}
        />
      </div>
      <div className={styles.checkbox}>
        <Checkbox
          value={perimeterImpact}
          onChange={(): void => setPerimeterImpact(!perimeterImpact)}
          label={localeDashboardForm[currentLocale].form.perimeterImpact}
        />
        <CommonTooltip
          visibleStyle={{ marginLeft: '4px' }}
          hiddenStyle={{ marginLeft: '-264px', marginTop: '-52px' }}
        >
          {localeDashboardForm[currentLocale].form.perimeterImpactInfo}
        </CommonTooltip>
      </div>
    </div>
  );
}
