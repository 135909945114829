import { Grid, GridColumn, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useState } from 'react';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import type { InventorizationResponse } from '../../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import type {
  InventorizationLinux,
  InventorizationWindows,
} from '../../../../../types/__generated/on-premise-solution/api/inventorizationResponse.v1';
import { StandartCell } from '../../../../common/cells/StandartCell';
import { ProcessesCell } from './сells/ProcessesCell';
import { WrapperMenu } from './WrapperMenu';
import { LAZY_PAGEABLE_DATA } from '../../../../../utils/helpers/constants';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function OpenPorts({ data }: { data: InventorizationResponse }): React.ReactElement {
  const [page, setPage] = useState({ take: 5, skip: 0 });

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  return (
    <WrapperMenu title={locale[currentLocale].openPorts.title}>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <Grid
            data={(
              data.result.open_ports as
                | InventorizationLinux['result']['open_ports']
                | InventorizationWindows['result']['open_ports']
            )?.slice(page.skip, page.take + page.skip)}
            pageable={LAZY_PAGEABLE_DATA}
            scrollable="none"
            total={
              (
                data.result.open_ports as
                  | InventorizationLinux['result']['open_ports']
                  | InventorizationWindows['result']['open_ports']
              )?.length
            }
            pageSize={page.take}
            skip={page.skip}
            onPageChange={pageChange}
            className="no-margin-top"
          >
            <GridColumn
              field="protocol"
              title={locale[currentLocale].openPorts.protocol}
              cell={StandartCell}
            />
            <GridColumn field="ip" title={locale[currentLocale].openPorts.ip} cell={StandartCell} />
            <GridColumn
              field="port"
              title={locale[currentLocale].openPorts.port}
              cell={StandartCell}
            />
            <GridColumn
              field="processes"
              title={locale[currentLocale].openPorts.processes}
              cell={ProcessesCell}
            />
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </WrapperMenu>
  );
}
