import type { GridCellProps } from '@progress/kendo-react-grid';
import type React from 'react';
import locale from '../../../utils/i18n/commonLocales/table.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function TypeCellYesOrNo(props: GridCellProps): React.ReactElement {
  const { dataItem, field, className } = props;

  return field !== undefined && typeof dataItem[field] === 'boolean' ? (
    <td className={className || ''}>
      {field ? locale[currentLocale].booleanValues.no : locale[currentLocale].booleanValues.yes}
    </td>
  ) : (
    <td />
  );
}
