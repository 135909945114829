/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-pascal-case */
import { useMutation, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import type { AssetDeletionResponse } from '../../../../types/__generated/on-premise-solution/api/assetDeletionResponse.v1';
import { deleteAsset } from '../../../../services/asset-service';
import type { Asset } from '../../../../types/__generated/on-premise-solution/api/asset.v1';
import { DeleteConfirmModal } from '../../../common/modal/DeleteConfirmModal';

interface IHostConfirmDel {
  setConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  assetName?: string;
}

export function HostConfirmDel({ setConfirm, assetName }: IHostConfirmDel): React.ReactElement {
  const queryClient = useQueryClient();

  const history = useHistory();

  const { assetId } = useParams<{ assetId: string }>();

  const mutation = useMutation<AssetDeletionResponse | null, ApiError, Asset['assetId']>(
    (payload) => deleteAsset(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('hostList');
      },
    },
  );

  const handleDelete = (): void => {
    mutation.mutate(assetId);
    setConfirm(false);
    history.goBack();
  };

  return (
    <DeleteConfirmModal
      type="asset"
      name={assetName || assetId}
      handleDelete={handleDelete}
      onClose={(): void => setConfirm(false)}
      errorMessage={mutation.error?.message}
    />
  );
}
