import type { Audit } from '../../types/__generated/on-premise-solution/api/audit.v1';

interface ISummaryAudit {
  severity: string | undefined;
  cvssScore: string;
  cvssVector: string;

  maxScore: number;
  cssSeverityClasses: string[];
  cssScoreClasses: string[];
}
export function calcDataSummaryAudit(queryAuditInfo: Audit | undefined): ISummaryAudit {
  let value = {} as ISummaryAudit;

  const cssSeverityClasses = [''] as string[];

  const cssScoreClasses = [''] as string[];
  let maxScore = 0;

  queryAuditInfo?.result.cumulativeData.metrics?.forEach((m) => {
    if (Number(m.cvss.score) > maxScore) {
      maxScore = Number(m.cvss.score);
      value = {
        ...value,
        severity: m.severity,
        cvssScore: m.cvss.score,
        cvssVector: m.cvss.vector,
        cssSeverityClasses,
        cssScoreClasses,
        maxScore,
      };
    }

    if (maxScore >= 0 && maxScore <= 3.9) {
      cssSeverityClasses.push('low-color-text');
      cssScoreClasses.push('asset_score low');
    }

    if (maxScore >= 3.9 && maxScore <= 6.9) {
      cssSeverityClasses.push('low-color-medium');
      cssScoreClasses.push('asset_score medium');
    }

    if (maxScore >= 6.9 && maxScore <= 8.9) {
      cssSeverityClasses.push('low-color-high');
      cssScoreClasses.push('asset_score high');
    }

    if (maxScore > 8.9) {
      cssSeverityClasses.push('low-color-critical');
      cssScoreClasses.push('asset_score critical');
    }
  });

  return value;
}

export function setColorValue(
  status: 'notActivated' | 'disabled' | 'online' | 'offline' | undefined,
): Array<string> {
  const colorValue = [] as Array<string>;

  if (status) {
    switch (status) {
      case 'notActivated':
        colorValue.push('#b5b0b0');
        break;
      case 'disabled':
        colorValue.push('#e97d7d');
        break;
      case 'online':
        colorValue.push('green');
        break;
      case 'offline':
        colorValue.push('#e97d7d');
        break;
      default:
        colorValue.push('#b5b0b0');
    }
  }

  return colorValue;
}
