import type { GridCellProps } from '@progress/kendo-react-grid';
import { NavLink } from 'react-router-dom';
import type { AssetRouter } from '../../../../types/__generated/on-premise-solution/api/asset.v1';
import type { AssetRouterDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/assetRouterDashboardResponse.v1';
import { useAuditModalContext } from '../../../../hooks/useAuditModalContext';

export function CellDeviceName({
  dataItem: { latestInventorization },
  dataItem: { assetId },
  dataItem: { type },
}: Omit<GridCellProps, 'latestInventorization' | 'assetId' | 'type'> & {
  dataItem: AssetRouter | AssetRouterDashboardResponse;
}): React.ReactElement {
  const { setCveName } = useAuditModalContext();

  return (
    <td title={latestInventorization.hostname ? latestInventorization.hostname : assetId}>
      <NavLink
        // target="_blank"
        onClick={(): void => setCveName(null)}
        to={`/lk/assets/devices/${type}/${assetId}`}
      >
        {latestInventorization.hostname || assetId}
      </NavLink>
    </td>
  );
}
