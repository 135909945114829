import { nanoid } from 'nanoid';
import localeNewTask from '../../../utils/i18n/taskLocale/newTask.json';
import { useTaskContext } from '../../../hooks/useTaskContext';
import { Button } from '../baseElements/Button';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;

export function BulletinMutableBlockTask(): React.ReactElement {
  const { selectedBulletinIds, deleteBulletinFromTask } = useTaskContext();

  return (
    <div className="apply-assets">
      <div className="title-apply-assets">
        <span>{localeNewTask[currentLocale].form.applyForBulletin.title}</span>
      </div>
      <div className="list-assets">
        {selectedBulletinIds?.map((b) => {
          return (
            <div className="item-asset" key={nanoid()}>
              <Button
                type="button"
                icon="close"
                fill="task_asset"
                onClick={(e): void => deleteBulletinFromTask(b.id, e)}
              >
                {b.id}
              </Button>
            </div>
          );
        })}
        {(selectedBulletinIds?.length === 0 || selectedBulletinIds === null) && (
          <span className="placeholder">
            {localeNewTask[currentLocale].form.applyForBulletin.bulletinListPlaceholder}
          </span>
        )}
      </div>
    </div>
  );
}
