import type { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import type { IUrlParamsTaskHistory } from './task-history-helper';

export function fillFiltersTaskHostHistory(
  urlParams: IUrlParamsTaskHistory,
  filterValue: React.MutableRefObject<CompositeFilterDescriptor | null>,
  setFilter: (value: React.SetStateAction<CompositeFilterDescriptor>) => void,
  customSearch: string,
): void {
  if (urlParams.filterUrlData.taskName24ne && !urlParams.filterUrlData.startedAt24gt) {
    filterValue.current = {
      filters: [
        {
          field: 'taskName',
          operator: 'neq',
          value: urlParams.filterUrlData.taskName24ne,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  } else if (urlParams.filterUrlData.taskName24ne && urlParams.filterUrlData.startedAt24gt) {
    filterValue.current = {
      filters: [
        {
          field: 'taskName',
          operator: 'neq',
          value: urlParams.filterUrlData.taskName24ne,
        },
        {
          field: 'startedAt',
          operator: 'gt',
          value: urlParams.filterUrlData.startedAt24gt,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  } else if (urlParams.filterUrlData.taskName24eq && !urlParams.filterUrlData.startedAt24gt) {
    filterValue.current = {
      filters: [
        {
          field: 'taskName',
          operator: 'eq',
          value: urlParams.filterUrlData.taskName24eq,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  } else if (urlParams.filterUrlData.taskName24eq && urlParams.filterUrlData.startedAt24gt) {
    filterValue.current = {
      filters: [
        {
          field: 'taskName',
          operator: 'eq',
          value: urlParams.filterUrlData.taskName24eq,
        },
        {
          field: 'startedAt',
          operator: 'gt',
          text: '',
          value: urlParams.filterUrlData.startedAt24gt,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  } else if (
    urlParams.filterUrlData.taskName24regex &&
    !urlParams.filterUrlData.startedAt24gt &&
    customSearch === ''
  ) {
    filterValue.current = {
      filters: [
        {
          field: 'taskName',
          operator: 'contains',
          value: urlParams.filterUrlData.taskName24regex,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  } else if (
    urlParams.filterUrlData.taskName24regex &&
    urlParams.filterUrlData.startedAt24gt &&
    customSearch === ''
  ) {
    filterValue.current = {
      filters: [
        {
          field: 'taskName',
          operator: 'contains',
          value: urlParams.filterUrlData.taskName24regex,
        },
        {
          field: 'startedAt',
          operator: 'gt',
          value: urlParams.filterUrlData.startedAt24gt,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  } else if (
    urlParams.filterUrlData.taskName24not24regex &&
    !urlParams.filterUrlData.startedAt24gt
  ) {
    filterValue.current = {
      filters: [
        {
          field: 'taskName',
          operator: 'doesnotcontain',
          value: urlParams.filterUrlData.taskName24not24regex,
        },
        {
          field: 'startedAt',
          operator: 'gt',
          value: urlParams.filterUrlData.startedAt24gt,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  } else if (
    urlParams.filterUrlData.taskName24not24regex &&
    urlParams.filterUrlData.startedAt24gt
  ) {
    filterValue.current = {
      filters: [
        {
          field: 'taskName',
          operator: 'doesnotcontain',
          value: urlParams.filterUrlData.taskName24not24regex,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  } else if (
    (!urlParams.filterUrlData.taskName24not24regex ||
      !urlParams.filterUrlData.taskName24regex ||
      !urlParams.filterUrlData.taskName24eq ||
      !urlParams.filterUrlData.taskName24ne) &&
    urlParams.filterUrlData.startedAt24gt
  ) {
    filterValue.current = {
      filters: [
        {
          field: 'startedAt',
          operator: 'gt',
          value: urlParams.filterUrlData.startedAt24gt,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  }
}
