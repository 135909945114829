/* eslint-disable react/jsx-props-no-spreading */
import type { GridCellProps } from '@progress/kendo-react-grid';
import type { ReactElement } from 'react';
import styles from '../../forms/AddAssetForm.module.scss';
import { CellPackageName } from '../../../Task/taskVmDetails/vmDetailsComponents/cells/CellVulnsPackageName';
import { StandartCell } from '../../../../common/baseElements/StandartCell';

export function DiffCellPackaheName(props: GridCellProps): ReactElement {
  const { className, dataItem, ...other } = props;

  // return dataItem.diffType === 'closed' || dataItem.diffType === 'noChanges' ? (
  //   <StandartCell
  //     className={`${dataItem.diffType === 'updated' && styles.updated} ${
  //       dataItem.diffType === 'closed' && styles.closed
  //     }`}
  //     dataItem={dataItem}
  //     {...other}
  //   />
  // ) : (
  //   <CellPackageName
  //     className={`${dataItem.diffType === 'updated' && styles.updated} ${
  //       dataItem.diffType === 'closed' && styles.closed
  //     }`}
  //     dataItem={dataItem}
  //     {...other}
  //   />
  // );
  return (
    <StandartCell
      className={`${dataItem.diffType === 'updated' && styles.updated} ${
        dataItem.diffType === 'closed' && styles.closed
      }`}
      dataItem={dataItem}
      {...other}
    />
  );
}
