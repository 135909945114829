/* eslint-disable react/jsx-no-useless-fragment */
import { nanoid } from 'nanoid';
import { sortExploitsBySource } from '../../../../utils/helpers/security-vulns-helper';
import { WrapperMenu } from '../../../features/Task/taskIDetails/iDetailsComponents/WrapperMenu';
import styles from './vulnModal.module.scss';

export interface IExploits {
  source: string;
  created_at: string;
  url: string;
  cve: string;
  verified: boolean | null;
  title?: string | undefined;
  severity?: string | undefined;
}
export function VulnExploitsList({
  exploits,
}: {
  exploits: IExploits[] | null;
}): React.ReactElement {
  const dataExpl = sortExploitsBySource(exploits);

  return (
    <>
      {dataExpl?.map((uniqueExpl) => {
        return (
          <WrapperMenu title={`${uniqueExpl.source} (${uniqueExpl.data?.length})`} key={nanoid()}>
            <div className={styles.reference_list} />
            <div>
              {uniqueExpl.data
                ?.sort((a, b) => (a.created_at < b.created_at ? 1 : -1))
                .map((expl) => (
                  <div key={nanoid()} className={styles.exploitsWrap}>
                    <div className={styles.exploitsContent}>
                      <span className={styles.exploitData}>
                        {new Date(expl.created_at).toLocaleString()}
                      </span>
                    </div>
                    <div className={styles.exploitsContent}>
                      <span>{expl.title}</span>
                    </div>
                    <div className={styles.exploitsContent}>
                      <a href={expl.url} target="_blank" rel="noreferrer">
                        {expl.url}
                      </a>
                    </div>
                  </div>
                ))}
            </div>
          </WrapperMenu>
        );
      })}
    </>
  );
}
