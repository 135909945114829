import { Checkbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs';

type CheckboxDataProps = {
  value: boolean;
  onChange: (event: CheckboxChangeEvent) => void;
  label: string;
  checked: boolean;
  disabled?: boolean;
  className?: string;
};
export function CheckBox({
  value,
  onChange,
  label,
  checked,
  disabled,
  className,
}: CheckboxDataProps): React.ReactElement {
  return (
    <div className={className ? `checkbox ${className}` : 'checkbox'}>
      <Checkbox
        value={value}
        onChange={onChange}
        label={label}
        checked={checked}
        disabled={disabled}
      />
    </div>
  );
}
