/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import type { GridCellProps } from '@progress/kendo-react-grid';
import localeHost from '../../../../../utils/i18n/assetLocales/hostList.json';
import { useHostModalContext } from '../../../../../hooks/useHostModalContext';
import type { AssetsHostItemDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/assetsHostsDashboardResponse.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHost;
export function Ip(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { latestInventorization, assetId }: AssetsHostItemDashboardResponse = dataItem;

  const { setHostDetailById } = useHostModalContext();

  return (
    <td>
      {latestInventorization ? (
        <span>{latestInventorization.ip}</span>
      ) : dataItem?.agentValue?.status === 'online' && latestInventorization === null ? (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <span
          onClick={(): void => setHostDetailById(assetId)}
          className="k-button"
          style={{ borderBottom: '1px black dashed' }}
        >
          <span className="k-icon k-i-warning" style={{ color: 'red' }} />
          {localeHost[currentLocale].waitingDetails}
        </span>
      ) : (
        <span>{localeHost[currentLocale].form.waitingForData}</span>
      )}
    </td>
  );
}
