import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import localeVmTask from '../../../../../utils/i18n/taskLocale/vmTask.json';
import { FirstColumn } from './metaBlockTables/FirstColumn';
import { SecondColumn } from './metaBlockTables/SecondColumn';
import { ThirdColumn } from './metaBlockTables/ThirdColumn';
import { SecondColumnImage } from './metaBlockTables/SecondColumnImage';
import type { AuditDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/auditDashboardResponse.v1';
import { calculateClassesCvssSeverity } from '../../../../../utils/helpers/task-helpers';
import style from '../../TaskDescription.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeVmTask;
export function MetaBlock({
  auditInfo,
}: {
  auditInfo: AuditDashboardResponse | undefined;
}): React.ReactElement {
  const urlAuditId = useParams<{ auditId: string }>();

  const vulnSoftTotal = auditInfo?.result.vulnerableObjects.length;

  const dataClassesCvssSeverityT = calculateClassesCvssSeverity(auditInfo);

  return (
    <div className="common-summary-page">
      <FirstColumn currentLocale={currentLocale} auditInfo={auditInfo} />
      {auditInfo?.asset.type === 'image' ? (
        <SecondColumnImage currentLocale={currentLocale} auditInfo={auditInfo} />
      ) : (
        <SecondColumn currentLocale={currentLocale} auditInfo={auditInfo} />
      )}
      <ThirdColumn
        vulnSoftTotal={vulnSoftTotal}
        dataClassesCvssSeverity={dataClassesCvssSeverityT}
        currentLocale={currentLocale}
        type={auditInfo?.type}
      />
      {auditInfo &&
        auditInfo.type !== 'hypervisor' &&
        auditInfo.payload.meta?.createdAt &&
        auditInfo.payload.meta?.taskAuditId &&
        urlAuditId.auditId !== auditInfo.payload.meta?.taskAuditId && (
          <div className={style.auditInfoMetaWrap}>
            <img src={`${process.env.PUBLIC_URL}/images/auditMeta.svg`} alt="audit" />
            <span>{localeVmTask[currentLocale].summary.auditInfoMeta}</span>
            <NavLink
              className="link-underline"
              to={`/lk/task/actions/${auditInfo.payload.meta?.taskAuditId}/audit`}
            >
              {new Date(auditInfo.payload.meta?.createdAt).toLocaleString()}
            </NavLink>
          </div>
        )}
    </div>
  );
}
