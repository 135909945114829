/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-nested-ternary */
import { Grid, GridColumn, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useMemo, useState } from 'react';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { LAZY_PAGEABLE_DATA } from '../../../../../utils/helpers/constants';
import {
  createFromListInvData,
  defineWrapperStatuses,
  type DiffFilterTypes,
  type IDiffInvElementInfo,
} from '../../../../../utils/helpers/difference-modal-helper';
import type {
  InventorizationDifferenceFirewall,
  InventorizationDifferenceRouter,
} from '../../../../../types/__generated/on-premise-solution/api/inventorizationDifference.v1';
import { WrapperMenu } from '../../../Task/taskIDetails/iDetailsComponents/WrapperMenu';
import { DiffTypeCell } from '../cells/DiffTypeCell';
import { DiffStandartCell } from '../cells/DiffStandartCell';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function DiffSoftPackageNetDevices({
  data,
  filter,
}: {
  data: InventorizationDifferenceRouter | InventorizationDifferenceFirewall;
  filter: DiffFilterTypes;
}): React.ReactElement {
  const packageData = useMemo(
    () => createFromListInvData(data.packages as unknown as IDiffInvElementInfo[], filter),
    [data.packages, filter],
  );
  const [page, setPage] = useState({ take: 5, skip: 0 });

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  return packageData && packageData.length ? (
    <WrapperMenu
      title={locale[currentLocale].packages.title}
      diffStatuses={filter === 'all' ? defineWrapperStatuses(data.packages) : undefined}
    >
      <Grid
        data={packageData.slice(page.skip, page.take + page.skip)}
        pageable={LAZY_PAGEABLE_DATA}
        scrollable="none"
        total={packageData.length}
        pageSize={page.take}
        skip={page.skip}
        onPageChange={pageChange}
        className="no-margin-top"
      >
        <GridColumn
          field="diffType"
          cell={DiffTypeCell}
          title=" "
          width={20}
          sortable={false}
          headerClassName="no-cursor"
        />
        <GridColumn
          field="name"
          title={locale[currentLocale].packages.name}
          cell={DiffStandartCell}
          sortable={false}
          headerClassName="no-cursor"
        />
        <GridColumn
          field="scheduled"
          title={locale[currentLocale].packages.scheduled}
          cell={DiffStandartCell}
          sortable={false}
          headerClassName="no-cursor"
        />
        <GridColumn
          field="version"
          title={locale[currentLocale].packages.version}
          cell={DiffStandartCell}
          sortable={false}
          headerClassName="no-cursor"
        />
      </Grid>
    </WrapperMenu>
  ) : (
    <></>
  );
}
