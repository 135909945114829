/* eslint-disable no-lone-blocks */
import type { GridCellProps } from '@progress/kendo-react-grid';
import { RadioButton, RadioButtonChangeEvent } from '@progress/kendo-react-inputs';
import { memo, useState } from 'react';
import { useParams } from 'react-router';
import { useWhiteListContext } from '../../../hooks/useWhiteListContext';
import { useHostModalContext } from '../../../hooks/useHostModalContext';
import { useRegisterContext } from '../../../hooks/useRegisterContext';
import { useNetDeviceContext } from '../../../hooks/useNetDeviceContext';

function CellSelectWL(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { assetId } = useParams<{ assetId: string }>();

  const { selectedWhitelist, setSelectedWhitelist } = useWhiteListContext();

  const { setHostForm } = useHostModalContext();

  const { setImageForm } = useRegisterContext();

  const { setDeviceForm } = useNetDeviceContext();

  const [selectedValue, setSelectedValue] = useState('');

  const handleChange = (e: RadioButtonChangeEvent): void => {
    setSelectedValue(e.value);
    setSelectedWhitelist([
      { name: dataItem.name, bulletinWhitelistId: dataItem.bulletinWhitelistId },
    ]);
    if (document.location.pathname.includes('/lk/assets/hosts/')) {
      setHostForm([
        {
          assetId,
        },
      ]);
    }
    if (document.location.pathname.includes('/lk/assets/devices/')) {
      setDeviceForm([
        {
          assetId,
          hostname: '',
        },
      ]);
    }
    if (document.location.pathname.includes('/lk/assets/images/')) {
      setImageForm([
        {
          assetId,
          imageNames: '',
        },
      ]);
    }
  };

  return (
    <td>
      <RadioButton
        name="group1"
        value={dataItem.name}
        checked={
          (selectedWhitelist &&
            Array.isArray(selectedWhitelist) &&
            selectedWhitelist[0].bulletinWhitelistId === dataItem.bulletinWhitelistId) ||
          false
        }
        onChange={handleChange}
      />
    </td>
  );
}

export default memo(CellSelectWL);
