/* eslint-disable react/jsx-pascal-case */
import { Grid, GridColumn as Column, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { PAGEABLE_DATA } from '../../utils/helpers/constants';
import type { ICommonAccountList } from '../../utils/helpers/types';
import { handleSort } from '../../utils/sorting-in-table';
import localeAccount from '../../utils/i18n/accountLocales/accountList.json';
import { AccountMenu } from '../features/User/AccountMenu';
import { CellSelectAccount } from '../features/User/Cells/CellSelectAccount';
import { CellCommonDate } from './baseElements/CellCommonDate';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAccount;

export function CommonAccountList(props: ICommonAccountList): React.ReactElement {
  const { sort, data, page, total, pageChange, setSort, dataForHooks } = props;

  return (
    <LocalizationProvider language={currentLocale}>
      <IntlProvider locale={currentLocale.substring(0, 2)}>
        <Grid
          pageable={total && total > 15 ? PAGEABLE_DATA : false}
          sortable
          sort={sort}
          data={data}
          skip={page.skip}
          take={page.take}
          total={total || 0}
          onPageChange={pageChange}
          onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
          scrollable="none"
          className={total && total < 15 ? 'no-pageable-k-grid' : ''}
        >
          {dataForHooks.componentName === 'AddAccountList' && (
            <Column
              field="addAccount"
              title={localeAccount[currentLocale].titleForColumnGrid.selectAccount}
              cell={CellSelectAccount}
              width="80px"
            />
          )}
          <Column
            field="name"
            title={localeAccount[currentLocale].titleForColumnGrid.name}
            filter="text"
            width="420px"
          />
          <Column field="accountId" title="ID" filter="text" width="400px" />
          <Column
            field="totalAssets"
            title={localeAccount[currentLocale].titleForColumnGrid.assetCount}
            filter="text"
            width="100px"
            className="number"
          />
          <Column
            field="totalUsers"
            title={localeAccount[currentLocale].titleForColumnGrid.userCount}
            filter="text"
            className="number"
            width="160px"
          />
          <Column
            cell={CellCommonDate}
            field="createdAt"
            title={localeAccount[currentLocale].titleForColumnGrid.createdAt}
            filter="text"
          />
          {dataForHooks.componentName === 'AccountList' && (
            <Column cell={AccountMenu} width="40px" />
          )}
        </Grid>
      </IntlProvider>
    </LocalizationProvider>
  );
}
