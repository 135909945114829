import { useCallback, useState } from 'react';
import { Stepper, StepperChangeEvent } from '@progress/kendo-react-layout';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { Button } from '../../../../common/baseElements/Button';
import styles from '../AddAssetForm.module.scss';
import { useHostCreateContext } from '../../../../../hooks/useHostCreateContext';
import {
  BACKEND_DOWNLOAD_URL,
  STEPPER_WINDOWS_ASSET,
} from '../../../../../utils/helpers/constants';
import localeWindowsForm from '../../../../../utils/i18n/dashboardLocale/addWindowsAssetForm.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeWindowsForm;

export function CreateWindowsHost(): React.ReactElement {
  const { hostCreateData, handleLinkDowload } = useHostCreateContext();

  const [step, setStep] = useState(0);

  const [bit, setBit] = useState('x64');

  const bits = ['x32', 'x64'];

  const handleChange = (e: StepperChangeEvent): void => {
    setStep(e.value);
  };

  const handleDownloadBinary = (): void => {
    handleLinkDowload(`${BACKEND_DOWNLOAD_URL}/v1/agent/agent-windows-installer-${bit}-latest.msi`);
    setStep(1);
  };

  const handleDownloadConf = (): void => {
    setStep(2);
    handleLinkDowload(hostCreateData?.url);
  };

  const handleBit = useCallback((e: DropDownListChangeEvent): void => {
    const selectedBit = e.target.value as string;
    setBit(selectedBit);
  }, []);

  return (
    <div className="create-windows-asset__content">
      <div className={styles.asset_form}>
        <Stepper
          value={step}
          onChange={handleChange}
          items={STEPPER_WINDOWS_ASSET}
          orientation="vertical"
          className="windows-install-agent-stepper"
        />
        <div className={styles.content}>
          <div className="asset-form__download">
            <p style={{ marginTop: '8px' }}>{localeWindowsForm[currentLocale].form.step0}</p>
            <DropDownList data={bits} value={bit} onChange={handleBit} />
            <p>{localeWindowsForm[currentLocale].form.step1}</p>
            <Button className="btn-download" icon="download" onClick={handleDownloadBinary}>
              {localeWindowsForm[currentLocale].btnDownloadMsi} {bit}
            </Button>
            <p>{localeWindowsForm[currentLocale].form.step2}</p>
            <Button className="btn-download" icon="download" onClick={handleDownloadConf}>
              {localeWindowsForm[currentLocale].btnDownloadConf}
            </Button>
            <p>{localeWindowsForm[currentLocale].form.step3}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
