/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useState } from 'react';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { useHistory } from 'react-router';
import localePkgUpdate from '../../../../utils/i18n/taskLocale/auditTask.json';
import type { ICreateKbsData } from '../../../../utils/helpers/task-helpers';
import { TaskKbResult } from './TaskKbResult';
import { TaskKbLog } from './TaskKbLog';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localePkgUpdate;

export function TaskTabsKb({
  kbsData,
  title,
  taskActionId,
}: {
  kbsData: ICreateKbsData[];
  title: string;
  taskActionId: string;
}): React.ReactElement {
  const history = useHistory();

  const [selected, setSelected] = useState<number>(0);

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      if (e.selected === 0) {
        history.replace(`/lk/task/actions/${taskActionId}/kb_install/result`);
        setSelected(e.selected);
      }
      if (e.selected === 1 && !document.location.pathname.includes('/logs')) {
        history.replace(`/lk/task/actions/${taskActionId}/kb_install/logs`);
        setSelected(e.selected);
      }
    },
    [history, taskActionId],
  );

  return (
    <TabStrip
      selected={document.location.pathname.includes('/result') ? 0 : 1}
      onSelect={handleSelect}
    >
      <TabStripTab
        title={localePkgUpdate[currentLocale].pkgResult}
        disabled={!kbsData || kbsData.length === 0}
      >
        <TaskKbResult kbsData={kbsData} title={title} />
      </TabStripTab>
      <TabStripTab title={localePkgUpdate[currentLocale].logs}>
        <TaskKbLog taskActionId={taskActionId} />
      </TabStripTab>
    </TabStrip>
  );
}
