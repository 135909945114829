import styles from './NewTask.module.scss';
import localeNewTask from '../../../../utils/i18n/taskLocale/newTask.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;

export function TaskActionNotification(): React.ReactElement {
  return (
    <div>
      <span>{localeNewTask[currentLocale].form.todoActions.notificationForTaskActions1}</span>
      <div className={styles.taskActionNotification}>
        <table>
          <thead>
            <tr>
              <th className={styles.col180px}>
                {localeNewTask[currentLocale].form.notification.action}
              </th>
              <th>{localeNewTask[currentLocale].form.notification.host}</th>
              <th>{localeNewTask[currentLocale].form.notification.device}</th>
              <th>{localeNewTask[currentLocale].form.notification.image}</th>
              <th>{localeNewTask[currentLocale].form.notification.registry}</th>
              <th>{localeNewTask[currentLocale].form.notification.hostGroup}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={styles.tdTitleWeight}>
                {localeNewTask[currentLocale].form.notification.audit}
              </td>
              <td>+</td>
              <td>+</td>
              <td>+</td>
              <td />
              <td>+</td>
            </tr>
            <tr>
              <td className={styles.tdTitle}>
                <span className="k-icon k-font-icon k-i-minus-sm" />{' '}
                <span>{localeNewTask[currentLocale].form.notification.useEL}</span>
              </td>
              <td>+</td>
              <td>+</td>
              <td>+</td>
              <td />
              <td>+</td>
            </tr>
            <tr>
              <td className={styles.tdTitle}>
                <span className="k-icon k-font-icon k-i-minus-sm" />{' '}
                <span>{localeNewTask[currentLocale].form.notification.withoutScanning}</span>
              </td>
              <td>+</td>
              <td>+</td>
              <td>+</td>
              <td />
              <td>+</td>
            </tr>
            <tr>
              <td className={styles.tdTitle}>
                <span className="k-icon k-font-icon k-i-minus-sm" />{' '}
                <span>{localeNewTask[currentLocale].form.notification.dockerImageAudit}</span>
              </td>
              <td>+</td>
              <td />
              <td />
              <td>+</td>
              <td>+</td>
            </tr>
            <tr>
              <td className={styles.tdTitleWeight}>
                {localeNewTask[currentLocale].form.notification.inventorization}
              </td>
              <td>+</td>
              <td>+</td>
              <td />
              <td />
              <td>+</td>
            </tr>
            <tr>
              <td className={styles.tdTitleWeight}>
                {localeNewTask[currentLocale].form.notification.agentUpgrade}
              </td>
              <td>+</td>
              <td />
              <td />
              <td />
              <td>+</td>
            </tr>
            <tr>
              <td className={styles.tdTitleWeight}>
                {localeNewTask[currentLocale].form.notification.packageUpgrade}
              </td>
              <td>+</td>
              <td />
              <td />
              <td />
              <td>+</td>
            </tr>
            <tr>
              <td className={styles.tdTitleWeight}>
                {localeNewTask[currentLocale].form.notification.installKB}
              </td>
              <td>+</td>
              <td />
              <td />
              <td />
              <td>+</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
