/* eslint-disable react/jsx-pascal-case */
import { loadMessages } from '@progress/kendo-react-intl';
import { useQuery, useQueryClient } from 'react-query';
import { useEffect, useRef } from 'react';
import { Loader } from '@progress/kendo-react-indicators/dist/npm/loader/Loader';
import { nanoid } from 'nanoid';
import localePkgUpdate from '../../../../utils/i18n/taskLocale/auditTask.json';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getTaskActionById } from '../../../../services/task-service';
import type { EnrichedTaskActionResponse } from '../../../../types/__generated/on-premise-solution/api/enrichedTaskActionResponse.v1';
import styles from '../TaskDescription.module.scss';
import { useScrollLog } from '../../../../hooks/components/useScrollLog';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localePkgUpdate;

loadMessages(localePkgUpdate[currentLocale], currentLocale);

export function TaskPConsole({
  taskActionId,
}: {
  taskActionId: string | undefined;
}): React.ReactElement {
  const queryClient = useQueryClient();

  const parentRef = useRef<HTMLHeadingElement>(null);

  const childRef = useRef<HTMLHeadingElement>(null);

  const resultDataOutput = useRef<false | (string | null | undefined)[] | undefined>();

  const resultCommand = useRef<string | false | null | undefined>();

  const lengthOfpartialResults = useRef<number | false | null | undefined>(null);

  const lengthOfDataState = useRef<number | undefined>(undefined);

  const query = useQuery<EnrichedTaskActionResponse, ApiError>(
    ['taskActionById', taskActionId],
    () => getTaskActionById(taskActionId),
    {
      onSuccess: (data) => {
        resultDataOutput.current =
          data.type === 'package_update' &&
          // !data.partialResults.hasPartialResults &&
          data.result?.data?.map((d) => d.output);

        resultCommand.current =
          data.type === 'package_update' && data.result?.data && data.result?.data[0].command;

        lengthOfpartialResults.current =
          data.type === 'package_update' &&
          data.partialResults?.data &&
          Array.isArray(data.partialResults?.data) &&
          data.partialResults?.data.length;
      },
      keepPreviousData: true,
      enabled: taskActionId !== undefined,
    },
  );

  const dataState = useScrollLog(parentRef, childRef, query.data);

  lengthOfDataState.current = dataState.current?.length;

  // Для скролинга вниз при заходе на стр
  useEffect(() => {
    if (childRef.current && query.data?.status === 'success') {
      childRef.current.scrollIntoView(false);
    }
  }, [query.data?.status]);

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        await queryClient.invalidateQueries('taskActionById');
        if (
          childRef.current &&
          query.data?.status === 'processing' &&
          query.data.type === 'package_update' &&
          lengthOfpartialResults.current &&
          lengthOfDataState.current &&
          lengthOfDataState.current !== lengthOfpartialResults.current
        ) {
          childRef.current.scrollIntoView(false);
        }
      } catch (e) {
        console.log(e);
      }
    }, 5000);

    if (query.data?.status !== 'processing') {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [dataState.current?.length, query.data, queryClient]);

  return (
    <div className={styles.task_pkg_console}>
      <div className={styles.title}>
        {dataState &&
        dataState.current &&
        dataState.current[0] &&
        dataState.current[0].type === 'package_update' &&
        'command' in dataState.current[0] ? (
          <span>{dataState.current[0].command}</span>
        ) : (
          <span>{resultCommand.current}</span>
        )}
      </div>
      <div ref={parentRef} className={styles.wrapper}>
        {dataState && dataState.current ? (
          <div className={styles.content}>
            {dataState.current.map((d) => (
              <span key={nanoid()}>{d.type === 'package_update' && 'output' in d && d.output}</span>
            ))}
          </div>
        ) : (
          <div className={styles.content}>
            <span>{resultDataOutput.current}</span>
            {query.data?.status === 'processing' && (
              <Loader
                style={{ color: 'white', marginLeft: '50%' }}
                size="large"
                type="converging-spinner"
              />
            )}
          </div>
        )}
        <div ref={childRef} style={{ padding: '4px', margin: '16px' }} />
      </div>
    </div>
  );
}
