/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import type { GridCellProps } from '@progress/kendo-react-grid';

export function StandartCell(props: GridCellProps): React.ReactElement {
  const { dataItem, field, className, style } = props;

  const fieldArray = field ? field.split('.') : [];
  let value = dataItem;

  if (!fieldArray) value = null;
  else if (fieldArray.length === 1) value = dataItem[fieldArray[0]];
  else if (fieldArray.length > 1) {
    fieldArray.forEach((f) => {
      value = value ? value[f] : value;
    });
  }

  return value && value !== '-' && value !== 0 ? (
    <td
      title={className && className.includes('title') ? value : ''}
      className={className || ''}
      style={style}
    >
      {value}
    </td>
  ) : (
    <td className={className || ''}>-</td>
  );
}
