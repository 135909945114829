import '../../../Host/hostComponents/HostDescription.module.scss';
import localeHostDescription from '../../../../../utils/i18n/assetLocales/hostDescription.json';
import type { AssetRouterDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/assetRouterDashboardResponse.v1';
import type { AssetDeviceDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/assetDeviceDashboardResponse.v1';
import type {
  AssetHypervisorDashboardResponse,
  AssetSwitchDashboardResponse,
} from '../../../../../types/__generated/on-premise-solution/api/assetsDevicesDashboardResponse.v1';
import {
  createOsNameForCheckpoint,
  createOsNameForHypervisor,
} from '../../../../../utils/helpers/audit-helpers';

export function TableFDeviceMetaBlock({
  data,
  currentLocale,
}: {
  data:
    | AssetRouterDashboardResponse
    | AssetDeviceDashboardResponse
    | AssetSwitchDashboardResponse
    | AssetHypervisorDashboardResponse
    | undefined;
  currentLocale: 'ru-RU' | 'en-EN';
}): React.ReactElement {
  function createOsName(): string | null | undefined {
    const Os =
      data?.latestInventorization &&
      data?.latestInventorization.os &&
      `${data?.latestInventorization.os?.name} ${
        data?.latestInventorization && data?.latestInventorization.os.version
      }`;

    if (data?.type === 'hypervisor') {
      return createOsNameForHypervisor(data);
    }

    if (data?.class === 'checkpoint') {
      return createOsNameForCheckpoint(data);
    }

    return Os;
  }

  const Os = createOsName();

  return (
    <table className="column">
      <tbody>
        <tr>
          <td>
            <span>{localeHostDescription[currentLocale].summary.type}</span>
          </td>
          <td>{data?.type || '-'}</td>
        </tr>
        <tr>
          <td>
            <span>
              <span>{localeHostDescription[currentLocale].summary.os}</span>
            </span>
          </td>
          <td className="device-os" title={Os || ''}>
            {Os || '-'}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
