import type { GridCellProps } from '@progress/kendo-react-grid';
import localeAuditTask from '../../../../../utils/i18n/taskLocale/auditTask.json';
import type { IPkgUpdateType } from '../../../../../utils/helpers/types';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

export function CellStatus(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  return (
    <td>
      {dataItem.status ? (
        <span>
          {
            localeAuditTask[currentLocale].packageList.typeVal[
              dataItem.status as keyof IPkgUpdateType
            ]
          }
        </span>
      ) : (
        <span>-</span>
      )}
    </td>
  );
}
