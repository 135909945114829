import { Filter } from '@progress/kendo-react-data-tools';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import type { IDataHooks } from '../../../../utils/helpers/types';
import '../../Host/hostList/HostList.scss';
import { СommonTableLoader } from '../../../common/tableLoader/CommonTableLoader';
import localeHost from '../../../../utils/i18n/assetLocales/hostList.json';
import { FILTER_REGISTRY_LIST } from '../../../../utils/helpers/constant-serv-filter-host';
import { useRegistriesList } from '../../../../hooks/components/useRegistryList';
import { CommonRegistryList } from '../../../common/CommonRegistryList';
import { getUrlList } from '../../../../utils/helpers/getUrl-list-helper';
import { Input } from '../../../common/baseElements/Input';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHost;

export function RegistryList(): React.ReactElement {
  const urlParams = getUrlList('RegistryList');

  const dataForHooks: IDataHooks = {
    name: 'name',
    componentName: 'RegistryList',
  };

  const {
    filter,
    onFilterChange,
    sort,
    page,
    total,
    pageChange,
    setSort,
    isLoading,
    isFetching,
    filterStatus,
    isError,
    errorMessage,
    dataRegistry,
    handleCustomSearch,
    customSearch,
  } = useRegistriesList(urlParams, dataForHooks, null);

  return (
    <div className="registry-list">
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <div className="filter-line">
            <Filter
              value={filter}
              onChange={onFilterChange}
              fields={FILTER_REGISTRY_LIST[currentLocale]}
            />
            {filter.filters.length === 0 && (
              <Input
                name="customSearch"
                placeholder={localeHost[currentLocale].customSearchRegistry}
                value={customSearch}
                onChange={(e): void => handleCustomSearch(e)}
              />
            )}
          </div>
        </IntlProvider>
      </LocalizationProvider>
      <CommonRegistryList
        sort={sort}
        dataRegistry={dataRegistry}
        page={page}
        total={total}
        pageChange={pageChange}
        setSort={setSort}
        dataForHooks={dataForHooks}
      />
      <СommonTableLoader queryData={filterStatus} filteringData={filterStatus} />{' '}
      {isError && <div className="info alert alert-danger">Error: {errorMessage}</div>}
    </div>
  );
}
