import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import type { InventorizationResponse } from '../../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import { WrapperMenu } from './WrapperMenu';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function CpuNetDevices({ data }: { data: InventorizationResponse }): React.ReactElement {
  if (
    ((data.type === 'router' || data.type === 'firewall') && data.class === 'mikrotik') ||
    data.type === 'hypervisor'
  )
    return (
      <WrapperMenu title={locale[currentLocale].cpu.title}>
        <table className="column">
          <tr>
            <td>{locale[currentLocale].cpuNetDevices.model}</td>
            {data.result.cpu_info?.model ? <td>{data.result.cpu_info?.model}</td> : <td>-</td>}
          </tr>
          <tr>
            <td>{locale[currentLocale].cpuNetDevices.frequency}</td>
            {data.result.cpu_info?.frequency ? (
              <td>{data.result.cpu_info?.frequency}</td>
            ) : (
              <td>-</td>
            )}
          </tr>
          <tr>
            <td>{locale[currentLocale].cpuNetDevices.count}</td>
            {data.result.cpu_info?.count ? <td>{data.result.cpu_info?.count}</td> : <td>-</td>}
          </tr>
          {data.type !== 'hypervisor' && (
            <tr>
              <td>{locale[currentLocale].cpuNetDevices.load}</td>
              {data.result.cpu_info?.load ? <td>{data.result.cpu_info?.load}</td> : <td>-</td>}
            </tr>
          )}
        </table>
      </WrapperMenu>
    );

  return <div />;
}
