import type { GridCellProps } from '@progress/kendo-react-grid';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Button } from '../../../../common/baseElements/Button';
import type { ApiError } from '../../../../../types/__generated/on-premise-solution/api/apiError.v1';
import localeImReg from '../../../../../utils/i18n/imageRegister/ImageRegister.json';
import type { AssetDeletionResponse } from '../../../../../types/__generated/on-premise-solution/api/assetDeletionResponse.v1';
import { deleteImage } from '../../../../../services/dashboard-service';
import type { AssetImageDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/assetsImagesDashboardResponse.v1';
import { getUserData } from '../../../../../services/local-storage-service';
import { useCreateTask } from '../../../../../hooks/components/useCreateTask';
import { DeleteConfirmModal } from '../../../../common/modal/DeleteConfirmModal';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeImReg;

export function ImageMenuCell(props: GridCellProps): React.ReactElement {
  const currentUser = getUserData();

  const { dataItem } = props;

  const { assetId }: AssetImageDashboardResponse = dataItem;

  const queryClient = useQueryClient();

  const [isConfirm, setConfirm] = useState(false);

  const { createTaskForImage } = useCreateTask();

  const handleConfirm = (): void => {
    setConfirm(true);
  };

  const mutation = useMutation<AssetDeletionResponse | null, ApiError, string>(
    (payload) => deleteImage(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('ImageList');
      },
    },
  );

  const handleDelete = (): void => {
    mutation.mutate(assetId);
    setConfirm(false);
  };

  return (
    <>
      {isConfirm && (
        <DeleteConfirmModal
          type="asset"
          name={dataItem?.imageNames[0] ? dataItem?.imageNames[0] : dataItem?.assetId}
          handleDelete={handleDelete}
          onClose={(): void => setConfirm(false)}
          errorMessage={mutation.error?.message}
        />
      )}
      <td className="cell_menu">
        <div className="groups__menu">
          <div className="dropdown__container">
            <span className="k-icon k-i-more-vertical" />
            <div className="dropdown__content">
              <Button
                fill="menu"
                onClick={(): void => {
                  createTaskForImage(
                    'image',
                    dataItem?.imageNames[0],
                    dataItem?.imageId,
                    dataItem?.assetId,
                  );
                }}
              >
                {localeImReg[currentLocale].btnCreateTask}
              </Button>
              {currentUser?.role !== 'reader_user' && (
                <Button
                  fill="menu"
                  onClick={handleConfirm}
                  disabled={
                    currentUser?.role === 'user' && currentUser.id !== dataItem.createdBy.id
                  }
                >
                  {localeImReg[currentLocale].btnDelete}
                </Button>
              )}
            </div>
          </div>
        </div>
      </td>
    </>
  );
}
