/* eslint-disable react/jsx-pascal-case */
import { orderBy } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import type { SortDescriptor } from '@progress/kendo-data-query';
import { useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import localeSettings from '../../../utils/i18n/settingsLocales/settings.json';
import { CellChannelName } from './Cells/CellChannelName';
import { CellDatetime } from './Cells/CellDatetime';
import { CellModifiedAt } from './Cells/CellModifiedAt';
import { CellDescription } from './Cells/CellDescription';
import styles from './Settings.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

export function ChannelTable({
  data,
  sort,
}: {
  data: any;
  sort: SortDescriptor[];
}): React.ReactElement {
  const [isVisible, setVisible] = useState<boolean>(false);

  const handleContent = (): void => setVisible((prev) => !prev);

  return (
    <>
      <table className={`horizontal ${styles.channel_group}`}>
        <tr>
          <td className={styles.channel_name_row}>
            {data.children ? (
              <div className="wrapper-menu">
                <div className="row">
                  <Button
                    className={isVisible ? 'active' : ''}
                    icon={isVisible ? 'minus' : 'plus'}
                    onClick={handleContent}
                    style={{ padding: '4px' }}
                  />
                  <span
                    onClick={handleContent}
                    className="k-button k-button-flat channel-name row-title"
                    role="button"
                    tabIndex={0}
                    onKeyPress={handleContent}
                  >
                    {data.group?.replace('@*', '')}
                  </span>
                </div>
              </div>
            ) : (
              <div className="wrapper-menu">
                <div className="row">
                  <span style={{ width: '20px' }} className="k-icon k-i-circle" />
                  <span className="k-button k-button-flat channel-name row-title">
                    {data.group}
                  </span>
                </div>
              </div>
            )}
          </td>
          <td className={styles.channel_type_row}>{data.type}</td>
          <td className={styles.channel_release_row}>
            {data.datetime && data.datetime !== '0'
              ? new Date(data.datetime).toLocaleString()
              : '-'}
          </td>
          <td className={styles.channel_update_row}>
            {data.modifiedAt && data.modifiedAt !== '0'
              ? new Date(data.modifiedAt).toLocaleString()
              : '-'}
          </td>
          <td
            title={
              currentLocale === 'ru-RU' ? data['x-ru-description'] || '-' : data.description || '-'
            }
          >
            {currentLocale === 'ru-RU' ? data['x-ru-description'] || '-' : data.description || '-'}
          </td>
        </tr>
      </table>
      {/* <h4>{data.group}</h4> */}
      {data.children && isVisible && (
        <Grid
          data={orderBy(data.children, sort)}
          sortable
          sort={sort}
          className={`${styles.channel_table} no-margin-top`}
          scrollable="none"
        >
          <Column
            field="name"
            title={localeSettings[currentLocale].formUpdate.channelsForm.code}
            cell={CellChannelName}
            width="238px"
          />
          <Column
            field="type"
            title={localeSettings[currentLocale].formUpdate.channelsForm.type}
            //  className={styles.channel_type_row}
            width="100px"
          />
          <Column
            field="datetime"
            title={localeSettings[currentLocale].formUpdate.channelsForm.release}
            cell={CellDatetime}
            width="228px"
          />
          <Column
            field="modifiedAt"
            title={localeSettings[currentLocale].formUpdate.channelsForm.modified}
            cell={CellModifiedAt}
            width="248px"
          />
          <Column
            field="description"
            title={localeSettings[currentLocale].formUpdate.channelsForm.description}
            cell={CellDescription}
          />
        </Grid>
      )}
    </>
  );
}
