import {
  CSSProperties,
  useCallback,
  createContext,
  JSXElementConstructor,
  ReactElement,
  useContext,
  useMemo,
  useState,
} from 'react';
import { getAgentInstaller } from '../services/asset-service';
import { getAccountId, getUserData } from '../services/local-storage-service';
import { useHostCreateContext } from './useHostCreateContext';
import localeNewTask from '../utils/i18n/taskLocale/newTask.json';
import { useTaskContext } from './useTaskContext';

const currentLocale =
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN';

export interface IHeaderContext {
  styles: CSSProperties | undefined;
  setStyles: (v: CSSProperties | undefined) => void;
  expanded: boolean;
  setExpanded: (v: boolean) => void;
  disabled: boolean;
  setDisabled: (v: boolean) => void;
  showAbout: boolean;
  setShowAbout: (v: boolean) => void;
  openHostDialog: (hostType: string) => Promise<void>;
  createTaskInHeader: (taskType: 'asset' | 'host' | 'report' | 'image' | 'device') => void;
  handleClick: () => void;
}

const HeaderContext = createContext<IHeaderContext | null>(null);

export function HeaderProvider({
  children,
}: {
  children: ReactElement<string | JSXElementConstructor<string>>;
}): React.ReactElement {
  const [styles, setStyles] = useState<CSSProperties | undefined>();

  const [expanded, setExpanded] = useState<boolean>(true);

  const [disabled, setDisabled] = useState<boolean>(false);

  const [showAbout, setShowAbout] = useState<boolean>(false);

  const { setHostCreateData } = useHostCreateContext();

  const { setOpenDialog: setOpenTask, setTaskRepeatData } = useTaskContext();

  const accountId = getAccountId();

  const userData = getUserData();

  const openHostDialog = useCallback(
    async (hostType: string): Promise<void> => {
      if (hostType === 'linux' || hostType === 'windows') {
        const selectAssetType = hostType as 'linux' | 'windows';
        const dataAssetDashboard = await getAgentInstaller(selectAssetType, null);
        setHostCreateData({
          ...dataAssetDashboard,
          assetType: selectAssetType,
          accountId,
        });
      }
    },
    [accountId, setHostCreateData],
  );

  const createTaskInHeader = useCallback(
    (taskType: 'asset' | 'host' | 'report' | 'image' | 'device') => {
      setTaskRepeatData({
        taskName: `${
          localeNewTask[currentLocale].form.defaultTaskName
        }_${new Date().toLocaleDateString()}_${userData?.email}`,
        taskType,
      });
      setOpenTask(true);
    },
    [setOpenTask, setTaskRepeatData, userData?.email],
  );

  const handleClick = useCallback((): void => {
    if (styles) {
      const main = document.getElementsByTagName('main')[0] || undefined;
      const sideBar = document.getElementsByTagName('aside')[0] || undefined;
      const docContent = document.getElementsByTagName('section')[0] || undefined;
      if (main) main.style.marginLeft = !expanded ? '240px' : '';
      if (sideBar) sideBar.style.marginLeft = !expanded ? '240px' : '';
      if (docContent) docContent.style.width = `calc(100vw - 300px${!expanded ? ' - 240px' : ''})`;
    }

    setExpanded(!expanded);
  }, [expanded, styles]);

  const value = useMemo(
    () => ({
      styles,
      setStyles,
      expanded,
      setExpanded,
      disabled,
      setDisabled,
      showAbout,
      setShowAbout,
      openHostDialog,
      createTaskInHeader,
      handleClick,
    }),
    [styles, expanded, disabled, showAbout, openHostDialog, createTaskInHeader, handleClick],
  );

  return <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>;
}

export const useHeaderContext = (): IHeaderContext => {
  const context = useContext(HeaderContext);
  if (context === null) {
    throw new Error('useHeaderContext must be used inside the WebSocketContext.Provider.');
  }

  return context;
};
