import React, { useCallback, useState } from 'react';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { useHistory } from 'react-router';
import locale from '../../../utils/i18n/credentials/credentials.json';
import { CredentialList } from './CredentialList';
import { VaultslList } from './VaultslList';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function TabsCredentials(): React.ReactElement {
  const history = useHistory();

  const [selected, setSelected] = useState<number>(
    document.location.pathname.includes('credentials/list') ? 0 : 1,
  );

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      if (e.selected === 0 && !document.location.pathname.includes('/list')) {
        history.replace('/lk/credentials/list');
        setSelected(e.selected);
      }
      if (e.selected === 1 && !document.location.pathname.includes('/vaults')) {
        history.replace('/lk/credentials/vaults');
        setSelected(e.selected);
      }
      setSelected(e.selected);
    },
    [history],
  );

  return (
    <TabStrip selected={selected} onSelect={handleSelect}>
      <TabStripTab title={locale[currentLocale].tabs.account}>
        <CredentialList />
      </TabStripTab>
      <TabStripTab title={locale[currentLocale].tabs.accountStorage}>
        <VaultslList />
      </TabStripTab>
    </TabStrip>
  );
}
