/* eslint-disable react-hooks/exhaustive-deps */
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import type { TextFilterProps } from '@progress/kendo-react-data-tools/dist/npm/filter/filters/TextFilter';
import type localeTask from '../../../utils/i18n/taskLocale/task.json';
import type { IDaraFilterSelect } from '../../../utils/helpers/types';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTask;

export function FilterDeviceAndUser(props: TextFilterProps): React.ReactElement {
  let dataLevel = [] as IDaraFilterSelect[];

  const { filter, onFilterChange } = props;

  if (document.location.pathname.includes('/users/list')) {
    dataLevel = [
      {
        text: currentLocale === 'ru-RU' ? 'Роль...' : 'Role...',
        val: '',
      },
      {
        text: currentLocale === 'ru-RU' ? 'super_admin' : 'super_admin',
        val: 'super_admin',
      },
      {
        text: currentLocale === 'ru-RU' ? 'account_admin' : 'account_admin',
        val: 'account_admin',
      },
      { text: currentLocale === 'ru-RU' ? 'user' : 'user', val: 'user' },
      { text: currentLocale === 'ru-RU' ? 'reader_user' : 'reader_user', val: 'reader_user' },
    ];
  } else if (document.location.pathname.includes('/audit/exceptions')) {
    dataLevel = [
      {
        text: currentLocale === 'ru-RU' ? 'Статус...' : 'Status...',
        val: '',
      },
      {
        text: currentLocale === 'ru-RU' ? 'Активный' : 'Active',
        val: 'false',
      },
      {
        text: currentLocale === 'ru-RU' ? 'Не активный' : 'Not active',
        val: 'true',
      },
    ];
  } else {
    dataLevel = [
      {
        text: currentLocale === 'ru-RU' ? 'Производитель...' : 'Vendor...',
        val: '',
      },
      { text: currentLocale === 'ru-RU' ? 'cisco' : 'cisco', val: 'cisco' },
      { text: currentLocale === 'ru-RU' ? 'huawei' : 'huawei', val: 'huawei' },
      { text: currentLocale === 'ru-RU' ? 'juniper' : 'juniper', val: 'juniper' },
      { text: currentLocale === 'ru-RU' ? 'fortinet' : 'fortinet', val: 'fortinet' },
      { text: currentLocale === 'ru-RU' ? 'mikrotik' : 'mikrotik', val: 'mikrotik' },
      { text: currentLocale === 'ru-RU' ? 'checkpoint' : 'checkpoint', val: 'checkpoint' },
      { text: currentLocale === 'ru-RU' ? 'vmware_esxi' : 'vmware_esxi', val: 'vmware_esxi' },
    ];
  }

  const handleChange = (event: DropDownListChangeEvent): void => {
    onFilterChange({
      nextFilter: { ...filter, ...{ value: event.target.value.val, text: '' } },
    });
  };

  return (
    <DropDownList
      defaultValue={dataLevel[0]}
      data={dataLevel}
      textField="text"
      dataItemKey="val"
      onChange={handleChange}
    />
  );
}
