import { State, process } from '@progress/kendo-data-query';
import type { ExcelExport } from '@progress/kendo-react-excel-export';

export function useLocalExcelExport(
  dataState: State,
  _export: React.MutableRefObject<ExcelExport | null>,
  data: any,
  setDataState: React.Dispatch<React.SetStateAction<State>>,
): () => void {
  const handleExport = (): void => {
    const { take } = dataState;

    // const vulnIds = [] as string[];

    // data.forEach((v: any) => {
    //   if (v.vulns) {
    //     v.vulns.forEach((n: any) => {
    //       vulnIds.push(n.id);
    //     });
    //   }
    // });

    const changedData = data.map((v: any) => {
      if (v.vulns) {
        return {
          ...v,
          vulns: v.vulns.map((n: any) => n.id).join(','),
        };
      }

      return {
        ...v,
      };
    });

    if (_export.current !== null) {
      _export.current.save(process(changedData, { ...dataState, take: changedData.length }));
      setDataState((prevState) => ({ ...prevState, take }));
    }
  };

  return handleExport;
}
