import { Dialog } from '@progress/kendo-react-dialogs';
import { Form, FormElement } from '@progress/kendo-react-form';
import { useQuery } from 'react-query';
import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ErrorBoundary } from 'react-error-boundary';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import hostDescriptionLocale from '../../../../../utils/i18n/assetLocales/hostDescription.json';
import styles from '../../forms/AddAssetForm.module.scss';
import type { AuditDifferenceResponse } from '../../../../../types/__generated/on-premise-solution/api/auditDifferenceResponse.v1';
import type { ApiError } from '../../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getDifferenceAudit, getDiffAuditCheckpoints } from '../../../../../services/asset-service';
import { useDifferenceModalContext } from '../../../../../hooks/useDifferenceModalContext';
import { DifferenceAuditResult } from './DifferenceAuditResult';
import { BoundaryErrorComponent } from '../../../../common/BoundaryErrorComponent';
import { handleError } from '../../../../../utils/errors';
import { useNotificationContext } from '../../../../../hooks/useNotificationContext';
import { useHostDescription } from '../../../../../hooks/components/useHostDescription';
import { DiffByDateTab } from '../DiffByDateTab';
import type { AuditHistory } from '../../../../../types/__generated/on-premise-solution/api/auditHistory.v1';
import { DiffByAuditTab } from './DiffByAuditTab';
import { Button } from '../../../../common/baseElements/Button';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof hostDescriptionLocale;

export function DifferenceAuditModal({
  ip,
  system,
  deviceType,
}: {
  ip?: string | null;
  system?: string;
  deviceType?: string;
}): ReactElement {
  const { hostname: hostName } = useHostDescription();

  const { setAuditOpen, assetType } = useDifferenceModalContext();

  const { createNotification } = useNotificationContext();

  const [localError, setLocalError] = useState<string | null>(null);
  let startDate: Date | null = null;
  let finishDate: Date | null = null;
  const { assetId } = useParams<{ assetId: string }>();
  const [selected, setSelected] = useState<number>(0);
  const [isAuditGraphic, setAuditGraphic] = useState(false);

  const checkpointsQuery = useQuery<AuditHistory, ApiError>(
    ['diffAuditCheckpoints'],
    () => getDiffAuditCheckpoints(assetId),
    { refetchOnWindowFocus: false },
  );

  const { isLoading, error, data, isFetching, refetch, remove } = useQuery<
    AuditDifferenceResponse,
    ApiError
  >(
    ['diffAudit'],
    () =>
      getDifferenceAudit(assetId, startDate?.toISOString() || '', finishDate?.toISOString() || ''),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (d) => {
        if (!startDate)
          startDate = d?.sourceAudits.firstAudit?.createdAt
            ? new Date(d.sourceAudits.firstAudit.createdAt)
            : null;
      },
    },
  );

  useEffect(() => {
    return () => {
      remove();
      checkpointsQuery.remove();
    };
  }, []);

  const onClick = (firstDate: string | undefined, secondDate: string | undefined): void => {
    if (!firstDate || !secondDate)
      setLocalError(hostDescriptionLocale[currentLocale].changes.errors.invalidDates);
    else if (firstDate === secondDate)
      setLocalError(hostDescriptionLocale[currentLocale].changes.errors.sameDates);
    else {
      // setStartDate(new Date(firstDate));
      // setFinishDate(new Date(secondDate));
      startDate = new Date(firstDate);
      finishDate = new Date(secondDate);
      setLocalError(null);
      refetch();
    }
  };

  useEffect(() => {
    if (error) handleError(error, createNotification);
  }, [error, createNotification]);

  const handleSelect = (e: TabStripSelectEventArguments): void => {
    setSelected(e.selected);
  };

  return (
    <Dialog
      autoFocus
      className="biggest difference-modal"
      title={`${hostDescriptionLocale[currentLocale].changes.auditModalTitle} ${
        assetType ? hostDescriptionLocale[currentLocale].changes[assetType] : ''
      }: ${hostName || assetId}`}
      onClose={(): void => setAuditOpen(false)}
    >
      <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
        <Form
          render={(): React.ReactElement => (
            <FormElement className={styles.diff_form}>
              <div className="common-summary-page" style={{ marginBottom: '4px' }}>
                <table className="column">
                  <tbody>
                    {assetType !== 'device' && (
                      <>
                        <tr>
                          <td>{hostDescriptionLocale[currentLocale].summary.hostname}</td>
                          <td>{hostName || '-'}</td>
                        </tr>
                        <tr>
                          <td>{hostDescriptionLocale[currentLocale].summary.ip}</td>
                          <td>{ip || '-'}</td>
                        </tr>
                      </>
                    )}
                    {assetType === 'device' && (
                      <>
                        <tr>
                          <td>
                            <span>{hostDescriptionLocale[currentLocale].summary.type}</span>
                          </td>
                          <td>{deviceType || '-'}</td>
                        </tr>
                        <tr>
                          <td>
                            <span>OS</span>
                          </td>
                          <td className="device-os" title={system}>
                            {system || '-'}
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
                <table className="column">
                  <tbody>
                    {assetType !== 'device' && (
                      <tr style={{ textTransform: 'capitalize' }}>
                        <td>{hostDescriptionLocale[currentLocale].summary.system}</td>
                        <td>{system || '-'}</td>
                      </tr>
                    )}
                    {assetType === 'device' && (
                      <tr>
                        <td>{hostDescriptionLocale[currentLocale].summary.ip}</td>
                        <td>{ip || '-'}</td>
                      </tr>
                    )}
                    <tr>
                      <td>{hostDescriptionLocale[currentLocale].summary.idAsset}</td>
                      <td>{assetId || '-'}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <TabStrip selected={selected} onSelect={handleSelect} style={{ marginTop: '32px' }}>
                <TabStripTab title={hostDescriptionLocale[currentLocale].changes.byAudit}>
                  <DiffByAuditTab
                    onClick={onClick}
                    data={data}
                    isLoading={isLoading || isFetching}
                    auditsData={checkpointsQuery.data}
                    isAuditGraphic={isAuditGraphic}
                    setAuditGraphic={setAuditGraphic}
                    error={error}
                    localError={localError}
                  />
                </TabStripTab>
                <TabStripTab title={hostDescriptionLocale[currentLocale].changes.byDate}>
                  <DiffByDateTab
                    type="audit"
                    startDate={startDate}
                    finishDate={finishDate}
                    onClick={onClick}
                    data={data}
                    isLoading={isLoading || isFetching}
                    auditsData={checkpointsQuery.data}
                    isAuditGraphic={isAuditGraphic}
                    setAuditGraphic={setAuditGraphic}
                    error={error}
                    localError={localError}
                  />
                </TabStripTab>
              </TabStrip>
              <DifferenceAuditResult
                data={data}
                isLoading={isLoading || isFetching}
                error={error}
                localError={localError}
              />
              <div className="k-form-buttons">
                <Button onClick={(): void => setAuditOpen(false)}>
                  {hostDescriptionLocale[currentLocale].changes.btnClose}
                </Button>
              </div>
            </FormElement>
          )}
        />
      </ErrorBoundary>
    </Dialog>
  );
}
