import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import type { InventorizationResponse } from '../../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import { WrapperMenu } from '../../../Task/taskIDetails/iDetailsComponents/WrapperMenu';
import type {
  InventorizationDifferenceFirewall,
  InventorizationDifferenceRouter,
  InventorizationDifferenceSwitch,
} from '../../../../../types/__generated/on-premise-solution/api/inventorizationDifference.v1';
import {
  prepareDiffObjectData,
  type DiffFilterTypes,
} from '../../../../../utils/helpers/difference-modal-helper';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function DiffCpuNetDevices({
  data,
  filter,
}: {
  data:
    | InventorizationDifferenceFirewall
    | InventorizationDifferenceSwitch
    | InventorizationDifferenceRouter;
  filter: DiffFilterTypes;
}): React.ReactElement {
  const cpuData = prepareDiffObjectData(
    {
      closed: data.cpu_info?.old,
      noChanges: data.cpu_info?.noChanges,
      updated: data.cpu_info?.new,
    },
    filter,
  );

  const wrapperStatuses = [];
  if (filter === 'all' && Object.keys(data.cpu_info?.old || {}).length)
    wrapperStatuses.push('closed');
  if (filter === 'all' && Object.keys(data.cpu_info?.new || {}).length) wrapperStatuses.push('new');
  if (filter === 'all' && Object.keys(data.cpu_info?.noChanges || {}).length)
    wrapperStatuses.push('noChanges');

  return Object.keys(cpuData).length ? (
    <WrapperMenu
      title={locale[currentLocale].cpu.title}
      diffStatuses={filter === 'all' ? wrapperStatuses : undefined}
    >
      {('model' in cpuData ||
        'frequency' in cpuData ||
        'count' in cpuData ||
        'load' in cpuData) && (
        <table className="column">
          {'model' in cpuData && (
            <>
              {cpuData.model.map((cpuModel) => (
                <tr>
                  <td className={cpuModel.className}>
                    {locale[currentLocale].cpuNetDevices.model}
                  </td>
                  <td className={cpuModel.className}>{cpuModel.value || '-'}</td>
                </tr>
              ))}
            </>
          )}
          {'frequency' in cpuData && (
            <>
              {cpuData.frequency.map((cpuFrequency) => (
                <tr>
                  <td className={cpuFrequency.className}>
                    {locale[currentLocale].cpuNetDevices.frequency}
                  </td>
                  <td className={cpuFrequency.className}>{cpuFrequency.value || '-'}</td>
                </tr>
              ))}
            </>
          )}
          {'count' in cpuData && (
            <>
              {cpuData.count.map((cpuCount) => (
                <tr>
                  <td className={cpuCount.className}>
                    {locale[currentLocale].cpuNetDevices.count}
                  </td>
                  <td className={cpuCount.className}>
                    {cpuCount.value || cpuCount.value === 0 ? cpuCount.value : '-'}
                  </td>
                </tr>
              ))}
            </>
          )}
          {'load' in cpuData && (
            <>
              {cpuData.load.map((cpuLoad) => (
                <tr>
                  <td className={cpuLoad.className}>{locale[currentLocale].cpuNetDevices.load}</td>
                  <td className={cpuLoad.className}>{cpuLoad.value || '-'}</td>
                </tr>
              ))}
            </>
          )}
        </table>
      )}
    </WrapperMenu>
  ) : (
    <></>
  );

  // if ((data.type === 'router' || data.type === 'firewall') && data.class === 'mikrotik')
  //   return (
  //     <WrapperMenu title={locale[currentLocale].cpu.title}>
  //       <table className="column">
  //         <tr>
  //           <td>{locale[currentLocale].cpuNetDevices.model}</td>
  //           {data.result.cpu_info?.model ? <td>{data.result.cpu_info?.model}</td> : <td>-</td>}
  //         </tr>
  //         <tr>
  //           <td>{locale[currentLocale].cpuNetDevices.frequency}</td>
  //           {data.result.cpu_info?.frequency ? (
  //             <td>{data.result.cpu_info?.frequency}</td>
  //           ) : (
  //             <td>-</td>
  //           )}
  //         </tr>
  //         <tr>
  //           <td>{locale[currentLocale].cpuNetDevices.count}</td>
  //           {data.result.cpu_info?.count ? <td>{data.result.cpu_info?.count}</td> : <td>-</td>}
  //         </tr>
  //         <tr>
  //           <td>{locale[currentLocale].cpuNetDevices.load}</td>
  //           {data.result.cpu_info?.load ? <td>{data.result.cpu_info?.load}</td> : <td>-</td>}
  //         </tr>
  //       </table>
  //     </WrapperMenu>
  //   );

  // return <div />;
}
