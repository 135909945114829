import { Chart, ChartLegend, ChartSeries, ChartSeriesItem } from '@progress/kendo-react-charts';
import { memo } from 'react';
import type { AssetOs } from '../../../../types/__generated/on-premise-solution/api/assetOs.v1';

export function AssetByOs({ data }: { data: AssetOs | undefined }): React.ReactElement {
  const series =
    data &&
    Object.entries(data).map(([key, value]) => {
      // const customValue = `${key[0].toUpperCase()}${key.slice(1)}: ${value}`;
      const customValue = `${key[0].toUpperCase()}${key.slice(1)}`;
      const countValue = value;

      return {
        category: customValue,
        value: countValue,
      };
    });

  return (
    <Chart>
      <ChartLegend position="left" />
      <ChartSeries>
        <ChartSeriesItem
          colorField="green"
          type="bar"
          data={series?.sort(function (a, b) {
            return b.value - a.value;
          })}
          field="value"
          categoryField="category"
          // tooltip={{ visible: true }}
          gap={1}
        />
        {/* <ChartSeriesItem
          type="bar"
          data={series?.sort(function (a, b) {
            return b.value - a.value;
          })}
        /> */}
      </ChartSeries>
    </Chart>
  );
}
// export default memo(AssetByOs);
