/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { nanoid } from 'nanoid';
import { useHistory } from 'react-router';
import { Button } from '../../../common/baseElements/Button';
import styles from './HostDescription.module.scss';
import type { Tag } from '../../../../types/__generated/on-premise-solution/api/assetHostDashboardResponse.v1';
import { useHostActions } from '../../../../hooks/components/useHostActions';
import { AssetTagContextMenu } from '../../../common/contextMenu/AssetTagContextMenu';
import { EditTagBlock } from './EditTagBlock';
import { useAssetActions } from '../../../../hooks/components/useAssetActions';
import { ChangeTagColor } from './ChangeTagColor';
import { useAssetCreatePropContext } from '../../../../hooks/useAssetCreatePropContext';
import { useHostModalContext } from '../../../../hooks/useHostModalContext';

interface ITagBlock {
  assetId: string;
  tags: Tag[] | null | undefined;
  tagIds: string[] | null | undefined;
  componentName?: string;
  domainNameLength?: number;
}
export function TagList(tagListProp: ITagBlock): React.ReactElement {
  const { assetId, tags, tagIds, componentName, domainNameLength } = tagListProp;

  const history = useHistory();

  const { deleTagFromHost } = useHostActions();

  const { getTextColor } = useAssetActions();

  const {
    isTagMenu,
    setTagMenu,
    setShowAddTag,
    isShowAddTag,
    editTagData,
    setEditTagData,
    editTagColor,
    setEditTagColor,
    setTagName,
    hovered,
    setHovered,
    keyValue,
    setKeyValue,
  } = useAssetCreatePropContext();

  const { setTagListInHostList } = useHostModalContext();

  const toggleHover = (val: string): void => setHovered(val);

  const showTagMenu = (keyVal: number): void => {
    if (editTagData) setEditTagData(null);

    if (editTagColor) setEditTagColor(null);

    if (isShowAddTag) setShowAddTag(false);

    setKeyValue(keyVal);

    setTagMenu(true);
  };

  const showAddTagComponent = (e: React.MouseEvent, name: string): void => {
    e.stopPropagation();
    const { target } = e;
    if (componentName === 'HostList' || componentName === 'HostListAllTag') {
      if (setTagListInHostList) setTagListInHostList('');
      history.push(
        `/lk/assets/hosts?skip=0&limit=15&sortField=latestAudit.maxCvssScore&sortOrder=desc&tags.name[$regex]=${name}`,
      );
      setTagName(name);
    } else if (
      target instanceof HTMLElement &&
      (target.className.includes('tagImg') || target.className.includes('tagTitle'))
    ) {
      setShowAddTag(true);
      setEditTagData(null);
      setTagMenu(false);
    }
  };

  return (
    <>
      {tags
        ?.sort((e, n) => {
          // return Number(n.tagId) - Number(e.tagId);
          if (e.name < n.name) {
            return -1;
          }
          if (e.name > n.name) {
            return 1;
          }

          return 0;
        })
        .map((t, i) => {
          if (editTagData?.indexEl === i)
            return (
              <EditTagBlock
                assetId={assetId}
                tags={tags}
                setHovered={setHovered}
                keyValue={keyValue}
                currentTagId={t.tagId}
                beforeEditVal={t.name}
              />
            );

          if (componentName && componentName === 'HostList' && i === 0 && domainNameLength)
            return (
              <div
                className={styles.tagActionsWrap}
                onMouseEnter={(): void => toggleHover(String(i))}
                onMouseLeave={(): void => toggleHover('')}
                onClick={(e): void => showAddTagComponent(e, t.name)}
                key={nanoid()}
              >
                <div
                  key={nanoid()}
                  className={styles.tagContentWrap}
                  style={{ backgroundColor: t.color }}
                  title={t.name && t.name.length >= 30 ? t.name : ''}
                >
                  <span
                    className={styles.tagTitle}
                    style={{ color: getTextColor(t.color) }}
                    title={t.name}
                  >
                    {domainNameLength + t.name.length > 48 ? `${t.name.slice(0, 20)}...` : t.name}
                  </span>
                </div>
                {editTagColor && editTagColor.indexEl === i && componentName === 'HostList' && (
                  <ChangeTagColor />
                )}
              </div>
            );

          if (componentName && componentName === 'HostListAllTag' && i !== 0)
            return (
              <div
                className={styles.tagActionsWrap}
                onMouseEnter={(): void => toggleHover(String(i))}
                onMouseLeave={(): void => toggleHover('')}
                onClick={(e): void => showAddTagComponent(e, t.name)}
                key={nanoid()}
              >
                <div
                  key={nanoid()}
                  className={styles.tagContentWrap}
                  style={{ backgroundColor: t.color }}
                  title={t.name && t.name.length >= 30 ? t.name : ''}
                >
                  <span className={styles.tagTitle} style={{ color: getTextColor(t.color) }}>
                    {t.name}
                  </span>
                </div>
                {editTagColor &&
                  editTagColor.indexEl === i &&
                  componentName === 'HostListAllTag' && <ChangeTagColor />}
              </div>
            );

          if (componentName !== 'HostList' && componentName !== 'HostListAllTag')
            return (
              <div
                className={styles.tagActionsWrap}
                onMouseEnter={(): void => toggleHover(String(i))}
                onMouseLeave={(): void => toggleHover('')}
                onClick={(e): void => showAddTagComponent(e, t.name)}
                key={nanoid()}
              >
                <div
                  key={nanoid()}
                  className={styles.tagContentWrap}
                  style={{ backgroundColor: t.color }}
                  title={t.name && t.name.length > 50 ? t.name : ''}
                >
                  <span
                    className={styles.tagImg}
                    style={{ backgroundColor: getTextColor(t.color) }}
                  />
                  <span className={styles.tagTitle} style={{ color: getTextColor(t.color) }}>
                    {t.name}
                  </span>
                </div>
                {editTagColor && editTagColor.indexEl === i && componentName !== 'AddTag' && (
                  <ChangeTagColor />
                )}
                {hovered !== '' && hovered === String(i) && (
                  <div
                    className={
                      componentName !== 'AddTag'
                        ? styles.hoveredTagActionBlock
                        : styles.hoveredTagActionBlockAdd
                    }
                    style={{ backgroundColor: t.color }}
                  >
                    {componentName !== 'AddTag' && (
                      <Button
                        className="button-noborder"
                        onClick={(): void => showTagMenu(i)}
                        style={{ backgroundColor: t.color, color: getTextColor(t.color) }}
                      >
                        ...
                      </Button>
                    )}
                    <Button
                      className="button-noborder"
                      onClick={(): void => deleTagFromHost(t.tagId, tags, tagIds, assetId)}
                      style={{ backgroundColor: t.color, color: getTextColor(t.color) }}
                    >
                      x
                    </Button>
                  </div>
                )}
                {isTagMenu && keyValue === i && (
                  <AssetTagContextMenu tag={t} setHovered={setHovered} indexEl={i} />
                )}
              </div>
            );

          return null;
        })}
    </>
  );
}
