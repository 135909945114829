import { useState, type ReactElement, useCallback } from 'react';
import {
  TabStrip,
  TabStripTab,
  type TabStripSelectEventArguments,
} from '@progress/kendo-react-layout';
import type { ApiError } from '../../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { LoadingPanel } from '../../../../common/tableLoader/LoadingPanel';
import localeITask from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import styles from '../../forms/AddAssetForm.module.scss';
import hostDescriptionLocale from '../../../../../utils/i18n/assetLocales/hostDescription.json';
import { CommonTooltip } from '../../../../common/CommonTooltip';
import type { InventorizationDifference } from '../../../../../types/__generated/on-premise-solution/api/inventorizationDifference.v1';
import { DiffSoftwareList } from './DiffSoftwareList';
import { DiffSystemIDetails } from './DiffSystemIDetails';
import { DiffServicesLin } from './DiffServicesLin';
import { DiffServicesWin } from './DiffServicesWin';
import { DiffHardwareIDetails } from './DiffHardwareIDetails';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof hostDescriptionLocale;

export function DifferenceInvResult({
  isLoading,
  data,
  error,
  localError,
}: {
  isLoading: boolean;
  data: InventorizationDifference | undefined;
  error: ApiError | null;
  localError?: string | null;
}): ReactElement {
  const [selected, setSelected] = useState(0);

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      setSelected(e.selected);
    },
    [setSelected],
  );

  return (
    <div style={{ position: 'relative', minHeight: '60px', marginTop: '12px' }}>
      {isLoading && <LoadingPanel />}
      {data && !localError && !error && (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h4
              style={{
                fontSize: '20px',
                fontWeight: '400',
                lineHeight: '125%',
                marginBottom: '32px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {hostDescriptionLocale[currentLocale].changes.afterInvsInfo}:
            </h4>
            <div className={styles.vulnsMetricItem}>
              <CommonTooltip>
                <div style={{ padding: '12px' }}>
                  {hostDescriptionLocale[currentLocale].changes.invTooltip.title}.<br />
                  <br />
                  {hostDescriptionLocale[currentLocale].changes.invTooltip.changesInfo}:<br />
                  <div className={styles.diff_legend}>
                    <div>
                      <span
                        style={{ backgroundColor: 'rgba(255, 0, 0, 0.15)' }}
                        className={styles.legend}
                      >
                        -
                      </span>
                      {hostDescriptionLocale[currentLocale].changes.invTooltip.updated}
                    </div>
                    <div>
                      <span
                        style={{ backgroundColor: 'rgba(0, 180, 0, 0.1)' }}
                        className={styles.legend}
                      >
                        +
                      </span>
                      <span>{hostDescriptionLocale[currentLocale].changes.invTooltip.closed}</span>
                    </div>
                    <div>
                      <span className={styles.legend} />
                      {hostDescriptionLocale[currentLocale].changes.invTooltip.noChanges}
                    </div>
                  </div>
                </div>
              </CommonTooltip>
            </div>
          </div>
          <TabStrip selected={selected} onSelect={handleSelect}>
            <TabStripTab title={`${localeITask[currentLocale].tabs.system}`}>
              <DiffSystemIDetails data={data} />
            </TabStripTab>
            {data?.type !== 'router' && data?.type !== 'firewall' && data?.type !== 'switch' && (
              <TabStripTab title={`${localeITask[currentLocale].tabs.software} `}>
                <DiffSoftwareList
                  data={
                    data.type === 'linux' || data.type === 'windows' ? data?.software : undefined
                  }
                />
              </TabStripTab>
            )}
            {data?.type !== 'router' && data?.type !== 'firewall' && data?.type !== 'switch' && (
              <TabStripTab title={`${localeITask[currentLocale].tabs.services}`}>
                {data?.type === 'linux' && <DiffServicesLin data={data.systemd_units} />}
                {data?.type === 'windows' && <DiffServicesWin data={data.services} />}
              </TabStripTab>
            )}
            <TabStripTab title={`${localeITask[currentLocale].tabs.hardware}`}>
              <DiffHardwareIDetails data={data} />
            </TabStripTab>
          </TabStrip>
        </>
      )}
    </div>
  );
}
