/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import localeNewTask from '../../../../utils/i18n/taskLocale/newTask.json';
import { useTaskAddContext } from '../../../../hooks/useTaskAddContext';
import styles from './NewTask.module.scss';

export function TaskDatePicker({
  currentLocale,
}: {
  currentLocale: 'ru-RU' | 'en-EN';
}): React.ReactElement {
  const [errorDateInterval, setErrorDateInterval] = useState('');

  const {
    handleFromTaskAction,
    handleToTaskAction,
    fromDatePickerTaskAction,
    toDatePickerTaskAction,
  } = useTaskAddContext();

  const compareDate = (): void => {
    if (fromDatePickerTaskAction && toDatePickerTaskAction) {
      if (fromDatePickerTaskAction.getTime() > toDatePickerTaskAction.getTime()) {
        setErrorDateInterval(localeNewTask[currentLocale].form.todoActions.errorDateInterval);
      } else if (fromDatePickerTaskAction.getTime() === toDatePickerTaskAction.getTime()) {
        setErrorDateInterval(localeNewTask[currentLocale].form.todoActions.emptyDateInterval);
      } else {
        setErrorDateInterval('');
      }
    }
  };

  useEffect(() => {
    if (toDatePickerTaskAction) {
      setErrorDateInterval('');
    }
  }, [toDatePickerTaskAction]);

  return (
    <div className={styles.ActionTaskDatePicker}>
      <div className="newtask-type">
        <label className="k-label required-label">
          {localeNewTask[currentLocale].form.todoActions.from}
        </label>
        <div className={styles.timeValueWrap}>
          <LocalizationProvider language={currentLocale}>
            <IntlProvider locale={currentLocale.substring(0, 2)}>
              <DatePicker
                // defaultValue={new Date()}
                format="dd-MMM-yyyy HH:mm:ss"
                focusedDate={new Date()}
                name="from"
                onChange={(e): void => handleFromTaskAction(e.target.value)}
                placeholder={localeNewTask[currentLocale].form.todoActions.specifyTheDate}
                onBlur={compareDate}
                value={fromDatePickerTaskAction}
                max={
                  fromDatePickerTaskAction &&
                  fromDatePickerTaskAction.getTime() > new Date().getTime()
                    ? new Date(new Date().getMinutes() + 1)
                    : fromDatePickerTaskAction
                    ? new Date(fromDatePickerTaskAction)
                    : new Date()
                }
              />
            </IntlProvider>
          </LocalizationProvider>
        </div>
      </div>
      <div className="newtask-type">
        <label className="k-label required-label">
          {localeNewTask[currentLocale].form.todoActions.to}
        </label>
        <div className={styles.timeValueWrap}>
          <LocalizationProvider language={currentLocale}>
            <IntlProvider locale={currentLocale.substring(0, 2)}>
              <DatePicker
                // defaultValue={new Date()}
                format="dd-MMM-yyyy HH:mm:ss"
                focusedDate={new Date()}
                name="to"
                onChange={(e): void => handleToTaskAction(e.target.value)}
                placeholder={localeNewTask[currentLocale].form.todoActions.specifyTheDate}
                min={fromDatePickerTaskAction ? new Date(fromDatePickerTaskAction) : new Date()}
                max={
                  toDatePickerTaskAction && toDatePickerTaskAction.getTime() > new Date().getTime()
                    ? new Date(new Date().getMinutes() + 1)
                    : toDatePickerTaskAction
                    ? new Date(toDatePickerTaskAction)
                    : new Date()
                }
                onBlur={compareDate}
                value={toDatePickerTaskAction}
              />
            </IntlProvider>
          </LocalizationProvider>
        </div>
      </div>
      <div className={styles.errorWrap}>
        <span>{errorDateInterval}</span>
      </div>
    </div>
  );
}
