/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
import type { GridCellProps } from '@progress/kendo-react-grid';
import type { IServicesWin } from '../../../../../../utils/helpers/types';

export function CellDescription(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { description }: IServicesWin = dataItem;

  return (
    <>
      {description && description.length > 25 ? (
        <td title={description}>{description}</td>
      ) : description === null ? (
        <td>-</td>
      ) : (
        <td>{description}</td>
      )}
    </>
  );
}
