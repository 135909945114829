import type { GridCellProps } from '@progress/kendo-react-grid';
import type { AssetRouter } from '../../../../types/__generated/on-premise-solution/api/asset.v1';

export function CellClass({
  dataItem: { class: classOfDevice },
}: Omit<GridCellProps, 'latestInventorization'> & {
  dataItem: AssetRouter;
}): React.ReactElement {
  return (
    <td>
      {classOfDevice ? (
        <span>
          {classOfDevice[0].toUpperCase()}
          {classOfDevice.slice(1)}
        </span>
      ) : (
        <span>-</span>
      )}
    </td>
  );
}
