import type { SortDescriptor } from '@progress/kendo-data-query';
import { INITIAL_SORT, INITIAL_PAGING, ROLE_VALUES2 } from './constants';
import type { IPageState } from './types';
import type { UsersDashboardResponse } from '../../types/__generated/on-premise-solution/api/usersDashboardResponse.v1';

export interface IFilterUrlUser {
  type?: string;
}
export interface IUrlParamsUser {
  sort: SortDescriptor[];
  page: IPageState;
  pageLimitGrid: { [key: string]: string } | null;
  filterUrlData: IFilterUrlUser;
}

export function getUrlUser(pageLimitGridKey: string): IUrlParamsUser {
  const pageLimitGrid: IUrlParamsUser['pageLimitGrid'] =
    localStorage.getItem('pageLimitGrid') &&
    JSON.parse(localStorage.getItem('pageLimitGrid') || '');

  const locationUrlData: { [name: string]: string } | null = {};
  if (document.location.search) {
    const locationSearch = document.location.search.slice(1).split('&');
    locationSearch.forEach((val) => {
      const value = val.split('=');
      if (value[0] === 'type[$eq]') {
        locationUrlData.type = decodeURI(value[1]) || '';
      }
      locationUrlData[value[0]] = value[1] || '';
    });
  }

  const { sortField, sortOrder, skip } = locationUrlData;
  const sort: SortDescriptor[] =
    sortField && sortOrder && (sortOrder === 'asc' || sortOrder === 'desc')
      ? [{ field: sortField, dir: sortOrder }]
      : INITIAL_SORT;

  const page: IPageState = {
    skip: Number(skip) || INITIAL_PAGING.skip,
    take:
      pageLimitGrid && Number(pageLimitGrid[pageLimitGridKey])
        ? Number(pageLimitGrid[pageLimitGridKey])
        : INITIAL_PAGING.take,
  };

  const filterUrlData = {} as IFilterUrlUser;

  if (locationUrlData.osNameRegex) {
    filterUrlData.type = locationUrlData.type;
  }

  return {
    sort,
    page,
    pageLimitGrid,
    filterUrlData,
  };
}

export const getRoleUsersCount = (
  users: UsersDashboardResponse['data'] | undefined,
  userRole: string,
): number => {
  return users?.filter(({ role }) => role === userRole).length || 0;
};

export const createData = (
  users: UsersDashboardResponse | undefined,
): Array<{
  role: string;
  usersCount: number;
  description: {
    'ru-RU': string;
    'en-EN': string;
  };
}> => {
  return ROLE_VALUES2.map(({ role, description }) => {
    return { role, usersCount: getRoleUsersCount(users?.data, role), description };
  });
};
