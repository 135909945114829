import { nanoid } from 'nanoid';
import { Button } from '../baseElements/Button';
import localeNewTask from '../../../utils/i18n/taskLocale/newTask.json';
import { useHostModalContext } from '../../../hooks/useHostModalContext';
import { useTaskAddContext } from '../../../hooks/useTaskAddContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;

export function HostsMutableBlock(): React.ReactElement {
  const { setOpen, hostForm } = useHostModalContext();

  const { deleteAssetFromTask } = useTaskAddContext();

  return (
    <div className="apply-assets">
      <div className="title-apply-assets">
        <span>{localeNewTask[currentLocale].form.applyForAsset.title}</span>
        <Button onClick={(): void => setOpen(true)} type="button">
          {localeNewTask[currentLocale].form.addAsset}
        </Button>
      </div>
      <div className="list-assets">
        {hostForm?.map((h) => {
          if (h.hostname || h.assetId) {
            return (
              <Button
                type="button"
                fill="task_asset"
                onClick={(e): void => deleteAssetFromTask(h.assetId!, e)}
                key={nanoid()}
              >
                {h.hostname ? <span>{h.hostname}</span> : <span>{h.assetId}</span>}
              </Button>
            );
          }

          return null;
        })}
        {(hostForm?.length === 0 || hostForm === null || !hostForm.find((h) => h.assetId)) && (
          <span className="placeholder">
            {localeNewTask[currentLocale].form.applyForAsset.assetListPlaceholder}
          </span>
        )}
      </div>
    </div>
  );
}
