/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useRef, useState } from 'react';
import localeNewTask from '../../../../utils/i18n/taskLocale/newTask.json';
import { useTaskAddContext } from '../../../../hooks/useTaskAddContext';
import styles from './NewTask.module.scss';
import { CommonTooltipMultiline } from '../../../common/CommonTooltipMultiline';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;

export function TaskPkgAdditionalParam(): React.ReactElement {
  const { kbRebootValues, pkgRebootVal, setPkgRebootVal } = useTaskAddContext();

  const [isTooltip, setTooltip] = useState(false);

  const tooltipProp = {
    marginTop: '8px',
    marginLeft: '-92px',
    padding: '8px 12px',
    fontSize: '12px',
    width: '420px',
    text: [
      {
        val: localeNewTask[currentLocale].form.kbRebootTooltip2Val,
        des: localeNewTask[currentLocale].form.kbRebootTooltip2Des,
      },
      {
        val: localeNewTask[currentLocale].form.kbRebootTooltip3Val,
        des: localeNewTask[currentLocale].form.pkgRebootTooltip3Des,
      },
      {
        val: localeNewTask[currentLocale].form.kbRebootTooltip4Val,
        des: localeNewTask[currentLocale].form.kbRebootTooltip4Des,
      },
    ],
  };

  const timer = useRef<NodeJS.Timeout | null>(null);

  const hideTooltip = (): void => {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setTooltip(false);
    }, 500);
  };

  const showTooltip = (): void => {
    if (timer.current) clearTimeout(timer.current);
    setTooltip(true);
  };

  return (
    <div className={styles.pkgAdditionalParamWrap}>
      <div className={styles.tooltipWrap}>
        <label className="k-label">{localeNewTask[currentLocale].form.kbRebootTitle}</label>
        <div
          className={styles.questionWrapper}
          onMouseOver={showTooltip}
          onMouseLeave={hideTooltip}
        >
          <span className={`k-icon k-i-question ${styles.titleHelper}`} />
          <CommonTooltipMultiline isTooltip={isTooltip} tooltipProp={tooltipProp} />
        </div>
      </div>
      <DropDownList
        data={kbRebootValues}
        textField="text"
        dataItemKey="id"
        value={pkgRebootVal}
        onChange={(e): void => setPkgRebootVal(e.value)}
        style={{
          width: '260px',
        }}
      />
    </div>
  );
}
