import localeHostList from '../i18n/assetLocales/hostList.json';

export function setColorValAgent(status: string | undefined): string[] {
  const colorValue = [] as Array<string>;

  if (status) {
    switch (status) {
      case 'notActivated':
        colorValue.push('#b5b0b0');
        break;
      case 'disabled':
        colorValue.push('#e97d7d');
        break;
      case 'online':
        colorValue.push('#44c74f');
        break;
      case 'offline':
        colorValue.push('#e97d7d');
        break;
      default:
        colorValue.push('#b5b0b0');
    }
  }

  return colorValue;
}

export function getStatusAgentName(status: string | undefined, locale: 'ru-RU' | 'en-EN'): string {
  if (!status) return localeHostList[locale].agentStatus.noInfo;

  switch (status) {
    case 'notActivated':
      return localeHostList[locale].agentStatus.notActivated;
    case 'disabled':
      return localeHostList[locale].agentStatus.disabled;
    case 'online':
      return localeHostList[locale].agentStatus.online;
    case 'offline':
      return localeHostList[locale].agentStatus.offline;

    default:
      return localeHostList[locale].agentStatus.noInfo;
  }
}
