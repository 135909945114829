/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import type { GridCellProps } from '@progress/kendo-react-grid';

export function DeviceTagInfoCell(props: GridCellProps): React.ReactElement {
  const { dataItem, field, className } = props;

  return (
    <td
      className={className || ''}
      title={
        field && dataItem[field] && field in dataItem && typeof dataItem[field] === 'string'
          ? dataItem[field]
          : '-'
      }
    >
      {field && dataItem[field] && field in dataItem && typeof dataItem[field] === 'string'
        ? dataItem[field]
        : '-'}
    </td>
  );
}
