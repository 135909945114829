/* eslint-disable react/jsx-no-useless-fragment */
import { Grid, GridColumn, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useState } from 'react';
import locale from '../../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { WrapperMenu } from '../../../../Task/taskIDetails/iDetailsComponents/WrapperMenu';
import { DiffStandartCell } from '../../cells/DiffStandartCell';
import { DiffSizeCell } from '../../cells/DiffSizeCell';
import { LAZY_PAGEABLE_DATA } from '../../../../../../utils/helpers/constants';
import { DiffTypeCell } from '../../cells/DiffTypeCell';
import styles from '../../../forms/AddAssetForm.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function DiffDiskPhysicalWrapper({
  disk,
  needShowStatus,
}: {
  disk: {
    infoData: {
      [k: string]: {
        className?: string | undefined;
        value: any;
      }[];
    };
    partitionsData: any;
    name: {} | null;
    newName: {} | null;
  } | null;
  needShowStatus: boolean;
}): React.ReactElement {
  const [page, setPage] = useState({ take: 5, skip: 0 });

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  const infoStatuses: string[] = [];
  const partirionsStatuses: string[] = [];

  Object.values(disk?.infoData || {}).forEach((containerInfo) => {
    const classNames = containerInfo.filter((ci) => ci.className).map((ci) => ci.className);
    if (classNames.includes(styles.closed)) infoStatuses.push('closed');
    if (classNames.includes(styles.updated)) infoStatuses.push('new');
    if (!classNames.includes(styles.closed) && !classNames.includes(styles.updated))
      infoStatuses.push('noChanges');
  });

  disk?.partitionsData.forEach((fm: any) => {
    if ('diffType' && fm.diffType === 'closed') partirionsStatuses.push('closed');
    else if ('diffType' && fm.diffType === 'updated') partirionsStatuses.push('new');
    else partirionsStatuses.push('noChanges');
  });

  return (
    <WrapperMenu
      title={disk?.name as string}
      diffStatuses={needShowStatus ? infoStatuses.concat(...partirionsStatuses) : undefined}
    >
      {disk?.infoData && Object.keys(disk.infoData).length ? (
        <WrapperMenu
          title={locale[currentLocale].disks.titleInfo}
          diffStatuses={needShowStatus ? infoStatuses : undefined}
        >
          {('disk_index' in disk.infoData ||
            'type' in disk.infoData ||
            'disk_size' in disk.infoData ||
            'partitions' in disk.infoData) && (
            <table className="column">
              {'disk_index' in disk.infoData && (
                <>
                  {disk.infoData.disk_index.map((diskIndex) => (
                    <tr>
                      <td className={diskIndex.className}>{locale[currentLocale].disks.index}</td>
                      <td className={diskIndex.className}>{diskIndex.value || '-'}</td>
                    </tr>
                  ))}
                </>
              )}
              {'type' in disk.infoData && (
                <>
                  {disk.infoData.type.map((diskType) => (
                    <tr>
                      <td className={diskType.className}>{locale[currentLocale].disks.type}</td>
                      <td className={diskType.className}>{diskType.value || '-'}</td>
                    </tr>
                  ))}
                </>
              )}
              {'disk_size' in disk.infoData && (
                <>
                  {disk.infoData.disk_size.map((diskSize) => (
                    <tr>
                      <td className={diskSize.className}>{locale[currentLocale].disks.size}</td>
                      <td className={diskSize.className}>
                        {diskSize.value || '-'} {locale[currentLocale].sizeGb}
                      </td>
                    </tr>
                  ))}
                </>
              )}
              {'partitions' in disk.infoData && (
                <>
                  {disk.infoData.partitions.map((diskPartitions) => (
                    <tr>
                      <td className={diskPartitions.className}>
                        {locale[currentLocale].disks.partitions}
                      </td>
                      <td className={diskPartitions.className}>{diskPartitions.value || '-'}</td>
                    </tr>
                  ))}
                </>
              )}
            </table>
          )}
          {('hardware_model' in disk.infoData ||
            'manufacturer' in disk.infoData ||
            'serial' in disk.infoData ||
            'name' in disk.infoData) && (
            <table className="column">
              {'hardware_model' in disk.infoData && (
                <>
                  {disk.infoData.hardware_model.map((diskHardModel) => (
                    <tr>
                      <td className={diskHardModel.className}>
                        {locale[currentLocale].disks.model}
                      </td>
                      <td className={diskHardModel.className}>{diskHardModel.value || '-'}</td>
                    </tr>
                  ))}
                </>
              )}
              {'manufacturer' in disk.infoData && (
                <>
                  {disk.infoData.manufacturer.map((diskManufacturer) => (
                    <tr>
                      <td className={diskManufacturer.className}>
                        {locale[currentLocale].disks.manufacturer}
                      </td>
                      <td className={diskManufacturer.className}>
                        {diskManufacturer.value || '-'}
                      </td>
                    </tr>
                  ))}
                </>
              )}
              {'serial' in disk.infoData && (
                <>
                  {disk.infoData.serial.map((diskSerial) => (
                    <tr>
                      <td className={diskSerial.className}>{locale[currentLocale].disks.serial}</td>
                      <td className={diskSerial.className}>{diskSerial.value || '-'}</td>
                    </tr>
                  ))}
                </>
              )}
              {'name' in disk.infoData && (
                <>
                  {disk.infoData.name.map((diskName) => (
                    <tr>
                      <td className={diskName.className}>{locale[currentLocale].disks.name}</td>
                      <td className={diskName.className}>{diskName.value || '-'}</td>
                    </tr>
                  ))}
                </>
              )}
            </table>
          )}
          {('id' in disk.infoData || 'description' in disk.infoData) && (
            <table className="column">
              {'id' in disk.infoData && (
                <>
                  {disk.infoData.id.map((diskId) => (
                    <tr>
                      <td className={diskId.className}>{locale[currentLocale].disks.identifier}</td>
                      <td className={diskId.className}>{diskId.value || '-'}</td>
                    </tr>
                  ))}
                </>
              )}
              {'description' in disk.infoData && (
                <>
                  {disk.infoData.description.map((diskDescription) => (
                    <tr>
                      <td className={diskDescription.className}>
                        {locale[currentLocale].disks.description}
                      </td>
                      <td className={diskDescription.className}>{diskDescription.value || '-'}</td>
                    </tr>
                  ))}
                </>
              )}
            </table>
          )}
        </WrapperMenu>
      ) : (
        <></>
      )}
      {disk?.partitionsData && disk.partitionsData.length ? (
        <WrapperMenu
          title={locale[currentLocale].partitions.title}
          diffStatuses={needShowStatus ? partirionsStatuses : undefined}
        >
          <Grid
            data={disk.partitionsData.slice(page.skip, page.take + page.skip)}
            pageable={LAZY_PAGEABLE_DATA}
            scrollable="none"
            total={disk.partitionsData.length}
            pageSize={page.take}
            skip={page.skip}
            onPageChange={pageChange}
            className="no-margin-top"
          >
            <GridColumn
              field="diffType"
              cell={DiffTypeCell}
              title=" "
              width={20}
              sortable={false}
              headerClassName="no-cursor"
            />
            <GridColumn
              field="device"
              title={locale[currentLocale].partitions.device}
              cell={DiffStandartCell}
            />
            <GridColumn
              field="partition"
              title={locale[currentLocale].partitions.partition}
              cell={DiffStandartCell}
            />
            <GridColumn
              field="type"
              title={locale[currentLocale].partitions.type}
              cell={DiffStandartCell}
            />
            <GridColumn
              field="size"
              title={locale[currentLocale].partitions.size}
              cell={DiffSizeCell}
            />
          </Grid>
        </WrapperMenu>
      ) : (
        <></>
      )}
    </WrapperMenu>
  );
  // const diskInfoData = prepareDiffObjectData(
  //   { closed: disk.info.old, noChanges: disk.info.noChanges, updated: disk.info.new },
  //   filter,
  // );

  // const partitionsInfoData = createFromListInvData(
  //   {disk.partitions} as unknown as IDiffInvElementInfo[],
  //   filter,
  // );

  // const hasDiskData = diskInfoData && Object.keys(diskInfoData || {}).length;
  // (partitionsInfoData && partitionsInfoData.length);

  // return hasDiskData ? (
  //   <WrapperMenu title="disk name">
  //     {diskInfoData && Object.keys(diskInfoData || {}).length ? (
  //       <WrapperMenu title={locale[currentLocale].disks.titleInfo}>
  //         {('disk_index' in diskInfoData ||
  //           'type' in diskInfoData ||
  //           'disk_size' in diskInfoData ||
  //           'partitions' in diskInfoData) && (
  //           <table className="column">
  //             {'disk_index' in diskInfoData && (
  //               <>
  //                 {diskInfoData.disk_index.map((diskIndex) => (
  //                   <tr>
  //                     <td className={diskIndex.className}>{locale[currentLocale].disks.index}</td>
  //                     <td className={diskIndex.className}>{diskIndex.value || '-'}</td>
  //                   </tr>
  //                 ))}
  //               </>
  //             )}
  //             {'type' in diskInfoData && (
  //               <>
  //                 {diskInfoData.type.map((diskType) => (
  //                   <tr>
  //                     <td className={diskType.className}>{locale[currentLocale].disks.type}</td>
  //                     <td className={diskType.className}>{diskType.value || '-'}</td>
  //                   </tr>
  //                 ))}
  //               </>
  //             )}
  //             {'disk_size' in diskInfoData && (
  //               <>
  //                 {diskInfoData.disk_size.map((diskSize) => (
  //                   <tr>
  //                     <td className={diskSize.className}>{locale[currentLocale].disks.size}</td>
  //                     <td className={diskSize.className}>
  //                       {diskSize.value || '-'} {locale[currentLocale].sizeGb}
  //                     </td>
  //                   </tr>
  //                 ))}
  //               </>
  //             )}
  //             {'partitions' in diskInfoData && (
  //               <>
  //                 {diskInfoData.partitions.map((diskPartitions) => (
  //                   <tr>
  //                     <td className={diskPartitions.className}>
  //                       {locale[currentLocale].disks.partitions}
  //                     </td>
  //                     <td className={diskPartitions.className}>{diskPartitions.value || '-'}</td>
  //                   </tr>
  //                 ))}
  //               </>
  //             )}
  //           </table>
  //         )}
  //         {('hardware_model' in diskInfoData ||
  //           'manufacturer' in diskInfoData ||
  //           'serial' in diskInfoData ||
  //           'name' in diskInfoData) && (
  //           <table className="column">
  //             {'hardware_model' in diskInfoData && (
  //               <>
  //                 {diskInfoData.hardware_model.map((diskHardModel) => (
  //                   <tr>
  //                     <td className={diskHardModel.className}>
  //                       {locale[currentLocale].disks.model}
  //                     </td>
  //                     <td className={diskHardModel.className}>{diskHardModel.value || '-'}</td>
  //                   </tr>
  //                 ))}
  //               </>
  //             )}
  //             {'manufacturer' in diskInfoData && (
  //               <>
  //                 {diskInfoData.manufacturer.map((diskManufacturer) => (
  //                   <tr>
  //                     <td className={diskManufacturer.className}>
  //                       {locale[currentLocale].disks.manufacturer}
  //                     </td>
  //                     <td className={diskManufacturer.className}>
  //                       {diskManufacturer.value || '-'}
  //                     </td>
  //                   </tr>
  //                 ))}
  //               </>
  //             )}
  //             {'serial' in diskInfoData && (
  //               <>
  //                 {diskInfoData.serial.map((diskSerial) => (
  //                   <tr>
  //                     <td className={diskSerial.className}>{locale[currentLocale].disks.serial}</td>
  //                     <td className={diskSerial.className}>{diskSerial.value || '-'}</td>
  //                   </tr>
  //                 ))}
  //               </>
  //             )}
  //             {'name' in diskInfoData && (
  //               <>
  //                 {diskInfoData.name.map((diskName) => (
  //                   <tr>
  //                     <td className={diskName.className}>{locale[currentLocale].disks.name}</td>
  //                     <td className={diskName.className}>{diskName.value || '-'}</td>
  //                   </tr>
  //                 ))}
  //               </>
  //             )}
  //           </table>
  //         )}
  //         {('id' in diskInfoData || 'description' in diskInfoData) && (
  //           <table className="column">
  //             {'id' in diskInfoData && (
  //               <>
  //                 {diskInfoData.id.map((diskId) => (
  //                   <tr>
  //                     <td className={diskId.className}>{locale[currentLocale].disks.identifier}</td>
  //                     <td className={diskId.className}>{diskId.value || '-'}</td>
  //                   </tr>
  //                 ))}
  //               </>
  //             )}
  //             {'description' in diskInfoData && (
  //               <>
  //                 {diskInfoData.description.map((diskDescription) => (
  //                   <tr>
  //                     <td className={diskDescription.className}>
  //                       {locale[currentLocale].disks.description}
  //                     </td>
  //                     <td className={diskDescription.className}>{diskDescription.value || '-'}</td>
  //                   </tr>
  //                 ))}
  //               </>
  //             )}
  //           </table>
  //         )}
  //       </WrapperMenu>
  //     ) : (
  //       <></>
  //     )}
  //   </WrapperMenu>
  // ) : (
  //   <></>
  // );
  // return (
  //   <>
  //     {(data.type === 'linux' || data.type === 'windows') &&
  //       data.result.disk?.map((disk) => {
  //         if (disk.info && 'id' in disk.info && disk.info.id) {
  //           return (
  //             <WrapperMenu title={disk.info?.name}>
  //               <WrapperMenu title={locale[currentLocale].disks.titleInfo}>
  //               </WrapperMenu>
  //               <WrapperMenu title={locale[currentLocale].partitions.title}>
  //                 <Grid data={disk.partitions} scrollable="none" className="no-margin-top">
  //                   <GridColumn
  //                     field="device"
  //                     title={locale[currentLocale].partitions.device}
  //                     cell={StandartCell}
  //                   />
  //                   <GridColumn
  //                     field="partition"
  //                     title={locale[currentLocale].partitions.partition}
  //                     cell={StandartCell}
  //                   />
  //                   <GridColumn
  //                     field="type"
  //                     title={locale[currentLocale].partitions.type}
  //                     cell={StandartCell}
  //                   />
  //                   <GridColumn
  //                     field="size"
  //                     title={locale[currentLocale].partitions.size}
  //                     cell={SizeCell}
  //                   />
  //                 </Grid>
  //               </WrapperMenu>
  //             </WrapperMenu>
  //           );
  //         }

  //         return null;
  //       })}
  //   </>
  // );
}
