/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import locale from '../../../../utils/i18n/credentials/credentials.json';
import { useAccountModalContext } from '../../../../hooks/useAccountModalContext';
import { useCredentialContext } from '../../../../hooks/useCredentialContext';
import { Button } from '../../../common/baseElements/Button';
import formStyles from '../../Containers/form/Form.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function TableWithSelectedCredential(): React.ReactElement {
  const { credentialVault, setCredentialVault, setSelectedCredential } = useCredentialContext();

  const { accountId } = useAccountModalContext();

  return (
    <div className="form-block">
      <Button
        type="button"
        icon="plus"
        // className="credential-bnt"
        className={`${formStyles.add_button} required-label-btn`}
        onClick={(): void =>
          setSelectedCredential({
            accountId: accountId.id || undefined,
          })
        }
      >
        {!credentialVault?.credentialId
          ? locale[currentLocale].form.addCredential
          : locale[currentLocale].form.changedCredential}
      </Button>
      {credentialVault?.credentialId && (
        <div className="selected-credential">
          {credentialVault && (
            <table className={`${formStyles.form_table} horizontal`}>
              <thead>
                <tr>
                  <th>{locale[currentLocale].table.type}</th>
                  <th>{locale[currentLocale].table.name}</th>
                  <th>ID</th>
                  <th className={formStyles.delete_button_th} />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{credentialVault?.credentialType}</td>
                  <td title={credentialVault?.credentialName || ''}>
                    {credentialVault?.credentialName}
                  </td>
                  <td>{credentialVault?.credentialId}</td>
                  <td className="delete-credential">
                    <Button fill="flat" className={formStyles.delete_button}>
                      <span
                        className="k-button-icon k-icon k-i-close"
                        onClick={(): void => setCredentialVault(undefined)}
                      />
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
}
