/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useRef, useState } from 'react';
import { Grid, GridColumn, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { Filter } from '@progress/kendo-react-data-tools';
import { State, process, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import type { InventorizationResponse } from '../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import {
  FILTER_INITIAL,
  PAGEABLE_DATA,
  PAGEABLE_DATA_LOCAL,
} from '../../../../utils/helpers/constants';
import locale from '../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { CellUrlSoftware } from './cellSoftware/cellUrlSoftware';
import { CellInstalledAtSoftware } from './cellSoftware/CellInstalledAtSoftware';
import { CellWinInstallLocation } from './cellSoftware/cellWinInstallLocation';
import { CellPublisher } from './cellSoftware/cellPublisher';
import { CellName } from './cellSoftware/cellName';
import {
  handlerCustomSearch,
  onFilterChange,
} from '../../../../utils/helpers/local-filter-helpers';
import {
  IPrepareLinuxData,
  prepareLinuxData,
} from '../../../../utils/helpers/inventorization-helper';
import { useLocalExcelExport } from '../../../../hooks/components/useLocalExcelExport';
import {
  FILTER_SOFTWARE_LIN,
  FILTER_SOFTWARE_WIN,
} from '../../../../utils/helpers/constant-local-filter-task';
import { Input } from '../../../common/baseElements/Input';
import { Button } from '../../../common/baseElements/Button';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function SoftwareInventorizationList({
  data,
  hostName,
}: {
  data: InventorizationResponse | undefined;
  hostName: string | null | undefined;
}): React.ReactElement {
  const pageLimitGrid: { [key: string]: string } =
    localStorage.getItem('pageLimitGrid') &&
    JSON.parse(localStorage.getItem('pageLimitGrid') || '');

  const initialDataState: State = {
    sort: [{ field: '', dir: 'asc' }],
    take: pageLimitGrid && pageLimitGrid.SoftInvList ? Number(pageLimitGrid.SoftInvList) : 10,
    skip: 0,
    filter: FILTER_INITIAL,
  };

  const [dataState, setDataState] = useState<State>(initialDataState);

  const [customSearch, setCustomSearch] = useState('');

  const [filter, setFilter] = useState(FILTER_INITIAL);

  const [linuxSoftware, setLinuxSoftware] = useState<IPrepareLinuxData[] | undefined>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _grid = useRef<React.LegacyRef<Grid> | undefined>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _export = useRef<ExcelExport | null>(null);

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  const fieldFindInTable = 'name';

  useEffect(() => {
    if (data && data.type === 'linux' && data.result.software) {
      setLinuxSoftware(prepareLinuxData(data.result.software));
    }
  }, [data]);

  const software = data?.type === 'linux' ? linuxSoftware : data?.result.software;

  const excelExport = useLocalExcelExport(dataState, _export, software, setDataState);

  const titleVulnsSoft = `${hostName && hostName.replaceAll('.', '-')}_${
    locale[currentLocale].tabs.software
  }`;

  return (
    <>
      {data?.result.software && (
        <div className="softwareint">
          <LocalizationProvider language={currentLocale}>
            <IntlProvider locale={currentLocale.substring(0, 2)}>
              <div className="filter-line">
                <Filter
                  value={filter}
                  onChange={(event): void =>
                    onFilterChange(
                      event,
                      setCustomSearch,
                      setDataState,
                      dataState,
                      setFilter,
                      filterValue,
                      customSearch,
                      fieldFindInTable,
                    )
                  }
                  fields={
                    data?.type === 'windows'
                      ? FILTER_SOFTWARE_WIN[currentLocale]
                      : FILTER_SOFTWARE_LIN[currentLocale]
                  }
                />
                {filter.filters.length === 0 && (
                  <Input
                    className="k-input k-input-md"
                    name="customSearch"
                    placeholder={locale[currentLocale].customFilterPh}
                    value={customSearch}
                    onChange={(e): void =>
                      handlerCustomSearch(
                        e,
                        setCustomSearch,
                        setDataState,
                        filterValue,
                        dataState,
                        fieldFindInTable,
                      )
                    }
                  />
                )}
                <Button excel fill="flat" onClick={excelExport}>
                  {locale[currentLocale].exportToExcel}
                </Button>
              </div>
            </IntlProvider>
          </LocalizationProvider>
          {!data && <p>{locale[currentLocale].summary.dataNotFound}</p>}
          {data && data.type === 'linux' && linuxSoftware && (
            <LocalizationProvider language={currentLocale}>
              <IntlProvider locale={currentLocale.substring(0, 2)}>
                <ExcelExport data={linuxSoftware} ref={_export} fileName={titleVulnsSoft}>
                  <Grid
                    pageable={
                      linuxSoftware && linuxSoftware.length > 10 ? PAGEABLE_DATA_LOCAL : false
                    }
                    sortable
                    style={{ width: '100%' }}
                    data={process(linuxSoftware || [], dataState)}
                    {...dataState}
                    onDataStateChange={(e: GridDataStateChangeEvent): void => {
                      setDataState(e.dataState);
                      if (pageLimitGrid) {
                        const pageLimit = {
                          ...pageLimitGrid,
                          SoftInvList: String(e.dataState.take),
                        };
                        localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
                      } else {
                        const pageLimit = {
                          SoftInvList: String(e.dataState.take),
                        };
                        localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
                      }
                    }}
                    scrollable="none"
                    ref={_grid.current}
                  >
                    <GridColumn field="name" title={locale[currentLocale].softwareLin.name} />
                    <GridColumn field="version" title={locale[currentLocale].softwareLin.version} />
                    <GridColumn
                      field="size"
                      title={locale[currentLocale].softwareLin.size}
                      width="200px"
                    />
                    <GridColumn
                      field="arch"
                      title={locale[currentLocale].softwareLin.arch}
                      width="120px"
                    />
                    <GridColumn
                      field="url"
                      title={locale[currentLocale].softwareLin.url}
                      cell={CellUrlSoftware}
                    />
                    <GridColumn
                      field="installedAt"
                      title={locale[currentLocale].softwareLin['installation/upgradeTime']}
                      cell={CellInstalledAtSoftware}
                    />
                  </Grid>
                </ExcelExport>
              </IntlProvider>
            </LocalizationProvider>
          )}
          {data && data.type === 'windows' && data.result.software && (
            <LocalizationProvider language={currentLocale}>
              <IntlProvider locale={currentLocale.substring(0, 2)}>
                <ExcelExport data={data.result.software!} ref={_export} fileName={titleVulnsSoft}>
                  <Grid
                    pageable={
                      data.result.software && data.result.software.length > 10
                        ? PAGEABLE_DATA_LOCAL
                        : false
                    }
                    sortable
                    style={{ width: '100%' }}
                    data={process(data.result.software!, dataState)}
                    {...dataState}
                    onDataStateChange={(e: GridDataStateChangeEvent): void => {
                      setDataState(e.dataState);
                    }}
                    scrollable="none"
                    ref={_grid.current}
                  >
                    <GridColumn
                      field="name"
                      title={locale[currentLocale].softwareWin.name}
                      cell={CellName}
                    />
                    <GridColumn
                      field="version"
                      title={locale[currentLocale].softwareWin.version}
                      width="160"
                    />
                    <GridColumn
                      field="publisher"
                      title={locale[currentLocale].softwareWin.publisher}
                      cell={CellPublisher}
                    />
                    <GridColumn
                      field="installedAt"
                      title={locale[currentLocale].softwareWin.installedAt}
                      cell={CellInstalledAtSoftware}
                      width="208px"
                    />
                    <GridColumn
                      field="installLocation"
                      title={locale[currentLocale].softwareWin.installLocation}
                      cell={CellWinInstallLocation}
                    />
                  </Grid>
                </ExcelExport>
              </IntlProvider>
            </LocalizationProvider>
          )}
        </div>
      )}
    </>
  );
}
