/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import '../../TaskDescription.module.scss';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { State, process as gridProcess } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { useRef } from 'react';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { PAGEABLE_DATA_LOCAL } from '../../../../../utils/helpers/constants';
import localeAuditTask from '../../../../../utils/i18n/taskLocale/auditTask.json';
import localeVmTask from '../../../../../utils/i18n/taskLocale/vmTask.json';
import { cellModified } from './cells/CellMissingKbModified';
import { CellPublished } from './cells/CellMissingKbPublshed';
import type { IMissingKBs } from '../../../../../utils/helpers/types';
import { FstecStatus } from './cells/FstecStatus';
import { FstecStatusHeaderCell } from './cells/FstecStatusHeaderCell';
import CellSelectKbs from './cells/CellSelectKbs';
import { KbIdCell } from '../../../../common/KbIdCell';
import CellSelectKbsWl from './cells/CellSelectKbsWl';

interface IMissingKBsTable {
  hostName: string | null | undefined | false;
  missedKbWlData?: IMissingKBs[] | null | undefined | false;
  id?: string | undefined;
  missedKb:
    | false
    | {
        id: string;
        title: string;
        description: string;
        date: {
          published: string;
          modified?: string | undefined;
        };
      }[]
    | null
    | undefined;
  exportRef: React.MutableRefObject<ExcelExport | null>;
  dataState: State;
  setDataState: React.Dispatch<React.SetStateAction<State>>;
  pageLimitGrid: {
    [key: string]: string;
  };
}

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

export function MissingKBsTable({ ...props }: IMissingKBsTable): React.ReactElement {
  const {
    hostName,
    missedKbWlData,
    id,
    missedKb,
    exportRef,
    dataState,
    setDataState,
    pageLimitGrid,
  } = props;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _grid = useRef<React.LegacyRef<Grid> | undefined>();

  const titleVulnsSoft = document.location.pathname.includes('assets/images')
    ? `${
        missedKbWlData
          ? localeVmTask[currentLocale].tabs.missingKBsWl
          : localeVmTask[currentLocale].tabs.missingKBs
      }`
    : `${hostName && hostName.replaceAll('.', '-')}_${
        missedKbWlData
          ? localeVmTask[currentLocale].tabs.missingKBsWl
          : localeVmTask[currentLocale].tabs.missingKBs
      }`;

  return (
    <LocalizationProvider language={currentLocale}>
      <IntlProvider locale={currentLocale.substring(0, 2)}>
        <ExcelExport
          data={
            missedKbWlData && missedKbWlData.length > 0
              ? missedKbWlData
              : missedKb && missedKb.length > 0
              ? missedKb
              : []
          }
          ref={exportRef}
          fileName={titleVulnsSoft}
        >
          <Grid
            pageable={
              id && missedKbWlData && missedKbWlData.length > 10
                ? PAGEABLE_DATA_LOCAL
                : !id && missedKb && missedKb.length > 10
                ? PAGEABLE_DATA_LOCAL
                : false
            }
            sortable
            data={gridProcess(
              Object.values(
                missedKbWlData && missedKbWlData.length > 0
                  ? missedKbWlData
                  : missedKb && missedKb.length > 0
                  ? missedKb
                  : [],
              ),
              dataState,
            )}
            {...dataState}
            onDataStateChange={(e: GridDataStateChangeEvent): void => {
              setDataState(e.dataState);
              if (pageLimitGrid) {
                const pageLimit = {
                  ...pageLimitGrid,
                  MissingKBs: String(e.dataState.take),
                };
                localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
              } else {
                const pageLimit = {
                  MissingKBs: String(e.dataState.take),
                };
                localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
              }
            }}
            className="taskaudit__vulnsgrid"
            scrollable="none"
            ref={_grid.current}
          >
            <Column
              field="addKb"
              title={localeAuditTask[currentLocale].vulnsSoft.table.selectPackage}
              cell={!id ? CellSelectKbs : CellSelectKbsWl}
              width="80px"
              sortable={false}
            />
            <Column
              field="id"
              title={localeAuditTask[currentLocale].isMissedKb.id}
              cell={KbIdCell}
              width="140px"
            />
            <Column field="title" title={localeAuditTask[currentLocale].isMissedKb.title} />
            <Column
              field="title"
              title={localeAuditTask[currentLocale].isMissedKb.vstec}
              width="200px"
              cell={FstecStatus}
              headerCell={FstecStatusHeaderCell}
            />
            <Column
              field="date.published"
              title={localeAuditTask[currentLocale].isMissedKb.published}
              cell={CellPublished}
              width="144px"
            />
            <Column
              field="date.modified"
              title={localeAuditTask[currentLocale].isMissedKb.modified}
              cell={cellModified}
              width="112px"
            />
          </Grid>
        </ExcelExport>
      </IntlProvider>
    </LocalizationProvider>
  );
}
