/* eslint-disable no-nested-ternary */
import { Grid, GridColumn, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useState } from 'react';
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import type { InventorizationResponse } from '../../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import { LAZY_PAGEABLE_DATA } from '../../../../../utils/helpers/constants';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { SizeCell } from '../../../../common/cells/SizeCell';
import { WrapperMenu } from './WrapperMenu';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function Mount({ data }: { data: InventorizationResponse }): React.ReactElement {
  const [page, setPage] = useState({ take: 5, skip: 0 });

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  return (
    <WrapperMenu title={locale[currentLocale].mounts.title}>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <Grid
            data={
              data.type === 'linux' || data.type === 'hypervisor'
                ? data.result.mounts?.slice(page.skip, page.take + page.skip)
                : null
            }
            pageable={LAZY_PAGEABLE_DATA}
            scrollable="none"
            total={
              data.type === 'linux' || data.type === 'hypervisor' ? data.result.mounts?.length : 0
            }
            pageSize={page.take}
            skip={page.skip}
            className="no-margin-top"
            onPageChange={pageChange}
          >
            <GridColumn field="path" title={locale[currentLocale].mounts.path} />
            <GridColumn field="device" title={locale[currentLocale].mounts.device} />
            <GridColumn field="type" title={locale[currentLocale].mounts.type} />
            <GridColumn field="size" title={locale[currentLocale].mounts.size} cell={SizeCell} />
            <GridColumn field="used" title={locale[currentLocale].mounts.used} cell={SizeCell} />
            <GridColumn
              field="available"
              title={locale[currentLocale].mounts.available}
              cell={SizeCell}
            />
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </WrapperMenu>
  );
}
