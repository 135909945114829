/* eslint-disable jsx-a11y/no-autofocus */
import type { ReactElement } from 'react';
import styles from './Input.module.scss';
import type { IInputProps } from '../../../utils/helpers/types';
import { Button } from './Button';

type InputButtonProps = IInputProps & {
  imageType?: 'calculator';
  onImageClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
};

export function InputButton(props: InputButtonProps): ReactElement {
  const {
    id,
    name,
    style,
    disabled,
    className,
    placeholder,
    value,
    type,
    maxLength,
    onChange,
    onFocus,
    onBlur,
    min,
    max,
    required,
    autoFocus,
    imageType,
    onImageClick,
  } = props;

  const stA = [];
  if (imageType) {
    stA.push(styles.icon, styles[`icon_${imageType}`]);
    // stA.push(styles[`icon_${imageType}`]);
  }

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <input
        style={style}
        name={name}
        type={type}
        disabled={disabled}
        value={value}
        id={id}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        maxLength={maxLength}
        className={`${styles.input} ${className || ''}`}
        placeholder={placeholder}
        min={min}
        max={max}
        required={required}
        autoFocus={autoFocus}
      />
      <Button
        className={styles.icon_button}
        onClick={(event: React.MouseEvent<HTMLButtonElement>): void => {
          event.preventDefault();
          if (onImageClick) onImageClick(event);
        }}
      >
        <span
          style={
            {
              // width: 'auto',
              // width: '20px',
              // height: '20px',
            }
          }
          className={stA.join(' ')}
        />
      </Button>
    </div>
  );
}
