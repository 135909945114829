import '../../../Host/hostComponents/HostDescription.module.scss';
import { useEffect } from 'react';
import type localeHostDescription from '../../../../../utils/i18n/assetLocales/hostDescription.json';
import { AddCredentialForm } from '../../../Credentials/form/AddCredentialForm';
import type { AssetImageDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/assetImageDashboardResponse.v1';
import { TableFImageMetaBlock } from './tables/TableFImageMetaBlock';
import { TableSImageMetaBlock } from './tables/TableSImageMetaBlock';
import { useCredentialContext } from '../../../../../hooks/useCredentialContext';
import { useAssetCreatePropContext } from '../../../../../hooks/useAssetCreatePropContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHostDescription;
export function ImageMetaBlock({
  data,
}: {
  data: AssetImageDashboardResponse | undefined;
}): React.ReactElement {
  const { isAddCredential, credentialData, isEdit } = useCredentialContext();

  const { setShowImportance, setShowHostType, setShowPerimeterImpact } =
    useAssetCreatePropContext();

  const rootDiv = document.getElementsByClassName('common-summary-page');

  const hideDropDownList = (): void => {
    setShowImportance(false);
    setShowHostType(false);
    setShowPerimeterImpact(false);
  };

  const keyDownHandler = (event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      event.preventDefault();

      setShowImportance(false);
      setShowHostType(false);
      setShowPerimeterImpact(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler);

    if (rootDiv[0]) {
      rootDiv[0].addEventListener('click', hideDropDownList);
      rootDiv[0].addEventListener('keydown', hideDropDownList);
    }

    return () => {
      if (rootDiv[0]) {
        rootDiv[0].removeEventListener('click', hideDropDownList);
      }
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  return (
    <div className="common-summary-page">
      <TableFImageMetaBlock data={data} currentLocale={currentLocale} />
      {/* <TableSImageMetaBlock data={data} currentLocale={currentLocale} /> */}
      <TableSImageMetaBlock data={data} currentLocale={currentLocale} />
      {(isAddCredential || (credentialData && isEdit)) && <AddCredentialForm />}
    </div>
  );
}
