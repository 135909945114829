import ReactDOM from 'react-dom';
import './LoadingPannel.scss';

export function LoadingPanel(): React.ReactElement {
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  );

  const gridContent = document && document.querySelector('.k-grid-content');

  return gridContent ? ReactDOM.createPortal(loadingPanel, gridContent) : loadingPanel;
}
