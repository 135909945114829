import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { useState, type ReactElement, useRef } from 'react';
import styles from './Form.module.scss';
import localeLogin from '../../../utils/i18n/loginLocale/loginForm.json';
import { FormInput } from '../../common/form/FormInput';
import { Button } from '../../common/baseElements/Button';
import { useAuthContext } from '../../../hooks/useAuthContext';
import type { FormDataValue } from '../../../utils/helpers/types';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeLogin;

export function TwoFactoredAuthForm({
  handleSubmit,
}: {
  handleSubmit: (dataItem: FormDataValue) => void;
}): ReactElement {
  const { setTwoFactoredAuthPage, loginError, isDisableConfirm } = useAuthContext();
  const [tfaCode, setTfaCode] = useState('');

  return (
    <div style={{ display: 'flex' }}>
      <Form
        //   onSubmit={handleSubmit}
        onSubmit={(dataItem: FormDataValue): void => {
          handleSubmit(dataItem);
          setTfaCode('');
        }}
        render={(): JSX.Element => (
          <FormElement>
            <fieldset className="k-form-fieldset">
              <legend className={styles.form_legend}>{localeLogin[currentLocale].formTitle}</legend>
              <div className={styles.field_block}>
                {/* <div className="k-form__field-block"> */}
                <Field
                  name="code"
                  component={FormInput}
                  id="code"
                  type="text"
                  label={localeLogin[currentLocale].tfaForm.codeLabel}
                  onChange={(e): void => {
                    setTfaCode(e.target.value.trim());
                  }}
                  inputValue={tfaCode}
                  className="form-field-wrapper"
                  // customValidator={customValidator}
                />
              </div>
            </fieldset>
            <div className="error-common-field">
              <span>{loginError}</span>
            </div>
            <div className="k-form-buttons">
              <Button disabled={isDisableConfirm} type="submit">
                {localeLogin[currentLocale].tfaForm.btnConfirm}
              </Button>
              <Button onClick={(): void => setTwoFactoredAuthPage(false)}>
                {localeLogin[currentLocale].tfaForm.btnBack}
              </Button>
            </div>
          </FormElement>
        )}
      />
    </div>
  );
}
