/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useState } from 'react';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { useHistory } from 'react-router';
import localeSettings from '../../../../utils/i18n/settingsLocales/settings.json';
import { AllTaskLogList } from './AllTaskLogList';
import { AllUserLogList } from './AllUserLogList';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

export function TabSettingLog(): React.ReactElement {
  const history = useHistory();

  const [selected, setSelected] = useState<number>(0);

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      if (e.selected === 0 && !document.location.pathname.includes('/users')) {
        history.replace('/lk/systemlog/users');
        setSelected(e.selected);
      }
      if (e.selected === 1 && !document.location.pathname.includes('/tasks')) {
        history.replace('/lk/systemlog/tasks');
        setSelected(e.selected);
      }
    },
    [history],
  );

  return (
    <TabStrip
      selected={document.location.pathname.includes('/tasks') ? 1 : 0}
      onSelect={handleSelect}
    >
      {/* <TabStripTab title={localeSettings[currentLocale].logs.tabs.systemEvents}>
        <span>{localeSettings[currentLocale].logs.tabs.systemEvents}</span>
      </TabStripTab> */}

      <TabStripTab title={localeSettings[currentLocale].logs.tabs.userActions}>
        <AllUserLogList />
      </TabStripTab>
      <TabStripTab title={localeSettings[currentLocale].logs.tabs.taskLogs}>
        <AllTaskLogList />
      </TabStripTab>
    </TabStrip>
  );
}
