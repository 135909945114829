import type { GridCellProps } from '@progress/kendo-react-grid';

export function CellModifiedAt({ dataItem }: GridCellProps): React.ReactElement {
  const { modifiedAt } = dataItem;

  return (
    <td className="channel-date-row">
      {modifiedAt && modifiedAt !== '0' ? new Date(modifiedAt).toLocaleString() : '-'}
    </td>
  );
}
