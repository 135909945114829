/* eslint-disable react/jsx-pascal-case */
// import styles from './VDB.module.scss';
import {
  Chart,
  ChartAxisDefaults,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
} from '@progress/kendo-react-charts';
import localeVulnDatabase from '../../../utils/i18n/taskLocale/vulnDatabase.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeVulnDatabase;

export function CountsByPerMonthDashboard({
  data,
}: {
  data:
    | {
        name: string;
        count: number;
        total?: number;
      }[]
    | undefined;
}): React.ReactElement {
  return (
    <Chart
      style={{
        height: 240,
        width: 720,
        border: '1px solid #ccc',
        borderRadius: '8px',
        marginTop: '12px',
        // marginLeft: '12px',
        padding: '0 20px 12px',
      }}
    >
      <ChartTitle text={localeVulnDatabase[currentLocale].charts.byMonth.title} />
      <ChartAxisDefaults
        labels={{
          content: (e: any) => {
            return typeof e.value === 'number' && e.value > 10000 ? `${e.value / 1000}k` : e.value;
          },
        }}
      />
      {/* <ChartLegend position="top" orientation="horizontal" /> */}
      <ChartCategoryAxis>
        <ChartCategoryAxisItem />
      </ChartCategoryAxis>
      <ChartSeries>
        <ChartSeriesItem
          type="line"
          data={data}
          categoryField="name"
          // field="count"
          field="total"
          color="#44c74f"
          // tooltip={{ visible: true }}
          labels={{ visible: true, background: '#00000000', color: '#006600' }}
        />
        {/* <ChartSeriesItem
          type="area"
          data={data}
          categoryField="name"
          field="count"
          // field="total"
          color="#ff0000"
          // tooltip={{ visible: true }}
          labels={{ visible: true, background: '#00000000', color: '#006600' }}
        /> */}
      </ChartSeries>
    </Chart>
  );
}
