import type { GridCellProps } from '@progress/kendo-react-grid';
import type { ArtifactResponse } from '../../../../../types/__generated/on-premise-solution/api/artifactsResponse.v1';

export function CellFileFormat(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { fileFormat }: ArtifactResponse = dataItem;

  return (
    <td title={fileFormat}>
      {fileFormat === 'pdf' && <span className="k-icon k-i-file-pdf" />}
      {fileFormat === 'html' && <span className="k-icon k-i-paste-as-html" />}
    </td>
  );
}
