import { nanoid } from 'nanoid';
import { Button } from '../../../../common/baseElements/Button';
import localeSettings from '../../../../../utils/i18n/settingsLocales/settings.json';
import { useApiKeyContext } from '../../../../../hooks/useApiKeyContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

export function MethodMutableBlock(): React.ReactElement {
  const { apiMethodsForm, setApiMethods, deleteMethodFromTask } = useApiKeyContext();

  return (
    <div className="apply-assets">
      <div className="title-apply-assets">
        <span>{localeSettings[currentLocale].apiKey.method.applyForMethods}</span>
        <Button
          onClick={(): void => setApiMethods(true)}
          type="button"
          disabled={document.location.pathname.includes('repositories')}
        >
          {localeSettings[currentLocale].apiKey.method.addMethod}
        </Button>
      </div>
      <div className="list-assets">
        {apiMethodsForm?.map((method) => {
          return (
            <div className="item-asset" key={nanoid()}>
              <Button
                type="button"
                fill="task_asset"
                onClick={(e): void => deleteMethodFromTask(method.path, e)}
                disabled={document.location.pathname.includes('repositories')}
              >
                {method.path}
              </Button>
            </div>
          );
        })}
        {(apiMethodsForm?.length === 0 || apiMethodsForm === null) && (
          <span className="placeholder">
            {localeSettings[currentLocale].apiKey.method.selectMethods}
          </span>
        )}
      </div>
    </div>
  );
}
