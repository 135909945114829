import type { GridCellProps } from '@progress/kendo-react-grid';
import type { IPkgVulns } from '../../../../../../utils/helpers/types';

export function CellPublished(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { date }: IPkgVulns = dataItem;

  return (
    <td className="number">
      <span>{new Date(date.published).toLocaleDateString()}</span>
    </td>
  );
}
