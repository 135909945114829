/* eslint-disable no-nested-ternary */
import type { GridCellProps } from '@progress/kendo-react-grid';
import type { AssetRouter } from '../../../../types/__generated/on-premise-solution/api/asset.v1';
import { IMG_SRC_VALUES } from '../../../../utils/helpers/constants';

export function CellOs({
  dataItem: { latestInventorization, class: classOfDevice },
}: Omit<GridCellProps, 'latestInventorization'> & {
  dataItem: AssetRouter;
}): React.ReactElement {
  return (
    <td
      title={
        latestInventorization.os && latestInventorization.os.version
          ? `${latestInventorization.os.name} ${latestInventorization.os.version}`
          : ''
      }
    >
      {latestInventorization.os && classOfDevice ? (
        <div className="cell_os">
          <img
            src={`${process.env.PUBLIC_URL}/images/os_logo/${
              IMG_SRC_VALUES[
                classOfDevice.toLowerCase() === 'pa-vm'
                  ? 'paloaltonetworks'
                  : classOfDevice.toLowerCase().includes('vmware')
                  ? 'vmware'
                  : classOfDevice.toLowerCase()
              ]
            }`}
            alt={latestInventorization.os.name}
          />
          <span>
            {latestInventorization.os.name} {latestInventorization.os.version}
          </span>{' '}
        </div>
      ) : (
        <span>-</span>
      )}
    </td>
  );
}
