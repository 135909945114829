import { nanoid } from 'nanoid';
import localeNewTask from '../../../../utils/i18n/taskLocale/newTask.json';
import { useHostModalContext } from '../../../../hooks/useHostModalContext';
import { useTaskAddContext } from '../../../../hooks/useTaskAddContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;

export function KbUpdateBlockTask(): React.ReactElement {
  const { hostForm, missedKbs, kbsInWl, isKbWlTask } = useHostModalContext();

  const { taskKbUpdate } = useTaskAddContext();

  return (
    <div className="apply-assets">
      <div className="title-apply-assets">
        {hostForm && (
          <div>
            {/* <span>
              {localeNewTask[currentLocale].form.applyForAsset.titleUpdatePkg}
              {hostForm[0].hostname},
            </span> */}
            <span>{localeNewTask[currentLocale].form.applyForAsset.titleUpdateKb}</span>
          </div>
        )}
      </div>
      <div className="list-assets">
        {taskKbUpdate &&
          !isKbWlTask &&
          missedKbs?.map((k, i) => {
            return (
              <span key={nanoid()}>
                {k.id}
                {missedKbs.length - 1 !== i && ', '}
              </span>
            );
          })}
        {taskKbUpdate &&
          isKbWlTask &&
          kbsInWl?.map((k, i) => {
            return (
              <span key={nanoid()}>
                {k.id}
                {kbsInWl.length - 1 !== i && ', '}
              </span>
            );
          })}
      </div>
    </div>
  );
}
