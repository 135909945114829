import { Dialog } from '@progress/kendo-react-dialogs';
import { useState, type ReactElement } from 'react';
import { TfaConnectionForm } from './TfaConnectingForm';
import { BackupCodesView } from './BackupCodesView';
import localeUser from '../../../../utils/i18n/userLocales/addUserForm.json';
import { useUserModalContext } from '../../../../hooks/useUserModalContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeUser;

export function TfaConnectModal({
  uid,
  userId,
  onClose,
  setModifiedTfa,
}: {
  uid: string;
  userId: string;
  onClose: () => void;
  setModifiedTfa: React.Dispatch<React.SetStateAction<boolean>>;
}): ReactElement {
  const [backupCodes, setBackupCodes] = useState<string[] | null>([]);
  const { email } = useUserModalContext();

  return (
    <Dialog
      title={`${localeUser[currentLocale].tfaForm.title} ${email}`}
      onClose={onClose}
      style={{ maxWidth: '660px' }}
    >
      {(!backupCodes || !backupCodes.length) && (
        <TfaConnectionForm
          uid={uid}
          userId={userId}
          onClose={onClose}
          setBackupCodes={setBackupCodes}
          setModifiedTfa={setModifiedTfa}
        />
      )}
      {backupCodes && backupCodes.length && (
        <BackupCodesView onClose={onClose} backupCodes={backupCodes} />
      )}
    </Dialog>
  );
}
