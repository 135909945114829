import type { GridCellProps } from '@progress/kendo-react-grid';
import styles from '../AssetList.module.scss';
import localeHost from '../../../../../utils/i18n/assetLocales/hostList.json';
import {
  setColorValAgent,
  getStatusAgentName,
} from '../../../../../utils/helpers/host-list-cell-helper';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHost;

export function AgentStatus(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const colorValue = setColorValAgent(dataItem?.agentValue?.status);

  return (
    <td>
      <div
        className="host__agent"
        title={
          dataItem.agentValue &&
          'status' in dataItem.agentValue &&
          getStatusAgentName(dataItem.agentValue.status, currentLocale)
        }
      >
        {dataItem.agentValue ? (
          <div className={styles.scan_type}>
            <div className="agent-status">
              <span style={{ backgroundColor: colorValue[0] }} />
              {/* {getStatusAgentName(dataItem.agentValue.status, currentLocale)} */}
              <div>
                <span>{dataItem.agentSoftwareVersion}</span>
              </div>
            </div>

            {dataItem.credential && dataItem.credential.type === 'ssh' && (
              <span className="credential-icon">ssh</span>
            )}
            {dataItem.credential && dataItem.credential.type === 'winrm' && (
              <span className="credential-icon">winrm</span>
            )}
          </div>
        ) : (
          <span>{localeHost[currentLocale].form.waitingForData}</span>
        )}
      </div>
    </td>
  );
}
