import type { GridCellProps } from '@progress/kendo-react-grid';
import localeHost from '../../../../../utils/i18n/assetLocales/hostList.json';
import type { AssetsHostItemDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/assetsHostsDashboardResponse.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHost;
export function Updates(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { latestAudit }: AssetsHostItemDashboardResponse = dataItem;

  return (
    <td className="number">
      {latestAudit ? (
        <span>{latestAudit.updates.count}</span>
      ) : (
        <span>{localeHost[currentLocale].form.waitingForData}</span>
      )}
    </td>
  );
}
