import { useHistory } from 'react-router';
import { Button } from '../baseElements/Button';
import styles from '../../features/Host/hostComponents/HostDescription.module.scss';
import localeHostDescription from '../../../utils/i18n/assetLocales/hostDescription.json';
import type { IChangeColorTag } from '../../../utils/helpers/types';
import { useAssetCreatePropContext } from '../../../hooks/useAssetCreatePropContext';
import type { Tag } from '../../../types/__generated/on-premise-solution/api/tagsResponse.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHostDescription;

interface IAssetTagContexMenu {
  tag: Tag;
  setHovered: React.Dispatch<React.SetStateAction<string>>;
  indexEl: number;
}

export function AssetTagContextMenu(contexMenuProps: IAssetTagContexMenu): React.ReactElement {
  const history = useHistory();

  const { tag, setHovered, indexEl } = contexMenuProps;

  const { tagId, color, name } = tag;

  const {
    setTagDataForDel,
    setTagMenu,
    setEditTagData,
    setTagName,
    editTagColor,
    setEditTagColor,
  } = useAssetCreatePropContext();

  const onEdit = (): void => {
    setEditTagData({
      tagId,
      indexEl,
    });
    setTagName(name);
  };

  const handleConfirm = (): void => {
    setTagDataForDel({ tagId, tagName: name });
    setTagMenu(false);
    setHovered('');
  };

  const handleColor = (dataColor: IChangeColorTag): void => {
    setEditTagColor({ ...dataColor });
    setTagMenu(false);
    setHovered('');
  };

  const filterByTag = (tagName: string): void => {
    setTagName(tagName);
    history.push(
      `/lk/assets/hosts?skip=0&limit=15&sortField=latestAudit.maxCvssScore&sortOrder=desc&tags.name[$regex]=${tagName}`,
    );
    // window.open(
    //   `/lk/assets/hosts?skip=0&limit=15&sortField=latestAudit.maxCvssScore&sortOrder=desc&tags.name[$regex]=${tagName}`,
    //   '_blank',
    // );
  };

  return (
    <div className={styles.assetTagContextMenu}>
      <div>
        <Button
          fill="menu"
          icon="delete"
          className={styles.credentials_button}
          onClick={handleConfirm}
        >
          {localeHostDescription[currentLocale].summary.deleteTag}
        </Button>
        <Button fill="menu" icon="edit" className={styles.credentials_button} onClick={onEdit}>
          {localeHostDescription[currentLocale].summary.renameTag}
        </Button>
        <Button
          fill="menu"
          icon="drop"
          className={styles.credentials_button}
          onClick={(): void => handleColor({ tagId, indexEl, color })}
        >
          {localeHostDescription[currentLocale].summary.changeColor}
        </Button>
        <Button
          fill="menu"
          icon="filterTag"
          className={styles.credentials_button}
          onClick={(): void => filterByTag(name)}
        >
          {localeHostDescription[currentLocale].summary.assetsWithThisTag}
        </Button>
      </div>
    </div>
  );
}
