import type { ReactElement } from 'react';
import { useState } from 'react';
import { Button } from './Button';
import styles from './Input.module.scss';
import { Input } from './Input';

interface IInputProps {
  id?: string;
  name?: string;
  style?: React.CSSProperties;
  className?: string;
  placeholder?: string;
  value?: string;
  type: string;
  maxLength?: number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

export function SecureInput(props: IInputProps): ReactElement {
  const { id, name, style, className, placeholder, value, type, maxLength, onChange, onBlur } =
    props;

  const defineType = (secure: boolean): 'password' | 'text' => {
    return secure ? 'password' : 'text';
  };

  const [isSecure, setSecure] = useState<boolean>(type === 'password');

  return (
    <div className={styles.secure}>
      <Input
        style={style}
        name={name}
        type={defineType(isSecure)}
        value={value}
        id={id}
        onChange={onChange}
        onBlur={onBlur}
        maxLength={maxLength}
        className={`${styles.input} ${className || ''}`}
        placeholder={placeholder}
      />

      <Button
        fill="flat"
        icon={isSecure ? 'show' : 'hide'}
        onClick={(): void => {
          setSecure((prev) => !prev);
        }}
        className={styles.secure_button}
      />
    </div>
  );
}
