/* eslint-disable no-nested-ternary */
import '../../../TaskDescription.module.scss';
import { NavLink } from 'react-router-dom';
import localeVmTask from '../../../../../../utils/i18n/taskLocale/vmTask.json';
import type { AuditDashboardResponse } from '../../../../../../types/__generated/on-premise-solution/api/auditDashboardResponse.v1';

export function FirstColumn({
  currentLocale,
  auditInfo,
}: {
  currentLocale: 'ru-RU' | 'en-EN';
  auditInfo: AuditDashboardResponse | undefined;
}): React.ReactElement {
  return (
    <table className="column">
      <tbody>
        <tr>
          <td>
            <span>{localeVmTask[currentLocale].summary.start}</span>
          </td>
          <td>
            {auditInfo && auditInfo.taskAction ? (
              <span>{new Date(auditInfo.taskAction.startedAt).toLocaleString()}</span>
            ) : (
              <span>-</span>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeVmTask[currentLocale].summary.finish}</span>
          </td>
          {auditInfo && auditInfo.taskAction ? (
            <td>{new Date(String(auditInfo.taskAction.finishedAt)).toLocaleString()}</td>
          ) : (
            <td>-</td>
          )}
        </tr>
        <tr>
          <td>
            <span>{localeVmTask[currentLocale].summary.startId}</span>
          </td>
          <td>
            {auditInfo ? (
              <NavLink
                className="link-underline"
                to={`/lk/task/executions/${auditInfo.taskExecutionId}`}
              >
                {auditInfo.taskExecutionId}
              </NavLink>
            ) : (
              <span>-</span>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeVmTask[currentLocale].summary.launchedBy}</span>
          </td>
          <td>
            {auditInfo && auditInfo.createdBy.source === 'user' ? (
              <span>{auditInfo.createdBy.name}</span>
            ) : (
              <span>-</span>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
