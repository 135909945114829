/* eslint-disable no-nested-ternary */
import '../../../TaskDescription.module.scss';
import { Button } from '../../../../../common/baseElements/Button';
import localeVmTask from '../../../../../../utils/i18n/taskLocale/vmTask.json';
import { handleVectorModal } from '../../../../../../utils/helpers/audit-helpers';
import { useAuditModalContext } from '../../../../../../hooks/useAuditModalContext';
import type { ICalculateClasses } from '../../../../../../utils/helpers/task-helpers';
import { CvssBlock } from '../../../../../common/smallBlocks/CvssBlock';

export function ThirdColumn({
  vulnSoftTotal,
  dataClassesCvssSeverity,
  currentLocale,
  type,
}: {
  vulnSoftTotal: number | undefined;
  dataClassesCvssSeverity: ICalculateClasses;
  currentLocale: 'ru-RU' | 'en-EN';
  type: 'linux' | 'windows' | 'network_device' | 'hypervisor' | undefined;
}): React.ReactElement {
  const { setCvssVector, setOpen } = useAuditModalContext();

  return (
    <table className="column">
      <tbody>
        <tr>
          <td>
            <span>{localeVmTask[currentLocale].summary.severity}</span>
          </td>
          <td>
            <span
              style={{ fontWeight: '700' }}
              className={dataClassesCvssSeverity.cssSeverityClasses.join(' ')}
            >
              {dataClassesCvssSeverity.severityValue ? dataClassesCvssSeverity.severityValue : '-'}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeVmTask[currentLocale].summary.cvssScore}</span>
          </td>
          <td>
            <CvssBlock score={dataClassesCvssSeverity.cvssScoreValue} />
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeVmTask[currentLocale].summary.cvssVector}</span>
          </td>
          <td>
            <span>
              {dataClassesCvssSeverity?.cvssVectorValue ? (
                <Button
                  fill="modal"
                  onClick={(): void =>
                    handleVectorModal(
                      dataClassesCvssSeverity.cvssVectorValue as string,
                      setOpen,
                      setCvssVector,
                    )
                  }
                >
                  <span className="underlined">{dataClassesCvssSeverity.cvssVectorValue}</span>
                </Button>
              ) : (
                '-'
              )}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span>
              {type === 'linux'
                ? localeVmTask[currentLocale].tabs.vulnSoftLin
                : localeVmTask[currentLocale].summary.vulnerableSoft}
            </span>
          </td>
          <td>
            <span>{vulnSoftTotal}</span>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
