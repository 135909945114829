import '../../../../Host/hostComponents/HostDescription.module.scss';
import localeHostDescription from '../../../../../../utils/i18n/assetLocales/hostDescription.json';
import localeImageDescription from '../../../../../../utils/i18n/assetLocales/imageDescription.json';
import type { ContainerRegistryResponse } from '../../../../../../types/__generated/on-premise-solution/api/containerRegistryResponse.v1';
import { useRegDescriptionRepList } from '../../../../../../hooks/components/useRegDescriptionRepList';

export function TableFRegistryMetaBlock({
  currentLocale,
}: {
  currentLocale: 'ru-RU' | 'en-EN';
}): React.ReactElement {
  const { queryDashboardRegistryId } = useRegDescriptionRepList();

  const { data } = queryDashboardRegistryId;

  return (
    <table className="column">
      <tbody>
        <tr>
          <td>
            <span>{localeImageDescription[currentLocale].summary.url}</span>
          </td>
          <td>
            {data?.address || '-'}
            {/* {data?.type === 'image' && data?.latestInventorization && (
              <span style={{ textTransform: 'capitalize' }}>
                {' '}
                {data?.latestInventorization?.os?.type === 'linux'
                  ? data?.latestInventorization.os.name
                  : data.latestInventorization.os?.name}{' '}
                {data.latestInventorization.os?.version}
              </span>
            )} */}
          </td>
        </tr>
        <tr>
          <td>
            {/* <span>{localeHostDescription[currentLocale].summary.size}</span> */}
            <span>{localeImageDescription[currentLocale].summary.class}</span>
          </td>
          <td>
            {data?.class || '-'}

            {/* {data?.type === 'image' && data?.latestInventorization && (
              <span>
                {' '}
                {data.latestInventorization.size
                  ? `${Math.floor(data.latestInventorization.size / 1024 / 1024)} Mb`
                  : '-'}{' '}
              </span>
            )} */}
          </td>
        </tr>
        <tr>
          <td>
            {/* <span>{localeHostDescription[currentLocale].summary.arch}</span> */}
            <span>{localeImageDescription[currentLocale].summary.type}</span>
          </td>
          <td>
            {data?.type || '-'}
            {/* {data?.type === 'image' && data?.latestInventorization && (
              <span>{data.latestInventorization.arch || '-'}</span>
            )} */}
          </td>
        </tr>
        {/* <tr>
          <td>
            <span>{localeHostDescription[currentLocale].summary.system}</span>
          </td>
          <td>:</td>
          <td>
            {data?.type === 'image' && data?.latestInventorization && (
              <span>
                {data?.latestInventorization?.os.name} {data?.latestInventorization?.os.type}{' '}
                {data?.latestInventorization?.os.version}
              </span>
            )}
          </td>
        </tr> */}
        {/* <tr>
          <td>
            <span>{localeHostDescription[currentLocale].summary.idAsset}</span>
          </td>
          <td>:</td>
          <td>
            <span> {data?.type === 'image' && data?.assetId}</span>
          </td>
        </tr> */}
      </tbody>
    </table>
  );
}
