/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-pascal-case */
import { useMutation, useQueryClient } from 'react-query';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '../../../common/baseElements/Button';
import localeHostDescription from '../../../../utils/i18n/assetLocales/hostDescription.json';
import localeDelete from '../../../../utils/i18n/commonLocales/confirmDeleteForm.json';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { deleteTag } from '../../../../services/asset-service';
import type { TagDeletionResponse } from '../../../../types/__generated/on-premise-solution/api/tagDeletionResponse.v1';
import styles from './HostDescription.module.scss';
import { useAssetCreatePropContext } from '../../../../hooks/useAssetCreatePropContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHostDescription;

export function HostConfirmDelTag(): React.ReactElement {
  const queryClient = useQueryClient();

  const { tagDataForDel, setTagDataForDel } = useAssetCreatePropContext();

  const mutation = useMutation<TagDeletionResponse | null, ApiError, string>(
    () => deleteTag(tagDataForDel?.tagId || ''),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('tagsList');
        queryClient.invalidateQueries('dashboardAssetId');
      },
    },
  );

  const handleClose = (): void => {
    setTagDataForDel(null);
  };

  const handleConfirm = (): void => {
    mutation.mutate(tagDataForDel!.tagId);
    setTagDataForDel(null);
  };

  const titleModal = `${localeHostDescription[currentLocale].summary.deleteTagTitle} ${
    tagDataForDel?.tagName || '-'
  }`;

  return (
    <Dialog title={titleModal} onClose={handleClose} className="modal small">
      <div className="k-form">
        <div className={styles.confirmContent}>
          <p>{localeHostDescription[currentLocale].summary.deleteTagText}</p>
          <span>
            <b>{tagDataForDel?.tagName || ''}</b>{' '}
          </span>
          <p>{localeHostDescription[currentLocale].summary.deleteTagText2}</p>
        </div>
        <div>
          <p>{localeHostDescription[currentLocale].summary.deleteTagText3}</p>
        </div>
        <div className="k-form-buttons">
          <Button type="submit" onClick={handleConfirm}>
            {localeDelete[currentLocale].btnDeleteYes}
          </Button>
          <Button type="button" onClick={handleClose}>
            {localeDelete[currentLocale].btnDeleteNo}
          </Button>
          {mutation.isError && (
            <div className="info alert alert-danger">Error: {mutation.error?.message}</div>
          )}
        </div>
      </div>
    </Dialog>
  );
}
