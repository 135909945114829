/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
import { ReactElement, useMemo } from 'react';
import type {
  InventorizationDifferenceLinux,
  InventorizationDifferenceWindows,
} from '../../../../../types/__generated/on-premise-solution/api/inventorizationDifference.v1';
import {
  defineWrapperStatuses,
  type DiffFilterTypes,
} from '../../../../../utils/helpers/difference-modal-helper';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { WrapperMenu } from '../../../Task/taskIDetails/iDetailsComponents/WrapperMenu';
import styles from '../../forms/AddAssetForm.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

function CpuWrapper({
  cpu,
  assetType,
  tdClassName,
  needShowStatus,
}: {
  cpu:
    | Exclude<InventorizationDifferenceLinux['cpu_info'], null>[number]['new']
    | Exclude<InventorizationDifferenceWindows['cpu_info'], null>[number]['new'];
  assetType: 'linux' | 'windows';
  tdClassName?: string;
  needShowStatus: boolean;
}): ReactElement {
  const wrapperStatuses: string[] = [];
  if (needShowStatus && tdClassName === styles.closed) wrapperStatuses.push('closed');
  if (needShowStatus && tdClassName === styles.updated) wrapperStatuses.push('new');
  if (!tdClassName || (tdClassName !== styles.closed && tdClassName !== styles.updated))
    wrapperStatuses.push('noChanges');

  return cpu ? (
    <WrapperMenu
      title={cpu?.model}
      className={tdClassName}
      diffStatuses={needShowStatus ? wrapperStatuses : undefined}
    >
      <table className="column">
        <tr>
          <td className={tdClassName}>{locale[currentLocale].cpu.model}</td>
          <td className={tdClassName}>{cpu?.model}</td>
        </tr>
        <tr>
          <td className={tdClassName}>{locale[currentLocale].cpu.socket_designation}</td>
          <td className={tdClassName}>{cpu?.socket_designation}</td>
        </tr>
        <tr>
          <td className={tdClassName}>{locale[currentLocale].cpu.manufacturer}</td>
          <td className={tdClassName}>{cpu?.manufacturer}</td>
        </tr>
        <tr>
          <td className={tdClassName}>{locale[currentLocale].cpu.processorType}</td>
          <td className={tdClassName}>{cpu?.processor_type}</td>
        </tr>
      </table>
      <table className="column">
        <tr>
          <td className={tdClassName}>{locale[currentLocale].cpu.numberOfCores}</td>
          <td className={tdClassName}>{cpu?.number_of_cores}</td>
        </tr>
        <tr>
          <td className={tdClassName}>{locale[currentLocale].cpu.logicalProcessors}</td>
          <td className={tdClassName}>{cpu?.logical_processors}</td>
        </tr>
        <tr>
          <td className={tdClassName}>{locale[currentLocale].cpu.currentClockSpeed}</td>
          <td className={tdClassName}>{cpu?.current_clock_speed}</td>
        </tr>
        <tr>
          <td className={tdClassName}>{locale[currentLocale].cpu.maxClockSpeed}</td>
          <td className={tdClassName}>{cpu?.max_clock_speed}</td>
        </tr>
      </table>
      <table className="column">
        <tr>
          <td className={tdClassName}>{locale[currentLocale].cpu.addressWidth}</td>
          <td className={tdClassName}>{cpu?.address_width}</td>
        </tr>
        <tr>
          <td className={tdClassName}>{locale[currentLocale].cpu.availability}</td>
          <td className={tdClassName}>{cpu?.availability}</td>
        </tr>
        <tr>
          <td className={tdClassName}>{locale[currentLocale].cpu.device_id}</td>
          <td className={tdClassName}>{cpu?.number_of_cores}</td>
        </tr>
        <tr>
          <td className={tdClassName}>{locale[currentLocale].cpu.handle}</td>
          {assetType === 'linux' && cpu && 'handle' in cpu && typeof cpu.handle === 'string' ? (
            <td className={tdClassName}>{cpu.handle}</td>
          ) : (
            <td className={tdClassName}>-</td>
          )}
        </tr>
      </table>
    </WrapperMenu>
  ) : (
    <></>
  );
}

export function DiffCpu({
  data,
  filter,
}: {
  data: InventorizationDifferenceLinux | InventorizationDifferenceWindows;
  filter: DiffFilterTypes;
}): React.ReactElement {
  const cpuInfoData = useMemo(() => {
    const result: {
      all: any[];
      noChanges: any[];
      installed: any[];
      removed: any[];
      changed: any[];
      updated: null;
    } = {
      all: [],
      noChanges: [],
      installed: [],
      removed: [],
      changed: [],
      updated: null,
    };

    data.cpu_info?.forEach((cpu) => {
      if (cpu.noChanges) {
        result.all.push(cpu.noChanges);
        result.noChanges.push(cpu.noChanges);
      } else if (cpu.old) {
        result.all.push({ ...cpu.old, className: styles.closed });
        if (cpu.new)
          result.changed.push(
            { ...cpu.old, className: styles.closed },
            { ...cpu.new, className: styles.updated },
          );
        else result.removed.push({ ...cpu.old, className: styles.closed });
      }
      if (cpu.new) {
        result.all.push({ ...cpu.new, className: styles.updated });
        if (!cpu.old) result.installed.push({ ...cpu.new, className: styles.updated });
      }
    });

    return result;
  }, [data.cpu_info, filter]);

  return cpuInfoData[filter] && cpuInfoData[filter]?.length ? (
    <WrapperMenu
      title={locale[currentLocale].cpu.title}
      diffStatuses={filter === 'all' ? defineWrapperStatuses(data.cpu_info) : undefined}
    >
      {cpuInfoData[filter]?.map((cpu) => (
        <CpuWrapper
          cpu={cpu}
          assetType={data.type}
          tdClassName={cpu.className}
          needShowStatus={filter === 'all'}
        />
      ))}
    </WrapperMenu>
  ) : (
    <></>
  );
}
