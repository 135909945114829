import { LoadingPanel } from './LoadingPanel';

interface IСommonTableLoader {
  queryData: boolean;
  filteringData: boolean;
}

export function СommonTableLoader(props: IСommonTableLoader): JSX.Element | null {
  const { queryData, filteringData } = props;

  return queryData || filteringData ? <LoadingPanel /> : null;
}
