/* eslint-disable no-nested-ternary */
import { Grid, GridColumn as Column, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useMemo, useState } from 'react';
import { LAZY_PAGEABLE_DATA } from '../../../../../utils/helpers/constants';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { WrapperMenu } from '../../../Task/taskIDetails/iDetailsComponents/WrapperMenu';
import type { InventorizationDifferenceLinux } from '../../../../../types/__generated/on-premise-solution/api/inventorizationDifference.v1';
import { DiffTypeCell } from '../cells/DiffTypeCell';
import { DiffStandartCell } from '../cells/DiffStandartCell';
import { DiffSizeCell } from '../cells/DiffSizeCell';
import {
  DiffFilterTypes,
  IDiffInvElementInfo,
  createFromListInvData,
  defineWrapperStatuses,
} from '../../../../../utils/helpers/difference-modal-helper';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function DiffMount({
  data,
  filter,
}: {
  data: InventorizationDifferenceLinux;
  filter: DiffFilterTypes;
}): React.ReactElement {
  const [page, setPage] = useState({ take: 5, skip: 0 });

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  const mountsData = useMemo(
    () => createFromListInvData(data.mounts as unknown as IDiffInvElementInfo[], filter),
    [data.mounts, filter],
  );

  if (!mountsData || !mountsData.length) return <></>;

  return (
    <WrapperMenu
      title={locale[currentLocale].mounts.title}
      diffStatuses={filter === 'all' ? defineWrapperStatuses(data.mounts) : undefined}
    >
      <Grid
        data={mountsData.slice(page.skip, page.take + page.skip)}
        pageable={LAZY_PAGEABLE_DATA}
        scrollable="none"
        total={mountsData.length}
        pageSize={page.take}
        skip={page.skip}
        className="no-margin-top"
        onPageChange={pageChange}
      >
        <Column
          field="diffType"
          cell={DiffTypeCell}
          title=" "
          width={20}
          sortable={false}
          headerClassName="no-cursor"
        />
        <Column
          field="path"
          title={locale[currentLocale].mounts.path}
          cell={DiffStandartCell}
          sortable={false}
          headerClassName="no-cursor"
        />
        <Column
          field="device"
          title={locale[currentLocale].mounts.device}
          cell={DiffStandartCell}
          sortable={false}
          headerClassName="no-cursor"
        />
        <Column
          field="type"
          title={locale[currentLocale].mounts.type}
          cell={DiffStandartCell}
          sortable={false}
          headerClassName="no-cursor"
        />
        <Column
          field="size"
          title={locale[currentLocale].mounts.size}
          cell={DiffSizeCell}
          sortable={false}
          headerClassName="no-cursor"
        />
        <Column
          field="used"
          title={locale[currentLocale].mounts.used}
          cell={DiffSizeCell}
          sortable={false}
          headerClassName="no-cursor"
        />
        <Column
          field="available"
          title={locale[currentLocale].mounts.available}
          cell={DiffSizeCell}
          sortable={false}
          headerClassName="no-cursor"
        />
      </Grid>
    </WrapperMenu>
  );
}
