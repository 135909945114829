import type { GridCellProps } from '@progress/kendo-react-grid';
import { NavLink } from 'react-router-dom';
import type { TaskExecutionDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/taskExecutionsDashboardResponse.v1';

export function HistoryTaskName(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { taskExecutionId, taskName }: TaskExecutionDashboardResponse = dataItem;

  return (
    <td>
      <NavLink className="link-underline" to={`/lk/task/executions/${taskExecutionId}`}>
        {taskName}
      </NavLink>
    </td>
  );
}
