import type { GridCellProps } from '@progress/kendo-react-grid';
// import type localeHost from '../../../../../utils/i18n/assetLocales/hostList.json';
import type { AssetImageDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/assetsImagesDashboardResponse.v1';

export function ImageSize(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { latestInventorization }: AssetImageDashboardResponse = dataItem;

  return (
    <td style={{ position: 'relative' }}>
      {latestInventorization.size
        ? `${Math.round(latestInventorization.size / 1024 / 1024)} Mb`
        : ''}
    </td>
  );
}
