import type { GridCellProps } from '@progress/kendo-react-grid';
import { useHistory } from 'react-router';
import { Button } from '../../../common/baseElements/Button';
import { useTaskContext } from '../../../../hooks/useTaskContext';
import type { TaskExecutionDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/taskExecutionsDashboardResponse.v1';
import localeTask from '../../../../utils/i18n/taskLocale/task.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTask;

export function HistoryMenu(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { taskId }: TaskExecutionDashboardResponse = dataItem;

  const history = useHistory();

  const { setTaskId, setTaskDeleteValues } = useTaskContext();

  const handleConfirm = (): void => {
    setTaskDeleteValues({
      taskId: dataItem.taskId,
      taskName: dataItem.name,
    });
  };

  const handleEdit = (): void => {
    setTaskId(taskId);
    history.push('/lk/audit/taskaddedit');
  };

  return (
    <td className="cell_menu">
      <div className="task__menu">
        <div className="dropdown__container">
          <span className="k-icon k-i-more-vertical" />
          <div className="dropdown__content">
            <Button fill="menu" onClick={handleEdit} disabled>
              {localeTask[currentLocale].btnEdit}
            </Button>
            <Button fill="menu" onClick={handleConfirm} disabled>
              {localeTask[currentLocale].btnDelete}
            </Button>
          </div>
        </div>
      </div>
    </td>
  );
}
