/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import type { GridCellProps } from '@progress/kendo-react-grid';

interface IProcess {
  [k: string]: unknown;
  name?: string | null | undefined;
  pid?: number | null | undefined;
}

export function ProcessesCell(props: GridCellProps): React.ReactElement {
  const { dataItem, className } = props;

  // return dataItem && dataItem. !== '-' ? <td className={className || ''}>{value}</td> : <td>-</td>;
  return dataItem && dataItem.processes && Array.isArray(dataItem.processes) ? (
    <td
      className={className || ''}
      title={dataItem.processes.map((p: IProcess) => `${p.name} (${p.pid})`).join('; ')}
    >
      {dataItem.processes.map((p: IProcess) => `${p.name} (${p.pid})`).join('; ')}
    </td>
  ) : (
    <td className={className || ''}>-</td>
  );
}
