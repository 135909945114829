import type { GridCellProps } from '@progress/kendo-react-grid';
import type { ISecurityVulns } from '../../../../utils/helpers/types';

export function CellSecurityCwe(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { cwe }: ISecurityVulns = dataItem;

  return (
    <td title={typeof cwe === 'object' ? `${cwe.id}: ${cwe.name}` : ''}>
      <span>
        {typeof cwe === 'object' ? (
          <span>
            {cwe.id}: {cwe.name}
          </span>
        ) : (
          <span>-</span>
        )}
      </span>
    </td>
  );
}
