import type { GridCellProps } from '@progress/kendo-react-grid';
import type { IWinSoftList } from '../../../../../utils/helpers/types';

export function CellPublisher(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { publisher }: IWinSoftList = dataItem;

  return <td>{publisher ? <span>{publisher}</span> : <span>-</span>}</td>;
}
