/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import styles from './TaskType.module.scss';
import localeHost from '../../../../utils/i18n/assetLocales/hostList.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHost;

const typeR = {
  super_admin: 'SA',
  account_admin: 'AA',
  user: 'U',
  reader_user: 'R',
};

export function UserRole({
  title,
  role,
}: {
  title?: boolean;
  role: 'super_admin' | 'account_admin' | 'user' | 'reader_user';
}): React.ReactElement {
  return (
    <span
      className={`${styles.user_role} ${styles[role]}`}
      title={title ? localeHost[currentLocale].userRole[role] : ''}
    >
      {typeR[role]}
    </span>
  );
}
