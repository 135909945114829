import type { GridCellProps } from '@progress/kendo-react-grid';
import type { ReactElement } from 'react';
import { StandartCell } from '../../../../common/baseElements/StandartCell';
import styles from '../../forms/AddAssetForm.module.scss';

export function DiffStandartCell(props: GridCellProps): ReactElement {
  const { className, dataItem, ...other } = props;

  return (
    <StandartCell
      className={`
            ${dataItem.diffType === 'updated' ? styles.updated : ''} 
            ${dataItem.diffType === 'closed' ? styles.closed : ''} 
            ${className || ''}`}
      dataItem={dataItem}
      {...other}
    />
  );
}
