/* eslint-disable react/jsx-pascal-case */
import { Filter } from '@progress/kendo-react-data-tools';
import { Dialog } from '@progress/kendo-react-dialogs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Button } from '../../../../common/baseElements/Button';
import { СommonTableLoader } from '../../../../common/tableLoader/CommonTableLoader';
import { getUrlList } from '../../../../../utils/helpers/getUrl-list-helper';
import localeAccount from '../../../../../utils/i18n/accountLocales/accountList.json';
import { useAccountList } from '../../../../../hooks/components/useAccountList';
import { CommonAccountList } from '../../../../common/CommonAccountList';
import { useAccountModalContext } from '../../../../../hooks/useAccountModalContext';
import { FILTER_ACCOUNT_LIST } from '../../../../../utils/helpers/constant-serv-filter-host';
import { addAllAccounts } from '../../../../../utils/helpers/apikey-helpers';
import { Input } from '../../../../common/baseElements/Input';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAccount;

export function AccountListForForm(): React.ReactElement {
  const dataForHooks = {
    name: 'name',
    componentName: 'AddAccountList',
  };

  const urlParams = getUrlList('addAccountList');

  const {
    setAddAccount,
    accountForm,
    setAccountForm,
    exitAccountFrom,
    delAllAccounts,
    addSelectedAccounts,
  } = useAccountModalContext();

  const {
    filter,
    onFilterChange,
    sort,
    page,
    total,
    pageChange,
    setSort,
    isLoading,
    filterStatus,
    isError,
    errorMessage,
    data,
    handleCustomSearch,
    customSearch,
  } = useAccountList(urlParams, dataForHooks);

  return (
    <div className="add-asset">
      <Dialog
        title={localeAccount[currentLocale].titleCheckboxAccount}
        onClose={(): void => setAddAccount(false)}
      >
        <div className="k-form">
          <div className="form-content select-asset">
            <LocalizationProvider language={currentLocale}>
              <IntlProvider locale={currentLocale.substring(0, 2)}>
                <div className="filter-line">
                  <Filter
                    value={filter}
                    onChange={onFilterChange}
                    fields={FILTER_ACCOUNT_LIST[currentLocale]}
                  />
                  {filter.filters.length === 0 && (
                    <Input
                      name="customSearch"
                      placeholder={localeAccount[currentLocale].customSearch}
                      value={customSearch}
                      onChange={(e): void => handleCustomSearch(e)}
                    />
                  )}
                </div>
              </IntlProvider>
            </LocalizationProvider>
            <div className="k-form-select-buttons select-asset__actions">
              <Button
                icon="check"
                onClick={(): void => addAllAccounts(data?.data, accountForm, setAccountForm)}
              >
                {localeAccount[currentLocale].btnAddAllAccounts}
              </Button>
              {accountForm && accountForm.length > 0 && (
                <Button icon="close" onClick={delAllAccounts}>
                  {localeAccount[currentLocale].btnDelAllAccounts} ({accountForm?.length})
                </Button>
              )}
            </div>
            <CommonAccountList
              sort={sort}
              data={data}
              page={page}
              total={total}
              pageChange={pageChange}
              setSort={setSort}
              dataForHooks={dataForHooks}
            />
            <СommonTableLoader queryData={isLoading} filteringData={filterStatus} />
            {isError && <div className="info alert alert-danger">Error: {errorMessage}</div>}
          </div>
          <div className="k-form-buttons">
            <Button type="submit" onClick={addSelectedAccounts}>
              {localeAccount[currentLocale].addAccount}{' '}
              {accountForm &&
                accountForm?.length > 0 &&
                `(${accountForm.filter((a) => a.accountId)?.length})`}
            </Button>
            <Button type="button" onClick={exitAccountFrom}>
              {localeAccount[currentLocale].btnCancel}
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
