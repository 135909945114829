/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { createHostGroup, deleteHostAsset, editContentOfGroup } from '../../services/asset-service';
import type { ApiError } from '../../types/__generated/on-premise-solution/api/apiError.v1';
import type { AssetGroupCreationRequest } from '../../types/__generated/on-premise-solution/api/assetGroupCreationRequest.v1';
import type { AssetGroupCreationResponse } from '../../types/__generated/on-premise-solution/api/assetGroupCreationResponse.v1';
import type { AssetGroupDeletionResponse } from '../../types/__generated/on-premise-solution/api/assetGroupDeletionResponse.v1';
import type { AssetGroupUpdateRequest } from '../../types/__generated/on-premise-solution/api/assetGroupUpdateRequest.v1';
import type { AssetGroupUpdateResponse } from '../../types/__generated/on-premise-solution/api/assetGroupUpdateResponse.v1';
import type { Asset } from '../../types/__generated/on-premise-solution/api/enrichedTaskActionResponse.v1';
import type { IGroupHook } from '../../utils/helpers/types';
import { useGroupContext } from '../useGroupContext';
import { useHostModalContext } from '../useHostModalContext';
import type localeHostGroups from '../../utils/i18n/assetLocales/assetGroups.json';
import { useCommonContext } from '../useCommonContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHostGroups;

export function useGroupHook(
  name: string | undefined,
  description: string | undefined | null,
  assetGroupId: string | null,
): IGroupHook {
  const queryClient = useQueryClient();

  const [typeOfChangeGr, setTypeOfChangeGr] = useState('');

  const history = useHistory();

  const [isConfirm, setConfirm] = useState(false);

  const [isDelHostFromGr, setDelHostFromGr] = useState(false);

  const {
    setHostId: setAssetId,
    setIsEdit,
    setIsOpen,
    setGroupData,
    isEdit,
    hostId,
    initialStateGr,
    groupName,
    setGroupName,
    groupDescription,
    setGroupDescription,
    setErrorMutation,
  } = useGroupContext();

  const { hostForm, hostFormTemp, setHostForm, setHostFormTemp, setOpen, isOpen } =
    useHostModalContext();

  const { setOpenForm } = useCommonContext();

  const editGroup = (): void => {
    const data = {
      name: name || '',
      description,
    };
    setAssetId(assetGroupId);
    setIsEdit(true);
    setGroupData(data);
    setIsOpen(true);
  };

  const mutation = useMutation<AssetGroupDeletionResponse | null, ApiError, Asset['assetId']>(
    (payload) => deleteHostAsset(payload),
    {
      onSuccess: () => {
        setConfirm(false);
        queryClient.invalidateQueries('HostGroupList');
        history.push('/lk/assets/groups');
      },
      onError: (resp) => {
        setErrorMutation(resp.message);
        setHostForm(null);
        setHostFormTemp(null);
      },
    },
  );

  const mutationEditGroup = useMutation<
    AssetGroupUpdateResponse,
    ApiError,
    AssetGroupUpdateRequest & { assetGroupId: string }
  >((payload) => editContentOfGroup(payload), {
    onSuccess: () => {
      setHostForm(null);
      setHostFormTemp(null);
      setTypeOfChangeGr('');
      setDelHostFromGr(false);
      queryClient.invalidateQueries('hostGroupDescription');
    },
    onError: (resp) => {
      setErrorMutation(resp.message);
    },
  });

  const handleConfirm = (): void => {
    setConfirm(true);
  };

  const handleDelete = (groupId: string): void => {
    mutation.mutate(groupId);
  };

  const handleDelAssets = (): void => {
    setTypeOfChangeGr('delete');
    const data = [] as string[];
    hostFormTemp?.forEach((h) => {
      if (h.assetId) data.push(h.assetId);
    });
    mutationEditGroup.mutateAsync({
      assetIdsToDelete: data,
      assetGroupId,
      type: 'host' as const,
    } as unknown as AssetGroupUpdateRequest & { assetGroupId: string });
  };

  const addAssetToGroup = (): void => {
    setOpen(true);
    setTypeOfChangeGr('add');
  };

  useEffect(() => {
    if (typeOfChangeGr === 'add' && hostForm && hostForm.length > 0 && !isOpen) {
      const assetsData = [] as string[];
      hostForm?.forEach((h) => {
        if (h.assetId) assetsData.push(h.assetId);
      });

      mutationEditGroup
        .mutateAsync({
          assetIdsToAdd: assetsData,
          assetGroupId,
          type: 'host' as const,
        } as AssetGroupUpdateRequest & { assetGroupId: string })
        .then(() => {
          queryClient.invalidateQueries('hostGroupDescription');
        });
    }
  }, [isOpen]);

  const mutationNewGroup = useMutation<
    AssetGroupCreationResponse,
    ApiError,
    AssetGroupCreationRequest
  >((payload) => createHostGroup(payload), {
    onSuccess: (res) => {
      queryClient.invalidateQueries('HostGroupList');
      history.push(`/lk/assets/groups/${res.assetGroupId}`);
    },
    onError: (resp) => {
      setErrorMutation(resp.message);
    },
  });

  const handleSubmit = async (): Promise<void> => {
    if (isEdit) {
      const data = {
        name: initialStateGr?.name !== groupName ? groupName : undefined,
        // description: initialStateGr?.name !== groupDescription ? groupDescription : undefined,
        description:
          groupDescription &&
          groupDescription.length > 0 &&
          initialStateGr?.description !== groupDescription
            ? groupDescription
            : groupDescription?.length === 0
            ? null
            : undefined,
      };
      await mutationEditGroup
        .mutateAsync({
          ...data,
          assetGroupId: hostId,
          type: 'host' as const,
        } as AssetGroupUpdateRequest & { assetGroupId: string })
        .then(() => queryClient.invalidateQueries('HostGroupList'));
    } else {
      const data = {
        name: groupName,
        description: groupDescription?.length === 0 ? undefined : groupDescription,
      };
      await mutationNewGroup.mutateAsync({
        accountId: localStorage.getItem('ai') as string,
        type: 'host',
        ...data,
      });
    }
    setAssetId(null);
    setIsOpen(false);
    setGroupData(null);
    setIsEdit(false);
  };

  const handleWindow = (): void => {
    setIsOpen(false);
    setGroupData(null);
    setIsEdit(false);
    setOpenForm(false);
    setGroupName('');
    setGroupDescription('');
  };

  return {
    isConfirm,
    setConfirm,
    editGroup,
    handleConfirm,
    handleDelete,
    isError: mutation.isError,
    errorMessage: mutation.error?.message,
    handleDelAssets,
    typeOfChangeGr,
    setTypeOfChangeGr,
    addAssetToGroup,
    handleSubmit,
    handleWindow,
    isDelHostFromGr,
    setDelHostFromGr,
  };
}
