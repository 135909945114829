import type { GridCellProps } from '@progress/kendo-react-grid';
import { useEffect, useState } from 'react';
import type { EnrichedLogResponse } from '../../../../../types/__generated/on-premise-solution/api/enrichedLogResponse.v1';
import styles from '../../TaskDescription.module.scss';

export function CellMessage(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { message }: EnrichedLogResponse = dataItem;

  const [rows, setRows] = useState(1);

  useEffect(() => {
    if (message.length && message.length > 104 && message.length < 208) {
      setRows(2);
    } else if (message.length && message.length > 208 && message.length < 312) {
      setRows(3);
    } else if (message.length && message.length > 312) {
      setRows(4);
    } else {
      setRows(1);
    }
  }, [message.length]);

  return (
    <td title={message}>
      <textarea value={message} cols={109} rows={rows} disabled className={styles.logMessageTa} />
    </td>
  );
}
