/* eslint-disable react-hooks/exhaustive-deps */
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import type { TextFilterProps } from '@progress/kendo-react-data-tools/dist/npm/filter/filters/TextFilter';
import type localeTask from '../../../utils/i18n/taskLocale/task.json';
import type { IDaraFilterSelect } from '../../../utils/helpers/types';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTask;

let dataStatus = [] as IDaraFilterSelect[];
export function FilterStatusForDev(props: TextFilterProps): React.ReactElement {
  const { filter, onFilterChange } = props;

  dataStatus = [
    { text: currentLocale === 'ru-RU' ? 'Тип устройства...' : 'Type of device...', val: '' },
    { text: currentLocale === 'ru-RU' ? 'Маршрутизатор' : 'Router', val: 'router' },
    { text: currentLocale === 'ru-RU' ? 'Межсетевой экран' : 'Firewall', val: 'firewall' },
    { text: currentLocale === 'ru-RU' ? 'Коммутатор' : 'Switch', val: 'switch' },
    { text: currentLocale === 'ru-RU' ? 'Гипервизор' : 'Hypervisor', val: 'hypervisor' },
  ];

  const handleChange = (event: DropDownListChangeEvent): void => {
    onFilterChange({
      nextFilter: { ...filter, ...{ value: event.target.value.val, text: '' } },
    });
  };

  return (
    <DropDownList
      defaultValue={dataStatus[0]}
      data={dataStatus}
      textField="text"
      dataItemKey="val"
      onChange={handleChange}
    />
  );
}
