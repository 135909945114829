/* eslint-disable react/jsx-props-no-spreading */
import { Error, Label } from '@progress/kendo-react-labels';
import { Input } from '@progress/kendo-react-inputs';
import type { FieldRenderProps } from '@progress/kendo-react-form';

export function TextField(fieldRenderProps: FieldRenderProps): React.ReactElement {
  const { validationMessage, visited, name, setName, label, ...others } = fieldRenderProps;

  return (
    <div style={{ marginBottom: '3px' }} className="text-field">
      <Label>{label}</Label>
      <Input {...others} maxLength={100} />
      {validationMessage && visited && <Error>{validationMessage}</Error>}
    </div>
  );
}
