import { Filter } from '@progress/kendo-react-data-tools';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Button } from '../../common/baseElements/Button';
import { СommonTableLoader } from '../../common/tableLoader/CommonTableLoader';
import localeNewTask from '../../../utils/i18n/taskLocale/newTask.json';
import { FILTER_DEVICELIST } from '../../../utils/helpers/constant-serv-filter-host';
import { getUrlList } from '../../../utils/helpers/getUrl-list-helper';
import { useNetworkDeviceList } from '../../../hooks/components/useNetworkDeviceList';
import { CommonNetworkDeviceList } from '../../common/CommonNetworkDeviceList';
import { useNetDeviceContext } from '../../../hooks/useNetDeviceContext';
import type localeDevices from '../../../utils/i18n/devices/devices.json';
import { handleAddAllDevices } from '../../../utils/helpers/add-host-list-helper';
import { Input } from '../../common/baseElements/Input';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeDevices;

export function DeviceListFormScope(): React.ReactElement {
  const dataForHooks = {
    name: 'latestInventorization.ip',
    componentName: 'AddDeviceList',
  };

  const urlParams = getUrlList('addDeviceList');

  const { deviceFormTemp, setDeviceFormTemp, delAllDevices } = useNetDeviceContext();

  const {
    filter,
    onFilterChange,
    sort,
    page,
    total,
    pageChange,
    setSort,
    isLoading,
    filterStatus,
    isError,
    errorMessage,
    data,
    handleCustomSearch,
    customSearch,
  } = useNetworkDeviceList(urlParams, dataForHooks);

  return (
    <div className="k-form">
      <div className="form-content select-asset">
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <div className="filter-line">
              <Filter
                value={filter}
                onChange={onFilterChange}
                fields={FILTER_DEVICELIST[currentLocale]}
              />
              {filter.filters.length === 0 && (
                <Input
                  name="customSearch"
                  placeholder={localeNewTask[currentLocale].customSearchIp}
                  value={customSearch}
                  onChange={(e): void => handleCustomSearch(e)}
                />
              )}
            </div>
          </IntlProvider>
        </LocalizationProvider>
        <div className="k-form-select-buttons">
          <Button
            onClick={(): void => handleAddAllDevices(data?.data, deviceFormTemp, setDeviceFormTemp)}
          >
            {localeNewTask[currentLocale].form.btnAddAllAssets}
          </Button>
          {deviceFormTemp && deviceFormTemp.length > 0 && (
            <Button onClick={delAllDevices}>
              {localeNewTask[currentLocale].form.btnDelAllDevices} ({deviceFormTemp?.length})
            </Button>
          )}
        </div>
        <CommonNetworkDeviceList
          sort={sort}
          data={data}
          page={page}
          total={total}
          pageChange={pageChange}
          setSort={setSort}
          dataForHooks={dataForHooks}
          conditionSort={15}
        />
        <СommonTableLoader queryData={isLoading} filteringData={filterStatus} />
        {isError && <div className="info alert alert-danger">Error: {errorMessage}</div>}
      </div>
    </div>
  );
}
