import type { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useCallback, useEffect } from 'react';
import { useApiKeyContext } from '../../../../../hooks/useApiKeyContext';

export function CellSelectMethodApi(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { apiMethodsForm, setApiMethodsForm } = useApiKeyContext();

  useEffect(() => {
    if (!apiMethodsForm) {
      setApiMethodsForm(apiMethodsForm);
    }
  }, [apiMethodsForm, setApiMethodsForm]);

  const addMethod = useCallback(
    ({ path }: { path: string }): void => {
      if (apiMethodsForm && apiMethodsForm.length > 0) {
        const index = apiMethodsForm?.findIndex((m) => m.path === path);
        if (index !== -1) {
          const cutMethodForm = apiMethodsForm.filter((m) => m.path !== path);
          setApiMethodsForm([...cutMethodForm]);
        } else {
          setApiMethodsForm([
            ...apiMethodsForm,
            { path, httpMethods: ['GET', 'POST', 'PATCH', 'DELETE'] },
          ]);
        }
      } else {
        setApiMethodsForm([
          { path: dataItem.path, httpMethods: ['GET', 'POST', 'PATCH', 'DELETE'] },
        ]);
      }
    },
    [apiMethodsForm, setApiMethodsForm, dataItem.path],
  );

  return (
    <td>
      <Checkbox
        checked={!!apiMethodsForm?.find((m) => m.path === dataItem.path)}
        onClick={(): void =>
          addMethod({
            path: dataItem.path,
          })
        }
      />
    </td>
  );
}
