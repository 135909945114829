import type { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import type { GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useEffect, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import type { FilterChangeEvent } from '@progress/kendo-react-data-tools';
import type { ApiError } from '../../types/__generated/on-premise-solution/api/apiError.v1';
import { handleError } from '../../utils/errors';
import { handleFilteringTest, IFilterVal } from '../../utils/filtering-in-table-test';
import { FILTER_INITIAL } from '../../utils/helpers/constants';
import type { IPageState } from '../../utils/helpers/types';
import { useNotificationContext } from '../useNotificationContext';
import { usePageChange } from './usePageChange';
import { useCustomSearch } from './useCustomSearch';
import type { IUrlParamsList } from '../../utils/helpers/getUrl-list-helper';
import { useFilter } from './useFilter';
import { fillFiltersHostList } from '../../utils/helpers/host-list-fill-filter-helper';
import type { AccountsDashboardResponse } from '../../types/__generated/on-premise-solution/api/accountsDashboardResponse.v1';
import { getAccountsForList } from '../../services/account-service';
import { getUserData } from '../../services/local-storage-service';

interface IUseAccountList {
  handleRefresh: () => void;
  filter: CompositeFilterDescriptor;
  onFilterChange: (event: FilterChangeEvent) => void;
  customSearch: string;
  handleCustomSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  sort: SortDescriptor[];
  page: IPageState;
  total: number | undefined;
  pageChange: (event: GridPageChangeEvent) => void;
  setSort: React.Dispatch<React.SetStateAction<SortDescriptor[]>>;
  dataForHooks: {
    name: string;
    componentName: string;
  };
  isLoading: boolean;
  isFetching: boolean;
  filterStatus: boolean;
  isError: boolean;
  errorMessage: string | undefined;
  data: AccountsDashboardResponse | undefined;
}

export function useAccountList(
  urlParams: IUrlParamsList,
  dataForHooks: {
    name: string;
    componentName: string;
  },
): IUseAccountList {
  const queryClient = useQueryClient();

  const currentUser = getUserData();

  const [customSearch, setCustomSearch] = useState('');

  const [sort, setSort] = useState<SortDescriptor[]>(urlParams.sort);

  const [page, setPage] = useState<IPageState>(urlParams.page);

  const [filter, setFilter] = useState<CompositeFilterDescriptor>(FILTER_INITIAL);

  const [filterStatus, setFilterStatus] = useState(false);

  const [filterVal, setFilterVal] = useState<IFilterVal[]>([]);

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  const filterRef = useRef<CompositeFilterDescriptor>(filter);

  const { createNotification } = useNotificationContext();

  // const { deviceForm, deviceFormTemp, setDeviceForm, setDeviceFormTemp } = useNetDeviceContext();

  const debouncedCustomSearch = useCustomSearch(
    setFilterStatus,
    setCustomSearch,
    filter,
    setFilterVal,
    setPage,
    page,
    dataForHooks,
  );

  const filterSearch = useFilter(
    filterValue,
    setFilter,
    customSearch,
    setCustomSearch,
    setFilterVal,
    filterRef,
    filter,
    setPage,
    page,
    dataForHooks,
  );
  const getPage = usePageChange(setPage, urlParams, dataForHooks);

  const query = useQuery<AccountsDashboardResponse, ApiError>(
    ['accountList', page, filterVal, sort],
    () => getAccountsForList(page, filterVal, sort),
    {
      keepPreviousData: true,
      enabled: currentUser?.role === 'super_admin',
    },
  );

  useEffect(() => {
    fillFiltersHostList(urlParams, filterValue, setFilter, setFilterStatus);
  }, []);

  useEffect(() => {
    queryClient.invalidateQueries('NetworkDevices');
  }, [queryClient]);

  useEffect(() => {
    let delaySearch: NodeJS.Timeout;

    if (filter?.filters?.length > 0) {
      filterRef.current = filter;
      delaySearch = setTimeout(() => {
        handleFilteringTest(filterRef.current, setFilterVal);
        setFilterStatus(false);
      }, 300);
    }

    return () => clearTimeout(delaySearch);
  }, [filter]);

  const pageChange = (event: GridPageChangeEvent): void => {
    getPage(event);
  };

  useEffect(() => {
    if (query.error) {
      handleError(query.error, createNotification);
    }
  }, [query.isError, createNotification, query.error]);

  const onFilterChange = (event: FilterChangeEvent): void => {
    filterSearch(event);
  };

  function handleCustomSearch(e: React.ChangeEvent<HTMLInputElement>): void {
    debouncedCustomSearch(e);
  }

  const handleRefresh = (): void => {
    setFilterStatus(true);
    query.refetch().then(() => setFilterStatus(false));
  };

  return {
    handleRefresh,
    filter,
    onFilterChange,
    customSearch,
    handleCustomSearch,
    sort,
    page,
    total: query.data?.total,
    pageChange,
    setSort,
    dataForHooks,
    isLoading: query.isLoading,
    isFetching: query.isFetching,
    filterStatus,
    isError: query.isError,
    errorMessage: query?.error?.message,
    data: query.data,
  };
}
