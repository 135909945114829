/* eslint-disable react/jsx-no-useless-fragment */
import { nanoid } from 'nanoid';
import { Button } from '../../../common/baseElements/Button';
import localeImageRegister from '../../../../utils/i18n/imageRegister/ImageRegister.json';
import { useRegisterContext } from '../../../../hooks/useRegisterContext';
import styles from './Form.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeImageRegister;

export function RepositoriesTable(): React.ReactElement {
  const { deleteRepositories, repositories } = useRegisterContext();

  return (
    <>
      {repositories && repositories.length > 0 && (
        <table className={`${styles.form_table} horizontal`}>
          <thead>
            <tr>
              <th>{localeImageRegister[currentLocale].form.name}</th>
              <th>{localeImageRegister[currentLocale].modal.tagsMask}</th>
              <th>{localeImageRegister[currentLocale].modal.isScannable}</th>
              <th className={styles.delete_button_th}> </th>
            </tr>
          </thead>
          <tbody>
            {repositories.map((r) => (
              <tr key={nanoid(8)}>
                <td>{r.name}</td>
                <td>{r.tagsMask}</td>
                <td>
                  {r.isScannable
                    ? localeImageRegister[currentLocale].modal.isScannableYes
                    : localeImageRegister[currentLocale].modal.isScannableNo}
                </td>
                <td>
                  <Button
                    fill="flat"
                    textColor="green"
                    className={styles.delete_button}
                    onClick={(): void => deleteRepositories(r.name)}
                  >
                    <span className="k-icon k-i-x" />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
}
