import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesItemTooltip,
  ChartSeriesLabels,
  ChartTooltip,
  SeriesVisualArgs,
} from '@progress/kendo-react-charts';
import type { CvssScores } from '../../../../types/__generated/on-premise-solution/api/cvssScores.v1';
import { checkDashboardCvss } from '../../../../utils/helpers/dashboard-helper';

// const labelContent = (props: any): string => {
//   return `${props.dataItem.category === null ? 'N/a' : props.dataItem.category}: ${
//     props.dataItem.value
//   }`;
// };

const labelContent = (e: any) => `${e.category}`;

// const defaultTooltipRender = ({ point }) => (
//   <span>
//     <b>Series 1 value: {point.value}</b>
//   </span>
// );

export function CvssScore({ data }: { data: CvssScores | undefined }): React.ReactElement {
  const series = checkDashboardCvss(data).sort((a, b) => {
    return a.value - b.value;
  });

  console.log(series);

  return (
    <Chart style={{ height: '100%' }}>
      <ChartLegend position="bottom" />
      <ChartTooltip
        render={(e: any) => {
          if (e.point?.category) return <span>{e.point.category}</span>;

          return '';
        }}
      />
      {/* <ChartTooltip format="{0}" /> */}
      <ChartSeries>
        <ChartSeriesItem
          type="donut"
          data={series}
          field="value"
          categoryField="category"
          tooltip={{ visible: true }}
          // visual={(e: SeriesVisualArgs) => <div>{e.category}</div>}
        >
          {/* <ChartSeriesItemTooltip render={nestedTooltipRender} /> */}
          {/* <ChartSeriesLabels position="outsideEnd" background="none" content={labelContent} /> */}
        </ChartSeriesItem>
      </ChartSeries>
    </Chart>
  );
}
