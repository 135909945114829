import {
  DropDownList,
  ListItemProps,
  DropDownListChangeEvent,
} from '@progress/kendo-react-dropdowns';
import type { ReactElement } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '../../../../common/baseElements/Button';
import styles from '../../forms/AddAssetForm.module.scss';
import hostDescriptionLocale from '../../../../../utils/i18n/assetLocales/hostDescription.json';
import type { AuditDifferenceResponse } from '../../../../../types/__generated/on-premise-solution/api/auditDifferenceResponse.v1';
import type { AuditHistory } from '../../../../../types/__generated/on-premise-solution/api/auditHistory.v1';
import { DynamicAuditChart } from './DynamicAuditsChart';
import { NearestInfo } from '../NearestInfo';
import { getAuditRangePoints } from '../../../../../lib/axios/helpers';
import type { ApiError } from '../../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { LoadingPanel } from '../../../../common/tableLoader/LoadingPanel';

const itemRender = (
  li: React.ReactElement<HTMLLIElement>,
  itemProps: ListItemProps,
): ReactElement<HTMLLIElement, string | React.JSXElementConstructor<any>> => {
  const { index, dataItem } = itemProps;
  const itemChildren = (
    <span key={index}>
      <div>{dataItem.text}</div>
      <div style={{ fontSize: '11px' }}>
        {dataItem.affectedSoftwareCount} уязв. ПО / {dataItem.vulnsCount} уязв. /{' '}
        {dataItem.softwareCount} ПО
      </div>
    </span>
  );

  return React.cloneElement(li, li.props, itemChildren);
};

const listNoDataRender = (element: React.ReactElement<HTMLDivElement>): ReactElement => {
  const noData = <LoadingPanel />;

  return React.cloneElement(element, { ...element.props }, noData);
};

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof hostDescriptionLocale;

export function DiffByAuditTab({
  data,
  isLoading,
  onClick,
  auditsData,
  isAuditGraphic,
  setAuditGraphic,
  error,
  localError,
}: {
  data: AuditDifferenceResponse | undefined;
  isLoading: boolean;
  onClick: (fd: string | undefined, sd: string | undefined) => void;
  auditsData: AuditHistory | undefined;
  isAuditGraphic?: boolean;
  setAuditGraphic?: React.Dispatch<React.SetStateAction<boolean>>;
  error: ApiError | null;
  localError?: string | null;
}): ReactElement {
  const dropdownData = useMemo(
    () =>
      auditsData?.history?.map((c, ind) => ({
        text: new Date(c.createdAt).toLocaleString(),
        id: ind,
        date: c.createdAt,
        affectedSoftwareCount: c.affectedSoftwareCount,
        vulnsCount: c.vulnsCount,
        softwareCount: c.softwareCount,
        maxCvss: c.maxCvssScore,
      })),
    [auditsData?.history],
  );

  // useEffect(() => {
  //   setDropdownData(
  //     auditsData?.history?.map((c, ind) => ({
  //       text: new Date(c.createdAt).toLocaleString(),
  //       id: ind,
  //       date: c.createdAt,
  //       affectedSoftwareCount: c.affectedSoftwareCount,
  //       vulnsCount: c.vulnsCount,
  //       softwareCount: c.softwareCount,
  //       maxCvss: c.maxCvssScore,
  //     })),
  //   );
  // }, [auditsData?.history]);

  // const dropdownData = ;
  function getDatePoint(
    date:
      | Exclude<AuditDifferenceResponse['sourceAudits'], null>['firstAudit']
      | Exclude<AuditDifferenceResponse['sourceAudits'], null>['secondAudit']
      | undefined,
  ):
    | {
        text: string;
        id: number;
        date: string;
        affectedSoftwareCount: number;
        vulnsCount: number;
        softwareCount: number;
        maxCvss: number;
      }
    | undefined {
    if (!date || !(dropdownData && dropdownData.length)) return undefined;
    const index = dropdownData.findIndex((p) => p.date === date.createdAt);

    return index >= 0 ? dropdownData[index] : undefined;
  }

  const [firstPoint, setFirstPoint] = useState<
    | {
        text: string;
        id: number;
        date: string;
        affectedSoftwareCount: number;
        vulnsCount: number;
        softwareCount: number;
        maxCvss: number;
      }
    | undefined
  >(getDatePoint(data?.sourceAudits.firstAudit));
  const [secondPoint, setSecondPoint] = useState<
    | {
        text: string;
        id: number;
        date: string;
        affectedSoftwareCount: number;
        vulnsCount: number;
        softwareCount: number;
        maxCvss: number;
      }
    | undefined
  >(getDatePoint(data?.sourceAudits.secondAudit));

  useEffect(() => {
    setFirstPoint(getDatePoint(data?.sourceAudits?.firstAudit));
    setSecondPoint(getDatePoint(data?.sourceAudits?.secondAudit));
  }, [data?.sourceAudits, dropdownData]);

  const handleChange = (e: DropDownListChangeEvent, str: 'first' | 'second'): void => {
    const date = new Date(e.target.value.date);
    if (str === 'first') {
      if (secondPoint && date > new Date(secondPoint.date)) {
        setFirstPoint(secondPoint);
        setSecondPoint(e.target.value);
        // setFinishDate(new Date(e.target.value.date));
      } else {
        setFirstPoint(e.target.value);
        // setStartDate(new Date(e.target.value.date));
      }
    }
    if (str === 'second') {
      if (firstPoint && date < new Date(firstPoint.date)) {
        setSecondPoint(firstPoint);
        setFirstPoint(e.target.value);
        // setStartDate(new Date(e.target.value.date));
      } else {
        setSecondPoint(e.target.value);
        // setFinishDate(new Date(e.target.value.date));
      }
    }
  };

  useEffect(() => {
    return () => {
      setFirstPoint(undefined);
      setSecondPoint(undefined);
    };
  }, []);

  return (
    <>
      <div>
        <div>
          <div
            style={{
              display: 'flex',
              gap: '20px',
              alignItems: 'flex-end',
            }}
          >
            <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-end' }}>
              <div className={styles.rangeReg}>
                <div>{hostDescriptionLocale[currentLocale].changes.startAuditPoint}</div>
                <DropDownList
                  data={dropdownData}
                  textField="text"
                  dataItemKey="id"
                  itemRender={itemRender}
                  onChange={(e: DropDownListChangeEvent): void => handleChange(e, 'first')}
                  defaultValue={getDatePoint(data?.sourceAudits.firstAudit)}
                  value={firstPoint}
                  listNoDataRender={listNoDataRender}
                  required
                />
              </div>
              <div className={styles.rangeReg}>
                <div>{hostDescriptionLocale[currentLocale].changes.finishAuditPoint}</div>
                <DropDownList
                  data={dropdownData}
                  textField="text"
                  dataItemKey="id"
                  onChange={(e: DropDownListChangeEvent): void => handleChange(e, 'second')}
                  defaultValue={getDatePoint(data?.sourceAudits.secondAudit)}
                  value={secondPoint}
                  itemRender={itemRender}
                  listNoDataRender={listNoDataRender}
                  required
                />
              </div>
              <div className="k-form-buttons">
                <Button
                  className="action"
                  onClick={(): void => onClick(firstPoint?.date, secondPoint?.date)}
                >
                  {hostDescriptionLocale[currentLocale].changes.btnShowChanges}
                </Button>
              </div>
            </div>
            {setAuditGraphic && (
              <Button
                style={{
                  padding: '8px',
                }}
                title={hostDescriptionLocale[currentLocale].changes.auditVulnsChart.chartName}
                icon="graphic"
                onClick={(): void => setAuditGraphic((prev) => !prev)}
              />
            )}
          </div>
          <div
            style={{
              display: 'flex',
              gap: '20px',
              // marginBottom: '8px',
              marginTop: '16px',
              fontSize: '13px',
            }}
          >
            <NearestInfo
              type="audit"
              className={`${styles.rangeReg} ${styles.audit_info}`}
              isLoading={isLoading}
              data={data?.sourceAudits.firstAudit}
            />
            <NearestInfo
              type="audit"
              className={`${styles.rangeReg} ${styles.audit_info}`}
              isLoading={isLoading}
              data={data?.sourceAudits.secondAudit}
            />
          </div>
        </div>
        <div>
          {isAuditGraphic && data && 'sourceAudits' in data && !error && (
            <DynamicAuditChart
              firstAuditId={data.sourceAudits.firstAudit?.auditId}
              secondAuditId={data.sourceAudits.secondAudit?.auditId}
              auditsHistory={auditsData?.history}
              auditsDynamic={auditsData?.dynamic}
            />
          )}
        </div>
      </div>
      {localError && (
        <div style={{ color: 'rgb(243, 23, 0)', marginTop: '12px' }}>{localError}</div>
      )}
      {error && error.code === '404' && (
        <div style={{ color: 'rgb(243, 23, 0)', marginTop: '12px' }}>
          {hostDescriptionLocale[currentLocale].changes.errors.noDataTime}
        </div>
      )}
    </>
  );
}
