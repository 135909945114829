/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import type { GridCellProps } from '@progress/kendo-react-grid';

export function CweCell(props: GridCellProps): React.ReactElement {
  const { dataItem, field } = props;

  const fieldArray = field ? field.split('.') : [];
  let value = dataItem;

  if (!fieldArray) value = null;
  else if (fieldArray.length === 1) value = dataItem[fieldArray[0]];
  else if (fieldArray.length > 1) {
    fieldArray.forEach((f) => {
      value = value ? value[f] : value;
    });
  }

  if (Array.isArray(value)) {
    const text = value.map((cwe) => `${cwe.id}: ${cwe.name}`).join(', ');

    return <td title={text}>{text}</td>;
  }

  if (value && value.id && value.name)
    return (
      <td title={`${value.id}: ${value.name}`}>
        {value.id}: {value.name}
      </td>
    );

  return <td>-</td>;
}
