import type { GridCellProps } from '@progress/kendo-react-grid';
import type { AssetRouter } from '../../../../types/__generated/on-premise-solution/api/asset.v1';
import localeDevice from '../../../../utils/i18n/devices/devices.json';
import type { IDeviceType } from '../../../../utils/helpers/types';
import { IMG_SRC_VALUES } from '../../../../utils/helpers/constants';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeDevice;

export function CellType({
  dataItem: { type },
}: Omit<GridCellProps, 'type'> & {
  dataItem: AssetRouter;
}): React.ReactElement {
  return (
    <td
      title={localeDevice[currentLocale].grid.deviceType[type as keyof IDeviceType]}
      className="type-of-device"
    >
      <div className="type-of-device__wrapper">
        <img
          src={`${process.env.PUBLIC_URL}/images/os_logo/${IMG_SRC_VALUES[type.toLowerCase()]}`}
          alt=""
          width="24px"
        />
      </div>
    </td>
  );
}
