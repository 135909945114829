/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/jsx-no-useless-fragment */
import type { GridCellProps } from '@progress/kendo-react-grid';
import type { IServicesWin } from '../../../../../../utils/helpers/types';

export function CellDisplayName(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { display_name }: IServicesWin = dataItem;

  return (
    <>
      {display_name && display_name.length > 25 ? (
        <td title={display_name}>{display_name}</td>
      ) : (
        <td>{display_name}</td>
      )}
    </>
  );
}
