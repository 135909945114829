import { nanoid } from 'nanoid';
import { NavLink } from 'react-router-dom';
import type { IWebSocketResultCommand } from '../../../../hooks/useWebSocketContext';
import './AssetViewSummaryCommand.scss';

export function HostViewSummaryCommand({ result }: IWebSocketResultCommand): React.ReactElement {
  return (
    <div>
      {result && (
        <div style={{ textAlign: 'center' }}>
          {result.data.data.success ? (
            <>
              {/* <h4>Summary info:</h4> */}
              {result.data.data.command === 'agent_info' && (
                <div className="summary-info">
                  <p>{result.data.data.result.message}</p>
                  {/* <p>Uptime in hours: {new Date(result.data.data.result.uptime).getHours()}</p> */}
                  <p>
                    Uptime in minutes: {Math.floor(Number(result.data.data.result.uptime) / 60000)}
                    {/* {Math.round((result.data.data.result.uptime / (1000 * 60)) % 60)} */}
                  </p>
                  <p>Version: {result.data.data.result.version}</p>
                  <p>Status: {result.data.data.result.status}</p>
                </div>
              )}
              {result.data.data.command === 'audit' && (
                <div className="summary-info">
                  <p>Audit: {result.data.data.result.auditId}</p>
                  {result.data.data.result.isVulnerable ? (
                    <>
                      <p>
                        Vulnerable Packages Count: {result.data.data.result.vulnerablePackagesCount}
                      </p>
                      <p>Vulnerabilties Count: {result.data.data.result.vulnerabilitiesCount}</p>
                    </>
                  ) : (
                    <p>Vulnerabilities not found</p>
                  )}
                  <NavLink to={`/lk/audits/${result.data.data.result.auditId}`}>Show More</NavLink>
                </div>
              )}
              {result.data.data.command === 'package_info' && (
                <div className="summary-info">
                  {result.data.data.result.packages?.map((p) => (
                    <>
                      <p key={p.version}>Name: {p.name}</p>
                      <p key={p.version}>Version: {p.version}</p>
                      <p key={p.version}>Arch: {p.arch}</p>
                      {/* <p key={p.version} style={{ textAlign: 'left' }}>
                        {p.data}
                      </p> */}
                    </>
                  ))}
                  {result.data.data.result.notFoundPackages && (
                    <div className="not-found">
                      {result.data.data.result.notFoundPackages.map((p) => (
                        <p key={p}>Package: {p} - not found</p>
                      ))}
                    </div>
                  )}
                </div>
              )}
              {result.data.data.command === 'package_update' &&
                result.data.data.result.type === 'linux' && (
                  <div className="summary-info">
                    {result.data.data.result.result?.map((p) => (
                      <div key={nanoid(4)}>
                        <p>Packagemnager: {p.packageManager}</p>
                        <p>Updated packages: {p.updatedPackages}</p>
                      </div>
                    ))}
                    {result.data.data.result.notFoundPackages && (
                      <div className="not-found">
                        {result.data.data.result.notFoundPackages.map((p) => (
                          <p key={p}>Package: {p} not found</p>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              {result.data.data.command === 'get_config' && (
                <div className="summary-info">
                  {result.data.data.result['logging.log_level'] && (
                    <p>Log Level: {result.data.data.result['logging.log_level']}</p>
                  )}
                  {result.data.data.result['logging.log_dir_path'] && (
                    <p>Dir Path: {result.data.data.result['logging.log_dir_path']}</p>
                  )}
                  {result.data.data.result['scheduler.schedule'] && (
                    <p>Scheduler: {result.data.data.result['scheduler.schedule']}</p>
                  )}
                </div>
              )}
              {result.data.data.command === 'put_config' && (
                <div className="summary-info">
                  {result.data.data.result['logging.log_level'] && (
                    <p>Log Level: {result.data.data.result['logging.log_level']}</p>
                  )}
                  {result.data.data.result['logging.log_dir_path'] && (
                    <p>Dir Path: {result.data.data.result['logging.log_dir_path']}</p>
                  )}
                  {result.data.data.result['scheduler.schedule'] && (
                    <p>Scheduler: {result.data.data.result['scheduler.schedule']}</p>
                  )}
                </div>
              )}
              {result.data.data.command === 'inventorization' && (
                <div className="summary-info">
                  <p>Created At: {result.data.data.result.createdAt}</p>
                  <p>Inventorization Id: {result.data.data.result.inventorizationId}</p>
                </div>
              )}
              {result.data.data.command === 'agent_update' && (
                <div className="summary-info">
                  <p>{result.data.data.result.message}</p>
                </div>
              )}
            </>
          ) : (
            <div className="summary-info error">
              <p>Error!</p>
              <p>{result.data.data.error}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
