import { Dialog } from '@progress/kendo-react-dialogs';
import { useCallback, useState } from 'react';
import { Hint } from '@progress/kendo-react-labels';
import { Field } from '@progress/kendo-react-form';
import localeImageRegister from '../../../../utils/i18n/imageRegister/ImageRegister.json';
import { useRegisterContext } from '../../../../hooks/useRegisterContext';
import { CheckBox } from '../../../common/form/CheckBox';
import { TextField } from '../../../common/form/TextField';
import { Button } from '../../../common/baseElements/Button';

export interface IRepositories {
  name: string;
  tagsMask?: string;
  isScannable: boolean;
  credentialId?: string;
}

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeImageRegister;
export function ModalAddRepository(): React.ReactElement {
  const { setAddRepository, credentialReg, setRepositories } = useRegisterContext();

  const [name, setName] = useState('');

  const [tagsMask, setTagsMask] = useState('*');

  const [isScannable, setScannable] = useState(true);

  const submit = useCallback((): void => {
    const repositoriesValues = {
      name,
      tagsMask,
      isScannable,
      credentialId: credentialReg?.credentialId ? credentialReg?.credentialId : undefined,
    };
    setRepositories((prevState) => [...prevState, repositoriesValues]);
    setAddRepository(false);
  }, [credentialReg?.credentialId, isScannable, name, setAddRepository, setRepositories, tagsMask]);

  return (
    <Dialog
      className="modal smallest"
      title={localeImageRegister[currentLocale].modal.title}
      onClose={(): void => setAddRepository(false)}
    >
      <div className="k-form">
        <div className="form-content modal-add-repository-content">
          <div className="add-repository-input">
            <Field
              name="group_report"
              component={TextField}
              label={localeImageRegister[currentLocale].form.name}
              value={name}
              onChange={(e): void => setName(e.target.value)}
            />
          </div>
          <div className="add-repository-input">
            <span>{localeImageRegister[currentLocale].modal.tagsMask}</span>
            <input
              className="k-input k-input-md k-rounded-md k-input-solid"
              value={tagsMask}
              onChange={(e): void => setTagsMask(e.target.value)}
            />
            <Hint>{localeImageRegister[currentLocale].modal.hintComment}</Hint>
          </div>
          <div className="add-repository-input-checkbox">
            <Field
              name="isScannable"
              component={CheckBox}
              checked={isScannable}
              value={isScannable}
              label={localeImageRegister[currentLocale].modal.isScannable}
              onChange={(): void => setScannable(!isScannable)}
            />
          </div>
        </div>
        <div className="k-form-buttons">
          <Button
            type="button"
            fill="action"
            disabled={name.length === 0 || tagsMask.length === 0}
            onClick={(): void => submit()}
          >
            {localeImageRegister[currentLocale].form.btnSave}
          </Button>
          <Button type="button" onClick={(): void => setAddRepository(false)}>
            {localeImageRegister[currentLocale].form.btnCancel}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
