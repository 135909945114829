import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { useEffect, useState } from 'react';
import { loadMessages } from '@progress/kendo-react-intl';
import { useQuery } from 'react-query';
import { getAllUsers } from '../../../services/user-service';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { DATA_CACHE_CACHETIME, DATA_CACHE_STALETIME } from '../../../utils/helpers/constants';
import localeCommonTable from '../../../utils/i18n/commonLocales/table.json';
import localeUser from '../../../utils/i18n/userLocales/userList.json';
import type { UsersDashboardResponse } from '../../../types/__generated/on-premise-solution/api/usersDashboardResponse.v1';
import { CellCurrentLocale } from '../../common/baseElements/CellCurrentLocale';
import { createData } from '../../../utils/helpers/user-list-helper';
import { CellUserRole } from './Cells/CellUserRole';
import { RoleHeaderCell } from './Cells/RoleHeaderCell';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeUser;

loadMessages(localeCommonTable[currentLocale], currentLocale);

export function RoleList(): React.ReactElement {
  const usersQuery = useQuery<UsersDashboardResponse, ApiError>('userList', () => getAllUsers(), {
    staleTime: DATA_CACHE_STALETIME,
    cacheTime: DATA_CACHE_CACHETIME,
    keepPreviousData: true,
  });

  const { data } = usersQuery;

  const [finalData, setFinalData] = useState(createData(data));

  useEffect(() => {
    setFinalData(createData(data));
  }, [data]);

  return (
    <Grid className="table roles" data={finalData} scrollable="none">
      <Column
        field="role"
        title={localeUser[currentLocale].titleForColumnGrid.role}
        filter="text"
        width="100px"
        cell={CellUserRole}
        headerCell={RoleHeaderCell}
      />
      <Column
        field="usersCount"
        title={localeUser[currentLocale].titleForColumnGrid.userCount}
        filter="text"
        width="132px"
        className="number"
      />
      <Column
        field="description"
        title={localeUser[currentLocale].titleForColumnGrid.description}
        filter="text"
        cell={CellCurrentLocale}
        className="title"
      />
    </Grid>
  );
}
