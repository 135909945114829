import { TextArea, TextAreaChangeEvent } from '@progress/kendo-react-inputs';
import { useState } from 'react';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import styles from './FormInput.module.scss';
import { Button } from '../baseElements/Button';

interface ITextAreaRenderProps {
  name: string;
  label: string;
  inputValue: string;
  type: string;
  customValidator: (
    e: React.FormEvent<HTMLInputElement>,
    setError: (value: React.SetStateAction<string>) => void,
  ) => void;
  className?: string;
  placeholder?: string;
  onChange: (e: TextAreaChangeEvent) => void;
  id: string;
  classForLabel?: string;
  helper?: string;
  maxLength?: number;
  rows?: number;
}
export function FormTextArea(textAreaRenderProps: ITextAreaRenderProps): React.ReactElement {
  const {
    name,
    label,
    inputValue,
    className,
    onChange,
    placeholder,
    id,
    classForLabel,
    helper,
    maxLength,
    rows,
  } = textAreaRenderProps;

  const [isHelper, setHelper] = useState<boolean>(false);

  return (
    <div className="form-field-wrapper">
      {helper ? (
        <label className="k-label" htmlFor={id}>
          <div className={styles.helperWrapper}>
            <span className={classForLabel}>{label}</span>
            <div className={styles.helper}>
              <Button
                type="button"
                fill="flat"
                className={styles.button_helper}
                onClick={(): void => setHelper(!isHelper)}
              >
                <span className={`k-icon k-i-question ${styles.titleHelper}`} />
              </Button>
              {isHelper && (
                <NotificationGroup
                  style={{
                    marginTop: '-20px',
                    marginLeft: '32px',
                  }}
                >
                  <Notification
                    closable
                    type={{ icon: false }}
                    onClose={(): void => setHelper(false)}
                  >
                    <span>{helper}</span>
                  </Notification>
                </NotificationGroup>
              )}
            </div>
          </div>
        </label>
      ) : (
        <label className={classForLabel ? `${classForLabel} k-label` : 'k-label'} htmlFor={id}>
          {label}
        </label>
      )}
      {/* <label className={classForLabel ? `${classForLabel} k-label` : 'k-label'} htmlFor={id}>
        {label}
      </label> */}
      <TextArea
        name={name}
        value={inputValue}
        id={id}
        onChange={onChange}
        // className={className}
        placeholder={placeholder}
        rows={rows || 5}
        maxLength={maxLength || 255}
        className={className || ''}
      />
    </div>
  );
}
