import type { GridCellProps } from '@progress/kendo-react-grid';
import type { ReactElement } from 'react';
import styles from '../../forms/AddAssetForm.module.scss';
import { DeviceCapabilityCell } from '../../../Task/taskIDetails/iDetailsComponents/сells/DeviceCapabilityCell';

export function DiffDeviceCapabilityCell(props: GridCellProps): ReactElement {
  const { dataItem, className, ...other } = props;

  return (
    <DeviceCapabilityCell
      className={`
            ${dataItem.diffType === 'updated' ? styles.updated : ''} 
            ${dataItem.diffType === 'closed' ? styles.closed : ''} 
            ${className || ''}`}
      dataItem={dataItem}
      {...other}
    />
  );
}
