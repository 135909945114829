import localeSettings from '../../../../../utils/i18n/settingsLocales/settings.json';
import styles from '../ApiKeyList.module.scss';
import type { ApiKeyDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/apiKeyDashboardResponse.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

export function ApiKeyDescriptionMetaBlock({
  data,
}: {
  data: ApiKeyDashboardResponse | undefined;
}): React.ReactElement {
  return (
    <div className={`common-summary-page ${styles.metablock}`}>
      <table className="column">
        <tbody>
          <tr>
            <td>
              <span>{localeSettings[currentLocale].apiKey.description.token}</span>
            </td>
            <td>
              {data?.name ? (
                <span style={{ fontWeight: '700' }}>{data?.name}</span>
              ) : (
                <span>-</span>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <span>{localeSettings[currentLocale].apiKey.description.id}</span>
            </td>
            <td className="pd-0">
              {data?.apiKeyId ? <span>{data?.apiKeyId}</span> : <span>-</span>}
            </td>
          </tr>
        </tbody>
      </table>
      <table className="column">
        <tbody>
          <tr>
            <td>
              <span>{localeSettings[currentLocale].apiKey.description.createdAt}</span>
            </td>
            <td>
              {data?.createdAt ? (
                <span>{new Date(data?.createdAt).toLocaleString()}</span>
              ) : (
                <span>-</span>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <span>{localeSettings[currentLocale].apiKey.description.createdBy}</span>
            </td>
            <td className="pd-0">
              {data?.createdBy.source === 'user' ? (
                <span>{data?.createdBy.name}</span>
              ) : (
                <span>-</span>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
