import type { FormDataValue } from './helpers/types';

interface PayloadValues {
  'logging.log_level'?: string | null;
  'logging.log_dir_path'?: string | null;
  'scheduler.schedule'?: string | null;
}

type DataSend = {
  assetId?: string | null;
  command: string | null;
  payload?: string[] | PayloadValues;
} | null;

export function createPutConfig(
  putConfig: string | null,
  loggingDirPath: string | null,
  schedule: string | null,
): PayloadValues | undefined {
  let payloadValues: PayloadValues | undefined;
  if (putConfig && loggingDirPath && schedule) {
    payloadValues = {
      'logging.log_level': putConfig,
      'logging.log_dir_path': loggingDirPath,
      'scheduler.schedule': schedule,
    };
  } else if (!putConfig && loggingDirPath && schedule) {
    payloadValues = {
      'logging.log_dir_path': loggingDirPath,
      'scheduler.schedule': schedule,
    };
  } else if (putConfig && !loggingDirPath && schedule) {
    payloadValues = {
      'logging.log_level': putConfig,
      'scheduler.schedule': schedule,
    };
  } else if (putConfig && loggingDirPath && !schedule) {
    payloadValues = {
      'logging.log_level': putConfig,
      'logging.log_dir_path': loggingDirPath,
    };
  } else if (putConfig && !loggingDirPath && !schedule) {
    payloadValues = {
      'logging.log_level': putConfig,
    };
  } else if (!putConfig && !loggingDirPath && schedule) {
    payloadValues = {
      'scheduler.schedule': schedule,
    };
  } else if (!putConfig && loggingDirPath && !schedule) {
    payloadValues = {
      'logging.log_dir_path': loggingDirPath,
    };
  }

  return payloadValues;
}

export function prepareDataAssetCommand(dataItem: FormDataValue, assetId: string): DataSend | null {
  let data: DataSend = null;

  if (!dataItem.payload && dataItem.command === 'get_config') {
    data = {
      command: dataItem.command,
      payload: dataItem.getConfig?.split(' '),
    };
  } else if (!dataItem.payload && dataItem.command === 'agent_update') {
    data = {
      command: 'agent_update',
    };
  } else if (!dataItem.payload && dataItem.command?.includes('inventorization')) {
    data = {
      command: dataItem.command,
      assetId,
    };
  } else if (dataItem.command === 'put_config') {
    const obj = createPutConfig(dataItem.putConfig, dataItem.loggingDirPath, dataItem.schedule);
    data = {
      command: dataItem.command,
      payload: obj,
    };
  } else if (!dataItem.payload) {
    data = {
      command: dataItem.command,
    };
  } else {
    data = {
      command: dataItem.command,
      payload: dataItem.payload?.split(' '),
    };
  }

  return data;
}
