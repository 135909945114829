/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-nested-ternary */
import { Button } from '@progress/kendo-react-buttons';
import type { GridCellProps } from '@progress/kendo-react-grid';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useRegDescriptionRepList } from '../../../../../hooks/components/useRegDescriptionRepList';
import type { AssetImageDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/assetsImagesDashboardResponse.v1';

export function ImageName(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;
  const { imageNames, assetId }: AssetImageDashboardResponse = dataItem;
  // const [isOpen, setOpen] = useState<boolean>(false);

  const { registryName } = useRegDescriptionRepList();

  return (
    <td>
      <NavLink target="_blank" to={`/lk/assets/images/${assetId}`}>
        {imageNames.filter((name) => name.includes(registryName))[0] || imageNames[0] || '-'}
      </NavLink>
    </td>
  );

  // return (
  //   <td title={newImageNames ? newImageNames[0] : imageNames[0] || ''}>
  //     {newImageNames ? (
  //       <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
  //         {newImageNames.length > 1 && (
  //           <Button
  //             fillMode="flat"
  //             icon={!isOpen ? 'plus' : 'minus'}
  //             onClick={(): void => setOpen((prev) => !prev)}
  //             style={{ marginRight: '8px' }}
  //           />
  //         )}
  //         <NavLink
  //           target="_blank"
  //           to={`/lk/assets/images/${assetId}`}
  //         >
  //           {newImageNames[0]}
  //         </NavLink>
  //         {isOpen && (
  //           <div style={{ marginLeft: '36px', lineHeight: '2' }}>
  //             {newImageNames.map((name) => <div>{name}</div>).slice(1) || '-'}
  //           </div>
  //         )}
  //       </div>
  //     ) : (
  //       imageNames[0] || '-'
  //     )}
  //   </td>
  // );
}
