import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Dialog } from '@progress/kendo-react-dialogs';
import { useEffect } from 'react';
import { Button } from '../../../common/baseElements/Button';
import localeImageRegister from '../../../../utils/i18n/imageRegister/ImageRegister.json';
import { useRegisterContext } from '../../../../hooks/useRegisterContext';
import { ModalAddRepository } from '../modal/ModalAddRepository';
import { useHostActions } from '../../../../hooks/components/useHostActions';
import { getAccountId } from '../../../../services/local-storage-service';
import styles from './Form.module.scss';
import { FormInput } from '../../../common/form/FormInput';
import { FormTextArea } from '../../../common/form/FormTextArea';
import { RepositoriesTable } from './RepositoriesTable';
import { checkLength } from '../../../../utils/helpers/validation';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeImageRegister;

export function AddEditRegisterForm(): React.ReactElement {
  const accountId = getAccountId();

  const { selectCredential } = useHostActions();

  const {
    registerData,
    initialRegisterFields,
    name,
    setName,
    address,
    setAddress,
    description,
    setDescription,
    isAddRepository,
    setAddRepository,
    credentialReg,
    setCredentialReg,
    onSubmitRegisterForm,
    cancelAddEdit,
    errorMutation,
    setErrorMutation,
  } = useRegisterContext();

  useEffect(() => {
    return () => setErrorMutation('');
  }, []);

  return (
    <Dialog
      title={
        registerData
          ? `${localeImageRegister[currentLocale].form.editTitle}`
          : `${localeImageRegister[currentLocale].form.addTitle}`
      }
      onClose={(): void => cancelAddEdit()}
      className="add-edit-registry modal small"
    >
      <Form
        initialValues={initialRegisterFields}
        onSubmitClick={onSubmitRegisterForm}
        render={(): React.ReactElement => (
          <FormElement>
            <div className="form-content">
              <Field
                name="name"
                component={FormInput}
                id="name"
                label={localeImageRegister[currentLocale].form.name}
                onChange={(e): void => {
                  setName(e.target.value);
                }}
                inputValue={name}
                className="form-field-wrapper"
                customValidator={checkLength}
                type="text"
                classForLabel="required-label"
              />
              <Field
                name="address"
                component={FormInput}
                id="address"
                label={localeImageRegister[currentLocale].form.address}
                onChange={(e): void => {
                  setAddress(e.target.value);
                }}
                inputValue={address}
                className="form-field-wrapper"
                customValidator={checkLength}
                type="text"
                placeholder={localeImageRegister[currentLocale].form.addressPlaceholder}
                classForLabel="required-label"
              />
              <div className="add-repository-input">
                {!credentialReg?.credentialId ? (
                  <Button
                    fill="flat"
                    textColor="green"
                    icon="plus"
                    type="button"
                    style={{ paddingLeft: '0' }}
                    className={styles.add_button}
                    onClick={(): void =>
                      selectCredential({
                        accountId: accountId || undefined,
                      })
                    }
                  >
                    {localeImageRegister[currentLocale].modal.credentialId}
                  </Button>
                ) : (
                  <div className="selected-repository-credential">
                    <span>{localeImageRegister[currentLocale].modal.credentialId}</span>
                    <div className={styles.credentialRepository}>
                      <span>{credentialReg.credentialName}</span>
                      <Button
                        fill="flat"
                        textColor="green"
                        onClick={(): void => setCredentialReg(undefined)}
                      >
                        <span className="k-icon k-i-x" />
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              <div className="add-registry-info-title">
                <span>{localeImageRegister[currentLocale].form.infoBlockTitle}</span>
              </div>
              <div className={styles.add_registry_repository}>
                <div className={styles.add_registry_info}>
                  <span>{localeImageRegister[currentLocale].form.infoBlock}</span>
                </div>
                <Button
                  fill="flat"
                  icon="plus"
                  textColor="green"
                  style={{ paddingLeft: '0' }}
                  type="button"
                  className={styles.add_button}
                  onClick={(): void => {
                    setAddRepository(true);
                  }}
                >
                  {localeImageRegister[currentLocale].form.addRepository}
                </Button>
                <RepositoriesTable />
              </div>
              <Field
                name="description"
                component={FormTextArea}
                id="description"
                label={localeImageRegister[currentLocale].form.description}
                onChange={(e): void => {
                  setDescription(e.value);
                }}
                inputValue={description}
              />
              {isAddRepository && <ModalAddRepository />}
            </div>
            <div className="error-common-field">
              <span>{errorMutation}</span>
            </div>
            <div className="k-form-buttons">
              <Button type="submit" disabled={!name || !address}>
                {localeImageRegister[currentLocale].form.btnSave}
              </Button>
              <Button type="button" onClick={(): void => cancelAddEdit()}>
                {localeImageRegister[currentLocale].form.btnCancel}
              </Button>
            </div>
          </FormElement>
        )}
      />
    </Dialog>
  );
}
