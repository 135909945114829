/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useState } from 'react';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import localeITask from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import '../../TaskDescription.module.scss';
import { SystemIDetails } from '../SystemIDetails';
import type { InventorizationResponse } from '../../../../../types/__generated/on-premise-solution/api/inventorizationResponse.v1';
import { HardwareIDetails } from '../HardwareIDetails';
import { ServiceIDetails } from '../ServiceIDetails';
import { SoftwareInventorizationList } from '../SoftwareInvList';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeITask;

export function TabsIDetails({
  data,
  hostName,
}: {
  data: InventorizationResponse | undefined;
  hostName: string | null | undefined;
}): React.ReactElement {
  const [selected, setSelected] = useState<number>(0);

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      setSelected(e.selected);
    },
    [setSelected],
  );

  const countSoft =
    data && data.type === 'linux'
      ? data.result.software?.length
      : data?.type === 'windows' && data?.result.software?.length;

  const countService =
    data && data.type === 'linux'
      ? data.result.systemd_units?.length
      : data?.type === 'windows' && data?.result.services?.length;

  return (
    <TabStrip selected={selected} onSelect={handleSelect}>
      <TabStripTab title={localeITask[currentLocale].tabs.system}>
        <SystemIDetails data={data} />
      </TabStripTab>
      {data?.type !== 'router' &&
        data?.type !== 'firewall' &&
        data?.type !== 'switch' &&
        countSoft && (
          <TabStripTab title={`${localeITask[currentLocale].tabs.software} (${countSoft || 0})`}>
            <SoftwareInventorizationList data={data} hostName={hostName} />
          </TabStripTab>
        )}
      {data?.type !== 'router' &&
        data?.type !== 'firewall' &&
        data?.type !== 'switch' &&
        countService && (
          <TabStripTab title={`${localeITask[currentLocale].tabs.services} (${countService || 0})`}>
            <ServiceIDetails data={data} hostName={hostName} />
          </TabStripTab>
        )}
      <TabStripTab title={localeITask[currentLocale].tabs.hardware}>
        <HardwareIDetails data={data} />
      </TabStripTab>
    </TabStrip>
  );
}
