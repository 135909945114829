/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { Field } from '@progress/kendo-react-form';
import { useEffect } from 'react';
import type localeHostGroups from '../../../../utils/i18n/assetLocales/assetGroups.json';
import locale from '../../../../utils/i18n/credentials/credentials.json';
import localeDashboardForm from '../../../../utils/i18n/dashboardLocale/addDashboardForm.json';
import { useCheck } from '../../../../hooks/components/useCheck';
import { useCommonContext } from '../../../../hooks/useCommonContext';
import { useCredentialContext } from '../../../../hooks/useCredentialContext';
import { DropDownSelect } from '../../../common/form/DropDownSelect';
import { FormInput } from '../../../common/form/FormInput';
import { checkLength } from '../../../../utils/helpers/validation';
import { Input } from '../../../common/baseElements/Input';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHostGroups;

export function CredentialFormWinrm(): React.ReactElement {
  const { ipValidator } = useCheck();

  const { resultCheckIp, setResultCheckIp } = useCommonContext();

  useEffect(() => {
    return () => setResultCheckIp(null);
  }, [setResultCheckIp]);

  const { typeCred, authTypeData, authType, changeAuthMeth, changeIpKdc, ipKdc, realm, setRealm } =
    useCredentialContext();

  return (
    <>
      {typeCred === 'winrm' && (
        <Field
          name="authType"
          component={DropDownSelect}
          label={locale[currentLocale].form.methodAuth}
          onChange={changeAuthMeth}
          data={authTypeData.filter((m) => m !== authType)}
          defaultValue={
            authTypeData.find((m) => m === authType) || locale[currentLocale].form.selectMethod
          }
          cls="form-field-wrapper"
          classForLabel="required-label"
        />
      )}
      {typeCred === 'winrm' && authType === 'kerberos' && (
        <>
          <div className="form-field-wrapper">
            <label className="k-label required-label" htmlFor="ipKdc">
              {locale[currentLocale].table.ipOfKeyCenter}
            </label>
            <Input
              id="ipKdc"
              className="k-input"
              name="ipKdc"
              value={ipKdc}
              onChange={(e): void => changeIpKdc(e)}
              onBlur={(e): void => ipValidator(e.target.value)}
            />
            {ipKdc && resultCheckIp && (
              <div className="error-common-field">
                <span>{resultCheckIp}</span>
              </div>
            )}
          </div>
          <Field
            name="realm"
            component={FormInput}
            id="realm"
            label={locale[currentLocale].table.area}
            onChange={(e): void => {
              setRealm(e.target.value);
            }}
            inputValue={realm}
            className="form-field-wrapper"
            customValidator={checkLength}
            type="text"
            classForLabel="required-label"
            tooltipText={localeDashboardForm[currentLocale].form.realmInfo}
          />
        </>
      )}
    </>
  );
}
