import {
  createContext,
  JSXElementConstructor,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import type { Account } from '../types/__generated/on-premise-solution/api/account.v1';

export interface ICommonContext {
  isOpenForm: boolean;
  setOpenForm: (v: boolean) => void;
  resultCheckIp: string | null;
  setResultCheckIp: (v: null | string) => void;
  handleCopied: () => void;
  copied: boolean;
  setCopied: React.Dispatch<React.SetStateAction<boolean>>;
  accountOfAsset: Pick<Account, 'accountId' | 'name'> | null;
  setAccountOfAsset: React.Dispatch<
    React.SetStateAction<Pick<Account, 'accountId' | 'name'> | null>
  >;
}

const CommonContext = createContext<ICommonContext | null>(null);

export function CommonContextProvider({
  children,
}: {
  children: ReactElement<string | JSXElementConstructor<string>>;
}): React.ReactElement {
  const [isOpenForm, setOpenForm] = useState<boolean>(false);

  const [resultCheckIp, setResultCheckIp] = useState<null | string>(null);

  const [copied, setCopied] = useState(false);

  const [accountOfAsset, setAccountOfAsset] = useState<Pick<Account, 'accountId' | 'name'> | null>(
    null,
  );

  const handleCopied = useCallback((): void => {
    const timeout = setTimeout(() => {
      setCopied(false);
      clearTimeout(timeout);
    }, 1200);
  }, [setCopied]);

  const value = useMemo(
    () => ({
      isOpenForm,
      setOpenForm,
      resultCheckIp,
      setResultCheckIp,
      handleCopied,
      copied,
      setCopied,
      accountOfAsset,
      setAccountOfAsset,
    }),
    [isOpenForm, resultCheckIp, handleCopied, copied, accountOfAsset],
  );

  return <CommonContext.Provider value={value}>{children}</CommonContext.Provider>;
}

export const useCommonContext = (): ICommonContext => {
  const context = useContext(CommonContext);
  if (context === null) {
    throw new Error('useCommonContext must be used inside the CommonContextProvider.Provider.');
  }

  return context;
};
