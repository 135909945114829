/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import type { GridCellProps } from '@progress/kendo-react-grid';
import locale from '../../../utils/i18n/taskLocale/inventorizationTask.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function SizeCell(props: GridCellProps): React.ReactElement {
  const { dataItem, field, className } = props;

  const fieldArray = field ? field.split('.') : [];
  let value = dataItem;

  if (!fieldArray) value = null;
  else if (fieldArray.length === 1) value = dataItem[fieldArray[0]];
  else if (fieldArray.length > 1) {
    fieldArray.forEach((f) => {
      value = value ? value[f] : value;
    });
  }

  return value && value !== '-' ? (
    <td className={className || ''}>
      {/* {Math.round(Number(dataItem[field]) / 1024 / 1024)} {locale[currentLocale].sizeMb} */}
      {Math.round(Number(value) / 1024 / 1024)} {locale[currentLocale].sizeMb}
    </td>
  ) : (
    <td className={className || ''}>-</td>
  );
}
