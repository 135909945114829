import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { useState } from 'react';
import localeSettings from '../../../../utils/i18n/settingsLocales/settings.json';
import styles from '../WhiteListTabEl.module.scss';
import type { BulletinWhitelistDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/bulletinWhitelistDashboardResponse.v1';
import { AssetDescriptionList } from './AssetDescriptionList';
import { GroupDescriptionList } from '../../../common/cells/GroupDescriptionList';
import { AssetTypeDescriptionList } from './AssetTypeDescriptionList';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

export function WhiteListDescriptionContentScope({
  scope,
}: {
  scope: BulletinWhitelistDashboardResponse['scope'] | undefined;
}): React.ReactElement {
  const [selected, setSelected] = useState<number>(0);

  const assetsCount = scope?.assets ? scope.assets.length : 0;

  const groupsCount = scope?.assetGroups ? scope.assetGroups.length : 0;

  const assetTypeCount = scope?.assetTypes ? scope.assetTypes.length : 0;

  const handleSelect = (e: TabStripSelectEventArguments): void => {
    setSelected(e.selected);
  };

  return (
    <div className={styles.whiteListScope}>
      <TabStrip selected={selected} onSelect={handleSelect}>
        {scope?.assets && scope.assets.length > 0 && (
          <TabStripTab
            title={`${localeSettings[currentLocale].whiteList.description.assets} (${assetsCount})`}
          >
            <AssetDescriptionList assets={scope?.assets} />
          </TabStripTab>
        )}
        {scope?.assetGroups && scope.assetGroups.length > 0 && (
          <TabStripTab
            title={`${localeSettings[currentLocale].whiteList.description.groups} (${groupsCount})`}
          >
            <GroupDescriptionList groups={scope?.assetGroups} />
          </TabStripTab>
        )}
        {scope?.assetTypes && scope.assetTypes.length > 0 && (
          <TabStripTab
            title={`${localeSettings[currentLocale].apiKey.form.tabs.assetType} (${assetTypeCount})`}
          >
            <AssetTypeDescriptionList scope={scope} />
          </TabStripTab>
        )}
      </TabStrip>
    </div>
  );
}
