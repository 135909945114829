import type { GridCellProps } from '@progress/kendo-react-grid';
import localeSettings from '../../../../../utils/i18n/settingsLocales/settings.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

export interface IAssetType {
  router: string;
  firewall: string;
  switch: string;
  host: string;
  image: string;
}
export function CellTypeApiKeyList(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  return (
    <td>
      <span>
        {localeSettings[currentLocale].apiKey.assetType[dataItem.type as keyof IAssetType]}
      </span>
    </td>
  );
}
