/* eslint-disable no-nested-ternary */
import '../../../TaskDescription.module.scss';
import { NavLink } from 'react-router-dom';
import localeVmTask from '../../../../../../utils/i18n/taskLocale/vmTask.json';
import type { AuditDashboardResponse } from '../../../../../../types/__generated/on-premise-solution/api/auditDashboardResponse.v1';

export function SecondColumn({
  currentLocale,
  auditInfo,
}: {
  currentLocale: 'ru-RU' | 'en-EN';
  auditInfo: AuditDashboardResponse | undefined;
}): React.ReactElement {
  return (
    <table className="column">
      <tbody>
        <tr>
          <td>
            <span>{localeVmTask[currentLocale].summary.hostName}</span>
          </td>
          <td>
            {auditInfo ? (
              <span style={{ fontWeight: '700' }}>
                {(auditInfo.asset.type === 'host' ||
                  auditInfo.asset.type === 'router' ||
                  auditInfo.asset.type === 'firewall') &&
                  auditInfo.asset.latestInventorization?.hostname}
              </span>
            ) : (
              <span>-</span>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeVmTask[currentLocale].summary.ip}</span>
          </td>
          <td>
            {auditInfo ? (
              <span>
                {(auditInfo.asset.type === 'host' ||
                  auditInfo.asset.type === 'router' ||
                  auditInfo.asset.type === 'firewall') &&
                  auditInfo.asset.latestInventorization?.ip}
              </span>
            ) : (
              <span>-</span>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeVmTask[currentLocale].summary.system}</span>
          </td>
          <td>
            {auditInfo && auditInfo.asset.type === 'host' && (
              <span>
                {auditInfo.asset.latestInventorization?.os.name}{' '}
                {auditInfo.asset.latestInventorization?.os.type[0].toUpperCase()}
                {auditInfo.asset.latestInventorization?.os.type.slice(1)}{' '}
                {auditInfo.asset.latestInventorization?.os.version}
              </span>
            )}
            {auditInfo &&
              (auditInfo.asset.type === 'router' || auditInfo.asset.type === 'firewall') && (
                <span>
                  {auditInfo.asset.latestInventorization?.os?.name}{' '}
                  {auditInfo.asset.latestInventorization?.os?.version}
                </span>
              )}
            {auditInfo && !auditInfo.asset.latestInventorization && <span>-</span>}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeVmTask[currentLocale].summary.assetId}</span>
          </td>
          <td>
            {auditInfo && auditInfo.asset.type === 'host' && (
              <NavLink
                className="link-underline"
                to={`/lk/assets/hosts/${auditInfo.asset.assetId}`}
              >
                {auditInfo.asset.assetId}
              </NavLink>
            )}
            {auditInfo &&
              (auditInfo.asset.type === 'router' || auditInfo.asset.type === 'firewall') && (
                <NavLink
                  className="link-underline"
                  to={`/lk/assets/devices/${auditInfo.asset.type}/${auditInfo.asset.assetId}`}
                >
                  {auditInfo.asset.assetId}
                </NavLink>
              )}
            {auditInfo && auditInfo.asset.type && auditInfo.asset.assetId && <span>-</span>}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
