/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-pascal-case */
import { useHistory, useParams } from 'react-router-dom';
import { Typography } from '@progress/kendo-react-common';
import { useQuery } from 'react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { useState } from 'react';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { BoundaryErrorComponent } from '../../common/BoundaryErrorComponent';
import localeSettings from '../../../utils/i18n/settingsLocales/settings.json';
import { getWhiteLIstId } from '../../../services/settings-service';
import type { BulletinWhitelistDashboardResponse } from '../../../types/__generated/on-premise-solution/api/bulletinWhitelistDashboardResponse.v1';
import { WhiteListDescriptionMetaBlock } from './description/WhiteListDescriptionMetaBlock';
import { WhiteListDescriptionContent } from './description/WhiteListDescriptionContent';
import { useAuditModalContext } from '../../../hooks/useAuditModalContext';
import { VulnModal } from '../../common/modal/vulnModal/VulnModal';
import { Button } from '../../common/baseElements/Button';
import { getUserData } from '../../../services/local-storage-service';
import styles from './WhiteListTabEl.module.scss';
import { useWhiteListContext } from '../../../hooks/useWhiteListContext';
import type { TExtendedBulletinWhitelist } from '../../../utils/helpers/types';
import { AddWhiteListForm } from './form/AddWhiteListForm';
import { DeleteModal } from '../../common/modal/DeleteModal';
import { useAssetCreatePropContext } from '../../../hooks/useAssetCreatePropContext';
import { AddVulnToWLForm } from './form/AddVulnToWLForm';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

export function WhiteListDescription(): React.ReactElement {
  const history = useHistory();

  const currentUser = getUserData();

  const [isConfirm, setConfirm] = useState(false);

  const { bulletinWhitelistId } = useParams<{ bulletinWhitelistId: string }>();

  const {
    whitelistUpdate,
    editWhiteList,
    setStatusWhiteList,
    mutationDeleteWhiteList,
    isWhiteListForm,
  } = useWhiteListContext();

  const { cveName } = useAuditModalContext();

  const { isAddVulnToWLForm } = useAssetCreatePropContext();

  const query = useQuery<BulletinWhitelistDashboardResponse, ApiError>(
    ['bulletinWhitelistId', bulletinWhitelistId],
    () => getWhiteLIstId(bulletinWhitelistId),
  );

  const createdByID = query.data?.createdBy.source === 'user' && query.data.createdBy.id;

  const handleEdit = (): void => {
    const data = query.data as TExtendedBulletinWhitelist;
    editWhiteList(data);
  };

  const handleConfirm = (): void => {
    setConfirm(true);
  };

  const handleDelete = (): void => {
    mutationDeleteWhiteList.mutate(query.data!.bulletinWhitelistId);
    history.goBack();
    setConfirm(false);
  };

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      <div className="whitelist-description">
        <div className="common-header-page">
          <Typography.h3 className="title">
            {localeSettings[currentLocale].whiteList.description.title}
            {query.data?.name}
          </Typography.h3>
        </div>
        <DeleteModal
          isConfirm={isConfirm}
          setConfirm={setConfirm}
          handleDelete={handleDelete}
          name={query.data?.name || ''}
          countInside={query.data?.totalBulletins}
        />
        {currentUser?.role !== 'reader_user' && (
          <div className={`topactions ${styles.topActions}`}>
            <Button
              fill="action"
              onClick={handleEdit}
              disabled={currentUser?.role === 'user' && createdByID !== currentUser?.id}
            >
              {localeSettings[currentLocale].whiteList.grid.edit}
            </Button>
            <Button
              onClick={(): void =>
                setStatusWhiteList(query.data!.isDisabled, query.data!.bulletinWhitelistId)
              }
              disabled={currentUser?.role === 'user' && createdByID !== currentUser?.id}
            >
              {query.data?.isDisabled
                ? localeSettings[currentLocale].whiteList.grid.setActiveMenu
                : localeSettings[currentLocale].whiteList.grid.setNotActive}
            </Button>
            <Button
              onClick={handleConfirm}
              disabled={currentUser?.role === 'user' && createdByID !== currentUser?.id}
            >
              {localeSettings[currentLocale].whiteList.grid.delete}
            </Button>
          </div>
        )}
        <WhiteListDescriptionMetaBlock data={query.data} />
        <WhiteListDescriptionContent scope={query.data?.scope} bulletins={query.data?.bulletins} />
      </div>
      {cveName && <VulnModal />}
      {((whitelistUpdate && !isWhiteListForm) || (!whitelistUpdate && isWhiteListForm)) && (
        <AddWhiteListForm />
      )}
      {isAddVulnToWLForm && <AddVulnToWLForm />}
    </ErrorBoundary>
  );
}
