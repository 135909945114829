import { nanoid } from 'nanoid';
import { Button } from '../../../../common/baseElements/Button';
import localeAccount from '../../../../../utils/i18n/accountLocales/accountList.json';
import { useAccountModalContext } from '../../../../../hooks/useAccountModalContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAccount;

export function AccountMutableBlock(): React.ReactElement {
  const { setAddAccount, accountForm, deleteAccountFromTask } = useAccountModalContext();

  return (
    <div className="apply-assets">
      <div className="title-apply-assets">
        <span>{localeAccount[currentLocale].titleAccountForm}</span>
        <button
          className="k-button k-button-md k-button-rectangle k-button-solid k-button-solid-base k-rounded-md btn-link btn-link"
          onClick={(): void => setAddAccount(true)}
          type="button"
          disabled={document.location.pathname.includes('repositories')}
        >
          {localeAccount[currentLocale].addAccount}
        </button>
      </div>
      <div className="list-assets">
        {accountForm?.map((a) => {
          return (
            <div className="item-asset" key={nanoid()}>
              <Button
                type="button"
                fill="task_asset"
                onClick={(e): void => deleteAccountFromTask(a.accountId, e)}
              >
                {a.name || a.accountId}
              </Button>
            </div>
          );
        })}
        {(accountForm?.length === 0 || accountForm === null) && (
          <span className="placeholder">{localeAccount[currentLocale].selectAccounts}</span>
        )}
      </div>
    </div>
  );
}
