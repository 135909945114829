import type { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { memo, useCallback } from 'react';
import { useHostModalContext } from '../../../../../../hooks/useHostModalContext';

function CellSelectPackageWl(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { selectedPkgsWl, setSelectedPkgsWl } = useHostModalContext();

  const addPackage = useCallback(
    (name: string, packetVersion: string): void => {
      if (selectedPkgsWl && selectedPkgsWl.length > 0) {
        const index = selectedPkgsWl.findIndex(
          (p) => p.name === name && p.packetVersion === packetVersion,
        );
        if (index !== -1) {
          const cutSelectedPkgsWl = selectedPkgsWl.filter(
            (pkgName) => pkgName.name !== name || pkgName.packetVersion !== packetVersion,
          );
          setSelectedPkgsWl([...cutSelectedPkgsWl]);
        } else {
          setSelectedPkgsWl([...selectedPkgsWl, { name, packetVersion }]);
        }
      } else {
        setSelectedPkgsWl([{ name, packetVersion }]);
      }
    },
    [selectedPkgsWl, setSelectedPkgsWl],
  );

  return (
    <td>
      {selectedPkgsWl?.find((p) => p === dataItem.name) ? (
        <Checkbox
          checked={
            !!selectedPkgsWl?.find(
              (p) => p.name === dataItem.name && p.packetVersion === dataItem.packetVersion,
            )
          }
          onClick={(): void => addPackage(dataItem.name, dataItem.packetVersion)}
        />
      ) : (
        <Checkbox
          checked={
            !!selectedPkgsWl?.find(
              (p) => p.name === dataItem.name && p.packetVersion === dataItem.packetVersion,
            )
          }
          onClick={(): void => addPackage(dataItem.name, dataItem.packetVersion)}
        />
      )}
    </td>
  );
}

export default memo(CellSelectPackageWl);
