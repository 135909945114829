/* eslint-disable react/jsx-pascal-case */
// import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Typography } from '@progress/kendo-react-common';
import { useQuery } from 'react-query';
import localeSettings from '../../../utils/i18n/settingsLocales/settings.json';
import { TabsSettings } from './TabsSettings';
import { Summary } from './Summary';
import './Settings.module.scss';
import type { ConfigResponse } from '../../../types/__generated/on-premise-solution/api/configResponse.v1';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getConfig } from '../../../services/license-service';
import { DATA_CACHE_CACHETIME, DATA_CACHE_STALETIME } from '../../../utils/helpers/constants';
import { getUserData } from '../../../services/local-storage-service';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

export function Settings(): React.ReactElement {
  const currentUser = getUserData();

  const query = useQuery<ConfigResponse, ApiError>(['settingsConfig'], () => getConfig(), {
    staleTime: DATA_CACHE_STALETIME,
    cacheTime: DATA_CACHE_CACHETIME,
    keepPreviousData: true,
    enabled: currentUser?.role === 'super_admin',
  });

  const { data } = query;

  return (
    <div className="settings">
      <div className="common-header-page">
        <Typography.h3 className="title">
          {localeSettings[currentLocale].settingsTitle}
        </Typography.h3>
      </div>
      <Summary />
      <TabsSettings data={data} />
    </div>
  );
}
