import type { SortDescriptor } from '@progress/kendo-data-query';
import { INITIAL_SORT, INITIAL_PAGING } from './constants';
import type { IPageState } from './types';

export interface IFilterUrlDataArtifacts {
  status24eq?: string;
  startedAt24gt?: string;
  startedAt24eq?: string;
  startedAt24gte?: string;
  startedAt24lt?: string;
  startedAt24lte?: string;
  lastActivity24gt?: string;
  lastActivity24eq?: string;
  lastActivity24gte?: string;
  lastActivity24lt?: string;
  lastActivity24lte?: string;
}
export interface IUrlParams {
  sort: SortDescriptor[];
  page: IPageState;
  pageLimitGrid: { [key: string]: string } | null;
  filterUrlData: IFilterUrlDataArtifacts;
}

export function getUrlParamsArtifacts(pageLimitGridKey: string): IUrlParams {
  const pageLimitGrid: IUrlParams['pageLimitGrid'] =
    localStorage.getItem('pageLimitGrid') &&
    JSON.parse(localStorage.getItem('pageLimitGrid') || '');

  const locationUrlData: { [name: string]: string } | null = {};
  if (document.location.search) {
    const locationSearch = document.location.search.slice(1).split('&');
    locationSearch.forEach((val) => {
      const value = val.split('=');
      if (value[0] === 'startedAt[%24gt]') {
        locationUrlData.startedAt24gt = decodeURI(value[1]) || '';
      } else if (value[0] === 'startedAt[%24eq]') {
        locationUrlData.startedAt24eq = decodeURI(value[1]) || '';
      } else if (value[0] === 'startedAt[%24gte]') {
        locationUrlData.startedAt24gte = decodeURI(value[1]) || '';
      } else if (value[0] === 'startedAt[%24lt]') {
        locationUrlData.startedAt24lt = decodeURI(value[1]) || '';
      } else if (value[0] === 'startedAt[%24lte]') {
        locationUrlData.startedAt24lte = decodeURI(value[1]) || '';
      } else if (value[0] === 'lastActivity[%24gt]') {
        locationUrlData.lastActivity24gt = decodeURI(value[1]) || '';
      } else if (value[0] === 'lastActivity[%24eq]') {
        locationUrlData.lastActivity24eq = decodeURI(value[1]) || '';
      } else if (value[0] === 'lastActivity[%24gte]') {
        locationUrlData.lastActivity24gte = decodeURI(value[1]) || '';
      } else if (value[0] === 'lastActivity[%24lt]') {
        locationUrlData.lastActivity24lt = decodeURI(value[1]) || '';
      } else if (value[0] === 'lastActivity[%24lte]') {
        locationUrlData.lastActivity24lte = decodeURI(value[1]) || '';
      } else {
        locationUrlData[value[0]] = value[1] || '';
      }
    });
  }

  const { sortField, sortOrder, skip } = locationUrlData;
  const sort: SortDescriptor[] =
    sortField && sortOrder && (sortOrder === 'asc' || sortOrder === 'desc')
      ? [{ field: sortField, dir: sortOrder }]
      : INITIAL_SORT;

  const page: IPageState = {
    skip: Number(skip) || INITIAL_PAGING.skip,
    take:
      pageLimitGrid && Number(pageLimitGrid[pageLimitGridKey])
        ? Number(pageLimitGrid[pageLimitGridKey])
        : INITIAL_PAGING.take,
  };

  const filterUrlData = {} as IFilterUrlDataArtifacts;

  if (locationUrlData.startedAt24gt) {
    filterUrlData.startedAt24gt = locationUrlData.startedAt24gt;
  }
  if (locationUrlData.startedAt24eq) {
    filterUrlData.startedAt24eq = locationUrlData.startedAt24eq;
  }
  if (locationUrlData.startedAt24gte) {
    filterUrlData.startedAt24gte = locationUrlData.startedAt24gte;
  }
  if (locationUrlData.startedAt24lt) {
    filterUrlData.startedAt24lt = locationUrlData.startedAt24lt;
  }
  if (locationUrlData.startedAt24lte) {
    filterUrlData.startedAt24lte = locationUrlData.startedAt24lte;
  }
  if (locationUrlData.lastActivity24gt) {
    filterUrlData.lastActivity24gt = locationUrlData.lastActivity24gt;
  }

  return {
    sort,
    page,
    pageLimitGrid,
    filterUrlData,
  };
}
