/* eslint-disable react/jsx-pascal-case */
import { Grid, GridColumn as Column, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { cvssScoreWidth, PAGEABLE_DATA } from '../../utils/helpers/constants';
import type { ICommonImageList } from '../../utils/helpers/types';
import { handleSort } from '../../utils/sorting-in-table';
import localeHost from '../../utils/i18n/assetLocales/hostList.json';
import { Repotags } from '../features/Containers/Image/cellComponents/Repotags';
import { ImageIdInfo } from '../features/Containers/Image/cellComponents/ImageIdInfo';
import { VulnsCount } from '../features/Containers/Image/cellComponents/VulnsCount';
import { ImageSystem } from '../features/Containers/Image/cellComponents/ImageSystem';
import { ImageSize } from '../features/Containers/Image/cellComponents/ImageSize';
import { ImageMenuCell } from '../features/Containers/Image/cellComponents/ImageMenuCell';
import { CellSelectImage } from '../features/Settings/ApiKeys/cells/CellSelectImage';
import { ScoreCvssCell } from './cells/ScoreCvssCell';
import { StandartCell } from './baseElements/StandartCell';
import { VHeaderCell } from './VHeaderCell';
import { ScoreVCell } from './cells/ScoreVCell';
import { getUserData } from '../../services/local-storage-service';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHost;

export function CommonImageList(props: ICommonImageList): React.ReactElement {
  const { sort, dataImage, page, total, pageChange, setSort, dataForHooks, conditionSort } = props;

  const currentUser = getUserData();

  return (
    <LocalizationProvider language={currentLocale}>
      <IntlProvider locale={currentLocale.substring(0, 2)}>
        <Grid
          pageable={total && total > conditionSort ? PAGEABLE_DATA : false}
          sortable
          sort={sort}
          data={dataImage}
          skip={page.skip}
          take={page.take}
          total={total || 0}
          onPageChange={pageChange}
          onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
          scrollable="none"
          className={
            total && total < conditionSort && dataForHooks.componentName !== 'VulnModalImage'
              ? 'no-pageable-k-grid'
              : ''
          }
        >
          {dataForHooks.componentName === 'AddImageList' && (
            <Column
              field="addImage"
              // title={localeHost[currentLocale].form.selectAsset}
              title=" "
              cell={CellSelectImage}
              width="40px"
            />
          )}
          <Column
            field="latestAudit.maxCvssScore"
            title={localeHost[currentLocale].form.cvssScore}
            filter="text"
            cell={ScoreCvssCell}
            width={cvssScoreWidth.sort}
          />
          <Column
            field="latestAudit.maxVFstec"
            title="v"
            filter="text"
            cell={ScoreVCell}
            width={cvssScoreWidth.sort}
            headerCell={VHeaderCell}
          />
          {dataForHooks.componentName !== 'ImageList' ? (
            <Column field="imageNames" title={localeHost[currentLocale].form.repotags} />
          ) : (
            <Column
              field="imageNames"
              title={localeHost[currentLocale].form.repotags}
              cell={Repotags}
            />
          )}
          <Column
            field="latestAudit.vulnerabilities.count"
            title={localeHost[currentLocale].form.vulnsCount}
            cell={VulnsCount}
            width="140px"
          />
          <Column
            field="latestInventorization.os.name"
            title={localeHost[currentLocale].form.system}
            filter="text"
            cell={ImageSystem}
            width="160px"
          />
          <Column
            field="latestInventorization.os.size"
            title={localeHost[currentLocale].form.size}
            filter="text"
            cell={ImageSize}
            width="108px"
          />
          <Column
            field="imageId"
            title={localeHost[currentLocale].form.imageId}
            cell={ImageIdInfo}
            width="140px"
          />
          <Column
            field="assetHostsCount"
            title={localeHost[currentLocale].form.hostsCount}
            cell={StandartCell}
            width="96px"
          />
          <Column
            field="registriesCount"
            title={localeHost[currentLocale].form.registryCount}
            cell={StandartCell}
            width={currentUser?.role === 'super_admin' ? '90' : '112px'}
          />
          {currentUser?.role === 'super_admin' &&
            dataForHooks.componentName !== 'VulnModalHosts' && (
              <Column
                field="account.name"
                title={localeHost[currentLocale].form.accountName}
                filter="text"
                width="124px"
              />
            )}
          {dataForHooks.componentName === 'ImageList' && (
            <Column cell={ImageMenuCell} width="40px" />
          )}
        </Grid>
      </IntlProvider>
    </LocalizationProvider>
  );
}
