/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import type { GridCellProps } from '@progress/kendo-react-grid';

export function BoolCell(props: GridCellProps): React.ReactElement {
  const { dataItem, field, className } = props;

  return field !== undefined && typeof dataItem[field] === 'boolean' ? (
    <td className={className || ''}>{dataItem[field].toString()}</td>
  ) : (
    <td className={className || ''}>-</td>
  );
}
