import '../../TaskDescription.module.scss';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import type { State, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { useEffect, useRef, useState } from 'react';
import { Filter } from '@progress/kendo-react-data-tools';
import type { ExcelExport } from '@progress/kendo-react-excel-export';
import { NavLink, useParams } from 'react-router-dom';
import { Button } from '../../../../common/baseElements/Button';
import { FILTER_INITIAL } from '../../../../../utils/helpers/constants';
import localeAuditTask from '../../../../../utils/i18n/taskLocale/auditTask.json';
import locale from '../../../../../utils/i18n/security/vulnerabilities.json';
import { useAuditModalContext } from '../../../../../hooks/useAuditModalContext';
import PackageNameModal from './PackageNameModal';
import type {
  Audit,
  AuditNetworkDeviceResult,
  AuditResult,
  AuditWindowsResult,
} from '../../../../../types/__generated/on-premise-solution/api/audit.v1';
import { ICollectVulnSoftData, collectVulnSoft } from '../../../../../utils/helpers/audit-helpers';
import {
  handlerCustomSearch,
  onFilterChange,
} from '../../../../../utils/helpers/local-filter-helpers';
import { useLocalExcelExport } from '../../../../../hooks/components/useLocalExcelExport';
import {
  VULNERABILITIES_LIN_SOFT,
  VULNERABILITIES_WIN_SOFT,
} from '../../../../../utils/helpers/constant-local-filter-task';
import { VulnModal } from '../../../../common/modal/vulnModal/VulnModal';
import { Input } from '../../../../common/baseElements/Input';
import styles from './packageNameModal.module.scss';
import { AddVulnToWLForm } from '../../../WhiteList/form/AddVulnToWLForm';
import { useAssetCreatePropContext } from '../../../../../hooks/useAssetCreatePropContext';
import { AddWhiteListForm } from '../../../WhiteList/form/AddWhiteListForm';
import { useWhiteListContext } from '../../../../../hooks/useWhiteListContext';
import { VulnsSoftActions } from './VulnsSoftActions';
import { VulnsSoftTable } from './VulnsSoftTable';
import type { TvulnSoftDataForSelect } from '../../../../../utils/helpers/types';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

type TVulnSoftProp = {
  dataResult: AuditResult | AuditWindowsResult | AuditNetworkDeviceResult | undefined;
  type: Audit['type'] | undefined;
  hostName: string | undefined | null | false;
  vulnSoftWhiteList?: false | ICollectVulnSoftData[] | undefined;
  id?: string | undefined;
  classOfDvice?: string | undefined;
  assetType?: string | undefined;
};

export function VulnsSoft(props: TVulnSoftProp): React.ReactElement {
  const { dataResult, type, hostName, vulnSoftWhiteList, id, classOfDvice, assetType } = props;

  const pageLimitGrid: { [key: string]: string } =
    localStorage.getItem('pageLimitGrid') &&
    JSON.parse(localStorage.getItem('pageLimitGrid') || '');

  const initialDataState: State = {
    sort: [{ field: 'maxScore', dir: 'desc' }],
    take: pageLimitGrid && pageLimitGrid.VulnsSoft ? Number(pageLimitGrid.VulnsSoft) : 10,
    skip: 0,
    filter: FILTER_INITIAL,
  };

  const { assetId } = useParams<{ assetId: string }>();

  const fieldFindInTable = 'name';

  const [dataState, setDataState] = useState<State>(initialDataState);

  const [customSearch, setCustomSearch] = useState('');

  const [filter, setFilter] = useState(FILTER_INITIAL);

  const [isWhiteListTable, setWhiteListTable] = useState(false);

  const [isEnabledWl, setEnabledWl] = useState(false);

  const [vulnSoftDataForSelect, setVulnSoftDataForSelect] = useState<
    TvulnSoftDataForSelect[] | undefined
  >(undefined);

  const [vulnSoftDataForSelectWl, setVulnSoftDataForSelectWl] = useState<
    TvulnSoftDataForSelect[] | undefined
  >(undefined);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _export = useRef<ExcelExport | null>(null);

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  const { pkgName, cveName } = useAuditModalContext();

  const vulnSoftData = collectVulnSoft(
    dataResult?.vulnerableObjects,
    dataResult?.cumulativeData.vulns,
  );

  const vulnSoftDataWl = collectVulnSoft(
    dataResult?.whitelisted?.vulnerableObjects,
    dataResult?.whitelisted?.cumulativeData.vulns,
  );

  useEffect(() => {
    if (dataState && vulnSoftData) {
      const vulnSoftDataSliced = vulnSoftData
        .sort((a, b) => b.maxScore - a.maxScore)
        .slice(dataState.skip, (dataState.skip || 0) + (dataState.take || 0))
        .map((value) => ({ name: value.name, packetVersion: value.packetVersion }));

      setVulnSoftDataForSelect(vulnSoftDataSliced);
    }
    if (dataState && vulnSoftDataWl) {
      const vulnSoftDataSliced = vulnSoftDataWl
        .sort((a, b) => b.maxScore - a.maxScore)
        .slice(dataState.skip, (dataState.skip || 0) + (dataState.take || 0))
        .map((value) => ({ name: value.name, packetVersion: value.packetVersion }));

      setVulnSoftDataForSelectWl(vulnSoftDataSliced);
    }
  }, [dataState.skip, dataState.take]);

  const excelExport = useLocalExcelExport(
    dataState,
    _export,
    vulnSoftWhiteList || vulnSoftData,
    setDataState,
  );

  const refWrapper = useRef<HTMLDivElement>(null);

  const { isAddVulnToWLForm } = useAssetCreatePropContext();

  const { isWhiteListForm } = useWhiteListContext();

  useEffect(() => {
    if (!refWrapper.current?.className.includes('vulns-soft')) {
      setEnabledWl(true);
    }

    const wlBlock = document.getElementById('idVulns');

    if (wlBlock) {
      wlBlock.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [isWhiteListTable]);

  return (
    <>
      <div
        className={vulnSoftWhiteList ? styles.vulnsSoftWhiteList : `vulns-soft ${styles.vulnsSoft}`}
        ref={refWrapper}
        id={id}
      >
        {vulnSoftWhiteList || vulnSoftData ? (
          <>
            <LocalizationProvider language={currentLocale}>
              <IntlProvider locale={currentLocale.substring(0, 2)}>
                <div className="filter-line">
                  <Filter
                    value={filter}
                    onChange={(event): void =>
                      onFilterChange(
                        event,
                        setCustomSearch,
                        setDataState,
                        dataState,
                        setFilter,
                        filterValue,
                        customSearch,
                        fieldFindInTable,
                      )
                    }
                    fields={
                      type === 'linux'
                        ? VULNERABILITIES_LIN_SOFT[currentLocale]
                        : VULNERABILITIES_WIN_SOFT[currentLocale]
                    }
                  />
                  {filter.filters.length === 0 && (
                    <Input
                      name="customSearch"
                      placeholder={
                        type === 'linux'
                          ? localeAuditTask[currentLocale].customFilterPackageName
                          : localeAuditTask[currentLocale].customFilterTitle
                      }
                      value={customSearch}
                      onChange={(e): void =>
                        handlerCustomSearch(
                          e,
                          setCustomSearch,
                          setDataState,
                          filterValue,
                          dataState,
                          fieldFindInTable,
                        )
                      }
                    />
                  )}
                  {vulnSoftDataWl && vulnSoftDataWl.length > 0 && !isEnabledWl && (
                    <div className={styles.whiteListGridTitle}>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/attention_yellow.svg`}
                        alt="attention"
                      />
                      <Button
                        fill="dotted"
                        type="button"
                        onClick={(): void => setWhiteListTable(!isWhiteListTable)}
                      >
                        {!isWhiteListTable
                          ? localeAuditTask[currentLocale].vulnsSoft.table.commontitleWl
                          : localeAuditTask[currentLocale].vulnsSoft.table.commontitleWlHide}
                        ({vulnSoftDataWl.length})
                      </Button>
                    </div>
                  )}
                  <Button excel fill="flat" onClick={excelExport}>
                    {localeAuditTask[currentLocale].vulnsSoft.table.exportToExcel}
                  </Button>
                </div>
              </IntlProvider>
            </LocalizationProvider>
            {type === 'linux' && assetType !== 'image' && (
              <VulnsSoftActions
                hostName={hostName}
                id={id}
                vulnSoftDataForSelect={vulnSoftDataForSelect}
                vulnSoftDataForSelectWl={vulnSoftDataForSelectWl}
              />
            )}
            <VulnsSoftTable
              hostName={hostName}
              dataResult={dataResult}
              id={id}
              vulnSoftWhiteList={vulnSoftWhiteList}
              exportRef={_export}
              dataState={dataState}
              setDataState={setDataState}
              pageLimitGrid={pageLimitGrid}
              type={type}
              classOfDvice={classOfDvice}
            />
            {pkgName && <PackageNameModal />}
            {cveName && <VulnModal />}
            {isAddVulnToWLForm && <AddVulnToWLForm />}
            {isWhiteListForm && !isAddVulnToWLForm && <AddWhiteListForm />}
          </>
        ) : (
          <p>{localeAuditTask[currentLocale].vulnsSoft.table.noVulnerableSoftFound}</p>
        )}
      </div>
      {dataResult?.whitelisted && vulnSoftDataWl && vulnSoftDataWl.length > 0 && !isEnabledWl && (
        <div className={styles.actionsWLWrap}>
          <div className={styles.whiteListGridTitle}>
            <span className="whitelist-grid-title__text">
              {locale[currentLocale].vulnsTable.titleWl}
            </span>
            <NavLink
              className="dashboard__link"
              to={`/lk/audit/exceptions${
                assetId
                  ? `?skip=0&limit=15&sortField=createdAt&sortOrder=desc&assetIds[$eq]=${assetId}`
                  : ''
              }`}
            >
              {locale[currentLocale].vulnsTable.titleWlLink}
            </NavLink>
            <Button
              fill="dotted"
              type="button"
              onClick={(): void => setWhiteListTable(!isWhiteListTable)}
            >
              {isWhiteListTable
                ? localeAuditTask[currentLocale].vulnsSoft.table.linkWlHide
                : localeAuditTask[currentLocale].vulnsSoft.table.linkWlShow}
              {type === 'windows' || type === 'network_device'
                ? localeAuditTask[currentLocale].vulnsSoft.table.linkWlVulnSoft
                : localeAuditTask[currentLocale].vulnsSoft.table.linkWlVulnPkgs}
              ({vulnSoftDataWl.length})
            </Button>
          </div>
        </div>
      )}
      {isWhiteListTable && (
        <VulnsSoft
          dataResult={dataResult}
          type={type}
          hostName={hostName}
          vulnSoftWhiteList={vulnSoftDataWl}
          id="idVulns"
        />
      )}
    </>
  );
}
