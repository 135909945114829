import { useEffect, type ReactElement, useState } from 'react';
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesItemTooltip,
  ChartTitle,
  ChartTooltip,
  ChartXAxis,
  ChartXAxisItem,
  ChartYAxis,
  ChartYAxisItem,
} from '@progress/kendo-react-charts';
import type { AuditHistory } from '../../../../../types/__generated/on-premise-solution/api/auditHistory.v1';
import hostDescriptionLocale from '../../../../../utils/i18n/assetLocales/hostDescription.json';
import styles from '../../forms/AddAssetForm.module.scss';
import { getAuditRangePoints, wordDeclension } from '../../../../../lib/axios/helpers';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof hostDescriptionLocale;

function getNumberWithSign(number: number): string {
  return number >= 0 ? `+${number}` : number.toString();
}

function transformAuditData(auditData: AuditHistory['dynamic']):
  | {
      createdAt: Date;
      vulnsCount: number;
      auditId: string;
    }[]
  | undefined {
  return auditData?.map((ad) => ({
    createdAt: new Date(ad.createdAt),
    vulnsCount: ad.vulnsCount,
    auditId: ad.auditId,
  }));
}

export function DynamicAuditChart({
  firstAuditId,
  secondAuditId,
  auditsHistory,
  auditsDynamic,
}: {
  firstAuditId: string | undefined;
  secondAuditId: string | undefined;
  auditsHistory: AuditHistory['history'] | undefined;
  auditsDynamic: AuditHistory['dynamic'] | undefined;
}): ReactElement {
  const [refreshChart, setRefreshChart] = useState(true);

  useEffect(() => {
    setRefreshChart(true);
  }, [firstAuditId, secondAuditId]);

  const dynamic = auditsDynamic
    ? getAuditRangePoints(firstAuditId, secondAuditId, auditsDynamic)
    : undefined;

  if (!auditsHistory || !auditsDynamic || !dynamic || !dynamic.length)
    return <div>{hostDescriptionLocale[currentLocale].changes.auditVulnsChart.noAudits}</div>;

  const dt = transformAuditData(auditsDynamic);

  const [minVulns, maxVulns] = [
    dt ? Math.min(...dt.map((d) => d.vulnsCount)) : undefined,
    dt ? Math.max(...dt.map((d) => d.vulnsCount)) : undefined,
  ];

  const tooltipRender = ({ point }: { point: any }) => {
    return (
      <span>
        {typeof point.dataItem.vulnsCountDiff === 'number' && (
          <div>
            {point.dataItem.vulnsCount} ({getNumberWithSign(point.dataItem.vulnsCountDiff)}){' '}
            {wordDeclension(
              point.dataItem.vulnsCount,
              hostDescriptionLocale[currentLocale].changes.auditVulnsChart.chartMarker.vulnsDiff,
              currentLocale,
            )}
          </div>
        )}
        {typeof point.dataItem.affectedSoftwareCountDiff === 'number' && (
          <div>
            {point.dataItem.affectedSoftwareCount} (
            {getNumberWithSign(point.dataItem.affectedSoftwareCountDiff)}){' '}
            {wordDeclension(
              point.dataItem.affectedSoftwareCount,
              hostDescriptionLocale[currentLocale].changes.auditVulnsChart.chartMarker
                .affectedSoftwareDiff,
              currentLocale,
            )}
          </div>
        )}
        {typeof point.dataItem.softwareCountDiff === 'number' && (
          <div>
            {point.dataItem.softwareCount} ({getNumberWithSign(point.dataItem.softwareCountDiff)}){' '}
            {wordDeclension(
              point.dataItem.softwareCount,
              hostDescriptionLocale[currentLocale].changes.auditVulnsChart.chartMarker.softwareDiff,
              currentLocale,
            )}
          </div>
        )}
        {(typeof point.dataItem.vulnsCountDiff === 'number' ||
          typeof point.dataItem.affectedSoftwareCountDiff === 'number' ||
          typeof point.dataItem.softwareCountDiff === 'number') && (
          <div style={{ height: '20px' }} />
        )}
        <div>
          <a
            style={{ color: 'inherit' }}
            href={`/lk/task/actions/${point.dataItem.auditId}/audit`}
            target="_blank"
            className={styles.new_tab_link}
            rel="noreferrer"
          >
            {hostDescriptionLocale[currentLocale].changes.auditVulnsChart.chartMarker.auditDated}{' '}
            {new Date(point.dataItem.createdAt).toLocaleString()}
          </a>
        </div>
      </span>
    );
  };

  const handleChartRefresh = (chartOptions: any, themeOptions: any, chartInstance: any) => {
    if (refreshChart) {
      chartInstance.setOptions(chartOptions, themeOptions);
      setRefreshChart(false);
    }
  };

  return (
    <Chart
      onRefresh={handleChartRefresh}
      zoomable={{
        mousewheel: {
          lock: 'y',
        },
        selection: {
          lock: 'y',
        },
      }}
      style={{
        marginTop: '20px',
      }}
    >
      <ChartTitle
        text={hostDescriptionLocale[currentLocale].changes.auditVulnsChart.chartName}
        font="400 20px NotoSans, Arial, sans-serif;"
      />
      <ChartTooltip />
      <ChartLegend visible={false} />
      <ChartSeries>
        <ChartSeriesItem
          type="scatterLine"
          data={dynamic?.reverse().map((d, index, array) => {
            const audit = auditsHistory.find((a) => a.auditId === array[index].auditId);

            const auditIndexInHistory = auditsHistory.findIndex((a) => a.auditId === d.auditId);

            const prevAudit =
              auditIndexInHistory + 1 && auditIndexInHistory + 1 < auditsHistory.length
                ? // ? auditsHistory.find((a) => a.auditId === array[index - 1].auditId)
                  // : undefined;
                  auditsHistory[auditIndexInHistory + 1]
                : undefined;

            return {
              auditId: d.auditId,
              createdAt: d.createdAt,
              vulnsCount: d.vulnsCount,
              affectedSoftwareCount: audit?.affectedSoftwareCount,
              softwareCount: audit?.softwareCount,
              vulnsCountDiff:
                audit && prevAudit ? audit.vulnsCount - prevAudit.vulnsCount : audit?.vulnsCount,
              affectedSoftwareCountDiff:
                audit && prevAudit
                  ? audit.affectedSoftwareCount - prevAudit.affectedSoftwareCount
                  : audit?.affectedSoftwareCount,
              softwareCountDiff:
                audit && prevAudit
                  ? audit.softwareCount - prevAudit.softwareCount
                  : audit?.softwareCount,
            };
          })}
          name="vulns"
          xField="createdAt"
          yField="vulnsCount"
          aggregate="count"
        >
          <ChartSeriesItemTooltip render={tooltipRender} />
        </ChartSeriesItem>
      </ChartSeries>
      <ChartXAxis>
        <ChartXAxisItem type="date" labels={{ format: 'm' }} />
      </ChartXAxis>
      <ChartYAxis>
        <ChartYAxisItem
          max={
            maxVulns && minVulns
              ? maxVulns + (Math.ceil((maxVulns - minVulns) * 0.3) || 3)
              : undefined
          }
          min={
            maxVulns && minVulns
              ? minVulns - (Math.ceil((maxVulns - minVulns) * 0.3) || 3)
              : undefined
          }
          type="numeric"
          labels={{ format: '{0:0}' }}
        />
      </ChartYAxis>
    </Chart>
  );
}
