import { useState } from 'react';
import { Grid, GridColumn, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import type { InventorizationResponse } from '../../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import type { InventorizationRouterJuniperResult } from '../../../../../types/__generated/on-premise-solution/api/inventorizationResponse.v1';
import { WrapperMenu } from './WrapperMenu';
import { LAZY_PAGEABLE_DATA } from '../../../../../utils/helpers/constants';
import { StandartCell } from '../../../../common/cells/StandartCell';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function VlanJuniperNetDevice({
  data,
}: {
  data: InventorizationResponse;
}): React.ReactElement {
  const [page, setPage] = useState({ take: 5, skip: 0 });

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  return (
    <WrapperMenu title={locale[currentLocale].vlan.title}>
      {data.type === 'router' && data.class === 'juniper' && (
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <Grid
              data={(data.result.vlans as InventorizationRouterJuniperResult['vlans'])?.slice(
                page.skip,
                page.take + page.skip,
              )}
              pageable={LAZY_PAGEABLE_DATA}
              scrollable="none"
              total={data.result.vlans?.length}
              pageSize={page.take}
              skip={page.skip}
              onPageChange={pageChange}
              className="no-margin-top"
            >
              <GridColumn
                field="interfaces"
                title={locale[currentLocale].vlan.interfaces}
                cell={StandartCell}
              />
              <GridColumn
                field="name"
                title={locale[currentLocale].vlan.name}
                cell={StandartCell}
              />
              <GridColumn field="tag" title={locale[currentLocale].vlan.tag} cell={StandartCell} />
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      )}
    </WrapperMenu>
  );
}
