import { Dialog } from '@progress/kendo-react-dialogs';
import { useQuery } from 'react-query';
import { useHeaderContext } from '../../../hooks/useHeaderContext';
import { getStatus } from '../../../services/license-service';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
// import type { StatusResponse } from '../../../types/__generated/on-premise-solution/api/statusResponse.v1';
import locale from '../../../utils/i18n/help/about.json';
import localeSettings from '../../../utils/i18n/settingsLocales/settings.json';
import type { SystemStatusResponse } from '../../../types/__generated/on-premise-solution/api/systemStatusResponse.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;
export function AboutModal(): React.ReactElement {
  const { setShowAbout } = useHeaderContext();

  const query = useQuery<SystemStatusResponse, ApiError>(['settingsStatus'], () => getStatus(), {
    keepPreviousData: true,
  });

  const { data } = query;

  return (
    <Dialog
      className="small about-modal modal"
      title={locale[currentLocale].title}
      onClose={(): void => setShowAbout(false)}
    >
      <div className="k-form">
        <div className="form-content">
          <table className="column">
            <tbody>
              <tr>
                <td>{localeSettings[currentLocale].summary.version}</td>
                <td>{data?.version ? data.version : '-'}</td>
              </tr>
              <tr>
                <td>{locale[currentLocale].support}</td>
                <td className="about-support">
                  <a href="mailto: support@frodex.ru">support@frodex.ru</a>, +7 (495) 967-65-19
                </td>
              </tr>
              <tr>
                <td>{locale[currentLocale].site}</td>
                <td className="about-site">
                  <a href="https://vulns.io/" target="_blank" rel="noreferrer">
                    https://vulns.io
                  </a>
                </td>
              </tr>
              <tr>
                <td>{locale[currentLocale].developed}</td>
                <td className="company-name">
                  <span>© 2023</span>{' '}
                  <a href="https://www.frodex.ru/" target="_blank" rel="noreferrer">
                    {' '}
                    Фродекс
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Dialog>
  );
}
