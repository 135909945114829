import qs from 'qs';
import type { SortDescriptor } from '@progress/kendo-data-query';
// import { httpInstance } from '../lib/axios/vm-axios';
import type {
  AuditResponse,
  AuditsResponse,
} from '../types/__generated/on-premise-solution/api/auditsResponse.v1';
import type { IPageState } from '../utils/helpers/types';
import { history } from '../utils/history';
import { vmInstance } from '../lib/axios/hooks/useVmAxios';
import { dashboardInstance } from '../lib/axios/hooks/useDashboardAxios';
import type { AuditDashboardResponse } from '../types/__generated/on-premise-solution/api/auditDashboardResponse.v1';

export async function getAudits(
  page: IPageState,
  filterVal: object,
  sort: Array<SortDescriptor>,
): Promise<AuditsResponse> {
  const result = await vmInstance.get<AuditsResponse>('/audits', {
    params: {
      skip: page.skip,
      limit: page.take,
      sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
      sortOrder: sort[0].dir,
      ...filterVal,
    },
    paramsSerializer: (params) => {
      history.replace({
        pathname: `/lk/audit/auditlist?${qs.stringify({
          skip: page.skip,
          limit: page.take,
          sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
          sortOrder: sort[0].dir,
          ...filterVal,
        })}`,
      });

      return qs.stringify(params, { encode: false });
    },
  });

  return result.data;
}
export async function infoAudit(auditId: string | undefined): Promise<AuditResponse> {
  const result = await vmInstance.get<AuditResponse>(`/audits/${auditId}`);

  return result.data;
}

export async function getAuditInfo(auditId: string | undefined): Promise<AuditDashboardResponse> {
  const result = await dashboardInstance.get<AuditDashboardResponse>(`/audits/${auditId}`);

  return result.data;
}
