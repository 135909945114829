import { Grid, GridColumn, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useState } from 'react';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import type { InventorizationResponse } from '../../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { StandartCell } from '../../../../common/cells/StandartCell';
import { DeviceTagInfoCell } from './сells/DeviceTagInfoCell';
import { WrapperMenu } from './WrapperMenu';
import { LAZY_PAGEABLE_DATA } from '../../../../../utils/helpers/constants';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function VlanCommonNetDevice({
  data,
}: {
  data: InventorizationResponse;
}): React.ReactElement {
  const [page, setPage] = useState({ take: 5, skip: 0 });

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  return (
    <WrapperMenu title={locale[currentLocale].vlan.title}>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <Grid
            data={
              (data.type === 'router' &&
                (data.class === 'cisco' || data.class === 'huawei' || data.class === 'eltex')) ||
              (data.type === 'switch' && data.class === 'aruba')
                ? data.result.vlans?.slice(page.skip, page.take + page.skip)
                : null
            }
            // style={{ maxHeight: '304px' }}
            pageable={LAZY_PAGEABLE_DATA}
            total={
              data.type === 'router' &&
              (data.class === 'cisco' || data.class === 'huawei' || data.class === 'eltex')
                ? data.result.vlans?.length
                : 0
            }
            pageSize={page.take}
            skip={page.skip}
            onPageChange={pageChange}
            scrollable="none"
            className="no-margin-top"
          >
            <GridColumn
              field="vlanId"
              title={locale[currentLocale].vlan.vlanId}
              cell={StandartCell}
            />
            <GridColumn field="name" title={locale[currentLocale].vlan.name} cell={StandartCell} />
            {data.class !== 'eltex' && (
              <GridColumn
                field="ports"
                title={locale[currentLocale].vlan.ports}
                cell={StandartCell}
                className="title"
              />
            )}
            {data.class !== 'eltex' && (
              <GridColumn
                field="status"
                title={locale[currentLocale].vlan.status}
                cell={StandartCell}
              />
            )}
            {data.class === 'eltex' && (
              <GridColumn
                field="tagged"
                title={locale[currentLocale].vlan.tagged}
                cell={DeviceTagInfoCell}
              />
            )}
            {data.class === 'eltex' && (
              <GridColumn
                field="untagged"
                title={locale[currentLocale].vlan.untagged}
                cell={StandartCell}
              />
            )}
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </WrapperMenu>
  );
}
