import type { GridCellProps } from '@progress/kendo-react-grid';
import { Button } from '../../../common/baseElements/Button';
import localeAssetGroup from '../../../../utils/i18n/assetLocales/assetGroups.json';
import locale from '../../../../utils/i18n/credentials/credentials.json';
import { useCredential } from '../../../../hooks/components/useCredential';
import { useCredentialContext } from '../../../../hooks/useCredentialContext';
import { getUserData } from '../../../../services/local-storage-service';
import { DeleteConfirmModal } from '../../../common/modal/DeleteConfirmModal';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAssetGroup;

export function CellCommonMenu(props: GridCellProps): React.ReactElement {
  const currentUser = getUserData();

  const { dataItem } = props;

  const { isConfirm, setConfirm, handleConfirm, handleDelete, handleDeleteVault } = useCredential();

  const { setEdit, handleEdit } = useCredentialContext();

  const onEdit = (): void => {
    setEdit(true);
    handleEdit(dataItem.credentialId);
  };

  return (
    <>
      {isConfirm && (
        <DeleteConfirmModal
          name={dataItem.name}
          type={
            document.location.pathname.includes('credentials/list')
              ? 'credential'
              : 'credential-storage'
          }
          onClose={(): void => setConfirm(false)}
          handleDelete={
            document.location.pathname.includes('credentials/list')
              ? (): void => handleDelete(dataItem.credentialId)
              : (): void => handleDeleteVault(dataItem.vaultId)
          }
        />
      )}
      <td className="cell_menu">
        <div className="groups__menu">
          <div className="dropdown__container">
            <span className="k-icon k-i-more-vertical" />
            <div className="dropdown__content">
              {document.location.pathname.includes('credentials/list') && (
                <Button
                  fill="menu"
                  onClick={onEdit}
                  disabled={
                    currentUser?.role === 'user' && currentUser.id !== dataItem.createdBy.id
                  }
                >
                  {locale[currentLocale].menu.btnEdit}
                </Button>
              )}
              <Button
                fill="menu"
                onClick={handleConfirm}
                disabled={
                  (currentUser?.role === 'user' && currentUser.id !== dataItem.createdBy.id) ||
                  (dataItem.vaultId === '0' &&
                    !document.location.pathname.includes('credentials/list'))
                }
              >
                {localeAssetGroup[currentLocale].menu.btnDelete}
              </Button>
            </div>
          </div>
        </div>
      </td>
    </>
  );
}
