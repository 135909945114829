import { Dialog } from '@progress/kendo-react-dialogs';
import type { GridCellProps } from '@progress/kendo-react-grid';
import { useCallback, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Button } from '../../common/baseElements/Button';
import { useAccountModalContext } from '../../../hooks/useAccountModalContext';
import { deleteAccount } from '../../../services/account-service';
import type { Account } from '../../../types/__generated/on-premise-solution/api/account.v1';
import type { AccountDeletionResponse } from '../../../types/__generated/on-premise-solution/api/accountDeletionResponse.v1';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import localeAccount from '../../../utils/i18n/accountLocales/accountList.json';
import { getUserData } from '../../../services/local-storage-service';
import { DeleteConfirmModal } from '../../common/modal/DeleteConfirmModal';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAccount;
export function AccountMenu({ dataItem }: GridCellProps): React.ReactElement {
  const currentUser = getUserData();

  const [isConfirm, setConfirm] = useState(false);

  const { setOpen, setEdit, setAccountData } = useAccountModalContext();

  const queryClient = useQueryClient();

  const editAccount = useCallback(() => {
    setAccountData({
      accountId: dataItem.accountId,
      name: dataItem.name,
    });
    setEdit(true);
    setOpen(true);
  }, [dataItem.accountId, dataItem.name, setEdit, setOpen, setAccountData]);

  const mutation = useMutation<AccountDeletionResponse | null, ApiError, Account['accountId']>(
    (payload) => deleteAccount(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('accountList');
      },
    },
  );

  const handleConfirm = (): void => {
    setConfirm(true);
  };

  const handleDelete = (): void => {
    const { accountId } = dataItem as Account;
    mutation.mutate(accountId);
    setConfirm(false);
  };

  return (
    <>
      {isConfirm && (
        <DeleteConfirmModal
          type="account"
          name={dataItem?.name}
          onClose={(): void => setConfirm(false)}
          handleDelete={handleDelete}
          errorMessage={mutation.error?.message}
        />
      )}
      <td className="cell_menu">
        <div className="groups__menu">
          <div className="dropdown__container">
            <span className="k-icon k-i-more-vertical" />
            <div className="dropdown__content">
              {currentUser?.role !== 'user' && currentUser?.role !== 'reader_user' && (
                <>
                  <Button fill="menu" onClick={editAccount}>
                    {localeAccount[currentLocale].btnEdit}
                  </Button>
                  <Button fill="menu" onClick={handleConfirm}>
                    {localeAccount[currentLocale].btnDelete}
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </td>
    </>
  );
}
