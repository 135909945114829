/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from 'react';
import { Grid, GridColumn as Column, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { Input } from '@progress/kendo-react-inputs';
import { useQuery } from 'react-query';
import { Button } from '@progress/kendo-react-buttons';
import type { SortDescriptor } from '@progress/kendo-data-query';
import { CSVLink } from 'react-csv';
import localeSettings from '../../../utils/i18n/settingsLocales/settings.json';
import type { ConfigResponse } from '../../../types/__generated/on-premise-solution/api/configResponse.v1';
import type { ChannelUpdatesResponse } from '../../../types/__generated/on-premise-solution/api/channelUpdatesResponse.v1';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getChannels } from '../../../services/license-service';
import { DATA_CACHE_CACHETIME, DATA_CACHE_STALETIME } from '../../../utils/helpers/constants';
import { ChannelTable } from './ChannelTable';
import styles from './Settings.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

export function UpdateTab({
  serverUpdateData,
  dataUpdater,
}: {
  serverUpdateData: ConfigResponse['cloud']['api'] | undefined;
  dataUpdater: ConfigResponse['data-updater'] | undefined;
}): React.ReactElement {
  const query = useQuery<ChannelUpdatesResponse, ApiError>(
    ['settingsUpdateChannels'],
    () => getChannels(),
    {
      staleTime: DATA_CACHE_STALETIME,
      cacheTime: DATA_CACHE_CACHETIME,
      keepPreviousData: true,
    },
  );

  const { data } = query;

  const [url, setUrl] = useState<string>(serverUpdateData?.url ? serverUpdateData.url : '');

  const [apiKey, setApiKey] = useState<string>(serverUpdateData?.key ? serverUpdateData?.key : '');

  const [sort, setSort] = useState<SortDescriptor[]>([]);

  const [schedule, setSchedule] = useState<string>(
    dataUpdater?.scheduler?.cron ? dataUpdater.scheduler.cron : '',
  );

  const [bulkwriteSize, setBulkwriteSize] = useState<string>(
    dataUpdater?.other?.mongodb_bulkwrite_size ? dataUpdater.other.mongodb_bulkwrite_size : '',
  );

  const [csvData, setCsvData] = useState([]);

  useEffect(() => {
    if (serverUpdateData?.url) setUrl(serverUpdateData.url);
  }, [serverUpdateData]);

  useEffect(() => {
    if (serverUpdateData?.key) setApiKey(serverUpdateData.key);
  }, [serverUpdateData?.key]);

  useEffect(() => {
    if (dataUpdater?.scheduler?.cron) setSchedule(dataUpdater.scheduler.cron);
  }, [dataUpdater?.scheduler?.cron]);

  useEffect(() => {
    if (dataUpdater?.other?.mongodb_bulkwrite_size)
      setBulkwriteSize(dataUpdater.other.mongodb_bulkwrite_size);
  }, [dataUpdater?.other?.mongodb_bulkwrite_size]);

  useEffect(() => {
    if (data) {
      const cData: any = [];
      Object.values(data).forEach((channel) => {
        if (channel.children) cData.push(...channel.children);
        else
          cData.push({
            name: channel.group,
            type: channel.type,
            datetime: channel.datetime,
            modifiedAt: channel.modifiedAt,
            description: channel.description,
          });
      });
      setCsvData(cData);
    }
  }, [data]);

  const exportJsonData = (): void => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(data, null, '\t'),
    )}`;
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = 'UpdateChannels.json';

    link.click();
  };

  return (
    <>
      <div className={styles.update}>
        <div className="update_server">
          <h4>{localeSettings[currentLocale].formUpdate.updateServer}</h4>
          <div className={styles.update_fields}>
            <div className={styles.update_field}>
              <span>{localeSettings[currentLocale].formUpdate.url}</span>
              <Input
                disabled
                type="text"
                value={url}
                onChange={(e: any): void => setUrl(e.target.value)}
                // disabled
              />
            </div>
            <div className={styles.update_field}>
              <span>{localeSettings[currentLocale].formUpdate.api}</span>
              <Input
                disabled
                type="text"
                value={apiKey}
                onChange={(e: any): void => setApiKey(e.target.value)}
                // disabled
              />
            </div>
          </div>
        </div>
        {data && (
          <div className={styles.update_channels}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h4>{localeSettings[currentLocale].formUpdate.channels}</h4>
              <div className={styles.buttons_group}>
                <Button className="k-buton k-button-flat json-export" onClick={exportJsonData}>
                  {localeSettings[currentLocale].formUpdate.channelsForm.jsonExport}
                </Button>
                <Button className="k-buton k-button-flat csv-export">
                  <CSVLink filename="UpdateChannels.csv" data={csvData}>
                    {localeSettings[currentLocale].formUpdate.channelsForm.csvExport}
                  </CSVLink>
                </Button>
              </div>
            </div>
            <Grid
              data={null}
              sortable
              sort={sort}
              onSortChange={(e: GridSortChangeEvent): void => {
                setSort(e.sort);
              }}
              className={`${styles.channels_header} no-margin-top`}
              scrollable="none"
            >
              <Column
                field="name"
                title={localeSettings[currentLocale].formUpdate.channelsForm.code}
                // className="channel-name-row"
                width="260px"
              />
              <Column
                field="type"
                title={localeSettings[currentLocale].formUpdate.channelsForm.type}
                width="100px"
              />
              <Column
                field="datetime"
                title={localeSettings[currentLocale].formUpdate.channelsForm.release}
                width="228px"
              />
              <Column
                field="modifiedAt"
                title={localeSettings[currentLocale].formUpdate.channelsForm.modified}
                width="248px"
              />
              <Column
                field="description"
                title={localeSettings[currentLocale].formUpdate.channelsForm.description}
              />
            </Grid>
            {Object.values(data).map((channel) => (
              <ChannelTable data={channel} sort={sort} />
            ))}
          </div>
        )}
        <div className={`${styles.update_fields} ${styles.aftertable}`}>
          <div className={styles.update_field}>
            <span className="head">{localeSettings[currentLocale].formUpdate.schedule}</span>
            <Input
              disabled
              type="text"
              value={schedule}
              onChange={(e: any): void => setSchedule(e.target.value)}
            />
            {/* <CronCalculator maxWidth='588px' /> */}
            <span className="comment">{localeSettings[currentLocale].formUpdate.format}</span>
          </div>
          <div className={styles.update_field}>
            <span className="head">{localeSettings[currentLocale].formUpdate.bulwriteSize}</span>
            <Input
              type="text"
              value={bulkwriteSize}
              onChange={(e: any): void => setBulkwriteSize(e.target.value)}
              disabled
            />
          </div>
        </div>
      </div>
      {/* <Button
        onClick={(): void => {
          patchConfig({
            'cloud.api.url': url || null,
            'cloud.api.key': apiKey || null,
            'data-updater.scheduler.cron': schedule || null,
            // 'data-updater.other.mongodb_bulkwrite_size': bulkwriteSize,
          });
        }}
      >
        Save config
      </Button> */}
    </>
  );
}
