import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
} from '@progress/kendo-react-charts';
import locale from '../../../../utils/i18n/dashboardLocale/dashboardLayout.json';
import type { AssetHostsAuditType } from '../../../../types/__generated/on-premise-solution/api/assetHostsAuditType.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

const labelContent = (props: any): string => {
  return `${props.dataItem.category}: ${props.dataItem.value}`;
};

export function AuditType({ data }: { data: AssetHostsAuditType | undefined }): React.ReactElement {
  const series = [
    {
      category: `${locale[currentLocale].agent} (${data?.totalWithActivatedAgents})`,
      // value: data?.withActivatedAgents,
      value: data?.totalWithActivatedAgents,
      color: '#2196f3',
    },
    {
      category: `${locale[currentLocale].agentLess} (${data?.totalWithCredentials})`,
      // value: data?.withCredentials,
      value: data?.totalWithCredentials,
      color: '#ffc107',
    },
  ];

  return (
    <Chart>
      {/* <ChartTitle text="World Population by Broad Age Groups" /> */}
      <ChartLegend position="bottom" />
      <ChartTooltip
        render={(e: any) => {
          if (e.point?.category) return <span>{e.point.category}</span>;

          return '';
        }}
      />
      <ChartSeries>
        <ChartSeriesItem
          type="donut"
          data={series}
          field="value"
          categoryField="category"
          tooltip={{ visible: true }}
          // labels={{ visible: true, content: labelContent }}
        />
      </ChartSeries>
    </Chart>
  );
}
