import { Checkbox } from '@progress/kendo-react-inputs';
import localeSettings from '../../../../utils/i18n/settingsLocales/settings.json';
import styles from '../WhiteListTabEl.module.scss';
import { useWhiteListContext } from '../../../../hooks/useWhiteListContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;
export function WhiteListFormAssetType(): React.ReactElement {
  const { assetType, setAssetType } = useWhiteListContext();

  return (
    <div className={`form-block ${styles.formFieldWidth}`}>
      <div className={styles.assetType}>
        <div className={styles.assetTypeTitle}>
          <span>{localeSettings[currentLocale].whiteList.titleAssetType}</span>
        </div>
        <div className={styles.assetTypeContent}>
          <Checkbox
            label={localeSettings[currentLocale].apiKey.form.checkbox.host}
            checked={assetType?.host}
            onChange={(): void =>
              setAssetType((prevState) => prevState && { ...prevState, host: !assetType?.host })
            }
          />
          <Checkbox
            label={localeSettings[currentLocale].apiKey.form.checkbox.image}
            checked={assetType?.image}
            onChange={(): void =>
              setAssetType((prevState) => prevState && { ...prevState, image: !assetType?.image })
            }
          />
          <Checkbox
            label={localeSettings[currentLocale].apiKey.form.checkbox.router}
            checked={assetType?.router}
            onChange={(): void =>
              setAssetType((prevState) => prevState && { ...prevState, router: !assetType?.router })
            }
          />
          <Checkbox
            label={localeSettings[currentLocale].apiKey.form.checkbox.switch}
            checked={assetType?.switch}
            onChange={(): void =>
              setAssetType((prevState) => prevState && { ...prevState, switch: !assetType?.switch })
            }
          />
          <Checkbox
            label={localeSettings[currentLocale].apiKey.form.checkbox.firewall}
            checked={assetType?.firewall}
            onChange={(): void =>
              setAssetType(
                (prevState) => prevState && { ...prevState, firewall: !assetType?.firewall },
              )
            }
          />
          <Checkbox
            label={localeSettings[currentLocale].apiKey.form.checkbox.allAssetType}
            checked={assetType?.all}
            onChange={(): void =>
              setAssetType(
                (prevState) =>
                  prevState && {
                    ...prevState,
                    host: !assetType?.all,
                    image: !assetType?.all,
                    router: !assetType?.all,
                    switch: !assetType?.all,
                    firewall: !assetType?.all,
                    all: !assetType?.all,
                  },
              )
            }
          />
        </div>
      </div>
    </div>
  );
}
