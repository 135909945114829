import { DatePicker, DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { useState, type ReactElement } from 'react';
import { Button } from '../../../common/baseElements/Button';
import styles from '../forms/AddAssetForm.module.scss';
import localeNewTask from '../../../../utils/i18n/taskLocale/newTask.json';
import hostDescriptionLocale from '../../../../utils/i18n/assetLocales/hostDescription.json';
import type { AuditDifferenceResponse } from '../../../../types/__generated/on-premise-solution/api/auditDifferenceResponse.v1';
import type { InventorizationDifference } from '../../../../types/__generated/on-premise-solution/api/inventorizationDifference.v1';
import { NearestInfo } from './NearestInfo';
import type { AuditHistory } from '../../../../types/__generated/on-premise-solution/api/auditHistory.v1';
import { DynamicAuditChart } from './Audit/DynamicAuditsChart';
import { getAuditRangePoints } from '../../../../lib/axios/helpers';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof hostDescriptionLocale;

export function DiffByDateTab({
  type,
  startDate,
  setStartDate,
  finishDate,
  setFinishDate,
  data,
  isLoading,
  onClick,
  auditsData,
  isAuditGraphic,
  setAuditGraphic,
  error,
  localError,
}: {
  type: 'inventorization' | 'audit';
  startDate: Date | null;
  setStartDate?: React.Dispatch<React.SetStateAction<Date | null>>;
  finishDate: Date | null;
  setFinishDate?: React.Dispatch<React.SetStateAction<Date | null>>;
  data: AuditDifferenceResponse | InventorizationDifference | undefined;
  isLoading: boolean;
  auditsData?: AuditHistory;
  isAuditGraphic?: boolean;
  setAuditGraphic?: React.Dispatch<React.SetStateAction<boolean>>;
  onClick: (fd: string | undefined, sd: string | undefined) => void;
  error: ApiError | null;
  localError?: string | null;
}): ReactElement {
  const fd =
    data && 'sourceAudits' in data
      ? data.sourceAudits.firstAudit?.createdAt
      : data?.sourceInventorizations?.firstInventorization?.createdAt;
  const sd =
    data && 'sourceAudits' in data
      ? data.sourceAudits.secondAudit?.createdAt
      : data?.sourceInventorizations?.secondInventorization?.createdAt;

  const [firstDate, setFirstDate] = useState<Date | null | undefined>(
    fd ? new Date(fd) : undefined,
  );
  const [secondDate, setSecondDate] = useState<Date | null | undefined>(
    sd ? new Date(sd) : undefined,
  );

  const changeStartDate = (e: DatePickerChangeEvent): void => {
    const date = e.target.value;
    if (finishDate && date && date > finishDate) {
      setFirstDate(secondDate);
      setSecondDate((prev) => {
        if (
          date?.getHours() === prev?.getHours() &&
          date?.getMinutes() === prev?.getMinutes() &&
          date?.getSeconds() === prev?.getSeconds()
        )
          date?.setHours(0, 0, 0, 0);

        return date;
      });
    } else
      setFirstDate((prev) => {
        if (
          date?.getHours() === prev?.getHours() &&
          date?.getMinutes() === prev?.getMinutes() &&
          date?.getSeconds() === prev?.getSeconds()
        )
          date?.setHours(0, 0, 0, 0);

        return date;
      });
  };

  const changeFinishDate = (e: DatePickerChangeEvent): void => {
    const date = e.target.value;
    if (startDate && date && date < startDate) {
      setSecondDate(startDate);
      setFirstDate((prev) => {
        if (
          date?.getHours() === prev?.getHours() &&
          date?.getMinutes() === prev?.getMinutes() &&
          date?.getSeconds() === prev?.getSeconds()
        )
          date?.setHours(0, 0, 0, 0);

        return date;
      });
    } else
      setSecondDate((prev) => {
        if (
          date?.getHours() === prev?.getHours() &&
          date?.getMinutes() === prev?.getMinutes() &&
          date?.getSeconds() === prev?.getSeconds()
        )
          date?.setHours(0, 0, 0, 0);

        return date;
      });
  };

  return (
    <>
      <div>
        <div>
          <div
            style={{
              display: 'flex',
              gap: '20px',
              alignItems: 'flex-end',
            }}
          >
            <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-end' }}>
              <div className={styles.rangeReg}>
                <DatePicker
                  value={firstDate}
                  format="dd.MM.yyyy HH:mm:ss"
                  focusedDate={startDate || new Date()}
                  name="startAt"
                  onChange={changeStartDate}
                  placeholder={localeNewTask[currentLocale].form.todoActions.specifyTheDate}
                  max={secondDate || new Date()}
                  // max={new Date()}
                  label={hostDescriptionLocale[currentLocale].changes.startDate}
                  validationMessage={
                    startDate && finishDate && startDate >= finishDate
                      ? hostDescriptionLocale[currentLocale].changes.startDateHigherError
                      : hostDescriptionLocale[currentLocale].changes.invalidDateError
                  }
                  required
                />
              </div>
              <div className={styles.rangeReg}>
                <DatePicker
                  value={secondDate}
                  format="dd.MM.yyyy HH:mm:ss"
                  focusedDate={finishDate || new Date()}
                  name="finishAt"
                  onChange={changeFinishDate}
                  placeholder={localeNewTask[currentLocale].form.todoActions.specifyTheDate}
                  max={new Date()}
                  label={hostDescriptionLocale[currentLocale].changes.finishDate}
                  validationMessage={hostDescriptionLocale[currentLocale].changes.invalidDateError}
                  required
                />
              </div>
              <div className="k-form-buttons">
                <Button
                  className="action"
                  onClick={() => onClick(firstDate?.toISOString(), secondDate?.toISOString())}
                >
                  {hostDescriptionLocale[currentLocale].changes.btnShowChanges}
                </Button>
              </div>
            </div>
            {setAuditGraphic && (
              <Button
                title={hostDescriptionLocale[currentLocale].changes.auditVulnsChart.chartName}
                style={{
                  padding: '8px',
                }}
                icon="graphic"
                onClick={(): void => setAuditGraphic((prev) => !prev)}
              />
            )}
          </div>
          <div
            style={{
              display: 'flex',
              gap: '20px',
              marginTop: '16px',
              fontSize: '13px',
            }}
          >
            {!data && (
              <>
                <NearestInfo
                  type={type}
                  className={`${styles.rangeReg} ${styles.audit_info}`}
                  isLoading={isLoading}
                  data={undefined}
                />
                <NearestInfo
                  type={type}
                  className={`${styles.rangeReg} ${styles.audit_info}`}
                  isLoading={isLoading}
                  data={undefined}
                />
              </>
            )}
            {data && 'sourceAudits' in data && (
              <>
                <NearestInfo
                  type={type}
                  className={`${styles.rangeReg} ${styles.audit_info}`}
                  isLoading={isLoading}
                  data={data.sourceAudits.firstAudit}
                />
                <NearestInfo
                  type={type}
                  className={`${styles.rangeReg} ${styles.audit_info}`}
                  isLoading={isLoading}
                  data={data.sourceAudits.secondAudit}
                />
              </>
            )}
            {data && 'sourceInventorizations' in data && (
              <>
                <NearestInfo
                  type={type}
                  className={`${styles.rangeReg} ${styles.audit_info}`}
                  isLoading={isLoading}
                  data={data.sourceInventorizations?.firstInventorization}
                />
                <NearestInfo
                  type={type}
                  className={`${styles.rangeReg} ${styles.audit_info}`}
                  isLoading={isLoading}
                  data={data.sourceInventorizations?.secondInventorization}
                />
              </>
            )}
          </div>
        </div>
        {isAuditGraphic && data && 'sourceAudits' in data && !localError && !error && (
          <DynamicAuditChart
            firstAuditId={data.sourceAudits.firstAudit?.auditId}
            secondAuditId={data.sourceAudits.secondAudit?.auditId}
            auditsHistory={auditsData?.history}
            auditsDynamic={auditsData?.dynamic}
          />
        )}
      </div>
      {localError && (
        <div style={{ color: 'rgb(243, 23, 0)', marginTop: '12px' }}>{localError}</div>
      )}
      {error && error.code === '404' && (
        <div style={{ color: 'rgb(243, 23, 0)', marginTop: '12px' }}>
          {hostDescriptionLocale[currentLocale].changes.errors.noDataTime}
        </div>
      )}
    </>
  );
}
