import { NotificationGroup, Notification } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import localeDashboardForm from '../../../../utils/i18n/dashboardLocale/addDashboardForm.json';

export function HostNotification({
  copied,
  marginTop,
  right,
}: {
  copied: boolean;
  marginTop?: string;
  right?: string;
}): React.ReactElement {
  return (
    <NotificationGroup
      className="copied-container"
      style={{
        // top: '10px',
        right: '48px',
        alignItems: 'flex-start',
        flexWrap: 'wrap-reverse',
        position: 'absolute',
        zIndex: '1999',
      }}
    >
      <Fade>
        {copied && (
          <Notification type={{ style: 'info' }}>
            <span>{localeDashboardForm['ru-RU'].notificationCopy}</span>
          </Notification>
        )}
      </Fade>
    </NotificationGroup>
  );
}
