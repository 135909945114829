import { TextFilter, Operators } from '@progress/kendo-react-data-tools';
import { FilterActionSelect } from '../../components/common/filterComponents/FilterActionSelect';
import { FilterAllStatusSelect } from '../../components/common/filterComponents/FilterAllStatusSelect';
import { FilterCalendar } from '../../components/common/filterComponents/FilterCalendar';
import { FilterFileFormatSelect } from '../../components/common/filterComponents/FilterFileFormatSelect';
import { FilterInputInteger } from '../../components/common/filterComponents/FilterInputInteger';
import { FilterLogLevel } from '../../components/common/filterComponents/FilterLogLevelSelect';
import { FilterStatusAndType } from '../../components/common/filterComponents/FilterStatusAndType';
import {
  FILTER_CUSTOM_OPERATORS,
  FILTER_OPERATORS,
  FILTER_TASK_OPERATORS,
  FILTER_TASK_OPERATORS_TEMP,
} from './constant-filter-operators';

export const FILTER_LIST_TASK = {
  'ru-RU': [
    {
      name: 'status',
      label: 'Статус задачи',
      filter: FilterStatusAndType,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'startedAt',
      label: 'Последний запуск',
      // filter: TextFilter,
      // filter: FilterDate,
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'name',
      label: 'Имя задачи',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'scope.assets.hostname',
      label: 'Область применимости(активы)',
      filter: TextFilter,
      // operators: FILTER_TASK_OPERATORS.text,
      operators: FILTER_TASK_OPERATORS_TEMP.text,
    },
    {
      name: 'scope.assetGroups.name',
      label: 'Область применимости(группы)',
      filter: TextFilter,
      // operators: FILTER_TASK_OPERATORS.text,
      operators: Operators.text,
    },
    {
      name: 'actions',
      label: 'Действие',
      filter: FilterActionSelect,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
  ],
  'en-EN': [
    {
      name: 'status',
      label: 'Task status',
      filter: FilterStatusAndType,
      operators: Operators.text,
    },
    {
      name: 'startedAt',
      label: 'Last start',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'name',
      label: 'Task Name',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'scope.assets.hostname',
      label: 'Asset scope(assets)',
      filter: TextFilter,
      // operators: FILTER_TASK_OPERATORS.text,
      operators: FILTER_TASK_OPERATORS_TEMP.text,
    },
    {
      name: 'scope.assetGroups.name',
      label: 'Asset scope(groups)',
      filter: TextFilter,
      // operators: FILTER_TASK_OPERATORS.text,
      operators: FILTER_TASK_OPERATORS_TEMP.text,
    },
    {
      name: 'actions',
      label: 'Actions',
      filter: FilterActionSelect,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
  ],
};

export const FILTER_TASK_HISTORY = {
  'ru-RU': [
    {
      name: 'status',
      label: 'Статус задачи',
      filter: FilterStatusAndType,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'startedAt',
      label: 'Время запуска',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'actions',
      label: 'Действие',
      filter: FilterActionSelect,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'taskName',
      label: 'Имя задачи',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'scope.assets.hostname',
      label: 'Область применимости (активы)',
      filter: TextFilter,
      // operators: FILTER_TASK_OPERATORS.text,
      operators: FILTER_TASK_OPERATORS_TEMP.text,
    },
    {
      name: 'scope.assetGroups.name',
      label: 'Область применимости (группы)',
      filter: TextFilter,
      // operators: FILTER_TASK_OPERATORS.text,
      operators: FILTER_TASK_OPERATORS_TEMP.text,
    },
  ],
  'en-EN': [
    {
      name: 'status',
      label: 'Task status',
      filter: FilterStatusAndType,
      operators: Operators.text,
    },
    {
      name: 'startedAt',
      label: 'Start time',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'actions',
      label: 'Actions',
      filter: FilterActionSelect,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'taskName',
      label: 'Task Name',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'scope.assets.hostname',
      label: 'Asset scope (assets)',
      filter: TextFilter,
      // operators: FILTER_TASK_OPERATORS.text,
      operators: FILTER_TASK_OPERATORS_TEMP.text,
    },
    {
      name: 'scope.assetGroups.name',
      label: 'Asset scope (groups)',
      filter: TextFilter,
      // operators: FILTER_TASK_OPERATORS.text,
      operators: FILTER_TASK_OPERATORS_TEMP.text,
    },
  ],
};
export const FILTER_HOST_TASK_HISTORY = {
  'ru-RU': [
    {
      name: 'status',
      label: 'Статус задачи',
      filter: FilterAllStatusSelect,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'startedAt',
      label: 'Время запуска',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'actions',
      label: 'Действие',
      filter: FilterActionSelect,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'taskName',
      label: 'Имя задачи',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
  ],
  'en-EN': [
    {
      name: 'status',
      label: 'Task status',
      filter: FilterAllStatusSelect,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'startedAt',
      label: 'Start time',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'actions',
      label: 'Actions',
      filter: FilterActionSelect,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'taskName',
      label: 'Task Name',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
  ],
};

export const FILTER_RESULT_TASK = {
  'ru-RU': [
    {
      name: 'status',
      label: 'Статус',
      filter: FilterAllStatusSelect,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'startedAt',
      label: 'Старт',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'lastActivity',
      label: 'Последняя активность',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'type',
      label: 'Действие',
      filter: FilterActionSelect,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
  ],
  'en-EN': [
    {
      name: 'status',
      label: 'Status',
      filter: FilterAllStatusSelect,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'startedAt',
      label: 'Start',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'lastActivity',
      label: 'Last activity',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'type',
      label: 'Actions',
      filter: FilterActionSelect,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
  ],
};

export const FILTER_ARIFACTS = {
  'ru-RU': [
    {
      name: 'fileFormat',
      label: 'Тип файла',
      filter: FilterFileFormatSelect,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'createdAt',
      label: 'Время создания',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'fileName',
      label: 'Имя файла',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
  ],
  'en-EN': [
    {
      name: 'fileFormat',
      label: 'File type',
      filter: FilterFileFormatSelect,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'createdAt',
      label: 'Time of creation',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'fileName',
      label: 'File name',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
  ],
};

export const FILTER_LOGS = {
  'ru-RU': [
    {
      name: 'createdAt',
      label: 'Время создания',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'level',
      label: 'Уровень',
      filter: FilterLogLevel,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'asset.latestInventorization.hostname',
      label: 'Актив',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'taskAction.type',
      label: 'Действие',
      filter: FilterActionSelect,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
  ],
  'en-EN': [
    {
      name: 'createdAt',
      label: 'Сreated at',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'level',
      label: 'Level',
      filter: FilterLogLevel,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'asset.latestInventorization.hostname',
      label: 'Asset',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'taskAction.type',
      label: 'Actions',
      filter: FilterActionSelect,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
  ],
};

export const FILTER_ALL_TASK_LOGS = {
  'ru-RU': [
    {
      name: 'createdAt',
      label: 'Время создания',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'level',
      label: 'Уровень',
      filter: FilterLogLevel,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'asset.latestInventorization.hostname',
      label: 'Актив',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'taskExecution.taskName',
      label: 'Имя задачи',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'taskAction.type',
      label: 'Действие',
      filter: FilterActionSelect,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
  ],
  'en-EN': [
    {
      name: 'createdAt',
      label: 'Сreated at',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'level',
      label: 'Level',
      filter: FilterLogLevel,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'asset.latestInventorization.hostname',
      label: 'Asset',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'taskExecution.taskName',
      label: 'Task name',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'taskAction.type',
      label: 'Actions',
      filter: FilterActionSelect,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
  ],
};
export const FILTER_ALL_USER_LOGS = {
  'ru-RU': [
    {
      name: 'createdAt',
      label: 'Дата и время',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'user.name',
      label: 'Пользователь',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
  ],
  'en-EN': [
    {
      name: 'createdAt',
      label: 'Date and time',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'user.name',
      label: 'User',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
  ],
};
export const FILTER_SECURITY_VULNS = {
  'ru-RU': [
    {
      name: 'bulletinId',
      label: 'ID',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'aliasId',
      label: 'Альтернативный ID',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'totalHostAssets',
      label: 'Количество хостов',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'totalImageAssets',
      label: 'Количество образов',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'totalNetworkDeviceAssets',
      label: 'Количество сетевых устройств',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
  ],
  'en-EN': [
    {
      name: 'bulletinId',
      label: 'ID',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'aliasId',
      label: 'Alternative ID',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'totalHostAssets',
      label: 'Total hosts',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'totalImageAssets',
      label: 'Total images',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'totalNetworkDeviceAssets',
      label: 'total network device',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
  ],
};

export const FILTER_ALL_BULLETINS = {
  'ru-RU': [
    {
      name: 'bulletinId',
      label: 'ID',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'aliases',
      label: 'Альтернативные ID',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'metrics.cwe.name',
      label: 'CWE name',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'metrics.cwe.id',
      label: 'CWE id',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'date.published',
      label: 'Опубликована',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
  ],
  'en-EN': [
    {
      name: 'bulletinId',
      label: 'ID',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'aliases',
      label: 'Alternative IDs',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'metrics.cwe.name',
      label: 'CWE name',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'metrics.cwe.id',
      label: 'CWE id',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'date.published',
      label: 'Published date',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
  ],
};

export const FILTER_VDB = {
  'ru-RU': [
    {
      name: 'bulletinId',
      label: 'ID',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'date.published',
      label: 'Дата создания',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
  ],
  'en-EN': [
    {
      name: 'bulletinId',
      label: 'ID',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'date.published',
      label: 'Published at',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
  ],
};

export const FILTER_VULNS_RELATED = {
  'ru-RU': [
    {
      name: 'bulletinId',
      label: 'ID',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
  ],
  'en-EN': [
    {
      name: 'bulletinId',
      label: 'ID',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
  ],
};
