import type { GridCellProps } from '@progress/kendo-react-grid';
import type { ILinSoftList } from '../../../../../utils/helpers/types';

export function CellUrlSoftware(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { url }: ILinSoftList = dataItem;

  return <td title={url || ''}>{url ? <span>{url}</span> : <span>-</span>}</td>;
}
