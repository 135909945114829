/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@progress/kendo-react-common';
import { Button } from '@progress/kendo-react-buttons';
import { useQuery, useQueryClient } from 'react-query';
import { ErrorBoundary } from 'react-error-boundary';
import localeTaskDescription from '../../../utils/i18n/taskLocale/taskDescription.json';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getTaskExecutionInfo } from '../../../services/task-service';
import { handleError } from '../../../utils/errors';
import { useNotificationContext } from '../../../hooks/useNotificationContext';
import { MetaBlock } from './taskDescriptionComponents/MetaBlock';
import { TabsTaskDescription } from './taskDescriptionComponents/TabsTaskDescription';
import type { TaskExecutionDashboardResponse } from '../../../types/__generated/on-premise-solution/api/taskExecutionsDashboardResponse.v1';
import { useRepeatTask } from '../../../hooks/components/useRepeatTask';
import { useCreateTask } from '../../../hooks/components/useCreateTask';
import { BoundaryErrorComponent } from '../../common/BoundaryErrorComponent';
import { getUserData } from '../../../services/local-storage-service';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTaskDescription;

export function TaskDescription(): React.ReactElement {
  const currentUser = getUserData();

  const queryClient = useQueryClient();

  // const { isOpenDialog } = useTaskContext();

  const { createNotification } = useNotificationContext();

  const { taskExecutionId } = useParams<{ taskExecutionId: string }>();

  const query = useQuery<TaskExecutionDashboardResponse, ApiError>(
    ['taskExecutionId', taskExecutionId],
    () => getTaskExecutionInfo(taskExecutionId),
    {
      keepPreviousData: true,
    },
  );

  const { data } = query;

  const checkAgentUpdate = data?.actions && 'agent_update' in data.actions;

  const checkPackageUpdate = data?.actions && 'package_update' in data.actions;

  useEffect(() => {
    if (query.error) {
      handleError(query.error, createNotification);
    }
  }, [query.isError, query.error, createNotification]);

  const handleRefresh = useCallback((): void => {
    queryClient.invalidateQueries('taskExecutionId');
    queryClient.invalidateQueries('taskActions');
    queryClient.invalidateQueries('logs');
    queryClient.invalidateQueries('artifacts');
  }, [queryClient]);

  const registries =
    query.data?.taskType === 'image' && query.data?.scope.registries
      ? query.data?.scope.registries
      : undefined;

  const { repeatTaskDescription } = useRepeatTask();

  const { createReportTaskDescription } = useCreateTask();

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      <div className="taskdescription">
        <div className="common-header-page">
          <Typography.h3 className="title">
            {/* <span className="k-i-computer" /> {localeTaskDescription[currentLocale].title}{' '} */}
            {localeTaskDescription[currentLocale].title} {query.data?.taskName}
          </Typography.h3>
          <div className="topactions">
            <Button onClick={handleRefresh} disabled={data?.status !== 'processing'}>
              {localeTaskDescription[currentLocale].btnRefresh}
            </Button>
            <Button
              onClick={(): void => repeatTaskDescription(data, registries)}
              disabled={
                query.data?.status === 'processing' ||
                (checkAgentUpdate && currentUser?.role === 'reader_user') ||
                (checkPackageUpdate && currentUser?.role === 'reader_user') ||
                (checkAgentUpdate && currentUser?.role === 'user')
              }
            >
              {localeTaskDescription[currentLocale].btnRepeat}
            </Button>
            <Button onClick={(): void => createReportTaskDescription(data)}>
              {localeTaskDescription[currentLocale].btnCreateReport}
            </Button>
          </div>
        </div>
        {data && <MetaBlock data={data} />}
        <TabsTaskDescription />
      </div>
    </ErrorBoundary>
  );
}
