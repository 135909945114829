import type { GridCellProps } from '@progress/kendo-react-grid';
import type { AssetImageDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/assetsImagesDashboardResponse.v1';
import localeHost from '../../../../../utils/i18n/assetLocales/hostList.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHost;

export function VulnsCount(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;
  const { latestAudit }: AssetImageDashboardResponse = dataItem;

  return (
    <td className="number">
      {latestAudit ? (
        <span>{latestAudit.vulnerabilities.count}</span>
      ) : (
        <span>{localeHost[currentLocale].form.waitingForData}</span>
      )}
    </td>
  );
}
