/* eslint-disable no-nested-ternary */
import { NavLink } from 'react-router-dom';
import styles from '../../../TaskDescription.module.scss';
import localeVmTask from '../../../../../../utils/i18n/taskLocale/vmTask.json';
import type { AuditDashboardResponse } from '../../../../../../types/__generated/on-premise-solution/api/auditDashboardResponse.v1';

export function SecondColumnImage({
  currentLocale,
  auditInfo,
}: {
  currentLocale: 'ru-RU' | 'en-EN';
  auditInfo: AuditDashboardResponse | undefined;
}): React.ReactElement {
  return (
    <table className="column">
      <tbody>
        <tr>
          <td>
            <span>{localeVmTask[currentLocale].summary.imageName}</span>
          </td>
          {auditInfo ? (
            <td
              style={{ fontWeight: '700' }}
              title={
                auditInfo.asset.type === 'image' && auditInfo.asset.imageNames[0]
                  ? auditInfo.asset.imageNames[0]
                  : ''
              }
            >
              {auditInfo.asset.type === 'image' && auditInfo.asset.imageNames[0]}
            </td>
          ) : (
            <td>-</td>
          )}
        </tr>
        <tr>
          <td>
            <span>{localeVmTask[currentLocale].summary.assetId}</span>
          </td>
          <td title={auditInfo?.assetId}>
            <NavLink
              to={`/lk/assets/images/${auditInfo?.assetId}`}
              // onClick={closeVulnModal}
            >
              {auditInfo?.assetId}
            </NavLink>
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeVmTask[currentLocale].summary.layerContent}</span>
          </td>
          {auditInfo && auditInfo.asset.type === 'image' && auditInfo.layer ? (
            <td className={styles.layerContent} title={auditInfo.layer.layerContent}>
              {auditInfo.layer.layerContent}
            </td>
          ) : (
            <td>-</td>
          )}
        </tr>
        <tr>
          <td>
            <span>{localeVmTask[currentLocale].summary.system}</span>
          </td>
          <td>
            {auditInfo &&
            auditInfo.asset.type === 'image' &&
            auditInfo.asset.latestInventorization?.os &&
            'name' in auditInfo.asset.latestInventorization.os ? (
              <span>
                {auditInfo.asset.latestInventorization.os.name[0].toUpperCase()}
                {auditInfo.asset.latestInventorization.os?.name.slice(1)}{' '}
                {auditInfo.asset.latestInventorization?.os.version}
              </span>
            ) : (
              <span>-</span>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
