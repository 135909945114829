/* eslint-disable no-nested-ternary */
import { useMemo } from 'react';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { getPastTime } from '../../../../../utils/helpers/settings-helpers';
import { WrapperMenu } from '../../../Task/taskIDetails/iDetailsComponents/WrapperMenu';
import type { InventorizationDifference } from '../../../../../types/__generated/on-premise-solution/api/inventorizationDifference.v1';
import {
  prepareDiffObjectData,
  type DiffFilterTypes,
  defineWrapperStatuses,
} from '../../../../../utils/helpers/difference-modal-helper';
import { DiffInventoryNetDevices } from './DiffInventoryNetDevices';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function DiffSystemInfo({
  data,
  filter,
}: {
  data: InventorizationDifference;
  filter: DiffFilterTypes;
}): React.ReactElement {
  const systemInfoData = useMemo(
    () => prepareDiffObjectData(data.system_info, filter),
    [data.system_info, filter],
  );
  const timeInfoData = useMemo(() => prepareDiffObjectData(data.time, filter), [data.time, filter]);
  const uptimeInfoData = useMemo(
    () => prepareDiffObjectData(data.uptime, filter),
    [data.uptime, filter],
  );
  const hardwareInfoData = useMemo(
    () =>
      data.type === 'firewall' || data.type === 'switch' || data.type === 'router'
        ? prepareDiffObjectData(
            {
              closed: data.hardware_info?.old,
              noChanges: data.hardware_info?.noChanges,
              updated: data.hardware_info?.new,
            },
            filter,
          )
        : {},
    [data, filter],
  );

  const isShowWrapper =
    Object.keys(systemInfoData).length ||
    Object.keys(timeInfoData).length ||
    Object.keys(uptimeInfoData).length ||
    Object.keys(hardwareInfoData).length;

  const wrapperStatuses =
    filter === 'all'
      ? [
          ...defineWrapperStatuses(data.system_info),
          ...defineWrapperStatuses(data.time),
          ...defineWrapperStatuses(data.uptime),
        ]
      : null;

  if (
    filter === 'all' &&
    wrapperStatuses &&
    (data.type === 'firewall' || data.type === 'switch' || data.type === 'router')
  )
    wrapperStatuses.push(...defineWrapperStatuses(data.hardware_info));

  return isShowWrapper ? (
    <WrapperMenu title={locale[currentLocale].systemInfo.title} diffStatuses={wrapperStatuses}>
      <table className="column">
        {'hostname' in systemInfoData && (
          <>
            {systemInfoData.hostname.map((hostname) => (
              <tr>
                <td className={hostname.className}>{locale[currentLocale].systemInfo.hostname}</td>
                <td className={hostname.className}>{hostname.value}</td>
              </tr>
            ))}
          </>
        )}
        {'uuid' in systemInfoData && (
          <>
            {systemInfoData.uuid.map((uuid) => (
              <tr>
                <td className={uuid.className}>{locale[currentLocale].systemInfo.uuid}</td>
                <td className={uuid.className}>{uuid.value}</td>
              </tr>
            ))}
          </>
        )}
        {'total_seconds' in uptimeInfoData && (
          <>
            {uptimeInfoData.total_seconds.map((totalSeconds) => (
              <tr>
                <td className={totalSeconds.className}>{locale[currentLocale].upTime.title}</td>
                <td className={totalSeconds.className}>
                  {getPastTime(Number(totalSeconds.value), currentLocale, 1000)}
                </td>
              </tr>
            ))}
          </>
        )}
        {'mac' in hardwareInfoData && (
          <>
            {hardwareInfoData.mac.map((mac) => (
              <tr>
                <td className={mac.className}>{locale[currentLocale].systemInfo.mac}</td>
                <td className={mac.className}>{mac.value}</td>
              </tr>
            ))}
          </>
        )}
      </table>
      <table className="column">
        {'datetime' in timeInfoData && (
          <>
            {timeInfoData.datetime.map((dateTime) => (
              <tr>
                <td className={dateTime.className}>{locale[currentLocale].time.dateTime}</td>
                <td className={dateTime.className}>
                  {new Date(String(dateTime.value)).toLocaleString()}
                </td>
              </tr>
            ))}
          </>
        )}
        {'local_timezone' in timeInfoData && (
          <>
            {timeInfoData.local_timezone.map((localTimezone) => (
              <tr>
                <td className={localTimezone.className}>
                  {locale[currentLocale].time.localTimezone}
                </td>
                <td className={localTimezone.className}>{localTimezone.value}</td>
              </tr>
            ))}
          </>
        )}
        {'unix_time' in timeInfoData && (
          <>
            {timeInfoData.unix_time.map((unixTime) => (
              <tr>
                <td className={unixTime.className}>{locale[currentLocale].time.unixTime}</td>
                <td className={unixTime.className}>{unixTime.value}</td>
              </tr>
            ))}
          </>
        )}
        {'serialNumber' in hardwareInfoData && (
          <>
            {hardwareInfoData.serialNumber.map((mac) => (
              <tr>
                <td className={mac.className}>{locale[currentLocale].systemInfo.serialNumber}</td>
                <td className={mac.className}>{mac.value}</td>
              </tr>
            ))}
          </>
        )}
      </table>
      <div className="break" />
      {(data.type === 'router' || data.type === 'firewall') && data.class === 'cisco' && (
        <DiffInventoryNetDevices data={data} filter={filter} />
      )}
    </WrapperMenu>
  ) : (
    <></>
  );
}
