import type { GridCellProps } from '@progress/kendo-react-grid';

const currentLocale =
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN';
export function CellCurrentLocale(props: GridCellProps): React.ReactElement {
  const { dataItem, field, className } = props;

  const fieldArray = field ? field.split('.') : [];
  let value = dataItem;

  if (!fieldArray) value = null;
  else if (fieldArray.length === 1) value = dataItem[fieldArray[0]];
  else if (fieldArray.length > 1) {
    fieldArray.forEach((f) => {
      value = value ? value[f] : value;
    });
  }

  return value && value !== '-' ? (
    <td
      title={className && className.includes('title') ? value[currentLocale] : ''}
      className={className || ''}
    >
      {value[currentLocale]}
    </td>
  ) : (
    <td>-</td>
  );
}
