import '../HostDescription.module.scss';
import localeHostDescription from '../../../../../utils/i18n/assetLocales/hostDescription.json';
import type { AssetHostDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/assetHostDashboardResponse.v1';
import { getUserData } from '../../../../../services/local-storage-service';

export function TableFourthHostMetaBlock({
  data,
  currentLocale,
}: {
  data: AssetHostDashboardResponse | undefined;
  currentLocale: 'ru-RU' | 'en-EN';
}): React.ReactElement {
  const currentUser = getUserData();

  return (
    <table className="column column-third">
      <tbody>
        <tr>
          <td>
            <span>{localeHostDescription[currentLocale].summary.added}</span>
          </td>
          <td>
            <span>
              {new Date(String(data?.type === 'host' ? data?.createdAt : '-')).toLocaleString()}
            </span>{' '}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeHostDescription[currentLocale].summary.addedBy}</span>
          </td>
          <td>
            {data?.type === 'host' && data?.createdBy.source === 'user' ? (
              <span>{data?.createdBy.name}</span>
            ) : (
              <span>-</span>
            )}
          </td>
        </tr>
        {currentUser?.role === 'super_admin' && (
          <>
            <tr>
              <td>
                <span>{localeHostDescription[currentLocale].summary.accountId}</span>
              </td>
              <td>
                {data?.account.accountId ? <span>{data?.account.accountId}</span> : <span>-</span>}
              </td>
            </tr>
            <tr>
              <td>
                <span>{localeHostDescription[currentLocale].summary.accountName}</span>
              </td>
              <td>{data?.account.name ? <span>{data?.account.name}</span> : <span>-</span>}</td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
}
