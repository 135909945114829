import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
} from '@progress/kendo-react-charts';
import locale from '../../../../utils/i18n/dashboardLocale/dashboardLayout.json';
import type { InventorizationAssetsAge } from '../../../../types/__generated/on-premise-solution/api/inventorizationAssetsAge.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

const labelContent = (props: any): string => {
  return `${props.dataItem.category}: ${props.dataItem.value}`;
};

export function InvAssetsAge({
  data,
}: {
  data: InventorizationAssetsAge | undefined;
}): React.ReactElement {
  const series = [
    {
      category: `${locale[currentLocale].assetsAge0_3} (${data?.['0-3']})`,
      value: data?.['0-3'],
      color: '#4caf50',
    },
    {
      category: `${locale[currentLocale].assetsAge3_6} (${data?.['3-6']})`,
      value: data?.['3-6'],
      color: '#ffeb3b',
    },
    {
      category: `${locale[currentLocale].assetsAge6_9} (${data?.['6-9']})`,
      value: data?.['6-9'],
      color: '#ff9800',
    },
    {
      category: `${locale[currentLocale].assetsAge9More} (${data?.['9+']})`,
      value: data?.['9+'],
      color: '#ff5722',
    },
  ];

  return (
    <Chart>
      {/* <ChartTitle text="World Population by Broad Age Groups" /> */}
      <ChartLegend position="bottom" />
      <ChartTooltip
        render={(e: any) => {
          if (e.point?.category) return <span>{e.point.category}</span>;

          return '';
        }}
      />
      <ChartSeries>
        <ChartSeriesItem
          type="donut"
          data={series}
          field="value"
          categoryField="category"
          tooltip={{ visible: true }}
          // labels={{ visible: true, content: labelContent }}
        />
      </ChartSeries>
    </Chart>
  );
}
