/* eslint-disable no-nested-ternary */
import { Grid, GridColumn, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useState } from 'react';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import type { InventorizationResponse } from '../../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import type { InventorizationRouterEltexResult } from '../../../../../types/__generated/on-premise-solution/api/inventorizationResponse.v1';
import { WrapperMenu } from './WrapperMenu';
import { StandartCell } from '../../../../common/cells/StandartCell';
import { LAZY_PAGEABLE_DATA } from '../../../../../utils/helpers/constants';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function FirewallRules({ data }: { data: InventorizationResponse }): React.ReactElement {
  const firewallRules = data.result
    .firewall_rules as InventorizationRouterEltexResult['firewall_rules'];

  const [page, setPage] = useState({ take: 5, skip: 0 });

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  return (
    <WrapperMenu title={locale[currentLocale].firewall.title}>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <Grid
            data={firewallRules?.slice(page.skip, page.take + page.skip)}
            pageable={LAZY_PAGEABLE_DATA}
            scrollable="none"
            total={firewallRules?.length}
            pageSize={page.take}
            skip={page.skip}
            onPageChange={pageChange}
            className="no-margin-top"
          >
            <GridColumn
              field="source.address"
              title={locale[currentLocale].firewall.source}
              cell={StandartCell}
            />
            <GridColumn
              field="source.port"
              title={locale[currentLocale].firewall.source}
              cell={StandartCell}
            />
            <GridColumn
              field="destination.address"
              title={locale[currentLocale].firewall.sourcePort}
              cell={StandartCell}
            />
            <GridColumn
              field="destination.port"
              title={locale[currentLocale].firewall.destinationPort}
              cell={StandartCell}
            />
            <GridColumn
              field="protocol"
              title={locale[currentLocale].firewall.protocol}
              cell={StandartCell}
            />
            <GridColumn
              field="application"
              title={locale[currentLocale].firewall.application}
              cell={StandartCell}
            />
            <GridColumn
              field="action"
              title={locale[currentLocale].firewall.action}
              cell={StandartCell}
            />
            <GridColumn
              field="status"
              title={locale[currentLocale].firewall.status}
              cell={StandartCell}
            />
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </WrapperMenu>
  );
}
