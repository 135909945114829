import {
  useEffect,
  createContext,
  JSXElementConstructor,
  ReactElement,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import type { AutoCompleteChangeEvent } from '@progress/kendo-react-dropdowns';
import { deleteTask, getTaskInfo } from '../services/task-service';
import type { ApiError } from '../types/__generated/on-premise-solution/api/apiError.v1';
import type { TaskCreationRequest } from '../types/__generated/on-premise-solution/api/taskCreationRequest.v1';
import type { TaskCreationResponse } from '../types/__generated/on-premise-solution/api/taskCreationResponse.v1';
import { useCommonContext } from './useCommonContext';
import type { TaskResponse } from '../types/__generated/on-premise-solution/api/tasksResponse.v1';
import type { DashboardBulletinsResponse } from '../types/__generated/on-premise-solution/api/dashboardBulletinsResponse.v1';
import { getBulletinIds } from '../services/bulletins-service';
import type { ITaskContext, ITaskRepeatData } from '../utils/helpers/types';

const TaskContext = createContext<ITaskContext | null>(null);

export function TaskProvider({
  children,
}: {
  children: ReactElement<string | JSXElementConstructor<string>>;
}): React.ReactElement {
  const queryClient = useQueryClient();

  const { setOpenForm } = useCommonContext();

  const [isOpenDialog, setOpenDialog] = useState<boolean>(false);

  const [taskId, setTaskId] = useState<ITaskContext['taskId']>(null);

  const [bulletinId, setBulletinId] = useState<string | undefined>(undefined);

  const [taskRepeatData, setTaskRepeatData] = useState<ITaskRepeatData | null>(null);

  const [taskDeleteValues, setTaskDeleteValues] = useState<{
    taskId: string;
    taskName: string;
  } | null>(null);

  const [selectBulletinData, setSelectBulletinData] = useState<{ id: string }[] | null>(null);

  const [selectedBulletinIds, setSelectedBulletinIds] = useState<{ id: string }[] | null>(null);

  const [errorMutation, setErrorMutation] = useState('');

  useQuery<TaskResponse, ApiError>(['tasks', taskId], () => getTaskInfo(Number(taskId)), {
    enabled: !!taskId,
  });

  const queryBulletinData = useQuery<DashboardBulletinsResponse, ApiError>(
    ['tasks', bulletinId],
    () => getBulletinIds(bulletinId || ''),
    {
      enabled: !!bulletinId,
      onSuccess: (response) => {
        const bulletinIds = response.data.map((b) => ({
          id: b.bulletinId,
        }));
        setSelectBulletinData([...bulletinIds]);
      },
    },
  );

  const mutationDeleteTask = useMutation<
    TaskCreationRequest | null,
    ApiError,
    TaskCreationResponse['taskId']
  >((payload) => deleteTask(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('queueTasks');
    },
    onError: (resp) => {
      setErrorMutation(resp.message);
    },
  });

  const changeBulletinId = useCallback((value) => {
    setBulletinId(value);
  }, []);

  const changeBulletinIdsData = useCallback(
    (value: string): void => {
      const id = value;

      if (selectedBulletinIds) {
        const index = selectedBulletinIds?.findIndex((b) => b.id === id);

        if (index === -1) {
          setSelectedBulletinIds([...selectedBulletinIds, { id }]);
        }
      }

      if (!selectedBulletinIds || selectedBulletinIds.length === 0) {
        setSelectedBulletinIds([{ id }]);
      }

      setBulletinId('');
    },
    [selectedBulletinIds],
  );

  const changeBulletinData = useCallback(
    (event: AutoCompleteChangeEvent): void => {
      changeBulletinId(event.value);

      if (event.syntheticEvent.type === 'click') {
        changeBulletinIdsData(event.value);
      }
    },
    [changeBulletinId, changeBulletinIdsData],
  );

  const handleDelete = useMemo(
    () =>
      (id: number): void => {
        mutationDeleteTask.mutate(id);
        setTaskDeleteValues(null);
      },
    [mutationDeleteTask],
  );

  const deleteBulletinFromTask = useCallback(
    (id: string | undefined, event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      event.stopPropagation();
      if (selectedBulletinIds && selectedBulletinIds.length > 0) {
        setSelectedBulletinIds(selectedBulletinIds?.filter((bulletin) => bulletin.id !== id));
        setSelectBulletinData(null);
      }
    },
    [selectedBulletinIds],
  );

  const value = useMemo(
    () => ({
      isOpenDialog,
      setOpenDialog,
      taskId,
      setTaskId,
      taskRepeatData,
      setTaskRepeatData,
      taskDeleteValues,
      setTaskDeleteValues,
      handleDelete,
      bulletinId,
      setBulletinId,
      selectBulletinData,
      selectedBulletinIds,
      setSelectedBulletinIds,
      changeBulletinIdsData,
      changeBulletinId,
      deleteBulletinFromTask,
      isLoadingBulletinData: queryBulletinData.isLoading,
      changeBulletinData,
      errorMutation,
      setErrorMutation,
    }),
    [
      isOpenDialog,
      taskId,
      taskRepeatData,
      taskDeleteValues,
      handleDelete,
      bulletinId,
      selectBulletinData,
      selectedBulletinIds,
      changeBulletinIdsData,
      changeBulletinId,
      deleteBulletinFromTask,
      queryBulletinData.isLoading,
      changeBulletinData,
      errorMutation,
    ],
  );

  useEffect((): void => {
    setOpenForm(isOpenDialog);
  }, [isOpenDialog, setOpenForm]);

  return <TaskContext.Provider value={value}>{children}</TaskContext.Provider>;
}

export const useTaskContext = (): ITaskContext => {
  const context = useContext(TaskContext);
  if (context === null) {
    throw new Error('useTaskEditContext must be used inside the TaskProvider.Provider.');
  }

  return context;
};
