/* eslint-disable react/jsx-pascal-case */
import { useQuery } from 'react-query';
import localeSettings from '../../../utils/i18n/settingsLocales/settings.json';
import { getLicense } from '../../../services/license-service';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import type { License } from '../../../types/__generated/on-premise-solution/api/license.v1';
import { DATA_CACHE_CACHETIME, DATA_CACHE_STALETIME } from '../../../utils/helpers/constants';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

export function LicenseComponent(): React.ReactElement {
  const query = useQuery<License, ApiError>(['settingsLicense'], () => getLicense(), {
    staleTime: DATA_CACHE_STALETIME,
    cacheTime: DATA_CACHE_CACHETIME,
    keepPreviousData: true,
  });

  const { data } = query;

  return (
    <>
      <table className="license column">
        <tbody>
          <tr>
            <td>{localeSettings[currentLocale].formLicense.licenseKey}</td>
            <td>{data?.license?.key ? data.license.key : '-'}</td>
          </tr>
          <tr>
            <td>{localeSettings[currentLocale].formLicense.product}</td>
            <td>{data?.product?.name ? data.product.name : '-'}</td>
          </tr>
          <tr>
            <td>{localeSettings[currentLocale].formLicense.productCode}</td>
            <td>{data?.product?.id ? data.product.id : '-'}</td>
          </tr>
          <tr>
            <td>{localeSettings[currentLocale].formLicense.issuedAt}</td>
            <td>
              {data?.license?.issuedAt ? new Date(data.license.issuedAt).toLocaleString() : '-'}
            </td>
          </tr>
          <tr>
            <td>{localeSettings[currentLocale].formLicense.expiresAt}</td>
            <td>
              {data?.license?.expiresAt ? new Date(data.license.expiresAt).toLocaleString() : '-'}
            </td>
          </tr>
          {/* <tr>
            <td>{localeSettings[currentLocale].formLicense.licenseStatus}</td>
            <td>:</td>
            <td>Will be soon</td>
          </tr> */}
          <tr>
            <td>{localeSettings[currentLocale].formLicense.assetsQuantity}</td>
            <td>
              {data?.license?.constraints?.assets
                ? `${data.license.constraints.assets.quantity} активов`
                : '-'}
            </td>
          </tr>
          <tr>
            <td>{localeSettings[currentLocale].formLicense.channels}</td>
            <td>
              {data?.license?.constraints?.audit?.channels
                ? data.license.constraints.audit.channels.join(', ')
                : '-'}
            </td>
          </tr>
          <tr>
            <td>{localeSettings[currentLocale].formLicense.licenseType}</td>
            <td>{data?.license?.type ? data.license.type : '-'}</td>
          </tr>
          <tr>
            <td>{localeSettings[currentLocale].formLicense.licenseClient}</td>
            <td>
              {data?.client?.name ? data.client.name : '-'}
              {data?.client?.email && `, ${data.client.email}`}
            </td>
          </tr>
        </tbody>
      </table>
      <div className="license-contacts">
        <span className="info-icon" />
        <p>{localeSettings[currentLocale].formLicense.licenseUpdate}:</p>
        <a href="mailto: support@frodex.ru" className="mail">
          support@frodex.ru
        </a>
        ,<a href="tel: +7 (495) 967-65-19 ">+7 (495) 967-65-19 </a>
      </div>
    </>
  );
}
