import type { GridCellProps } from '@progress/kendo-react-grid';
import { Button } from '../../../../../common/baseElements/Button';
import { useAuditModalContext } from '../../../../../../hooks/useAuditModalContext';
import type { IPkgVulns } from '../../../../../../utils/helpers/types';
import { handleVulnModal } from '../../../../../../utils/helpers/audit-helpers';

export function CellVulnListAlias(props: GridCellProps): React.ReactElement {
  const { dataItem, className } = props;

  const { aliases }: IPkgVulns = dataItem;

  const { setCveName, setOpen } = useAuditModalContext();

  return (
    // <td className={`audit__vulns ${className || ''}`} title={aliases ? aliases.join(', ') : ' '}>
    //   {aliases?.map((a, i) => (
    //     <>
    //       <Button fill="modal" onClick={(): void => handleVulnModal(a, setOpen, setCveName)}>
    //         {a}
    //       </Button>
    //       {i !== aliases.length - 1 ? ', ' : ''}
    //     </>
    //   ))}
    //   {!aliases && <span>-</span>}
    // </td>
    <td className={`audit__vulns ${className || ''}`} title={aliases || ' '}>
      {aliases ? (
        <Button fill="modal" onClick={(): void => handleVulnModal(aliases, setOpen, setCveName)}>
          {aliases}
        </Button>
      ) : (
        <span>-</span>
      )}
    </td>
  );
}
