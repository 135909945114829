/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import '../../TaskDescription.module.scss';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { State, process as gridProcess } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { useRef } from 'react';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { PAGEABLE_DATA_LOCAL } from '../../../../../utils/helpers/constants';
import localeAuditTask from '../../../../../utils/i18n/taskLocale/auditTask.json';
import localeVmTask from '../../../../../utils/i18n/taskLocale/vmTask.json';
import { CellId } from './cells/CellVulnerabilitiesListId';
import { CellPublished } from './cells/CellVulnerabilitiesListPublished';
import { CellCwe } from './cells/CellVulnerabilitiesListCwe';
import { CellDescription } from './cells/CellVulnsListDescription';
import { CellCvssVector } from './cells/CellCvssVector';
import { CellVulnListAlias } from './cells/CellVulnListAlias';
import { ScoreCvssCell } from '../../../../common/cells/ScoreCvssCell';
import type { IPkgVulns } from '../../../../../utils/helpers/types';
import { VHeaderCell } from '../../../../common/VHeaderCell';
import { ScoreVCell } from '../../../../common/cells/ScoreVCell';
import CellSelectVuln from './cells/CellSelectVuln';
import { CellWlsInfo } from './cells/CellWlsInfo';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

interface IVulnerabilitiesListTable {
  vulnsData: IPkgVulns[];
  hostName: string | null | undefined | false;
  vulnsWhiteList?: IPkgVulns[] | undefined;
  id?: string | undefined;
  exportRef: React.MutableRefObject<ExcelExport | null>;
  dataState: State;
  setDataState: React.Dispatch<React.SetStateAction<State>>;
  pageLimitGrid: {
    [key: string]: string;
  };
}

export function VulnerabilitiesListTable({
  ...props
}: IVulnerabilitiesListTable): React.ReactElement {
  const {
    hostName,
    vulnsData,
    id,
    exportRef,
    dataState,
    vulnsWhiteList,
    setDataState,
    pageLimitGrid,
  } = props;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _grid = useRef<React.LegacyRef<Grid> | undefined>();

  const titleVulnsSoft = document.location.pathname.includes('assets/images')
    ? `${
        vulnsWhiteList
          ? localeVmTask[currentLocale].tabs.vulnerabilitiesWl
          : localeVmTask[currentLocale].tabs.vulnerabilities
      }`
    : `${hostName && hostName.replaceAll('.', '-')}_${
        vulnsWhiteList
          ? localeVmTask[currentLocale].tabs.vulnerabilitiesWl
          : localeVmTask[currentLocale].tabs.vulnerabilities
      }`;

  return (
    <LocalizationProvider language={currentLocale}>
      <IntlProvider locale={currentLocale.substring(0, 2)}>
        <ExcelExport data={vulnsWhiteList || vulnsData} ref={exportRef} fileName={titleVulnsSoft}>
          <Grid
            pageable={
              id && vulnsWhiteList && vulnsWhiteList.length > 10
                ? PAGEABLE_DATA_LOCAL
                : !id && vulnsData && vulnsData.length > 10
                ? PAGEABLE_DATA_LOCAL
                : false
            }
            sortable
            data={gridProcess(
              vulnsWhiteList && vulnsWhiteList.length > 0
                ? vulnsWhiteList
                : vulnsData && vulnsData.length > 0
                ? vulnsData
                : [],
              dataState,
            )}
            {...dataState}
            onDataStateChange={(e: GridDataStateChangeEvent): void => {
              setDataState(e.dataState);
              if (pageLimitGrid) {
                const pageLimit = {
                  ...pageLimitGrid,
                  VulnerabilitiesList: String(e.dataState.take),
                };
                localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
              } else {
                const pageLimit = {
                  VulnerabilitiesList: String(e.dataState.take),
                };
                localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
              }
            }}
            className="taskaudit__vulnsgrid"
            scrollable="none"
            ref={_grid.current}
          >
            {vulnsData && vulnsData.length > 0 && !id && (
              <Column
                field="addVuln"
                title=" "
                cell={CellSelectVuln}
                width="40px"
                sortable={false}
              />
            )}
            <Column
              field="maxScore"
              title={localeAuditTask[currentLocale].vulnsSoft.table.cvssScore}
              cell={ScoreCvssCell}
              width="74px"
            />
            <Column
              field="VFstec"
              title="v"
              cell={ScoreVCell}
              width="74px"
              headerCell={VHeaderCell}
            />
            <Column
              field="id"
              title={localeAuditTask[currentLocale].vulnsList.table.id}
              cell={CellId}
              width="144px"
            />
            <Column
              field="aliases"
              title={localeAuditTask[currentLocale].vulnsList.table.aliases}
              cell={CellVulnListAlias}
              width={
                vulnsWhiteList
                  ? vulnsWhiteList.find((v) => v.aliases)
                    ? '178px'
                    : '94px'
                  : vulnsData.find((v) => v.aliases)
                  ? '178px'
                  : '94px'
              }
            />
            <Column
              field="description"
              title={localeAuditTask[currentLocale].vulnsList.table.description}
              cell={CellDescription}
              width="360px"
            />
            <Column
              field="cwe"
              title={localeAuditTask[currentLocale].vulnsList.table.cwe}
              cell={CellCwe}
              width="140px"
            />
            <Column
              field="vector"
              title={localeAuditTask[currentLocale].vulnsList.table.cvssVector}
              cell={CellCvssVector}
            />
            <Column
              field="date.published"
              title={localeAuditTask[currentLocale].vulnsList.table.published}
              cell={CellPublished}
              width="140px"
            />
            {vulnsWhiteList && (
              <Column
                title={localeAuditTask[currentLocale].vulnsSoft.table.wlCellTitle}
                cell={CellWlsInfo}
                width={60}
              />
            )}
          </Grid>
        </ExcelExport>
      </IntlProvider>
    </LocalizationProvider>
  );
}
