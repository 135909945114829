import type { GridCellProps } from '@progress/kendo-react-grid';
import cronstrue from 'cronstrue/i18n';
import { getUTCForTaskList } from '../../../../utils/helpers/add-task-helper';
import type localeTask from '../../../../utils/i18n/taskLocale/task.json';
import cronLocale from '../../../../utils/i18n/commonLocales/cronCalculator.json';
import type { TaskHostDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/tasksDashboardResponse.v1';
import { CommonTooltip } from '../../../common/CommonTooltip';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTask;
export function Schedule(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { schedule }: TaskHostDashboardResponse = dataItem;

  return (
    <>
      {schedule && 'repeat' in schedule && (
        <td
          title={cronstrue.toString(getUTCForTaskList(schedule.repeat), {
            locale: currentLocale.slice(0, 2),
          })}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {cronstrue.toString(getUTCForTaskList(schedule.repeat), {
              locale: currentLocale.slice(0, 2),
            })}
          </div>
          <div>
            <CommonTooltip>{cronLocale[currentLocale].utcTime}</CommonTooltip>
          </div>
        </td>
      )}
      {schedule && 'startAt' in schedule && <td>{new Date(schedule.startAt).toLocaleString()}.</td>}
      {!schedule && <td>-</td>}
    </>
  );
}
