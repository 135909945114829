/* eslint-disable react/jsx-pascal-case */
import { TileLayout, TileLayoutRepositionEvent } from '@progress/kendo-react-layout';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Typography } from '@progress/kendo-react-common';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { ErrorBoundary } from 'react-error-boundary';
import { Button } from '../../../common/baseElements/Button';
import locale from '../../../../utils/i18n/dashboardLocale/dashboardLayout.json';
import {
  getAgentStatusesDashboard,
  getTotalAssets,
  getAssetHostsAuditType,
  getBulletins,
  getCvssScore,
  getVulnerableAssets,
  getVulnerableSoftware,
  getTotalVulnerabilities,
  getAuditAssetAge,
  getAllTotalInfo,
} from '../../../../services/dashboard-service';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { DASHBOARD_INTERVAL } from '../../../../utils/helpers/constants';
import type { IDashboardInterval } from '../../../../utils/helpers/types';
import type { AgentStatuses } from '../../../../types/__generated/on-premise-solution/api/agentStatuses.v1';
import { AgentStatus } from '../inventorization/AgentStatus';
import { AuditType } from '../inventorization/AuditType';
import { TopVulnsAsset } from './TopVulnsAsset';
import type { VulnerableAssetsResponse } from '../../../../types/__generated/on-premise-solution/api/vulnerableAssetsResponse.v1';
import type { CvssScores } from '../../../../types/__generated/on-premise-solution/api/cvssScores.v1';
import { CvssScore } from './CvssScore';
import type { VulnerableSoftware } from '../../../../types/__generated/on-premise-solution/api/vulnerableSoftware.v1';
import { TopVulnsSoftware } from './TopVulnsSoftware';
import type { BulletinsWidgetResponse } from '../../../../types/__generated/on-premise-solution/api/bulletinsWidgetResponse.v1';
import { Bulletins } from './Bulletins';
import { clearDashboardSettings } from '../../../../services/local-storage-service';
import type { AssetsResponse } from '../../../../types/__generated/on-premise-solution/api/assetsResponse.v1';
import { useAuditModalContext } from '../../../../hooks/useAuditModalContext';
import { VulnModal } from '../../../common/modal/vulnModal/VulnModal';
import type { AssetHostsAuditType } from '../../../../types/__generated/on-premise-solution/api/assetHostsAuditType.v1';
import styles from '../Dashboard.module.scss';
import { useAssetCreatePropContext } from '../../../../hooks/useAssetCreatePropContext';
import { AddVulnToWLForm } from '../../WhiteList/form/AddVulnToWLForm';
import { AddWhiteListForm } from '../../WhiteList/form/AddWhiteListForm';
import { useWhiteListContext } from '../../../../hooks/useWhiteListContext';
import type { TotalAssetVulnerabillitiesResponse } from '../../../../types/__generated/on-premise-solution/api/totalAssetVulnerabillitiesResponse.v1';
import type { AuditAssetsAgeResponse } from '../../../../types/__generated/on-premise-solution/api/auditAssetsAgeResponse.v1';
import { AudAssetsAge } from './AuditAssetsAge';
import type { TotalAssetCount } from '../../../../types/__generated/on-premise-solution/api/totalAssetCount.v1';
import { AssetsCount } from './AssetsCount';
import { VulnerabilitiesCount } from './VulnerabilitiesCount';
import { BoundaryErrorComponent } from '../../../common/BoundaryErrorComponent';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

// связь параметр-дашборд по индексу (для связи используется один и тот же индекс тут и в tiles)
// order определяет последовательность отображения на странице (с учётом col)
const defaultDashboardSet = [
  { col: 1, rowSpan: 1, colSpan: 2, order: 1 }, // Дашборд "Активов"
  { col: 7, rowSpan: 2, colSpan: 2, order: 8 }, // Дашборд "Статус агентов"
  { col: 7, rowSpan: 2, colSpan: 2, order: 4 }, // Дашборд "Тип аудита"
  { col: 3, rowSpan: 2, colSpan: 2, order: 5 }, // Дашборд "Топ 10 уязвимых хостов"
  { col: 1, rowSpan: 2, colSpan: 8, order: 9 }, // Дашборд "Топ 10 уязвимого ПО"
  { col: 1, rowSpan: 2, colSpan: 2, order: 6 }, // Дашборд "Топ 10 уязвимостей (по кол-ву хостов)"
  { col: 3, rowSpan: 2, colSpan: 2, order: 2 }, // Дашборд "Диаграмма по CVSS score"
  { col: 5, rowSpan: 2, colSpan: 2, order: 7 }, // Дашборд "Топ 10 хостов по V ФСТЭК"
  { col: 1, rowSpan: 1, colSpan: 2, order: 0 }, // Дашборд "Уязвимостей"
  { col: 5, rowSpan: 2, colSpan: 2, order: 3 }, // Дашборд "Давность аудита"
];

export function DashboardVulns(): React.ReactElement {
  const [intervalValue, setIntervalValue] = useState<null | IDashboardInterval>(null);

  const queryTotalAssets = useQuery<AssetsResponse, ApiError>(['totalAssets'], () =>
    getTotalAssets(),
  );

  const queryAllTotalAssets = useQuery<TotalAssetCount, ApiError>(['allTotalAssets'], () =>
    getAllTotalInfo(),
  );

  const queryAgentStatuses = useQuery<AgentStatuses, ApiError>(['agent-statuses'], () =>
    getAgentStatusesDashboard(),
  );

  const queryAssetHostsAuditTypes = useQuery<AssetHostsAuditType, ApiError>(
    ['assetHostsAuditTypes'],
    () => getAssetHostsAuditType(),
  );

  const queryVulnerableAssets = useQuery<VulnerableAssetsResponse, ApiError>(
    ['vulnerable-assets'],
    () => getVulnerableAssets(),
  );

  const queryVulnerableAssetsV = useQuery<VulnerableAssetsResponse, ApiError>(
    ['vulnerable-assets-v'],
    () => getVulnerableAssets('latestAudit.maxVFstec'),
  );

  const queryVulnerableSoftware = useQuery<VulnerableSoftware, ApiError>(
    ['vulnerable-software'],
    () => getVulnerableSoftware(),
  );

  const queryCvssScore = useQuery<CvssScores, ApiError>(['cvss-scores'], () => getCvssScore());

  const queryBulletins = useQuery<BulletinsWidgetResponse, ApiError>(['bulletins'], () =>
    getBulletins(),
  );

  const queryTotalVulnerabilities = useQuery<TotalAssetVulnerabillitiesResponse, ApiError>(
    ['total-vulnerabilities'],
    () => getTotalVulnerabilities(),
  );

  const queryAuditAssetAge = useQuery<AuditAssetsAgeResponse, ApiError>(['audit-asset-age'], () =>
    getAuditAssetAge(),
  );

  const dashboardColRow =
    localStorage.getItem('dashboardVulns') &&
    JSON.parse(localStorage.getItem('dashboardVulns') || '');

  useEffect(() => {
    if (localStorage.getItem('dashboardVulnsUpdate')) {
      setIntervalValue(JSON.parse(localStorage.getItem('dashboardVulnsUpdate') || ''));
    }
  }, []);

  if (dashboardColRow && defaultDashboardSet.length > dashboardColRow.length)
    localStorage.removeItem('dashboardVulns');

  const INITIAL_DASHBOARD_SET = dashboardColRow || defaultDashboardSet;

  const { cveName } = useAuditModalContext();

  const { isWhiteListForm } = useWhiteListContext();

  const [data, setData] = useState(INITIAL_DASHBOARD_SET);

  const tiles = [
    // Дашборд "Активов"
    {
      header: locale[currentLocale].numberOfAssets,
      body: (
        <div className={styles.number_of_assets}>
          {/* <NavLink className="dashboard__link" to="/lk/assets/hosts">
            <h2>{queryTotalAssets.data?.total}</h2>
          </NavLink> */}
          {queryAllTotalAssets.data && <AssetsCount data={queryAllTotalAssets.data} />}
        </div>
      ),
    },
    // Дашборд "Статус агентов"
    {
      header: locale[currentLocale].agentsStatus,
      body: useMemo(() => {
        return (
          <div>
            <AgentStatus data={queryAgentStatuses.data} />
          </div>
        );
      }, [queryAgentStatuses.data]),
    },
    // Дашборд "Тип аудита"
    {
      header: locale[currentLocale].assetTypes,
      body: useMemo(() => {
        return (
          <div>
            <AuditType data={queryAssetHostsAuditTypes.data} />
          </div>
        );
      }, [queryAssetHostsAuditTypes.data]),
    },
    // Дашборд "Топ 10 уязвимых хостов"
    {
      header: locale[currentLocale].vulnerableHosts,
      body: useMemo(() => {
        return (
          <div>
            <TopVulnsAsset data={queryVulnerableAssets.data} />
          </div>
        );
      }, [queryVulnerableAssets.data]),
    },
    // Дашборд "Топ 10 уязвимого ПО"
    {
      header: locale[currentLocale].vulnerableSoftware,
      body: useMemo(() => {
        return (
          <div>
            <TopVulnsSoftware data={queryVulnerableSoftware.data} />
          </div>
        );
      }, [queryVulnerableSoftware.data]),
    },
    // Дашборд "Топ 10 уязвимостей (по кол-ву хостов)"
    {
      header: locale[currentLocale].topVulnerabilities,
      body: useMemo(() => {
        return (
          <div>
            <Bulletins data={queryBulletins.data} />
          </div>
        );
      }, [queryBulletins.data]),
    },
    // Дашборд "Диаграмма по CVSS score"
    {
      header: locale[currentLocale].chartCvssScore,
      body: useMemo(() => {
        return (
          <div style={{ height: '100%' }}>
            <CvssScore data={queryCvssScore.data} />
          </div>
        );
      }, [queryCvssScore.data]),
    },
    // Дашборд "Топ 10 хостов по V ФСТЭК"
    {
      header: locale[currentLocale].vulnerableFstecHosts,
      body: useMemo(() => {
        return (
          <div>
            <TopVulnsAsset data={queryVulnerableAssetsV.data} isV />
          </div>
        );
      }, [queryVulnerableAssetsV.data]),
    },
    // Дашборд "Уязвимостей"
    {
      header: locale[currentLocale].totalVulnerabilities,
      body: (
        // <div className={styles.number_of_assets}>
        //   <NavLink className="dashboard__link" to="/lk/security/vulnerabilities">
        //     <h2 style={{ fontSize: '36px' }}>{queryTotalVulnerabilities.data?.total}</h2>
        //   </NavLink>
        // </div>
        <VulnerabilitiesCount data={queryTotalVulnerabilities.data} />
      ),
    },
    // Дашборд "Давность аудита"
    {
      header: locale[currentLocale].auditAge,
      body: useMemo(() => {
        return (
          <div>
            <AudAssetsAge data={queryAuditAssetAge.data} />
          </div>
        );
      }, [queryAuditAssetAge.data]),
    },
  ];

  const handleReposition = useCallback((e: TileLayoutRepositionEvent): void => {
    setData(e.value);
    localStorage.setItem('dashboardVulns', JSON.stringify(e.value));
  }, []);

  const handleRefresh = useCallback((): void => {
    queryTotalAssets.refetch();
    queryAgentStatuses.refetch();
    queryAssetHostsAuditTypes.refetch();
    queryVulnerableAssets.refetch();
    queryVulnerableSoftware.refetch();
    queryCvssScore.refetch();
    queryBulletins.refetch();
    queryTotalVulnerabilities.refetch();
    queryVulnerableAssetsV.refetch();
    queryAuditAssetAge.refetch();
  }, [
    queryAgentStatuses,
    queryAssetHostsAuditTypes,
    queryAuditAssetAge,
    queryBulletins,
    queryCvssScore,
    queryTotalAssets,
    queryTotalVulnerabilities,
    queryVulnerableAssets,
    queryVulnerableAssetsV,
    queryVulnerableSoftware,
  ]);

  const handleChangeUpdate = useCallback((event: DropDownListChangeEvent): void => {
    setIntervalValue({
      value: { text: event.target.value.text, value: event.target.value.value },
    });
  }, []);

  const { isAddVulnToWLForm } = useAssetCreatePropContext();

  useEffect(() => {
    let repeatDashboard: NodeJS.Timer;
    if (intervalValue && intervalValue.value.value !== 0) {
      localStorage.setItem('dashboardVulnsUpdate', JSON.stringify(intervalValue));
      repeatDashboard = setInterval(() => {
        handleRefresh();
      }, Number(intervalValue.value.value));
    } else if (intervalValue && intervalValue.value.value === 0) {
      localStorage.removeItem('dashboardVulnsUpdate');
    }

    return () => clearInterval(repeatDashboard);
  }, [handleRefresh, intervalValue]);

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      <div className="dashboard">
        <div className="common-header-page">
          <Typography.h3>{locale[currentLocale].titleVuln}</Typography.h3>
          <div className="topactions">
            <Button onClick={handleRefresh}>{locale[currentLocale].btnRefresh}</Button>
            {/* <span className="k-icon k-i-rotate" />{' '} */}
            <span className="auto-update-dashboard">{locale[currentLocale].titleRefresh}</span>
            <DropDownList
              data={DASHBOARD_INTERVAL[currentLocale]}
              textField="text"
              dataItemKey="value"
              defaultValue={DASHBOARD_INTERVAL[currentLocale][0]}
              value={intervalValue?.value}
              onChange={handleChangeUpdate}
            />
            <Button onClick={(): void => clearDashboardSettings('dashboardVulns')}>
              {locale[currentLocale].btnDefault}
            </Button>
          </div>
        </div>
        <TileLayout
          autoFlow="column"
          columns={8}
          rowHeight={255}
          positions={data}
          gap={{
            rows: 10,
            columns: 10,
          }}
          items={tiles}
          onReposition={handleReposition}
        />
        {cveName && <VulnModal />}
        {isAddVulnToWLForm && <AddVulnToWLForm />}
        {isWhiteListForm && !isAddVulnToWLForm && <AddWhiteListForm />}
      </div>
    </ErrorBoundary>
  );
}
