import { useMemo, useState } from 'react';
import { Grid, GridColumn, GridPageChangeEvent } from '@progress/kendo-react-grid';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';

import { LAZY_PAGEABLE_DATA } from '../../../../../utils/helpers/constants';
import {
  createFromListInvData,
  IDiffInvElementInfo,
  type DiffFilterTypes,
  defineWrapperStatuses,
} from '../../../../../utils/helpers/difference-modal-helper';
import type { InventorizationDifferenceRouter } from '../../../../../types/__generated/on-premise-solution/api/inventorizationDifference.v1';
import { WrapperMenu } from '../../../Task/taskIDetails/iDetailsComponents/WrapperMenu';
import { DiffTypeCell } from '../cells/DiffTypeCell';
import { DiffStandartCell } from '../cells/DiffStandartCell';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function DiffVlanJuniperNetDevice({
  data,
  filter,
}: {
  data: InventorizationDifferenceRouter;
  filter: DiffFilterTypes;
}): React.ReactElement {
  const vlanData = useMemo(
    () => createFromListInvData(data.vlans as unknown as IDiffInvElementInfo[], filter),
    [data.vlans, filter],
  );
  const [page, setPage] = useState({ take: 5, skip: 0 });

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  return vlanData && vlanData.length ? (
    <WrapperMenu
      title={locale[currentLocale].vlan.title}
      diffStatuses={filter === 'all' ? defineWrapperStatuses(data.vlans) : undefined}
    >
      {data.type === 'router' && data.class === 'juniper' && (
        <Grid
          data={vlanData.slice(page.skip, page.take + page.skip)}
          pageable={LAZY_PAGEABLE_DATA}
          scrollable="none"
          total={vlanData.length}
          pageSize={page.take}
          skip={page.skip}
          onPageChange={pageChange}
          className="no-margin-top"
        >
          <GridColumn
            field="diffType"
            cell={DiffTypeCell}
            title=" "
            width={20}
            sortable={false}
            headerClassName="no-cursor"
          />
          <GridColumn
            field="interfaces"
            title={locale[currentLocale].vlan.interfaces}
            cell={DiffStandartCell}
            sortable={false}
            headerClassName="no-cursor"
          />
          <GridColumn
            field="name"
            title={locale[currentLocale].vlan.name}
            cell={DiffStandartCell}
            sortable={false}
            headerClassName="no-cursor"
          />
          <GridColumn
            field="tag"
            title={locale[currentLocale].vlan.tag}
            cell={DiffStandartCell}
            sortable={false}
            headerClassName="no-cursor"
          />
        </Grid>
      )}
    </WrapperMenu>
  ) : (
    <></>
  );
}
