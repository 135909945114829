/* eslint-disable react/jsx-no-useless-fragment */
import type { GridCellProps } from '@progress/kendo-react-grid';
import { NavLink } from 'react-router-dom';
import type { EnrichedLogResponse } from '../../../../../types/__generated/on-premise-solution/api/enrichedLogResponse.v1';
import type { Log } from '../../../../../types/__generated/on-premise-solution/api/enrichedLogsResponse.v1';

export function CellHostname(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { asset }: EnrichedLogResponse = dataItem;

  const { assetId }: Log = dataItem;

  return (
    <>
      {asset?.latestInventorization?.hostname ? (
        <td
          title={
            asset?.latestInventorization?.hostname ? asset?.latestInventorization?.hostname : ''
          }
        >
          {asset?.latestInventorization?.os.type === 'windows' ||
          asset?.latestInventorization?.os.type === 'linux' ? (
            <span title={asset?.latestInventorization?.hostname}>
              <NavLink to={`/lk/assets/hosts/${assetId}`}>
                {asset?.latestInventorization?.hostname}
              </NavLink>
            </span>
          ) : (
            <span>{asset?.latestInventorization?.hostname}</span>
          )}
        </td>
      ) : (
        <td>-</td>
      )}
    </>
  );
}
