import type { GridCellProps } from '@progress/kendo-react-grid';
import type { IWinSoftList } from '../../../../../utils/helpers/types';

export function CellWinInstallLocation(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { installLocation }: IWinSoftList = dataItem;

  return <td>{installLocation ? <span>{installLocation}</span> : <span>-</span>}</td>;
}
