/* eslint-disable react/jsx-no-useless-fragment */
import { Grid, GridColumn, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useEffect, useMemo, useState } from 'react';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import type { InventorizationResponse } from '../../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import { SizeCell } from '../../../../common/cells/SizeCell';
import { StandartCell } from '../../../../common/cells/StandartCell';
import { NormalDateCell } from '../../../../common/cells/NormalDateCell';
import { LAZY_PAGEABLE_DATA } from '../../../../../utils/helpers/constants';
import { WrapperMenu } from '../../../Task/taskIDetails/iDetailsComponents/WrapperMenu';
import {
  createFromListInvData,
  defineWrapperStatus,
  defineWrapperStatuses,
  type DiffFilterTypes,
  type IDiffInvElementInfo,
} from '../../../../../utils/helpers/difference-modal-helper';
import type {
  InventorizationDifferenceLinux,
  InventorizationDifferenceWindows,
} from '../../../../../types/__generated/on-premise-solution/api/inventorizationDifference.v1';
import { DiffStandartCell } from '../cells/DiffStandartCell';
import { DiffSizeCell } from '../cells/DiffSizeCell';
import { DiffFullDateCell } from '../cells/DiffFullDateCell';
import { DiffTypeCell } from '../cells/DiffTypeCell';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function ContainerImages({
  data,
  filter,
}: {
  data: InventorizationDifferenceLinux | InventorizationDifferenceWindows;
  filter: DiffFilterTypes;
}): React.ReactElement {
  const [page, setPage] = useState({ take: 5, skip: 0 });

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  const imagesData = useMemo(
    () => createFromListInvData(data.container_images as unknown as IDiffInvElementInfo[], filter),
    [data.container_images, filter],
  );

  if (!imagesData || !imagesData.length) return <></>;

  return (
    <WrapperMenu
      title={locale[currentLocale].images.title}
      diffStatuses={filter === 'all' ? defineWrapperStatuses(data.container_images) : undefined}
    >
      <Grid
        data={imagesData.slice(page.skip, page.take + page.skip)}
        pageable={LAZY_PAGEABLE_DATA}
        scrollable="none"
        total={imagesData.length}
        pageSize={page.take}
        skip={page.skip}
        onPageChange={pageChange}
        className="no-margin-top"
      >
        <GridColumn
          field="diffType"
          cell={DiffTypeCell}
          title=" "
          width={20}
          sortable={false}
          headerClassName="no-cursor"
        />
        <GridColumn
          field="data.repo"
          title={locale[currentLocale].images.repository}
          cell={DiffStandartCell}
          sortable={false}
          headerClassName="no-cursor"
        />
        <GridColumn
          field="data.tag"
          title={locale[currentLocale].images.tag}
          cell={DiffStandartCell}
          sortable={false}
          headerClassName="no-cursor"
        />
        <GridColumn
          field="data.id"
          title={locale[currentLocale].images.id}
          cell={DiffStandartCell}
          sortable={false}
          headerClassName="no-cursor"
        />
        <GridColumn
          field="data.created"
          title={locale[currentLocale].images.createdAt}
          cell={DiffFullDateCell}
          sortable={false}
          headerClassName="no-cursor"
        />
        <GridColumn
          field="data.size"
          title={locale[currentLocale].images.size}
          cell={DiffSizeCell}
          sortable={false}
          headerClassName="no-cursor"
        />
        <GridColumn
          field="type"
          title={locale[currentLocale].images.type}
          cell={DiffStandartCell}
          sortable={false}
          headerClassName="no-cursor"
        />
        <GridColumn
          field="data.arch"
          title={locale[currentLocale].images.arch}
          cell={DiffStandartCell}
          sortable={false}
          headerClassName="no-cursor"
        />
      </Grid>
    </WrapperMenu>
  );
}
