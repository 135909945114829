import type { GridFilterOperators } from '@progress/kendo-react-grid';
import type localeCommonTable from '../i18n/commonLocales/table.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeCommonTable;
export const FILTER_OPERATOR_LIST: { [key: string]: string } = {
  contains: '$regex',
  doesnotcontain: '$not',
  eq: '$eq',
  neq: '$ne',
  startswith: '$regex',
  endswith: '$regex',
  isnull: '$regex',
  isnotnull: 'isnotnull',
  isempty: '$regex',
  gt: '$gt',
  gte: '$gte',
  lt: '$lt',
  lte: '$lte',
};
export const FILTER_OPERATORS: GridFilterOperators = {
  date: [
    // { text: 'grid.filterEqOperator', operator: 'eq' },
    { text: 'grid.filterAfterOrEqualOperator', operator: 'gte' },
    { text: 'grid.filterNotEqOperator', operator: 'neq' },
    { text: 'grid.filterAfterOperator', operator: 'gt' },
    { text: 'grid.filterBeforeOperator', operator: 'lt' },
    { text: 'grid.filterBeforeOrEqualOperator', operator: 'lte' },
  ],
  dateWithoutNeq: [
    { text: 'grid.filterAfterOrEqualOperator', operator: 'gte' },
    { text: 'grid.filterAfterOperator', operator: 'gt' },
    { text: 'grid.filterBeforeOperator', operator: 'lt' },
    { text: 'grid.filterBeforeOrEqualOperator', operator: 'lte' },
  ],
  text: [
    { text: 'grid.filterContainsOperator', operator: 'contains' },
    { text: 'grid.filterNotContainsOperator', operator: 'doesnotcontain' },
    { text: 'grid.filterEqOperator', operator: 'eq' },
    { text: 'grid.filterNotEqOperator', operator: 'neq' },
    // { text: 'grid.filterStartsWithOperator', operator: 'startswith' },
    // { text: 'grid.filterEndsWithOperator', operator: 'endswith' },
    // { text: 'grid.filterIsNullOperator', operator: 'isnull' },
    // { text: 'grid.filterIsNotNullOperator', operator: 'isnotnull' },
    // { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
    // { text: 'grid.filterIsNotEmptyOperator', operator: 'isnotempty' },
  ],
  textRestrict1: [
    // { text: 'grid.filterContainsOperator', operator: 'contains' },
    // { text: 'grid.filterNotContainsOperator', operator: 'doesnotcontain' },
    { text: 'grid.filterEqOperator', operator: 'eq' },
    // { text: 'grid.filterNotEqOperator', operator: 'neq' },
    // { text: 'grid.filterStartsWithOperator', operator: 'startswith' },
    // { text: 'grid.filterEndsWithOperator', operator: 'endswith' },
    // { text: 'grid.filterIsNullOperator', operator: 'isnull' },
    // { text: 'grid.filterIsNotNullOperator', operator: 'isnotnull' },
    // { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
    // { text: 'grid.filterIsNotEmptyOperator', operator: 'isnotempty' },
  ],
  textСontains1: [
    { text: 'grid.filterContainsOperator', operator: 'contains' },
    { text: 'grid.filterNotContainsOperator', operator: 'doesnotcontain' },
  ],
  textСontains2: [
    { text: 'grid.filterNotEqOperator', operator: 'neq' },
    { text: 'grid.filterNotContainsOperator', operator: 'doesnotcontain' },
  ],
  textMore: [
    { text: 'grid.filterContainsOperator', operator: 'contains' },
    { text: 'grid.filterNotContainsOperator', operator: 'doesnotcontain' },
    { text: 'grid.filterEqOperator', operator: 'eq' },
    { text: 'grid.filterNotEqOperator', operator: 'neq' },
    // { text: 'grid.filterStartsWithOperator', operator: 'startswith' },
    // { text: 'grid.filterEndsWithOperator', operator: 'endswith' },
    { text: 'grid.filterIsNullOperator', operator: 'isnull' },
    // { text: 'grid.filterIsNotNullOperator', operator: 'isnotnull' },
    { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
    // { text: 'grid.filterIsNotEmptyOperator', operator: 'isnotempty' },
  ],
  numeric: [
    { text: 'filter.eqOperator', operator: 'eq' },
    { text: 'filter.notEqOperator', operator: 'neq' },
    {
      text: currentLocale === 'ru-RU' ? 'Больше или равно' : 'Greater than or equal',
      operator: 'gte',
    },
    {
      text: currentLocale === 'ru-RU' ? 'Больше' : 'Greater than',
      operator: 'gt',
    },
    { text: currentLocale === 'ru-RU' ? 'Меньше' : 'Less than ', operator: 'lt' },
    {
      text: currentLocale === 'ru-RU' ? 'Меньше или равно' : 'Less than or equal',
      operator: 'lte',
    },
  ],
  numericLocal: [
    { text: 'filter.notEqOperator', operator: 'neq' },
    { text: 'filter.eqOperator', operator: 'eq' },
  ],
  textLocal: [
    { text: 'grid.filterNotContainsOperator', operator: 'doesnotcontain' },
    { text: 'grid.filterContainsOperator', operator: 'contains' },
    { text: 'grid.filterEqOperator', operator: 'eq' },
    { text: 'grid.filterNotEqOperator', operator: 'neq' },
  ],
  dateLocal: [
    { text: 'grid.filterNotEqOperator', operator: 'neq' },
    { text: 'grid.filterBeforeOrEqualOperator', operator: 'lte' },
    { text: 'grid.filterAfterOrEqualOperator', operator: 'gte' },
    { text: 'grid.filterAfterOperator', operator: 'gt' },
    { text: 'grid.filterBeforeOperator', operator: 'lt' },
  ],
};

export const FILTER_CUSTOM_OPERATORS = {
  text0: [
    { text: 'filter.eqOperator', operator: 'eq' },
    // { text: 'filter.notEqOperator', operator: 'neq' },
  ],
  text: [
    { text: 'filter.eqOperator', operator: 'eq' },
    { text: 'filter.notEqOperator', operator: 'neq' },
  ],
  numeric: [
    { text: 'filter.eqOperator', operator: 'eq' },
    { text: 'filter.notEqOperator', operator: 'neq' },
    { text: 'filter.gteOperator', operator: 'gte' },
    { text: 'filter.gtOperator', operator: 'gt' },
    { text: 'filter.ltOperator', operator: 'lt' },
    { text: 'filter.lteOperator', operator: 'lte' },
  ],
};
export const FILTER_TEXT_CONTAINS = {
  text: [
    { text: 'grid.filterContainsOperator', operator: 'contains' },
    { text: 'grid.filterNotContainsOperator', operator: 'doesnotcontain' },
  ],
};

export const FILTER_TEXT_EQ = {
  text: [
    { text: 'filter.eqOperator', operator: 'eq' },
    { text: 'filter.notEqOperator', operator: 'neq' },
  ],
};

// Tasks:
export const FILTER_TASK_OPERATORS = {
  text: [
    { text: 'filter.eqOperator', operator: 'eq' },
    { text: 'filter.notEqOperator', operator: 'neq' },
    { text: 'grid.filterContainsOperator', operator: 'contains' },
    { text: 'grid.filterNotContainsOperator', operator: 'doesnotcontain' },
  ],
  numeric: [
    { text: 'filter.eqOperator', operator: 'eq' },
    { text: 'filter.notEqOperator', operator: 'neq' },
  ],
};

export const FILTER_TASK_OPERATORS_TEMP = {
  text: [
    { text: 'filter.eqOperator', operator: 'eq' },
    { text: 'filter.notEqOperator', operator: 'neq' },
    // { text: 'grid.filterContainsOperator', operator: 'contains' },
    // { text: 'grid.filterNotContainsOperator', operator: 'doesnotcontain' },
  ],
  numeric: [
    { text: 'filter.eqOperator', operator: 'eq' },
    { text: 'filter.notEqOperator', operator: 'neq' },
  ],
};
