/* eslint-disable react/jsx-pascal-case */
import { useCallback } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '../baseElements/Button';
import localeHost from '../../../utils/i18n/assetLocales/assetGroups.json';
import { useHostModalContext } from '../../../hooks/useHostModalContext';
import localeNewTask from '../../../utils/i18n/taskLocale/newTask.json';
import { getUrlHostGr } from '../../../utils/helpers/host-group-list-helper';
import type { IDataHooks } from '../../../utils/helpers/types';
import { CommonGroupList } from '../CommonGroupList';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHost;

export function GroupListForForm(): React.ReactElement {
  const urlParams = getUrlHostGr('AddHostGroups');

  const dataForHooks: IDataHooks = {
    name: 'name',
    componentName: 'AddHostGroups',
  };

  const { setOpenGroupList, hostForm, setHostForm, hostFormTemp, setHostFormTemp } =
    useHostModalContext();

  const handleExit = useCallback(() => {
    setHostFormTemp(null);

    setOpenGroupList(false);
  }, [setHostFormTemp, setOpenGroupList]);

  const addSelectedGroups = useCallback((): void => {
    setHostForm(hostFormTemp);
    setOpenGroupList(false);
  }, [setHostForm, hostFormTemp, setOpenGroupList]);

  return (
    <Dialog
      title={localeHost[currentLocale].dialogTitle}
      onClose={(): void => setOpenGroupList(false)}
      className="hostgroup-list modal"
    >
      <div className="k-form">
        <div className="form-content groups">
          <CommonGroupList dataForHooks={dataForHooks} urlParams={urlParams} />
        </div>
        <div className="k-form-buttons">
          <Button
            type="submit"
            disabled={
              (!hostFormTemp && hostForm?.length === 0) ||
              (!hostFormTemp && hostForm === null) ||
              (!hostForm && hostFormTemp?.length === 0) ||
              (!hostForm && hostFormTemp === null)
            }
            onClick={addSelectedGroups}
          >
            {localeNewTask[currentLocale].form.btnAddAsset}{' '}
            {hostFormTemp &&
              hostFormTemp?.length > 0 &&
              `(${hostFormTemp.filter((h) => h.assetGroupId)?.length})`}
          </Button>
          <Button type="button" onClick={handleExit}>
            {localeNewTask[currentLocale].form.btnCancel}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
