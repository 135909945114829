/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-pascal-case */
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { useState } from 'react';
import localeSettings from '../../../../../utils/i18n/settingsLocales/settings.json';
import styles from '../ApiKeyList.module.scss';
import { MethodDescriptionList } from './MethodDescriptionList';
import type { IApiMethod } from '../../../../../utils/helpers/types';
import { IpRangesDescriptionList } from './IpRangesDescriptionList';
import type { ApiKeyDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/apiKeyDashboardResponse.v1';
import { AccountDescriptionList } from './AccountDescriptionList';
import { GroupDescriptionList } from '../../../../common/cells/GroupDescriptionList';
import { AssetDescriptionList } from './AssetDescriptionList';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

export function ApiKeyDescriptionContent({
  scope,
}: {
  scope: ApiKeyDashboardResponse['scope'] | undefined;
}): React.ReactElement {
  const [selected, setSelected] = useState<number>(0);

  const handleSelect = (e: TabStripSelectEventArguments): void => {
    setSelected(e.selected);
  };

  const apiMethods = scope?.allowedApiMethods as IApiMethod[];
  const allowedIpRanges = scope?.allowedIpRanges?.map((val) => ({ ip: val }));

  return (
    <div className={styles.descriptionContent}>
      <div className={styles.descriptionTitleContent}>
        <span>{localeSettings[currentLocale].apiKey.grid.restrictions}</span>
      </div>
      <TabStrip selected={selected} onSelect={handleSelect}>
        <TabStripTab title={localeSettings[currentLocale].apiKey.form.tabs.accounts}>
          <AccountDescriptionList accounts={scope?.accounts} />
        </TabStripTab>
        <TabStripTab title={localeSettings[currentLocale].apiKey.form.tabs.assets}>
          <AssetDescriptionList assets={scope?.assets} />
        </TabStripTab>
        <TabStripTab title={localeSettings[currentLocale].apiKey.form.tabs.group}>
          <GroupDescriptionList groups={scope?.assetGroups} />
        </TabStripTab>
        <TabStripTab title={localeSettings[currentLocale].apiKey.form.tabs.methods}>
          <MethodDescriptionList apiMethods={apiMethods} />
        </TabStripTab>
        <TabStripTab title={localeSettings[currentLocale].apiKey.form.tabs.ip}>
          <IpRangesDescriptionList allowedIpRanges={allowedIpRanges} />
        </TabStripTab>
      </TabStrip>
    </div>
  );
}
