/* eslint-disable no-nested-ternary */
import { Grid, GridColumn, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useState } from 'react';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import type { InventorizationResponse } from '../../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { StandartCell } from '../../../../common/cells/StandartCell';
import { BoolCell } from '../../../../common/cells/BoolCell';
import { WrapperMenu } from './WrapperMenu';
import { LAZY_PAGEABLE_DATA } from '../../../../../utils/helpers/constants';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function Route({ data }: { data: InventorizationResponse }): React.ReactElement {
  const [page, setPage] = useState({ take: 5, skip: 0 });

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  return (
    <WrapperMenu title={locale[currentLocale].routes.title}>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <Grid
            data={
              data.type === 'linux' ||
              data.type === 'windows' ||
              data.type === 'router' ||
              data.type === 'firewall' ||
              data.type === 'switch' ||
              data.type === 'hypervisor'
                ? data.result.routes?.slice(page.skip, page.take + page.skip)
                : null
            }
            pageable={LAZY_PAGEABLE_DATA}
            scrollable="none"
            total={data.result.routes?.length}
            pageSize={page.take}
            skip={page.skip}
            onPageChange={pageChange}
            className="no-margin-top"
          >
            <GridColumn
              field="destination"
              title={locale[currentLocale].routes.destination}
              cell={StandartCell}
            />
            <GridColumn
              field="netmask"
              title={locale[currentLocale].routes.netmask}
              cell={StandartCell}
            />
            <GridColumn
              field="gateway"
              title={locale[currentLocale].routes.gateway}
              cell={StandartCell}
            />
            <GridColumn
              field="is_default"
              title={locale[currentLocale].routes.isDefault}
              cell={BoolCell}
            />
            <GridColumn
              field="metric"
              title={locale[currentLocale].routes.metric}
              cell={StandartCell}
            />
            <GridColumn
              field="interface"
              title={locale[currentLocale].routes.interface}
              cell={StandartCell}
            />
            {data.type !== 'router' &&
              data.type !== 'firewall' &&
              data.type !== 'switch' &&
              data.type !== 'hypervisor' && (
                <GridColumn
                  field="source"
                  title={locale[currentLocale].routes.source}
                  cell={StandartCell}
                />
              )}
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </WrapperMenu>
  );
}
