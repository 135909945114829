import {
  createContext,
  JSXElementConstructor,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { getStatus } from '../services/license-service';
import type { ApiError } from '../types/__generated/on-premise-solution/api/apiError.v1';
import type { SystemStatusResponse } from '../types/__generated/on-premise-solution/api/systemStatusResponse.v1';

export interface ISystemInfoContext {
  licenseAnswer: SystemStatusResponse | null;
  setLicenseAnswer: (v: SystemStatusResponse | null) => void;
  classValues: string[];
  closeLicenseBlock: () => void;
  dbFirstUpdated: boolean;
  setDbFirstUpdated: React.Dispatch<React.SetStateAction<boolean>>;
}

const SystemInfoContext = createContext<ISystemInfoContext | null>(null);

export function SystemInfoProvider({
  children,
}: {
  children: ReactElement<string | JSXElementConstructor<string>>;
}): React.ReactElement {
  const queryClient = useQueryClient();

  const [licenseAnswer, setLicenseAnswer] = useState<SystemStatusResponse | null>(null);

  const [classValues, setClassValues] = useState<string[]>(['check-license']);

  const [dbFirstUpdated, setDbFirstUpdated] = useState(false);

  const interval = useRef<NodeJS.Timeout | null>(null);

  const setTimer = (): void => {
    interval.current = setInterval(() => {
      queryClient.invalidateQueries('checkLicense');
    }, 60000);
  };

  useQuery<SystemStatusResponse, ApiError>(['checkLicense'], () => getStatus(), {
    keepPreviousData: true,
    onSuccess: (response) => {
      setLicenseAnswer(response);

      if (!response.vdb) setDbFirstUpdated(false);

      if (response.vdb && !response.vdb.isFirstUpdateFinished) {
        setDbFirstUpdated(true);
      } else if (response.vdb && response.vdb.isFirstUpdateFinished && interval.current) {
        clearInterval(interval.current);
        setDbFirstUpdated(false);
      }
    },
  });

  useEffect(() => {
    setTimer();

    return () => {
      if (interval.current) clearTimeout(interval.current);
    };
  }, []);

  const closeLicenseBlock = useCallback((): void => {
    if (
      licenseAnswer &&
      licenseAnswer.license.status.isExpired.status === false &&
      licenseAnswer.license.status.isExpired.daysUntilExpiration > 7
    ) {
      const licInfo = {
        daysUntilExpiration: licenseAnswer?.license.status.isExpired.daysUntilExpiration,
      };
      localStorage.setItem('licInfo', JSON.stringify(licInfo));
    }
    if (
      licenseAnswer &&
      licenseAnswer.license.status.isExpired.status === false &&
      licenseAnswer.license.status.isExpired.daysUntilExpiration < 7
    ) {
      localStorage.removeItem('licInfo');
    }
    setLicenseAnswer(null);
  }, [licenseAnswer]);

  useEffect(() => {
    if (
      licenseAnswer &&
      licenseAnswer.license.status.isExpired.status === false &&
      licenseAnswer.license.status.isExpired.daysUntilExpiration >= 0
    ) {
      setClassValues(['check-license', 'check-license-warning']);
    }
    if (licenseAnswer && licenseAnswer.license.status.isExpired.status === true) {
      setClassValues(['check-license', 'check-license-error']);
    }
    if (licenseAnswer && licenseAnswer.license.status.isAssetQuantityExceeded.status === true) {
      setClassValues(['check-license', 'check-license-error']);
    }
    if (licenseAnswer && licenseAnswer.license.status.isProductNotMatched.status === true) {
      setClassValues(['check-license', 'check-license-error']);
    }

    if (
      licenseAnswer &&
      licenseAnswer.license.status.isExpired.status === false &&
      licenseAnswer.license.status.isExpired.daysUntilExpiration > 30 &&
      licenseAnswer.license.status.isExpired.status === false &&
      !!localStorage.getItem('licInfo')
    ) {
      localStorage.removeItem('licInfo');
    }
  }, [licenseAnswer]);

  const value = useMemo(
    () => ({
      licenseAnswer,
      setLicenseAnswer,
      classValues,
      closeLicenseBlock,
      dbFirstUpdated,
      setDbFirstUpdated,
    }),
    [classValues, closeLicenseBlock, dbFirstUpdated, licenseAnswer],
  );

  return <SystemInfoContext.Provider value={value}>{children}</SystemInfoContext.Provider>;
}

export const useSystemInfoContext = (): ISystemInfoContext => {
  const context = useContext(SystemInfoContext);
  if (context === null) {
    throw new Error('useSystemInfoContext must be used inside the WebSocketContext.Provider.');
  }

  return context;
};
