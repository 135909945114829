import { Button } from '@progress/kendo-react-buttons';
import type { GridCellProps } from '@progress/kendo-react-grid';
import localeSettings from '../../../../../utils/i18n/settingsLocales/settings.json';
import { useApiKeyContext } from '../../../../../hooks/useApiKeyContext';
import { getUserData } from '../../../../../services/local-storage-service';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

export function CellMenuApiKeyList(props: GridCellProps): React.ReactElement {
  const currentUser = getUserData();

  const { dataItem } = props;

  const { setDataForDelete } = useApiKeyContext();

  const handleConfirm = (): void => {
    setDataForDelete({ name: dataItem.name, apiKeyId: dataItem.apiKeyId });
  };

  return (
    <td className="cell_menu">
      <div className="task__menu">
        <div className="dropdown__container">
          <span className="k-icon k-i-more-vertical" />
          <div className="dropdown__content">
            {currentUser?.role !== 'user' && currentUser?.role !== 'reader_user' && (
              <Button onClick={handleConfirm}>
                {localeSettings[currentLocale].apiKey.form.delete}
              </Button>
            )}
          </div>
        </div>
      </div>
    </td>
  );
}
