/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { State, process as Process, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { useRef, useState } from 'react';
import { Filter } from '@progress/kendo-react-data-tools';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { FILTER_INITIAL, PAGEABLE_DATA_LOCAL } from '../../../../utils/helpers/constants';
import localeAuditTask from '../../../../utils/i18n/taskLocale/auditTask.json';
import localeVmTask from '../../../../utils/i18n/taskLocale/vmTask.json';
import { useLocalExcelExport } from '../../../../hooks/components/useLocalExcelExport';
import type { IPackagesUpdate } from '../../../../utils/helpers/task-helpers';
import { CellOldVersion } from './cells/CellOldVersion';
import { CellNewVersion } from './cells/CellNewVersion';
import { CellArch } from './cells/CellArch';
import {
  handlerCustomSearch,
  onFilterChange,
} from '../../../../utils/helpers/local-filter-helpers';
import { PKG_UPDATE } from '../../../../utils/helpers/constant-local-filter-task';
import { CellStatus } from './cells/CellType';
import { TaskPFastFilter } from './TaskPFastFilter';
import { TaskPResultAdditionalInform } from './TaskPResultAdditionalInform';
import { Input } from '../../../common/baseElements/Input';
import { Button } from '../../../common/baseElements/Button';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

export function TaskPResult({
  packagesUpdate,
  hostName,
  status,
  agentError,
  checkConsoleData,
  setSelected,
}: {
  packagesUpdate: IPackagesUpdate;
  hostName: string | undefined | null | false;
  status: 'processing' | 'success' | 'fail' | 'timeout';
  agentError: string | false | undefined;
  checkConsoleData: boolean;
  setSelected: React.Dispatch<React.SetStateAction<number>>;
}): React.ReactElement {
  const pageLimitGrid: { [key: string]: string } =
    localStorage.getItem('pageLimitGrid') &&
    JSON.parse(localStorage.getItem('pageLimitGrid') || '');

  const initialDataState: State = {
    sort: [{ field: 'maxScore', dir: 'desc' }],
    take: pageLimitGrid && pageLimitGrid.VulnsSoft ? Number(pageLimitGrid.VulnsSoft) : 15,
    skip: 0,
    filter: FILTER_INITIAL,
  };

  const fieldFindInTable = 'name';

  const [dataState, setDataState] = useState<State>(initialDataState);

  const [customSearch, setCustomSearch] = useState('');

  const [filter, setFilter] = useState(FILTER_INITIAL);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _grid = useRef<React.LegacyRef<Grid> | undefined>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _export = useRef<ExcelExport | null>(null);

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  const excelExport = useLocalExcelExport(dataState, _export, packagesUpdate.allPkgs, setDataState);

  const titleVulnsSoft = `${hostName && hostName.replaceAll('.', '-')}_${
    localeVmTask[currentLocale].tabs.excelTitlePkg
  }`;

  return (
    <div className="task-p-result">
      {packagesUpdate && packagesUpdate.allPkgs.length > 0 && (
        <>
          <div className="filter-actions">
            <LocalizationProvider language={currentLocale}>
              <IntlProvider locale={currentLocale.substring(0, 2)}>
                <div className="filter-line">
                  <Filter
                    value={filter}
                    onChange={(event): void =>
                      onFilterChange(
                        event,
                        setCustomSearch,
                        setDataState,
                        dataState,
                        setFilter,
                        filterValue,
                        customSearch,
                        fieldFindInTable,
                      )
                    }
                    fields={PKG_UPDATE[currentLocale]}
                  />
                  {filter.filters.length === 0 && (
                    <Input
                      name="customSearch"
                      placeholder={localeAuditTask[currentLocale].customFilterPackageName}
                      value={customSearch}
                      onChange={(e): void =>
                        handlerCustomSearch(
                          e,
                          setCustomSearch,
                          setDataState,
                          filterValue,
                          dataState,
                          fieldFindInTable,
                        )
                      }
                    />
                  )}
                  <Button excel fill="flat" onClick={excelExport}>
                    {localeAuditTask[currentLocale].vulnsSoft.table.exportToExcel}
                  </Button>
                </div>
              </IntlProvider>
            </LocalizationProvider>
            {filter.filters.length === 0 && (
              <TaskPFastFilter
                dataState={dataState}
                setDataState={setDataState}
                packagesUpdate={packagesUpdate}
                filterValue={filterValue}
              />
            )}
          </div>
          <LocalizationProvider language={currentLocale}>
            <IntlProvider locale={currentLocale.substring(0, 2)}>
              {packagesUpdate && packagesUpdate.allPkgs && (
                <ExcelExport
                  data={packagesUpdate.allPkgs}
                  ref={_export}
                  fileName={titleVulnsSoft.replaceAll('.', '_')}
                >
                  <Grid
                    pageable={packagesUpdate.allPkgs.length > 10 ? PAGEABLE_DATA_LOCAL : false}
                    sortable
                    data={Process(packagesUpdate.allPkgs, dataState)}
                    {...dataState}
                    onDataStateChange={(e: GridDataStateChangeEvent): void => {
                      setDataState(e.dataState);
                      if (pageLimitGrid) {
                        const pageLimit = {
                          ...pageLimitGrid,
                          VulnsSoft: String(e.dataState.take),
                        };
                        localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
                      } else {
                        const pageLimit = {
                          VulnsSoft: String(e.dataState.take),
                        };
                        localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
                      }
                    }}
                    scrollable="none"
                    ref={_grid.current}
                    className={
                      packagesUpdate.allPkgs.length && packagesUpdate.allPkgs.length < 10
                        ? 'no-pageable-k-grid'
                        : ''
                    }
                  >
                    <Column
                      field="name"
                      title={localeAuditTask[currentLocale].vulnsSoft.table.packageName}
                    />
                    <Column
                      field="oldVersion"
                      title={localeAuditTask[currentLocale].packageList.oldVersion}
                      cell={CellOldVersion}
                    />
                    <Column
                      field="newVersion"
                      title={localeAuditTask[currentLocale].packageList.newVersion}
                      cell={CellNewVersion}
                    />
                    <Column
                      field="arch"
                      title={localeAuditTask[currentLocale].packageList.arch}
                      width="140px"
                      cell={CellArch}
                    />
                    <Column
                      field="status"
                      title={localeAuditTask[currentLocale].packageList.status}
                      width="120px"
                      cell={CellStatus}
                    />
                  </Grid>
                </ExcelExport>
              )}
            </IntlProvider>
          </LocalizationProvider>
        </>
      )}
      <TaskPResultAdditionalInform
        packagesUpdate={packagesUpdate}
        status={status}
        agentError={agentError}
        checkConsoleData={checkConsoleData}
        setSelected={setSelected}
      />
    </div>
  );
}
