import type { GridCellProps } from '@progress/kendo-react-grid';
import type { IStatusValues } from '../../../../utils/helpers/types';
import localeTask from '../../../../utils/i18n/taskLocale/task.json';
import type { TaskHostDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/tasksDashboardResponse.v1';
import type { TaskExecutionDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/taskExecutionsDashboardResponse.v1';
import { createStatusCls } from '../../../../utils/helpers/task-list-helper';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTask;

export function Status(props: GridCellProps): React.ReactElement {
  const { dataItem, className } = props;

  const { status }: TaskHostDashboardResponse | TaskExecutionDashboardResponse = dataItem;

  const cssClasses = createStatusCls(status);

  return (
    <td className={className || ''}>
      <div className="task__status">
        <span className={cssClasses.join(' ')}>
          {localeTask[currentLocale].form.statusValues[dataItem.status as keyof IStatusValues]}
        </span>
      </div>
    </td>
  );
}
