import type { GridCellProps } from '@progress/kendo-react-grid';
import { useAuditModalContext } from '../../hooks/useAuditModalContext';
import { Button } from './baseElements/Button';

export function KbIdCell(props: GridCellProps): React.ReactElement {
  const { dataItem, className } = props;

  const { setIdKb } = useAuditModalContext();

  const handleModal = (idVal: string): void => {
    setIdKb(idVal);
  };

  return (
    <td className={className}>
      {dataItem.id ? (
        <Button fill="modal" onClick={(): void => handleModal(dataItem.id)}>
          {dataItem.id}
        </Button>
      ) : (
        '-'
      )}
    </td>
  );
}
