import type { SortDescriptor } from '@progress/kendo-data-query';
import { INITIAL_SORT_CREDENTIAL_LIST, INITIAL_PAGING } from './constants';
import type { IPageState, IVaultData } from './types';
import type { VaultsResponse } from '../../types/__generated/on-premise-solution/api/vaultsResponse.v1';

export interface IFilterUrlCredential {
  type?: string;
}
export interface IUrlParamsCredential {
  sort: SortDescriptor[];
  page: IPageState;
  pageLimitGrid: { [key: string]: string } | null;
  filterUrlData: IFilterUrlCredential;
}

export function getUrlCredential(pageLimitGridKey: string): IUrlParamsCredential {
  const pageLimitGrid: IUrlParamsCredential['pageLimitGrid'] =
    localStorage.getItem('pageLimitGrid') &&
    JSON.parse(localStorage.getItem('pageLimitGrid') || '');

  const locationUrlData: { [name: string]: string } | null = {};
  if (document.location.search) {
    const locationSearch = document.location.search.slice(1).split('&');
    locationSearch.forEach((val) => {
      const value = val.split('=');
      if (value[0] === 'type[$eq]') {
        locationUrlData.type = decodeURI(value[1]) || '';
      }
      locationUrlData[value[0]] = value[1] || '';
    });
  }

  const { sortField, sortOrder, skip } = locationUrlData;
  const sort: SortDescriptor[] =
    sortField && sortOrder && (sortOrder === 'asc' || sortOrder === 'desc')
      ? [{ field: sortField, dir: sortOrder }]
      : INITIAL_SORT_CREDENTIAL_LIST;

  const page: IPageState = {
    skip: Number(skip) || INITIAL_PAGING.skip,
    take:
      pageLimitGrid && Number(pageLimitGrid[pageLimitGridKey])
        ? Number(pageLimitGrid[pageLimitGridKey])
        : INITIAL_PAGING.take,
  };

  const filterUrlData = {} as IFilterUrlCredential;

  if (locationUrlData.osNameRegex) {
    filterUrlData.type = locationUrlData.type;
  }

  return {
    sort,
    page,
    pageLimitGrid,
    filterUrlData,
  };
}

export function createVaults(data: VaultsResponse | undefined): IVaultData[] {
  const vaultIdList: IVaultData[] = [];

  data?.data.forEach((d) => {
    const vaultData = {
      id: d.vaultId,
      name: d.name,
    };
    vaultIdList.push(vaultData);
  });

  return vaultIdList;
}
