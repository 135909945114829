/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect, useRef } from 'react';
import type { GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useQuery, useQueryClient } from 'react-query';
import { Filter, FilterChangeEvent } from '@progress/kendo-react-data-tools';
import type { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { NavLink, useParams } from 'react-router-dom';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { FILTER_INITIAL, INITIAL_PAGING_10 } from '../../../../utils/helpers/constants';
import { useNotificationContext } from '../../../../hooks/useNotificationContext';
import { handleError } from '../../../../utils/errors';
import type { IPageState } from '../../../../utils/helpers/types';
import { СommonTableLoader } from '../../tableLoader/CommonTableLoader';
import localeHost from '../../../../utils/i18n/assetLocales/hostList.json';
import localeNewTask from '../../../../utils/i18n/taskLocale/newTask.json';
import { handleFilteringTest, IFilterVal } from '../../../../utils/filtering-in-table-test';
import { getUrlParamsAddHosts } from '../../../../utils/helpers/add-host-list-helper';
import { usePageChange } from '../../../../hooks/components/usePageChange';
import { useFilterHostChange } from '../../../../hooks/components/useFilterHostChange';
import { FILTER_IMAGELIST_MODAL } from '../../../../utils/helpers/constant-serv-filter-host';
import type { AssetsImagesDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/assetsImagesDashboardResponse.v1';
import { getBulletinImages } from '../../../../services/dashboard-service';
import { CommonImageList } from '../../CommonImageList';
import { useCustomSearch } from '../../../../hooks/components/useCustomSearch';
import styles from './vulnModal.module.scss';
import { Button } from '../../baseElements/Button';
import { Input } from '../../baseElements/Input';
import { VulnModalImageWl } from './VulnModalImageWl';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHost;

const dataForHooks = {
  name: 'imageNames',
  componentName: 'VulnModalImage',
};
export function VulnModalImage({
  bulletinId,
  totalImageAssetsWhitelisted,
}: {
  bulletinId: string | null;
  totalImageAssetsWhitelisted: number | undefined;
}): React.ReactElement {
  const urlParams = getUrlParamsAddHosts(dataForHooks.componentName);

  const { assetId } = useParams<{ assetId: string }>();

  const queryClient = useQueryClient();

  const [customSearch, setCustomSearch] = useState('');

  const [sort, setSort] = useState<SortDescriptor[]>(urlParams.sort);

  const [page, setPage] = useState<IPageState>(INITIAL_PAGING_10);

  const [filter, setFilter] = useState<CompositeFilterDescriptor>(FILTER_INITIAL);

  const [filterStatus, setFilterStatus] = useState(false);

  const [filterVal, setFilterVal] = useState<IFilterVal[]>([]);

  const [isWhiteListTable, setWhiteListTable] = useState(false);

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  const filterRef = useRef<CompositeFilterDescriptor>(filter);

  const { createNotification } = useNotificationContext();

  const getPage = usePageChange(setPage, urlParams, dataForHooks);

  const debouncedCustomSearch = useCustomSearch(
    setFilterStatus,
    setCustomSearch,
    filter,
    setFilterVal,
    setPage,
    page,
    dataForHooks,
  );

  const filterSearch = useFilterHostChange(
    filterValue,
    setFilter,
    customSearch,
    setCustomSearch,
    setFilterVal,
    filterRef,
    filter,
    setPage,
    urlParams,
  );

  const query = useQuery<AssetsImagesDashboardResponse, ApiError>(
    ['bulletinModalImages', page, filterVal, sort],
    () => getBulletinImages(page, filterVal, sort, bulletinId),
  );

  useEffect(() => {
    let delaySearch: NodeJS.Timeout;

    if (filter?.filters?.length > 0) {
      filterRef.current = filter;
      delaySearch = setTimeout(() => {
        handleFilteringTest(filterRef.current, setFilterVal);
        setFilterStatus(false);
      }, 300);
    }

    return () => clearTimeout(delaySearch);
  }, [filter]);

  useEffect(() => {
    queryClient.invalidateQueries('bulletinModalImages');
  }, [queryClient]);

  const pageChange = (event: GridPageChangeEvent): void => {
    getPage(event);
  };

  useEffect(() => {
    if (query.error) {
      handleError(query.error, createNotification);
    }
  }, [query.isError, createNotification, query.error]);

  useEffect(() => {
    const wlBlock = document.getElementById('idModalImage');

    if (wlBlock) {
      wlBlock.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [isWhiteListTable]);

  const onFilterChange = (event: FilterChangeEvent): void => {
    filterSearch(event);
  };

  const handleCustomSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    debouncedCustomSearch(e);
  };

  return (
    <>
      <div className="vuln-modal-asset">
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <div className="filter-line">
              <Filter
                value={filter}
                onChange={onFilterChange}
                fields={FILTER_IMAGELIST_MODAL[currentLocale]}
              />
              {filter.filters.length === 0 && (
                <Input
                  name="customSearch"
                  placeholder={localeNewTask[currentLocale].customSearchImage}
                  value={customSearch}
                  onChange={(e): void => handleCustomSearch(e)}
                  style={{ width: '400px' }}
                />
              )}
              {totalImageAssetsWhitelisted &&
              totalImageAssetsWhitelisted > 0 &&
              Number(query.data?.total) !== 0 ? (
                <div className={styles.whitelistGridTitleTop}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/attention_yellow.svg`}
                    alt="attention"
                  />
                  <Button
                    fill="dotted"
                    type="button"
                    onClick={(): void => setWhiteListTable(!isWhiteListTable)}
                  >
                    {!isWhiteListTable
                      ? localeHost[currentLocale].vulns.titleWlShow
                      : localeHost[currentLocale].vulns.titleWlShowHide}
                    ({totalImageAssetsWhitelisted})
                  </Button>
                </div>
              ) : null}
            </div>
          </IntlProvider>
        </LocalizationProvider>
        <CommonImageList
          sort={sort}
          dataImage={query.data}
          page={page}
          total={query.data?.total}
          pageChange={pageChange}
          setSort={setSort}
          dataForHooks={dataForHooks}
          conditionSort={10}
        />
        <СommonTableLoader queryData={query.isLoading} filteringData={filterStatus} />
        {query.isError && (
          <div className="info alert alert-danger">Error: {query.error.message}</div>
        )}
        {totalImageAssetsWhitelisted && totalImageAssetsWhitelisted > 0 ? (
          <div
            className={
              query.data?.total && query.data?.total < 10
                ? `condition-margin-top ${styles.whitelistGridTitle}`
                : styles.whitelistGridTitle
            }
          >
            <span className={styles.whitelistGridText}>
              {localeHost[currentLocale].vulns.titleWl}
            </span>
            <NavLink
              className="dashboard__link"
              to={`/lk/audit/exceptions${
                assetId
                  ? `?skip=0&limit=15&sortField=createdAt&sortOrder=desc&assetIds[$eq]=${assetId}`
                  : ''
              }`}
            >
              {localeHost[currentLocale].vulns.titleWlLink}
            </NavLink>
            <Button
              fill="dotted"
              type="button"
              onClick={(): void => setWhiteListTable(!isWhiteListTable)}
            >
              {isWhiteListTable
                ? localeHost[currentLocale].vulns.titleWlHide
                : localeHost[currentLocale].vulns.titleWlShow}
              ({totalImageAssetsWhitelisted})
            </Button>
          </div>
        ) : null}
      </div>
      {isWhiteListTable && <VulnModalImageWl bulletinId={bulletinId} />}
    </>
  );
}
