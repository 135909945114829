import type { GridCellProps } from '@progress/kendo-react-grid';
import { nanoid } from 'nanoid';
import localeSettings from '../../../../../utils/i18n/settingsLocales/settings.json';
import type { IApiKeyVal } from '../../../../../utils/helpers/types';
import styles from '../ApiKeyList.module.scss';
import { showRestrictions } from '../../../../../utils/helpers/apikey-helpers';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

export function CellRestrictionApiKeyList(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const data = showRestrictions(dataItem.scope);

  return (
    <td className={styles.restrictions}>
      {data.map((r) =>
        r.restriction ? (
          <span className={styles.restrictionsEnable} key={nanoid(4)}>
            {localeSettings[currentLocale].apiKey.restrictions[r.field] as keyof IApiKeyVal}
          </span>
        ) : (
          <span className={styles.restrictionsDisable} key={nanoid(4)}>
            {localeSettings[currentLocale].apiKey.restrictions[r.field] as keyof IApiKeyVal}
          </span>
        ),
      )}
    </td>
  );
}
