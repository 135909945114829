/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect } from 'react';
import { useWhiteListContext } from '../../../hooks/useWhiteListContext';
import { DeleteConfirmModal } from './DeleteConfirmModal';

export function DeleteModal({
  isConfirm,
  setConfirm,
  handleDelete,
  name,
  countInside,
}: {
  isConfirm: boolean;
  setConfirm: (value: React.SetStateAction<boolean>) => void;
  handleDelete: () => void;
  name: string;
  countInside?: number;
}): React.ReactElement {
  const { setErrorMutation, errorMutation } = useWhiteListContext();

  useEffect(() => {
    return () => setErrorMutation('');
  }, []);

  return (
    <>
      {isConfirm && (
        <DeleteConfirmModal
          type="exception-list"
          name={name}
          onClose={(): void => setConfirm(false)}
          handleDelete={handleDelete}
          errorMessage={errorMutation}
          countInside={countInside}
        />
      )}
    </>
  );
}
