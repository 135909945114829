/* eslint-disable jsx-a11y/no-autofocus */
import type { ReactElement } from 'react';
import styles from './Input.module.scss';
import type { IInputProps } from '../../../utils/helpers/types';

export function Input(props: IInputProps): ReactElement {
  const {
    id,
    name,
    style,
    disabled,
    className,
    placeholder,
    value,
    type,
    maxLength,
    onChange,
    onFocus,
    onBlur,
    min,
    max,
    required,
    autoFocus,
  } = props;

  return (
    <input
      style={style}
      name={name}
      type={type}
      disabled={disabled}
      value={value}
      id={id}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      maxLength={maxLength}
      className={`${styles.input} ${className || ''}`}
      placeholder={placeholder}
      min={min}
      max={max}
      required={required}
      autoFocus={autoFocus}
    />
  );
}
