import type { GridCellProps } from '@progress/kendo-react-grid';
import type { ArtifactResponse } from '../../../../../types/__generated/on-premise-solution/api/artifactsResponse.v1';
import localeInventorizationTask from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeInventorizationTask;

export function CellSize(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { size }: ArtifactResponse = dataItem;

  return (
    <td>
      {Number(size) >= 1024 ? (
        <span>
          {Math.round(Number(size) / 1024)} {localeInventorizationTask[currentLocale].sizeKib}
        </span>
      ) : (
        <span>
          {size} {localeInventorizationTask[currentLocale].byte}
        </span>
      )}
    </td>
  );
}
