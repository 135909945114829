import type { GridCellProps } from '@progress/kendo-react-grid';

export function CellNewVersion(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  return (
    <td title={dataItem.newVersion ? dataItem.newVersion : ''}>
      {dataItem.newVersion ? <span>{dataItem.newVersion}</span> : <span>-</span>}
    </td>
  );
}
