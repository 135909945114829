import { Grid, GridColumn, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useState } from 'react';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import type { InventorizationResponse } from '../../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { WrapperMenu } from './WrapperMenu';
import { LAZY_PAGEABLE_DATA } from '../../../../../utils/helpers/constants';
import { StandartCell } from '../../../../common/cells/StandartCell';
import { DeviceCapabilityCell } from './сells/DeviceCapabilityCell';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function CdpNetDevice({ data }: { data: InventorizationResponse }): React.ReactElement {
  const [page, setPage] = useState({ take: 5, skip: 0 });

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  return (
    <WrapperMenu title={locale[currentLocale].cdp.title}>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <Grid
            data={
              data.type === 'router' && data.class === 'cisco'
                ? data.result.cdp?.slice(page.skip, page.take + page.skip)
                : null
            }
            pageable={LAZY_PAGEABLE_DATA}
            scrollable="none"
            total={
              (data.type === 'router' && data.class === 'cisco') ||
              (data.type === 'switch' && data.class === 'aruba')
                ? data.result.cdp?.length
                : 0
            }
            pageSize={page.take}
            skip={page.skip}
            onPageChange={pageChange}
            className="no-margin-top"
          >
            <GridColumn
              field="deviceId"
              title={locale[currentLocale].cdp.deviceId}
              cell={StandartCell}
            />
            <GridColumn
              field="interface"
              title={locale[currentLocale].cdp.interface}
              cell={StandartCell}
            />
            <GridColumn
              field="portId"
              title={locale[currentLocale].cdp.portId}
              cell={StandartCell}
            />
            <GridColumn
              field="holdTime"
              title={locale[currentLocale].cdp.holdTime}
              cell={StandartCell}
            />
            <GridColumn
              field="capability"
              title={locale[currentLocale].cdp.capability}
              cell={DeviceCapabilityCell}
            />
            <GridColumn
              field="platform"
              title={locale[currentLocale].cdp.platform}
              cell={StandartCell}
            />
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </WrapperMenu>
  );
}
