/* eslint-disable no-nested-ternary */
import type { InventorizationResponse } from '../../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { DiskPhysical } from './diskContent/DiskPhysical';
import { DiskVirtual } from './diskContent/DiskVirtual';
import { DiskLogical } from './diskContent/DiskLogical';
import { WrapperMenu } from './WrapperMenu';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function Disk({ data }: { data: InventorizationResponse }): React.ReactElement {
  return (
    <WrapperMenu title={locale[currentLocale].disks.title}>
      <WrapperMenu title={locale[currentLocale].disks.titlePhysicalDrives}>
        <DiskPhysical data={data} />
      </WrapperMenu>
      {data.type === 'linux' && (
        <WrapperMenu title={locale[currentLocale].disks.titleVirtualDisk}>
          <DiskVirtual data={data} />
        </WrapperMenu>
      )}
      <DiskLogical data={data} />
    </WrapperMenu>
  );
}
