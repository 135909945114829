import '../../TaskDescription.module.scss';
import { useParams } from 'react-router';
import { Button } from '../../../../common/baseElements/Button';
import localeAuditTask from '../../../../../utils/i18n/taskLocale/auditTask.json';
import { useHostModalContext } from '../../../../../hooks/useHostModalContext';
import { useCreateTask } from '../../../../../hooks/components/useCreateTask';
import styles from './packageNameModal.module.scss';
import type { TvulnSoftDataForSelect, TVulnsSoftActions } from '../../../../../utils/helpers/types';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

export function VulnsSoftActions(props: TVulnsSoftActions): React.ReactElement {
  const { hostName, id, vulnSoftDataForSelect, vulnSoftDataForSelectWl } = props;

  const { selectedPkgs, setSelectedPkgs, selectedPkgsWl, setSelectedPkgsWl, setPkgWlTask } =
    useHostModalContext();

  const { assetId } = useParams<{ assetId: string }>();

  const { createTaskForAsset } = useCreateTask();

  const handleTaskForAsset = (typePkg: string): void => {
    createTaskForAsset({
      val: 'package_update',
      data: undefined,
      assetId,
      hostname: hostName || undefined,
    });
    setPkgWlTask(typePkg === 'wl');
  };

  const selectAllPkgOnPage = (
    pkgsOnPage: TvulnSoftDataForSelect[] | undefined,
    type?: string,
  ): void => {
    if (type) {
      if (selectedPkgs && pkgsOnPage) {
        const selectedAndOnPage = [...selectedPkgs, ...pkgsOnPage];

        const duplicatValChecked = selectedAndOnPage.filter(
          (pkg, i, arr) =>
            arr.findIndex((p) => p.packetVersion === pkg.packetVersion && p.name === pkg.name) ===
            i,
        );

        setSelectedPkgs(duplicatValChecked);
      }
      if (!selectedPkgs && pkgsOnPage) setSelectedPkgs(pkgsOnPage);
    }
    if (!type) {
      if (selectedPkgsWl && pkgsOnPage) {
        const selectedAndOnPage = [...selectedPkgsWl, ...pkgsOnPage];

        const duplicatValChecked = selectedAndOnPage.filter(
          (pkg, i, arr) =>
            arr.findIndex((p) => p.packetVersion === pkg.packetVersion && p.name === pkg.name) ===
            i,
        );

        setSelectedPkgsWl(duplicatValChecked);
      }
      if (!selectedPkgsWl && pkgsOnPage) setSelectedPkgsWl(pkgsOnPage);
    }
  };

  return (
    <div className={styles.pkgUpdate}>
      {!id && (
        <Button
          onClick={(): void => selectAllPkgOnPage(vulnSoftDataForSelect, 'notWl')}
          className="selected-pkgs button-mr20"
        >
          {localeAuditTask[currentLocale].vulnsSoft.selectAllVulnerabilities}
        </Button>
      )}
      {!id && selectedPkgs && selectedPkgs.length > 0 && (
        <Button
          onClick={(): void => handleTaskForAsset('notWl')}
          className="selected-pkgs action button-mr20"
        >
          {localeAuditTask[currentLocale].updateSelectedPackages} ({selectedPkgs.length})
        </Button>
      )}
      {id && (
        <Button
          onClick={(): void => selectAllPkgOnPage(vulnSoftDataForSelectWl)}
          className="selected-pkgs button-mr20"
        >
          {localeAuditTask[currentLocale].vulnsSoft.selectAllVulnerabilities}
        </Button>
      )}
      {id && selectedPkgsWl && selectedPkgsWl.length > 0 && (
        <Button
          onClick={(): void => handleTaskForAsset('wl')}
          className="selected-pkgs action button-mr20"
        >
          {localeAuditTask[currentLocale].updateSelectedPackages} ({selectedPkgsWl.length})
        </Button>
      )}
      {!id && selectedPkgs && selectedPkgs.length > 0 && (
        <Button onClick={(): void => setSelectedPkgs(null)} className="cancel-selected-pkgs">
          {localeAuditTask[currentLocale].cancelSelectedPackages}
        </Button>
      )}
      {/* {id && (
        <Button
          onClick={(): void => selectAllPkgOnPage(vulnSoftDataForSelectWl)}
          className="selected-pkgs button-mr20"
        >
          {localeAuditTask[currentLocale].vulnsSoft.selectAllVulnerabilities}
        </Button>
      )} */}
      {id && selectedPkgsWl && selectedPkgsWl.length > 0 && (
        <Button onClick={(): void => setSelectedPkgsWl(null)} className="cancel-selected-pkgs">
          {localeAuditTask[currentLocale].cancelSelectedPackages}
        </Button>
      )}
    </div>
  );
}
