import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useEffect } from 'react';
import type { AssetHostDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/assetHostDashboardResponse.v1';
import localeDashboardForm from '../../../../../utils/i18n/dashboardLocale/addDashboardForm.json';
import { Button } from '../../../../common/baseElements/Button';
import { useHostActions } from '../../../../../hooks/components/useHostActions';
import styles from '../../../../common/AssetDescription.module.scss';
import { useAssetCreatePropContext } from '../../../../../hooks/useAssetCreatePropContext';
import type { IAssetImportanceValue } from '../../../../../utils/helpers/types';
import { CommonTooltip } from '../../../../common/CommonTooltip';

interface IHostImportance {
  server: string;
  workstation: string;
  other: string;
}
export function TableTHostMetaBlock({
  data,
  currentLocale,
}: {
  data: AssetHostDashboardResponse | undefined;
  currentLocale: 'ru-RU' | 'en-EN';
}): React.ReactElement {
  const {
    hostImportance,
    isShowImportance,
    setShowImportance,
    errCreateHostMutation,
    isSavedImportance,
    setSavedImportance,
    setSavedHostType,
    isShowHostType,
    setShowHostType,
    hostSubType,
    isSavedHostType,
    perimeterImpactText,
    isShowPerimeterImpact,
    setShowPerimeterImpact,
    isSavedPerimeterImpact,
    setSavedPerimeterImpact,
    prepareAssetData,
  } = useAssetCreatePropContext();

  const { changeImportance, changeHostType, changePerimeterImpactText } = useHostActions();

  useEffect(() => {
    return () => {
      setSavedImportance(false);
      setSavedHostType(false);
      setSavedPerimeterImpact(false);
      setShowImportance(false);
      setShowHostType(false);
      setShowPerimeterImpact(false);
    };
  }, []);

  return (
    <table className="column column-third">
      <tbody>
        <tr>
          <td>
            <span>{localeDashboardForm[currentLocale].hostImportanceText}</span>
          </td>
          <td className={styles.tdAssetMutableVals}>
            {!isShowImportance && (
              <Button
                fill="modal"
                onClick={(): void => prepareAssetData(setShowImportance, isShowImportance, data)}
              >
                <span>
                  {data?.importance
                    ? (localeDashboardForm[currentLocale].hostImportanceVals[
                        data.importance
                      ] as keyof IAssetImportanceValue)
                    : '-'}
                </span>
              </Button>
            )}
            {isShowImportance && (
              <DropDownList
                data={hostImportance}
                name="hostImportance"
                id="val"
                textField="text"
                onChange={changeImportance}
                defaultValue={{ val: '', text: localeDashboardForm[currentLocale].selectValue }}
                opened={isShowImportance}
                style={{ width: '180px' }}
              />
            )}
            {!errCreateHostMutation && isSavedImportance && (
              <div className={styles.assetMutableValsSaved}>
                <span>{localeDashboardForm[currentLocale].savedText}</span>
              </div>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeDashboardForm[currentLocale].hostType}</span>
          </td>
          <td className={styles.tdAssetMutableVals}>
            {!isShowHostType && (
              <Button
                fill="modal"
                onClick={(): void => prepareAssetData(setShowHostType, isShowHostType, data)}
              >
                <span>
                  {data?.subType
                    ? (localeDashboardForm[currentLocale].hostImportance[
                        data.subType
                      ] as keyof IHostImportance)
                    : '-'}
                </span>
              </Button>
            )}
            {isShowHostType && (
              <DropDownList
                data={hostSubType}
                name="hostSubType"
                id="val"
                textField="text"
                onChange={changeHostType}
                defaultValue={{ val: '', text: localeDashboardForm[currentLocale].selectValue }}
                opened={isShowHostType}
                style={{ width: '198px' }}
              />
            )}
            {!errCreateHostMutation && isSavedHostType && (
              <div className={styles.assetMutableValsSaved}>
                <span>{localeDashboardForm[currentLocale].savedText}</span>
              </div>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeDashboardForm[currentLocale].form.perimeterImpact}</span>
          </td>
          <td className={styles.tdAssetMutableVals}>
            {!isShowPerimeterImpact && (
              <Button
                fill="modal"
                onClick={(): void =>
                  prepareAssetData(setShowPerimeterImpact, isShowPerimeterImpact, data)
                }
              >
                <span>
                  {data?.perimeterImpact === 1
                    ? localeDashboardForm[currentLocale].yes
                    : localeDashboardForm[currentLocale].no}
                </span>
              </Button>
            )}
            {isShowPerimeterImpact && (
              <DropDownList
                data={perimeterImpactText}
                name="perimeterImpactText"
                id="val"
                textField="text"
                onChange={changePerimeterImpactText}
                defaultValue={{ val: '', text: localeDashboardForm[currentLocale].selectValue }}
                opened={isShowPerimeterImpact}
                style={{ width: '180px' }}
              />
            )}
            {!errCreateHostMutation && isSavedPerimeterImpact && (
              <div className={styles.assetMutableValsSaved}>
                <span>{localeDashboardForm[currentLocale].savedText}</span>
              </div>
            )}
            <div className={styles.vulnsMetricItem}>
              <CommonTooltip>
                {localeDashboardForm[currentLocale].form.perimeterImpactInfo}
              </CommonTooltip>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
