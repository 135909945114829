import { useHistory } from 'react-router-dom';
import locale from '../../utils/i18n/commonLocales/errorMessage.json';
import './boundaryErrorComponent.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

interface IBoundaryError {
  error: { message: string };
}

export function BoundaryErrorComponent({ error }: IBoundaryError): React.ReactElement {
  const history = useHistory();

  return (
    <div className="boundary-error">
      <p>{locale[currentLocale].boundaryErrorTitle}</p>
      <div>
        <span>{locale[currentLocale].boundaryErrorText}</span>
        <span className="error-message">{error.message}</span>
      </div>
      <button
        className="k-button k-button-md k-button-rectangle k-button-solid k-button-solid-base k-rounded-md"
        onClick={(): void => history.goBack()}
        type="button"
      >
        {locale[currentLocale].boundaryErrorGoBack}
      </button>
    </div>
  );
}
