/* eslint-disable react/jsx-pascal-case */
import { useState, useEffect, useRef } from 'react';
import { Checkbox, Input, InputChangeEvent } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useQueryClient } from 'react-query';
import localeSettings from '../../../utils/i18n/settingsLocales/settings.json';
import type { ConfigResponse } from '../../../types/__generated/on-premise-solution/api/configResponse.v1';
import { patchConfig } from '../../../services/settings-service';
import test from '../../../types/__generated/on-premise-solution/api/configUpdateRequest.v1.schema.json';
import type { ConfigUpdateRequest } from '../../../types/__generated/on-premise-solution/api/configUpdateRequest.v1';
import styles from './Settings.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

export function SmtpTab({ smtp }: { smtp: ConfigResponse['smtp'] }): React.ReactElement {
  const [name, setName] = useState<ConfigUpdateRequest['smtp.sender.name']>(smtp?.sender.name);
  const [mail, setMail] = useState<ConfigUpdateRequest['smtp.sender.email']>(smtp?.sender.email);
  const [host, setHost] = useState<ConfigUpdateRequest['smtp.transport.smtp.host']>(
    smtp?.transport.smtp?.host,
  );
  const [port, setPort] = useState<ConfigUpdateRequest['smtp.transport.smtp.port']>(
    smtp?.transport.smtp?.port,
  );
  const [transportService, setTransportService] = useState<{
    value: ConfigUpdateRequest['smtp.transport.service'] | '';
    text: string;
  }>({
    value: smtp?.transport.service || '',
    text: smtp?.transport.service || localeSettings[currentLocale].formSmtp.custom,
  });
  // const [service, setService] = useState<string>('');
  const [user, setUser] = useState<ConfigUpdateRequest['smtp.transport.auth.user']>(
    smtp?.transport.auth.user,
  );
  const [password, setPassword] = useState<ConfigUpdateRequest['smtp.transport.auth.password']>('');
  const [isSecureConnection, setIsSecureConnection] = useState<
    ConfigUpdateRequest['smtp.transport.smtp.secure']
  >(smtp?.transport.smtp?.secure || false);

  const queryClient = useQueryClient();

  const timer = useRef<NodeJS.Timeout | null>(null);
  const [isShowSavedMessage, setShowSavedMessage] = useState(false);

  const showSavedMessage = (): void => {
    setShowSavedMessage(true);
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setShowSavedMessage(false);
    }, 3000);
  };

  useEffect(() => {
    setName(smtp?.sender.name);
    setMail(smtp?.sender.email);
    setHost(smtp?.transport.smtp?.host);
    setPort(smtp?.transport.smtp?.port);
    setTransportService({
      value: smtp?.transport.service || '',
      text: smtp?.transport.service || localeSettings[currentLocale].formSmtp.custom,
    });
    setUser(smtp?.transport.auth.user);
    setIsSecureConnection(smtp?.transport.smtp?.secure || false);
  }, [smtp]);

  const serviceChange = (e: any): void => {
    setHost('');
    setPort('');
    setIsSecureConnection(false);
    setTransportService(e.target.value);
  };

  const saveConfig = (): void => {
    const newConfig: ConfigUpdateRequest = {
      'smtp.sender.name': name || null,
      'smtp.sender.email': mail || null,
      'smtp.transport.auth.user': user || null,
      'smtp.transport.smtp.host': transportService.value ? null : host || null,
      'smtp.transport.smtp.port': transportService.value ? null : port || null,
      'smtp.transport.smtp.secure': transportService.value ? null : isSecureConnection,
    };
    if (password) newConfig['smtp.transport.auth.password'] = password || null;
    if (transportService.value)
      newConfig['smtp.transport.service'] = transportService.value || null;
    else {
      newConfig['smtp.transport.service'] = null;
    }
    patchConfig(newConfig).then((data) => {
      queryClient.setQueryData('settingsConfig', data);
      showSavedMessage();
    });
  };

  const serviceList = test.properties['smtp.transport.service'].enum.map((service) => ({
    value: service || '',
    text: service || localeSettings[currentLocale].formSmtp.custom,
  }));

  return (
    <>
      <div className={styles.smtp}>
        <div className={styles.setting}>
          <span className={styles.head}>{localeSettings[currentLocale].formSmtp.name}</span>
          <Input
            type="text"
            value={name as string | undefined}
            onChange={(e: InputChangeEvent): void =>
              setName(e.target.value as ConfigUpdateRequest['smtp.sender.name'])
            }
          />
        </div>
        <div className={styles.setting}>
          <span className={styles.head}>{localeSettings[currentLocale].formSmtp.mail}</span>
          <Input
            type="text"
            value={mail as string | undefined}
            onChange={(e: InputChangeEvent): void =>
              setMail(e.target.value as ConfigUpdateRequest['smtp.sender.email'])
            }
          />
        </div>
        <div className={styles.setting}>
          <span className={styles.head}>{localeSettings[currentLocale].formSmtp.service}</span>
          <DropDownList
            data={serviceList}
            value={transportService}
            defaultValue={transportService}
            textField="text"
            onChange={serviceChange}
          />
        </div>
        {!transportService.value && (
          <>
            <div className={styles.setting}>
              <span className={styles.head}>{localeSettings[currentLocale].formSmtp.host}</span>
              <Input
                type="text"
                value={host as string | undefined}
                onChange={(e: InputChangeEvent): void =>
                  setHost(e.target.value as ConfigUpdateRequest['smtp.transport.smtp.host'])
                }
              />
            </div>
            <div className={styles.setting}>
              <span className={styles.head}>{localeSettings[currentLocale].formSmtp.port}</span>
              <Input
                type="text"
                value={port as string | undefined}
                onChange={(e: InputChangeEvent): void =>
                  setPort(e.target.value as ConfigUpdateRequest['smtp.transport.smtp.port'])
                }
              />
            </div>
            <div className={styles.setting}>
              <Checkbox
                value={isSecureConnection}
                onChange={(e): void => {
                  setIsSecureConnection(
                    e.value as ConfigUpdateRequest['smtp.transport.smtp.secure'],
                  );
                  setTransportService({
                    value: '',
                    text: localeSettings[currentLocale].formSmtp.custom,
                  });
                }}
                label={localeSettings[currentLocale].formSmtp.connectionType}
              />
            </div>
          </>
        )}
        <div className={styles.setting}>
          <span className={styles.head}>{localeSettings[currentLocale].formSmtp.user}</span>
          <Input
            type="text"
            value={user as string | undefined}
            onChange={(e: InputChangeEvent): void =>
              setUser(e.target.value as ConfigUpdateRequest['smtp.transport.auth.user'])
            }
          />
        </div>
        <div className={styles.setting}>
          <span className={styles.head}>{localeSettings[currentLocale].formSmtp.password}</span>
          <Input
            type="password"
            value={password as string}
            onChange={(e: InputChangeEvent): void =>
              setPassword(e.target.value as ConfigUpdateRequest['smtp.transport.auth.password'])
            }
          />
        </div>
      </div>
      <div className={styles.save_button_block}>
        <Button
          onClick={saveConfig}
          className="action"
          disabled={
            name === smtp?.sender.name &&
            mail === smtp?.sender.email &&
            host === smtp?.transport.smtp?.host &&
            port === smtp?.transport.smtp?.port &&
            transportService.value === (smtp?.transport.service || '') &&
            transportService.text ===
              (smtp?.transport.service || localeSettings[currentLocale].formSmtp.custom) &&
            user === smtp?.transport.auth.user &&
            isSecureConnection === (smtp?.transport.smtp?.secure || false) &&
            !password
          }
        >
          {localeSettings[currentLocale].saveBtn}
        </Button>
        {isShowSavedMessage && (
          <span className={styles.saved_message}>
            <i>{localeSettings[currentLocale].formSmtp.savedMessage}</i>
          </span>
        )}
      </div>
    </>
  );
}
