import type { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import type { TotalAssetVulnerabillitiesResponse } from '../../../../types/__generated/on-premise-solution/api/totalAssetVulnerabillitiesResponse.v1';
import localeDashboards from '../../../../utils/i18n/dashboardLocale/dashboardLayout.json';
import styles from '../Dashboard.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeDashboards;

export function VulnerabilitiesCount({
  data,
}: {
  data: TotalAssetVulnerabillitiesResponse | undefined;
}): ReactElement {
  return (
    <div
      className={styles.number_of_assets}
      //   style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      {/* <NavLink className="dashboard__link" to="/lk/security/vulnerabilities">
        <h2 style={{ fontSize: '36px' }}>{data?.total || 0}</h2>
      </NavLink> */}
      <table className="column">
        <tbody>
          <tr>
            <td className={styles.asset_type} style={{ fontSize: '24px' }}>
              {localeDashboards[currentLocale].assetsCount.total}
            </td>
            <td className={styles.asset_count} style={{ fontSize: '28px' }}>
              <NavLink
                style={{ fontWeight: 'bold' }}
                className="dashboard__link"
                to="/lk/security/vulnerabilities"
              >
                {data?.total || '-'}
              </NavLink>
            </td>
          </tr>
          {data &&
            data.data &&
            data.data.map((d) => {
              const totalTypeName = `total${
                d.label === 'host' || d.label === 'image'
                  ? d.label.charAt(0).toUpperCase() + d.label.slice(1)
                  : 'NetworkDevice'
              }Assets`;

              return (
                <tr>
                  <td className={styles.asset_type}>
                    {
                      localeDashboards[currentLocale].vulnsCount[
                        d.label === 'host' || d.label === 'image' ? d.label : 'device'
                      ]
                    }
                  </td>
                  <td className={styles.asset_count} style={{ color: '#333' }}>
                    {/* <NavLink
                      className="dashboard__link"
                      to={`/lk/security/vulnerabilities?skip=0&limit=15&sortField=${totalTypeName}&sortOrder=desc&${totalTypeName}[$gte]=1`}
                    > */}
                    {d.value || '-'}
                    {/* </NavLink> */}
                  </td>
                </tr>
              );
            })}
          {/* <tr>
            <td className={styles.asset_type}>
              {localeDashboards[currentLocale].assetsCount.host}
            </td>
            <td className={styles.asset_count}>
              <NavLink
                className="dashboard__link"
                to="/lk/security/vulnerabilities?skip=0&limit=15&sortField=totalHostAssets&sortOrder=desc&totalHostAssets[$gte]=1"
              >
                120
              </NavLink>
            </td>
          </tr> */}
          {/* <tr>
            <td className={styles.asset_type}>
              {localeDashboards[currentLocale].assetsCount.image}
            </td>
            <td className={styles.asset_count}>
              <NavLink
                className="dashboard__link"
                to="/lk/security/vulnerabilities?skip=0&limit=15&sortField=totalImageAssets&sortOrder=desc&totalImageAssets[$eq]=1"
              >
                77
              </NavLink>
            </td>
          </tr> */}
          {/* <tr>
            <td className={styles.asset_type}>
              {localeDashboards[currentLocale].assetsCount.device}
            </td>
            <td className={styles.asset_count}>
              <NavLink
                className="dashboard__link"
                to="/lk/security/vulnerabilities?skip=0&limit=15&sortField=totalNetworkDeviceAssets&sortOrder=desc&totalNetworkDeviceAssets[$eq]=1"
              >
                12
              </NavLink>
            </td>
          </tr> */}
        </tbody>
      </table>
    </div>
  );
}
