import { commonInstance } from '../lib/axios/hooks/useCommonAxios';
import type { BulletinResult } from '../types/__generated/cloud/common/bulletinResult.v1';
import type { DecodedCVSSVector } from '../types/__generated/cloud/common/decodedCVSSVector.v1';

export async function getBulletins(id: string | undefined | null): Promise<BulletinResult> {
  const result = await commonInstance.get<BulletinResult>('/v1/info/bulletin', {
    params: {
      id,
    },
  });

  return result.data;
}

export async function decodeCvss(vector: string | undefined | null): Promise<DecodedCVSSVector> {
  const result = await commonInstance.get<DecodedCVSSVector>(
    `/v1/tools/decode/cvss-vector?vector=${vector}`,
  );

  return result.data;
}
