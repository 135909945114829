import './App.scss';
import { Redirect, Route } from 'react-router';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
/* CLDR Data */
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';
import ruNumbers from 'cldr-numbers-full/main/ru/numbers.json';
import ruLocalCurrency from 'cldr-numbers-full/main/ru/currencies.json';
import ruCaGregorian from 'cldr-dates-full/main/ru/ca-gregorian.json';
import ruDateFields from 'cldr-dates-full/main/ru/dateFields.json';
import usNumbers from 'cldr-numbers-full/main/en/numbers.json';
import usLocalCurrency from 'cldr-numbers-full/main/en/currencies.json';
import usCaGregorian from 'cldr-dates-full/main/en/ca-gregorian.json';
import usDateFields from 'cldr-dates-full/main/en/dateFields.json';
import { IntlProvider, load } from '@progress/kendo-react-intl';
import { NotificationComponent } from './components/features/NotificationComponent/NotificationComponent';
import { useNotificationContext } from './hooks/useNotificationContext';
import { getUserData } from './services/local-storage-service';
import { Drawer } from './layouts/Drawer';
import packageJsonFile from '../package.json';
import { LoginContainer } from './components/features/login/LoginContainer';
import { CommonContextProvider } from './hooks/useCommonContext';
import { CredentialContextProvider } from './hooks/useCredentialContext';

load(
  likelySubtags,
  currencyData,
  weekData,
  ruNumbers,
  ruLocalCurrency,
  ruCaGregorian,
  ruDateFields,
  usNumbers,
  usLocalCurrency,
  usCaGregorian,
  usDateFields,
);

export function App(): React.ReactElement {
  const queryClient = new QueryClient();
  const userData = getUserData();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line camelcase
  window._vio_onpremise_frontend_version = packageJsonFile.version;

  const { notifications } = useNotificationContext();

  return (
    <QueryClientProvider client={queryClient}>
      <IntlProvider locale="ru-RU">
        <CommonContextProvider>
          <div className="App">
            <Route path="/lk" component={Drawer} />
            <Route path="/auth" component={LoginContainer} />
            <Route exact path="/">
              {userData ? <Redirect to="/lk/audit/tasks" /> : <LoginContainer />}
            </Route>
            {notifications && <NotificationComponent />}
          </div>
        </CommonContextProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </IntlProvider>
    </QueryClientProvider>
  );
}
