/* eslint-disable react/jsx-no-useless-fragment */
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import type { InventorizationResponse } from '../../../../../../types/__generated/on-premise-solution/api/inventorizationResponse.v1';
import locale from '../../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { WrapperMenu } from '../WrapperMenu';
import { SizeCell } from '../../../../../common/cells/SizeCell';
import { StandartCell } from '../../../../../common/cells/StandartCell';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function DiskPhysical({ data }: { data: InventorizationResponse }): React.ReactElement {
  return (
    <>
      {(data.type === 'linux' || data.type === 'windows') &&
        data.result.disk?.map((disk) => {
          if (disk.info && 'id' in disk.info && disk.info.id) {
            return (
              <WrapperMenu title={disk.info?.name}>
                <WrapperMenu title={locale[currentLocale].disks.titleInfo}>
                  <table className="column">
                    <tr>
                      <td>
                        <span>{locale[currentLocale].disks.index}</span>
                      </td>
                      <td>{disk.info?.disk_index}</td>
                    </tr>
                    <tr>
                      <td>
                        <span>{locale[currentLocale].disks.type}</span>
                      </td>
                      <td>{disk.info?.type}</td>
                    </tr>
                    <tr>
                      <td>
                        <span>{locale[currentLocale].disks.size}</span>
                      </td>
                      <td>
                        {disk.info?.disk_size} {locale[currentLocale].sizeGb}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{locale[currentLocale].disks.partitions}</span>
                      </td>
                      {data.type === 'linux' &&
                      disk.info &&
                      'partitions' in disk.info &&
                      disk.info?.partitions &&
                      typeof disk.info?.partitions === 'string' ? (
                        <td>{disk.info?.partitions}</td>
                      ) : (
                        <td>-</td>
                      )}
                    </tr>
                  </table>
                  <table className="column">
                    <tr>
                      <td>
                        <span>{locale[currentLocale].disks.model}</span>
                      </td>
                      {data.type === 'linux' &&
                      disk.info &&
                      'hardware_model' in disk.info &&
                      disk.info?.hardware_model &&
                      typeof disk.info?.hardware_model === 'string' ? (
                        <td>{disk.info?.hardware_model}</td>
                      ) : (
                        <td>-</td>
                      )}
                    </tr>
                    <tr>
                      <td>
                        <span>{locale[currentLocale].disks.manufacturer}</span>
                      </td>
                      {data.type === 'linux' &&
                      disk.info &&
                      'manufacturer' in disk.info &&
                      disk.info?.manufacturer &&
                      typeof disk.info?.manufacturer === 'string' ? (
                        <td>{disk.info?.manufacturer}</td>
                      ) : (
                        <td>-</td>
                      )}
                    </tr>
                    <tr>
                      <td>
                        <span>{locale[currentLocale].disks.serial}</span>
                      </td>
                      {data.type === 'linux' &&
                      disk.info &&
                      'serial' in disk.info &&
                      disk.info.serial &&
                      typeof disk.info.serial === 'string' ? (
                        <td>{disk.info?.serial}</td>
                      ) : (
                        <td>-</td>
                      )}
                    </tr>
                    <tr>
                      <td>
                        <span>{locale[currentLocale].disks.name}</span>
                      </td>
                      {disk.info && 'name' in disk.info && disk.info.name ? (
                        <td>{disk.info.name}</td>
                      ) : (
                        <td>-</td>
                      )}
                    </tr>
                  </table>
                  <table className="column">
                    <tr>
                      <td>
                        <span>{locale[currentLocale].disks.identifier}</span>
                      </td>
                      {data.type === 'linux' &&
                      disk.info &&
                      'id' in disk.info &&
                      disk.info?.id &&
                      typeof disk.info?.id === 'string' ? (
                        <td>{disk.info?.id}</td>
                      ) : (
                        <td>-</td>
                      )}
                    </tr>
                    <tr>
                      <td>
                        <span>{locale[currentLocale].disks.description}</span>
                      </td>
                      {disk.info && 'description' in disk.info ? (
                        <td>{disk.info?.description}</td>
                      ) : (
                        <td>-</td>
                      )}
                    </tr>
                  </table>
                </WrapperMenu>
                <WrapperMenu title={locale[currentLocale].partitions.title}>
                  <Grid data={disk.partitions} scrollable="none" className="no-margin-top">
                    <GridColumn
                      field="device"
                      title={locale[currentLocale].partitions.device}
                      cell={StandartCell}
                    />
                    <GridColumn
                      field="partition"
                      title={locale[currentLocale].partitions.partition}
                      cell={StandartCell}
                    />
                    <GridColumn
                      field="type"
                      title={locale[currentLocale].partitions.type}
                      cell={StandartCell}
                    />
                    <GridColumn
                      field="size"
                      title={locale[currentLocale].partitions.size}
                      cell={SizeCell}
                    />
                  </Grid>
                </WrapperMenu>
              </WrapperMenu>
            );
          }

          return null;
        })}
    </>
  );
}
