/* eslint-disable react/jsx-pascal-case */
import { Dialog } from '@progress/kendo-react-dialogs';
import { useCallback, useLayoutEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
  ChartValueAxis,
  ChartValueAxisItem,
} from '@progress/kendo-react-charts';
import { Button } from '../../baseElements/Button';
import styles from './vectorModal.module.scss';
import localeAuditTask from '../../../../utils/i18n/taskLocale/auditTask.json';
import { useAuditModalContext } from '../../../../hooks/useAuditModalContext';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { decodeCvss } from '../../../../services/common-service';
import type { DecodedCVSSVector } from '../../../../types/__generated/cloud/common/decodedCVSSVector.v1';
import { returnClassName } from '../../../../lib/axios/helpers';
import { ScoresInfoBlock } from './ScoresInfoBlock';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

export function VectorModal(): React.ReactElement {
  const queryClient = useQueryClient();

  const { cvssVector, setCvssVector, setOpen } = useAuditModalContext();

  const handleCloseModal = useCallback((): void => {
    setCvssVector(null);
    setOpen(false);
    // queryClient.invalidateQueries(['decodedVector']);
  }, [setCvssVector, setOpen]);

  function calculateCvssScoreColor(score: number): string {
    if (score >= 0 && score <= 3.9) return '#00bb00';

    if (score >= 3.9 && score <= 6.9) return '#ffb800';

    if (score >= 6.9 && score <= 8.9) return '#ff6200';

    if (score > 8.9) return '#dc0000';

    return '#000000';
  }

  useLayoutEffect(() => {
    queryClient.invalidateQueries(['decodedVector']);
  }, []);

  const query = useQuery<DecodedCVSSVector, ApiError>(
    ['decodedVector'],
    () => decodeCvss(cvssVector),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      keepPreviousData: true,
    },
    // {
    //   retry: false,
    // },
  );

  // useEffect(() => {

  // })

  return (
    <>
      {query.error ? (
        <Dialog title={`${cvssVector}`} onClose={handleCloseModal} className="vector-modal modal">
          <div className="k-form">
            <div className="form-content">
              <p className="nodata vector-content">
                {localeAuditTask[currentLocale].vectorModal.noData}
              </p>
            </div>
            <div className="k-form-buttons">
              <Button type="button" onClick={handleCloseModal}>
                {localeAuditTask[currentLocale].close}
              </Button>
            </div>
          </div>
        </Dialog>
      ) : (
        <Dialog title={`${cvssVector}`} onClose={handleCloseModal} className="vector-modal">
          <div className="k-form">
            <div className="form-content vector-content">
              <div className={styles.vector_info}>
                <table className={`${styles.scores_info} ${styles.info_block}`}>
                  <tr>
                    <td className={styles.score_name}>CVSS Base Score:</td>
                    <td>
                      <span className={returnClassName(query.data?.cvss?.base.score.toString())}>
                        {query.data?.cvss?.base.score}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Impact Subscore:</td>
                    <td>
                      <span
                        className={returnClassName(
                          query.data?.cvss?.base.impact.subscore.toString(),
                        )}
                      >
                        {query.data?.cvss?.base.impact.subscore || '0.0'}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Exploitability Subscore:</td>
                    <td>
                      <span
                        className={returnClassName(
                          query.data?.cvss?.base.exploitability.subscore.toString(),
                        )}
                      >
                        {query.data?.cvss?.base.exploitability.subscore || '0.0'}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className={styles.score_name}>CVSS Temporal Score:</td>
                    <td>
                      <span
                        className={returnClassName(query.data?.cvss?.temporal?.score.toString())}
                      >
                        {query.data?.cvss?.temporal?.score || '0.0'}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>CVSS Environmental Score:</td>
                    <td>
                      <span
                        className={returnClassName(
                          query.data?.cvss?.environmental?.score?.toString(),
                        )}
                      >
                        {query.data?.cvss?.environmental?.score || '0.0'}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Modified Impact Subscore:</td>
                    <td>
                      <span
                        className={returnClassName(
                          query.data?.cvss?.environmental?.securityRequirements?.subscore?.toString(),
                        )}
                      >
                        {query.data?.cvss?.environmental?.securityRequirements?.subscore || '0.0'}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className={styles.score_name}>Overall CVSS Score:</td>
                    <td>
                      <span className={returnClassName(query.data?.cvss?.score.toString())}>
                        {query.data?.cvss?.score || '0.0'}
                      </span>
                    </td>
                  </tr>
                </table>
                <div className={styles.info_block}>
                  <Chart style={{ width: '240px', height: '240px' }}>
                    <ChartTitle text="Base score" />
                    <ChartValueAxis>
                      <ChartValueAxisItem min={0} max={10} />
                    </ChartValueAxis>
                    {/* <ChartCategoryAxis>
                  <ChartCategoryAxisItem categories={['Base', 'Impact', 'Exploitability']} />
                </ChartCategoryAxis> */}
                    <ChartSeries>
                      <ChartSeriesItem
                        data={[
                          {
                            metricGroupName: 'Base',
                            score: query.data?.cvss?.base.score || 0,
                            color: calculateCvssScoreColor(query.data?.cvss?.base.score || 0),
                            test: '10',
                          },
                          {
                            metricGroupName: 'Impact',
                            score: query.data?.cvss?.base.impact.subscore || 0,
                            color: calculateCvssScoreColor(
                              query.data?.cvss?.base.impact.subscore || 0,
                            ),
                            test: '10',
                          },
                          {
                            metricGroupName: 'Exploitability',
                            score: query.data?.cvss?.base.exploitability.subscore || 0,
                            color: calculateCvssScoreColor(
                              query.data?.cvss?.base.exploitability.subscore || 0,
                            ),
                            test: '10',
                          },
                        ]}
                        tooltip={{ visible: true, color: '#33333', background: '#000000' }}
                        type="column"
                        field="score"
                        categoryField="metricGroupName"
                        colorField="color"
                        gap={1}
                        upperField="test"
                      />
                    </ChartSeries>
                  </Chart>
                </div>
                <div className={styles.info_block}>
                  <Chart style={{ width: '100px', height: '240px', zIndex: '0' }}>
                    <ChartValueAxis>
                      <ChartValueAxisItem min={0} max={10} />
                    </ChartValueAxis>
                    <ChartTitle text="Temporal" />
                    {/* <ChartCategoryAxis>
                  <ChartCategoryAxisItem categories={['asd', 'ddd']} />
                </ChartCategoryAxis> */}
                    <ChartSeries>
                      <ChartSeriesItem
                        // labels={{ align: 'center', visible: true }}
                        data={[
                          {
                            metricGroupName: 'Temporal',
                            score: query.data?.cvss?.temporal?.score || 0,
                            color: calculateCvssScoreColor(query.data?.cvss?.temporal?.score || 0),
                          },
                        ]}
                        tooltip={{ visible: true, color: '#33333', background: '#000000' }}
                        type="column"
                        field="score"
                        categoryField="metricGroupName"
                        colorField="color"
                        gap={1}
                      />
                    </ChartSeries>
                  </Chart>
                </div>
                <div className={styles.info_block}>
                  <Chart style={{ width: '200px', height: '254px', zIndex: '0' }}>
                    <ChartValueAxis>
                      <ChartValueAxisItem min={0} max={10} />
                    </ChartValueAxis>
                    <ChartTitle text="Environmental" />
                    <ChartSeries>
                      <ChartSeriesItem
                        // labels={{ align: 'center', visible: true }}
                        data={[
                          {
                            metricGroupName: 'Environmental',
                            score: query.data?.cvss?.environmental?.score || 0,
                            color: calculateCvssScoreColor(
                              query.data?.cvss?.environmental?.score || 0,
                            ),
                          },
                          {
                            metricGroupName: 'Modified \nImpact',
                            score:
                              query.data?.cvss?.environmental?.securityRequirements?.subscore || 0,
                            color: calculateCvssScoreColor(
                              query.data?.cvss?.environmental?.securityRequirements?.subscore || 0,
                            ),
                          },
                        ]}
                        tooltip={{ visible: true, color: '#33333', background: '#000000' }}
                        type="column"
                        field="score"
                        categoryField="metricGroupName"
                        colorField="color"
                        gap={1}
                        minSize={10}
                      />
                    </ChartSeries>
                  </Chart>
                </div>
                <div className={styles.info_block}>
                  <Chart style={{ width: '100px', height: '240px', zIndex: '0' }}>
                    <ChartValueAxis>
                      <ChartValueAxisItem min={0} max={10} />
                    </ChartValueAxis>
                    <ChartTitle text="Overall" />
                    <ChartCategoryAxis>
                      <ChartCategoryAxisItem categories={['asd', 'ddd']} />
                    </ChartCategoryAxis>
                    <ChartSeries>
                      <ChartSeriesItem
                        data={[
                          {
                            metricGroupName: 'Overall',
                            score: query.data?.cvss?.score || 0,
                            color: calculateCvssScoreColor(query.data?.cvss?.score || 0),
                          },
                        ]}
                        tooltip={{ visible: true, color: '#33333', background: '#000000' }}
                        type="column"
                        field="score"
                        categoryField="metricGroupName"
                        colorField="color"
                        gap={1}
                      />
                    </ChartSeries>
                  </Chart>
                </div>
              </div>
              {query.data?.cvss ? <ScoresInfoBlock data={query.data?.cvss} /> : 'No data'}
            </div>
            <div className="k-form-buttons">
              <Button type="button" onClick={handleCloseModal}>
                {localeAuditTask[currentLocale].close}
              </Button>
            </div>
          </div>
        </Dialog>
      )}{' '}
    </>
  );
}
