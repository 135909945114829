/* eslint-disable jsx-a11y/label-has-associated-control */
import { RadioButton } from '@progress/kendo-react-inputs';
import localeDeviceForm from '../../../../utils/i18n/devices/devices.json';
import { useNetDeviceContext } from '../../../../hooks/useNetDeviceContext';
import type { IDeviceType } from '../../../../utils/helpers/types';
import styles from '../deviceComponents/DeviceDescription.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeDeviceForm;

export function RadioInputs(): React.ReactElement {
  const { typeOfDevice, changeType } = useNetDeviceContext();

  return (
    <div className={`${styles.radioInputWrap} form-field-wrapper`}>
      <label className="k-label">{localeDeviceForm[currentLocale].form.type}</label>
      <div className={styles.radioInputBlock}>
        <RadioButton
          name="type"
          value="router"
          checked={typeOfDevice === 'router'}
          label={localeDeviceForm[currentLocale].grid.deviceType['router' as keyof IDeviceType]}
          onChange={changeType}
        />
      </div>
      <div className={styles.radioInputBlock}>
        <RadioButton
          name="type"
          value="firewall"
          className="k-radio"
          checked={typeOfDevice === 'firewall'}
          label={localeDeviceForm[currentLocale].grid.deviceType['firewall' as keyof IDeviceType]}
          onChange={changeType}
        />
      </div>
      <div className={styles.radioInputBlock}>
        <RadioButton
          name="type"
          value="switch"
          checked={typeOfDevice === 'switch'}
          label={localeDeviceForm[currentLocale].grid.deviceType['switch' as keyof IDeviceType]}
          onChange={changeType}
        />
      </div>
      <div className={styles.radioInputBlock}>
        <RadioButton
          name="type"
          value="hypervisor"
          checked={typeOfDevice === 'hypervisor'}
          label={localeDeviceForm[currentLocale].grid.deviceType['hypervisor' as keyof IDeviceType]}
          onChange={changeType}
        />
      </div>
    </div>
  );
}
