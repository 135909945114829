import { Grid, GridColumn, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useState } from 'react';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { WrapperMenu } from '../../../Task/taskIDetails/iDetailsComponents/WrapperMenu';
import { DiffStandartCell } from '../cells/DiffStandartCell';
import { DiffTypeCell } from '../cells/DiffTypeCell';
import { LAZY_PAGEABLE_DATA } from '../../../../../utils/helpers/constants';
import styles from '../../forms/AddAssetForm.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function DiffContainerWrapper({
  container,
  assetType,
  needShowStatus,
}: {
  container: {
    infoData: {
      [k: string]: {
        className?: string | undefined;
        value: any;
      }[];
    };
    fileMountsData: any;
    portBindingsData: any;
    name: string | null;
    newName: string | null;
  };
  assetType: 'linux' | 'windows';
  needShowStatus: boolean;
}): React.ReactElement {
  const [mountsPage, setMountsPage] = useState({ take: 5, skip: 0 });
  const [portsPage, setPortsPage] = useState({ take: 5, skip: 0 });

  const pageMountsChange = (event: GridPageChangeEvent): void => {
    setMountsPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  const pagePortsChange = (event: GridPageChangeEvent): void => {
    setPortsPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  // const wrapperStatuses = [];
  // if (filter === 'all' && Object.keys(data.memory?.old || {}).length)
  //   wrapperStatuses.push('closed');
  // if (filter === 'all' && Object.keys(data.memory?.new || {}).length) wrapperStatuses.push('new');

  const infoStatuses: string[] = [];
  const fileMountsStatuses: string[] = [];
  const portBindingsStatuses: string[] = [];

  Object.values(container.infoData || {}).forEach((containerInfo) => {
    const classNames = containerInfo.filter((ci) => ci.className).map((ci) => ci.className);
    if (classNames.includes(styles.closed)) infoStatuses.push('closed');
    if (classNames.includes(styles.updated)) infoStatuses.push('new');
    if (!classNames.includes(styles.closed) && !classNames.includes(styles.updated))
      infoStatuses.push('noChanges');
  });

  container.fileMountsData.forEach((fm: any) => {
    if ('diffType' && fm.diffType === 'closed') fileMountsStatuses.push('closed');
    else if ('diffType' && fm.diffType === 'updated') fileMountsStatuses.push('new');
    else fileMountsStatuses.push('noChanges');
  });

  container.portBindingsData.forEach((pb: any) => {
    if ('diffType' && pb.diffType === 'closed') portBindingsStatuses.push('closed');
    else if ('diffType' && pb.diffType === 'updated') portBindingsStatuses.push('new');
    else portBindingsStatuses.push('noChanges');
  });

  return Object.keys(container.infoData || {}).length ? (
    <WrapperMenu
      title={container.name}
      diffStatuses={
        needShowStatus
          ? infoStatuses.concat(...portBindingsStatuses, ...fileMountsStatuses)
          : undefined
      }
    >
      {('id' in container.infoData || 'image' in container.infoData) && (
        <>
          <table className="column">
            {'id' in container.infoData && (
              <>
                {container.infoData.id.map((containerId) => (
                  <tr>
                    <td className={containerId.className}>{locale[currentLocale].containers.id}</td>
                    <td className={containerId.className}>{containerId.value || '-'}</td>
                  </tr>
                ))}
              </>
            )}
            {'image' in container.infoData && (
              <>
                {container.infoData.image.map((containerImage) => (
                  <tr>
                    <td className={containerImage.className}>
                      {locale[currentLocale].containers.image}
                    </td>
                    <td className={containerImage.className}>{containerImage.value || '-'}</td>
                  </tr>
                ))}
              </>
            )}
          </table>
          <div className="break" />
        </>
      )}
      {('startedAt' in container.infoData ||
        'status' in container.infoData ||
        'exitCode' in container.infoData) && (
        <table className="column">
          {'startedAt' in container.infoData && (
            <>
              {container.infoData.startedAt.map((containerStartedAt) => (
                <tr>
                  <td className={containerStartedAt.className}>
                    {locale[currentLocale].containers.startedAt}
                  </td>
                  <td className={containerStartedAt.className}>
                    {containerStartedAt.value
                      ? new Date(containerStartedAt.value).toLocaleString()
                      : '-'}
                  </td>
                </tr>
              ))}
            </>
          )}
          {'status' in container.infoData && (
            <>
              {container.infoData.status.map((containerStatus) => (
                <tr>
                  <td className={containerStatus.className}>
                    {locale[currentLocale].containers.status}
                  </td>
                  <td className={containerStatus.className}>{containerStatus.value || '-'}</td>
                </tr>
              ))}
            </>
          )}
          {'exitCode' in container.infoData && (
            <>
              {container.infoData.exitCode.map((containerExitCode) => (
                <tr>
                  <td className={containerExitCode.className}>
                    {locale[currentLocale].containers.exitCode}
                  </td>
                  <td className={containerExitCode.className}>
                    {containerExitCode.value || (containerExitCode.value === 0 ? '0' : '-')}
                  </td>
                </tr>
              ))}
            </>
          )}
        </table>
      )}
      {('finishedAt' in container.infoData ||
        'cmd' in container.infoData ||
        'error' in container.infoData) && (
        <table className="column">
          {'finishedAt' in container.infoData && (
            <>
              {container.infoData.finishedAt.map((containerFinishedAt) => (
                <tr>
                  <td className={containerFinishedAt.className}>
                    {locale[currentLocale].containers.finishedAt}
                  </td>
                  <td className={containerFinishedAt.className}>
                    {containerFinishedAt.value
                      ? new Date(containerFinishedAt.value).toLocaleString()
                      : '-'}
                  </td>
                </tr>
              ))}
            </>
          )}
          {'cmd' in container.infoData && (
            <>
              {container.infoData.cmd.map((containerCmd) => (
                <tr>
                  <td className={containerCmd.className}>{locale[currentLocale].containers.cmd}</td>
                  <td className={containerCmd.className}>
                    {containerCmd?.value?.join(' ') || '-'}
                  </td>
                </tr>
              ))}
            </>
          )}
          {'error' in container.infoData && (
            <>
              {container.infoData.error.map((containerError) => (
                <tr>
                  <td className={containerError.className}>
                    {locale[currentLocale].containers.error}
                  </td>
                  <td className={containerError.className}>{containerError.value || '-'}</td>
                </tr>
              ))}
            </>
          )}
        </table>
      )}
      {'pid' in container.infoData && (
        <table className="column">
          {'pid' in container.infoData && (
            <>
              {container.infoData.pid.map((containerPid) => (
                <tr>
                  <td className={containerPid.className}>{locale[currentLocale].containers.pid}</td>
                  <td className={containerPid.className}>{containerPid.value || '-'}</td>
                </tr>
              ))}
            </>
          )}
        </table>
      )}
      <div className="break" />
      <div>
        {assetType === 'linux' && container.fileMountsData.length ? (
          <WrapperMenu
            title={locale[currentLocale].containers.volumes}
            diffStatuses={needShowStatus ? fileMountsStatuses : undefined}
          >
            <Grid
              data={container.fileMountsData.slice(
                mountsPage.skip,
                mountsPage.take + mountsPage.skip,
              )}
              pageable={LAZY_PAGEABLE_DATA}
              scrollable="none"
              total={container.fileMountsData.length}
              pageSize={mountsPage.take}
              skip={mountsPage.skip}
              onPageChange={pageMountsChange}
              className="no-margin-top"
            >
              <GridColumn
                field="diffType"
                cell={DiffTypeCell}
                title=" "
                width={20}
                sortable={false}
                headerClassName="no-cursor"
              />
              <GridColumn
                field="source"
                title={locale[currentLocale].containers.source}
                cell={DiffStandartCell}
                className="title"
                sortable={false}
                headerClassName="no-cursor"
              />
              <GridColumn
                field="destination"
                title={locale[currentLocale].containers.destination}
                cell={DiffStandartCell}
                className="title"
                sortable={false}
                headerClassName="no-cursor"
              />
              <GridColumn
                field="type"
                title={locale[currentLocale].containers.type}
                cell={DiffStandartCell}
                sortable={false}
                headerClassName="no-cursor"
              />
              <GridColumn
                field="mode"
                title={locale[currentLocale].containers.mode}
                cell={DiffStandartCell}
                sortable={false}
                headerClassName="no-cursor"
              />
            </Grid>
          </WrapperMenu>
        ) : (
          <></>
        )}
        {container.portBindingsData.length ? (
          <>
            <div className="break" />
            <WrapperMenu
              title={locale[currentLocale].containers.ports}
              diffStatuses={needShowStatus ? portBindingsStatuses : undefined}
            >
              <Grid
                data={container.portBindingsData.slice(
                  portsPage.skip,
                  portsPage.take + portsPage.skip,
                )}
                pageable={LAZY_PAGEABLE_DATA}
                scrollable="none"
                total={container.portBindingsData.length}
                pageSize={portsPage.take}
                skip={portsPage.skip}
                onPageChange={pagePortsChange}
                className="no-margin-top"
              >
                <GridColumn
                  field="diffType"
                  cell={DiffTypeCell}
                  title=" "
                  width={20}
                  sortable={false}
                  headerClassName="no-cursor"
                />
                <GridColumn
                  field="hostIp"
                  title={locale[currentLocale].containers.ip}
                  cell={DiffStandartCell}
                  sortable={false}
                  headerClassName="no-cursor"
                />
                <GridColumn
                  field="hostPort"
                  title={locale[currentLocale].containers.port}
                  cell={DiffStandartCell}
                  sortable={false}
                  headerClassName="no-cursor"
                />
                <GridColumn
                  field="containerPort"
                  title={locale[currentLocale].containers.containerPort}
                  cell={DiffStandartCell}
                  sortable={false}
                  headerClassName="no-cursor"
                />
              </Grid>
            </WrapperMenu>
          </>
        ) : (
          <></>
        )}
      </div>
    </WrapperMenu>
  ) : (
    <></>
  );
}
