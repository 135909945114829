/* eslint-disable no-bitwise */
import { useCallback, useEffect, useState } from 'react';
import type { UseMutationResult, UseQueryResult } from 'react-query';
import { useParams } from 'react-router';
import type {
  Tag,
  TagsResponse,
} from '../../types/__generated/on-premise-solution/api/tagsResponse.v1';
import type { TagUpdateResponse } from '../../types/__generated/on-premise-solution/api/tagUpdateResponse.v1';
import type { ApiError } from '../../types/__generated/on-premise-solution/api/apiError.v1';
import type { TagUpdateRequest } from '../../types/__generated/on-premise-solution/api/tagUpdateRequest.v1';
import localeHostDescription from '../../utils/i18n/assetLocales/hostDescription.json';
import { useHostActions } from './useHostActions';
import type { AssetUpdateRequest } from '../../types/__generated/on-premise-solution/api/assetUpdateRequest.v1';
import { useAssetCreatePropContext } from '../useAssetCreatePropContext';
import type { TagCreationRequest } from '../../types/__generated/on-premise-solution/api/tagCreationRequest.v1';
import { getAccountId } from '../../services/local-storage-service';
import type { TagCreationResponse } from '../../types/__generated/on-premise-solution/api/tagCreationResponse.v1';
import { backgroundColorList } from '../../utils/helpers/constants';
import { useDeviceDescription } from './useDeviceDescription';
import { useImageDescription } from './useImageDescription';
import { useHostDescription } from './useHostDescription';
import type { ISecurityVulns, TBulletinId } from '../../utils/helpers/types';

interface IPrepareTagVals {
  e: React.KeyboardEvent<HTMLDivElement>;
  tagsQuery: UseQueryResult<TagsResponse, ApiError>;
  tagName: string;
  mutationNewTag: UseMutationResult<TagCreationResponse, ApiError, TagCreationRequest, unknown>;
  assetId: string;
}
interface IUseAssetActions {
  handleEditTag: (editData: IEditTag) => void;
  addExistingTag: (dataAddExistTag: IAddExistTag) => void;
  getRandomColor(min: number, max: number): string;
  getTextColor(backgroundColor: string): string;
  prepareTagData: (vals: IPrepareTagVals) => void;
  addExistedTag: (
    existedTagName: string,
    tagsQuery: UseQueryResult<TagsResponse, ApiError>,
    assetId: string,
  ) => void;
  addVuln: (vulnId: string) => void;
  selectAllVulnOnPage: (vulns: ISecurityVulns[] | TBulletinId[] | undefined) => void;
}

interface IEditTag {
  name: string;
  editTagError: string | null;
  tags: Tag[] | null | undefined;
  isExistingTag: React.MutableRefObject<boolean>;
  setEditTagError: React.Dispatch<React.SetStateAction<string | null>>;
  mutation: UseMutationResult<TagUpdateResponse, ApiError, TagUpdateRequest, unknown>;
  setHovered: React.Dispatch<React.SetStateAction<string>>;
  keyValue: number | null;
}

interface IAddExistTag {
  query: UseQueryResult<TagsResponse, ApiError>;
  currentTagName: React.MutableRefObject<string | null>;
  existedTagName: string;
  tagId: string;
  tags: Tag[] | null | undefined;
  setHovered: React.Dispatch<React.SetStateAction<string>>;
  setEditTagError: React.Dispatch<React.SetStateAction<string | null>>;
  currentTagId: string;
  assetId: string;
  beforeEditVal: string;
}

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHostDescription;

export function useAssetActions(): IUseAssetActions {
  const { assetId: assetIdFromUrl } = useParams<{ assetId: string }>();

  const { setEditTagData, setTagName, setTagMenu, selectedVulns, setSelectedVulns } =
    useAssetCreatePropContext();

  const { handleEditHost } = useHostActions();

  const { devicename } = useDeviceDescription();

  const { imagename } = useImageDescription();

  const { hostname } = useHostDescription();

  function generateRandomColor(): string {
    return Math.random().toString(16).slice(-6);
  }

  const handleEditTag = useCallback(
    (editData: IEditTag): void => {
      console.log('handleEditTag');

      const {
        name,
        editTagError,
        tags,
        isExistingTag,
        setEditTagError,
        mutation,
        setHovered,
        keyValue,
      } = editData;

      if (tags && !isExistingTag.current) {
        setHovered('');

        const indexAlreadyExistName = tags.findIndex((t) => t.name === name);

        const indexSameTag = tags.findIndex((t, i) => t.name === name && i === keyValue);

        console.log(indexAlreadyExistName, name, tags);

        if (indexAlreadyExistName !== -1 && indexSameTag === -1) {
          setEditTagError(`${localeHostDescription[currentLocale].summary.confirmDelTag}`);
        } else if (indexAlreadyExistName !== -1 && indexSameTag !== -1) {
          setEditTagData(null);
        } else if (indexAlreadyExistName === -1 && !editTagError && name !== '') {
          const payload = {
            name,
          };

          mutation.mutateAsync(payload);
        }
      }
    },
    [setEditTagData],
  );

  const addExistingTag = (dataAddExistTag: IAddExistTag): void => {
    const {
      existedTagName,
      query,
      currentTagName,
      currentTagId,
      tags,
      tagId,
      assetId,
      setHovered,
      setEditTagError,
      beforeEditVal,
    } = dataAddExistTag;
    console.log('addExistingTag', existedTagName, beforeEditVal);

    setTagName(existedTagName);

    const matchingTagNameIndex = query.data?.data.findIndex((t) => t.name === existedTagName);

    if (matchingTagNameIndex === -1) {
      const deletedExistingTag = tags?.filter((t) => t.tagId !== currentTagId).map((t) => t.tagId);

      const dataForUpdateHost = {
        tagIds: [...(deletedExistingTag || []), tagId],
        type: 'host',
      } as AssetUpdateRequest;

      handleEditHost(assetId, dataForUpdateHost);
      setEditTagData(null);
      setHovered('');
      setTagMenu(false);
    } else if (matchingTagNameIndex !== -1 && beforeEditVal !== existedTagName) {
      setTagMenu(false);
      setEditTagError(`${localeHostDescription[currentLocale].summary.confirmDelTag}`);
    } else if (matchingTagNameIndex !== -1 && beforeEditVal === existedTagName) {
      setTagMenu(false);
      setEditTagData(null);
    }
  };
  const addExistedTag = (
    existedTagName: string,
    tagsQuery: UseQueryResult<TagsResponse, ApiError>,
    assetId: string,
  ): void => {
    const foundedExistedTag = tagsQuery.data?.data.filter((t) => t.name === existedTagName);

    if (existedTagName && foundedExistedTag) {
      const val = {
        tagIdsToAdd: [foundedExistedTag[0].tagId],
        type: 'host',
      } as AssetUpdateRequest;
      handleEditHost(assetId, val);
    }
  };

  function getRandomColor(min: number, max: number): string {
    // const index = Math.floor(Math.random() * val);
    const index = Math.floor(Math.random() * (max - min) + min);

    return backgroundColorList[index];
  }

  const prepareTagData = (vals: IPrepareTagVals): void => {
    const { e, tagsQuery, tagName, mutationNewTag, assetId } = vals;

    const accountId = getAccountId();

    const foundedTag = tagsQuery.data?.data.filter((t) => t.name === tagName);
    if (e.key === 'Enter' && (!foundedTag || foundedTag.length === 0)) {
      const payload: TagCreationRequest = {
        accountId: accountId || '',
        name: tagName,
        color: `#${getRandomColor(0, 12)}`,
        // color: `#${generateRandomColor()}`,
      };

      mutationNewTag.mutateAsync(payload);
    } else if (e.key === 'Enter' && foundedTag && foundedTag.length > 0) {
      const val = {
        tagIdsToAdd: [foundedTag[0].tagId],
        type: 'host',
      } as AssetUpdateRequest;
      handleEditHost(assetId, val);
    }
  };

  function getTextColor(bgColor: string): string {
    const rgb = parseInt(bgColor.slice(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;

    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
  }

  const addVuln = (vulnId: string): void => {
    let name;

    if (document.location.pathname.includes('assets/hosts/')) {
      name = hostname;
    } else if (document.location.pathname.includes('assets/devices/')) {
      name = devicename;
    } else {
      name = undefined;
    }

    const selectedVuln = {
      vulnId,
      assetId: assetIdFromUrl,
      hostname: name,
      imageNames: imagename,
    };

    if (selectedVulns && selectedVulns.length > 0) {
      const index = selectedVulns?.findIndex((v) => v.vulnId === vulnId);
      if (index !== -1) {
        const cutSelectedVulns = selectedVulns.filter((v) => v.vulnId !== vulnId);
        setSelectedVulns([...cutSelectedVulns]);
      } else {
        setSelectedVulns([...selectedVulns, selectedVuln]);
      }
    } else {
      setSelectedVulns([selectedVuln]);
    }
  };

  const selectAllVulnOnPage = (vulns: ISecurityVulns[] | TBulletinId[] | undefined): void => {
    const vulnsOnPage = vulns?.map((v) => ({
      vulnId: v.bulletinId,
      assetId: undefined,
      hostname: undefined,
      imageNames: undefined,
    }));

    if (selectedVulns && vulnsOnPage) {
      const selectedAndOnPage = [...selectedVulns, ...vulnsOnPage];

      const duplicatValChecked = selectedAndOnPage.filter(
        (vuln, i, arr) => arr.findIndex((val) => val.vulnId === vuln.vulnId) === i,
      );
      setSelectedVulns(duplicatValChecked);
    }
    if (!selectedVulns && vulnsOnPage) {
      setSelectedVulns(vulnsOnPage);
    }
  };

  return {
    handleEditTag,
    addExistingTag,
    getRandomColor,
    getTextColor,
    prepareTagData,
    addExistedTag,
    addVuln,
    selectAllVulnOnPage,
  };
}
