import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HostMetaBlock } from './HostMetaBlock';
import { useHostCreateContext } from '../../../../hooks/useHostCreateContext';
import { ReinstallHostForm } from '../forms/ReinstallHostForm';
import { useAuditModalContext } from '../../../../hooks/useAuditModalContext';
import { TabsHostTop } from './TabsHostTop';
import { useHostModalContext } from '../../../../hooks/useHostModalContext';
import { GroupListOfHost } from './GroupsEdit';
import { BoundaryErrorComponent } from '../../../common/BoundaryErrorComponent';
import { EditPortModal } from '../../../common/modal/EditPortModal';
import { HostConfirmDel } from './HostConfirmDel';
import { HostDescriptionHeader } from './HostDescriptionHeader';
import { DifferenceAuditModal } from '../DifferenceModal/Audit/DifferenceAuditModal';
import { useDifferenceModalContext } from '../../../../hooks/useDifferenceModalContext';
import { MissingKbModal } from '../../Task/taskVmDetails/vmDetailsComponents/MissingKbModal';
import { DifferenceInvModal } from '../DifferenceModal/Inventorization/DifferenceInvModal';
import { HostConfirmDelTag } from './HostConfirmDelTag';
import { useAssetCreatePropContext } from '../../../../hooks/useAssetCreatePropContext';
import { useHostDescription } from '../../../../hooks/components/useHostDescription';

export function HostDescription(): React.ReactElement {
  const [isConfirm, setConfirm] = useState(false);

  const { assetId } = useParams<{ assetId: string }>();

  const { hostCreateData } = useHostCreateContext();

  const { idKb } = useAuditModalContext();

  const {
    isAuditOpen: isDiffAuditOpen,
    setAuditOpen,
    isInvOpen: isDiffInvOpen,
    setInvOpen,
  } = useDifferenceModalContext();

  const {
    isOpenHostGroups,
    isEditPort,
    hostForm,
    setHostForm,
    setSelectedPkgs,
    setSelectedPkgsWl,
    setMissedKbs,
    setKbsInWl,
  } = useHostModalContext();

  const { tagDataForDel, closeTagWindow, setEditTagColor, setShowAddTag, setSelectedVulns } =
    useAssetCreatePropContext();

  const { assetIdData, hostname } = useHostDescription();

  useEffect(() => {
    document.addEventListener('click', closeTagWindow);

    return () => {
      document.removeEventListener('click', closeTagWindow);
      setEditTagColor(null);
      setShowAddTag(false);
      setAuditOpen(false);
      setInvOpen(false);
    };
  }, []);

  useEffect(() => {
    if (hostname && !hostForm) setHostForm([{ assetId, hostname }]);
  }, [assetId, hostname, setHostForm, hostForm]);

  useEffect(() => {
    return () => {
      setHostForm(null);
      setSelectedPkgs(null);
      setSelectedPkgsWl(null);
      setSelectedVulns(null);
      setKbsInWl(null);
      setMissedKbs(null);
    };
  }, []);

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      {idKb && <MissingKbModal />}
      <div className="hostdescription">
        {isDiffAuditOpen && (
          <DifferenceAuditModal
            ip={assetIdData?.latestInventorization?.ip}
            system={`${assetIdData?.latestInventorization?.os.name} ${assetIdData?.latestInventorization?.os.version}`}
          />
        )}
        {isDiffInvOpen && (
          <DifferenceInvModal
            ip={assetIdData?.latestInventorization?.ip}
            system={`${assetIdData?.latestInventorization?.os.name} ${assetIdData?.latestInventorization?.os.version}`}
          />
        )}
        {isConfirm && (
          <HostConfirmDel
            setConfirm={setConfirm}
            assetName={
              assetIdData?.type === 'host' && assetIdData?.latestInventorization?.hostname
                ? assetIdData?.latestInventorization?.hostname
                : undefined
            }
          />
        )}
        {!isConfirm && tagDataForDel && <HostConfirmDelTag />}
        <HostDescriptionHeader setConfirm={setConfirm} />
        {assetIdData && <HostMetaBlock data={assetIdData} />}
        <TabsHostTop />
        {hostCreateData && typeof hostCreateData.hostname !== 'undefined' && <ReinstallHostForm />}
        {isOpenHostGroups && (
          <GroupListOfHost
            assetId={assetIdData?.type === 'host' ? assetIdData?.assetId : undefined}
            hostname={hostname}
            startGroupsIds={assetIdData?.type === 'host' ? assetIdData?.assetGroupIds : null}
          />
        )}
        {isEditPort && (
          <EditPortModal assetId={assetId} assetType="host" sshPortCurrent={assetIdData?.sshPort} />
        )}
      </div>
    </ErrorBoundary>
  );
}
