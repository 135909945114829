/* eslint-disable no-restricted-globals */
import type { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { useRef } from 'react';
import type { FilterChangeEvent } from '@progress/kendo-react-data-tools';
import { useHistory } from 'react-router-dom';
import { FILTER_INITIAL } from '../../utils/helpers/constants';
import {
  handleFilteringTest,
  IFilterVal,
  setDataInFilter,
} from '../../utils/filtering-in-table-test';
import type { IDataHooks, IPageState } from '../../utils/helpers/types';

export function useFilter(
  filterValue: React.MutableRefObject<CompositeFilterDescriptor | null>,
  setFilter: (value: React.SetStateAction<CompositeFilterDescriptor>) => void,
  customSearch: string,
  setCustomSearch: (value: React.SetStateAction<string>) => void,
  setFilterVal: React.Dispatch<React.SetStateAction<IFilterVal[]>>,
  filterRef: React.MutableRefObject<CompositeFilterDescriptor>,
  filter: CompositeFilterDescriptor,
  setPage: (value: React.SetStateAction<IPageState>) => void,
  page: IPageState,
  dataForHooks: IDataHooks,
): (event: FilterChangeEvent) => void {
  const history = useHistory();

  const timer = useRef<NodeJS.Timeout | null>(null);

  const filterCallback = (event: FilterChangeEvent): void => {
    if (event.filter.filters.length === 0 || event.filter.filters[0] === undefined) {
      filterValue.current = null;
      setFilter(FILTER_INITIAL);
      setCustomSearch('');
      handleFilteringTest(FILTER_INITIAL, setFilterVal);
      filterRef.current = filter;
      if (dataForHooks.urlPath && dataForHooks.componentName !== 'RegistryTaskHistory') {
        history.push({
          pathname: dataForHooks.urlPath,
        });
      }
    } else if (event.filter.filters.length > 0 && customSearch !== '') {
      filterValue.current = {
        filters: [
          {
            field: dataForHooks.name,
            operator: 'contains',
            value: customSearch,
          },
        ],
        logic: 'and',
      } as CompositeFilterDescriptor;
      setFilter(filterValue.current);
      setPage({ skip: 0, take: page.take });
      setCustomSearch('');
    } else {
      // setFilter(event.filter);
      setDataInFilter(event, setFilter, timer, setPage, setFilterVal, page);
    }
  };

  return filterCallback;
}
