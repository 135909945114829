import type { GridCellProps } from '@progress/kendo-react-grid';
import type { ISoftwareList } from '../SoftwareListWin';

export function CellPublisher(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { publisher }: ISoftwareList = dataItem;

  return <td>{publisher ? <span>{publisher}</span> : <span>-</span>}</td>;
}
