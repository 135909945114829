/* eslint-disable react/jsx-pascal-case */
import { Filter } from '@progress/kendo-react-data-tools';
import { Menu, MenuItem } from '@progress/kendo-react-layout';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Typography } from '@progress/kendo-react-common';
import { ErrorBoundary } from 'react-error-boundary';
import { useEffect } from 'react';
import { Button } from '../../../common/baseElements/Button';
import type { IDataHooks } from '../../../../utils/helpers/types';
import './HostList.scss';
import { Input } from '../../../common/baseElements/Input';
import { СommonTableLoader } from '../../../common/tableLoader/CommonTableLoader';
import localeHost from '../../../../utils/i18n/assetLocales/hostList.json';
import { getUrlParamsHosts } from '../../../../utils/helpers/host-list-helper';
import { CommonHostList } from '../../../common/CommonHostList';
import {
  FILTER_HOSTLIST,
  FILTER_HOSTLIST_NOT_SUPER_ADMIN,
} from '../../../../utils/helpers/constant-serv-filter-host';
import { useHostList } from '../../../../hooks/components/useHostList';
import { useHostModalContext } from '../../../../hooks/useHostModalContext';
import { ModalHostDetailById } from './modal/ModalHostDetailById';
import { BoundaryErrorComponent } from '../../../common/BoundaryErrorComponent';
import { getUserData } from '../../../../services/local-storage-service';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHost;

export function HostList(): React.ReactElement {
  const currentUser = getUserData();

  const urlParams = getUrlParamsHosts('HostList');

  const dataForHooks: IDataHooks = {
    name: 'latestInventorization',
    componentName: 'HostList',
  };

  const {
    handleAsset,
    handleRefresh,
    filter,
    onFilterChange,
    customSearch,
    sort,
    dataDashboard,
    page,
    handleCustomSearch,
    total,
    pageChange,
    setSort,
    isLoading,
    isFetching,
    filterStatus,
    isError,
    errorMessage,
  } = useHostList(urlParams, dataForHooks);

  const { hostDetailById, setHostDetailById } = useHostModalContext();

  useEffect(() => {
    return () => setHostDetailById(null);
  }, []);

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      <div className="host-list">
        <div className="common-header-page">
          <Typography.h3>{localeHost[currentLocale].hostTitle}</Typography.h3>
          <div className="topactions">
            {currentUser?.role !== 'reader_user' && (
              <Menu hoverOpenDelay={0} onSelect={handleAsset} className="action">
                <MenuItem text={localeHost[currentLocale].btnCreateHost}>
                  <MenuItem cssStyle={{ width: '140px' }} text="linux" />
                  <MenuItem cssStyle={{ width: '140px' }} text="windows" />
                </MenuItem>
              </Menu>
            )}
            <Button icon="refresh" onClick={handleRefresh}>
              {localeHost[currentLocale].btnRefresh}
            </Button>
          </div>
        </div>
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <div className="filter-line">
              <Filter
                value={filter}
                onChange={onFilterChange}
                fields={
                  currentUser?.role === 'super_admin'
                    ? FILTER_HOSTLIST[currentLocale]
                    : FILTER_HOSTLIST_NOT_SUPER_ADMIN[currentLocale]
                }
              />
              {filter.filters.length === 0 && (
                <Input
                  name="customSearch"
                  placeholder={localeHost[currentLocale].customSearch}
                  value={customSearch}
                  onChange={(e): void => handleCustomSearch(e)}
                />
              )}
            </div>
          </IntlProvider>
        </LocalizationProvider>
        <CommonHostList
          sort={sort}
          dataDashboard={dataDashboard}
          page={page}
          total={total}
          pageChange={pageChange}
          setSort={setSort}
          dataForHooks={dataForHooks}
          conditionSort={15}
        />
        <СommonTableLoader queryData={filterStatus} filteringData={filterStatus} />{' '}
        {hostDetailById && <ModalHostDetailById />}
        {isError && <div className="info alert alert-danger">Error: {errorMessage}</div>}
      </div>
    </ErrorBoundary>
  );
}
