import type { GridCellProps } from '@progress/kendo-react-grid';
import { useState } from 'react';
import { loadMessages } from '@progress/kendo-react-intl';
import { Button } from '../../../common/baseElements/Button';
import { getUserData } from '../../../../services/local-storage-service';
import localeCommonTable from '../../../../utils/i18n/commonLocales/table.json';
import { useWhiteListContext } from '../../../../hooks/useWhiteListContext';
import localeSettings from '../../../../utils/i18n/settingsLocales/settings.json';
import { DeleteModal } from '../../../common/modal/DeleteModal';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

loadMessages(localeCommonTable[currentLocale], currentLocale);

export function CellWhiteListMenu({ dataItem }: GridCellProps): React.ReactElement {
  const currentUser = getUserData();

  const [isConfirm, setConfirm] = useState(false);

  const { setStatusWhiteList, editWhiteList, mutationDeleteWhiteList } = useWhiteListContext();

  const createdByID = dataItem.createdBy.source === 'user' && dataItem.createdBy.id;

  const handleConfirm = (): void => {
    setConfirm(true);
  };

  const handleDelete = (): void => {
    mutationDeleteWhiteList.mutate(dataItem.bulletinWhitelistId);
    setConfirm(false);
  };

  return (
    <>
      <DeleteModal
        isConfirm={isConfirm}
        setConfirm={setConfirm}
        handleDelete={handleDelete}
        name={dataItem.name}
        countInside={dataItem.totalBulletins}
      />
      <td className="cell_menu">
        <div className="groups__menu">
          <div className="dropdown__container">
            <span className="k-icon k-i-more-vertical" />
            <div className="dropdown__content">
              {(currentUser?.role !== 'reader_user' ||
                (currentUser.id === dataItem.userId && dataItem.type === 'local')) && (
                <>
                  <Button
                    fill="menu"
                    onClick={(): void => editWhiteList(dataItem)}
                    disabled={currentUser?.role === 'user' && createdByID !== currentUser?.id}
                  >
                    {localeSettings[currentLocale].whiteList.grid.edit}
                  </Button>
                  <Button
                    fill="menu"
                    onClick={(): void =>
                      setStatusWhiteList(dataItem.isDisabled, dataItem.bulletinWhitelistId)
                    }
                    disabled={currentUser?.role === 'user' && createdByID !== currentUser?.id}
                  >
                    {dataItem.isDisabled
                      ? localeSettings[currentLocale].whiteList.grid.setActiveMenu
                      : localeSettings[currentLocale].whiteList.grid.setNotActive}
                  </Button>
                  <Button
                    fill="menu"
                    onClick={handleConfirm}
                    disabled={currentUser?.role === 'user' && createdByID !== currentUser?.id}
                  >
                    {localeSettings[currentLocale].whiteList.grid.delete}
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </td>
    </>
  );
}
