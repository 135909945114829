import type { GridCellProps } from '@progress/kendo-react-grid';
import type { ArtifactResponse } from '../../../../../types/__generated/on-premise-solution/api/artifactsResponse.v1';
import { BACKEND_DOWNLOAD_URL } from '../../../../../utils/helpers/constants';

export function CellFilePath(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { fileName }: ArtifactResponse = dataItem;

  return (
    <td>
      {/* <span
        onClick={(): void =>
          handleLinkDowload(`${BACKEND_DOWNLOAD_URL}/v1/artifacts/${fileName}`)
        }
        className="link-download"
        role="link"
        tabIndex={0}
        onKeyPress={(): void =>
          handleLinkDowload(`${BACKEND_DOWNLOAD_URL}/v1/artifacts/${fileName}`)
        }
      >
        {fileName}
      </span> */}
      <a
        href={`${BACKEND_DOWNLOAD_URL}/v1/artifacts/${fileName}`}
        className="link-underline"
        download
        target="_blank"
        rel="noreferrer"
      >
        {fileName}
      </a>
    </td>
  );
}
