import { Grid, GridColumn as Column, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useEffect, useMemo, useRef, useState } from 'react';
import { orderBy, type SortDescriptor } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { PAGEABLE_DATA } from '../../../../../utils/helpers/constants';
import localeAuditTask from '../../../../../utils/i18n/taskLocale/auditTask.json';
import type { AuditWindowsDifference } from '../../../../../types/__generated/on-premise-solution/api/auditDifferenceResponse.v1';
import { DiffTypeCell } from '../cells/DiffTypeCell';
import { DiffStandartCell } from '../cells/DiffStandartCell';
import { DiffDateCell } from '../cells/DiffDateCell';
import { DiffFstecStatusCell } from '../cells/DiffFstecStatusCell';
import { FstecStatusHeaderCell } from '../../../Task/taskVmDetails/vmDetailsComponents/cells/FstecStatusHeaderCell';
import { Input } from '../../../../common/baseElements/Input';
import localeVmTask from '../../../../../utils/i18n/taskLocale/vmTask.json';
import { Button } from '../../../../common/baseElements/Button';
import hostDescriptionLocale from '../../../../../utils/i18n/assetLocales/hostDescription.json';
import { DiffFilters } from '../DiffFilters';
import { createMissedKbsData } from '../../../../../utils/helpers/difference-modal-helper';
import { DiffKbIdCell } from '../cells/DiffKbIdCell';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

export function DiffMissingKbs({ data }: { data: AuditWindowsDifference['missedKb'] }) {
  const _export = useRef<ExcelExport | null>(null);
  const [page, setPage] = useState({ take: 10, skip: 0 });
  const sort: SortDescriptor[] = [{ field: 'name', dir: 'asc' }];
  const [searchValue, setSearchValue] = useState('');
  const [filter, setFilter] = useState<
    'all' | 'noChanges' | 'updated' | 'changed' | 'installed' | 'removed'
  >('all');
  const missingKbData = useMemo(() => createMissedKbsData(data), [data]);
  const [missingKbList, setmissingKbList] = useState(missingKbData[filter]);

  const filterList = {
    all: `${hostDescriptionLocale[currentLocale].changes.shortFilters.vulns.all} (${missingKbData.all.length})`,
    noChanges: `${hostDescriptionLocale[currentLocale].changes.shortFilters.vulns.noChanges} (${missingKbData.noChanges.length})`,
    installed: `${hostDescriptionLocale[currentLocale].changes.shortFilters.vulns.discovered} (${missingKbData.installed.length})`,
    removed: `${hostDescriptionLocale[currentLocale].changes.shortFilters.vulns.closed} (${missingKbData.removed.length})`,
  };

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  useEffect(() => {
    setmissingKbList(
      missingKbData[filter].filter((missingKb) => {
        if (!missingKb.id) return false;

        return missingKb.id.toLowerCase().includes(searchValue.toLowerCase());
      }),
    );
    setPage((prev) => ({ ...prev, skip: 0 }));
  }, [filter, searchValue, data]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save(missingKbData.all);
    }
  };

  return (
    <>
      <div className="filter-line">
        <Input
          name="customSearch"
          placeholder={localeAuditTask[currentLocale].customFilterName}
          onChange={handleSearch}
          value={searchValue}
        />
        <Button excel fill="flat" onClick={excelExport}>
          {localeAuditTask[currentLocale].vulnsSoft.table.exportToExcel}
        </Button>
      </div>
      <DiffFilters currentFilter={filter} setFilter={setFilter} filterList={filterList} />
      <ExcelExport ref={_export} fileName={localeVmTask[currentLocale].tabs.missingKBs}>
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <Grid
              pageable={PAGEABLE_DATA}
              sortable={false}
              // sort={sort}
              total={missingKbList ? missingKbList.length : 0}
              skip={page.skip}
              take={page.take}
              onPageChange={pageChange}
              data={orderBy(missingKbList || [], sort)?.slice(page.skip, page.take + page.skip)}
              className="no-margin-top diffaudit__missedkbgrid"
              scrollable="none"
            >
              <Column
                field="diffType"
                cell={DiffTypeCell}
                title=" "
                width={32}
                sortable={false}
                headerClassName="no-cursor"
              />
              <Column
                field="id"
                title={localeAuditTask[currentLocale].isMissedKb.id}
                cell={DiffKbIdCell}
                width="140px"
              />
              <Column
                field="title"
                cell={DiffStandartCell}
                title={localeAuditTask[currentLocale].isMissedKb.title}
              />
              <Column
                field="title"
                title={localeAuditTask[currentLocale].isMissedKb.vstec}
                width="200px"
                cell={DiffFstecStatusCell}
                headerCell={FstecStatusHeaderCell}
              />
              <Column
                field="date.published"
                title={localeAuditTask[currentLocale].isMissedKb.published}
                cell={DiffDateCell}
                width="144px"
              />
              <Column
                field="date.modified"
                title={localeAuditTask[currentLocale].isMissedKb.modified}
                cell={DiffDateCell}
                width="112px"
              />
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      </ExcelExport>
    </>
  );
}
