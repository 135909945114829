import { Dialog } from '@progress/kendo-react-dialogs';
import type { ReactElement } from 'react';
import { Button } from '../baseElements/Button';
import locale from '../../../utils/i18n/commonLocales/confirmDeleteForm.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

function getLocaleType(
  type:
    | 'asset'
    | 'registry'
    | 'task'
    | 'group'
    | 'api-key'
    | 'credential'
    | 'credential-storage'
    | 'exception-list'
    | 'user'
    | 'account'
    | 'auth-server',
): string | string[] | undefined {
  switch (type) {
    case 'asset':
      return locale[currentLocale].entities.asset;
    case 'registry':
      return locale[currentLocale].entities.registry;
    case 'task':
      return locale[currentLocale].entities.task;
    case 'group':
      return locale[currentLocale].entities.group;
    case 'api-key':
      return locale[currentLocale].entities.apiKey;
    case 'credential':
      return locale[currentLocale].entities.credential;
    case 'credential-storage':
      return locale[currentLocale].entities.credentialStorage;
    case 'exception-list':
      return locale[currentLocale].entities.exceptionList;
    case 'user':
      return locale[currentLocale].entities.user;
    case 'account':
      return locale[currentLocale].entities.account;
    case 'auth-server':
      return locale[currentLocale].entities.authServer;

    default:
      return undefined;
  }
}

export function DeleteConfirmModal({
  type,
  name,
  onClose,
  handleDelete,
  errorMessage,
  countInside,
}: {
  type:
    | 'asset'
    | 'registry'
    | 'task'
    | 'group'
    | 'api-key'
    | 'credential'
    | 'credential-storage'
    | 'exception-list'
    | 'user'
    | 'account'
    | 'auth-server';
  name: string;
  onClose: (() => void) | undefined;
  handleDelete: () => void;
  errorMessage?: string | undefined;
  countInside?: number;
}): ReactElement {
  const localeType = getLocaleType(type);

  return (
    <Dialog
      autoFocus
      className="small"
      title={`${locale[currentLocale].title} ${localeType?.length ? localeType[0] : localeType}`}
      onClose={onClose}
    >
      <div className="k-form">
        <div className="form-content">
          <p>
            {countInside && type === 'group' ? `${locale[currentLocale].assetsInside} ` : ''}
            {countInside && type === 'exception-list'
              ? `${locale[currentLocale].bulletinsInside} `
              : ''}
            {locale[currentLocale].deleteMessage} {localeType?.length ? localeType[1] : localeType}:{' '}
            <b>{name}</b>? {locale[currentLocale].deleteUndone}
          </p>
        </div>
        <div className="k-form-buttons">
          <Button type="submit" onClick={handleDelete} tabIndex={0}>
            {locale[currentLocale].btnDeleteYes}
          </Button>
          <Button type="button" onClick={onClose}>
            {locale[currentLocale].btnDeleteNo}
          </Button>
          {errorMessage && (
            <div className="info alert alert-danger">
              {locale[currentLocale].error}: {errorMessage}
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
}
