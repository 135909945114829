/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-nested-ternary */
import { Grid, GridColumn, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useMemo, useState } from 'react';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { LAZY_PAGEABLE_DATA } from '../../../../../utils/helpers/constants';
import { WrapperMenu } from '../../../Task/taskIDetails/iDetailsComponents/WrapperMenu';
import type {
  InventorizationDifferenceFirewall,
  InventorizationDifferenceRouter,
  InventorizationDifferenceSwitch,
} from '../../../../../types/__generated/on-premise-solution/api/inventorizationDifference.v1';
import {
  createFromListInvData,
  IDiffInvElementInfo,
  type DiffFilterTypes,
  defineWrapperStatuses,
} from '../../../../../utils/helpers/difference-modal-helper';
import { DiffStandartCell } from '../cells/DiffStandartCell';
import { DiffSizeCell } from '../cells/DiffSizeCell';
import { DiffBoolCell } from '../cells/DiffBoolCell';
import { DiffTypeCell } from '../cells/DiffTypeCell';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function DiffFileSystemNetDevices({
  data,
  filter,
}: {
  data:
    | InventorizationDifferenceFirewall
    | InventorizationDifferenceRouter
    | InventorizationDifferenceSwitch;
  filter: DiffFilterTypes;
}): React.ReactElement {
  const fileSystemData = useMemo(
    () => createFromListInvData(data.file_system as unknown as IDiffInvElementInfo[], filter),
    [data.file_system, filter],
  );
  const [page, setPage] = useState({ take: 5, skip: 0 });

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  const hasFileSystem =
    fileSystemData &&
    fileSystemData.length &&
    (data.class === 'cisco' || data.class === 'juniper' || data.class === 'paloaltonetworks');

  return hasFileSystem ? (
    <WrapperMenu
      title={locale[currentLocale].fileSystem.title}
      diffStatuses={defineWrapperStatuses(data.file_system)}
    >
      {data.class === 'cisco' && (
        <Grid
          data={fileSystemData?.slice(page.skip, page.take + page.skip)}
          pageable={LAZY_PAGEABLE_DATA}
          scrollable="none"
          total={fileSystemData?.length}
          pageSize={page.take}
          skip={page.skip}
          onPageChange={pageChange}
          className="no-margin-top"
        >
          <GridColumn
            field="diffType"
            cell={DiffTypeCell}
            title=" "
            width={20}
            sortable={false}
            headerClassName="no-cursor"
          />
          <GridColumn
            field="flags"
            title={locale[currentLocale].fileSystem.flags}
            cell={DiffStandartCell}
            sortable={false}
            headerClassName="no-cursor"
          />
          <GridColumn
            field="free"
            title={locale[currentLocale].fileSystem.free}
            cell={DiffSizeCell}
            sortable={false}
            headerClassName="no-cursor"
          />
          <GridColumn
            field="isDefault"
            title={locale[currentLocale].fileSystem.isDefault}
            cell={DiffBoolCell}
            sortable={false}
            headerClassName="no-cursor"
          />
          <GridColumn
            field="prefixes"
            title={locale[currentLocale].fileSystem.prefixes}
            cell={DiffStandartCell}
            sortable={false}
            headerClassName="no-cursor"
          />
          <GridColumn
            field="size"
            title={locale[currentLocale].fileSystem.size}
            cell={DiffSizeCell}
            sortable={false}
            headerClassName="no-cursor"
          />
          <GridColumn
            field="type"
            title={locale[currentLocale].fileSystem.type}
            cell={DiffStandartCell}
            sortable={false}
            headerClassName="no-cursor"
          />
        </Grid>
      )}
      {data.class === 'juniper' && (
        <Grid
          data={fileSystemData?.slice(page.skip, page.take + page.skip)}
          pageable={LAZY_PAGEABLE_DATA}
          scrollable="none"
          total={fileSystemData?.length}
          pageSize={page.take}
          skip={page.skip}
          onPageChange={pageChange}
          className="no-margin-top"
        >
          <GridColumn
            field="diffType"
            cell={DiffTypeCell}
            title=" "
            width={20}
            sortable={false}
            headerClassName="no-cursor"
          />
          <GridColumn
            field="device"
            title={locale[currentLocale].fileSystem.device}
            cell={DiffStandartCell}
            sortable={false}
            headerClassName="no-cursor"
          />
          <GridColumn
            field="free"
            title={locale[currentLocale].fileSystem.free}
            cell={DiffSizeCell}
            sortable={false}
            headerClassName="no-cursor"
          />
          <GridColumn
            field="size"
            title={locale[currentLocale].fileSystem.size}
            cell={DiffSizeCell}
            sortable={false}
            headerClassName="no-cursor"
          />
        </Grid>
      )}
      {data.class === 'paloaltonetworks' && (
        <Grid
          data={fileSystemData?.slice(page.skip, page.take + page.skip)}
          pageable={LAZY_PAGEABLE_DATA}
          scrollable="none"
          total={fileSystemData?.length}
          pageSize={page.take}
          skip={page.skip}
          onPageChange={pageChange}
          className="no-margin-top"
        >
          <GridColumn
            field="diffType"
            cell={DiffTypeCell}
            title=" "
            width={20}
            sortable={false}
            headerClassName="no-cursor"
          />
          <GridColumn
            field="device"
            title={locale[currentLocale].fileSystem.device}
            cell={DiffStandartCell}
            sortable={false}
            headerClassName="no-cursor"
          />
          <GridColumn
            field="size"
            title={locale[currentLocale].fileSystem.size}
            cell={DiffSizeCell}
            sortable={false}
            headerClassName="no-cursor"
          />
          <GridColumn
            field="available"
            title={locale[currentLocale].fileSystem.available}
            cell={DiffSizeCell}
            sortable={false}
            headerClassName="no-cursor"
          />
          <GridColumn
            field="path"
            title={locale[currentLocale].fileSystem.path}
            cell={DiffStandartCell}
            sortable={false}
            headerClassName="no-cursor"
          />
        </Grid>
      )}
    </WrapperMenu>
  ) : (
    <></>
  );
}
