/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-nested-ternary */
import type { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useCallback, useEffect } from 'react';
import { useRegisterContext } from '../../../../../hooks/useRegisterContext';

export function CellSelectRegistry(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { registryForm, registryFormTemp, setRegistryFormTemp } = useRegisterContext();

  useEffect(() => {
    if (!registryFormTemp) {
      setRegistryFormTemp(registryForm);
    }
  }, [registryForm, registryFormTemp, setRegistryFormTemp]);

  const addRegistry = useCallback(
    ({ name, registryId }: { name: string; registryId: string }): void => {
      if (registryFormTemp && registryFormTemp.length > 0) {
        const index = registryFormTemp?.findIndex((r) => r.registryId === registryId);
        if (index !== -1) {
          const cutRegistryForm = registryFormTemp.filter(
            (registry) => registry.registryId !== registryId,
          );
          setRegistryFormTemp([...cutRegistryForm]);
        } else {
          setRegistryFormTemp([
            ...registryFormTemp,
            {
              registryId,
              name,
            },
          ]);
        }
      } else {
        setRegistryFormTemp([
          {
            registryId: dataItem.registryId,
            name: dataItem.name,
          },
        ]);
      }
    },
    [registryFormTemp, setRegistryFormTemp, dataItem.registryId, dataItem.name],
  );

  return (
    <td className="checkbox_cell">
      <Checkbox
        checked={!!registryFormTemp?.find((r) => r.registryId === dataItem.registryId)}
        onClick={(): void =>
          addRegistry({
            name: dataItem.name,
            registryId: dataItem.registryId,
          })
        }
      />
    </td>
  );
}
