import React, { useCallback, useState } from 'react';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import localeVmTask from '../../../../utils/i18n/taskLocale/vmTask.json';
import type { Audit } from '../../../../types/__generated/on-premise-solution/api/audit.v1';
import { VulnerabilitiesList } from '../../Task/taskVmDetails/vmDetailsComponents/VulnerabilitiesList';
import { PackageList } from '../../Task/taskVmDetails/vmDetailsComponents/PackageList';
import { VulnsSoft } from '../../Task/taskVmDetails/vmDetailsComponents/VulnsSoft';
import { MissingKBs } from '../../Task/taskVmDetails/vmDetailsComponents/MissingKBs';
import { SoftwareListWin } from '../../Task/taskVmDetails/vmDetailsComponents/SoftwareListWin';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeVmTask;

export function TabsHostBottom({
  data,
  hostName,
  isImage,
}: {
  data: Audit | undefined;
  hostName: string | undefined | null;
  isImage?: boolean;
}): React.ReactElement {
  const [selected, setSelected] = useState<number>(0);

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      setSelected(e.selected);
    },
    [setSelected],
  );

  const countVulnerableObjects = data?.result?.vulnerableObjects
    ? data.result?.vulnerableObjects.length
    : 0;

  const countVulns = data?.result?.cumulativeData.vulns
    ? (data?.result?.cumulativeData.vulns as object)
    : 0;

  const countPackageList =
    data?.payload && data.type === 'linux' && 'packages' in data.payload
      ? data.payload.packages.length
      : 0;

  const countSoftwareList =
    data?.payload && data.type === 'windows' && 'software' in data.payload
      ? data.payload.software?.length
      : 0;

  const countmissedKB =
    data?.type === 'windows' && data.result.cumulativeData.missedKb !== null
      ? Object.values(data.result.cumulativeData.missedKb).length
      : 0;

  return (
    <>
      {data?.type === 'linux' && (
        <TabStrip selected={selected} onSelect={handleSelect}>
          <TabStripTab
            title={`${localeVmTask[currentLocale].tabs.vulnSoftLin} (${countVulnerableObjects})`}
          >
            <VulnsSoft
              type={data.type}
              dataResult={data?.result}
              hostName={hostName}
              assetType={isImage ? 'image' : undefined}
            />
          </TabStripTab>
          <TabStripTab
            title={`${localeVmTask[currentLocale].tabs.vulnerabilities} (${
              Object.values(countVulns).length || 0
            })`}
          >
            <VulnerabilitiesList dataResult={data?.result} hostName={hostName} />
          </TabStripTab>
          {!isImage && (
            <TabStripTab
              title={`${localeVmTask[currentLocale].tabs.packageList} (${countPackageList})`}
            >
              <PackageList dataPayload={data?.payload} hostName={hostName} />
            </TabStripTab>
          )}
          {/* <TabStripTab title={localeVmTask[currentLocale].tabs.auditLogs}>
            <p>Tab 3 Content</p>
          </TabStripTab> */}
        </TabStrip>
      )}
      {data?.type === 'windows' && (
        <TabStrip selected={selected} onSelect={handleSelect}>
          <TabStripTab
            title={`${localeVmTask[currentLocale].tabs.vulnSoftWin} (${countVulnerableObjects})`}
          >
            <VulnsSoft
              type={data.type}
              dataResult={data?.result}
              hostName={hostName}
              vulnSoftWhiteList={undefined}
            />
          </TabStripTab>
          <TabStripTab
            title={`${localeVmTask[currentLocale].tabs.vulnerabilities} (${
              Object.values(countVulns).length
            })`}
          >
            <VulnerabilitiesList
              dataResult={data?.result}
              hostName={hostName}
              vulnsWhiteList={undefined}
            />
          </TabStripTab>
          <TabStripTab title={`${localeVmTask[currentLocale].tabs.missingKBs}  (${countmissedKB})`}>
            <MissingKBs data={data} hostName={hostName} />
          </TabStripTab>
          <TabStripTab
            title={`${localeVmTask[currentLocale].tabs.softwareList} (${countSoftwareList})`}
          >
            <SoftwareListWin data={data} hostName={hostName} />
          </TabStripTab>
          {/* <TabStripTab title={localeVmTask[currentLocale].tabs.auditLogs}>
            <p>Tab 3 Content</p>
          </TabStripTab> */}
        </TabStrip>
      )}
      {data?.type !== 'linux' && data?.type !== 'windows' && (
        <p style={{ textAlign: 'center' }}>No data</p>
      )}
    </>
  );
}
