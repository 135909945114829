import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { ErrorBoundary } from 'react-error-boundary';
import { Filter } from '@progress/kendo-react-data-tools';
import type { IDataHooks } from '../../../utils/helpers/types';
import localeAccount from '../../../utils/i18n/accountLocales/accountList.json';
import { getUrlList } from '../../../utils/helpers/getUrl-list-helper';
import { useAccountList } from '../../../hooks/components/useAccountList';
import { CommonAccountList } from '../../common/CommonAccountList';
import { BoundaryErrorComponent } from '../../common/BoundaryErrorComponent';
import { СommonTableLoader } from '../../common/tableLoader/CommonTableLoader';
import { FILTER_ACCOUNT_LIST } from '../../../utils/helpers/constant-serv-filter-host';
import { Input } from '../../common/baseElements/Input';
import { AccountAddEdit } from './modals/AccountAddEdit';
import { Button } from '../../common/baseElements/Button';
import { useAccountModalContext } from '../../../hooks/useAccountModalContext';
import { getUserData } from '../../../services/local-storage-service';
import styles from './AccountList.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAccount;

export function AccountList(): React.ReactElement {
  const urlParams = getUrlList('AccountList');

  const { isOpen, setOpen } = useAccountModalContext();

  const currentUser = getUserData();

  const dataForHooks: IDataHooks = {
    name: 'name',
    componentName: 'AccountList',
  };

  const {
    filter,
    onFilterChange,
    sort,
    page,
    total,
    pageChange,
    setSort,
    filterStatus,
    isError,
    errorMessage,
    data,
    handleCustomSearch,
    customSearch,
  } = useAccountList(urlParams, dataForHooks);

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      <div className="accounts">
        <div className={styles.accountActions}>
          {currentUser?.role === 'super_admin' && (
            <Button
              fill="action"
              onClick={(): void => {
                setOpen(true);
              }}
            >
              {localeAccount[currentLocale].btnCreateAccounts}
            </Button>
          )}
        </div>
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <div className="filter-line">
              <Filter
                value={filter}
                onChange={onFilterChange}
                fields={FILTER_ACCOUNT_LIST[currentLocale]}
              />
              {filter.filters.length === 0 && (
                <Input
                  name="customSearch"
                  placeholder={localeAccount[currentLocale].customSearch}
                  value={customSearch}
                  onChange={(e): void => handleCustomSearch(e)}
                />
              )}
            </div>
          </IntlProvider>
        </LocalizationProvider>
        <CommonAccountList
          sort={sort}
          data={data}
          page={page}
          total={total}
          pageChange={pageChange}
          setSort={setSort}
          dataForHooks={dataForHooks}
        />
        <СommonTableLoader queryData={filterStatus} filteringData={filterStatus} />{' '}
        {isError && <div className="info alert alert-danger">Error: {errorMessage}</div>}
        {isOpen && <AccountAddEdit />}
      </div>
    </ErrorBoundary>
  );
}
