/* eslint-disable react-hooks/exhaustive-deps */
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import type { TextFilterProps } from '@progress/kendo-react-data-tools/dist/npm/filter/filters/TextFilter';
import { useQuery } from 'react-query';
import { useEffect } from 'react';
import localeHostDescription from '../../../utils/i18n/assetLocales/hostDescription.json';
import type { TagsResponse } from '../../../types/__generated/on-premise-solution/api/tagsResponse.v1';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getAllTags } from '../../../services/asset-service';
import { useAssetCreatePropContext } from '../../../hooks/useAssetCreatePropContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHostDescription;

export function FilterTagSelect(props: TextFilterProps): React.ReactElement {
  const { filter, onFilterChange } = props;

  const { tagName, setTagName } = useAssetCreatePropContext();

  const tagsQuery = useQuery<TagsResponse, ApiError>(['tagsList'], () => getAllTags(''), {
    keepPreviousData: true,
  });

  const handleChange = (event: DropDownListChangeEvent): void => {
    onFilterChange({
      nextFilter: { ...filter, ...{ value: event.target.value.name, text: '' } },
    });
  };

  useEffect(() => {
    return () => setTagName('');
  }, []);

  return (
    <DropDownList
      defaultValue={{ name: tagName || localeHostDescription[currentLocale].selectTag }}
      data={tagsQuery.data?.data}
      textField="name"
      dataItemKey="tagId"
      onChange={handleChange}
    />
  );
}
