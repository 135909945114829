import React, { useCallback, useState } from 'react';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { useQuery } from 'react-query';
import localeVmTask from '../../../../utils/i18n/taskLocale/vmTask.json';
import type { InventorizationResponse } from '../../../../types/__generated/on-premise-solution/api/inventorizationResponse.v1';
import { TabsIDetails } from '../../Task/taskIDetails/iDetailsComponents/TabsIDetails';
import { DeviceTaskHistory } from './DeviceTaskHistory';
import { DeviceInvMetaBlock } from './DeviceInvMetaBlock';
import type { AuditDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/auditDashboardResponse.v1';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getAuditInfo } from '../../../../services/audit-service';
import { TabsVmNetDevDetails } from '../../Task/taskVmDetails/vmDetailsComponents/TabsVmNetDevDetails';
import { useAuditModalContext } from '../../../../hooks/useAuditModalContext';
import { HostVulnsMetaBlock } from '../../Host/hostComponents/HostVulnsMetaBlock';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeVmTask;

export function TabsDeviceDescription({
  invData,
  hostName,
  inventorizationId,
  auditId,
  maxVFstec,
}: {
  invData: InventorizationResponse | undefined;
  hostName: string | undefined | null;
  inventorizationId: string | null | undefined;
  auditId: string | undefined;
  maxVFstec: number | null | undefined;
}): React.ReactElement {
  const [selected, setSelected] = useState<number>(0);

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      setSelected(e.selected);
    },
    [setSelected],
  );

  const { setAuditData } = useAuditModalContext();

  const querygetAuditInfo = useQuery<AuditDashboardResponse, ApiError>(
    ['auditInfo', auditId],
    () => getAuditInfo(auditId),
    {
      enabled: !!auditId,
      onSuccess: (data) => {
        setAuditData(data);
      },
    },
  );

  const countVulns = querygetAuditInfo.data?.result?.cumulativeData.vulns
    ? (querygetAuditInfo.data?.result?.cumulativeData.vulns as object)
    : 0;

  return (
    <div className="top-tabstrip">
      <TabStrip selected={selected} onSelect={handleSelect}>
        <TabStripTab
          title={`${localeVmTask[currentLocale].tabs.vulnerabilities} (${
            Object.values(countVulns).length || 0
          })`}
        >
          <HostVulnsMetaBlock
            queryAuditInfo={querygetAuditInfo.data}
            hostName={hostName}
            maxVFstec={maxVFstec}
          />
          <TabsVmNetDevDetails data={querygetAuditInfo.data} hostName={hostName} />
        </TabStripTab>
        <TabStripTab title={localeVmTask[currentLocale].tabs.inventorization}>
          <DeviceInvMetaBlock invData={invData} inventorizationId={inventorizationId} />
          <TabsIDetails data={invData} hostName={hostName} />
        </TabStripTab>
        <TabStripTab title={localeVmTask[currentLocale].tabs.taskHistory}>
          <DeviceTaskHistory />
        </TabStripTab>
      </TabStrip>
    </div>
  );
}
