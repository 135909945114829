import type { GridCellProps } from '@progress/kendo-react-grid';
import { RadioButton } from '@progress/kendo-react-inputs';
import { useEffect } from 'react';
import { useHostActions } from '../../../../hooks/components/useHostActions';
import { useCredentialContext } from '../../../../hooks/useCredentialContext';
import { useRegisterContext } from '../../../../hooks/useRegisterContext';

export function CellCheckCredential(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { selectCredential } = useHostActions();

  const { credentialReg } = useRegisterContext();

  const { selectedCredential, setSelectedCredential, credentialHost, credentialDev } =
    useCredentialContext();

  useEffect(() => {
    if (credentialHost && credentialHost.credentialId)
      setSelectedCredential({ ...selectedCredential, credentialId: credentialHost.credentialId });
    if (credentialDev && credentialDev.credentialId)
      setSelectedCredential({ ...selectedCredential, credentialId: credentialDev.credentialId });
    if (credentialReg && credentialReg.credentialId)
      setSelectedCredential({ ...selectedCredential, credentialId: credentialReg.credentialId });
  }, []);

  return (
    <td style={{ textOverflow: 'clip' }}>
      <RadioButton
        name="credentialGroup"
        value={dataItem.credentialId}
        checked={selectedCredential?.credentialId === dataItem.credentialId}
        onChange={(): void =>
          selectCredential({
            ...selectedCredential,
            credentialId: dataItem.credentialId,
            credentialName: dataItem.name,
            type: dataItem.type,
          })
        }
      />
    </td>
  );
}
