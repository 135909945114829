/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-useless-fragment */
import { Button } from '../baseElements/Button';
import localeDeviceForm from '../../../utils/i18n/devices/devices.json';
import styles from './Form.module.scss';
import { useCredentialContext } from '../../../hooks/useCredentialContext';
import { useCredential } from '../../../hooks/components/useCredential';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeDeviceForm;

type DataCredForAsset = {
  credentialId: string | null | undefined;
  credentialName: string | undefined;
  credentialType: string | undefined;
  credentialSshPort?: number | undefined;
};

export function SelectedCredForAsset({
  dataCred,
  type,
}: {
  dataCred: DataCredForAsset | undefined;
  type: string;
}): React.ReactElement {
  const { setCredentialDev, setCredentialHost } = useCredentialContext();

  const { handleWindow } = useCredential();

  const resetCred = (): void => {
    if (type === 'device') {
      setCredentialDev(undefined);
    } else {
      setCredentialHost(undefined);
    }
    handleWindow();
  };

  return (
    <>
      {dataCred?.credentialId && (
        <div className="selected-credential">
          {dataCred && (
            <table className={`${styles.formCredentialTable} horizontal`}>
              <thead>
                <tr>
                  <th style={{ width: '68px' }}>{localeDeviceForm[currentLocale].table.type}</th>
                  <th style={{ width: '188px' }}>{localeDeviceForm[currentLocale].table.name}</th>
                  <th>ID</th>
                  <th className={styles.delete_button_th}> </th>
                </tr>
              </thead>
              <tbody>
                <tr className="cred-in-add-form">
                  <td>{dataCred?.credentialType}</td>
                  <td title={dataCred?.credentialName || ''}>{dataCred?.credentialName}</td>
                  <td title={dataCred?.credentialId || ''}>{dataCred?.credentialId}</td>
                  <td>
                    <Button fill="flat" textColor="green" className={styles.delete_button}>
                      <span className="k-icon k-icon k-i-x" onClick={resetCred} />
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      )}
    </>
  );
}
