import type { GridCellProps } from '@progress/kendo-react-grid';
import { NavLink } from 'react-router-dom';
import type { ISecurityVulns } from '../../../../utils/helpers/types';

export function CellSecurityAsset(props: GridCellProps): React.ReactElement {
  const { dataItem, className } = props;

  const { totalHostAssets, bulletinId }: ISecurityVulns = dataItem;

  return (
    <td className="number">
      <span>
        {totalHostAssets ? (
          <NavLink
            to={`/lk/assets/hosts?skip=0&limit=15&sortField=latestAudit.maxCvssScore&sortOrder=desc&${
              className === 'whitelist ' ? 'whitelistedBulletinId' : 'bulletinId'
            }[$eq]=${bulletinId}`}
          >
            {totalHostAssets}
          </NavLink>
        ) : (
          <span>-</span>
        )}
      </span>
    </td>
  );
}
