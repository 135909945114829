import type { GridCellProps } from '@progress/kendo-react-grid';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Button } from '../../../../common/baseElements/Button';
import { deleteRegistry } from '../../../../../services/asset-service';
import type { ApiError } from '../../../../../types/__generated/on-premise-solution/api/apiError.v1';
import type { ContainerRegistryDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/containerRegistryDashboardResponse.v1';
import type { ContainerRegistryDeletionResponse } from '../../../../../types/__generated/on-premise-solution/api/containerRegistryDeletionResponse.v1';
import localeImReg from '../../../../../utils/i18n/imageRegister/ImageRegister.json';
import { useRegisterContext } from '../../../../../hooks/useRegisterContext';
import type { IRegisterData } from '../../../../../utils/helpers/types';
import { useCreateTask } from '../../../../../hooks/components/useCreateTask';
import { getUserData } from '../../../../../services/local-storage-service';
import { DeleteConfirmModal } from '../../../../common/modal/DeleteConfirmModal';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeImReg;

export function RegistryMenu(props: GridCellProps): React.ReactElement {
  const currentUser = getUserData();

  const { dataItem } = props;

  const { registryId }: ContainerRegistryDashboardResponse = dataItem;

  const queryClient = useQueryClient();

  const [isConfirm, setConfirm] = useState(false);

  const { setRegisterData } = useRegisterContext();

  function addDataForEdit(): void {
    const payloadRegisterData: IRegisterData = {
      registryId: dataItem.registryId,
      name: dataItem.name,
      address: dataItem.address,
      credential: {
        credentialId: dataItem.credential?.credentialId
          ? dataItem.credential?.credentialId
          : undefined,
        name: dataItem.credential?.name,
        type: dataItem.credential?.type,
      },
      repositories: dataItem.repositories,
      description: dataItem.description,
    };

    setRegisterData(payloadRegisterData);
  }

  const handleConfirm = (): void => {
    setConfirm(true);
  };

  const mutation = useMutation<ContainerRegistryDeletionResponse | null, ApiError, string>(
    (payload) => deleteRegistry(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('registryList');
      },
    },
  );

  const handleDelete = (): void => {
    mutation.mutate(registryId);
    setConfirm(false);
  };

  const { createTaskForRegistry } = useCreateTask();

  return (
    <>
      {isConfirm && (
        <DeleteConfirmModal
          name={dataItem?.name}
          type="registry"
          onClose={(): void => setConfirm(false)}
          handleDelete={handleDelete}
          errorMessage={mutation.error?.message}
        />
      )}
      <td className="cell_menu">
        <div className="groups__menu">
          <div className="dropdown__container">
            <span className="k-icon k-i-more-vertical" />
            <div className="dropdown__content">
              <Button
                fill="menu"
                onClick={(): void => {
                  createTaskForRegistry('registry', dataItem.registryId, dataItem.name);
                }}
              >
                {localeImReg[currentLocale].btnCreateTask}
              </Button>
              <div className="delimiter" />
              {currentUser?.role !== 'reader_user' && (
                <Button
                  fill="menu"
                  onClick={(): void => addDataForEdit()}
                  disabled={
                    currentUser?.role === 'user' && currentUser.id !== dataItem.createdBy.id
                  }
                >
                  {localeImReg[currentLocale].btnEdit}
                </Button>
              )}
              {currentUser?.role !== 'reader_user' && (
                <Button
                  fill="menu"
                  onClick={handleConfirm}
                  disabled={
                    currentUser?.role === 'user' && currentUser.id !== dataItem.createdBy.id
                  }
                >
                  {localeImReg[currentLocale].btnDelete}
                </Button>
              )}
            </div>
          </div>
        </div>
      </td>
    </>
  );
}
