/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-pascal-case */
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { State, process, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Filter } from '@progress/kendo-react-data-tools';
import { FILTER_INITIAL, PAGEABLE_DATA } from '../../../utils/helpers/constants';
import localeSettings from '../../../utils/i18n/settingsLocales/settings.json';
import styles from '../../features/Settings/ApiKeys/ApiKeyList.module.scss';
import type { ApiKeyDashboardResponse } from '../../../types/__generated/on-premise-solution/api/apiKeyDashboardResponse.v1';
import { CellLinkName } from '../../features/Settings/ApiKeys/cells/CellLinkName';
import { Input } from '../baseElements/Input';
import { handlerCustomSearch, onFilterChange } from '../../../utils/helpers/local-filter-helpers';
import type { BulletinWhitelistDashboardResponse } from '../../../types/__generated/on-premise-solution/api/bulletinWhitelistDashboardResponse.v1';
import { GROUP_DESCRIPTION } from '../../../utils/helpers/constant-local-filter-task';
import { useWhiteListContext } from '../../../hooks/useWhiteListContext';
import { CellCommonDate } from '../baseElements/CellCommonDate';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

export function GroupDescriptionList({
  groups,
}: {
  groups:
    | ApiKeyDashboardResponse['scope']['assetGroups']
    | BulletinWhitelistDashboardResponse['scope']['assetGroups']
    | undefined;
}): React.ReactElement {
  const pageLimitGrid: { [key: string]: string } =
    localStorage.getItem('pageLimitGrid') &&
    JSON.parse(localStorage.getItem('pageLimitGrid') || '');

  const initialDataState: State = useMemo(
    () => ({
      sort: [{ field: 'name', dir: 'desc' }],
      take: pageLimitGrid && pageLimitGrid.ApiMessods ? Number(pageLimitGrid.ApiMessods) : 10,
      skip: 0,
      filter: FILTER_INITIAL,
    }),
    [pageLimitGrid],
  );

  const fieldFindInTable = 'name';

  const urlPath = document.location.pathname.includes('tokens');

  const [dataState, setDataState] = useState<State>(initialDataState);

  const [customSearch, setCustomSearch] = useState('');

  const [filter, setFilter] = useState(FILTER_INITIAL);

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  const { whitelistUpdate } = useWhiteListContext();

  // useEffect(() => {
  //   const table = document.getElementsByClassName('group-description')[0].querySelector('table');

  //   if (table && groups && groups.length <= 10) {
  //     table.style.marginBottom = '18px';
  //   }

  //   if (table && groups && groups.length > 10) {
  //     table.style.marginBottom = '0px';
  //   }
  // }, [groups]);

  useEffect(() => {
    if (whitelistUpdate) {
      setDataState(initialDataState);
    }
  }, [initialDataState, whitelistUpdate]);

  return (
    <div className="group-description">
      {groups ? (
        <>
          <LocalizationProvider language={currentLocale}>
            <IntlProvider locale={currentLocale.substring(0, 2)}>
              <div className={styles.whiteListFilterLine}>
                <Filter
                  value={filter}
                  onChange={(event): void =>
                    onFilterChange(
                      event,
                      setCustomSearch,
                      setDataState,
                      dataState,
                      setFilter,
                      filterValue,
                      customSearch,
                      fieldFindInTable,
                    )
                  }
                  fields={GROUP_DESCRIPTION[currentLocale]}
                />
                {filter.filters.length === 0 && (
                  <Input
                    className={`k-input k-input-md ${styles.apiKeyCustomSearch}`}
                    name="customSearch"
                    placeholder={localeSettings[currentLocale].apiKey.restrictions.groupSearch}
                    value={customSearch}
                    onChange={(e): void =>
                      handlerCustomSearch(
                        e,
                        setCustomSearch,
                        setDataState,
                        filterValue,
                        dataState,
                        fieldFindInTable,
                      )
                    }
                  />
                )}
              </div>
            </IntlProvider>
          </LocalizationProvider>
          <LocalizationProvider language={currentLocale}>
            <IntlProvider locale={currentLocale.substring(0, 2)}>
              <Grid
                pageable={groups.length > 10 ? PAGEABLE_DATA : false}
                sortable
                data={process([...groups] || [], dataState)}
                {...dataState}
                onDataStateChange={(e: GridDataStateChangeEvent): void => {
                  setDataState(e.dataState);
                  if (pageLimitGrid) {
                    const pageLimit = {
                      ...pageLimitGrid,
                      componentName: String(e.dataState.take),
                    };
                    localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
                  } else {
                    const pageLimit = {
                      componentName: String(e.dataState.take),
                    };
                    localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
                  }
                }}
                scrollable="none"
                className={groups.length < 10 ? 'no-pageable-k-grid' : ''}
              >
                <Column
                  field="name"
                  title={localeSettings[currentLocale].apiKey.form.tabs.group}
                  filter="text"
                  cell={CellLinkName}
                  width={!urlPath ? '400px' : 'auto'}
                />
                <Column
                  field="totalAssets"
                  title={localeSettings[currentLocale].apiKey.form.tabs.totalAssets}
                  filter="text"
                  width="200px"
                />
                {!urlPath && (
                  <Column
                    field="addedBy.name"
                    title={localeSettings[currentLocale].whiteList.grid.addedBy}
                    filter="text"
                    width="220px"
                  />
                )}
                <Column
                  cell={CellCommonDate}
                  title={localeSettings[currentLocale].whiteList.grid.addedAt}
                  filter="text"
                  field="addedAt"
                  width="200px"
                />
                <Column
                  field="assetGroupId"
                  title={localeSettings[currentLocale].apiKey.restrictions.groupId}
                  filter="text"
                />
              </Grid>
            </IntlProvider>
          </LocalizationProvider>
        </>
      ) : (
        <div className={styles.titleDescriptionList}>
          <span>
            {document.location.pathname.includes('/whitelists')
              ? localeSettings[currentLocale].whiteList.grid.noData
              : localeSettings[currentLocale].apiKey.form.tabs.titleNoRestriction}
          </span>
        </div>
      )}
    </div>
  );
}
