import { useQuery } from 'react-query';
import type { AccountsDashboardResponse } from '../../types/__generated/on-premise-solution/api/accountsDashboardResponse.v1';
import type { IAccount } from '../../utils/helpers/types';
import type { ApiError } from '../../types/__generated/on-premise-solution/api/apiError.v1';
import { getAllAccounts } from '../../services/account-service';
import { getUserData } from '../../services/local-storage-service';

interface IAccounts {
  destructuringAccounts: () => IAccount[] | undefined;
  isLoading: boolean;
  isFetching: boolean;
}
export function useAccounts(): IAccounts {
  const currentUser = getUserData();

  const query = useQuery<AccountsDashboardResponse, ApiError>(
    ['allAccount'],
    () => getAllAccounts(),
    {
      keepPreviousData: true,
      enabled: currentUser?.role === 'super_admin',
    },
  );

  const { isLoading, isFetching } = query;

  const destructuringAccounts = (): IAccount[] | undefined =>
    query.data?.data.map((account) => {
      return { id: account.accountId, name: account.name };
    });

  return {
    destructuringAccounts,
    isLoading,
    isFetching,
  };
}
