/* eslint-disable radix */
/* eslint-disable no-bitwise */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { nanoid } from 'nanoid';
import { ColorPicker } from '@progress/kendo-react-inputs';
import { Button } from '../../../common/baseElements/Button';
import localeHostDescription from '../../../../utils/i18n/assetLocales/hostDescription.json';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { editTag } from '../../../../services/asset-service';
import styles from './HostDescription.module.scss';
import { useAssetCreatePropContext } from '../../../../hooks/useAssetCreatePropContext';
import type { TagUpdateResponse } from '../../../../types/__generated/on-premise-solution/api/tagUpdateResponse.v1';
import type { TagUpdateRequest } from '../../../../types/__generated/on-premise-solution/api/tagUpdateRequest.v1';
import { backgroundColorList } from '../../../../utils/helpers/constants';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHostDescription;

export function ChangeTagColor(): React.ReactElement {
  const queryClient = useQueryClient();

  const [editTagError, setEditTagError] = useState<string | null>(null);

  const { editTagColor, setEditTagColor, handleBgColor, chooseInColorPicker, setHovered } =
    useAssetCreatePropContext();

  const [bgColor, setBgColor] = useState<string>(editTagColor?.color || '');

  const mutation = useMutation<TagUpdateResponse, ApiError, TagUpdateRequest>(
    (payload) => editTag(editTagColor?.tagId || '', payload),
    {
      onSuccess: (resp) => {
        queryClient.invalidateQueries('dashboardAssetId');
      },
      onError: (resp) => {
        if (resp.code && resp.code === '409') {
          setEditTagError(`${localeHostDescription[currentLocale].summary.confirmDelTag}`);
        } else {
          setEditTagError(`${resp.code} ${resp.type}`);
        }
      },
    },
  );

  const chooseColor = (color: string): void => {
    const payload = {
      color: color && !color.includes('#') ? `#${color}` : editTagColor?.color,
    };
    if (editTagColor) {
      setEditTagColor({
        tagId: String(editTagColor.tagId),
        indexEl: editTagColor.indexEl,
        color: color && !color.includes('#') ? `#${color}` : editTagColor?.color,
      });
    }
    mutation.mutateAsync(payload);
  };

  const closeEditColor = (): void => {
    setEditTagColor(null);
    setHovered('');
  };

  return (
    <div
      className={`change-color-tag ${styles.editTagColorWrap}`}
      onClick={(e): void => chooseInColorPicker(e, chooseColor, bgColor)}
    >
      <div className={styles.editTagColorTitle}>
        <span>{localeHostDescription[currentLocale].summary.changeColor}</span>
        <Button
          className="button-noborder"
          onClick={closeEditColor}
          style={{
            backgroundColor: 'inherit',
          }}
        >
          <span className="k-icon k-font-icon k-i-close" />
        </Button>
      </div>
      <div className={styles.editTagColorContainer}>
        {backgroundColorList.map((c) => (
          <div
            className={styles.editTagColorElement}
            style={{ backgroundColor: `#${c}` }}
            onClick={(): void => chooseColor(c)}
            key={nanoid()}
          />
        ))}
      </div>
      <div className={`edir-color-tag ${styles.editTagColorPicker}`}>
        <ColorPicker
          view="gradient"
          value={bgColor}
          onChange={(e): void => handleBgColor(e, setBgColor)}
          onActiveColorClick={(): void => chooseColor(bgColor)}
          gradientSettings={{ opacity: false }}
        />
      </div>
    </div>
  );
}
