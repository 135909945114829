import qs from 'qs';
import type { SortDescriptor } from '@progress/kendo-data-query';
import type { AssetDeletionResponse } from '../types/__generated/on-premise-solution/api/assetDeletionResponse.v1';
import type { AssetCreationRequest } from '../types/__generated/on-premise-solution/api/assetCreationRequest.v1';
import type { AssetUpdateRequest } from '../types/__generated/on-premise-solution/api/assetUpdateRequest.v1';
import type { Asset } from '../types/__generated/on-premise-solution/api/asset.v1';
import type { IPageState } from '../utils/helpers/types';
import { history } from '../utils/history';
import { vmInstance } from '../lib/axios/hooks/useVmAxios';
import type { AgentDeletionResponse } from '../types/__generated/on-premise-solution/api/agentDeletionResponse.v1';
import type { AgentInstallerDownloadUrlResponse } from '../types/__generated/on-premise-solution/api/agentInstallerDownloadUrlResponse.v1';
import type { AssetGroupDeletionResponse } from '../types/__generated/on-premise-solution/api/assetGroupDeletionResponse.v1';
import { dashboardInstance } from '../lib/axios/hooks/useDashboardAxios';
import type { AssetGroupCreationResponse } from '../types/__generated/on-premise-solution/api/assetGroupCreationResponse.v1';
import type { AssetGroupCreationRequest } from '../types/__generated/on-premise-solution/api/assetGroupCreationRequest.v1';
import type { AssetGroupUpdateResponse } from '../types/__generated/on-premise-solution/api/assetGroupUpdateResponse.v1';
import type { AssetGroupUpdateRequest } from '../types/__generated/on-premise-solution/api/assetGroupUpdateRequest.v1';
import type { EnrichedAssetGroupResponse } from '../types/__generated/on-premise-solution/api/enrichedAssetGroupResponse.v1';
import { prepareFilterVal } from './filterVal-for-service';
import type { IFilterVal } from '../utils/filtering-in-table-test';
import type { ContainerRegistryDeletionResponse } from '../types/__generated/on-premise-solution/api/containerRegistryDeletionResponse.v1';
import type { TagCreationRequest } from '../types/__generated/on-premise-solution/api/tagCreationRequest.v1';
import type { TagCreationResponse } from '../types/__generated/on-premise-solution/api/tagCreationResponse.v1';
import type { TagsResponse } from '../types/__generated/on-premise-solution/api/tagsResponse.v1';
import type { TagDeletionResponse } from '../types/__generated/on-premise-solution/api/tagDeletionResponse.v1';
import type { AuditDifferenceResponse } from '../types/__generated/on-premise-solution/api/auditDifferenceResponse.v1';
import type { InventorizationDifference } from '../types/__generated/on-premise-solution/api/inventorizationDifference.v1';
import type { TagUpdateResponse } from '../types/__generated/on-premise-solution/api/tagUpdateResponse.v1';
import type { TagUpdateRequest } from '../types/__generated/on-premise-solution/api/tagUpdateRequest.v1';
import type { AuditHistory } from '../types/__generated/on-premise-solution/api/auditHistory.v1';
import type { InventorizationHistory } from '../types/__generated/on-premise-solution/api/inventorizationHistory.v1';
import type { AssetGroupsDashboardResponse } from '../types/__generated/on-premise-solution/api/assetGroupsDashboardResponse.v1';
import { urlFilterString } from '../utils/helpers/url-filter-string-helper';

export async function getAgentInstaller(
  osType: string | undefined,
  assetId: string | null,
): Promise<AgentInstallerDownloadUrlResponse> {
  // debugger;
  const result = await vmInstance.get<AgentInstallerDownloadUrlResponse>(
    '/agents/download/installer/url',
    {
      params: {
        osType,
        assetId,
      },
      // paramsSerializer: (params) => {
      //   history.replace({
      //     pathname: `/lk/assets/download/installer/url?${qs.stringify({
      //       osType,
      //       assetId,
      //     })}`,
      //   });

      //   return qs.stringify(params, { encode: false });
      // },
    },
  );

  return result.data;
}
export async function createNetDevice(
  payload: Omit<AssetCreationRequest, 'AssetHostCreationRequest'>,
): Promise<Omit<AssetCreationRequest, 'AssetHostCreationRequest'>> {
  const result = await vmInstance.post<Omit<AssetCreationRequest, 'AssetHostCreationRequest'>>(
    '/assets',
    payload,
  );

  return result.data;
}
export async function createTag(payload: TagCreationRequest): Promise<TagCreationResponse> {
  const result = await vmInstance.post<TagCreationResponse>('/tags', payload);

  return result.data;
}

export async function getAllTags(name: string): Promise<TagsResponse> {
  const result = await vmInstance.get<TagsResponse>(name ? `/tags?name[$regex]=${name}` : '/tags');

  return result.data;
}
export async function deleteTag(tagId: string): Promise<TagDeletionResponse | null> {
  const result = await vmInstance.delete<TagDeletionResponse>(`/tags/${tagId}`);

  return result.data;
}
export async function editTag(
  tagId: string,
  payload: TagUpdateRequest,
): Promise<TagUpdateResponse> {
  const result = await vmInstance.patch<TagUpdateResponse>(`/tags/${tagId}`, {
    ...payload,
  });

  return result.data;
}
export async function deleteNetDevice(assetId: string): Promise<AssetDeletionResponse> {
  const result = await vmInstance.delete<AssetDeletionResponse>(`/assets/${assetId}`);

  return result.data;
}

export async function deleteAsset(assetId: string): Promise<AssetDeletionResponse | null> {
  const result = await vmInstance.delete<AssetDeletionResponse>(`/assets/${assetId}`);

  return result.data;
}

export async function deleteRegistry(
  registryId: string,
): Promise<ContainerRegistryDeletionResponse | null> {
  const result = await vmInstance.delete<ContainerRegistryDeletionResponse>(
    `/container-registries/${registryId}`,
  );

  return result.data;
}

export async function deleteAgent(agentId: string): Promise<AgentDeletionResponse | null> {
  const result = await vmInstance.delete<AgentDeletionResponse>(`/agents/${agentId}`);

  return result.data;
}

export async function editAsset(
  assetId: Asset['assetId'],
  payload: AssetUpdateRequest,
): Promise<AssetUpdateRequest> {
  const result = await vmInstance.patch<AssetUpdateRequest>(`/assets/${assetId}`, {
    ...payload,
  });

  return result.data;
}

export async function getHostGroupList(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
  isGroupsPage?: boolean,
): Promise<AssetGroupsDashboardResponse> {
  // const queryString = urlFilterString(filterVal);

  // const paramsData = {
  //   skip: page.skip,
  //   limit: page.take,
  //   sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
  //   sortOrder: sort[0].dir,
  //   str: queryString,
  // };
  const result = await dashboardInstance.get<AssetGroupsDashboardResponse>(
    '/asset/groups?type=host',
    {
      params: {
        skip: page.skip,
        limit: page.take,
        sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
        sortOrder: sort[0].dir,
        ...prepareFilterVal(filterVal),
      },

      // paramsSerializer: (params) => {
      //   if (!isGroupsPage) return '';
      //   const stringifiedParams = qs.stringify(params, { encode: false });
      //   history.replace({
      //     pathname: `/lk/assets/groups?${stringifiedParams}`,
      //   });

      //   return stringifiedParams;
      // },

      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });

        if (document.location.pathname.includes('assets/groups')) {
          history.replace({
            pathname: `/lk/assets/groups?${stringifiedParams}`,
          });
        }

        return stringifiedParams;
      },
    },
  );

  return result.data;
}

export async function createHostGroup(
  groupData: AssetGroupCreationRequest,
): Promise<AssetGroupCreationResponse> {
  const result = await vmInstance.post<AssetGroupCreationResponse>('/asset/groups', groupData);

  return result.data;
}

export async function editContentOfGroup(
  groupData: AssetGroupUpdateRequest & { assetGroupId: string },
): Promise<AssetGroupUpdateResponse> {
  const { assetGroupId, ...gd } = groupData;
  const result = await vmInstance.patch<AssetGroupUpdateResponse>(
    `/asset/groups/${assetGroupId}`,
    gd,
  );

  return result.data;
}

export async function deleteHostAsset(
  assetGroupId: string,
): Promise<AssetGroupDeletionResponse | null> {
  const result = await vmInstance.delete<AssetGroupDeletionResponse>(
    `/asset/groups/${assetGroupId}`,
  );

  return result.data;
}

export async function getHostGroupDescription(
  assetGroupId: string,
): Promise<EnrichedAssetGroupResponse> {
  const result = await dashboardInstance.get<EnrichedAssetGroupResponse>(
    `/asset/groups/${assetGroupId}`,
  );

  return result.data;
}

export async function getDiffAuditCheckpoints(assetId: string): Promise<AuditHistory> {
  const result = await vmInstance.get<AuditHistory>(`/assets/${assetId}/history/audits`);

  return result.data;
}

export async function getDiffInvCheckpoints(assetId: string): Promise<InventorizationHistory> {
  const result = await vmInstance.get<InventorizationHistory>(
    `/assets/${assetId}/history/inventorizations`,
  );

  return result.data;
}

export async function getDifferenceAudit(
  assetId: string,
  dateRangeFrom?: string,
  dateRangeTo?: string,
): Promise<AuditDifferenceResponse> {
  const params: {
    [k: string]: string;
  } = {
    assetId,
  };

  if (dateRangeFrom && dateRangeTo) {
    params.dateRangeFrom = dateRangeFrom;
    params.dateRangeTo = dateRangeTo;
  }
  const result = await vmInstance.get<AuditDifferenceResponse>('/audits/difference', {
    params,
  });

  return result.data;
}

export async function getDifferenceInv(
  assetId: string,
  dateRangeFrom?: string,
  dateRangeTo?: string,
): Promise<InventorizationDifference> {
  const params: {
    [k: string]: string;
  } = {
    assetId,
  };

  if (dateRangeFrom && dateRangeTo) {
    params.dateRangeFrom = dateRangeFrom;
    params.dateRangeTo = dateRangeTo;
  }
  const result = await vmInstance.get<InventorizationDifference>('/inventorizations/difference', {
    params,
  });

  return result.data;
}
