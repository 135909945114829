import { NavLink } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import localeSettings from '../../utils/i18n/settingsLocales/settings.json';
import styles from '../features/Settings/Settings.module.scss';
import { useWhiteListContext } from '../../hooks/useWhiteListContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;
export function CommonNotificationWl(): React.ReactElement {
  const { setOnSubmitVulnSuccess, selectedWhitelist, setSelectedWhitelist } = useWhiteListContext();

  const timer = useRef<NodeJS.Timeout | null>(null);

  const setTimer = (): void => {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setOnSubmitVulnSuccess(false);
      setSelectedWhitelist(null);
    }, 3800);
  };

  useEffect(() => {
    setTimer();

    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, []);

  return (
    <div className={styles.wlNotificationWrap}>
      <span>{localeSettings[currentLocale].changeTheExclusionList}</span>
      {selectedWhitelist && (
        <div className={styles.wlNotificationLinkWrap}>
          <NavLink to={`/lk/audit/exceptions/${selectedWhitelist[0].bulletinWhitelistId}`}>
            {selectedWhitelist[0].name}
          </NavLink>
        </div>
      )}{' '}
      <span>{localeSettings[currentLocale].changeTheExclusionListSaved}</span>
    </div>
  );
}
