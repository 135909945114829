/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import type { GridCellProps } from '@progress/kendo-react-grid';

export function NormalDateCell(props: GridCellProps): React.ReactElement {
  const { dataItem, field, className } = props;

  const fieldArray = field ? field.split('.') : [];
  let value = dataItem;

  if (!fieldArray) value = null;
  else if (fieldArray.length === 1) value = dataItem[fieldArray[0]];
  else if (fieldArray.length > 1) {
    fieldArray.forEach((f) => {
      value = value ? value[f] : value;
    });
  }

  return value && value !== '-' ? (
    <td className={className || ''} style={{ textAlign: 'right' }}>{new Date(value).toLocaleString()}</td>
  ) : (
    <td className={className || ''} style={{ textAlign: 'right' }}>-</td>
  );
}
