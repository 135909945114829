import { Button } from '@progress/kendo-react-buttons';
import type { State } from '@progress/kendo-data-query';
import { useState } from 'react';
import localeAuditTask from '../../../../utils/i18n/taskLocale/auditTask.json';
import type { IPackagesUpdate } from '../../../../utils/helpers/task-helpers';
import { handlerInstalledSearch } from '../../../../utils/helpers/local-filter-helpers';
import styles from '../TaskDescription.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

export function TaskPFastFilter({
  dataState,
  setDataState,
  packagesUpdate,
  filterValue,
}: {
  dataState: State;
  setDataState: React.Dispatch<React.SetStateAction<State>>;
  packagesUpdate: IPackagesUpdate;
  filterValue: React.MutableRefObject<any>;
}): React.ReactElement {
  const [activeBtn, setActiveBtn] = useState('all');

  return (
    <div className={styles.package_fast_filter}>
      <Button
        className={activeBtn === 'all' ? 'custom-type-search-active' : 'custom-type-search'}
        onClick={(): void =>
          handlerInstalledSearch(setDataState, filterValue, dataState, 'all', setActiveBtn, 'type')
        }
      >
        {localeAuditTask[currentLocale].packageList.customSearch.all} (
        {packagesUpdate ? packagesUpdate.allPkgs.length : 0})
      </Button>
      <Button
        className={activeBtn === 'updated' ? 'custom-type-search-active' : 'custom-type-search'}
        onClick={(): void =>
          handlerInstalledSearch(
            setDataState,
            filterValue,
            dataState,
            'updated',
            setActiveBtn,
            'status',
          )
        }
      >
        {localeAuditTask[currentLocale].packageList.customSearch.updated} (
        {packagesUpdate ? packagesUpdate.updatedPkgs.length : 0})
      </Button>
      <Button
        className={activeBtn === 'notUpdated' ? 'custom-type-search-active' : 'custom-type-search'}
        onClick={(): void =>
          handlerInstalledSearch(
            setDataState,
            filterValue,
            dataState,
            'notUpdated',
            setActiveBtn,
            'status',
          )
        }
      >
        {localeAuditTask[currentLocale].packageList.customSearch.notUpdated} (
        {packagesUpdate ? packagesUpdate.notUpdatedPkg.length : 0})
      </Button>
      <Button
        className={activeBtn === 'installed' ? 'custom-type-search-active' : 'custom-type-search'}
        onClick={(): void =>
          handlerInstalledSearch(
            setDataState,
            filterValue,
            dataState,
            'installed',
            setActiveBtn,
            'status',
          )
        }
      >
        {localeAuditTask[currentLocale].packageList.customSearch.installed} (
        {packagesUpdate ? packagesUpdate.installedPkgs.length : 0})
      </Button>
      <Button
        className={activeBtn === 'deleted' ? 'custom-type-search-active' : 'custom-type-search'}
        onClick={(): void =>
          handlerInstalledSearch(
            setDataState,
            filterValue,
            dataState,
            'deleted',
            setActiveBtn,
            'status',
          )
        }
      >
        {localeAuditTask[currentLocale].packageList.customSearch.deleted} (
        {packagesUpdate ? packagesUpdate.deletedPkg.length : 0})
      </Button>
    </div>
  );
}
