import { Checkbox } from '@progress/kendo-react-inputs';
import { Field } from '@progress/kendo-react-form';
import { useEffect } from 'react';
import localeSettings from '../../../../utils/i18n/settingsLocales/settings.json';
import styles from '../WhiteListTabEl.module.scss';
import { useWhiteListContext } from '../../../../hooks/useWhiteListContext';
import { FormInput } from '../../../common/form/FormInput';
import { FormTextArea } from '../../../common/form/FormTextArea';
import { useAccounts } from '../../../../hooks/components/useAccounts';
import { DropDownSelect } from '../../../common/form/DropDownSelect';
import { useAccountModalContext } from '../../../../hooks/useAccountModalContext';
import { getUserData } from '../../../../services/local-storage-service';
import localeUser from '../../../../utils/i18n/userLocales/userList.json';
import { checkLength } from '../../../../utils/helpers/validation';
import { BulletinMutableBlockWhiteList } from '../../../common/form/BulletinMutableBlockWhiteList';
import { useHostModalContext } from '../../../../hooks/useHostModalContext';
import { useNetDeviceContext } from '../../../../hooks/useNetDeviceContext';
import { useRegisterContext } from '../../../../hooks/useRegisterContext';
import { usedAssetType } from '../../../../utils/helpers/settings-helpers';
import { UsedAssetType } from './UsedAssetType';
import type { TAssets } from '../../../../utils/helpers/types';
import { useCommonContext } from '../../../../hooks/useCommonContext';

interface IValuesForCheckbox {
  isHosts: boolean;
  setHosts: React.Dispatch<React.SetStateAction<boolean>>;
  isGroups: boolean;
  setGroups: React.Dispatch<React.SetStateAction<boolean>>;
  isDevices: boolean;
  setDevices: React.Dispatch<React.SetStateAction<boolean>>;
  isImages: boolean;
  setImages: React.Dispatch<React.SetStateAction<boolean>>;
  isAssetType: boolean;
  setIsAssetType: React.Dispatch<React.SetStateAction<boolean>>;
}

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;
export function WhiteListFormMainPage({
  valuesForCheckbox,
}: {
  valuesForCheckbox: IValuesForCheckbox;
}): React.ReactElement {
  const currentUser = getUserData();

  const {
    isHosts,
    setHosts,
    isDevices,
    setDevices,
    isImages,
    setImages,
    isAssetType,
    setIsAssetType,
  } = valuesForCheckbox;

  const { changeDropDownAccount, accountId, setAccountId } = useAccountModalContext();

  // const { selectedVulns } = useAssetCreatePropContext();

  // const { pkgName, cveName } = useAuditModalContext();

  const { destructuringAccounts } = useAccounts();

  const destructuredAccounts = destructuringAccounts();

  const { imageForm, setImageForm } = useRegisterContext();

  const { whitelistUpdate, setAssetType } = useWhiteListContext();

  const {
    name,
    setName,
    description,
    setDescription,
    changeDropDownStatus,
    whiteListStatus,
    whiteListStatusValues,
    assetType,
  } = useWhiteListContext();

  const { accountOfAsset } = useCommonContext();

  // !__При использовании перенести в хук или контекст__!
  // const createTitle = (): void => {
  //   if (
  //     (document.location.pathname.includes('/assets/hosts/') ||
  //       document.location.pathname.includes('/assets/devices/')) &&
  //     !cveName &&
  //     !pkgName
  //   ) {
  //     if (selectedVulns && selectedVulns.length === 1) {
  //       const title = selectedVulns[0].hostname
  //         ? `${selectedVulns[0].vulnId}: уязвимость в ${selectedVulns[0].hostname}`
  //         : `${selectedVulns[0].vulnId}: уязвимость в ${selectedVulns[0].assetId}`;
  //       setName(title || '');
  //     }
  //   }
  //   if (document.location.pathname.includes('/assets/images/') && !cveName && !pkgName) {
  //     if (selectedVulns && selectedVulns.length === 1) {
  //       const title = selectedVulns[0].hostname
  //         ? `${selectedVulns[0].vulnId}: уязвимость в ${selectedVulns[0].hostname}`
  //         : `${selectedVulns[0].vulnId}: уязвимость в ${selectedVulns[0].assetId}`;
  //       setName(title || '');
  //     }
  //   }
  //   // if (
  //   //   (document.location.pathname.includes('/assets/hosts/') ||
  //   //     document.location.pathname.includes('/assets/devices/')) &&
  //   //   !cveName &&
  //   //   pkgName
  //   // ) {
  //   //   if (selectedVulns) {
  //   //     const title = selectedVulns[0].hostname
  //   //       ? `Уязвимость в ${pkgName.name} для ${selectedVulns[0].hostname}`
  //   //       : `Уязвимость в ${pkgName.name} для ${selectedVulns[0].assetId}`;
  //   //     setName(title || '');
  //   //   }
  //   // }
  //   if (
  //     (document.location.pathname.includes('/assets/hosts/') ||
  //       document.location.pathname.includes('/assets/devices/')) &&
  //     !cveName &&
  //     !pkgName
  //   ) {
  //     if (selectedVulns && selectedVulns.length > 1) {
  //       const title = selectedVulns[0].hostname
  //         ? `Уязвимости в: ${selectedVulns[0].hostname}`
  //         : `Уязвимости в: ${selectedVulns[0].assetId}`;
  //       setName(title || '');
  //     }
  //   }
  //   if (document.location.pathname.includes('/assets/images/') && !cveName && !pkgName) {
  //     if (selectedVulns && selectedVulns.length > 1) {
  //       const title = selectedVulns[0].imageNames
  //         ? `Уязвимости в: ${selectedVulns[0].imageNames}`
  //         : `Уязвимости в: ${selectedVulns[0].assetId}`;
  //       setName(title || '');
  //     }
  //   }
  //   if (!cveName && pkgName) {
  //     setName(pkgName.name || '');
  //   }
  //   if (cveName && !pkgName) {
  //     setName(cveName || '');
  //   }
  // };

  // useEffect(() => {
  //   createTitle();
  // }, []);

  const { hostForm, setHostForm, setHostFormTemp } = useHostModalContext();

  const { deviceForm, setDeviceForm, setDeviceFormTemp } = useNetDeviceContext();

  const types = assetType ? usedAssetType(assetType) : null;

  const changeCheckbox = (text: string, val: boolean): void => {
    if (text === 'host') {
      setHosts(val);
      if (!val) {
        setHostForm(null);
        setHostFormTemp(null);
      }
    }
    if (text === 'device') {
      setDevices(val);
      if (!val) {
        setDeviceForm(null);
        setDeviceFormTemp(null);
      }
    }
    if (text === 'image') {
      setImages(val);
      if (!val) setImageForm(null);
    }
    if (text === 'assetType') {
      setIsAssetType(val);
      if (!val)
        setAssetType({
          host: false,
          image: false,
          router: false,
          switch: false,
          firewall: false,
          all: false,
        });
    }
  };

  useEffect(() => {
    if (document.location.pathname.includes('/lk/assets/') && accountOfAsset) {
      setAccountId({
        id: accountOfAsset?.accountId || '',
        name: accountOfAsset?.name || '',
      });
    }
  }, [accountOfAsset, accountOfAsset?.accountId, accountOfAsset?.name, setAccountId]);

  useEffect(() => {
    if (hostForm && hostForm.length > 0) {
      setHosts(true);
    }
    if (deviceForm && deviceForm.length > 0) {
      setDevices(true);
    }
    if (imageForm && imageForm.length > 0) {
      setImages(true);
    }
  }, [deviceForm, hostForm, imageForm, setDevices, setHosts, setImages]);

  useEffect(() => {
    if (whitelistUpdate) {
      if (whitelistUpdate.scope.assets) {
        whitelistUpdate.scope.assets.forEach((a: TAssets) => {
          if (a.type === 'host' && hostForm) {
            setHosts(true);
          }
          if (a.type === 'image' && imageForm) {
            setImages(true);
          }
          if ((a.type === 'firewall' || a.type === 'router' || a.type === 'switch') && deviceForm) {
            setDevices(true);
          }
        });
      }
      if (whitelistUpdate.scope.assetGroupIds && hostForm) {
        setHosts(true);
      }
      if (
        whitelistUpdate.scope.assetTypes &&
        (assetType?.all ||
          assetType?.firewall ||
          assetType?.host ||
          assetType?.image ||
          assetType?.router ||
          assetType?.switch)
      ) {
        setIsAssetType(true);
      }
    }
  }, []);

  return (
    <>
      <div className={`form-block ${styles.formFieldWidth}`}>
        {!whitelistUpdate && currentUser?.role === 'super_admin' && (
          <Field
            name="accountId"
            component={DropDownSelect}
            label={localeUser[currentLocale].modal.accountName}
            onChange={changeDropDownAccount}
            data={destructuredAccounts?.filter((a) => a.id !== accountId.id)}
            textField="name"
            dataItemKey="id"
            defaultValue={{
              id: '000',
              name: localeUser[currentLocale].modal.placeholderAccountName,
            }}
            cls="form-field-wrapper"
            classForLabel="required-label"
            currentValue={accountId}
            disabled={!destructuredAccounts}
          />
        )}
        <Field
          name="name"
          component={FormInput}
          id="name"
          label={localeSettings[currentLocale].whiteList.form.name}
          onChange={(e): void => {
            setName(e.target.value.trimStart());
          }}
          inputValue={name}
          className="form-field-wrapper"
          classForLabel="required-label"
          customValidator={checkLength}
          type="text"
          required
        />
        <Field
          name="isDisabled"
          component={DropDownSelect}
          label={localeUser[currentLocale].modal.status}
          onChange={changeDropDownStatus}
          data={whiteListStatusValues
            .filter((s) => s.text !== whiteListStatus.text)
            .map((v) => v.text)}
          defaultValue={localeUser[currentLocale].active}
          cls="form-field-wrapper"
          currentValue={whiteListStatus.text}
        />
      </div>
      <BulletinMutableBlockWhiteList />
      <div className={styles.whitelistCheckboxWrapper}>
        <div className={styles.titleTabMain}>
          <span className="required-label">
            {localeSettings[currentLocale].whiteList.titleTabMain}
          </span>
          {/* {!isHosts && !isAssetType && !isImages && !isAssetType && (
            <span>{localeSettings[currentLocale].whiteList.titleTabMainInfo}</span>
          )} */}
        </div>
        <div className={styles.whitelistCheckboxContent}>
          <div className={styles.checkboxWrapperCount}>
            <Checkbox
              label={localeSettings[currentLocale].apiKey.form.checkbox.hostGroup}
              checked={isHosts}
              onChange={(): void => changeCheckbox('host', !isHosts)}
            />
            {hostForm && hostForm.length > 0 && isHosts && (
              <div className={styles.checkboxCount}>
                <span>
                  ({localeSettings[currentLocale].whiteList.form.selected} {hostForm?.length})
                </span>
              </div>
            )}
            {isHosts && (!hostForm || hostForm.length === 0) && (
              <div className={styles.checkboxCount}>
                <span>({localeSettings[currentLocale].whiteList.form.notSelected})</span>
              </div>
            )}
          </div>
          <div className={styles.checkboxWrapperCount}>
            <Checkbox
              label={localeSettings[currentLocale].apiKey.form.checkbox.deviceList}
              checked={isDevices}
              onChange={(): void => changeCheckbox('device', !isDevices)}
            />
            {deviceForm && deviceForm.length > 0 && isDevices && (
              <div className={styles.checkboxCount}>
                <span>
                  ({localeSettings[currentLocale].whiteList.form.selected} {deviceForm?.length})
                </span>
              </div>
            )}
            {isDevices && (!deviceForm || deviceForm.length === 0) && (
              <div className={styles.checkboxCount}>
                <span>({localeSettings[currentLocale].whiteList.form.notSelected})</span>
              </div>
            )}
          </div>
          <div className={styles.checkboxWrapperCount}>
            <Checkbox
              label={localeSettings[currentLocale].apiKey.form.checkbox.imageList}
              checked={isImages}
              onChange={(): void => changeCheckbox('image', !isImages)}
            />
            {imageForm && imageForm.length > 0 && isImages && (
              <div className={styles.checkboxCount}>
                <span>
                  ({localeSettings[currentLocale].whiteList.form.selected} {imageForm?.length})
                </span>
              </div>
            )}
            {isImages && (!imageForm || imageForm.length === 0) && (
              <div className={styles.checkboxCount}>
                <span>({localeSettings[currentLocale].whiteList.form.notSelected})</span>
              </div>
            )}
          </div>
          <div className={styles.checkboxWrapperCount}>
            <Checkbox
              label={localeSettings[currentLocale].apiKey.form.checkbox.assetType}
              checked={isAssetType}
              onChange={(): void => changeCheckbox('assetType', !isAssetType)}
            />
            {isAssetType &&
              !assetType.firewall &&
              !assetType.host &&
              !assetType.image &&
              !assetType.router &&
              !assetType.switch && (
                <div className={styles.checkboxCount}>
                  <span>({localeSettings[currentLocale].whiteList.form.notSelected})</span>
                </div>
              )}
          </div>
          {types && isAssetType && <UsedAssetType types={types} />}
        </div>
      </div>
      <div className={styles.apiDescriptionField}>
        <Field
          name="description"
          component={FormTextArea}
          id="description"
          label={localeSettings[currentLocale].apiKey.form.description}
          onChange={(e): void => {
            setDescription(e.value);
          }}
          inputValue={description || ''}
        />
      </div>
    </>
  );
}
