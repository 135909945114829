/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from 'react';
import {
  Grid,
  GridColumn,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Button } from '../../baseElements/Button';
import localeAuditTask from '../../../../utils/i18n/taskLocale/auditTask.json';
import type { EnrichedBulletinResponse } from '../../../../types/__generated/on-premise-solution/api/enrichedBulletinResponse.v1';
import { handleVulnModal } from '../../../../utils/helpers/audit-helpers';
import { useAuditModalContext } from '../../../../hooks/useAuditModalContext';
import { StandartCell } from '../../cells/StandartCell';
import { ScoreCvssCell } from '../../cells/ScoreCvssCell';
import { CweCell } from './cells/CweCell';
import { VectorCell } from './cells/VectorCell';
import { INITIAL_PAGING_10, LAZY_PAGEABLE_DATA } from '../../../../utils/helpers/constants';
import formStyle from '../../../features/Containers/form/Form.module.scss';
import styles from './vulnModal.module.scss';
import type { IPageState } from '../../../../utils/helpers/types';
import type { BulletinDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/bulletinDashboardResponse.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

export function VulnDescriptionModal({
  data,
}: {
  data: BulletinDashboardResponse | undefined;
}): React.ReactElement {
  const metrics = data && 'metrics' in data ? data.metrics : null;

  const { setCveName, setOpen } = useAuditModalContext();

  const [page, setPage] = useState<IPageState>(INITIAL_PAGING_10);

  const [sort, setSort] = useState<SortDescriptor[]>([]);

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  let delayOpenModal: NodeJS.Timeout;

  const openModal = (id: string): void => {
    delayOpenModal = setTimeout(() => {
      setCveName(null);
      handleVulnModal(id, setOpen, setCveName);
    }, 200);
  };

  useEffect(() => {
    return () => {
      clearTimeout(delayOpenModal);
    };
  }, []);

  return (
    <div className="vulns-description">
      {data && data.aliases && (
        <div className={styles.aliases_info}>
          <span className="aliases-info__prefix">
            {localeAuditTask[currentLocale].vulnsSoft.VulnModal.alternativeIDs}:{' '}
          </span>
          {data.aliases.map((a, i) => (
            <>
              <Button
                fill="modal"
                className={formStyle.add_button}
                onClick={(): void => openModal(a)}
              >
                {a}
              </Button>
              {data.aliases && i !== data.aliases.length - 1 ? ', ' : ''}
            </>
          ))}
        </div>
      )}
      <div className="description">
        <span>{data?.description}</span>
      </div>

      <Grid
        scrollable="none"
        data={metrics ? orderBy(metrics, sort).slice(page.skip, page.take + page.skip) : null}
        total={metrics?.length || 0}
        pageable={metrics?.length && metrics?.length > 10 ? LAZY_PAGEABLE_DATA : false}
        skip={page.skip}
        take={page.take}
        onPageChange={pageChange}
        sortable
        sort={sort}
        onSortChange={(e: GridSortChangeEvent): void => {
          setSort(e.sort);
        }}
      >
        <GridColumn width="100px" title="Metric" field="source" cell={StandartCell} />
        <GridColumn width="124px" title="CVSS Score" field="cvss.score" cell={ScoreCvssCell} />
        <GridColumn width="140px" title="CVSS Severity" field="severity" cell={StandartCell} />
        <GridColumn title="CVSS Vector" field="cvss.vector" cell={VectorCell} />
        <GridColumn title="CWE" field="cwe" cell={CweCell} />
      </Grid>
    </div>
  );
}
