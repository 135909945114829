/* eslint-disable no-nested-ternary */
import { useMemo } from 'react';
import type { InventorizationDifference } from '../../../../../types/__generated/on-premise-solution/api/inventorizationDifference.v1';
import {
  DiffFilterTypes,
  defineObjectWrapperStatus,
  defineWrapperStatus,
  defineWrapperStatuses,
  prepareDiffObjectData,
} from '../../../../../utils/helpers/difference-modal-helper';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { WrapperMenu } from '../../../Task/taskIDetails/iDetailsComponents/WrapperMenu';
import styles from '../../forms/AddAssetForm.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function DiffOs({
  data,
  filter,
}: {
  data: InventorizationDifference;
  filter: DiffFilterTypes;
}): React.ReactElement {
  const osInfoData = useMemo(() => prepareDiffObjectData(data.os, filter), [data.os, filter]);

  const wrapperStatuses = filter === 'all' ? defineWrapperStatuses(data.os) : null;

  return Object.keys(osInfoData).length ? (
    <WrapperMenu title={locale[currentLocale].os.title} diffStatuses={wrapperStatuses}>
      <table className="column">
        {'name' in osInfoData && (
          <>
            {osInfoData.name.map((osName) => (
              <tr>
                <td className={osName.className}>{locale[currentLocale].os.name}</td>
                <td className={osName.className}>{osName.value || '-'}</td>
              </tr>
            ))}
          </>
        )}
        {'version' in osInfoData && (
          <>
            {osInfoData.version.map((osVersion) => (
              <tr>
                <td className={osVersion.className}>{locale[currentLocale].os.version}</td>
                <td className={osVersion.className}>{osVersion.value || '-'}</td>
              </tr>
            ))}
          </>
        )}
        {'type' in osInfoData && (
          <>
            {osInfoData.type.map((osType) => (
              <tr>
                <td className={osType.className}>{locale[currentLocale].os.type}</td>
                <td className={osType.className} style={{ textTransform: 'capitalize' }}>
                  {osType.value || '-'}
                </td>
              </tr>
            ))}
          </>
        )}
        {data.type !== 'router' && data.type !== 'firewall' && 'arch' in osInfoData && (
          <>
            {osInfoData.arch.map((osArch) => (
              <tr>
                <td className={osArch.className}>{locale[currentLocale].os.arch}</td>
                <td className={osArch.className}>{osArch.value || '-'}</td>
              </tr>
            ))}
          </>
        )}
      </table>
      <table className="column">
        {data.type !== 'router' && data.type !== 'firewall' && 'platform' in osInfoData && (
          <>
            {osInfoData.platform.map((osPlatform) => (
              <tr>
                <td className={osPlatform.className}>{locale[currentLocale].os.platform}</td>
                <td className={osPlatform.className} style={{ textTransform: 'capitalize' }}>
                  {osPlatform.value || '-'}
                </td>
              </tr>
            ))}
          </>
        )}
        {data.type !== 'router' && data.type !== 'firewall' && 'platform_like' in osInfoData && (
          <>
            {osInfoData.platform_like.map((osPlatformLike) => (
              <tr>
                <td className={osPlatformLike.className}>
                  {locale[currentLocale].os.platformLike}
                </td>
                <td className={osPlatformLike.className} style={{ textTransform: 'capitalize' }}>
                  {osPlatformLike.value || '-'}
                </td>
              </tr>
            ))}
          </>
        )}
        {data.type !== 'router' && data.type !== 'firewall' && 'install_date' in osInfoData && (
          <>
            {osInfoData.install_date.map((osInstallDate) => (
              <tr>
                <td className={osInstallDate.className}>{locale[currentLocale].os.installDate}</td>
                <td className={osInstallDate.className}>
                  {osInstallDate.value
                    ? new Date(String(osInstallDate.value)).toLocaleString()
                    : '-'}
                </td>
              </tr>
            ))}
          </>
        )}
      </table>
    </WrapperMenu>
  ) : (
    <></>
  );
}
