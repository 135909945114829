import { useEffect, useMemo, useRef, useState } from 'react';
import { Grid, GridColumn as Column, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { PAGEABLE_DATA } from '../../../../../utils/helpers/constants';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { Input } from '../../../../common/baseElements/Input';
import hostDescriptionLocale from '../../../../../utils/i18n/assetLocales/hostDescription.json';
import localeAuditTask from '../../../../../utils/i18n/taskLocale/auditTask.json';
import { Button } from '../../../../common/baseElements/Button';
import { DiffFilters } from '../DiffFilters';
import type { InventorizationDifferenceLinux } from '../../../../../types/__generated/on-premise-solution/api/inventorizationDifference.v1';
import { createFromListInvData } from '../../../../../utils/helpers/difference-modal-helper';
import { DiffStandartCell } from '../cells/DiffStandartCell';
import { DiffTypeCell } from '../cells/DiffTypeCell';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function DiffServicesLin({
  data,
}: {
  data: InventorizationDifferenceLinux['systemd_units'];
}): React.ReactElement {
  const _export = useRef<ExcelExport | null>(null);
  const [page, setPage] = useState({ take: 10, skip: 0 });
  const sort: SortDescriptor[] = [{ field: 'name', dir: 'asc' }];
  const [searchValue, setSearchValue] = useState('');
  const [filter, setFilter] = useState<
    'all' | 'noChanges' | 'updated' | 'changed' | 'installed' | 'removed'
  >('all');
  const servicesData = useMemo(
    () => createFromListInvData(data as unknown as { new: any[]; noChanges: []; old: any[] }[]),
    [data],
  );
  const [servicesList, setServicesList] = useState(servicesData[filter]);

  const filterList = {
    all: `${hostDescriptionLocale[currentLocale].changes.shortFilters.soft.all} (${servicesData.all.length})`,
    noChanges: `${hostDescriptionLocale[currentLocale].changes.shortFilters.soft.noChanges} (${servicesData.noChanges.length})`,
    // updated: `${hostDescriptionLocale[currentLocale].changes.shortFilters.soft.updated} (${servicesData.updated.length / 2})`,
    installed: `${hostDescriptionLocale[currentLocale].changes.shortFilters.vulns.added} (${servicesData.installed.length})`,
    removed: `${hostDescriptionLocale[currentLocale].changes.shortFilters.vulns.removed} (${servicesData.removed.length})`,
    changed: `${hostDescriptionLocale[currentLocale].changes.shortFilters.vulns.changed} (${servicesData.changed.length})`,
  };

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  useEffect(() => {
    setServicesList(
      servicesData[filter].filter((service) => {
        if (!service.id) return false;

        return service.id.toLowerCase().includes(searchValue.toLowerCase());
      }),
    );
    setPage((prev) => ({ ...prev, skip: 0 }));
  }, [filter, searchValue, data]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save(servicesData.all);
    }
  };

  return (
    <div className="service-int">
      <div className="filter-line">
        <Input
          name="customSearch"
          placeholder={localeAuditTask[currentLocale].customFilterName}
          onChange={handleSearch}
          value={searchValue}
        />
        <Button excel fill="flat" onClick={excelExport}>
          {localeAuditTask[currentLocale].vulnsSoft.table.exportToExcel}
        </Button>
      </div>
      <DiffFilters currentFilter={filter} setFilter={setFilter} filterList={filterList} />
      <ExcelExport ref={_export} fileName={locale[currentLocale].tabs.services}>
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <Grid
              pageable={PAGEABLE_DATA}
              sortable={false}
              // sort={sort}
              total={servicesList ? servicesList.length : 0}
              skip={page.skip}
              take={page.take}
              onPageChange={pageChange}
              data={orderBy(servicesList || [], sort)?.slice(page.skip, page.take + page.skip)}
              className="no-margin-top diffinventorization__servicegrid"
              scrollable="none"
            >
              <Column
                field="diffType"
                cell={DiffTypeCell}
                title=" "
                width={20}
                sortable={false}
                headerClassName="no-cursor"
              />
              <Column
                field="id"
                title={locale[currentLocale].services.id}
                width="240px"
                cell={DiffStandartCell}
                sortable={false}
                headerClassName="no-cursor"
              />
              <Column
                field="description"
                title={locale[currentLocale].services.description}
                cell={DiffStandartCell}
                sortable={false}
                headerClassName="no-cursor"
              />
              <Column
                field="active_state"
                title={locale[currentLocale].services.activeState}
                width="88px"
                cell={DiffStandartCell}
                sortable={false}
                headerClassName="no-cursor"
              />
              <Column
                field="sub_state"
                title={locale[currentLocale].services.subState}
                width="88px"
                cell={DiffStandartCell}
                sortable={false}
                headerClassName="no-cursor"
              />
              <Column
                field="load_state"
                title={locale[currentLocale].services.downloadStatus}
                width="116px"
                cell={DiffStandartCell}
                sortable={false}
                headerClassName="no-cursor"
              />
              <Column
                field="pid"
                title={locale[currentLocale].services.pid}
                width="72px"
                cell={DiffStandartCell}
                sortable={false}
                headerClassName="no-cursor"
              />
              <Column
                field="fragment_path"
                title={locale[currentLocale].services.fragmentPath}
                cell={DiffStandartCell}
                sortable={false}
                headerClassName="no-cursor"
                className="title"
              />
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      </ExcelExport>
    </div>
  );
}
