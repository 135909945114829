import type { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import type { IUrlParamsTaskList } from './task-list-helper';

export function fillFiltersTaskList(
  urlParams: IUrlParamsTaskList,
  filterValue: React.MutableRefObject<CompositeFilterDescriptor | null>,
  setFilter: (value: React.SetStateAction<CompositeFilterDescriptor>) => void,
  customSearch: string,
): void {
  if (
    urlParams.filterUrlData.name24ne &&
    (!urlParams.filterUrlData.startedAt24gt ||
      !urlParams.filterUrlData.startedAt24gte ||
      !urlParams.filterUrlData.startedAt24ne ||
      !urlParams.filterUrlData.startedAt24lt ||
      !urlParams.filterUrlData.startedAt24lte)
  ) {
    filterValue.current = {
      filters: [
        {
          field: 'name',
          operator: 'neq',
          value: urlParams.filterUrlData.name24ne,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  } else if (urlParams.filterUrlData.name24ne && urlParams.filterUrlData.startedAt24gt) {
    filterValue.current = {
      filters: [
        {
          field: 'name',
          operator: 'neq',
          value: urlParams.filterUrlData.name24ne,
        },
        {
          field: 'startedAt',
          operator: 'gt',
          value: urlParams.filterUrlData.startedAt24gt,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  } else if (urlParams.filterUrlData.name24eq && !urlParams.filterUrlData.startedAt24gt) {
    filterValue.current = {
      filters: [
        {
          field: 'name',
          operator: 'eq',
          value: urlParams.filterUrlData.name24eq,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  } else if (urlParams.filterUrlData.name24eq && urlParams.filterUrlData.startedAt24gt) {
    filterValue.current = {
      filters: [
        {
          field: 'name',
          operator: 'eq',
          value: urlParams.filterUrlData.name24eq,
        },
        {
          field: 'startedAt',
          operator: 'gt',
          text: '',
          value: urlParams.filterUrlData.startedAt24gt,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  } else if (
    urlParams.filterUrlData.name24regex &&
    !urlParams.filterUrlData.startedAt24gt &&
    customSearch === ''
  ) {
    filterValue.current = {
      filters: [
        {
          field: 'name',
          operator: 'contains',
          value: urlParams.filterUrlData.name24regex,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  } else if (
    urlParams.filterUrlData.name24regex &&
    urlParams.filterUrlData.startedAt24gt &&
    customSearch === ''
  ) {
    filterValue.current = {
      filters: [
        {
          field: 'name',
          operator: 'contains',
          value: urlParams.filterUrlData.name24regex,
        },
        {
          field: 'startedAt',
          operator: 'gt',
          value: urlParams.filterUrlData.startedAt24gt,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  } else if (urlParams.filterUrlData.name24not24regex && !urlParams.filterUrlData.startedAt24gt) {
    filterValue.current = {
      filters: [
        {
          field: 'name',
          operator: 'doesnotcontain',
          value: urlParams.filterUrlData.name24not24regex,
        },
        {
          field: 'startedAt',
          operator: 'gt',
          value: urlParams.filterUrlData.startedAt24gt,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  } else if (urlParams.filterUrlData.name24not24regex && urlParams.filterUrlData.startedAt24gt) {
    filterValue.current = {
      filters: [
        {
          field: 'name',
          operator: 'doesnotcontain',
          value: urlParams.filterUrlData.name24not24regex,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  } else if (
    (!urlParams.filterUrlData.name24not24regex ||
      !urlParams.filterUrlData.name24regex ||
      !urlParams.filterUrlData.name24eq ||
      !urlParams.filterUrlData.name24ne) &&
    urlParams.filterUrlData.startedAt24gt
  ) {
    filterValue.current = {
      filters: [
        {
          field: 'startedAt',
          operator: 'gt',
          value: urlParams.filterUrlData.startedAt24gt,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  }
}
