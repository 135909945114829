import React, { createContext, useState, useMemo, useContext, useEffect, useCallback } from 'react';
import type { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import type { Account } from '../types/__generated/on-premise-solution/api/account.v1';
import type { IChildrenNodes } from '../utils/helpers/types';
import { useCommonContext } from './useCommonContext';
// import localeUserList from '../utils/i18n/userLocales/userList.json';

export type AccountEdit = Pick<Account, 'accountId' | 'name'>;

export type DifferenceModalContent = {
  isAuditOpen: boolean;
  setAuditOpen: (v: boolean) => void;
  isInvOpen: boolean;
  setInvOpen: (v: boolean) => void;
  assetType: 'host' | 'image' | 'device' | undefined;
  setAssetType: (v: 'host' | 'image' | 'device' | undefined) => void;
  // diffType: 'audit' | 'inventorization' | undefined;
  // setDiffType: (v: 'audit' | 'inventorization' | undefined) => void;
  // isEdit: boolean;
  // name: string;
  // setName: (v: string) => void;
  // setEdit: (v: boolean) => void;
  // accountData: AccountEdit | null;
  // setAccountData: (v: AccountEdit | null) => void;
  // isAddAccount: boolean;
  // setAddAccount: (v: boolean) => void;
  // accountForm: IAccountForm[] | null;
  // setAccountForm: React.Dispatch<React.SetStateAction<IAccountForm[] | null>>;
  // deleteAccountFromTask: (
  //   accountId: string | undefined,
  //   event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  // ) => void;
  // exitAccountFrom: () => void;
  // delAllAccounts: () => void;
  // addSelectedAccounts: () => void;
  // accountId: { id: string; name: string };
  // setAccountId(accountId: { id: string; name: string }): void;
  // changeDropDownAccount: (event: DropDownListChangeEvent) => void;
};

// const currentLocale = (
//   window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
// ) as keyof typeof localeUserList;
// export interface IAccountForm {
//   accountId: string | undefined;
//   name: string | undefined;
// }

const DifferenceModalContext = createContext<DifferenceModalContent | null>(null);

export function DifferenceModalProvider({ children }: IChildrenNodes): React.ReactElement {
  // const { setOpenForm } = useCommonContext();

  const [isAuditOpen, setAuditOpen] = useState(false);

  const [isInvOpen, setInvOpen] = useState(false);

  const [assetType, setAssetType] = useState<'host' | 'image' | 'device' | undefined>(undefined);

  // const [diffType, setDiffType] = useState<'audit' | 'inventorization' | undefined>(undefined);

  // useEffect(() => { if (!isOpen) setDiffType(undefined) }, [isOpen]);

  // const [isEdit, setEdit] = useState(false);

  // const [name, setName] = useState<string>('');

  // const [accountData, setAccountData] = useState<AccountEdit | null>(null);

  // const [isAddAccount, setAddAccount] = useState(false);

  // const [accountForm, setAccountForm] = useState<IAccountForm[] | null>(null);

  // const [accountId, setAccountId] = useState<AccountModalContent['accountId']>({
  //   id: '000',
  //   name: localeUserList[currentLocale].modal.placeholderAccountId,
  // });

  // useEffect((): void => {
  //   setOpenForm(isOpen);
  // }, [isOpen, setOpenForm]);

  // const deleteAccountFromTask = useCallback(
  //   (id: string | undefined, event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
  //     event.stopPropagation();
  //     if (accountForm && accountForm.length > 0) {
  //       setAccountForm(accountForm?.filter((a) => a.accountId !== id));
  //     }
  //   },
  //   [accountForm],
  // );

  // const exitAccountFrom = useCallback(() => {
  //   setAccountForm(null);
  //   setAddAccount(false);
  // }, []);

  // const delAllAccounts = useCallback((): void => {
  //   setAccountForm(null);
  // }, []);

  // const addSelectedAccounts = useCallback((): void => {
  //   setAccountForm(accountForm);
  //   setAddAccount(false);
  // }, [accountForm]);

  // const changeDropDownAccount = useCallback(
  //   (event: DropDownListChangeEvent): void => {
  //     setAccountId({
  //       id: event.target.value.id,
  //       name: event.target.value.name,
  //     });
  //   },
  //   [setAccountId],
  // );

  const value = useMemo(
    () => ({
      isAuditOpen,
      setAuditOpen,
      isInvOpen,
      setInvOpen,
      assetType,
      setAssetType,
      // diffType,
      // setDiffType,
      // isEdit,
      // setEdit,
      // name,
      // setName,
      // accountData,
      // setAccountData,
      // isAddAccount,
      // setAddAccount,
      // accountForm,
      // setAccountForm,
      // deleteAccountFromTask,
      // exitAccountFrom,
      // delAllAccounts,
      // addSelectedAccounts,
      // accountId,
      // setAccountId,
      // changeDropDownAccount,
    }),
    [
      isAuditOpen,
      isInvOpen,
      assetType,
      // diffType,
      // isEdit,
      // name,
      // accountData,
      // isAddAccount,
      // accountForm,
      // deleteAccountFromTask,
      // exitAccountFrom,
      // delAllAccounts,
      // addSelectedAccounts,
      // accountId,
      // changeDropDownAccount,
    ],
  );

  return <DifferenceModalContext.Provider value={value}>{children}</DifferenceModalContext.Provider>;
}
export const useDifferenceModalContext = (): DifferenceModalContent => {
  const context = useContext(DifferenceModalContext);
  if (context === null) {
    throw new Error('ModalContext must be used inside the ModalContext.Provider.');
  }

  return context;
};
