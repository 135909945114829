/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-pascal-case */
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { State, process, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { useRef, useState } from 'react';
import { FILTER_INITIAL, PAGEABLE_DATA } from '../../../../../utils/helpers/constants';
import localeSettings from '../../../../../utils/i18n/settingsLocales/settings.json';
import styles from '../ApiKeyList.module.scss';
import type { ApiKeyDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/apiKeyDashboardResponse.v1';
import { CellAssetName } from '../cells/CellAssetName';
import { CellTypeApiKeyList } from '../cells/CellTypeApiKeyList';
import { Input } from '../../../../common/baseElements/Input';
import { handlerCustomSearch } from '../../../../../utils/helpers/local-filter-helpers';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

export function AssetDescriptionList({
  assets,
}: {
  assets: ApiKeyDashboardResponse['scope']['assets'] | undefined;
}): React.ReactElement {
  const pageLimitGrid: { [key: string]: string } =
    localStorage.getItem('pageLimitGrid') &&
    JSON.parse(localStorage.getItem('pageLimitGrid') || '');

  const initialDataState: State = {
    sort: [{ field: 'name', dir: 'desc' }],
    take: pageLimitGrid && pageLimitGrid.ApiMessods ? Number(pageLimitGrid.ApiMessods) : 15,
    skip: 0,
    filter: FILTER_INITIAL,
  };

  const fieldFindInTable = 'latestInventorization.hostname';

  const [dataState, setDataState] = useState<State>(initialDataState);

  const [customSearch, setCustomSearch] = useState('');

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  return (
    <div className="accounts">
      {assets ? (
        <>
          {/* <div className={styles.titleDescriptionList}>
            <span>{localeSettings[currentLocale].apiKey.restrictions.titleAsset}</span>
          </div> */}
          <Input
            className={`k-input k-input-md ${styles.apiKeyCustomSearch}`}
            name="customSearch"
            placeholder={localeSettings[currentLocale].apiKey.restrictions.assetSearch}
            value={customSearch}
            onChange={(e): void =>
              handlerCustomSearch(
                e,
                setCustomSearch,
                setDataState,
                filterValue,
                dataState,
                fieldFindInTable,
              )
            }
          />
          <LocalizationProvider language={currentLocale}>
            <IntlProvider locale={currentLocale.substring(0, 2)}>
              <Grid
                pageable={PAGEABLE_DATA}
                sortable
                data={process(assets || [], dataState)}
                {...dataState}
                onDataStateChange={(e: GridDataStateChangeEvent): void => {
                  setDataState(e.dataState);
                  if (pageLimitGrid) {
                    const pageLimit = {
                      ...pageLimitGrid,
                      componentName: String(e.dataState.take),
                    };
                    localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
                  } else {
                    const pageLimit = {
                      componentName: String(e.dataState.take),
                    };
                    localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
                  }
                }}
                scrollable="none"
              >
                <Column
                  field="latestInventorization.hostname"
                  title={localeSettings[currentLocale].apiKey.form.tabs.asset}
                  filter="text"
                  cell={CellAssetName}
                />
                <Column
                  field="assetId"
                  title={localeSettings[currentLocale].apiKey.restrictions.assetIds}
                  filter="text"
                />
                <Column
                  field="type"
                  title={localeSettings[currentLocale].apiKey.restrictions.type}
                  filter="text"
                  cell={CellTypeApiKeyList}
                />
              </Grid>
            </IntlProvider>
          </LocalizationProvider>
        </>
      ) : (
        <div className={styles.titleDescriptionList}>
          <span>{localeSettings[currentLocale].apiKey.form.tabs.titleNoRestriction}</span>
        </div>
      )}
    </div>
  );
}
