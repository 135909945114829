import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Router } from 'react-router-dom';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { history } from './utils/history';
import { NotificationProvider } from './hooks/useNotificationContext';
import { WebSocketProvider } from './hooks/useWebSocketContext';
import { AuthProvider } from './hooks/useAuthContext';
import { AuditModalProvider } from './hooks/useAuditModalContext';

ReactDOM.render(
  <StrictMode>
    <Router history={history}>
      <NotificationProvider>
        <AuthProvider>
          <WebSocketProvider>
            <AuditModalProvider>
              <App />
            </AuditModalProvider>
          </WebSocketProvider>
        </AuthProvider>
      </NotificationProvider>
    </Router>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
