import { nanoid } from 'nanoid';
import { NavLink } from 'react-router-dom';
import { Button } from '../../../common/baseElements/Button';
import { useAuditModalContext } from '../../../../hooks/useAuditModalContext';
import type { BulletinsWidgetResponse } from '../../../../types/__generated/on-premise-solution/api/bulletinsWidgetResponse.v1';
import { handleVulnModal } from '../../../../utils/helpers/audit-helpers';
import locale from '../../../../utils/i18n/dashboardLocale/dashboardLayout.json';
import { CvssBlock } from '../../../common/smallBlocks/CvssBlock';
import styles from '../Dashboard.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function Bulletins({
  data,
}: {
  data: BulletinsWidgetResponse | undefined;
}): React.ReactElement {
  const { setCveName, setOpen } = useAuditModalContext();

  return (
    <table className="horizontal">
      <thead>
        <tr>
          <th className={styles.cvss_score}>CVSS</th>
          {/* <th>{locale[currentLocale].bulletinDescription}</th> */}
          <th>{locale[currentLocale].bulletinId}</th>
          <th className={styles.total_assets}>{locale[currentLocale].totalAssets}</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((b) => (
          <tr key={nanoid(4)}>
            <td>
              <CvssBlock score={b.bulletinMaxCvssScore} />
            </td>
            <td>
              <Button
                fill="modal"
                onClick={(): void => handleVulnModal(b.bulletinId, setOpen, setCveName)}
                className="dashboard-link-vulns cvss-button"
              >
                {b.bulletinId}
              </Button>
            </td>
            <td className="number">
              <NavLink
                className="dashboard__link"
                to={`/lk/assets/hosts?skip=0&limit=15&sortField=latestAudit.maxCvssScore&sortOrder=desc&bulletinId[$eq]=${b.bulletinId}`}
              >
                {b.totalAssets}
              </NavLink>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
