/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from 'react';
import { UseQueryResult, useMutation, useQuery, useQueryClient } from 'react-query';
import { nanoid } from 'nanoid';
import { Button } from '../../../common/baseElements/Button';
import localeHostDescription from '../../../../utils/i18n/assetLocales/hostDescription.json';
import { Input } from '../../../common/baseElements/Input';
import type { TagCreationRequest } from '../../../../types/__generated/on-premise-solution/api/tagCreationRequest.v1';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import type { TagCreationResponse } from '../../../../types/__generated/on-premise-solution/api/tagCreationResponse.v1';
import { createTag, getAllTags } from '../../../../services/asset-service';
import { useHostActions } from '../../../../hooks/components/useHostActions';
import type { AssetUpdateRequest } from '../../../../types/__generated/on-premise-solution/api/assetUpdateRequest.v1';
import type { TagsResponse } from '../../../../types/__generated/on-premise-solution/api/tagsResponse.v1';
import type { AssetHostDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/assetHostDashboardResponse.v1';
import { TagList } from './TagList';
import styles from './HostDescription.module.scss';
import { useAssetActions } from '../../../../hooks/components/useAssetActions';
import { useHostDescription } from '../../../../hooks/components/useHostDescription';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHostDescription;

interface IAddTagProp {
  marginTop: string;
  marginLeft: string;
  padding: string;
  fontSize: string;
}

export function AddTag({
  addTagProp,
  assetId,
}: {
  addTagProp: IAddTagProp;
  assetId: string;
}): React.ReactElement {
  const { assetIdData } = useHostDescription();

  const queryClient = useQueryClient();

  const [tagName, setTagName] = useState<string>('');

  const { marginTop, marginLeft } = addTagProp;

  const { handleEditHost } = useHostActions();

  const { prepareTagData, addExistedTag, getTextColor } = useAssetActions();

  const mutationNewTag = useMutation<TagCreationResponse, ApiError, TagCreationRequest>(
    (payload) => createTag(payload),
    {
      onSuccess: (resp) => {
        const val = {
          tagIdsToAdd: [resp.tagId],
          type: 'host',
        } as AssetUpdateRequest;

        handleEditHost(assetId, val);

        queryClient.invalidateQueries('tagsList');
      },
    },
  );

  const tagsQuery = useQuery<TagsResponse, ApiError>(
    ['tagsList', tagName],
    () => getAllTags(tagName),
    {
      keepPreviousData: true,
    },
  );

  const changeTagName = (val: string): void => {
    setTagName(val.toLocaleLowerCase());
  };

  const prepareTagVals = {
    tagsQuery,
    tagName: tagName.trim(),
    mutationNewTag,
    assetId,
  };

  const resultTags = tagsQuery.data?.data.filter(
    (tAll) => !assetIdData?.tags?.some((t) => tAll.tagId === t.tagId),
  );

  return (
    <div
      className={styles.addTag}
      style={{
        marginTop,
        marginLeft,
      }}
      onKeyDown={(e): void => prepareTagData({ ...prepareTagVals, e })}
    >
      {assetIdData?.tags && assetIdData.tags.length > 0 && (
        <div className={styles.addTagListWrap}>
          <TagList
            assetId={assetId}
            tags={assetIdData?.tags}
            tagIds={assetIdData?.tagIds}
            componentName="AddTag"
          />
        </div>
      )}
      <Input
        name="tagName"
        type="text"
        value={tagName}
        id="tag"
        onChange={(e): void => {
          changeTagName(e.target.value);
        }}
        className="k-input input-noborder-bottom"
        placeholder={localeHostDescription[currentLocale].searchOrAddTag}
        max={100}
      />
      <div className={styles.addAllTags}>
        {resultTags
          ?.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;

            return 0;
          })
          ?.map((t) => (
            <Button
              key={nanoid()}
              onClick={(): void => addExistedTag(t.name, tagsQuery, assetId)}
              className="button-add-tag"
            >
              {/* <span>{t.name}</span> */}
              <div
                key={nanoid()}
                className={styles.tagContentWrap}
                style={{ backgroundColor: t.color }}
              >
                <span
                  className={styles.tagTitle}
                  style={{ color: getTextColor(t.color) }}
                  title={t.name && t.name.length > 50 ? t.name : ''}
                >
                  {t.name}
                </span>
              </div>
            </Button>
          ))}
      </div>
    </div>
  );
}
