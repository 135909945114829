import type { GridCellProps } from '@progress/kendo-react-grid';
import type { TaskHostDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/tasksDashboardResponse.v1';

export function TaskName(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { status, latestTaskExecutionId, name }: TaskHostDashboardResponse = dataItem;

  return (
    <td>
      {(status === 'processing' && latestTaskExecutionId) ||
      (status === 'waiting' && latestTaskExecutionId) ? (
        <span>{name}</span>
      ) : (
        <span>{name}</span>
      )}
    </td>
  );
}
