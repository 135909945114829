import { useEffect, useState } from 'react';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { checkCaptchaField, checkLength, checkPassword } from '../../../utils/helpers/validation';
import { CheckBox } from '../../common/form/CheckBox';
import styles from './Form.module.scss';
import type { FormDataValue } from '../../../utils/helpers/types';
import { useNotificationContext } from '../../../hooks/useNotificationContext';
import { handleError } from '../../../utils/errors';
import { useAuthContext } from '../../../hooks/useAuthContext';
import localeLogin from '../../../utils/i18n/loginLocale/loginForm.json';
import { FormInput } from '../../common/form/FormInput';
import { Button } from '../../common/baseElements/Button';
import { TwoFactoredAuthForm } from './TwoFactoredAuthForm';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { checkCaptcha } from '../../../services/auth';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeLogin;
export function LoginForm({
  mutationError,
  handleSubmit,
  handleSubmitTfaCode,
}: {
  mutationError: ApiError | null;
  handleSubmit: (dataItem: FormDataValue) => void;
  handleSubmitTfaCode: (dataItem: FormDataValue) => void;
}): React.ReactElement {
  const { createNotification } = useNotificationContext();

  const {
    type,
    stayOn,
    setStayOn,
    login,
    setLogin,
    password,
    setPassword,
    username,
    setUsername,
    uid,
    setUid,
    isTwoFactoredAuthPage,
    loginError,
    setLoginError,
    captcha,
  } = useAuthContext();

  const [captchaText, setCaptchaText] = useState('');

  useEffect(() => {
    if (mutationError) {
      handleError(mutationError, createNotification);
    }
  }, [mutationError, createNotification]);

  return isTwoFactoredAuthPage ? (
    <TwoFactoredAuthForm handleSubmit={handleSubmitTfaCode} />
  ) : (
    <div style={{ display: 'flex' }}>
      <Form
        onSubmit={(dataItem: FormDataValue): void => {
          handleSubmit(dataItem);
          // setLoginError('');
          setCaptchaText('');
        }}
        render={(): JSX.Element => (
          <FormElement>
            <fieldset className="k-form-fieldset">
              <legend className={styles.form_legend}>{localeLogin[currentLocale].formTitle}</legend>
              <div className={styles.field_block}>
                {type === 'local' && (
                  <Field
                    name="login"
                    component={FormInput}
                    id="login"
                    label={localeLogin[currentLocale].loginLabel}
                    onChange={(e): void => {
                      setLogin(e.target.value);
                    }}
                    inputValue={login}
                    customValidator={checkLength}
                    type="text"
                  />
                )}
                {type === 'activedirectory' && (
                  <Field
                    name="username"
                    component={FormInput}
                    id="username"
                    label={localeLogin[currentLocale].adName}
                    onChange={(e): void => {
                      setUsername(e.target.value);
                    }}
                    inputValue={username}
                    className="form-field-wrapper"
                    customValidator={checkLength}
                    type="text"
                  />
                )}
                {type === 'ldap' && (
                  <Field
                    name="uid"
                    component={FormInput}
                    id="uid"
                    label={localeLogin[currentLocale].ldapName}
                    onChange={(e): void => {
                      setUid(e.target.value);
                    }}
                    inputValue={uid}
                    className="form-field-wrapper"
                    customValidator={checkLength}
                    type="text"
                  />
                )}
              </div>
              <div className={styles.field_block}>
                <Field
                  name="password"
                  component={FormInput}
                  id="password"
                  type="password"
                  label={localeLogin[currentLocale].passwordLabel}
                  onChange={(e): void => {
                    setPassword(e.target.value);
                  }}
                  inputValue={password}
                  className="form-field-wrapper"
                  customValidator={checkPassword}
                />
              </div>
              <div className={styles.field_block}>
                <Field
                  name="stayOn"
                  component={CheckBox}
                  value={stayOn}
                  onChange={setStayOn}
                  label={localeLogin[currentLocale].stayonLabel}
                />
              </div>
              {captcha && (
                <div>
                  <img src={`data:image/svg+xml;base64,${captcha}`} alt="" />
                  <Field
                    name="captcha"
                    component={FormInput}
                    id="captcha"
                    // label={localeLogin[currentLocale].loginLabel}
                    onChange={(e): void => {
                      setCaptchaText(e.target.value);
                    }}
                    inputValue={captchaText}
                    customValidator={checkCaptchaField}
                    type="text"
                    className="form-field-wrapper"
                  />
                </div>
              )}
            </fieldset>
            <div className="k-form-buttons">
              <Button
                type="submit"
                disabled={
                  (type === 'local' && (!login || !password)) ||
                  (type === 'ldap' && (!uid || !password)) ||
                  (type === 'activedirectory' && (!username || !password))
                }
              >
                {localeLogin[currentLocale].loginBtn}
              </Button>
            </div>
            <div className="error-common-field">
              <span>{loginError}</span>
            </div>
          </FormElement>
        )}
      />
    </div>
  );
}
