/* eslint-disable react/jsx-props-no-spreading */
import { Dialog } from '@progress/kendo-react-dialogs';
import { State, process } from '@progress/kendo-data-query';
import { memo, useEffect, useRef, useState } from 'react';
import { Grid, GridColumn, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Button } from '../../../../common/baseElements/Button';
import { useAuditModalContext } from '../../../../../hooks/useAuditModalContext';
import localeAuditTask from '../../../../../utils/i18n/taskLocale/auditTask.json';
import { collectpkgDataModal } from '../../../../../utils/helpers/audit-helpers';
import { usePkgModal } from '../../../../../hooks/components/usePkgModal';
import { ScoreCvssCell } from '../../../../common/cells/ScoreCvssCell';
import { CellId } from './cells/CellVulnerabilitiesListId';
import { CellVulnListAlias } from './cells/CellVulnListAlias';
import { CellDescription } from '../../../Security/cells/CellDescription';
import { CellCwe } from './cells/CellVulnerabilitiesListCwe';
import { CellCvssVector } from './cells/CellCvssVector';
import { CellPublished } from './cells/CellVulnerabilitiesListPublished';
import {
  cvssScoreWidth,
  FILTER_INITIAL,
  PAGEABLE_DATA_LOCAL,
} from '../../../../../utils/helpers/constants';
import CellSelectVuln from './cells/CellSelectVuln';
import { useAssetCreatePropContext } from '../../../../../hooks/useAssetCreatePropContext';
import styles from './packageNameModal.module.scss';
import { useWhiteListContext } from '../../../../../hooks/useWhiteListContext';
import { CommonNotificationWl } from '../../../../common/CommonNotificationWl';
import { useAssetActions } from '../../../../../hooks/components/useAssetActions';
import type { TVulnOnPage } from '../../../../../utils/helpers/types';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

function PackageNameModal(): React.ReactElement {
  const [referenceId, setReferenceId] = useState<string[]>(['']);

  const currentLength = useRef<number | null>(null);

  const pageLimitGrid: { [key: string]: string } =
    localStorage.getItem('pageLimitGrid') &&
    JSON.parse(localStorage.getItem('pageLimitGrid') || '');

  const initialDataState: State = {
    sort: [{ field: 'maxScore', dir: 'desc' }],
    take:
      pageLimitGrid && pageLimitGrid.VulnerabilitiesList
        ? Number(pageLimitGrid.VulnerabilitiesList)
        : 15,
    skip: 0,
    filter: FILTER_INITIAL,
  };

  const [dataState, setDataState] = useState<State>(initialDataState);

  const { auditData, pkgName } = useAuditModalContext();

  const data = collectpkgDataModal(auditData, pkgName);

  const [cutValue, setCutValue] = useState(
    data.pkgVulns && data.pkgVulns.length < 10 ? data.pkgVulns.length : 10,
  );

  const [allVulnOnPageData, setAllVulnOnPageData] = useState<TVulnOnPage[]>([]);

  const { selectedVulns, setSelectedVulns, setAddVulnToWLForm } = useAssetCreatePropContext();

  const { onSubmitVulnSuccess, setOnSubmitVulnSuccess } = useWhiteListContext();

  useEffect(() => {
    return () => setOnSubmitVulnSuccess(false);
  }, []);

  if (data.pkgVulns && data.pkgVulns.length) currentLength.current = data.pkgVulns.length;

  const { onScroll, closePkgModal } = usePkgModal(
    data,
    currentLength,
    setCutValue,
    cutValue,
    referenceId,
    setReferenceId,
  );

  const { selectAllVulnOnPage } = useAssetActions();

  useEffect(() => {
    if (dataState && data.pkgVulns) {
      const slicedvulnOnPageData = data.pkgVulns
        .sort(function (a, b) {
          return Number(b.maxScore) - Number(a.maxScore);
        })
        .slice(dataState.skip, (dataState.skip || 0) + (dataState.take || 0))
        .map((vuln) => ({ bulletinId: vuln.id }));

      setAllVulnOnPageData(slicedvulnOnPageData);
    }
  }, [dataState.skip, dataState.take]);

  useEffect(() => {
    const kDialogContent = document.getElementsByClassName('k-dialog-content');
    kDialogContent[0].addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  return (
    <Dialog
      title={`${
        auditData?.type === 'windows'
          ? localeAuditTask[currentLocale].vulnsSoft.packageNameModal.titleWin
          : localeAuditTask[currentLocale].vulnsSoft.packageNameModal.title
      } "${pkgName?.detectedName ? pkgName?.detectedName : pkgName?.name} ${pkgName?.version}"`}
      onClose={closePkgModal}
      className="package-name-modal modal"
    >
      <div className="k-form">
        {onSubmitVulnSuccess && <CommonNotificationWl />}
        <div className={`${styles.packageModalInfo} form-content taskaudit__content`}>
          <div className="common-summary-page">
            <table className="column">
              <tr>
                <td>{localeAuditTask[currentLocale].vulnsSoft.packageNameModal.vulnerabilities}</td>
                <td>{data.vulns}</td>
              </tr>
              <tr>
                <td>{localeAuditTask[currentLocale].vulnsSoft.packageNameModal.updates}</td>
                <td>{data.updates ? <span>{data.updates}</span> : <span>-</span>}</td>
              </tr>
              <tr>
                <td>{localeAuditTask[currentLocale].vulnsSoft.packageNameModal.upgradeTo}</td>
                <td>
                  {pkgName?.updateVersion ? <span>{pkgName?.updateVersion}</span> : <span>-</span>}
                </td>
              </tr>
            </table>
            <table className="column">
              <tr>
                <td>{localeAuditTask[currentLocale].vulnsSoft.packageNameModal.os}</td>
                <td style={{ textTransform: 'capitalize' }}>
                  {auditData?.payload.os.name
                    ? `${auditData?.payload.os.name} ${auditData?.payload.os.version || ''}`
                    : '-'}
                </td>
              </tr>
            </table>
          </div>
          <div className={styles.pkgUpdate}>
            <Button
              onClick={(): void => selectAllVulnOnPage(allVulnOnPageData)}
              className="selected-pkgs button-mr20"
            >
              {localeAuditTask[currentLocale].vulnsSoft.selectAllVulnerabilities}
            </Button>
            {selectedVulns && selectedVulns.length > 0 && (
              <Button
                onClick={(): void => setAddVulnToWLForm(true)}
                className="selected-pkgs action button-mr20"
              >
                {localeAuditTask[currentLocale].vulnsSoft.excludeSelectedVulnerabilities} (
                {selectedVulns.length})
              </Button>
            )}
            {selectedVulns && selectedVulns.length > 0 && (
              <Button onClick={(): void => setSelectedVulns(null)} className="cancel-selected-pkgs">
                {localeAuditTask[currentLocale].cancelSelectedPackages}
              </Button>
            )}
          </div>
          <LocalizationProvider language={currentLocale}>
            <IntlProvider locale={currentLocale.substring(0, 2)}>
              <Grid
                pageable={data.pkgVulns && data.pkgVulns.length > 10 ? PAGEABLE_DATA_LOCAL : false}
                sortable
                data={process(data.pkgVulns, dataState)}
                {...dataState}
                onDataStateChange={(e: GridDataStateChangeEvent): void => {
                  setDataState(e.dataState);
                  if (pageLimitGrid) {
                    const pageLimit = {
                      ...pageLimitGrid,
                      VulnerabilitiesList: String(e.dataState.take),
                    };
                    localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
                  } else {
                    const pageLimit = {
                      VulnerabilitiesList: String(e.dataState.take),
                    };
                    localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
                  }
                }}
                scrollable="none"
              >
                <GridColumn
                  field="addVuln"
                  title={localeAuditTask[currentLocale].vulnsSoft.table.selectPackage}
                  cell={CellSelectVuln}
                  width="80px"
                  sortable={false}
                />
                <GridColumn
                  field="maxScore"
                  title={localeAuditTask[currentLocale].vulnsSoft.table.cvssScore}
                  cell={ScoreCvssCell}
                  width={cvssScoreWidth.sort}
                />
                <GridColumn
                  field="id"
                  title={localeAuditTask[currentLocale].vulnsList.table.id}
                  cell={CellId}
                  width="164px"
                />
                <GridColumn
                  field="aliases"
                  title={localeAuditTask[currentLocale].vulnsList.table.aliases}
                  cell={CellVulnListAlias}
                  width="164px"
                />
                <GridColumn
                  field="description"
                  title={localeAuditTask[currentLocale].vulnsList.table.description}
                  cell={CellDescription}
                />
                <GridColumn
                  field="cwe"
                  title={localeAuditTask[currentLocale].vulnsList.table.cwe}
                  cell={CellCwe}
                  width="160px"
                />
                <GridColumn
                  field="vector"
                  title={localeAuditTask[currentLocale].vulnsList.table.cvssVector}
                  cell={CellCvssVector}
                  width="320px"
                />
                <GridColumn
                  field="date.published"
                  title={localeAuditTask[currentLocale].vulnsList.table.published}
                  cell={CellPublished}
                  width="148px"
                />
              </Grid>
            </IntlProvider>
          </LocalizationProvider>
        </div>
        <div
          className={
            data.pkgVulns && data.pkgVulns.length > 10
              ? 'k-form-buttons'
              : 'k-form-buttons button-mt32'
          }
        >
          <Button type="button" onClick={closePkgModal}>
            {localeAuditTask[currentLocale].close}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
export default memo(PackageNameModal);
