import type { GridCellProps } from '@progress/kendo-react-grid';
import type { ISoftwareList } from '../SoftwareListWin';

export function CellName(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { name }: ISoftwareList = dataItem;

  return (
    <td title={name}>
      <span>{name}</span>
    </td>
  );
}
