/* eslint-disable react/jsx-pascal-case */
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Dialog } from '@progress/kendo-react-dialogs';
import { useEffect } from 'react';
import { IGroupContext, useGroupContext } from '../../../../hooks/useGroupContext';
import localeHostGroups from '../../../../utils/i18n/assetLocales/assetGroups.json';
import { useGroupHook } from '../../../../hooks/components/useGroupHook';
import { useCheck } from '../../../../hooks/components/useCheck';
import { Button } from '../../../common/baseElements/Button';
import { FormInput } from '../../../common/form/FormInput';
import { FormTextArea } from '../../../common/form/FormTextArea';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHostGroups;

export function EditGroupForm(): React.ReactElement {
  const {
    hostId,
    isEdit,
    groupData,
    groupName,
    setGroupName,
    groupDescription,
    setGroupDescription,
    errorMutation,
    setErrorMutation,
  }: IGroupContext = useGroupContext();

  useEffect(() => {
    return () => setErrorMutation('');
  }, []);

  const { checkLength } = useCheck();

  const { handleSubmit, handleWindow } = useGroupHook(
    groupData?.name,
    groupData?.description,
    hostId,
  );

  return (
    <Dialog
      title={
        isEdit
          ? `${localeHostGroups[currentLocale].modal.editTitle} "${groupData?.name}"`
          : localeHostGroups[currentLocale].modal.addTitle
      }
      onClose={handleWindow}
      className="add-edit-group modal small"
    >
      <Form
        // initialValues={initialFields}
        onSubmit={handleSubmit}
        render={(): React.ReactElement => (
          <FormElement>
            <div className="form-content">
              <Field
                name="name"
                component={FormInput}
                id="name"
                label={localeHostGroups[currentLocale].form.groupName}
                onChange={(e): void => {
                  setGroupName(e.target.value);
                }}
                inputValue={groupName}
                className="form-field-wrapper"
                customValidator={checkLength}
                type="text"
                classForLabel="required-label"
              />
              <Field
                name="description"
                maxLength={255}
                component={FormTextArea}
                id="description"
                label={localeHostGroups[currentLocale].form.description}
                onChange={(e): void => {
                  setGroupDescription(e.value);
                }}
                inputValue={groupDescription}
              />
            </div>
            <div className="error-common-field">
              <span>{errorMutation}</span>
            </div>
            <div className="k-form-buttons">
              <Button type="submit" disabled={!groupName}>
                {localeHostGroups[currentLocale].modal.btnSave}
              </Button>
              <Button type="button" onClick={handleWindow}>
                {localeHostGroups[currentLocale].modal.btnCancel}
              </Button>
            </div>
          </FormElement>
        )}
      />
    </Dialog>
  );
}
