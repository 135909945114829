/* eslint-disable react/jsx-props-no-spreading */
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import {
  State,
  process as gridProcess,
  CompositeFilterDescriptor,
} from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { useRef, useState } from 'react';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { FILTER_INITIAL, PAGEABLE_DATA_LOCAL } from '../../../../utils/helpers/constants';
import localeAuditTask from '../../../../utils/i18n/taskLocale/auditTask.json';
import tablelocale from '../../../../utils/i18n/commonLocales/table.json';
import { useLocalExcelExport } from '../../../../hooks/components/useLocalExcelExport';
import type { ICreateKbsData } from '../../../../utils/helpers/task-helpers';
import { RebootBehaviorCell } from './cell/RebootBehaviorCell';
import { TaskKbFastFilter } from './TaskKbFastFilter';
import { Button } from '../../../common/baseElements/Button';
import styles from './TaskKbInstall.module.scss';
import { StatusCell } from './cell/StatusCell';
import { KbIdCell } from '../../../common/KbIdCell';
import { useAuditModalContext } from '../../../../hooks/useAuditModalContext';
import { MissingKbModal } from '../taskVmDetails/vmDetailsComponents/MissingKbModal';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

export function TaskKbResult({
  kbsData,
  title,
}: {
  kbsData: ICreateKbsData[];
  title: string;
}): React.ReactElement {
  const pageLimitGrid: { [key: string]: string } =
    localStorage.getItem('pageLimitGrid') &&
    JSON.parse(localStorage.getItem('pageLimitGrid') || '');

  const initialDataState: State = {
    sort: [{ field: 'maxScore', dir: 'desc' }],
    take: pageLimitGrid && pageLimitGrid.MissingKBs ? Number(pageLimitGrid.MissingKBs) : 15,
    skip: 0,
    filter: FILTER_INITIAL,
  };

  const [dataState, setDataState] = useState<State>(initialDataState);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _grid = useRef<React.LegacyRef<Grid> | undefined>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _export = useRef<ExcelExport | null>(null);

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  const excelExport = useLocalExcelExport(dataState, _export, kbsData, setDataState);

  const { idKb } = useAuditModalContext();

  return (
    <div className={styles.resultKbInstall}>
      {kbsData.length > 0 ? (
        <>
          <div className={styles.filterWrap}>
            <TaskKbFastFilter
              dataState={dataState}
              setDataState={setDataState}
              kbsData={kbsData}
              filterValue={filterValue}
            />
            <Button fill="flat" onClick={excelExport} className={styles.exportToExcel}>
              {localeAuditTask[currentLocale].vulnsSoft.table.exportToExcel}
            </Button>
          </div>
          <LocalizationProvider language={currentLocale}>
            <IntlProvider locale={currentLocale.substring(0, 2)}>
              <ExcelExport data={kbsData} ref={_export} fileName={title.length > 1 ? title : '__'}>
                <Grid
                  pageable={kbsData.length > 10 ? PAGEABLE_DATA_LOCAL : false}
                  sortable
                  data={gridProcess(kbsData, dataState)}
                  {...dataState}
                  onDataStateChange={(e: GridDataStateChangeEvent): void => {
                    setDataState(e.dataState);
                    if (pageLimitGrid) {
                      const pageLimit = {
                        ...pageLimitGrid,
                        MissingKBs: String(e.dataState.take),
                      };
                      localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
                    } else {
                      const pageLimit = {
                        MissingKBs: String(e.dataState.take),
                      };
                      localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
                    }
                  }}
                  // className="taskaudit__vulnsgrid"
                  scrollable="none"
                  ref={_grid.current}
                >
                  <Column
                    field="id"
                    title={localeAuditTask[currentLocale].isMissedKb.id}
                    cell={KbIdCell}
                  />
                  <Column
                    field="rebootBehavior"
                    cell={RebootBehaviorCell}
                    title={localeAuditTask[currentLocale].rebootBehavior}
                  />
                  <Column
                    field="rebootBehavior"
                    cell={StatusCell}
                    title={localeAuditTask[currentLocale].status}
                  />
                </Grid>
              </ExcelExport>
            </IntlProvider>
          </LocalizationProvider>
        </>
      ) : (
        <div className={styles.resultEmpty}>
          <span>{tablelocale[currentLocale].grid.noKb}</span>
        </div>
      )}
      {idKb && <MissingKbModal />}
    </div>
  );
}
