/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import type { GridHeaderCellProps } from '@progress/kendo-react-grid';
import styles from '../../Task/TaskList.module.scss';
import { UserRole } from '../../Task/common/UserRole';

export function RoleHeaderCell(props: GridHeaderCellProps): React.ReactElement {
  const { title, onClick, children } = props;

  return (
    <div onClick={onClick} className={`${styles.action_header_cell} k-cell-inner`}>
      {title}
      <span className={styles.types_info}>
        <div className={`${styles.types_info_content} k-link`}>
          <div className={styles.type_info}>
            <UserRole role="super_admin" />
            super_admin
          </div>
          <div className={styles.type_info}>
            <UserRole role="account_admin" />
            account_admin
          </div>
          <div className={styles.type_info}>
            <UserRole role="user" />
            user
          </div>
          <div className={styles.type_info}>
            <UserRole role="reader_user" />
            reader_user
          </div>
        </div>
        {children}
        <img
          src={`${process.env.PUBLIC_URL}/images/question.svg`}
          alt=""
          className="tooltip-question-images"
        />
      </span>
    </div>
  );
}
