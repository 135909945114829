/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import type { GridHeaderCellProps } from '@progress/kendo-react-grid';
import styles from './VHeaderCell.module.scss';
import type localeHostDescription from '../../utils/i18n/assetLocales/hostDescription.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHostDescription;

const info = {
  'ru-RU': { text: 'Критичность уязвимости по методике ФСТЭК' },
  'en-EN': { text: 'Vulnerability severity by FSTEC methodology' },
};
export function VHeaderCell(props: GridHeaderCellProps): React.ReactElement {
  const { title, onClick, children } = props;

  return (
    <div onClick={onClick} className={`${styles.action_header_cell} vhead-cell k-cell-inner`}>
      {title}
      <span className={`${styles.types_info}`}>
        <div className={`${styles.types_info_content} k-link`}>
          <div className={styles.type_info}>{info[currentLocale].text}</div>
          <table className={styles.info_table}>
            <tr>
              <td className={styles.score_cell}>
                <span className={`${styles.v_block} ${styles.low}`}>low</span>
              </td>
              <td>{'0 ≤ V < 1.5'}</td>
            </tr>
            <tr>
              <td>
                <span className={`${styles.v_block} ${styles.medium}`}>medium</span>
              </td>
              <td>{'1.5 ≤ V < 4.5'}</td>
            </tr>
            <tr>
              <td>
                <span className={`${styles.v_block} ${styles.high}`}>high</span>
              </td>
              <td>{'4.5 ≤ V < 7'}</td>
            </tr>
            <tr>
              <td>
                <span className={`${styles.v_block} ${styles.critical}`}>critical</span>
              </td>
              <td>7 ≤ V ≤ 10</td>
            </tr>
          </table>
        </div>
        {children}
        <img
          src={`${process.env.PUBLIC_URL}/images/question.svg`}
          alt=""
          className="tooltip-question-images"
        />
      </span>
    </div>
  );
}
