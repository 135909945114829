/* eslint-disable react/jsx-pascal-case */
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import { Typography } from '@progress/kendo-react-common';
import { Filter } from '@progress/kendo-react-data-tools';
import { ErrorBoundary } from 'react-error-boundary';
import localeCommonTable from '../../../utils/i18n/commonLocales/table.json';
import localeDevices from '../../../utils/i18n/devices/devices.json';
import { BoundaryErrorComponent } from '../../common/BoundaryErrorComponent';
import { Button } from '../../common/baseElements/Button';
import { getUrlList } from '../../../utils/helpers/getUrl-list-helper';
import type { IDataHooks } from '../../../utils/helpers/types';
import { useNetworkDeviceList } from '../../../hooks/components/useNetworkDeviceList';
import { CommonNetworkDeviceList } from '../../common/CommonNetworkDeviceList';
import { СommonTableLoader } from '../../common/tableLoader/CommonTableLoader';
import { useNetDeviceContext } from '../../../hooks/useNetDeviceContext';
import {
  FILTER_DEVICELIST,
  FILTER_DEVICELIST_NOT_SUPER_ADMIN,
} from '../../../utils/helpers/constant-serv-filter-host';
import { INITIAL_DEVICE_LIST } from '../../../utils/helpers/constants';
import { Input } from '../../common/baseElements/Input';
import { getUserData } from '../../../services/local-storage-service';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeDevices;

loadMessages(localeCommonTable[currentLocale], currentLocale);

export function DeviceList(): React.ReactElement {
  const currentUser = getUserData();

  const urlParams = getUrlList('DeviceList', INITIAL_DEVICE_LIST);

  const dataForHooks: IDataHooks = {
    name: 'latestInventorization.ip',
    componentName: 'DeviceList',
  };

  const {
    handleRefresh,
    filter,
    onFilterChange,
    sort,
    page,
    total,
    pageChange,
    setSort,
    isLoading,
    isFetching,
    filterStatus,
    isError,
    errorMessage,
    data,
    handleCustomSearch,
    customSearch,
  } = useNetworkDeviceList(urlParams, dataForHooks);

  const { setAddNetDevice } = useNetDeviceContext();

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      <div className="devices">
        <div className="common-header-page">
          <Typography.h3>{localeDevices[currentLocale].diviceTitle}</Typography.h3>
          <div className="topactions">
            {currentUser?.role !== 'reader_user' && (
              <Button
                fill="action"
                onClick={(): void => {
                  setAddNetDevice(true);
                }}
              >
                {localeDevices[currentLocale].addDevicebtn}
              </Button>
            )}
            <Button onClick={handleRefresh}>{localeDevices[currentLocale].btnRefresh}</Button>
          </div>
        </div>
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <div className="filter-line">
              <Filter
                value={filter}
                onChange={onFilterChange}
                fields={
                  currentUser?.role === 'super_admin'
                    ? FILTER_DEVICELIST[currentLocale]
                    : FILTER_DEVICELIST_NOT_SUPER_ADMIN[currentLocale]
                }
              />
              {filter.filters.length === 0 && (
                <Input
                  name="customSearch"
                  placeholder={localeDevices[currentLocale].grid.customSearchIp}
                  value={customSearch}
                  onChange={(e): void => handleCustomSearch(e)}
                />
              )}
            </div>
          </IntlProvider>
        </LocalizationProvider>
        <CommonNetworkDeviceList
          sort={sort}
          data={data}
          page={page}
          total={total}
          pageChange={pageChange}
          setSort={setSort}
          dataForHooks={dataForHooks}
          conditionSort={15}
        />
        <СommonTableLoader queryData={filterStatus} filteringData={filterStatus} />{' '}
        {isError && <div className="info alert alert-danger">Error: {errorMessage}</div>}
      </div>
    </ErrorBoundary>
  );
}
