import { Grid, GridColumn, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useMemo, useState } from 'react';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { LAZY_PAGEABLE_DATA } from '../../../../../utils/helpers/constants';
import { WrapperMenu } from '../../../Task/taskIDetails/iDetailsComponents/WrapperMenu';
import {
  createFromListInvData,
  IDiffInvElementInfo,
  type DiffFilterTypes,
  defineWrapperStatuses,
} from '../../../../../utils/helpers/difference-modal-helper';
import type {
  InventorizationDifferenceLinux,
  InventorizationDifferenceWindows,
} from '../../../../../types/__generated/on-premise-solution/api/inventorizationDifference.v1';
import { DiffStandartCell } from '../cells/DiffStandartCell';
import { DiffTypeCell } from '../cells/DiffTypeCell';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function DiffGroups({
  data,
  filter,
}: {
  data: InventorizationDifferenceLinux | InventorizationDifferenceWindows;
  filter: DiffFilterTypes;
}): React.ReactElement {
  const [page, setPage] = useState({ take: 5, skip: 0 });

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  const groupsData = useMemo(
    () => createFromListInvData(data.groups as unknown as IDiffInvElementInfo[], filter),
    [data.groups, filter],
  );

  if (!groupsData || !groupsData.length) return <></>;

  return (
    <WrapperMenu
      title={locale[currentLocale].groups.title}
      diffStatuses={filter === 'all' ? defineWrapperStatuses(data.groups) : undefined}
    >
      <Grid
        data={groupsData.slice(page.skip, page.take + page.skip)}
        pageable={LAZY_PAGEABLE_DATA}
        scrollable="none"
        total={groupsData.length}
        pageSize={page.take}
        skip={page.skip}
        onPageChange={pageChange}
        className="no-margin-top"
      >
        <GridColumn
          field="diffType"
          cell={DiffTypeCell}
          title=" "
          width={20}
          sortable={false}
          headerClassName="no-cursor"
        />
        <GridColumn
          field="groupname"
          title={locale[currentLocale].groups.groupname}
          cell={DiffStandartCell}
          sortable={false}
          headerClassName="no-cursor"
        />
        <GridColumn
          field="gid"
          title={locale[currentLocale].groups.gid}
          cell={DiffStandartCell}
          sortable={false}
          headerClassName="no-cursor"
        />
        {data.type === 'windows' && (
          <GridColumn
            field="description"
            title={locale[currentLocale].groups.description}
            cell={DiffStandartCell}
            sortable={false}
            headerClassName="no-cursor"
          />
        )}
        {data.type === 'windows' && (
          <GridColumn
            field="domain"
            title={locale[currentLocale].groups.domain}
            cell={DiffStandartCell}
            sortable={false}
            headerClassName="no-cursor"
          />
        )}
      </Grid>
    </WrapperMenu>
  );
}
