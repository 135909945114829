import type { GridCellProps } from '@progress/kendo-react-grid';
import type { ISecurityVulns } from '../../../../utils/helpers/types';

export function CellSecurityTitle(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { title }: ISecurityVulns = dataItem;

  return (
    <td title={title || ''}>
      <span>{title === '-' ? <span>-</span> : <span>{title}</span>}</span>
    </td>
  );
}
