/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-no-useless-fragment */
import { Button } from '@progress/kendo-react-buttons';
import { NavLink } from 'react-router-dom';
import { useSystemInfoContext } from '../../hooks/useSystemInfoContext';
import localeLicense from '../../utils/i18n/settingsLocales/settings.json';
import styles from '../features/Settings/Settings.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeLicense;
export function CommonErrorLicense(): React.ReactElement {
  const { licenseAnswer, classValues, closeLicenseBlock } = useSystemInfoContext();

  return (
    <>
      {licenseAnswer &&
        (licenseAnswer.license.status.isExpired.status !== false ||
          (licenseAnswer.license.status.isExpired.status === false &&
            licenseAnswer.license.status.isExpired.daysUntilExpiration &&
            licenseAnswer.license.status.isExpired.daysUntilExpiration <= 30 &&
            licenseAnswer.license.status.isExpired.daysUntilExpiration > 7 &&
            !localStorage.getItem('licInfo')) ||
          licenseAnswer.license.status.isAssetQuantityExceeded.status !== false ||
          (licenseAnswer.license.status.isExpired.status === false &&
            licenseAnswer.license.status.isExpired.daysUntilExpiration < 7) ||
          licenseAnswer?.license.status.isExpired.status !== false) && (
          <div className={`${classValues.join(' ')} ${styles.check_license}`}>
            <div>
              {(licenseAnswer.license.status.isExpired.status !== false ||
                (licenseAnswer.license.status.isExpired.status === false &&
                  licenseAnswer.license.status.isExpired.daysUntilExpiration <= 30 &&
                  licenseAnswer.license.status.isExpired.daysUntilExpiration > 7 &&
                  !localStorage.getItem('licInfo'))) && (
                <>
                  <span>{localeLicense[currentLocale].license.licExpired}: </span>
                  <span>
                    {new Date(licenseAnswer.license.status.isExpired.expiresAt).toLocaleString()}.
                  </span>
                </>
              )}
              {licenseAnswer.license.status.isExpired.status === false &&
                licenseAnswer.license.status.isExpired.daysUntilExpiration < 7 && (
                  <>
                    <span>{localeLicense[currentLocale].license.licExpired}: </span>
                    <span>
                      {new Date(licenseAnswer.license.status.isExpired.expiresAt).toLocaleString()}.
                    </span>
                  </>
                )}
              {licenseAnswer.license.status.isAssetQuantityExceeded.status === true &&
                licenseAnswer.license.status.isAssetQuantityExceeded.assetsQuantityConstraint &&
                licenseAnswer.license.status.isAssetQuantityExceeded.currentAssetsQuantity &&
                licenseAnswer.license.status.isAssetQuantityExceeded.assetsQuantityConstraint <=
                  licenseAnswer.license.status.isAssetQuantityExceeded.currentAssetsQuantity && (
                  <>
                    <span>{localeLicense[currentLocale].license.isAssetQuantityExceeded}.</span>
                    <span>{localeLicense[currentLocale].license.currentAssetsQuantity}:</span>
                    <span>
                      {licenseAnswer.license.status.isAssetQuantityExceeded.currentAssetsQuantity}.
                    </span>
                    <span>{localeLicense[currentLocale].license.assetsQuantityConstraint}:</span>
                    <span>
                      {
                        licenseAnswer.license.status.isAssetQuantityExceeded
                          .assetsQuantityConstraint
                      }
                      .
                    </span>
                  </>
                )}
              {licenseAnswer?.license.status.isProductNotMatched.status !== false && (
                <span>{localeLicense[currentLocale].license.isProductNotMatched}.</span>
              )}{' '}
              <NavLink to="/lk/settings/license" className="link-about-lic">
                {localeLicense[currentLocale].license.aboutLicense}
              </NavLink>
            </div>
            {licenseAnswer.license.status.isExpired.status === false &&
              licenseAnswer.license.status.isExpired.daysUntilExpiration <= 30 &&
              licenseAnswer.license.status.isExpired.daysUntilExpiration > 0 &&
              licenseAnswer.license.status.isAssetQuantityExceeded.status !== true &&
              licenseAnswer.license.status.isProductNotMatched.status !== true && (
                <Button icon="close" fillMode="flat" onClick={closeLicenseBlock} />
              )}{' '}
          </div>
        )}
    </>
  );
}
