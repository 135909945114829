/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@progress/kendo-react-common';
import { Button } from '@progress/kendo-react-buttons';
import { useQuery } from 'react-query';
import { Loader } from '@progress/kendo-react-indicators';
import { ErrorBoundary } from 'react-error-boundary';
import { Menu, MenuItem } from '@progress/kendo-react-layout';
import localeVmTask from '../../../../utils/i18n/taskLocale/vmTask.json';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { handleError } from '../../../../utils/errors';
import { useNotificationContext } from '../../../../hooks/useNotificationContext';
import { useTaskContext } from '../../../../hooks/useTaskContext';
import { BoundaryErrorComponent } from '../../../common/BoundaryErrorComponent';
import { TaskAddEdit } from '../form/AddTask';
import { useConvertToJson } from '../../../../hooks/components/useConvertToJson';
import { useRepeatTask } from '../../../../hooks/components/useRepeatTask';
import { getTaskActionById, getTaskExecutionInfo } from '../../../../services/task-service';
import type { EnrichedTaskActionResponse } from '../../../../types/__generated/on-premise-solution/api/enrichedTaskActionResponse.v1';
import { MetaBlock } from '../../../common/MetaBlock';
import { createKbsData } from '../../../../utils/helpers/task-helpers';
import { TaskTabsKb } from './TaskTabsKb';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeVmTask;

export function TaskKbDetails(): React.ReactElement {
  const hostName = useRef<string | false | undefined>();

  const { taskActionId } = useParams<{ taskActionId: string }>();

  const { isOpenDialog } = useTaskContext();

  const { createNotification } = useNotificationContext();

  const { repeatTaskDescription } = useRepeatTask();

  const query = useQuery<EnrichedTaskActionResponse, ApiError>(
    ['taskActionById', taskActionId],
    () => getTaskActionById(taskActionId),
    {
      keepPreviousData: true,
      onSuccess(data) {
        hostName.current =
          (data.assets &&
            data.assets[0].type === 'host' &&
            data.assets[0].latestInventorization?.hostname) ||
          '';
      },
    },
  );

  const repeatTask = async (): Promise<void> => {
    const result = await getTaskExecutionInfo(query.data?.taskExecutionId);
    repeatTaskDescription(result, undefined);
  };

  const handle2Json = useConvertToJson(query.data, hostName.current, 'pkgUpdate');

  const title = hostName.current
    ? `${localeVmTask[currentLocale].kbinstalled} ${hostName.current}`
    : '';

  const kbsData = createKbsData(query.data);

  useEffect(() => {
    if (query.error) {
      handleError(query.error, createNotification);
    }
  }, [createNotification, query.error]);

  return (
    <div>
      {isOpenDialog && <TaskAddEdit />}
      {query.status === 'success' ? (
        <div className="taskdescription">
          <div className="common-header-page">
            <Typography.h3 className="title">
              <span className="audit-taskname">{title}</span>
            </Typography.h3>
            <div className="topactions">
              <Button onClick={repeatTask} disabled={query.data?.status === 'processing'}>
                {localeVmTask[currentLocale].btnRepeat}
              </Button>
              <div className="json-actions">
                <Menu hoverOpenDelay={0} onSelect={handle2Json}>
                  <MenuItem text={localeVmTask[currentLocale].techDetails}>
                    <MenuItem text={localeVmTask[currentLocale].assetData} />
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
          <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
            <MetaBlock taskAction={query.data} />
            <TaskTabsKb kbsData={kbsData} title={title} taskActionId={taskActionId} />
          </ErrorBoundary>
        </div>
      ) : (
        <Loader
          style={{ color: 'black', marginLeft: '50%' }}
          size="large"
          type="converging-spinner"
        />
      )}
    </div>
  );
}
