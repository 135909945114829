import { Grid, GridColumn as Column, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { SortDescriptor, process as gridProcess, orderBy } from '@progress/kendo-data-query';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { DiffScoreCvssCell } from '../cells/DiffScoreCvssCell';
import { DiffScoreVCell } from '../cells/DiffScoreVCell';
import type { AuditDifferenceResponse } from '../../../../../types/__generated/on-premise-solution/api/auditDifferenceResponse.v1';
import { DiffCellId } from '../cells/DiffCellId';
import { PAGEABLE_DATA } from '../../../../../utils/helpers/constants';
import localeAuditTask from '../../../../../utils/i18n/taskLocale/auditTask.json';
import { VHeaderCell } from '../../../../common/VHeaderCell';
import { DiffCellVulnListAlias } from '../cells/DiffCellVulnListAlias';
import { DiffStandartCell } from '../cells/DiffStandartCell';
import { DiffTypeCell } from '../cells/DiffTypeCell';
import { DiffDateCell } from '../cells/DiffDateCell';
import { DiffCvssVectorCell } from '../cells/DiffCvssVectorCell';
import { Input } from '../../../../common/baseElements/Input';
import { Button } from '../../../../common/baseElements/Button';
import localeVmTask from '../../../../../utils/i18n/taskLocale/vmTask.json';
import hostDescriptionLocale from '../../../../../utils/i18n/assetLocales/hostDescription.json';
import { DiffFilters } from '../DiffFilters';
import { createDiffVulnData } from '../../../../../utils/helpers/difference-modal-helper';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

export function DiffVulnerabilitiesList({ data }: { data: AuditDifferenceResponse }) {
  const _export = useRef<ExcelExport | null>(null);
  const sort: SortDescriptor[] = [{ field: 'id', dir: 'asc' }];
  const [page, setPage] = useState({ take: 10, skip: 0 });
  const [searchValue, setSearchValue] = useState('');
  const [filter, setFilter] = useState<
    'all' | 'noChanges' | 'updated' | 'changed' | 'installed' | 'removed'
  >('all');
  const vulnsData = useMemo(() => createDiffVulnData(data), [data]);
  const [vulnsList, setVulnsList] = useState(vulnsData[filter]);

  const filterList = {
    all: `${hostDescriptionLocale[currentLocale].changes.shortFilters.vulns.all} (${vulnsData.all.length})`,
    noChanges: `${hostDescriptionLocale[currentLocale].changes.shortFilters.vulns.noChanges} (${vulnsData.noChanges.length})`,
    // updated: `${hostDescriptionLocale[currentLocale].changes.shortFilters.vulns.updated} (${vulnsData.updated.length})`,
    installed: `${hostDescriptionLocale[currentLocale].changes.shortFilters.vulns.new} (${vulnsData.installed.length})`,
    removed: `${hostDescriptionLocale[currentLocale].changes.shortFilters.vulns.closed} (${vulnsData.removed.length})`,
    // changed: `${hostDescriptionLocale[currentLocale].changes.shortFilters.vulns.changed} (${vulnsData.changed.length})`,
  };

  useEffect(() => {
    setVulnsList(
      vulnsData[filter].filter((vuln) => {
        if (!vuln.id) return false;

        return vuln.id.toLowerCase().includes(searchValue.toLowerCase());
      }),
    );
    setPage((prev) => ({ ...prev, skip: 0 }));
  }, [filter, searchValue, data]);

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save(vulnsData.all);
    }
  };

  return (
    <>
      <div className="filter-line">
        <Input
          name="customSearch"
          placeholder={localeAuditTask[currentLocale].customFilterName}
          onChange={handleSearch}
          value={searchValue}
        />
        <Button excel fill="flat" onClick={excelExport}>
          {localeAuditTask[currentLocale].vulnsSoft.table.exportToExcel}
        </Button>
      </div>
      <DiffFilters currentFilter={filter} setFilter={setFilter} filterList={filterList} />
      <ExcelExport ref={_export} fileName={localeVmTask[currentLocale].tabs.vulnerabilities}>
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <Grid
              sortable={false}
              data={orderBy(vulnsList, sort).slice(page.skip, page.take + page.skip)}
              // sort={sort}
              total={vulnsList.length}
              pageable={PAGEABLE_DATA}
              skip={page.skip}
              take={page.take}
              onPageChange={pageChange}
              className="no-margin-top diffaudit__vulnsgrid"
              scrollable="none"
            >
              <Column
                field="diffType"
                cell={DiffTypeCell}
                title=" "
                width={20}
                sortable={false}
                headerClassName="no-cursor"
              />
              <Column
                field="maxScore"
                title={localeAuditTask[currentLocale].vulnsSoft.table.cvssScore}
                cell={DiffScoreCvssCell}
                width="60px"
                sortable={false}
                headerClassName="no-cursor"
              />
              <Column
                field="VFstec"
                title="v"
                cell={DiffScoreVCell}
                width="60px"
                headerCell={VHeaderCell}
                sortable={false}
                headerClassName="no-cursor"
              />
              <Column
                field="id"
                title={localeAuditTask[currentLocale].vulnsList.table.id}
                cell={DiffCellId}
                width="152px"
                sortable={false}
                headerClassName="no-cursor"
              />
              <Column
                field="aliases"
                title={localeAuditTask[currentLocale].vulnsList.table.aliases}
                cell={DiffCellVulnListAlias}
                // width={vulnsList.find((v) => v.aliases)
                //     ? '160px'
                //     : '94px'}
                width={160}
                sortable={false}
                headerClassName="no-cursor"
              />
              <Column
                field="description"
                title={localeAuditTask[currentLocale].vulnsList.table.description}
                cell={DiffStandartCell}
                // width="260px"
                className="title"
                sortable={false}
                headerClassName="no-cursor"
              />
              <Column
                field="cwe"
                title={localeAuditTask[currentLocale].vulnsList.table.cwe}
                cell={DiffStandartCell}
                width="160px"
                className="title"
                sortable={false}
                headerClassName="no-cursor"
              />
              <Column
                field="vector"
                title={localeAuditTask[currentLocale].vulnsList.table.cvssVector}
                cell={DiffCvssVectorCell}
                width="260px"
                sortable={false}
                headerClassName="no-cursor"
              />
              <Column
                field="date.published"
                title={localeAuditTask[currentLocale].vulnsList.table.published}
                cell={DiffDateCell}
                width="140px"
                sortable={false}
                headerClassName="no-cursor"
              />
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      </ExcelExport>
    </>
  );
}
