import type { GridCellProps } from '@progress/kendo-react-grid';
import type { AssetRouter } from '../../../../types/__generated/on-premise-solution/api/asset.v1';
import type { AssetRouterDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/assetRouterDashboardResponse.v1';
import localeHost from '../../../../utils/i18n/assetLocales/hostList.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'RU' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHost;
export function CellDeviceVulnerabilities({
  dataItem: { latestAudit },
}: Omit<GridCellProps, 'latestAudit'> & {
  dataItem: AssetRouter | AssetRouterDashboardResponse;
}): React.ReactElement {
  return (
    <td className="number">
      {latestAudit ? (
        <span>{latestAudit.vulnerabilities.count}</span>
      ) : (
        <span>{localeHost[currentLocale].form.nd}</span>
      )}
    </td>
  );
}
