import type { ReactElement } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import packageStyles from '../../Task/TaskDescription.module.scss';
import type { DiffFilterTypes } from '../../../../utils/helpers/difference-modal-helper';

export function DiffFilters({
  currentFilter,
  filterList,
  setFilter,
}: {
  currentFilter: DiffFilterTypes;
  filterList: {
    [k: string]: string;
  };
  setFilter: (v: DiffFilterTypes) => void;
}): ReactElement {
  return (
    <div className={packageStyles.package_fast_filter} style={{ marginBottom: '24px' }}>
      {Object.entries(filterList).map(([key, v]) => (
        <Button
          className={key === currentFilter ? 'custom-type-search-active' : 'custom-type-search'}
          onClick={(): void => setFilter(key as DiffFilterTypes)}
        >
          {v}
        </Button>
      ))}
    </div>
  );
}
