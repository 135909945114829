/* eslint-disable no-nested-ternary */
import React, { createContext, useState, useMemo, useContext, useEffect, useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import type {
  IChildrenNodes,
  IEditRegister,
  IImageForm,
  IRegisterData,
  IRegistryForm,
} from '../utils/helpers/types';
import type { IRepositories } from '../components/features/Containers/modal/ModalAddRepository';
import type { ContainerRegistryCreationResponse } from '../types/__generated/on-premise-solution/api/containerRegistryCreationResponse.v1';
import type { ApiError } from '../types/__generated/on-premise-solution/api/apiError.v1';
import { createRegistry, editRegistry } from '../services/dashboard-service';
import { getAccountId } from '../services/local-storage-service';
import type {
  ContainerRegistriesResponse,
  ContainerRegistryResponse,
} from '../types/__generated/on-premise-solution/api/containerRegistriesResponse.v1';
import type { ContainerRegistryUpdateResponse } from '../types/__generated/on-premise-solution/api/containerRegistryUpdateResponse.v1';

export interface IRegisterFields {
  name: string;
  address: string;
  description: string;
}

export interface IInitialStateRegister {
  // accountId: string;
  description?: string;
  name: string;
  address: string;
  repositories?: IRepositories[];
  credentialId: string | undefined;
}
export type TRegisterContent = {
  isAddRegister: boolean;
  setAddRegister: (v: boolean) => void;
  isRegisterList: boolean;
  setRegisterList: (v: boolean) => void;
  isAddRepository: boolean;
  setAddRepository: (v: boolean) => void;
  registerData: IRegisterData | null;
  setRegisterData: React.Dispatch<React.SetStateAction<IRegisterData | null>>;
  initialRegisterFields: IRegisterFields;
  name: string;
  setName: (v: string) => void;
  address: string;
  setAddress: (v: string) => void;
  description: string;
  setDescription: (v: string) => void;
  credentialReg:
    | {
        credentialId: string | undefined;
        credentialName: string | undefined;
      }
    | undefined;
  setCredentialReg: (
    v: { credentialId: string | undefined; credentialName: string | undefined } | undefined,
  ) => void;
  repositories: [] | IRepositories[];
  setRepositories: React.Dispatch<React.SetStateAction<[] | IRepositories[]>>;
  onSubmitRegisterForm: (dataItem: any) => void;
  registryForm: IRegistryForm[] | null;
  setRegistryForm: (v: IRegistryForm[] | null) => void;
  registryFormTemp: IRegistryForm[] | null;
  setRegistryFormTemp: (v: IRegistryForm[] | null) => void;
  deleteRegistryFromTask: (
    registryId: string,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  exitRegistryFromTask: () => void;
  // addAllRegistry: (dataRegistry: ContainerRegistriesResponse | undefined) => void;
  addAllRegistry: (dataRegistry: ContainerRegistryResponse[] | undefined) => void;
  delAllRegistry: () => void;
  deleteRepositories: (nameValue: string) => void;
  cancelAddEdit: () => void;
  addSelectedRegisters: () => void;
  showRegWebHooks: boolean;
  setShowRegWebHooks: React.Dispatch<React.SetStateAction<boolean>>;
  initialStateRegister: IInitialStateRegister | null;
  isAddImage: boolean;
  setAddImage: (v: boolean) => void;
  imageForm: IImageForm[] | null;
  setImageForm: (v: IImageForm[] | null) => void;
  deleteImageFromTask: (
    imageId: string | undefined,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  exitImageFrom: () => void;
  addSelectedImages: () => void;
  errorMutation: string;
  setErrorMutation: (v: string) => void;
};

const RegisterContext = createContext<TRegisterContent | null>(null);

export function RegisterProvider({ children }: IChildrenNodes): React.ReactElement {
  const queryClient = useQueryClient();

  const accountId = getAccountId();

  const [isAddRegister, setAddRegister] = useState(false);

  const [isRegisterList, setRegisterList] = useState(false);

  const [isAddRepository, setAddRepository] = useState(false);

  const [registerData, setRegisterData] = useState<IRegisterData | null>(null); // registerData for edit, need change type interface;

  const [registryForm, setRegistryForm] = useState<IRegistryForm[] | null>(null);

  const [registryFormTemp, setRegistryFormTemp] = useState<IRegistryForm[] | null>(null);

  const [name, setName] = useState('');

  const [address, setAddress] = useState('');

  const [description, setDescription] = useState<string>('');

  const [credentialReg, setCredentialReg] = useState<
    | {
        credentialId: string | undefined;
        credentialName: string | undefined;
      }
    | undefined
  >(undefined);

  const [repositories, setRepositories] = useState<IRepositories[] | []>([]);

  const [showRegWebHooks, setShowRegWebHooks] = useState(false);

  const [initialStateRegister, setInitialStateRegister] = useState<IInitialStateRegister | null>(
    null,
  );

  const [isAddImage, setAddImage] = useState<boolean>(false);

  const [imageForm, setImageForm] = useState<IImageForm[] | null>(null);

  const [errorMutation, setErrorMutation] = useState('');

  const deleteImageFromTask = useCallback(
    (assetId: string | undefined, event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      event.stopPropagation();
      if (imageForm && imageForm.length > 0) {
        setImageForm(imageForm?.filter((image) => image.assetId !== assetId));
      }
    },
    [imageForm],
  );

  const exitImageFrom = useCallback(() => {
    setImageForm(null);
    setAddImage(false);
  }, []);

  const addSelectedImages = useCallback((): void => {
    setImageForm(imageForm);
    setAddImage(false);
  }, [imageForm]);

  const initialRegisterFields = useMemo(
    () => ({
      name: registerData ? registerData.name : '',
      address: registerData ? registerData.address : '',
      description: registerData ? registerData.description : '',
    }),
    [registerData],
  );

  const mutationAdd = useMutation<
    ContainerRegistryCreationResponse,
    ApiError,
    ContainerRegistryCreationResponse
  >((payload) => createRegistry(payload), {
    onSuccess: () => {
      setAddRegister(false);
      setRegisterData(null);
      setCredentialReg(undefined);
      setRepositories([]);
      queryClient.invalidateQueries('registryList');
    },
    onError: (resp) => {
      setErrorMutation(resp.message);
    },
  });

  const mutationEdit = useMutation<ContainerRegistryUpdateResponse, ApiError, IEditRegister>(
    (payload) => editRegistry(payload),
    {
      onSuccess: () => {
        setAddRegister(false);
        setRegisterData(null);
        setCredentialReg(undefined);
        setRepositories([]);
        queryClient.invalidateQueries('registryList');
        queryClient.invalidateQueries('dashboardRegistryId');
      },
      onError: (resp) => {
        setErrorMutation(resp.message);
      },
    },
  );

  useEffect(() => {
    if (registerData?.repositories && registerData.repositories.length > 0) {
      setRepositories(registerData.repositories);
    }
    if (registerData?.credential) {
      setCredentialReg({
        credentialId: registerData?.credential.credentialId,
        credentialName: registerData?.credential.name,
      });
    }
    setName(registerData?.name || '');
    setAddress(registerData?.address || '');
    setDescription(registerData?.description || '');
    if (registerData) {
      setInitialStateRegister({
        description: registerData.description,
        name: registerData.name,
        address: registerData.address,
        repositories: registerData.repositories,
        credentialId: registerData.credential?.credentialId,
      });
    }
  }, [registerData, registerData?.credential, registerData?.repositories]);

  const onSubmitRegisterForm = useCallback((): void => {
    const containerRegistryCreationData = {
      type: 'private',
      class: 'docker',
      accountId,
      description:
        description && description.length > 0 && initialStateRegister?.description !== description
          ? description
          : description?.length === 0
          ? null
          : undefined,
      name: initialStateRegister?.name !== name ? name : undefined,
      address: initialStateRegister?.address !== address ? address : undefined,
      repositories:
        initialStateRegister?.repositories &&
        repositories.length > 0 &&
        JSON.stringify(initialStateRegister?.repositories) !== JSON.stringify(repositories)
          ? repositories
          : initialStateRegister?.repositories &&
            repositories.length === 0 &&
            JSON.stringify(initialStateRegister?.repositories) !== JSON.stringify(repositories)
          ? null
          : !initialStateRegister?.repositories &&
            Array.isArray(repositories) &&
            repositories.length > 0
          ? repositories
          : undefined,
      credentialId:
        credentialReg && initialStateRegister?.credentialId !== credentialReg?.credentialId
          ? credentialReg?.credentialId
          : !credentialReg && initialStateRegister?.credentialId
          ? null
          : !credentialReg && !initialStateRegister?.credentialId
          ? undefined
          : undefined,
    } as ContainerRegistryCreationResponse;
    if (registerData) {
      mutationEdit.mutate({
        payload: containerRegistryCreationData,
        registryId: registerData.registryId,
      });
    } else {
      mutationAdd.mutate(containerRegistryCreationData);
    }
  }, [
    accountId,
    address,
    credentialReg,
    description,
    initialStateRegister?.address,
    initialStateRegister?.credentialId,
    initialStateRegister?.description,
    initialStateRegister?.name,
    initialStateRegister?.repositories,
    mutationAdd,
    mutationEdit,
    name,
    registerData,
    repositories,
  ]);

  // const addAllRegistry = useCallback(
  //   (dataRegistry: ContainerRegistriesResponse | undefined): void => {
  //     const selectedAllRegs: IRegistryForm[] = [];
  //     dataRegistry?.data.forEach((r) => {
  //       selectedAllRegs.push({ name: r.name, registryId: r.registryId });
  //     });
  //     setRegistryFormTemp(selectedAllRegs);
  //   },
  //   [],
  // );

  const addAllRegistry = useCallback(
    (dataRegistry: ContainerRegistryResponse[] | undefined): void => {
      const registryAllListForm = [] as IRegistryForm[];

      dataRegistry?.forEach((d) => {
        if (d.registryId) {
          registryAllListForm.push({ name: d.name, registryId: d.registryId });
        }
      });

      if (registryFormTemp && registryFormTemp.length > 0) {
        const registryFormAndSelected = [...registryFormTemp, ...registryAllListForm];

        const duplicatValChecked = registryFormAndSelected.filter(
          (reg, i, arr) => arr.findIndex((val) => val.registryId === reg.registryId) === i,
        );

        setRegistryFormTemp([...duplicatValChecked]);
      } else {
        setRegistryFormTemp([...registryAllListForm]);
      }
    },
    [registryFormTemp],
  );

  const delAllRegistry = useCallback((): void => {
    setRegistryFormTemp(null);
  }, []);

  const deleteRegistryFromTask = useCallback(
    (registryId: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      event.stopPropagation();
      if (registryForm && registryForm.length > 0) {
        setRegistryForm(registryForm?.filter((registry) => registry.registryId !== registryId));
      }
      if (registryFormTemp && registryFormTemp.length > 0) {
        setRegistryFormTemp(
          registryFormTemp?.filter((registry) => registry.registryId !== registryId),
        );
      }
    },
    [registryForm, registryFormTemp],
  );

  const exitRegistryFromTask = useCallback(() => {
    setRegistryFormTemp(null);
    setRegisterList(false);
  }, []);

  const deleteRepositories = useCallback(
    (nameValue: string): void => {
      const changedRepositories = repositories.filter((r) => r.name !== nameValue);
      setRepositories(changedRepositories);
    },
    [repositories, setRepositories],
  );

  const cancelAddEdit = useCallback((): void => {
    setAddRegister(false);
    setCredentialReg(undefined);
    setRegisterData(null);
    setRepositories([]);
    setInitialStateRegister(null);
  }, [setAddRegister, setCredentialReg, setRegisterData, setRepositories]);

  const addSelectedRegisters = useCallback((): void => {
    setRegistryForm(registryFormTemp);
    setRegisterList(false);
  }, [registryFormTemp]);

  const value = useMemo(
    () => ({
      isAddRegister,
      setAddRegister,
      isRegisterList,
      setRegisterList,
      isAddRepository,
      setAddRepository,
      registerData,
      setRegisterData,
      initialRegisterFields,
      name,
      setName,
      address,
      setAddress,
      credentialReg,
      setCredentialReg,
      repositories,
      setRepositories,
      onSubmitRegisterForm,
      description,
      setDescription,
      registryForm,
      setRegistryForm,
      deleteRegistryFromTask,
      exitRegistryFromTask,
      addAllRegistry,
      delAllRegistry,
      deleteRepositories,
      cancelAddEdit,
      registryFormTemp,
      setRegistryFormTemp,
      addSelectedRegisters,
      showRegWebHooks,
      setShowRegWebHooks,
      initialStateRegister,
      isAddImage,
      setAddImage,
      imageForm,
      setImageForm,
      deleteImageFromTask,
      exitImageFrom,
      addSelectedImages,
      errorMutation,
      setErrorMutation,
    }),
    [
      isAddRegister,
      isRegisterList,
      isAddRepository,
      registerData,
      initialRegisterFields,
      name,
      address,
      credentialReg,
      repositories,
      onSubmitRegisterForm,
      description,
      registryForm,
      deleteRegistryFromTask,
      exitRegistryFromTask,
      addAllRegistry,
      delAllRegistry,
      deleteRepositories,
      cancelAddEdit,
      registryFormTemp,
      addSelectedRegisters,
      showRegWebHooks,
      initialStateRegister,
      isAddImage,
      imageForm,
      deleteImageFromTask,
      exitImageFrom,
      addSelectedImages,
      errorMutation,
    ],
  );

  return <RegisterContext.Provider value={value}>{children}</RegisterContext.Provider>;
}
export const useRegisterContext = (): TRegisterContent => {
  const context = useContext(RegisterContext);
  if (context === null) {
    throw new Error('RegisterContext must be used inside the RegisterProvider.Provider.');
  }

  return context;
};
