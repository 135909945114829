import type { GridCellProps } from '@progress/kendo-react-grid';
import type { ILinSoftList, IWinSoftList } from '../../../../../utils/helpers/types';

export function CellInstalledAtSoftware(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { installedAt }: ILinSoftList | IWinSoftList = dataItem;

  return (
    <td>{installedAt ? <span>{new Date(installedAt).toLocaleString()}</span> : <span>-</span>}</td>
  );
}
