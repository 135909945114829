/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import localeHostDescription from '../../../../../utils/i18n/assetLocales/hostDescription.json';
import { useHostActions } from '../../../../../hooks/components/useHostActions';
import type {
  AssetFirewallDashboardResponse,
  AssetHypervisorDashboardResponse,
} from '../../../../../types/__generated/on-premise-solution/api/assetsDevicesDashboardResponse.v1';
import type { AssetRouterDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/assetRouterDashboardResponse.v1';
import { AssetDescriptionContextMenu } from '../../../../common/contextMenu/AssetDescriptionContextMenu';
import { useHostModalContext } from '../../../../../hooks/useHostModalContext';
import type { AssetSwitchDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/assetDeviceDashboardResponse.v1';
import { getUserData } from '../../../../../services/local-storage-service';
import formStyles from '../../../Containers/form/Form.module.scss';
import styles from '../../../Host/hostComponents/HostDescription.module.scss';

export function TableSDevicetMetaBlock({
  data,
  currentLocale,
}: {
  data:
    | AssetRouterDashboardResponse
    | AssetFirewallDashboardResponse
    | AssetSwitchDashboardResponse
    | AssetHypervisorDashboardResponse
    | undefined;
  currentLocale: 'ru-RU' | 'en-EN';
}): React.ReactElement {
  const currentUser = getUserData();

  const userRoleCondition =
    data?.createdBy.source === 'user' &&
    currentUser?.role === 'user' &&
    data.createdBy.id === currentUser.id;

  const { selectCredential } = useHostActions();

  const { setSshPort } = useHostModalContext();

  useEffect(() => {
    if (data && data.sshPort) {
      setSshPort(String(data.sshPort));
    }
  }, []);

  return (
    <table className="column">
      <tbody>
        <tr>
          <td>
            <span>{localeHostDescription[currentLocale].summary.ip}</span>
          </td>
          <td>{data?.latestInventorization.ip || '-'}</td>
        </tr>
        <tr>
          <td>
            <span>{localeHostDescription[currentLocale].summary.credential}</span>
          </td>
          <td>
            {data &&
              data.credential &&
              data.credentialId &&
              (currentUser?.role === 'reader_user' ||
                (!userRoleCondition && currentUser?.role === 'user')) && (
                <div className="credential-block-content">
                  <div className="credential-name" title={data.credential.name}>
                    {data.credential.name}
                  </div>
                  <div className="credential-block">
                    <div className="credential-name" title={data.credential.name}>
                      {data.credential.name}
                    </div>
                    <span className="credential-icon">
                      {data.credential.type ? data.credential.type : ''}
                    </span>
                  </div>
                </div>
              )}
            {data &&
              data.credential &&
              data.credentialId &&
              (currentUser?.role === 'super_admin' ||
                currentUser?.role === 'account_admin' ||
                userRoleCondition) && (
                <div className="credential-block-content">
                  <div className="credential-block">
                    <div className="credential-name" title={data.credential.name}>
                      {data.credential.name}
                    </div>
                    <span className="credential-icon">
                      {data.credential.type ? data.credential.type : ''}
                    </span>
                  </div>
                  <AssetDescriptionContextMenu data={data} currentLocale={currentLocale} />
                </div>
              )}
            {(!data?.credential || !data.credentialId) &&
              (currentUser?.role === 'super_admin' ||
                currentUser?.role === 'account_admin' ||
                userRoleCondition) && (
                <Button
                  icon="plus"
                  fillMode="flat"
                  className={`${formStyles.add_button} ${styles.add_credential}`}
                  onClick={(): void =>
                    selectCredential({
                      assetId: data?.assetId,
                      accountId: data?.accountId,
                      assetGroupIds: data?.assetGroupIds,
                      credentialId: data?.credential?.credentialId || undefined,
                      assetType: data?.type,
                    })
                  }
                >
                  <div className="add-credential-content__wrapper">
                    <span>{localeHostDescription[currentLocale].summary.addCredential}</span>
                  </div>
                </Button>
              )}
            {(!data?.credential || !data.credentialId) &&
              (currentUser?.role === 'reader_user' ||
                (!userRoleCondition && currentUser?.role === 'user')) && (
                <span>{localeHostDescription[currentLocale].isNotUsed}</span>
              )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
