import type { GridCellProps } from '@progress/kendo-react-grid';
import { NavLink } from 'react-router-dom';

export function CellAssetName(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  return (
    <td>
      {dataItem.type === 'image' && dataItem.imageNames[0] && (
        <NavLink
          // style={{ textDecoration: 'underline' }}
          to={`/lk/assets/images/${dataItem.assetId}`}
        >
          {dataItem.imageNames[0]}
        </NavLink>
      )}
      {(dataItem.type === 'router' ||
        dataItem.type === 'firewall' ||
        dataItem.type === 'switch' ||
        dataItem.type === 'host') &&
        dataItem.latestInventorization &&
        'hostname' in dataItem.latestInventorization && (
          <NavLink
            // style={{ textDecoration: 'underline' }}
            to={
              dataItem.type === 'router' ||
              dataItem.type === 'firewall' ||
              dataItem.type === 'switch'
                ? `/lk/assets/devices/${dataItem.type}/${dataItem.assetId}`
                : `/lk/assets/hosts/${dataItem.assetId}`
            }
          >
            {dataItem.latestInventorization.hostname}
          </NavLink>
        )}
      {dataItem.type === 'image' && !dataItem.imageNames[0] && <span>-</span>}
      {(dataItem.type === 'router' ||
        dataItem.type === 'firewall' ||
        dataItem.type === 'switch' ||
        dataItem.type === 'host') &&
        !dataItem.latestInventorization && <span>-</span>}
    </td>
  );
}
