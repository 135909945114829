import { nanoid } from 'nanoid';
import localeSettings from '../../../../utils/i18n/settingsLocales/settings.json';
import styles from '../WhiteListTabEl.module.scss';

interface ITypeValue {
  host: string;
  image: string;
  router: string;
  switch: string;
  firewall: string;
}

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;
export function UsedAssetType({ types }: { types: string[] | null }): React.ReactElement {
  return (
    <ul className={styles.usedAssetList}>
      {types?.map((t) => (
        <li key={nanoid()}>
          {localeSettings[currentLocale].apiKey.form.assetType[t as keyof ITypeValue]}{' '}
        </li>
      ))}
    </ul>
  );
}
