// eslint-disable-next-line import/no-cycle
import { authInstance } from '../../lib/axios/hooks/useAuthAxios';
import type { ApiError } from '../../types/__generated/on-premise-solution/api/apiError.v1';
// import type { CaptchaConfirmRequest } from '../../types/__generated/on-premise-solution/api/captchaConfirmRequest.v1';
// import type { CaptchaConfirmResponse } from '../../types/__generated/on-premise-solution/api/captchaConfirmResponse.v1';
import type { CaptchaValidateRequest } from '../../types/__generated/on-premise-solution/api/captchaValidateRequest.v1';
import type { CaptchaValidateResponse } from '../../types/__generated/on-premise-solution/api/captchaValidateResponse.v1';
import type { User2FALoginRequest } from '../../types/__generated/on-premise-solution/api/user2FALoginRequest.v1';
import type { User2FALoginResponse } from '../../types/__generated/on-premise-solution/api/user2FALoginResponse.v1';
// import type { UserLogin2FARequest } from '../../types/__generated/on-premise-solution/api/userLogin2FARequest.v1';
import type { UserLoginRequest } from '../../types/__generated/on-premise-solution/api/userLoginRequest.v1';
// import type { UserLogin2FARequest } from '../../types/__generated/on-premise-solution/api/userLoginRequestIntegration.v1';
import type { UserLoginResponse } from '../../types/__generated/on-premise-solution/api/userLoginResponse.v1';
import type { UserLogoutRequest } from '../../types/__generated/on-premise-solution/api/userLogoutRequest.v1';
import type { UserLogoutResponse } from '../../types/__generated/on-premise-solution/api/userLogoutResponse.v1';
import { getRefreshToken, removeAuthData, setTokens } from '../local-storage-service';

export async function sendLogin(payload: UserLoginRequest): Promise<UserLoginResponse> {
  if (payload.type === 'ldap') {
    const { type, password, uid, stayOn } = payload;
    const { data } = await authInstance.post<UserLoginResponse>('/login', {
      type,
      password,
      uid,
      stayOn,
    });
    setTokens(data);

    return data;
  }
  if (payload.type === 'activedirectory') {
    const { type, username, password, stayOn } = payload;
    const { data } = await authInstance.post<UserLoginResponse>('/login', {
      type,
      password,
      username,
      stayOn,
    });
    setTokens(data);

    return data;
  }

  const { type, login, email, password, stayOn } = payload;
  const { data } = await authInstance.post<UserLoginResponse>('/login', {
    type,
    login,
    email,
    password,
    stayOn,
  });

  if ('accessToken' in data) setTokens(data);

  return data;
}

export async function sendTfaCode(payload: User2FALoginRequest): Promise<User2FALoginResponse> {
  const { accessToken, code } = payload;

  const { data } = await authInstance.post<User2FALoginResponse>('/2fa/login', {
    accessToken,
    code,
  });

  if ('accessToken' in data) setTokens(data);

  return data;
}

export async function checkCaptcha(
  payload: CaptchaValidateRequest,
): Promise<CaptchaValidateResponse> {
  const { code } = payload;

  const { data } = await authInstance.post<CaptchaValidateResponse>('/captcha/validate', {
    code,
  });

  return data;
}

// export async function ldapLogin({
//   type,
//   uid,
//   password,
//   stayOn,
// }: UserLdapLoginRequest): Promise<UserLoginResponse> {
//   const { data } = await authInstance.post<UserLoginResponse>('/login', {
//     type,
//     uid,
//     password,
//     stayOn,
//   });
//   setTokens(data);

//   return data;
// }

// export async function adLogin({
//   type,
//   username,
//   password,
//   stayOn,
// }: UserActiveDirectoryLoginRequest): Promise<UserLoginResponse> {
//   const { data } = await authInstance.post<UserLoginResponse>('/login', {
//     type,
//     username,
//     password,
//     stayOn,
//   });
//   setTokens(data);

//   return data;
// }

export async function logout(): Promise<ApiError> {
  const status = await authInstance.post<UserLogoutResponse, ApiError, UserLogoutRequest>(
    '/logout',
    {
      refreshToken: getRefreshToken() || undefined,
    },
  );
  removeAuthData();

  return status;
}

export async function refresh(): Promise<UserLoginResponse> {
  console.log('Sending refresh.....');
  const { data } = await authInstance.post<UserLoginResponse>('/refresh', {
    refreshToken: getRefreshToken() || undefined,
  });

  return data;
}
