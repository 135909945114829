import type { ReactNode } from 'react';
import './PopUpMenu.scss';

export function PopUpMenu({
  title,
  children,
  className,
  disabled,
}: {
  title: string;
  children: ReactNode;
  className?: string;
  disabled?: boolean;
}): React.ReactElement {
  return (
    <div
      className={`popup-menu k-button k-button-flat ${className || ''} ${
        disabled ? 'k-disabled' : ''
      }`}
    >
      <span>{title}</span>
      <span className="k-icon k-i-arrow-chevron-right" />
      {!disabled && <div className="popup-content">{children}</div>}
    </div>
  );
}
