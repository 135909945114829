import { Skeleton } from '@progress/kendo-react-indicators';
import type { ReactElement } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import hostDescriptionLocale from '../../../../utils/i18n/assetLocales/hostDescription.json';
import type { AuditDifferenceResponse } from '../../../../types/__generated/on-premise-solution/api/auditDifferenceResponse.v1';
import type { InventorizationDifference } from '../../../../types/__generated/on-premise-solution/api/inventorizationDifference.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof hostDescriptionLocale;

export function NearestInfo({
  type,
  //   actionId,
  //   createdAt,
  //   taskActionId,
  className,
  isLoading,
  data,
}: {
  type: 'audit' | 'inventorization';
  //   actionId: string;
  //   taskActionId: string | null;
  //   createdAt: string;
  className?: string;
  isLoading?: boolean;
  data:
    | AuditDifferenceResponse['sourceAudits']['firstAudit']
    | Exclude<InventorizationDifference['sourceInventorizations'], null>['firstInventorization']
    | undefined;
}): ReactElement {
  const { assetId } = useParams<{ assetId: string }>();
  if (isLoading)
    return (
      <div>
        <Skeleton shape="rectangle" style={{ width: '300px', height: '16px' }} />
        <Skeleton shape="rectangle" style={{ width: '160px', height: '16px', marginTop: '5px' }} />
      </div>
    );

  if (!data) {
    return (
      <span className={className || ''}>
        {type === 'audit'
          ? hostDescriptionLocale[currentLocale].changes.noAudit
          : hostDescriptionLocale[currentLocale].changes.noInv}
      </span>
    );
  }

  const actionId = 'auditId' in data ? data.auditId : data.inventorizationId;
  let info = hostDescriptionLocale[currentLocale].changes.auditAgentInfo;
  let href = `/lk/assets/hosts/${assetId}`;

  if (type === 'audit' && data.taskActionId) {
    info = hostDescriptionLocale[currentLocale].changes.auditInfo;
    href = `/lk/task/actions/${actionId}/audit`;
  } else if (type === 'inventorization' && data.taskActionId) {
    info = hostDescriptionLocale[currentLocale].changes.invInfo;
    href = `/lk/task/actions/${actionId}/inventorization`;
  }

  /// //////////////////////////////////////

  //   if (!taskActionId)
  //     return (
  //       <span className={className || ''}>
  //         {type === 'audit'
  //           ? hostDescriptionLocale[currentLocale].changes.noAudit
  //           : hostDescriptionLocale[currentLocale].changes.noInv}
  //       </span>
  //     );

  //   let info = hostDescriptionLocale[currentLocale].changes.auditAgentInfo;
  //   let href = `/lk/assets/hosts/${assetId}`;

  //   if (type === 'audit' && taskActionId) {
  //     info = hostDescriptionLocale[currentLocale].changes.auditInfo;
  //     href = `/lk/task/actions/${actionId}/audit`;
  //   } else if (type === 'inventorization' && taskActionId) {
  //     info = hostDescriptionLocale[currentLocale].changes.invInfo;
  //     href = `/lk/task/actions/${actionId}/inventorization`;
  //   }

  return (
    <span className={className || ''}>
      {info}{' '}
      <NavLink target="_blank" to={href}>
        {new Date(data.createdAt).toLocaleString()}
      </NavLink>
    </span>
  );
}
