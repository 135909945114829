/* eslint-disable react/jsx-no-useless-fragment */
import { Dialog } from '@progress/kendo-react-dialogs';
import { useCallback, useRef } from 'react';
import { useQuery } from 'react-query';
import { Button } from '../../../../common/baseElements/Button';
import styles from './packageNameModal.module.scss';
import { useAuditModalContext } from '../../../../../hooks/useAuditModalContext';
import localeAuditTask from '../../../../../utils/i18n/taskLocale/auditTask.json';
import type { ApiError } from '../../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getBulletinById } from '../../../../../services/bulletins-service';
import type { BulletinDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/bulletinDashboardResponse.v1';
import { useAssetCreatePropContext } from '../../../../../hooks/useAssetCreatePropContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

export function MissingKbModal(): React.ReactElement {
  const { idKb, setIdKb } = useAuditModalContext();

  const title = useRef<string | null>(null);

  const description = useRef<string | null>(null);

  const modified = useRef<string | null>(null);

  const published = useRef<string | null>(null);

  useQuery<BulletinDashboardResponse, ApiError>(['bulletinById'], () => getBulletinById(idKb), {
    onSuccess: (resp) => {
      title.current = resp?.title ? resp.title : '';
      description.current = resp?.description ? resp.description : '';
      modified.current = resp?.date ? new Date(String(resp?.date.modified)).toLocaleString() : '-';
      published.current = resp?.date
        ? new Date(String(resp?.date.published)).toLocaleString()
        : '-';
    },
    retry: false,
    enabled: !!idKb,
  });

  const handleCloseModal = useCallback((): void => {
    setIdKb(null);
  }, [setIdKb]);

  const { addThisKbToWl } = useAssetCreatePropContext();

  return (
    <>
      <Dialog title={`${idKb}`} onClose={handleCloseModal} className={styles.kbModalWrap}>
        <div className={styles.addVuln}>
          <Button className="selected-pkgs" onClick={(): void => addThisKbToWl(idKb || '')}>
            {localeAuditTask[currentLocale].vulnsSoft.addToExceptions}
          </Button>
        </div>
        <div className="k-form">
          <div className="form-content">
            <div className="kbmodal__content">
              <div className="common-summary-page">
                <table className="column">
                  <tr>
                    <td>{localeAuditTask[currentLocale].isMissedKbModal.title}</td>
                    <td>{title.current || '-'}</td>
                  </tr>
                  <tr>
                    <td> {localeAuditTask[currentLocale].isMissedKbModal.published}</td>
                    <td>{published.current || '-'}</td>
                  </tr>
                  <tr>
                    <td> {localeAuditTask[currentLocale].isMissedKbModal.modified}</td>
                    <td>{modified.current}</td>
                  </tr>
                </table>
              </div>
            </div>
            <table className="column">
              <tr>
                <td>{localeAuditTask[currentLocale].isMissedKbModal.description}</td>
                <td className={styles.kb_description_td}>{description.current || '-'}</td>
              </tr>
            </table>
          </div>
          <div className="k-form-buttons">
            <Button type="button" onClick={handleCloseModal}>
              {localeAuditTask[currentLocale].close}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
