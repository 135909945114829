/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-nested-ternary */
import { Grid, GridColumn, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useState } from 'react';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import type {
  InventorizationResponse,
  InventorizationFirewallCheckpointResult,
} from '../../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { WrapperMenu } from './WrapperMenu';
import { StandartCell } from '../../../../common/cells/StandartCell';
import { LAZY_PAGEABLE_DATA } from '../../../../../utils/helpers/constants';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function SoftwareListNetDevices({
  data,
}: {
  data: InventorizationResponse;
}): React.ReactElement {
  const { result } = data;

  const { softwareList } = result as unknown as InventorizationFirewallCheckpointResult;

  const [page, setPage] = useState({ take: 5, skip: 0 });

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  return (
    <WrapperMenu title={locale[currentLocale].packages.title}>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <Grid
            data={
              data.type === 'firewall' && data.class === 'checkpoint'
                ? softwareList?.slice(page.skip, page.take + page.skip)
                : null
            }
            pageable={LAZY_PAGEABLE_DATA}
            scrollable="none"
            total={
              data.type === 'firewall' && data.class === 'checkpoint' ? softwareList?.length : 0
            }
            pageSize={page.take}
            skip={page.skip}
            onPageChange={pageChange}
            className="no-margin-top"
          >
            <GridColumn
              field="name"
              title={locale[currentLocale].packages.name}
              cell={StandartCell}
            />
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </WrapperMenu>
  );
}
