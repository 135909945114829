import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import {
  Grid,
  GridColumn as Column,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { ErrorBoundary } from 'react-error-boundary';
import type { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { useQuery, useQueryClient } from 'react-query';
import { useEffect, useRef, useState } from 'react';
import { Filter, FilterChangeEvent } from '@progress/kendo-react-data-tools';
import localeHostGroups from '../../utils/i18n/assetLocales/assetGroups.json';
import { FILTER_INITIAL, PAGEABLE_DATA } from '../../utils/helpers/constants';
import type { IDataHooks, IPageState } from '../../utils/helpers/types';
import { FILTER_OPERATORS } from '../../utils/helpers/constant-filter-operators';
import { handleSort } from '../../utils/sorting-in-table';
import { CellGroupName } from './cellsCommonGroupList/CellGroupName';
import { CellCommonTd } from './cellsCommonGroupList/CellCommonTd';
import { CellMenuGroup } from './cellsCommonGroupList/CellMenuGroup';
import { BoundaryErrorComponent } from './BoundaryErrorComponent';
import { CellSelectGroupHost } from './cellsCommonGroupList/CellSelectGroupHost';
import { CellToCheckGr } from './cellsCommonGroupList/CellToCheckGr';
import { CellGroupDescription } from './cellsCommonGroupList/CellGroupDescription';
import type { IUrlParamsAssetGr } from '../../utils/helpers/host-group-list-helper';
import { handleFilteringTest, IFilterVal } from '../../utils/filtering-in-table-test';
import { useCustomSearch } from '../../hooks/components/useCustomSearch';
import { useFilter } from '../../hooks/components/useFilter';
import { usePageChange } from '../../hooks/components/usePageChange';
import { useNotificationContext } from '../../hooks/useNotificationContext';
import type { ApiError } from '../../types/__generated/on-premise-solution/api/apiError.v1';
import { getHostGroupList } from '../../services/asset-service';
import { handleError } from '../../utils/errors';
import { FILTER_LIST_GROUP } from '../../utils/helpers/constant-serv-filter-host';
import { Input } from './baseElements/Input';
import { Button } from './baseElements/Button';
import { handleAddAllGroups } from '../../utils/helpers/add-host-list-helper';
import { useHostModalContext } from '../../hooks/useHostModalContext';
import type { AssetGroupsDashboardResponse } from '../../types/__generated/on-premise-solution/api/assetGroupsDashboardResponse.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHostGroups;

export function CommonGroupList({
  dataForHooks,
  urlParams,
}: {
  dataForHooks: IDataHooks;
  urlParams: IUrlParamsAssetGr;
}): React.ReactElement {
  const queryClient = useQueryClient();

  const [customSearch, setCustomSearch] = useState('');

  const [sort, setSort] = useState<SortDescriptor[]>([{ field: 'name', dir: 'desc' }]);

  const [page, setPage] = useState<IPageState>(urlParams.page);

  const [filter, setFilter] = useState<CompositeFilterDescriptor>(FILTER_INITIAL);

  const [filterStatus, setFilterStatus] = useState(false);

  const [filterVal, setFilterVal] = useState<IFilterVal[]>([]);

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  const filterRef = useRef<CompositeFilterDescriptor>(filter);

  const debouncedCustomSearch = useCustomSearch(
    setFilterStatus,
    setCustomSearch,
    filter,
    setFilterVal,
    setPage,
    page,
    dataForHooks,
  );

  const filterSearch = useFilter(
    filterValue,
    setFilter,
    customSearch,
    setCustomSearch,
    setFilterVal,
    filterRef,
    filter,
    setPage,
    page,
    dataForHooks,
  );

  const getPage = usePageChange(setPage, urlParams, dataForHooks);

  const { createNotification } = useNotificationContext();

  const { hostFormTemp, setHostFormTemp } = useHostModalContext();

  const query = useQuery<AssetGroupsDashboardResponse, ApiError>(
    ['HostGroupList', page, filterVal, sort],
    () => getHostGroupList(page, filterVal, sort, dataForHooks.componentName === 'HostGroupList'),
    {
      keepPreviousData: true,
    },
  );

  useEffect(() => {
    queryClient.invalidateQueries('HostGroupList');
  }, [queryClient]);

  useEffect(() => {
    let delaySearch: NodeJS.Timeout;

    if (filter?.filters?.length > 0) {
      filterRef.current = filter;
      delaySearch = setTimeout(() => {
        handleFilteringTest(filterRef.current, setFilterVal);
        setFilterStatus(false);
      }, 300);
    }

    return () => clearTimeout(delaySearch);
  }, [filter?.filters, page.skip, filter]);

  useEffect(() => {
    if (query.error) {
      handleError(query.error, createNotification);
    }
  }, [query.isError, query.error, createNotification]);

  const pageChange = (event: GridPageChangeEvent): void => {
    getPage(event);
  };

  const onFilterChange = (event: FilterChangeEvent): void => {
    filterSearch(event);
  };

  const handleCustomSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    debouncedCustomSearch(e);
  };

  const { data } = query;

  return (
    <>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <div className="filter-line">
            <Filter
              value={filter}
              onChange={onFilterChange}
              fields={FILTER_LIST_GROUP[currentLocale]}
            />
            {filter.filters.length === 0 && (
              <Input
                name="customSearch"
                placeholder={localeHostGroups[currentLocale].customSearchName}
                value={customSearch}
                onChange={(e): void => handleCustomSearch(e)}
              />
            )}
          </div>
        </IntlProvider>
      </LocalizationProvider>
      {!document.location.pathname.includes('assets/groups') && (
        <div className="common-group-actions">
          <Button
            onClick={(): void => handleAddAllGroups(data?.data, hostFormTemp, setHostFormTemp)}
          >
            {localeHostGroups[currentLocale].form.btnAddAllGr}
          </Button>
          {hostFormTemp &&
            hostFormTemp.filter((gr) => !gr.assetId) &&
            hostFormTemp.filter((gr) => !gr.assetId).length > 0 && (
              <Button onClick={(): void => setHostFormTemp(null)}>
                {localeHostGroups[currentLocale].form.btnCancelAllGr} (
                {hostFormTemp.filter((gr) => !gr.assetId).length})
              </Button>
            )}
        </div>
      )}
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
            <Grid
              pageable={Number(data?.total) > 15 ? PAGEABLE_DATA : false}
              sortable
              sort={sort}
              data={data}
              skip={page.skip}
              take={page.take}
              total={Number(data?.total)}
              filterOperators={FILTER_OPERATORS}
              onPageChange={pageChange}
              onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
              scrollable="none"
              className={
                Number(data?.total) && Number(data?.total) < 15
                  ? 'no-pageable-k-grid table'
                  : 'table'
              }
            >
              {dataForHooks.componentName === 'AddHostGroups' && (
                <Column
                  field="addAssetGroup"
                  // title={localeHostGroups[currentLocale].form.groupAddForm}
                  title=" "
                  cell={CellSelectGroupHost}
                  width="40px"
                />
              )}
              {dataForHooks.componentName === 'GroupListOfHost' && (
                <Column
                  field="addAssetGroup"
                  title={localeHostGroups[currentLocale].form.groupAddForm}
                  cell={CellToCheckGr}
                  width="92px"
                  sortable={false}
                />
              )}
              {dataForHooks.componentName === 'AddHostGroups' ? (
                <Column
                  field="name"
                  title={localeHostGroups[currentLocale].form.name}
                  filter="text"
                />
              ) : (
                <Column
                  field="name"
                  title={localeHostGroups[currentLocale].form.name}
                  filter="text"
                  cell={CellGroupName}
                  width={dataForHooks.componentName === 'GroupListOfHost' ? '380px' : 'auto'}
                />
              )}
              <Column
                field="assetCount"
                title={localeHostGroups[currentLocale].form.assetCount}
                filter="text"
                width={dataForHooks.componentName === 'GroupListOfHost' ? '132px' : '96px'}
                className="number"
              />
              <Column
                field="description"
                title={localeHostGroups[currentLocale].form.description}
                filter="text"
                cell={CellGroupDescription}
              />
              {dataForHooks.componentName !== 'GroupListOfHost' && (
                <Column
                  field="createdAt"
                  title={localeHostGroups[currentLocale].form.createdAt}
                  filter="text"
                  cell={CellCommonTd}
                  width="180px"
                />
              )}
              {dataForHooks.componentName !== 'GroupListOfHost' && (
                <Column
                  field="createdBy"
                  title={localeHostGroups[currentLocale].form.createdBy}
                  filter="text"
                  cell={CellCommonTd}
                />
              )}
              {dataForHooks.componentName !== 'GroupListOfHost' && (
                <Column
                  field="modifiedAt"
                  title={localeHostGroups[currentLocale].form.modifiedAt}
                  filter="text"
                  cell={CellCommonTd}
                  width="180px"
                />
              )}
              {dataForHooks.componentName !== 'GroupListOfHost' && (
                <Column
                  field="modifiedBy"
                  title={localeHostGroups[currentLocale].form.modifiedBy}
                  filter="text"
                  cell={CellCommonTd}
                />
              )}
              {dataForHooks.componentName === 'HostGroupList' && (
                <Column width="40px" cell={CellMenuGroup} />
              )}
            </Grid>
          </ErrorBoundary>
        </IntlProvider>
      </LocalizationProvider>
    </>
  );
}
