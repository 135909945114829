import { Button } from '@progress/kendo-react-buttons';
import type { State } from '@progress/kendo-data-query';
import { useState } from 'react';
import localeAuditTask from '../../../../utils/i18n/taskLocale/auditTask.json';
import type { ICreateKbsData } from '../../../../utils/helpers/task-helpers';
import { handlerInstalledSearch } from '../../../../utils/helpers/local-filter-helpers';
import styles from '../TaskDescription.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

export function TaskKbFastFilter({
  dataState,
  setDataState,
  kbsData,
  filterValue,
}: {
  dataState: State;
  setDataState: React.Dispatch<React.SetStateAction<State>>;
  kbsData: ICreateKbsData[];
  filterValue: React.MutableRefObject<any>;
}): React.ReactElement {
  const [activeBtn, setActiveBtn] = useState('all');

  return (
    <div className={styles.package_fast_filter}>
      <Button
        className={activeBtn === 'all' ? 'custom-type-search-active' : 'custom-type-search'}
        onClick={(): void =>
          handlerInstalledSearch(setDataState, filterValue, dataState, 'all', setActiveBtn, 'type')
        }
      >
        {localeAuditTask[currentLocale].packageList.customSearch.all} (
        {kbsData ? kbsData.length : 0})
      </Button>
      <Button
        className={
          activeBtn === 'alreadyExistKb' ? 'custom-type-search-active' : 'custom-type-search'
        }
        onClick={(): void =>
          handlerInstalledSearch(
            setDataState,
            filterValue,
            dataState,
            'alreadyExistKb',
            setActiveBtn,
            'status',
          )
        }
      >
        {localeAuditTask[currentLocale].packageList.customSearch.alreadyExistKb} (
        {kbsData ? kbsData.filter((k) => k.status === 'alreadyExistKb')?.length : 0})
      </Button>
      <Button
        className={activeBtn === 'deletedKb' ? 'custom-type-search-active' : 'custom-type-search'}
        onClick={(): void =>
          handlerInstalledSearch(
            setDataState,
            filterValue,
            dataState,
            'deletedKb',
            setActiveBtn,
            'status',
          )
        }
      >
        {localeAuditTask[currentLocale].packageList.customSearch.deletedKb} (
        {kbsData ? kbsData.filter((k) => k.status === 'deletedKb')?.length : 0})
      </Button>
      <Button
        className={activeBtn === 'installedKb' ? 'custom-type-search-active' : 'custom-type-search'}
        onClick={(): void =>
          handlerInstalledSearch(
            setDataState,
            filterValue,
            dataState,
            'installedKb',
            setActiveBtn,
            'status',
          )
        }
      >
        {localeAuditTask[currentLocale].packageList.customSearch.installedKb} (
        {kbsData ? kbsData.filter((k) => k.status === 'installedKb')?.length : 0})
      </Button>
      <Button
        className={activeBtn === 'notFoundKb' ? 'custom-type-search-active' : 'custom-type-search'}
        onClick={(): void =>
          handlerInstalledSearch(
            setDataState,
            filterValue,
            dataState,
            'notFoundKb',
            setActiveBtn,
            'status',
          )
        }
      >
        {localeAuditTask[currentLocale].packageList.customSearch.notFoundKb} (
        {kbsData ? kbsData.filter((k) => k.status === 'notFoundKb')?.length : 0})
      </Button>
      <Button
        className={
          activeBtn === 'notInstalledKb' ? 'custom-type-search-active' : 'custom-type-search'
        }
        onClick={(): void =>
          handlerInstalledSearch(
            setDataState,
            filterValue,
            dataState,
            'notInstalledKb',
            setActiveBtn,
            'status',
          )
        }
      >
        {localeAuditTask[currentLocale].packageList.customSearch.notInstalledKb} (
        {kbsData ? kbsData.filter((k) => k.status === 'notInstalledKb')?.length : 0})
      </Button>
    </div>
  );
}
