import { Filter } from '@progress/kendo-react-data-tools';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Button } from '../../../../common/baseElements/Button';
import { СommonTableLoader } from '../../../../common/tableLoader/CommonTableLoader';
import type localeAccount from '../../../../../utils/i18n/accountLocales/accountList.json';
import localeSettings from '../../../../../utils/i18n/settingsLocales/settings.json';
import localeHost from '../../../../../utils/i18n/assetLocales/hostList.json';
import { FILTER_IMAGELIST } from '../../../../../utils/helpers/constant-serv-filter-host';
import { useImagesList } from '../../../../../hooks/components/useImageList';
import { CommonImageList } from '../../../../common/CommonImageList';
import { addAllImages } from '../../../../../utils/helpers/apikey-helpers';
import { Input } from '../../../../common/baseElements/Input';
import { useRegisterContext } from '../../../../../hooks/useRegisterContext';
import { INITIAL_IMAGE_LIST } from '../../../../../utils/helpers/constants';
import { getUrlParamsAddHosts } from '../../../../../utils/helpers/add-host-list-helper';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAccount;

export function ImageListFormTab(): React.ReactElement {
  const dataForHooks = {
    name: 'imageNames',
    componentName: 'AddImageList',
  };

  const urlParams = getUrlParamsAddHosts('addImageList', INITIAL_IMAGE_LIST);

  const {
    filter,
    onFilterChange,
    sort,
    page,
    total,
    pageChange,
    setSort,
    isLoading,
    filterStatus,
    isError,
    errorMessage,
    dataImage,
    handleCustomSearch,
    customSearch,
  } = useImagesList(urlParams, dataForHooks);

  const { imageForm, setImageForm } = useRegisterContext();

  return (
    <div className="add-asset">
      <div className="k-form">
        <div className="form-content select-asset">
          <LocalizationProvider language={currentLocale}>
            <IntlProvider locale={currentLocale.substring(0, 2)}>
              <div className="filter-line">
                <Filter
                  value={filter}
                  onChange={onFilterChange}
                  fields={FILTER_IMAGELIST[currentLocale]}
                />
                {filter.filters.length === 0 && (
                  <Input
                    name="customSearch"
                    placeholder={localeHost[currentLocale].customSearchImage}
                    value={customSearch}
                    onChange={(e): void => handleCustomSearch(e)}
                  />
                )}
              </div>
            </IntlProvider>
          </LocalizationProvider>
          <div className="select-asset__actions k-form-select-buttons">
            <Button onClick={(): void => addAllImages(dataImage, imageForm, setImageForm)}>
              {localeSettings[currentLocale].apiKey.btnAddAll}
            </Button>
            {imageForm && imageForm.length > 0 && (
              <Button onClick={(): void => setImageForm(null)}>
                {localeSettings[currentLocale].apiKey.btnDelAllImages} ({imageForm?.length})
              </Button>
            )}
          </div>
          <CommonImageList
            sort={sort}
            dataImage={dataImage}
            page={page}
            total={total}
            pageChange={pageChange}
            setSort={setSort}
            dataForHooks={dataForHooks}
            conditionSort={15}
          />
          <СommonTableLoader queryData={isLoading} filteringData={filterStatus} />
          {isError && <div className="info alert alert-danger">Error: {errorMessage}</div>}
        </div>
      </div>
    </div>
  );
}
