import { Filter } from '@progress/kendo-react-data-tools';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Button } from '../../../common/baseElements/Button';
import { СommonTableLoader } from '../../../common/tableLoader/CommonTableLoader';
import type localeHost from '../../../../utils/i18n/assetLocales/hostList.json';
import localeNewTask from '../../../../utils/i18n/taskLocale/newTask.json';
import { getUrlParamsAddHosts } from '../../../../utils/helpers/add-host-list-helper';
import { FILTER_REGISTRY_LIST } from '../../../../utils/helpers/constant-serv-filter-host';
import { useRegistriesList } from '../../../../hooks/components/useRegistryList';
import { CommonRegistryList } from '../../../common/CommonRegistryList';
import { useRegisterContext } from '../../../../hooks/useRegisterContext';
import { Input } from '../../../common/baseElements/Input';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHost;

export function RegistryListFormTab(): React.ReactElement {
  const dataForHooks = {
    name: 'name',
    componentName: 'AddRegistryList',
  };

  const urlParams = getUrlParamsAddHosts(dataForHooks.componentName);

  const { addAllRegistry, delAllRegistry, registryFormTemp } = useRegisterContext();

  const {
    filter,
    onFilterChange,
    sort,
    page,
    total,
    pageChange,
    setSort,
    isLoading,
    filterStatus,
    isError,
    errorMessage,
    dataRegistry,
    handleCustomSearch,
    customSearch,
  } = useRegistriesList(urlParams, dataForHooks, null);

  return (
    <div className="k-form">
      <div className="form-content select-asset">
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <div className="filter-line">
              <Filter
                value={filter}
                onChange={onFilterChange}
                fields={FILTER_REGISTRY_LIST[currentLocale]}
              />
              {filter.filters.length === 0 && (
                <Input
                  name="customSearch"
                  placeholder={localeNewTask[currentLocale].customFilterRegName}
                  value={customSearch}
                  onChange={(e): void => handleCustomSearch(e)}
                />
              )}
            </div>
          </IntlProvider>
        </LocalizationProvider>
        <div className="k-form-select-buttons">
          <Button onClick={(): void => addAllRegistry(dataRegistry?.data)}>
            {localeNewTask[currentLocale].form.btnAddAllAssets}
          </Button>
          {registryFormTemp && registryFormTemp && registryFormTemp.length > 0 && (
            <Button onClick={delAllRegistry}>
              {localeNewTask[currentLocale].form.btnDelAllRegistry} ({registryFormTemp?.length})
            </Button>
          )}
        </div>
        <CommonRegistryList
          sort={sort}
          dataRegistry={dataRegistry}
          page={page}
          total={total}
          pageChange={pageChange}
          setSort={setSort}
          dataForHooks={dataForHooks}
        />
        <СommonTableLoader queryData={isLoading} filteringData={filterStatus} />
        {isError && <div className="info alert alert-danger">Error: {errorMessage}</div>}
      </div>
    </div>
  );
}
