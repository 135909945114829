/* eslint-disable react/jsx-pascal-case */
import { Grid, GridColumn as Column, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { ErrorBoundary } from 'react-error-boundary';
import { useEffect } from 'react';
import type { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Filter } from '@progress/kendo-react-data-tools';
import locale from '../../utils/i18n/credentials/credentials.json';
import '../features/Credentials/Credentials.module.scss';
import type { ICommonDataCredList } from '../../utils/helpers/types';
import { PAGEABLE_DATA } from '../../utils/helpers/constants';
import { BoundaryErrorComponent } from './BoundaryErrorComponent';
import { FILTER_OPERATORS } from '../../utils/helpers/constant-filter-operators';
import { handleSort } from '../../utils/sorting-in-table';
import { CellCommonMenu } from '../features/Credentials/cell/CellCommonMenu';
import { CellCheckCredential } from '../features/Credentials/cell/CellCheckCredential';
import { StandartCell } from './baseElements/StandartCell';
import { CellCommonDate } from './baseElements/CellCommonDate';
import { getUrlCredential } from '../../utils/helpers/credential-helper';
import { useCredentialList } from '../../hooks/components/useCredentialList';
import { useCredentialContext } from '../../hooks/useCredentialContext';
import { FILTER_LIST_CREDENTIALS } from '../../utils/helpers/constant-serv-filter-host';
import { Input } from './baseElements/Input';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function CommonCredentialList(props: ICommonDataCredList): React.ReactElement {
  const { dataForHooks, creditType } = props;

  const urlParams = getUrlCredential('CredentialList');

  const {
    sort,
    data,
    page,
    setPage,
    total,
    pageChange,
    setSort,
    filterValue,
    setFilter,
    filter,
    onFilterChange,
    customSearch,
    handleCustomSearch,
  } = useCredentialList(urlParams, dataForHooks);

  useEffect(() => {
    if (dataForHooks.componentName === 'CredentialListModal') {
      const startPage = { skip: 0, take: 15 };
      setPage(startPage);
    }
  }, []);

  const { selectedCredential } = useCredentialContext();

  useEffect(() => {
    if (creditType === 'ssh') {
      filterValue.current = {
        filters: [
          {
            field: 'type',
            operator: 'eq',
            value: 'ssh',
          },
        ],
        logic: 'and',
      } as CompositeFilterDescriptor;
      setFilter(filterValue.current);
    } else if (creditType === 'winrm') {
      filterValue.current = {
        filters: [
          {
            field: 'type',
            operator: 'eq',
            value: 'winrm',
          },
        ],
        logic: 'and',
      } as CompositeFilterDescriptor;
      setFilter(filterValue.current);
    } else if (creditType === 'container_registry') {
      filterValue.current = {
        filters: [
          {
            field: 'type',
            operator: 'eq',
            value: 'container_registry',
          },
        ],
        logic: 'and',
      } as CompositeFilterDescriptor;
      setFilter(filterValue.current);
    } else if (creditType === 'hashicorp_vault_userpass') {
      filterValue.current = {
        filters: [
          {
            field: 'type',
            operator: 'eq',
            value: 'hashicorp_vault_userpass',
          },
        ],
        logic: 'and',
      } as CompositeFilterDescriptor;
      setFilter(filterValue.current);
    } else if (creditType === 'hashicorp_vault_token') {
      filterValue.current = {
        filters: [
          {
            field: 'type',
            operator: 'eq',
            value: 'hashicorp_vault_token',
          },
        ],
        logic: 'and',
      } as CompositeFilterDescriptor;
      setFilter(filterValue.current);
    } else if (creditType === 'snmp') {
      filterValue.current = {
        filters: [
          {
            field: 'type',
            operator: 'eq',
            value: 'snmp',
          },
        ],
        logic: 'or',
      } as CompositeFilterDescriptor;
      setFilter(filterValue.current);
    }
  }, [
    creditType,
    data,
    filterValue,
    selectedCredential,
    selectedCredential?.assetType,
    selectedCredential?.osType,
    selectedCredential?.type,
    setFilter,
  ]);

  return (
    <div className="credential-list">
      {dataForHooks.componentName === 'CredentialList' && (
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <div className="filter-line">
              <Filter
                value={filter}
                onChange={onFilterChange}
                fields={FILTER_LIST_CREDENTIALS[currentLocale]}
              />
              {filter.filters.length === 0 && (
                <Input
                  name="customSearch"
                  placeholder={locale[currentLocale].table.customSearchName}
                  value={customSearch}
                  onChange={(e): void => handleCustomSearch(e)}
                />
              )}
            </div>
          </IntlProvider>
        </LocalizationProvider>
      )}
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
            <Grid
              pageable={total && total > 15 ? PAGEABLE_DATA : false}
              sortable
              sort={sort}
              data={data}
              skip={page.skip}
              take={page.take}
              total={total || 0}
              filterOperators={FILTER_OPERATORS}
              onPageChange={pageChange}
              onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
              scrollable="none"
              className={total && total < 15 ? 'no-pageable-k-grid' : ''}
            >
              {dataForHooks.componentName === 'CredentialListModal' && (
                <Column width="40" cell={CellCheckCredential} />
              )}
              <Column
                field="type"
                title={locale[currentLocale].table.type}
                filter="text"
                width="200"
              />
              <Column
                field="name"
                title={locale[currentLocale].table.name}
                filter="text"
                width="232"
                cell={StandartCell}
              />
              <Column
                field="createdBy.name"
                title={locale[currentLocale].table.createdBy}
                filter="text"
                width="180"
                className="title"
                cell={StandartCell}
              />
              <Column
                field="createdAt"
                title={locale[currentLocale].table.createdAt}
                filter="text"
                cell={CellCommonDate}
                width="180"
              />
              <Column field="credentialId" title="ID" filter="text" width="300" />
              <Column
                field="description"
                title={locale[currentLocale].table.description}
                filter="text"
                cell={StandartCell}
                // width="300"
              />
              {dataForHooks.componentName === 'CredentialList' && (
                <Column cell={CellCommonMenu} width="40px" />
              )}
            </Grid>
          </ErrorBoundary>
        </IntlProvider>
      </LocalizationProvider>
    </div>
  );
}
