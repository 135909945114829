/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import globalLocale from '../../../utils/i18n/templates/global.json';
import styles from './Blocks.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'RU' ? 'ru-RU' : 'en-EN'
) as keyof typeof globalLocale;

function defineScoreSeverity(score: number | null | undefined): string {
  if (typeof score === 'number' && score >= 0 && score < 1.5) return 'low';

  if (score && score >= 1.5 && score < 4.5) return 'medium';

  if (score && score >= 4.5 && score < 7) return 'high';

  if (score && score >= 7) return 'critical';

  return 'none';
}

export function VBlock({
  score,
}: {
  score: number | string | null | undefined;
}): React.ReactElement {
  const scoreSeverity = defineScoreSeverity(typeof score === 'string' ? Number(score) : score);

  return (
    <span className={`${styles.v_score} ${styles[scoreSeverity]}`}>
      {scoreSeverity !== 'none' ? Number(score) : globalLocale[currentLocale].cvssScore.nd}
    </span>
  );
}
