/* eslint-disable react/jsx-pascal-case */
// import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Field } from '@progress/kendo-react-form';
import localeSettings from '../../../../../utils/i18n/settingsLocales/settings.json';
import styles from '../ApiKeyList.module.scss';
import { useApiKeyContext } from '../../../../../hooks/useApiKeyContext';
import { FormInput } from '../../../../common/form/FormInput';
import { checkLength } from '../../../../../utils/helpers/validation';
import { FormTextArea } from '../../../../common/form/FormTextArea';
import { getUserData } from '../../../../../services/local-storage-service';

interface IValuesForCheckbox {
  isAccounts: boolean;
  setAccounts: React.Dispatch<React.SetStateAction<boolean>>;
  isHosts: boolean;
  setHosts: React.Dispatch<React.SetStateAction<boolean>>;
  isGroups: boolean;
  setGroups: React.Dispatch<React.SetStateAction<boolean>>;
  isMethods: boolean;
  setMethods: React.Dispatch<React.SetStateAction<boolean>>;
  isDevices: boolean;
  setDevices: React.Dispatch<React.SetStateAction<boolean>>;
  isImages: boolean;
  setImages: React.Dispatch<React.SetStateAction<boolean>>;
  isIps: boolean;
  setIps: React.Dispatch<React.SetStateAction<boolean>>;
}

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;
export function ApiFormMain({
  valuesForCheckbox,
}: {
  valuesForCheckbox: IValuesForCheckbox;
}): React.ReactElement {
  const currentUser = getUserData();

  const {
    isAccounts,
    setAccounts,
    isHosts,
    setHosts,
    isMethods,
    setMethods,
    isDevices,
    setDevices,
    isImages,
    setImages,
    isIps,
    setIps,
  } = valuesForCheckbox;

  const { name, setName, description, setDescription } = useApiKeyContext();

  return (
    <>
      <div className={`form-block ${styles.apiInputName}`}>
        <Field
          name="name"
          component={FormInput}
          id="name"
          label={localeSettings[currentLocale].apiKey.form.name}
          onChange={(e): void => {
            setName(e.target.value);
          }}
          inputValue={name}
          className="form-field-wrapper"
          customValidator={checkLength}
          type="text"
          required
          classForLabel="required-label"
        />
      </div>
      <div className={styles.apikeyCheckboxWrapper}>
        <div className={styles.titleTabMain}>
          <span>{localeSettings[currentLocale].apiKey.titleTabMain}</span>
        </div>
        {currentUser?.role === 'super_admin' && (
          <Checkbox
            label={localeSettings[currentLocale].apiKey.form.checkbox.accountList}
            checked={isAccounts}
            onChange={(): void => setAccounts(!isAccounts)}
          />
        )}
        <Checkbox
          label={localeSettings[currentLocale].apiKey.form.checkbox.hostGroup}
          checked={isHosts}
          onChange={(): void => setHosts(!isHosts)}
        />
        <Checkbox
          label={localeSettings[currentLocale].apiKey.form.checkbox.apiMethods}
          checked={isMethods}
          onChange={(): void => setMethods(!isMethods)}
        />
        <Checkbox
          label={localeSettings[currentLocale].apiKey.form.checkbox.deviceList}
          checked={isDevices}
          onChange={(): void => setDevices(!isDevices)}
        />
        <Checkbox
          label={localeSettings[currentLocale].apiKey.form.checkbox.imageList}
          checked={isImages}
          onChange={(): void => setImages(!isImages)}
        />
        <Checkbox
          label={localeSettings[currentLocale].apiKey.form.checkbox.ipList}
          checked={isIps}
          onChange={(): void => setIps(!isIps)}
        />
      </div>
      <div className={styles.apiDescriptionField}>
        <Field
          name="description"
          component={FormTextArea}
          id="description"
          label={localeSettings[currentLocale].apiKey.form.description}
          onChange={(e): void => {
            setDescription(e.value);
          }}
          inputValue={description}
        />
      </div>
    </>
  );
}
