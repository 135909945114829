/* eslint-disable react/jsx-pascal-case */
import { Field } from '@progress/kendo-react-form';
import { loadMessages } from '@progress/kendo-react-intl';
import localeCommonTable from '../../../../utils/i18n/commonLocales/table.json';
import locale from '../../../../utils/i18n/credentials/credentials.json';
import { FormInput } from '../../../common/form/FormInput';
import { useCredentialContext } from '../../../../hooks/useCredentialContext';
import { checkLength } from '../../../../utils/helpers/validation';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

loadMessages(localeCommonTable[currentLocale], currentLocale);

export function TokenForm(): React.ReactElement {
  const { token, setToken } = useCredentialContext();

  return (
    <div className="form-content">
      <Field
        name="token"
        component={FormInput}
        id="token"
        label={locale[currentLocale].form.token}
        onChange={(e): void => {
          setToken(e.target.value);
        }}
        inputValue={token}
        className="form-field-wrapper"
        customValidator={checkLength}
        type="password"
        classForLabel="required-label"
      />
    </div>
  );
}
