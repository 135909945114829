import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';
import { useHeaderContext } from '../../../hooks/useHeaderContext';

export function Doc(): React.ReactElement {
  const { setExpanded, setStyles, setDisabled } = useHeaderContext();
  useEffect(() => {
    setExpanded(false);
    setStyles({ position: 'fixed', width: '100%' });
    // setDisabled(true);

    return () => {
      setExpanded(true);
      setStyles(undefined);
      window.scrollTo(0, 0);
      // setDisabled(false);
    };
  }, []);

  return (
    <div>
      <Helmet>
        <script src="/lk/doc/docsify.config.js" type="text/javascript" />
        <script src="/lk/doc/docsify.js" type="text/javascript" />
        <script src="/lk/doc/docsify.search.js" type="text/javascript" />
        <link rel="stylesheet" type="text/css" href="docsify.css" />
      </Helmet>
      <div id="app" className="documentation" />
    </div>
  );
}
