/* eslint-disable react/jsx-pascal-case */
import { useParams, useHistory } from 'react-router';
import { useMutation } from 'react-query';
import { Typography } from '@progress/kendo-react-common';
import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useCallback, useEffect, useState } from 'react';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { sendCommand } from '../../../../services/account-service';
import { TextField } from '../../../common/form/TextField';
import type { AccountAgentCommandResponse } from '../../../../types/__generated/on-premise-solution/api/accountAgentCommandResponse.v1';
import {
  COMMAND_VALUES,
  GET_CONFIG_VALUES,
  PUT_CONFIG_VALUES,
} from '../../../../utils/helpers/constants';
import type { FormDataValue } from '../../../../utils/helpers/types';
import { prepareDataAssetCommand } from '../../../../utils/prepare-data-asset-command';
import {
  useWebSocketContext,
  IWebSocketResultCommand,
} from '../../../../hooks/useWebSocketContext';
import { HostViewSummaryCommand } from './HostViewSummaryCommand';
import { useNotificationContext } from '../../../../hooks/useNotificationContext';
import { handleError } from '../../../../utils/errors';
import type { AccountAgentCommandRequestAudit } from '../../../../types/__generated/on-premise-solution/api/accountAgentCommandRequest.v1';

export function HostCommand(): React.ReactElement {
  const history = useHistory();
  const { assetId } = useParams<{ assetId: string }>();
  const [userCommandResult, setUserCommandResult] = useState<IWebSocketResultCommand | null>(null);
  const { createNotification } = useNotificationContext();
  const { ws } = useWebSocketContext();

  const initialFields = {
    command: '',
    payload: '',
    getConfig: '',
    putConfig: '',
    loggingDirPath: '',
    schedule: '',
  };

  const mutation = useMutation<
    AccountAgentCommandResponse,
    ApiError,
    AccountAgentCommandRequestAudit
  >((payload) => sendCommand(payload));

  const handleWebsocketMessage = useCallback(
    (wsMessage): void => {
      const data: IWebSocketResultCommand = JSON.parse(wsMessage?.data);
      if (
        data.result.data &&
        mutation.data &&
        mutation.data.eventId === data.result.data.data.meta.eventId
      )
        setUserCommandResult(data);
    },
    [mutation?.data],
  );

  useEffect(() => {
    const websocketMessage = ws.current;
    websocketMessage?.addEventListener('message', handleWebsocketMessage);

    return () => websocketMessage?.removeEventListener('message', handleWebsocketMessage);
  }, [ws, handleWebsocketMessage]);

  useEffect(() => {
    if (mutation.error && mutation.error.type === 'TokenExpired') history.push('/lk/dashboard');

    if (mutation.error) {
      handleError(mutation.error, createNotification);
    }
  }, [mutation.isError, mutation.error, createNotification, history]);

  useEffect(() => {
    if (mutation.data?.status === 'OK') {
      const currentInfo = {
        message: 'command sent successfully',
        type: 'info' as const,
      };
      createNotification(currentInfo);
    }
  }, [mutation.data, createNotification]);

  const handleSubmit = (dataItem: FormDataValue): void => {
    setUserCommandResult(null);
    const data = prepareDataAssetCommand(dataItem, assetId);
    if (data && !data.assetId) data.assetId = assetId;
    mutation.mutateAsync(data as unknown as AccountAgentCommandRequestAudit);
    dataItem.payload = '';
  };

  return (
    <div style={{ marginBottom: '10px' }}>
      <Typography.h3 style={{ textAlign: 'center', padding: '10px' }}>
        Select Command:
      </Typography.h3>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialFields}
        key={JSON.stringify(initialFields)}
        render={(formRenderProps: FormRenderProps): JSX.Element => (
          <FormElement style={{ maxWidth: 650 }}>
            <fieldset className="k-form-fieldset">
              <legend
                style={{ textTransform: 'none' }}
                className="k-form-legend"
              >{`Asset ID: ${assetId}`}</legend>
              <Field
                label="Select Command"
                name="command"
                data={COMMAND_VALUES}
                component={DropDownList}
              />
              {formRenderProps.valueGetter('command') === 'audit' && (
                <Field name="payload" component={TextField} label="Packages" />
              )}
              {(formRenderProps.valueGetter('command') === 'package_info' ||
                formRenderProps.valueGetter('command') === 'package_update') && (
                <Field
                  name="payload"
                  component={TextField}
                  label="Packages"
                  // validator={textMinValidator}
                />
              )}
              {formRenderProps.valueGetter('command') === 'get_config' && (
                <Field
                  label="Select"
                  name="getConfig"
                  data={GET_CONFIG_VALUES}
                  component={DropDownList}
                />
              )}
              {formRenderProps.valueGetter('command') === 'put_config' && (
                <>
                  <Field
                    label="Select"
                    name="putConfig"
                    data={PUT_CONFIG_VALUES}
                    component={DropDownList}
                  />
                  <Field name="loggingDirPath" component={TextField} label="Logging dir path" />
                  <Field name="schedule" component={TextField} label="Schedule" />
                </>
              )}
            </fieldset>
            <div className="k-form-buttons">
              <Button disabled={!formRenderProps.valueGetter('command')}>Send command</Button>
            </div>
          </FormElement>
        )}
      />
      {userCommandResult && userCommandResult.result && userCommandResult.result.data && (
        <HostViewSummaryCommand result={userCommandResult.result} />
      )}
      {mutation.isSuccess && !userCommandResult?.result && (
        <p style={{ textAlign: 'center' }}>Loading...</p>
      )}
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Button onClick={(): void => history.goBack()}>Back</Button>
      </div>
      {mutation.isLoading && <p style={{ textAlign: 'center' }}>Sending...</p>}
    </div>
  );
}
