import { RadioButton } from '@progress/kendo-react-inputs';
import {
  DropDownList,
  DropDownListChangeEvent,
  MultiSelect,
  MultiSelectChangeEvent,
} from '@progress/kendo-react-dropdowns';
import { useState, type ReactElement, useEffect, MouseEvent } from 'react';
import cronLocale from '../../../utils/i18n/commonLocales/cronCalculator.json';
import type { ICronConfig, ICronConfigTimes, ICronTimes } from './CronCalculator';
import { useTaskAddContext } from '../../../hooks/useTaskAddContext';
import styles from './CronCalculator.module.scss';
import { wordDeclension } from '../../../lib/axios/helpers';

interface IListItem {
  key: number;
  text: string;
}

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof cronLocale;

// function defineConfigType(timeType: ICronTimes, cronConfig: string): 'every' | 'in' | 'between' {
//   if (cronConfig.includes('-')) return 'between';

//   if ()

//   return cronString.split(' ')[times.indexOf(timeType)];
// }

function generateTimeArray(timeType: ICronTimes): IListItem[] {
  switch (timeType) {
    case 'minute':
      return [...Array(60)].map((_, i) => ({ text: i.toString(), key: i }));

    case 'hour':
      return [...Array(24)].map((_, i) => ({ text: i.toString(), key: i }));

    case 'day':
      return [...Array(31)].map((_, i) => ({ text: (i + 1).toString(), key: i + 1 }));

    case 'month':
      return cronLocale[currentLocale].monthNames.map((monthName, i) => ({
        key: i + 1,
        text: monthName,
      }));

    case 'weekday':
      return cronLocale[currentLocale].weekdayNames.map((weekdayName, i) => ({
        key: i + 1,
        text: weekdayName,
      }));

    default:
      return [];
  }
}

export function CronTimeConfig({
  timeType,
  config,
  setConfig,
}: {
  timeType: ICronTimes;
  config: ICronConfig;
  setConfig: React.Dispatch<React.SetStateAction<ICronConfig>>;
}): ReactElement {
  function getConfigString(): string {
    return `${config.minute} ${config.hour} ${config.day} ${config.month} ${config.weekday}`;
  }
  const { cronInputValue, setCronInputValue, changeCronValue, correctCron } = useTaskAddContext();

  // const timeValue = defineTimeValue(timeType, cronInputValue);
  const timesArray = generateTimeArray(timeType);
  const [everyDropdown, setEveryDropdown] = useState(
    timesArray[timeType === 'minute' || timeType === 'hour' ? 1 : 0],
  );
  const [inTimeMultiSelect, setInTimeMultiSelect] = useState<IListItem[]>([]);
  const [startRangeDropdown, setStartRangeDropdown] = useState(timesArray[0]);
  const [finishRangeDropdown, setFinishRangeDropdown] = useState(timesArray[1]);

  const handleReset = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setEveryDropdown(timesArray[timeType === 'minute' || timeType === 'hour' ? 1 : 0]);
    setInTimeMultiSelect([]);
    setStartRangeDropdown(timesArray[0]);
    setFinishRangeDropdown(timesArray[1]);
    setConfig({
      minute: '*',
      hour: '*',
      day: '*',
      month: '*',
      weekday: '*',
    });
  };

  useEffect(() => {
    setEveryDropdown(timesArray[timeType === 'minute' || timeType === 'hour' ? 1 : 0]);
    setInTimeMultiSelect(
      (!config[timeType].includes('*') &&
        !config[timeType].includes('/') &&
        !config[timeType].includes('-')) ||
        config[timeType].includes(',')
        ? config[timeType]
            .split(',')
            .map((c) => timesArray.find((t) => t.key.toString() === c) as IListItem)
        : [],
    );
    setStartRangeDropdown(timesArray[0]);
    setFinishRangeDropdown(timesArray[1]);
  }, [timeType]);

  useEffect(() => {
    setCronInputValue(getConfigString());
  }, [config]);

  useEffect(() => {
    changeCronValue();
  }, [cronInputValue]);

  return (
    <div className={styles.cron_tab}>
      <div className={styles.cron_property}>
        <RadioButton
          checked={config[timeType].includes('*')}
          onClick={(): void => {
            setConfig((prev) => {
              return {
                ...prev,
                [timeType]:
                  timeType === 'month' ||
                  timeType === 'weekday' ||
                  !everyDropdown.key ||
                  everyDropdown.key === 1
                    ? '*'
                    : `*/${everyDropdown.key}`,
              };
            });
          }}
          label={wordDeclension(
            everyDropdown.key,
            cronLocale[currentLocale][timeType === 'minute' ? 'everyFemale' : 'everyMale'],
          )}
        >
          {' '}
          {timeType !== 'month' && timeType !== 'weekday' && (
            <DropDownList
              style={{ width: '300px' }}
              dataItemKey="key"
              textField="text"
              data={timeType === 'minute' || timeType === 'hour' ? timesArray.slice(1) : timesArray}
              value={everyDropdown}
              onChange={(e: DropDownListChangeEvent): void => {
                const { key } = e.target.value;
                setEveryDropdown(e.target.value);
                setConfig((prev) => {
                  return {
                    ...prev,
                    [timeType]: !key || key === 1 ? '*' : `*/${key}`,
                  };
                });
              }}
            />
          )}{' '}
          {timeType === 'month' || timeType === 'weekday'
            ? cronLocale[currentLocale][timeType][0]
            : wordDeclension(everyDropdown.key, cronLocale[currentLocale][timeType])}
        </RadioButton>
      </div>
      <div className={styles.cron_property}>
        <RadioButton
          checked={
            (!config[timeType].includes('*') &&
              !config[timeType].includes('/') &&
              !config[timeType].includes('-')) ||
            config[timeType].includes(',')
          }
          onClick={(): void => {
            if (inTimeMultiSelect.length)
              setConfig((prev) => {
                return {
                  ...prev,
                  [timeType]: inTimeMultiSelect.length
                    ? inTimeMultiSelect.map((val: IListItem) => val.key).join(',')
                    : '*',
                };
              });
            else {
              setInTimeMultiSelect([timesArray[0]]);
              setConfig((prev) => {
                return {
                  ...prev,
                  [timeType]: timesArray[0].key.toString(),
                };
              });
            }
            // setTimeout(() => setCheckedIn(false), 1000)
          }}
          label={`${cronLocale[currentLocale].specified} ${cronLocale[currentLocale].plural[timeType]}:`}
        />{' '}
        <MultiSelect
          // opened={checkedIn}
          // opened={checkedIn ? checkedIn : undefined}
          // onOpen={() => setCheckedIn(true)}
          // onClose={() => setCheckedIn(false)}
          // onBlur={() => setCheckedIn(false)}
          autoClose={false}
          style={{ width: '340px' }}
          dataItemKey="key"
          textField="text"
          data={timesArray}
          value={inTimeMultiSelect}
          placeholder={cronLocale[currentLocale].changeVariantsPlaceholder}
          onChange={(e: MultiSelectChangeEvent): void => {
            const values = e.value;
            setInTimeMultiSelect(
              values.sort((a: IListItem, b: IListItem) => {
                if (a.key < b.key) return -1;
                if (a.key > b.key) return 1;

                return 0;
              }),
            );
            setConfig((prev) => {
              return {
                ...prev,
                [timeType]:
                  values && values.length ? values.map((val: any) => val.key).join(',') : '*',
              };
            });
          }}
        />{' '}
        {/* {timeType !== 'month' && timeType !== 'weekday' && cronLocale[currentLocale][timeType][1]} */}
      </div>
      <div className={styles.cron_property}>
        <RadioButton
          checked={config[timeType].includes('-')}
          label={cronLocale[currentLocale].from}
          onClick={(): void => {
            setConfig((prev) => {
              return {
                ...prev,
                [timeType]: `${startRangeDropdown.key}-${finishRangeDropdown.key}`,
              };
            });
          }}
        />{' '}
        <DropDownList
          style={{ width: '120px' }}
          dataItemKey="key"
          textField="text"
          value={startRangeDropdown}
          onChange={(e: DropDownListChangeEvent): void => {
            const val = e.target.value;
            if (val.key >= finishRangeDropdown.key) setFinishRangeDropdown(timesArray[val.key + 1]);
            setStartRangeDropdown(val);
            setConfig((prev) => {
              return {
                ...prev,
                [timeType]: `${val.key}-${
                  val.key >= finishRangeDropdown.key
                    ? timesArray[val.key].key + 1
                    : finishRangeDropdown.key
                }`,
              };
            });
          }}
          data={timesArray.slice(0, -1)}
        />{' '}
        {cronLocale[currentLocale].to}{' '}
        <DropDownList
          style={{ width: '120px' }}
          dataItemKey="key"
          textField="text"
          value={finishRangeDropdown}
          data={timesArray.filter((t) => t.key > startRangeDropdown.key)}
          onChange={(e: DropDownListChangeEvent): void => {
            const val = e.target.value;
            setFinishRangeDropdown(val);
            setConfig((prev) => {
              return {
                ...prev,
                [timeType]: `${startRangeDropdown.key}-${val.key}`,
              };
            });
          }}
        />{' '}
        {timeType !== 'month' && timeType !== 'weekday' && cronLocale[currentLocale][timeType][0]}
      </div>
      <div style={{ textAlign: 'right' }}>
        <button type="button" onClick={handleReset}>
          {cronLocale[currentLocale].resetBtn}
        </button>
      </div>
    </div>
  );
}
