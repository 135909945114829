import type { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { memo, useCallback } from 'react';
import { useHostModalContext } from '../../../../../../hooks/useHostModalContext';

function CellSelectPackage(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { selectedPkgs, setSelectedPkgs } = useHostModalContext();

  const addPackage = useCallback(
    (name: string, packetVersion: string): void => {
      if (selectedPkgs && selectedPkgs.length > 0) {
        const index = selectedPkgs?.findIndex(
          (p) => p.name === name && p.packetVersion === packetVersion,
        );
        if (index !== -1) {
          const cutSelectedPkgs = selectedPkgs.filter(
            (pkgName) => pkgName.name !== name || pkgName.packetVersion !== packetVersion,
          );
          setSelectedPkgs([...cutSelectedPkgs]);
        } else {
          setSelectedPkgs([...selectedPkgs, { name, packetVersion }]);
        }
      } else {
        setSelectedPkgs([{ name, packetVersion }]);
      }
    },
    [selectedPkgs, setSelectedPkgs],
  );

  return (
    <td>
      {selectedPkgs?.find((p) => p === dataItem.name) ? (
        <Checkbox
          checked={
            !!selectedPkgs?.find(
              (p) => p.name === dataItem.name && p.packetVersion === dataItem.packetVersion,
            )
          }
          onClick={(): void => addPackage(dataItem.name, dataItem.packetVersion)}
        />
      ) : (
        <Checkbox
          checked={
            !!selectedPkgs?.find(
              (p) => p.name === dataItem.name && p.packetVersion === dataItem.packetVersion,
            )
          }
          onClick={(): void => addPackage(dataItem.name, dataItem.packetVersion)}
        />
      )}
    </td>
  );
}

export default memo(CellSelectPackage);
