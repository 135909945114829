import type { GridCellProps } from '@progress/kendo-react-grid';
import { NavLink } from 'react-router-dom';
import type { EnrichedAssetGroupResponse } from '../../../types/__generated/on-premise-solution/api/enrichedAssetGroupResponse.v1';

export function CellGroupName(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { name, assetGroupId }: EnrichedAssetGroupResponse = dataItem;

  return (
    <td>
      <span>
        {name ? <NavLink to={`/lk/assets/groups/${assetGroupId}`}>{name}</NavLink> : <span>-</span>}
      </span>
    </td>
  );
}
