import { Grid, GridColumn } from '@progress/kendo-react-grid';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import type {
  InventorizationFirewallCiscoResult,
  InventorizationResponse,
  InventorizationRouterCiscoResult,
} from '../../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import { WrapperMenu } from './WrapperMenu';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function InventoryNetDevices({
  data,
}: {
  data: InventorizationResponse;
}): React.ReactElement {
  const { result } = data;

  const { inventory } = result as unknown as
    | InventorizationFirewallCiscoResult
    | InventorizationRouterCiscoResult;

  return (
    <WrapperMenu title={locale[currentLocale].inventory.title}>
      <Grid data={inventory} scrollable="none">
        <GridColumn field="name" title={locale[currentLocale].inventory.name} />
        <GridColumn field="pid" title={locale[currentLocale].inventory.pid} />
        <GridColumn field="vid" title={locale[currentLocale].inventory.vid} />
        <GridColumn field="sn" title={locale[currentLocale].inventory.sn} />
        <GridColumn field="descr" title={locale[currentLocale].inventory.descr} />
      </Grid>
    </WrapperMenu>
  );
}
