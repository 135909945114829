import type { GridCellProps } from '@progress/kendo-react-grid';
import styles from '../packageNameModal.module.scss';
import globalLocale from '../../../../../../utils/i18n/templates/global.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'RU' ? 'ru-RU' : 'en-EN'
) as keyof typeof globalLocale;

export function FstecStatus(props: GridCellProps): React.ReactElement {
  const { dataItem, className } = props;

  return (
    <td title={dataItem.conclusion || ''} style={{ textAlign: 'center' }} className={className ? className : ''}>
      {!dataItem.conclusion && <span className={styles.fstec_no_info}>{globalLocale[currentLocale].cvssScore.nd}</span>}
      {dataItem.conclusion ===
        'В ходе тестирования обновления недекларированные возможности не выявлены' && (
          <span className="k-icon k-i-check success" />
        )}
      {dataItem.conclusion ===
        'Обновление может быть установлено при определенных ограничениях' && (
          <span className="k-icon k-i-close fail" />
        )}
      {dataItem.conclusion ===
        'Обновление является небезопасным и устанавливать его не рекомендуется' && (
          <span className={`${styles.fstec_warning} k-icon`} />
        )}
    </td>
  );
}
