/* eslint-disable react/jsx-pascal-case */
import { Dialog } from '@progress/kendo-react-dialogs';
// import './vulnModal.scss';
import { useCallback, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import type { TabStripSelectEventArguments } from '@progress/kendo-react-layout';
import { Button } from '../../baseElements/Button';
import localeAuditTask from '../../../../utils/i18n/taskLocale/auditTask.json';
import { useAuditModalContext } from '../../../../hooks/useAuditModalContext';
import { VulnModalMetaBlock } from './VulnModalMetaBlock';
import { TabsVulnModal } from './TabsVulnModal';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getBulletinById } from '../../../../services/bulletins-service';
import { useHostModalContext } from '../../../../hooks/useHostModalContext';
import { ModalHostDetailById } from '../../../features/Host/hostList/modal/ModalHostDetailById';
import styles from '../../../features/Task/taskVmDetails/vmDetailsComponents/packageNameModal.module.scss';
import { useAssetCreatePropContext } from '../../../../hooks/useAssetCreatePropContext';
import { useWhiteListContext } from '../../../../hooks/useWhiteListContext';
import { CommonNotificationWl } from '../../CommonNotificationWl';
import type { BulletinDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/bulletinDashboardResponse.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

export function VulnModal(): React.ReactElement {
  const queryClient = useQueryClient();

  const [selected, setSelected] = useState<number>(0);

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      setSelected(e.selected);
    },
    [setSelected],
  );

  const { cveName, setCveName, setOpen } = useAuditModalContext();

  const { setHostDetailById, hostDetailById } = useHostModalContext();

  const handleCloseModal = useCallback((): void => {
    setCveName(null);
    setOpen(false);
  }, [setCveName, setOpen]);

  const query = useQuery<BulletinDashboardResponse, ApiError>(
    ['bulletinById'],
    () => getBulletinById(cveName),
    {
      retry: false,
    },
  );

  useEffect(() => {
    queryClient.invalidateQueries(['bulletinById']);
  }, [queryClient]);

  const { addThisVulnToWl } = useAssetCreatePropContext();

  const { onSubmitVulnSuccess, setOnSubmitVulnSuccess } = useWhiteListContext();

  useEffect(() => {
    return () => {
      setOnSubmitVulnSuccess(false);
      setHostDetailById(null);
    };
  }, []);

  return (
    <>
      {query.error ? (
        <Dialog title={`${cveName}`} onClose={handleCloseModal} className="vuln-modal">
          <div className="k-form">
            <div className="form-content">
              <p className="nodata-content">
                {localeAuditTask[currentLocale].vulnModal.relatedNoData}
              </p>
            </div>
            <div className="k-form-buttons">
              <Button type="button" onClick={handleCloseModal}>
                {localeAuditTask[currentLocale].close}
              </Button>
            </div>
          </div>
        </Dialog>
      ) : (
        <Dialog
          title={`${cveName}`}
          onClose={handleCloseModal}
          className={`${styles.vulnModal} vuln-modal`}
        >
          {onSubmitVulnSuccess && <CommonNotificationWl />}
          <div className={styles.addVuln}>
            <Button onClick={(): void => addThisVulnToWl(cveName || '')} className="selected-pkgs">
              {localeAuditTask[currentLocale].vulnsSoft.addToExceptions}
            </Button>
          </div>
          <div className="k-form">
            <div className="form-content">
              <VulnModalMetaBlock data={query.data} handleSelect={handleSelect} />
              <TabsVulnModal
                cveName={cveName}
                data={query.data}
                handleSelect={handleSelect}
                selected={selected}
              />
            </div>
            <div className="k-form-buttons">
              <Button type="button" onClick={handleCloseModal}>
                {localeAuditTask[currentLocale].close}
              </Button>
            </div>
          </div>
        </Dialog>
      )}
      {hostDetailById && <ModalHostDetailById />}
    </>
  );
}
