import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Dialog } from '@progress/kendo-react-dialogs';
import { useEffect } from 'react';
import localeHostGroups from '../../../../utils/i18n/assetLocales/assetGroups.json';
import locale from '../../../../utils/i18n/credentials/credentials.json';
import { getUserData } from '../../../../services/local-storage-service';
import { DropDownSelect } from '../../../common/form/DropDownSelect';
import { useAccountModalContext } from '../../../../hooks/useAccountModalContext';
import { useAccounts } from '../../../../hooks/components/useAccounts';
import { FormInput } from '../../../common/form/FormInput';
import { useCredentialContext } from '../../../../hooks/useCredentialContext';
import { checkLength } from '../../../../utils/helpers/validation';
import { FormTextArea } from '../../../common/form/FormTextArea';
import { TableWithSelectedCredential } from './TableWithSelectedCredential';
import { useVault } from '../../../../hooks/components/useVault';
import { Button } from '../../../common/baseElements/Button';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHostGroups;

export function AddCredentialStorageForm(): React.ReactElement {
  const currentUser = getUserData();

  const {
    nameVault,
    setNameVault,
    typeVault,
    endpoint,
    setEndpoint,
    typeValuesForSelect,
    enginePath,
    setEnginePath,
    descriptionVault,
    setDescriptionVault,
    credentialVault,
    credentialData,
    nameCred,
    errorMutation,
    setErrorMutation,
  } = useCredentialContext();

  useEffect(() => {
    return setErrorMutation('');
  }, []);

  const { onSubmitAddStorage, exitStorageForm, changeDropDownType } = useVault();

  const { changeDropDownAccount, accountId } = useAccountModalContext();

  const { destructuringAccounts } = useAccounts();

  const destructuredAccounts = destructuringAccounts();

  return (
    <Dialog
      title={
        credentialData
          ? `${locale[currentLocale].form.editCredential} "${nameCred}"`
          : locale[currentLocale].form.addStorage
      }
      onClose={exitStorageForm}
      className="add-edit-vault small modal"
    >
      <Form
        onSubmit={onSubmitAddStorage}
        render={(): React.ReactElement => (
          <FormElement>
            <div className="form-content">
              {currentUser?.role === 'super_admin' && (
                <Field
                  name="accountId"
                  component={DropDownSelect}
                  label={locale[currentLocale].form.accountName}
                  onChange={changeDropDownAccount}
                  data={destructuredAccounts?.filter((a) => a.id !== accountId.id)}
                  textField="name"
                  dataItemKey="id"
                  defaultValue={
                    destructuredAccounts &&
                    Array.isArray(destructuredAccounts) &&
                    destructuredAccounts.length === 1
                      ? { id: destructuredAccounts[0].id, name: destructuredAccounts[0].name }
                      : {
                          id: '000',
                          name: locale[currentLocale].form.placeholderAccountName,
                        }
                  }
                  cls="form-field-wrapper"
                  currentValue={accountId}
                  disabled={
                    (destructuredAccounts &&
                      Array.isArray(destructuredAccounts) &&
                      destructuredAccounts.length === 1) ||
                    !destructuredAccounts
                  }
                  classForLabel="required-label"
                />
              )}
              <Field
                name="name"
                component={FormInput}
                id="name"
                label={locale[currentLocale].form.name}
                onChange={(e): void => {
                  setNameVault(e.target.value);
                }}
                inputValue={nameVault}
                className="form-field-wrapper"
                customValidator={checkLength}
                type="text"
                classForLabel="required-label"
              />
              <Field
                name="type"
                component={DropDownSelect}
                label={locale[currentLocale].form.type}
                onChange={changeDropDownType}
                data={typeValuesForSelect.filter((t) => t !== typeVault)}
                defaultValue={typeValuesForSelect[0]}
                cls="form-field-wrapper"
                disabled
                classForLabel="required-label"
              />
              <Field
                name="endpoint"
                component={FormInput}
                id="endpoint"
                label={locale[currentLocale].table.endpoint}
                onChange={(e): void => {
                  setEndpoint(e.target.value);
                }}
                inputValue={endpoint}
                className="form-field-wrapper"
                customValidator={checkLength}
                type="text"
                classForLabel="required-label"
              />
              <Field
                name="enginePath"
                component={FormInput}
                id="enginePath"
                label="Secret engine path:"
                onChange={(e): void => {
                  setEnginePath(e.target.value);
                }}
                inputValue={enginePath}
                className="form-field-wrapper"
                placeholder="vio_onprem_credentials_storage"
                type="text"
                // classForLabel="required-label"
              />
              <TableWithSelectedCredential />
              <Field
                name="description"
                component={FormTextArea}
                id="description"
                label={locale[currentLocale].form.description}
                onChange={(e): void => {
                  setDescriptionVault(e.value);
                }}
                placeholder={locale[currentLocale].form.desPlaceholder}
                inputValue={descriptionVault}
              />
            </div>
            <div className="error-common-field">
              <span>{errorMutation}</span>
            </div>
            <div className="k-form-buttons">
              <Button
                type="submit"
                disabled={
                  (destructuredAccounts &&
                    Array.isArray(destructuredAccounts) &&
                    destructuredAccounts.length > 1 &&
                    accountId.id === '000' &&
                    currentUser?.role === 'super_admin') ||
                  !nameVault ||
                  !typeVault ||
                  !endpoint ||
                  !credentialVault?.credentialId
                }
              >
                {localeHostGroups[currentLocale].modal.btnSave}
              </Button>
              <Button type="button" onClick={exitStorageForm}>
                {localeHostGroups[currentLocale].modal.btnCancel}
              </Button>
            </div>
          </FormElement>
        )}
      />
    </Dialog>
  );
}
