import type { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { memo } from 'react';
import { useAssetCreatePropContext } from '../../../../../../hooks/useAssetCreatePropContext';
import { useAssetActions } from '../../../../../../hooks/components/useAssetActions';

function CellSelectVuln(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { selectedVulns } = useAssetCreatePropContext();

  const { addVuln } = useAssetActions();

  return (
    <td>
      {selectedVulns?.find((v) => v.vulnId === dataItem.id) ? (
        <Checkbox
          checked={!!selectedVulns?.find((v) => v.vulnId === dataItem.id)}
          onClick={(): void => addVuln(dataItem.id)}
        />
      ) : (
        <Checkbox
          checked={!!selectedVulns?.find((v) => v.vulnId === dataItem.id)}
          onClick={(): void => addVuln(dataItem.id)}
        />
      )}
    </td>
  );
}

export default memo(CellSelectVuln);
