/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
import '../TaskIDetails.module.scss';
import { useRef, useState } from 'react';
import { Grid, GridColumn, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { Filter } from '@progress/kendo-react-data-tools';
import { State, process, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import type { InventorizationResponse } from '../../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import { FILTER_INITIAL, PAGEABLE_DATA_LOCAL } from '../../../../../utils/helpers/constants';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { CellWinService } from './cellsWinService/cellWinService';
import { CellWinPath } from './cellsWinService/cellWinPath';
import { CellDisplayName } from './cellsWinService/cellDisplayName';
import { CellDescription } from './cellsWinService/cellDescription';
import {
  handlerCustomSearch,
  onFilterChange,
} from '../../../../../utils/helpers/local-filter-helpers';
import { useLocalExcelExport } from '../../../../../hooks/components/useLocalExcelExport';
import { SERVICES_LOCAL_WIN } from '../../../../../utils/helpers/constant-local-filter-task';
import { Input } from '../../../../common/baseElements/Input';
import { Button } from '../../../../common/baseElements/Button';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function ServicesWin({
  data,
  hostName,
}: {
  data: InventorizationResponse;
  hostName: string | undefined | null;
}): React.ReactElement {
  const pageLimitGrid: { [key: string]: string } =
    localStorage.getItem('pageLimitGrid') &&
    JSON.parse(localStorage.getItem('pageLimitGrid') || '');

  const initialDataState: State = {
    sort: [{ field: '', dir: 'asc' }],
    take: pageLimitGrid && pageLimitGrid.ServicesWin ? Number(pageLimitGrid.ServicesWin) : 10,
    skip: 0,
    filter: FILTER_INITIAL,
  };

  const fieldFindInTable = 'name';

  const [dataState, setDataState] = useState<State>(initialDataState);

  const [filter, setFilter] = useState(FILTER_INITIAL);

  const [customSearch, setCustomSearch] = useState('');

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _grid = useRef<React.LegacyRef<Grid> | undefined>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _export = useRef<ExcelExport | null>(null);

  const linService = data?.type === 'windows' && data.result.services;

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  const excelExport = useLocalExcelExport(dataState, _export, linService, setDataState);

  const titleVulnsSoft = `${hostName && hostName.replaceAll('.', '-')}_${locale[currentLocale].tabs.services
    }`;

  return (
    <div className="service-int">
      {data.type === 'windows' && (
        <>
          <LocalizationProvider language={currentLocale}>
            <IntlProvider locale={currentLocale.substring(0, 2)}>
              <div className="filter-line">
                <Filter
                  value={filter}
                  onChange={(event): void =>
                    onFilterChange(
                      event,
                      setCustomSearch,
                      setDataState,
                      dataState,
                      setFilter,
                      filterValue,
                      customSearch,
                      fieldFindInTable,
                    )
                  }
                  fields={SERVICES_LOCAL_WIN[currentLocale]}
                />
                {filter.filters.length === 0 && (
                  <Input
                    name="customSearch"
                    placeholder={locale[currentLocale].customFilterPh}
                    value={customSearch}
                    onChange={(e): void =>
                      handlerCustomSearch(
                        e,
                        setCustomSearch,
                        setDataState,
                        filterValue,
                        dataState,
                        fieldFindInTable,
                      )
                    }
                  />
                )}
                <Button excel fill="flat" onClick={excelExport}>
                  {locale[currentLocale].exportToExcel}
                </Button>
              </div>
            </IntlProvider>
          </LocalizationProvider>
          <LocalizationProvider language={currentLocale}>
            <IntlProvider locale={currentLocale.substring(0, 2)}>
              <ExcelExport data={data.result.services!} ref={_export} fileName={titleVulnsSoft}>
                <Grid
                  pageable={
                    data.result.services && data.result.services.length > 10
                      ? PAGEABLE_DATA_LOCAL
                      : false
                  }
                  sortable
                  data={process(data.result.services!, dataState)}
                  {...dataState}
                  onDataStateChange={(e: GridDataStateChangeEvent): void => {
                    setDataState(e.dataState);
                    if (pageLimitGrid) {
                      const pageLimit = {
                        ...pageLimitGrid,
                        ServicesWin: String(e.dataState.take),
                      };
                      localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
                    } else {
                      const pageLimit = {
                        ServicesWin: String(e.dataState.take),
                      };
                      localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
                    }
                  }}
                  scrollable="none"
                  ref={_grid.current}
                >
                  <GridColumn
                    field="name"
                    title={locale[currentLocale].servicesWin.name}
                    width="240px"
                  />
                  <GridColumn
                    field="display_name"
                    title={locale[currentLocale].servicesWin.displayName}
                    cell={CellDisplayName}
                  />
                  <GridColumn
                    field="description"
                    title={locale[currentLocale].servicesWin.description}
                    cell={CellDescription}
                  />
                  <GridColumn
                    field="status"
                    title={locale[currentLocale].servicesWin.status}
                    width="80px"
                  />
                  <GridColumn
                    field="state"
                    title={locale[currentLocale].servicesWin.state}
                    width="88px"
                  />
                  <GridColumn
                    field="start_type"
                    title={locale[currentLocale].servicesWin.startType}
                    cell={CellWinService}
                    width="116px"
                  />
                  <GridColumn field="pid" title={locale[currentLocale].services.pid} width="72px" />
                  <GridColumn
                    field="user_account"
                    title={locale[currentLocale].servicesWin.userAccount}
                  />
                  <GridColumn
                    field="path"
                    title={locale[currentLocale].servicesWin.path}
                    cell={CellWinPath}
                  />
                </Grid>
              </ExcelExport>
            </IntlProvider>
          </LocalizationProvider>
        </>
      )}
    </div>
  );
}
