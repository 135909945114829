import type { GridCellProps } from '@progress/kendo-react-grid';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Button } from '../../../common/baseElements/Button';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import localeDevices from '../../../../utils/i18n/devices/devices.json';
import type { AssetDeviceDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/assetsDevicesDashboardResponse.v1';
import type { AssetDeletionResponse } from '../../../../types/__generated/on-premise-solution/api/assetDeletionResponse.v1';
import { deleteNetDevice } from '../../../../services/asset-service';
import { useCreateTask } from '../../../../hooks/components/useCreateTask';
import { getUserData } from '../../../../services/local-storage-service';
import { DeleteConfirmModal } from '../../../common/modal/DeleteConfirmModal';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeDevices;

export function CellMenu(props: GridCellProps): React.ReactElement {
  const currentUser = getUserData();

  const { dataItem } = props;

  const { assetId, latestInventorization }: AssetDeviceDashboardResponse = dataItem;

  const queryClient = useQueryClient();

  const [isConfirm, setConfirm] = useState(false);

  const { createTaskForAsset } = useCreateTask();

  const handleConfirm = (): void => {
    setConfirm(true);
  };

  const mutation = useMutation<AssetDeletionResponse | null, ApiError, string>(
    () => deleteNetDevice(assetId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('NetworkDevices');
      },
    },
  );

  const handleDelete = (): void => {
    mutation.mutate(assetId);
    setConfirm(false);
  };

  return (
    <>
      {isConfirm && (
        <DeleteConfirmModal
          name={`${latestInventorization.hostname || assetId}`}
          type="asset"
          onClose={(): void => setConfirm(false)}
          handleDelete={handleDelete}
          errorMessage={mutation.error?.message}
        />
      )}
      <td className="cell_menu">
        <div className="groups__menu">
          <div className="dropdown__container">
            <span className="k-icon k-i-more-vertical" />
            <div className="dropdown__content">
              <Button
                fill="menu"
                onClick={(): void => {
                  createTaskForAsset({
                    val: 'inventorization',
                    data: dataItem,
                    assetId: dataItem.assetId,
                  });
                }}
              >
                {localeDevices[currentLocale].grid.menuCreateTask}
              </Button>
              <div className="delimiter" />
              {/* <Button fillMode="flat" onClick={(): void => addDataForEdit()}>
                {localeImReg[currentLocale].btnEdit}
              </Button> */}
              {currentUser?.role !== 'reader_user' && (
                <Button
                  fill="menu"
                  onClick={handleConfirm}
                  disabled={
                    currentUser?.role === 'user' && currentUser.id !== dataItem.createdBy.id
                  }
                >
                  {localeDevices[currentLocale].btnDelete}
                </Button>
              )}
            </div>
          </div>
        </div>
      </td>
    </>
  );
}
